import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { beneficiaryToPatient } from "../gql";
import notify from "antd/lib/notification";
import Spin from "antd/lib/spin";
import get from "lodash/get";

function UpgradeUnderAge({ group }) {
  const [notified, setNotified] = useState(false);
  const limitYear = moment().subtract(18, "years").calendar().slice(6);
  const adults = group.filter(
    (person) => person.email && moment(person.dateOfBirth).format("YYYY") <= limitYear
  );
  const adultsMissingEmail = group.filter(
    (person) => moment(person.dateOfBirth).format("YYYY") <= limitYear
  );
  const [postBeneficiaryToPatient, { loading }] = useMutation(beneficiaryToPatient, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["getMeBeneficiaries", "FindBeneficiaries"],
  });

  useEffect(() => {
    if (adultsMissingEmail.length >= 1 && !notified) {
      notify.info({
        message:
          "Ahora puedes agregarle un correo a tu beneficiario(s) mayor de edad y asi le crearemos su propia cuenta!",
      });
      setNotified(true);
    }
  }, [adultsMissingEmail, notified]);

  const clickHandler = (adults) => {
    adults.forEach((person) => {
      postBeneficiaryToPatient({ variables: { id: person.id } })
        .then(() => {
          notify.success({ message: `Cuenta creada para ${person.name}` });
        })
        .catch((error) => {
          if (error.graphQLErrors) {
            const description = get(error.graphQLErrors, [0, "message"], error.graphQLErrors);
            notify.error({
              message: "Error",
              description,
            });
          }
        });
    });
  };

  return (
    <>
      {adults.length >= 1 && (
        <button
          className='text-center text-primary-hover underline'
          onClick={() => clickHandler(adults)}
        >
          {loading ? (
            <Spin />
          ) : (
            `Tu Beneficiario ${
              adults.length === 1
                ? adults[0].name + " puede tener su propia cuenta"
                : adults.map((person, i) => {
                    if (i === adults.length - 1) return person.name + " ";
                    if (i === adults.length - 2) return person.name + " y ";
                    return person.name + ", ";
                  }) + "pueden tener su propia cuenta"
            }  y seguir obteniendo tus beneficios, presiona aqui`
          )}
        </button>
      )}
    </>
  );
}

UpgradeUnderAge.propTypes = {
  group: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)])
    )
  ),
};

export default UpgradeUnderAge;
