import React, {
  useContext,
  useState,
  createContext,
  useReducer,
  useEffect,
  useCallback,
} from "react";
import get from "lodash/get";
import { useQuery } from "@apollo/react-hooks";
import * as actionName from "./Profile/storeProfileMedic/actionStoreMedic";

import Layout from "layouts/profile/Layout";
import { GlobalContext } from "views/App";
// component
import ContendView from "./Profile/taps";
import SiderMedic from "./Profile/SiderMedic/index";

import reducer from "./Profile/storeProfileMedic/reducerStoreMedic";
import initialState from "./Profile/storeProfileMedic/initialStateStoreMedic";
import {
  doctorById,
  doctorMDById,
  updateMedicPicture,
  updateMedicPictureMD,
} from "./Profile/gqlTags";

import { useHistory } from "react-router-dom";

// create context Store
export const profileMedicContext = createContext();

export default React.memo(() => {
  const history = useHistory();
  // global State
  const { state } = useContext(GlobalContext);

  // State Profile Medic
  const [stateLocal, dispatch] = useReducer(reducer, initialState);
  const { pictureURL, fullName, id, role } = state.user || {};
  const [selected, onSelect] = useState(id);
  const [appointment, setAppointment] = useState(null);

  const { data, loading, error } = useQuery(role.name === "doctorMD" ? doctorMDById : doctorById, {
    variables: { id },
    fetchPolicy: "no-cache",
  });

  const isDoctor = role.name === "doctor" || role.name === "doctorMD";
  if (!isDoctor) {
    history.push("/");
  }
  const tab = useCallback(get(history, "location.tab", ""), [history]);

  const getBalance = useCallback(() => {
    if (role.name === "doctorMD") {
      return null;
    } else {
      return data.doctorById.balance;
    }
  }, [role, data]);

  const getDataMedic = useCallback(() => {
    if (role.name === "doctorMD") {
      return {
        role: role.name,
        id: data.doctormdById.id,
        fullName: data.doctormdById.fullName,
        firstName: data.doctormdById.firstName,
        lastName: data.doctormdById.lastName,
        pictureURL: data.doctormdById.pictureURL,
        phoneNumber: data.doctormdById.phoneNumber,
        minsaNumber: data.doctormdById.minsaNumber,
        specialties: data.doctormdById.specialties,
        languages: data.doctormdById.languages,
        description: data.doctormdById.description,
        signature: data.doctormdById
          ? data.doctormdById.signature
            ? data.doctormdById.signature.url
            : ""
          : "",
        email: data.doctormdById.email,
      };
    } else {
      return {
        role: role.name,
        id: data.doctorById.id,
        fullName: data.doctorById.fullName,
        firstName: data.doctorById.firstName,
        lastName: data.doctorById.lastName,
        pictureURL: data.doctorById.pictureURL,
        phoneNumber: data.doctorById.phoneNumber,
        minsaNumber: data.doctorById.minsaNumber,
        specialties: data.doctorById.specialties,
        languages: data.doctorById.languages,
        description: data.doctorById.description,
        signature: data.doctorById
          ? data.doctorById.signature
            ? data.doctorById.signature.url
            : ""
          : "",
        appointmentPrice: data.doctorById.appointmentPrice,
        approvalStatus: data.doctorById.approvalStatus,
        email: data.doctorById.email,
      };
    }
  }, [role, data]);

  useEffect(() => {
    if (loading) {
      dispatch({
        type: actionName.SET_MEDIC,
        payload: {
          ...stateLocal,
          loading: true,
        },
      });
    } else if (data && !loading) {
      dispatch({
        type: actionName.SET_MEDIC,
        payload: {
          ...stateLocal,
          error: error || null,
          loading: false,
          tabsActive: tab ? "2" : "1",
          balance: getBalance(),
          dataMedic: getDataMedic(),
        },
      });
    }
    if (error) {
      dispatch({
        type: actionName.SET_ERROR,
        payload: error || null,
      });
    }
  }, [data, loading, error, getBalance, getDataMedic, tab]);

  const propsComponet = {
    Person: {
      id,
      role: role.name,
      gql: {
        upPic: role.name === "doctor" ? updateMedicPicture : updateMedicPictureMD,
      },
      pic: get(pictureURL, "url"),
      name: fullName,
      label: "Doctor",
      onSelect,
      selected: selected,
      size: 0,
    },
    appointment,
    setAppointment,
  };

  return (
    <profileMedicContext.Provider value={{ state: stateLocal, dispatch: dispatch }}>
      <Layout sider={<SiderMedic {...propsComponet} />} content={<ContendView />} />
    </profileMedicContext.Provider>
  );
});
