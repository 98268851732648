import React, { useState, useEffect } from "react";
import { Calendar, Button, Spin, notification } from "antd";
import moment from "moment";
import { useQuery } from "@apollo/react-hooks";
import { HandleError } from "utils.js/HandleError";
import User from "components/User";

// Taps
import { appointmentsDoctor } from "../../Profile/gqlTags";

// icons
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";

// componets
import Person from "components/Person";
import CardcitaAgendada from "./CardCitaAgendada";

const IndexSideMedic = (props) => {
  const Title = ({ className, ...attr }) => <p className={`title ${className}`} {...attr} />;
  return (
    <div className='p-5'>
      <User>
        <Title>
          Mi Perfil
          <Icon className='text-center mx-4' size='sm' icon={faChevronDown} />
        </Title>
      </User>
      <Person.Card {...props.Person} />
      {props.Person.role === "doctor" && <SiderMedic {...props} />}
    </div>
  );
};

const SiderMedic = (props) => {
  const [DateCalendar, setDateCalendar] = useState(moment());

  const [variables, setVariables] = useState({
    perPage: 25,
    page: 1,
    filters: [
      {
        byDate: {
          field: "APPOINTMENTDATEANDTIME",
          from: `${moment().startOf("month").toISOString()}`,
          to: `${moment().endOf("month").toISOString()}`,
        },
      },
    ],
  });

  const [variablesForDay, setVariablesForDay] = useState({
    perPage: 25,
    page: 1,
    filters: [
      {
        byDate: {
          field: "APPOINTMENTDATEANDTIME",
          from: `${moment().startOf("day").toISOString()}`,
          to: `${moment().endOf("day").toISOString()}`,
        },
      },
    ],
  });

  const Title = ({ className, ...attr }) => (
    <p className={`title mt-2 mb-1 ${className}`} {...attr} />
  );
  const { data, loading, error } = useQuery(appointmentsDoctor, {
    variables: { ...variables },
    fetchPolicy: "no-cache",
  });
  const {
    data: dataDay,
    loading: loadingDay,
    error: errorDay,
  } = useQuery(appointmentsDoctor, {
    variables: { ...variablesForDay },
    fetchPolicy: "no-cache",
    pollInterval: 5000,
  });

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
  }, [error]);

  return (
    <React.Fragment>
      <Title>Mi Agenda</Title>
      <Spin spinning={loading} tip='Loading...'>
        <Calendar
          fullscreen={false}
          value={DateCalendar}
          headerRender={(valueRender) => (
            <CustomHeader
              variables={variables}
              setVariables={setVariables}
              setDateCalendar={setDateCalendar}
              {...valueRender}
            />
          )}
          dateFullCellRender={(valueRender) => (
            <CustomCellRender
              variables={variablesForDay}
              setVariables={(e) => setVariablesForDay(e)}
              data={data || null}
              {...valueRender}
            />
          )}
        />
      </Spin>
      <Title className='mt-5 mb-1'>Citas Agendadas</Title>
      <p className='text-center '>{moment(variablesForDay.filters[0].byDate.from).format("LL")}</p>
      <CardcitaAgendada data={dataDay} loading={loadingDay} error={errorDay} />
    </React.Fragment>
  );
};

const CustomCellRender = (propsCell) => {
  const [selectedDay, setSelectedDay] = useState(moment());

  const onClickHandles = () => {
    setSelectedDay(propsCell._d);
    propsCell.setVariables({
      ...propsCell.variables,
      filters: [
        {
          byDate: {
            field: "APPOINTMENTDATEANDTIME",
            from: `${moment(propsCell._d).startOf("day").toISOString()}`,
            to: `${moment(propsCell._d).endOf("day").toISOString()}`,
          },
        },
      ],
    });
  };

  if (propsCell.data) {
    const listDate = propsCell.data.appointments.docs.map((value) => value.appointmentDateAndTime);
    if (listDate.some((date) => moment(date).isSame(moment(propsCell._d), "day"))) {
      return (
        <Button
          className='z-50 '
          onClick={() => onClickHandles()}
          type='primary'
          shape='circle'
          size='middle'
        >
          {moment(propsCell._d).format("DD")}
        </Button>
      );
    } else {
      return (
        <div
          className={`py-2 z-50  ${
            moment(selectedDay).isSame(moment(propsCell._d), "day")
              ? "border-solid border-2 rounded-full border-primary"
              : ""
          }`}
        >
          {moment(propsCell._d).format("DD")}
        </div>
      );
    }
  }
  return <span className='m-1'>{moment(propsCell._d).format("DD")}</span>;
};

const CustomHeader = (propsHeader) => {
  const { value, setDateCalendar, variables, setVariables } = propsHeader;
  return (
    <div className='py-4 mx-2'>
      <div className='flex justify-between'>
        <div className='flex items-center'>
          <span>{moment(value).format("MMMM YYYY")}</span>
        </div>
        <div>
          <Button
            onClick={() => {
              setDateCalendar(moment(value).subtract(1, "month"));
              setVariables({
                ...variables,
                filters: [
                  {
                    byDate: {
                      field: "APPOINTMENTDATEANDTIME",
                      from: `${moment(value)
                        .subtract(1, "month")
                        .startOf("month")
                        .format("MM/DD/YYYY 00:00")}`,
                      to: `${moment(value)
                        .subtract(1, "month")
                        .endOf("month")
                        .format("MM/DD/YYYY 23:59")}`,
                    },
                  },
                ],
              });
            }}
            style={{ border: "none" }}
            type='default'
            shape='round'
            size='middle'
            icon={<Icon className='text-center' size='lg' icon={faChevronLeft} />}
          />
          <Button
            onClick={() => {
              setDateCalendar(moment(value).add(1, "month"));
              setVariables({
                ...variables,
                filters: [
                  {
                    byDate: {
                      field: "APPOINTMENTDATEANDTIME",
                      from: `${moment(value)
                        .add(1, "month")
                        .startOf("month")
                        .format("MM/DD/YYYY 00:00")}`,
                      to: `${moment(value)
                        .add(1, "month")
                        .endOf("month")
                        .format("MM/DD/YYYY 23:59")}`,
                    },
                  },
                ],
              });
            }}
            style={{ border: "none" }}
            type='default'
            shape='round'
            size='middle'
            icon={<Icon className='text-center' size='lg' icon={faChevronRight} />}
          />
        </div>
      </div>
    </div>
  );
};

export default IndexSideMedic;
