import React, { useContext, useEffect, useState, useCallback } from "react";
import { Button, Layout, Menu, Spin } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { faMobileAlt, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// logo
import Logo from "assets/img/logoEasyMD.png";

// components
import User from "components/User";

// context
import { useLazyQuery } from "@apollo/react-hooks";
import MeGql from "./gql";
import { SET_USER } from "store/actions";
import { GlobalContext } from "views/App";

import Headroom from "react-headroom";

import get from "lodash/get";
import lowerCase from "lodash/lowerCase";

// utils
import { logoutAndClearAll } from "layouts/utils";

// styles
import styles from "./styles.module.scss";
import "./antdOverrideHeader.scss";
import getValidNationality from "views/Patient/Tabs/Home/getValidNationality";

const { Header } = Layout;
export const ROUTES_HOME = {
  home: {
    url: "/md/home",
    key: "0",
  },
  familiar: {
    url: "/md/familiar",
    key: "1",
  },
  empresa: {
    url: "/md/empresa",
    key: "2",
  },
  // tecnologia: {
  //   url: "/md/tecnologia",
  //   key: "3",
  // },
  especialistas: {
    url: "/md/encuentra/especialistas",
    key: "4",
  },
  loading: {
    url: null,
    key: "5",
  },
  userLogo: {
    url: null,
    key: "6",
  },
  login: {
    url: "/md/auth/login",
    key: "7",
  },
  signup: {
    url: "/md/auth/signup",
    key: "8",
  },
  mobileApp: {
    url: "https://play.google.com/store/apps/details?id=com.easymdv2.android",
    key: "9",
  },
};

const HeaderHome = () => {
  const location = useLocation();
  const [currentPathName, setCurrentPathName] = useState(get(location, "pathname", null));
  const token = localStorage.getItem("refreshToken");

  // store
  const { state, dispatch } = useContext(GlobalContext);

  const { user } = state;

  const { listen, push } = useHistory();
  const validNationality = getValidNationality(user?.nationality);

  // Queries
  const [sendMeUser, { data, error, loading }] = useLazyQuery(MeGql, { fetchPolicy: "no-cache" });

  useEffect(() => {
    listen((newLocation) => {
      setCurrentPathName(get(newLocation, "pathname", null));
    });
  }, [listen]);

  const getUser = useCallback(() => {
    sendMeUser();
  }, [sendMeUser]);

  useEffect(() => {
    !user && token && getUser();
  }, [user, token, getUser]);

  useEffect(() => {
    if (!loading) {
      if (data && data.me) {
        dispatch({ type: SET_USER, payload: data.me });
      } else if (error) {
        logoutAndClearAll();
        dispatch({ type: SET_USER, payload: null });
      }
    }
  }, [data, loading, error, dispatch]);

  const changeView = (url) => {
    push(url);
  };

  const getActiveClass = (key) => {
    if (lowerCase(key) === lowerCase(currentPathName)) {
      return " text-primary-dark font-semibold ";
    }
    return " text-blue font-medium ";
  };

  const getDefaultSelect = () => {
    const current = [];
    for (const [value] of Object.entries(ROUTES_HOME)) {
      if (lowerCase(value.url) === lowerCase(currentPathName)) {
        current.push(value.key);
      }
    }
    return current;
  };

  return (
    <Headroom
      style={{
        height: "auto",
        zIndex: "2",
        width: "100%",
      }}
      className='HeadroomMD'
    >
      <Header className='shadow-md bg-white px-4 md:p-0'>
        <div className='container mx-auto'>
          <div className={`${styles.containerLogo}  flex xs:pr-3 md:pr-5`}>
            <Link to={ROUTES_HOME.home.url} className='my-auto mx-auto'>
              <img alt='easyMDLogo' src={Logo} />
            </Link>
          </div>

          <Menu
            className='headerEasyMD bg-white flex lg:ml-5'
            mode='horizontal'
            overflowedIndicator={
              <FontAwesomeIcon
                className='text-black hover:text-primary focus:text-primary'
                size='2x'
                icon={faBars}
              />
            }
            selectedKeys={getDefaultSelect()}
          >
            <Menu.Item
              key={ROUTES_HOME.familiar.key}
              className='xs:hover:bg-whiteDark bg-transparent flex p-0 xs:mx-auto lg:mx-0'
              onClick={() => changeView(ROUTES_HOME.familiar.url)}
            >
              <div className='my-auto mx-auto'>
                <span
                  className={
                    getActiveClass(ROUTES_HOME.familiar.url) + "md:border-r md:border-info px-4"
                  }
                >
                  Para ti y tu familia
                </span>
              </div>
            </Menu.Item>
            <Menu.Item
              key={ROUTES_HOME.empresa.key}
              className='bg-transparent xs:hover:bg-whiteDark flex p-0 xs:mx-auto lg:mx-0'
              onClick={() => changeView(ROUTES_HOME.empresa.url)}
            >
              <div className='my-auto mx-auto'>
                <span
                  className={
                    getActiveClass(ROUTES_HOME.empresa.url) + "md:border-r md:border-info px-4"
                  }
                >
                  Empresas
                </span>
              </div>
            </Menu.Item>
            {/* <Menu.Item
              key={ROUTES_HOME.tecnologia.key}
              className="bg-transparent xs:hover:bg-whiteDark flex p-0 xs:mx-auto lg:mx-0"
              onClick={() => changeView(ROUTES_HOME.tecnologia.url)}
            >
              <div className="my-auto mx-auto">
                <span
                  className={
                    getActiveClass(ROUTES_HOME.tecnologia.url) + "md:border-r md:border-info px-4"
                  }
                >
                  Tecnología
                </span>
              </div>
            </Menu.Item> */}
            {/* EAS-1608 */}
            {validNationality || !user?.nationality ? (
              <Menu.Item
                key={ROUTES_HOME.especialistas.key}
                className='bg-transparent xs:hover:bg-whiteDark flex p-0 xs:mx-auto lg:mx-0'
                onClick={() => changeView(ROUTES_HOME.especialistas.url)}
              >
                <div className='my-auto mx-auto'>
                  <span className={getActiveClass(ROUTES_HOME.especialistas.url) + " px-3"}>
                    Red de Especialistas
                  </span>
                </div>
              </Menu.Item>
            ) : (
              <div>
                <p></p>
              </div>
            )}
            {loading && (
              <Menu.Item
                key={ROUTES_HOME.loading.key}
                className='ml-auto bg-transparent lg:justify-self-end flex p-0'
              >
                <div className='my-auto mx-auto'>
                  <Spin />
                </div>
              </Menu.Item>
            )}

            {!loading && user !== null && (
              <Menu.Item
                key={ROUTES_HOME.userLogo.key}
                className='ml-auto bg-transparent justify-self-end flex p-0'
              >
                <div className=' my-auto text-black mx-auto'>
                  <User size={40} />
                </div>
              </Menu.Item>
            )}

            {!loading && user === null && (
              <Menu.Item
                onClick={() => changeView(ROUTES_HOME.login.url)}
                key={ROUTES_HOME.login.key}
                className='ml-auto xs:hover:bg-whiteDark bg-transparent flex p-0'
              >
                <Button type='link' className='font-medium my-auto mx-auto text-black'>
                  Inicia sesión
                </Button>
              </Menu.Item>
            )}

            {!loading && user === null && (
              <Menu.Item
                onClick={() => changeView(ROUTES_HOME.signup.url)}
                key={ROUTES_HOME.signup.key}
                className='bg-transparent flex p-0'
              >
                <Button
                  type='primary'
                  className='my-auto mx-auto font-medium shadow-md'
                  shape='round'
                >
                  <span className='text-white font-medium'>Crear cuenta</span>
                </Button>
              </Menu.Item>
            )}

            <Menu.Item
              key={ROUTES_HOME.mobileApp.key}
              className='bg-transparent flex border-0 lg:pr-0'
            >
              <Button
                type='link'
                className='button-success my-auto mx-auto font-medium shadow-md'
                shape='round'
                icon={<FontAwesomeIcon className='mr-2' size='md' icon={faMobileAlt} />}
              >
                <a
                  rel='noopener noreferrer'
                  target='_blank'
                  href={ROUTES_HOME.mobileApp.url}
                  className='text-white font-medium'
                >
                  Descargar la app
                </a>
              </Button>
            </Menu.Item>
          </Menu>
        </div>
      </Header>
    </Headroom>
  );
};

export default HeaderHome;
