import React, { useContext } from "react";
import { Table, Input, Button, Divider } from "antd";
import columns from "./columnsTable";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

// components
import ModalUser from "./modales/modalUser";
import ModalRole from "./modales/modalRole";
import ModalDelete from "./modales/modalDelete";

import { ContextTableUsers } from "./index";
import useTable from "./hooks/useTable";

const TableUsers = () => {
  const { state } = useContext(ContextTableUsers);
  const { searchName, modalNewUser, modalNewRole, modalOpenDelete, modalEditUser } = state;
  const {
    loading,
    setSearchName,
    clickNewUser,
    clickNewRole,
    getDataForTable,
    getPagination,
    handleTableChange,
  } = useTable();

  return (
    <>
      <div className='w-full flex xs:flex-col sm:flex-row mb-3'>
        <Input
          className={"w-full mx-auto sm:mx-0 " + styles.max250px}
          placeholder='Buscar por nombre'
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
        <Button
          onClick={() => clickNewRole()}
          type='link'
          shape='round'
          className='text-success hover:text-success-lighter ml-auto'
          size='large'
          icon={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faPlus} />}
        >
          Nuevo Rol
        </Button>{" "}
        <Divider type='vertical' className='mt-1 h-8' />
        <Button
          onClick={() => clickNewUser()}
          type='link'
          shape='round'
          className='text-success hover:text-success-lighter'
          size='large'
          icon={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faPlus} />}
        >
          Nuevo Usuario
        </Button>
      </div>
      {!loading && (
        <>
          <ModalRole visible={modalNewRole} />
          <ModalUser visible={modalNewUser || modalEditUser} />
          <ModalDelete visible={modalOpenDelete} />
        </>
      )}
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={getDataForTable()}
        pagination={getPagination()}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TableUsers;
