import gql from "graphql-tag";

export default () => {
  const schema = `query appointmentById($id: String!) {
            appointmentById(id: $id) {
                id
                status
                expiresAt
            }
            }
        `;
  return gql`
    ${schema}
  `;
};
