export const SET_INITIAL_STATE = "SETINITIALSTATE";

export const SET_MODAL_INFO = "SETMODALINFO";
export const SET_MODAL_ACEPTAR = "SETMODALACEPTAR";
export const SET_MODAL_RECHAZAR = "SETMODALRECHAZAR";
export const SET_CLOSE_MODAL = "SETCLOSEMODAL";

export const SET_RANDOM_VALUE_FOR_QUERY = "SETRANDOMVALUEFORQUERY";

export const SET_INFO_DOCTOR = "SETINFODOCTOR";
