import React, { useEffect } from "react";

import { Form, Button, notification } from "antd";

// componets
import InputForm from "./InputForm";
import getInputList from "./InputList";

const FormCreateUser = ({
  title,
  data,
  loading,
  error,
  onFinish,
  roles,
  success,
  successPost,
  edit,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (error && !success && !loading) {
      notification.destroy();
      notification.error({
        message: "Error",
        description: error.graphQLErrors[0].message,
      });
    }
    if (success) {
      notification.success({
        message: "Exito!",
      });
      successPost();
    }
  }, [data, success, error, loading, successPost]);

  useEffect(() => {
    if (data.edit) {
      form.setFields([
        { name: "firstName", value: data.firstName, errors: null },
        { name: "lastName", value: data.lastName, errors: null },
        { name: "email", value: data.email, errors: null },
        { name: "phoneNumber", value: data.phoneNumber, errors: null },
        { name: "role", value: data.role?.id, errors: null },
      ]);
    }
  }, [data, form]);

  const validateOnFinish = (values) => {
    form.validateFields().then((values) => {
      onFinish(values);
    });
  };

  const disabledState = !!loading;
  const InputList = getInputList(edit);

  return (
    <div className='w-full lg:w-full'>
      <h3 className='mt-3 mb-8 text-2xl'>
        <strong>{title}</strong>
      </h3>
      <Form
        form={form}
        layout='horizontal'
        validateTrigger='onBlur'
        name='basic'
        initialValues={{ remember: true }}
        onFinish={(valueForm) => validateOnFinish(valueForm)}
      >
        <div className='grid grid-cols-12 gap-2'>
          <div className='col-span-12 md:col-span-6 lg:col-span-6'>
            <InputForm
              item={{ ...InputList.firstName.item }}
              input={{
                ...InputList.firstName.input,
                disabled: disabledState,
              }}
            />
          </div>
          <div className='col-span-12 md:col-span-6 lg:col-span-6'>
            <InputForm
              item={{ ...InputList.lastName.item }}
              input={{
                ...InputList.lastName.input,
                disabled: disabledState,
              }}
            />
          </div>
          <div className='col-span-12 md:col-span-6'>
            <InputForm
              item={{ ...InputList.email.item }}
              input={{
                ...InputList.email.input,
                disabled: disabledState,
              }}
            />
          </div>
          <div className='col-span-12 md:col-span-6'>
            <InputForm
              item={{ ...InputList.phoneNumber.item }}
              input={{
                ...InputList.phoneNumber.input,
                disabled: disabledState,
              }}
            />
          </div>
          <div className='col-span-12 md:col-span-6'>
            <InputForm
              typeInput='password'
              item={{ ...InputList.password.item }}
              input={{
                ...InputList.password.input,
                disabled: disabledState,
              }}
            />
          </div>
          <div className='col-span-12 md:col-span-6'>
            <InputForm
              typeInput='select'
              seeTooltip={false}
              item={{ ...InputList.role.item }}
              select={{
                ...InputList.role.select,
                disabled: disabledState,
              }}
              options={roles}
            />
          </div>
          <div className='col-span-12 flex flex-col md:flex-row'>
            <div className='flex flex-col'>
              <div className='flex xs:flex-col md:flex-row justify-between col-span-6'>
                <Form.Item className=''>
                  <Button
                    disabled={loading}
                    loading={loading}
                    htmlType='submit'
                    type='primary'
                    shape='round'
                    size='large'
                    className='xs:mx-auto md:mx-4'
                  >
                    {data.edit ? "Editar cuenta" : "Crear cuenta"}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

FormCreateUser.defaultProps = {
  buttonLogin: false,
  redirect: true,
  clickLoginButton: () => {},
};

export default React.memo(FormCreateUser);
