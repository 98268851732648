import React from "react";
import classes from "classnames/bind";
// antd
import Card from "antd/lib/card";
import Skeleton from "antd/lib/skeleton";
// styles
import style from "./style.module.scss";

const cx = classes.bind(style);

export default function SkeletonPersonCard() {
  const propsSkeleton = {
    avatar: true,
    loading: true,
  };

  const propsCard = {
    title: <Skeleton {...propsSkeleton} />,
    bordered: false,
    className: cx("card", "skeleton"),
    bodyStyle: { display: "none" },
  };

  return <Card {...propsCard} />;
}
