import React from "react";
import CuentaRegresiva from "../../../components/CitasUtils/CuentaRegresiva";
import BrandLoading from "../../../components/CitasUtils/BrandLoading/brand";

import BrowserValidator from "./browserValidator";

import moment from "moment";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
require("moment/min/locales.min");
moment.locale("es");

const EstadoCitaPacienteNormal = (props) => {
  const { isInAppointment, isBefore, isAfter, infoAppointment } = props;

  const MessageAppointment = () => {
    if (isBefore) {
      return (
        <div className='flex flex-col'>
          <BrowserValidator />
          <p className='break-words font-bold text-xl text-primary-hoverDark text-center mt-2 mb-0'>
            La consulta iniciara en:
          </p>
          <CuentaRegresiva date={infoAppointment.appointmentDateAndTime} />
        </div>
      );
    }
    if (isInAppointment) {
      return (
        <div className='flex flex-col'>
          <BrowserValidator />
          <p className='break-words font-bold text-xl mb-1 text-primary-hoverDark text-center'>
            Su cita comenzará en unos momentos.{" "}
          </p>
          <p className='break-words font-bold text-xl mb-1 text-primary-hoverDark text-center'>
            Por favor espere ...{" "}
          </p>
        </div>
      );
    }
    if (isAfter) {
      return (
        <div className='flex flex-col'>
          <p className='break-words font-bold text-xl mb-1 text-primary-hoverDark text-center'>
            La cita ha concluido
          </p>
          <p className='break-words font-bold text-xl mb-1 text-primary-hoverDark text-center'>
            Gracias por tu tiempo!
          </p>
        </div>
      );
    }
  };

  return (
    <div className='flex flex-col h-full w-full'>
      {isAfter && (
        <Link
          to='/patient'
          className='text-danger hover:text-danger-hover break-words mx-auto mt-auto'
        >
          <Button type='default' shape='round' size='large' danger className='mx-auto'>
            <FontAwesomeIcon icon={faUser} className='mr-2 hover:text-danger-hover' />
            Ir a mi perfil
          </Button>
        </Link>
      )}
      <div className='mx-auto my-auto h-auto w-2/3 justify-center'>
        <div className='bg-transparent mb-3 flex justify-center'>
          <BrandLoading />
        </div>
        <MessageAppointment {...props} />
      </div>
    </div>
  );
};

export default EstadoCitaPacienteNormal;
