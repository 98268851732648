import React from "react";
// antd
import Avatar from "antd/lib/avatar";
// style
import style from "./style.module.scss";
// icons
import { faUserMd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";

const Cover = ({ avatar }) => {
  const propsAvatar = {
    src: avatar,
    size: 160,
    icon: <Fa icon={faUserMd} />,
    style: { backgroundColor: "white", color: "#29AAE1" },
    className: style.avatar,
  };
  return <Avatar {...propsAvatar} />;
};

export default Cover;
