import React, { useContext } from "react";
// antd
import Card from "antd/lib/card";
import Divider from "antd/lib/divider";
import notification from "antd/lib/notification";
import { useForm } from "antd/es/form/Form";
// components
import FormCode from "views/Patient/Tabs/Affiliation/Code/Form";
import { useMutation } from "@apollo/react-hooks";
import { muteMeAffiliateInfo } from "views/Patient/gql";
import * as actionName from "../../../../../store/actions";
// uitil
import { HandleError } from "utils.js/HandleError";
// context
import { GlobalContext } from "views/App";

const CardCode = (_) => {
  const [form] = useForm();
  const { state, dispatch } = useContext(GlobalContext);
  const { enabled, idUser } = _;
  const [fechCode, { loading }] = useMutation(muteMeAffiliateInfo);

  const onFinish = ({ code }) => {
    fechCode({ variables: { user: idUser, code } })
      .then((reqValues) => {
        const { useCode } = reqValues.data;
        if (useCode) {
          dispatch({
            type: actionName.SET_USER,
            payload: {
              ...state.user,
              belongsToCompany: useCode,
            },
          });
          form.resetFields();
          notification.success({
            message: "Felicidades te has afiliado con éxito!",
          });
        } else {
          form.resetFields();
          notification.error({
            message: "Error al afiliarse!",
          });
        }
      })
      .catch((e) => {
        form.resetFields();
        notification.error({
          message: "Error",
          description: HandleError(e),
        });
      });
  };

  const props = {
    Card: {
      title: "Código de afiliación",
    },
    Form: {
      form,
      onFinish,
      name: "Code",
      validateTrigger: "onBlur",
      hideRequiredMark: true,
    },
    stateButton: {
      loading,
      enabled,
    },
    p: {
      children: "Ingresa el código de afiliación y disfruta de tus beneficios!",
    },
  };

  return (
    <Card {...props.Card}>
      <p {...props.p} />
      <Divider dashed />
      <FormCode stateButton={{ ...props.stateButton }} form={{ ...props.Form }} />
    </Card>
  );
};

export default CardCode;
