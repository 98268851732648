import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router";
import { companyByIdGql } from "./gqlTags";

// utils
import get from "lodash/get";

// components
import LoadingFirts from "../CompanyCodes/loadingFirts";
import Charts from "./charts/index";
import View404Router from "../../../components/View404Router";

// global Context
import { GlobalContext } from "views/App";

const getIdUrl = (globalState, props) => {
  const role = get(globalState, ["user", "role", "name"], "");
  const id =
    role === "root"
      ? get(props, ["match", "params", "id"], "_")
      : get(globalState, ["user", "id"], "");
  return {
    id,
    role,
  };
};

const CompanyCharts = (props) => {
  const { state: globalState } = useContext(GlobalContext);
  const [idCompany] = useState(getIdUrl(globalState, props).id);

  const { data, loading, error } = useQuery(companyByIdGql, {
    variables: {
      id: idCompany,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const validateRender = () => {
    if (!data && !error) {
      return <LoadingFirts />;
    }
    if (!loading && error) {
      return <View404Router />;
    }
    return <Charts data={data.companyById} id={idCompany} />;
  };

  return <React.Fragment>{validateRender()}</React.Fragment>;
};

export default withRouter(CompanyCharts);
