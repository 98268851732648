import React, { useContext } from "react";
import { SET_INITIAL_STATE } from "../store/actions";
import { Modal, notification } from "antd";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { createCompany, getPlansPrice } from "../gqlTags";

import { ContextTablaEmpresas } from "../index";
import FormCrearEmpresa from "../../../../components/FormCrearEmpresa";
import { HandleError } from "../../../../utils.js/HandleError";

const ModalNew = ({ visible }) => {
  const { state, dispatch } = useContext(ContextTablaEmpresas);

  const afterClose = () => {
    dispatch({ type: SET_INITIAL_STATE });
  };

  const refetchDoctors = () => {
    dispatch({ type: SET_INITIAL_STATE });
    state.refetch();
  };

  return (
    <Modal
      title='Agregar nueva empresa afiliada'
      style={{
        minWidth: "20rem",
      }}
      visible={visible}
      destroyOnClose
      width='50vw'
      onCancel={afterClose}
      footer={null}
      afterClose={afterClose}
      maskClosable={false}
    >
      {visible ? <BodyModalNew closeAndRefetch={refetchDoctors} /> : null}
    </Modal>
  );
};

const BodyModalNew = ({ closeAndRefetch }) => {
  const Queries = () => {
    const queryA = useMutation(createCompany, { fetchPolicy: "no-cache" });
    const queryB = useQuery(getPlansPrice);
    return [queryA, queryB];
  };

  const [
    [fetchSignupCompany, { loading, error, data }],
    { loading: loadingPlans, error: errorPlans, data: dataPlans },
  ] = Queries();

  const onFinish = async (values) => {
    /// values.userCap = parseFloat(values.userCap)
    values = {
      ...values,
      userCap: 0,
    };

    if (values.description === undefined) {
      values.description = "";
    }
    if (values.phoneNumber) values.phoneNumber = String(values.phoneNumber);

    try {
      await fetchSignupCompany({ variables: { ...values } });
    } catch (e) {
      notification.error({
        message: "Internal server error ",
        description: HandleError(e),
      });
    }
  };

  return (
    <FormCrearEmpresa
      loading={loading && loadingPlans}
      data={data}
      plansInfo={dataPlans}
      error={error && errorPlans}
      onFinish={onFinish}
      successCreate={closeAndRefetch}
      title='Creación de nueva Empresa'
    />
  );
};

export default ModalNew;
