import React, { useRef, useEffect, useState, Children } from "react";
// antd
import Grid from "antd/lib/grid";
import Carousel from "antd/lib/carousel";
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function CarouselMD({ children }) {
  const cards = Children.count(children);
  const refCarousel = useRef();
  const [screensActive, setScreensActive] = useState(null);

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
    for (const Breakpoint in screens) {
      if (screens[Breakpoint] === true) {
        setScreensActive(Breakpoint);
      }
    }
  }, [screens]);

  const visibleFooter = () => {
    switch (screensActive) {
      case "md":
        return cards > 1;

      case "lg":
        return !cards <= 2;

      case "xl":
      case "xxl":
        return cards > 3;

      default:
        return true;
    }
  };

  const ShowCard = () => {
    switch (screensActive) {
      case "md":
      case "lg":
        return cards >= 2 ? 2 : 1;

      case "xl":
      case "xxl":
        return cards >= 3 ? 3 : cards;

      default:
        return 1;
    }
  };

  const props = {
    children,
    ref: refCarousel,
    dots: false,
    autoplay: false,
    dotPosition: null,
    slidesToShow: ShowCard(),
  };

  const footerCarousel = (
    <div className='flex flex-row lg:mt-4 justify-center items-center'>
      <div
        className='rounded-full border border-success bg-white hover:bg-success py-2 px-3 mx-2 cursor-pointer text-success hover:text-white'
        onClick={() => refCarousel.current.prev()}
      >
        <FontAwesomeIcon className='md:text-2xl lg:text-3xl' icon={faArrowLeft} />
      </div>
      <div
        className='rounded-full border border-success bg-white hover:bg-success py-2 px-3 mx-2 cursor-pointer text-success hover:text-white'
        onClick={() => refCarousel.current.next()}
      >
        <FontAwesomeIcon className='md:text-2xl lg:text-3xl' icon={faArrowRight} />
      </div>
    </div>
  );

  if (children) {
    return (
      <>
        <Carousel {...props} />
        {visibleFooter() && footerCarousel}
      </>
    );
  }

  return (
    <>
      <Carousel dots={false} ref={refCarousel} {...props}>
        <div className='bg-primary h-56'>
          <h3 className='text-center text-5xl'>1</h3>
        </div>
        <div className='bg-success h-56'>
          <h3 className='text-center text-5xl'>2</h3>
        </div>
        <div className='bg-danger h-56'>
          <h3 className='text-center text-5xl'>3</h3>
        </div>
        <div className='bg-warning h-56'>
          <h3 className='text-center text-5xl'>4</h3>
        </div>
        <div className='bg-warning-dark h-56'>
          <h3 className='text-center text-5xl'>5</h3>
        </div>
        <div className='bg-indigo-700 h-56'>
          <h3 className='text-center text-5xl'>6</h3>
        </div>
      </Carousel>
      {footerCarousel}
    </>
  );
}
