import React, { useState } from "react";
import { Card, notification } from "antd";
import {
  symptoms,
  deleteSymptomById,
  symptomById,
  updateSymptom,
  createSymptom,
} from "../gqlAdmin";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { BasicTable } from "components/TableEasyMD";
import { isNumber, isString } from "lodash";

const Sintomas = () => {
  const [variables, setVariables] = useState({
    perPage: 5,
    page: 1,
    filters: [],
  });

  const { data, loading, error } = useQuery(symptoms, {
    variables: { ...variables },
    fetchPolicy: "no-cache",
  });
  const [deleteSymptom, reqDeleteSymptom] = useMutation(deleteSymptomById, {
    refetchQueries: ["symptoms"],
  });
  const [updateSintomas, reqUpdateSintomas] = useMutation(updateSymptom, {
    refetchQueries: ["symptoms"],
  });
  const [addSintomas, reqAddSintomas] = useMutation(createSymptom, {
    refetchQueries: ["symptoms"],
  });

  const changePage = (page) => {
    if (page && isNumber(page)) {
      setVariables({
        ...variables,
        page,
      });
    } else {
      notification.error({
        message: "Error",
        description: "Índice de página invalido",
      });
    }
  };

  const onSearch = (value) => {
    if (isString(value)) {
      setVariables({
        ...variables,
        filters: [
          {
            byText: { field: "NAME", value: value },
          },
        ],
      });
    }
  };

  const DeleteMedicine = (id) => {
    try {
      setVariables({
        perPage: 5,
        page: 1,
        filters: [],
      });
      return deleteSymptom({ variables: { id: id } });
    } catch (e) {
      console.log(e);
    }
  };

  const onEdit = (Item) => {
    try {
      const { idItem: id, descriptionItem: description, nameItem: name } = Item;
      return updateSintomas({ variables: { id, description, name } });
    } catch (e) {
      console.log(e);
    }
  };

  const onAddItem = (values) => {
    try {
      return addSintomas({ variables: { bulkNames: values } });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className='m-3 p-3 w-full mx-auto md:w-3/5'>
      <h2 className='text-2xl'>
        <strong>Síntomas</strong>
      </h2>
      <div className='mt-5'>
        <Card bodyStyle={{ padding: "0.5rem" }} title={null}>
          <BasicTable
            erroraddItem={reqAddSintomas.error}
            onAddItem={onAddItem}
            EditItemSubmit={onEdit}
            reqUpdateItem={reqUpdateSintomas.error}
            gqlFindItem={symptomById}
            errorDelete={reqDeleteSymptom.error}
            error={error}
            changePage={changePage}
            deleteItem={DeleteMedicine}
            loading={loading}
            data={data}
            plaseholdeAlertDelete='Seguro que quieres eliminar este Síntoma?'
            plaseholderSearch='Nombre del Síntoma'
            onSearchTable={onSearch}
            path='symptoms'
            pathId='symptomById'
          />
        </Card>
      </div>
    </div>
  );
};

export default Sintomas;
