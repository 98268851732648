import React, { useReducer, useEffect, createContext } from "react";
import { useLocation } from "react-router-dom";
import Card from "antd/lib/card";

import TableAffiliates from "./TableAffiliates";

// store
import reducer from "./store/reducerStore";
import initialState from "./store/initialState";
import * as actionName from "./store/actionStore";

// create context Store
export const AffiliatesContext = createContext();

const Affiliates = () => {
  const [stateLocal, dispatch] = useReducer(reducer, initialState);
  const filterWithPlan = useLocation().state?.plan;
  useEffect(() => {
    filterWithPlan &&
      dispatch({
        type: actionName.SET_PLANS,
        payload: new Array(filterWithPlan),
      });
  }, [filterWithPlan]);

  return (
    <AffiliatesContext.Provider value={{ state: stateLocal, dispatch: dispatch }}>
      <div className='w-full mx-auto min-h-25rem p-6 '>
        <p className='mt-3 text-2xl font-bold mb-0'>Afiliaciones</p>
        <Card className='mt-5' bodyStyle={{ padding: "0.5rem" }} title={null}>
          <TableAffiliates />
        </Card>
      </div>
    </AffiliatesContext.Provider>
  );
};

export default Affiliates;
