import React, { useState, useEffect, useCallback /* useContext*/ } from "react";
import Lottie from "react-lottie";
import sigNupAnimation from "../../../assets/lottie/signup.json";
import sigNupAnimationDoctor from "../../../assets/lottie/doctor-sign-in.json";

// import { GlobalContext } from "views/App";

import FormSignUp from "../../../components/FormCrearCuenta";
import DotorSignUp from "../../Medic/CrearCuenta";

import { Spin, Button } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { createPatient } from "./gql";
import get from "lodash/get";
import { getDecoded, useCouponCode } from "utils.js/getDecoded";
import { findPhoneNumbersInText, isValidPhoneNumber } from "libphonenumber-js";
import { upperCase } from "lodash";

import { AnimateOnChange } from "react-animation";

const SignUp = (props) => {
  const [hashInfo, setHashInfo] = useState(null);
  const [verifyHash, setVerifyHash] = useState(false);
  const [focusButton, setFocusButton] = useState("paciente");
  const [benefactor, setBenefactor] = useState(null);
  // const { state, dispatch } = useContext(GlobalContext);
  // const { location } = state;
  // const isNicaragua = location === "Nicaragua";
  // const isPanama = location === "Panama";

  const getInfoHash = useCallback(() => {
    const hashCode = get(props, ["match", "params", "id"], null);
    if (hashCode) {
      if (hashCode.length > 30 && String(hashCode).slice(0, 1).includes("e")) {
        const sliceKey = hashCode.slice(0, 11).slice(0, -1); // key for decode, first 10 characters
        const sliceHash = hashCode.slice(11, hashCode.length); // true hash with info
        getDecoded(sliceHash, sliceKey).then((values) => {
          setHashInfo(values);
          values.id && setBenefactor(values.id);
        });
      }
    }
    setVerifyHash(true);
  }, [props]);

  useEffect(() => {
    getInfoHash();
  }, [getInfoHash]);

  useCouponCode(props.match);

  const defaultOptionsAnimation = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [fetchCreatePatient, { loading, error, data }] = useMutation(createPatient, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const getValidPhoneNumber = (phoneNumber, nationality) => {
    if (!phoneNumber) {
      return null;
    }
    const isoCountry = nationality === "PANAMA" ? "PA" : "NI";
    const validNicaPhone = isValidPhoneNumber(phoneNumber, isoCountry);
    return validNicaPhone
      ? findPhoneNumbersInText(phoneNumber, isoCountry)[0].number.nationalNumber
      : phoneNumber;
  };

  const createPatientMutation = async (values) => {
    try {
      const { phoneNumber, ...restValues } = values;
      await fetchCreatePatient({
        variables: {
          phoneNumber,
          benefactor,
          email: values.email ? values.email : "505" + phoneNumber + "@alianzaTigoEasyMD.com",
          ...restValues,
        },
      });
    } catch (e) {
      console.log("error createUser");
    }
  };

  const onFinish = async (values) => {
    if (!loading) {
      const { phoneNumber, nationality } = values;
      values.phoneNumber = getValidPhoneNumber(phoneNumber, nationality);
      values.nationality =
        values.nationality === "El Salvador" ? "EL_SALVADOR" : upperCase(values.nationality);
      await createPatientMutation(values);
    }
  };

  const formSignUpProps = {
    userType: focusButton,
    hashInfo: hashInfo,
    onFinish: onFinish,
    loading: loading,
    data,
    error,
    title: "Bienvenido a EasyMD",
    description: "Conectate con tus proveedores de salud en menos de 5 min estes donde estes.",
    // isNicaragua,
    // isPanama,
    // dispatch,
  };

  const doctorSignUpProps = {
    // isNicaragua,
    // isPanama,
    // dispatch,
  };

  const renderComponent = () => {
    if (focusButton === "paciente") {
      return !verifyHash ? (
        <div
          style={{ minWidth: "22rem", width: "32rem" }}
          className='h-20 md:h-auto my-8 lg:my-0 flex justify-center'
        >
          <Spin size='large' className='my-5 mx-5' />
        </div>
      ) : (
        <FormSignUp {...formSignUpProps} />
      );
    } else {
      return <DotorSignUp {...doctorSignUpProps} />;
    }
  };

  return (
    <div
      className={`
        pt-12
        flex items-center justify-center mx-auto mb-10
         xs:h-auto xs:px-5
      `}
    >
      <div className='flex flex-col md:gap-0 lg:flex-row xl:gap-20'>
        <div className='self-center'>
          <AnimateOnChange durationOut='500'>
            {focusButton === "paciente" ? (
              <Lottie
                isClickToPauseDisabled
                options={{
                  ...defaultOptionsAnimation,
                  animationData: sigNupAnimation,
                }}
                height='520px'
              />
            ) : (
              <Lottie
                isClickToPauseDisabled
                options={{
                  ...defaultOptionsAnimation,
                  animationData: sigNupAnimationDoctor,
                }}
                height='520px'
              />
            )}
          </AnimateOnChange>
        </div>
        <div className='flex flex-col justify-start items-start'>
          <div className='flex justify-start items-center'>
            <Button
              className={`border-none p-0 ${
                focusButton === "paciente"
                  ? "text-primary font-bold"
                  : "text-info-hoverDark font-light"
              }`}
              type='link'
              size='large'
              onClick={() => setFocusButton("paciente")}
            >
              Soy Paciente
            </Button>
            <span className='mx-4'>|</span>
            <Button
              className={`border-none p-0 ${
                focusButton === "doctor"
                  ? "text-primary font-bold"
                  : "text-info-hoverDark font-light"
              }`}
              type='link'
              size='large'
              onClick={() => setFocusButton("doctor")}
            >
              Soy Doctor
            </Button>
          </div>
          {renderComponent()}
        </div>
      </div>
    </div>
  );
};

export default React.memo(SignUp);
