import React, { useState } from "react";
import { DatePicker } from "antd";

import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
require("moment/min/locales.min");
moment.locale("es");

const { RangePicker } = DatePicker;

export const getDefaultDates = (isArray = false, current = false) => {
  if (isArray) {
    return [
      moment()
        .subtract(current ? 0 : 30, "days")
        .startOf("month"),
      moment()
        .subtract(current ? 0 : 30, "days")
        .endOf("month"),
    ];
  }
  return {
    from: moment()
      .subtract(current ? 0 : 30, "days")
      .startOf(current ? "month" : "day"),
    to: moment()
      .subtract(current ? 0 : 30, "days")
      .endOf("month"),
  };
};

const SelectorRange = (props) => {
  const [current, setCurrent] = useState(getDefaultDates(true, props.current));

  const disabledDate = (current) => {
    const logsDate = moment(props.disabledDates ? "2021-04-01" : "2020-01-01");
    const currentAdd30 = moment().add(1, "month").startOf("month");
    return current < logsDate || current > currentAdd30;
  };

  const rangesDates = () => {
    return {
      "Últimos 30 días": [moment().subtract(30, "days"), moment()],
      "Mes anterior": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
      "Mes actual": [moment().startOf("month"), moment().endOf("month")],
    };
  };

  const handleOnChange = (value) => {
    if (value && value instanceof Array) {
      setCurrent(value);
    } else {
      setCurrent(null);
    }
    if (props.onChange instanceof Function) {
      props.onChange({
        from: !value ? null : value[0].format("YYYY-MM-DD"),
        to: !value ? null : value[1].format("YYYY-MM-DD"),
      });
    }
  };

  return (
    <RangePicker
      defaultValue={getDefaultDates(true, props.current)}
      value={current}
      ranges={rangesDates()}
      onChange={handleOnChange}
      locale={locale}
      format='DD-MMMM-YYYY'
      disabledDate={disabledDate}
      disabled={props.disabled}
      loading={props.loading}
      showNow
      allowClear={false}
    />
  );
};

SelectorRange.defaultProps = {
  minWidth: "220px",
  maxWidth: "300px",
  loading: false,
  disabled: false,
  disabledDates: true,
};

export default SelectorRange;
