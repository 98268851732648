import React, { useState, useEffect } from "react";

import MobileComponent from "./mobileComponet";
import FullScreenComponent from "./fullScreenComponent";
import ModalPlan from "../ModalPlan";

import { withRouter } from "react-router-dom";

import { Grid } from "antd";
const { useBreakpoint } = Grid;

const PlanAndFeatures = (props) => {
  const screens = useBreakpoint();
  const isMobile = (screens.xs || screens.sm || screens.md) && !screens.lg;

  const [infoModal, setInfoModal] = useState({
    visible: false,
    frequency: null,
    planId: null,
  });

  useEffect(() => {
    if (props.location && props.location.state?.id) {
      const { state: stateRouter } = props.location;
      openModalAndSetInfo({
        visible: true,
        frequency: stateRouter.frequency,
        planId: stateRouter.id,
      });
      localStorage.setItem("mobileNotification", true);
    }
  }, [props.location]);

  const openModalAndSetInfo = ({ visible = false, frequency = null, planId = null }) => {
    setInfoModal({
      visible,
      frequency,
      planId,
    });
  };

  if (isMobile === undefined) {
    return null;
  }

  return (
    <React.Fragment>
      {infoModal.visible && (
        <ModalPlan
          dataPlan={{
            frequency: infoModal.frequency,
            planId: infoModal.planId,
          }}
          closeModal={() => openModalAndSetInfo({ visible: false, frequency: null, planId: null })}
        />
      )}
      {isMobile ? (
        <MobileComponent {...props} openModal={openModalAndSetInfo} />
      ) : (
        <FullScreenComponent {...props} openModal={openModalAndSetInfo} />
      )}
    </React.Fragment>
  );
};

export default React.memo(withRouter(PlanAndFeatures));
