const initialState = {
  fetchInfoPacientLoading: false,
  idAppointment: "",
  idDoctor: "",
  appointmentById: null,
  isSubmitMedia: false,
  paciente: null,
  razonCita: null,
  sintomas: null,
  adjuntos: [],
  medicamentos: null,
  alergias: null,
  condicionesMedicas: null,
  creandoCitaLoading: false,
  citaAgendadaConExito: false,
  metodoDePago: {
    propio: false, // puede ser un metodo de pago preGuardado en el perfil u otra tarjeta que no se guardara
    infoPropio: null, // info del token id
    infoOther: null, // info encriptada
    tabActiva: 1,
    cardNumber: null,
    isFullOther: false,
  },
  savePaymentMethod: false,
};

export default initialState;
