import React from "react";
import { Col, Empty, Row, Tag } from "antd";
import Avatar from "antd/lib/avatar";
import { ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment";

const InfoPaciente = (props) => {
  const { infoAppointment } = props;
  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} sm={12} md={8}>
        <p className='break-words font-bold text-lg text-success mb-1'>Fecha y hora:</p>
        <p className='break-words font-semibold text-lg text-primary mb-1'>
          <ClockCircleOutlined className='text-2xl text-primary' />{" "}
          {moment(infoAppointment.appointmentDateAndTime).format("LLLL a")}
        </p>
      </Col>
      <Col xs={24} sm={12} md={6} className='px-3'>
        <p className='break-words font-bold text-lg text-success mb-1'>Razón de la cita:</p>
        <p className='break-words font-semibold text-lg text-primary mb-1'>
          {infoAppointment.reasonForConsultation}
        </p>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <p className='break-words font-bold text-lg text-success mb-1'>Síntomas:</p>
        {infoAppointment.newSymptoms.length < 1 ? (
          <Empty description={false} />
        ) : (
          infoAppointment.newSymptoms.map((value, index) => {
            return (
              <Tag
                key={index}
                style={{ backgroundColor: "#ECF1FF" }}
                title={value}
                className='my-1 text-black-hover rounded-full text-base h-auto py-1 hover:text-primary truncate'
              >
                <strong className='text-xs'>{value}</strong>
              </Tag>
            );
          })
        )}
      </Col>
      <Col xs={24} sm={12} md={4}>
        <p className='break-words font-bold text-lg text-success mb-1'>Doctor:</p>
        <div className='flex flex-col justify-center'>
          <Avatar
            className='mx-auto'
            size={80}
            icon={<img src={infoAppointment.doctor ? infoAppointment.doctor.pictureURL.url : ""} />}
          />
          <p className='break-words font-semibold text-lg text-primary mb-1 text-center'>
            {infoAppointment.doctor ? infoAppointment.doctor.fullName : ""}
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default InfoPaciente;
