const initialState = {
  listDoctors: [],
  totalData: 0,
  isScrollFetch: false,
  page: 1,
  perPage: 25,
  typeFilters: {
    byName: null,
    byStarts: { byNumber: { field: "RATING", greaterOrEqualThan: 0 } },
    bySpeciality: null,
    byLanguaje: null,
    byPacient: null,
    byOnline: null,
  },
  defaultValuesSelect: {
    byLanguaje: null,
  },
  filter: [
    {
      byText: {
        field: "APPROVALSTATUS",
        value: "approved",
      },
    },
  ],
};

export default initialState;
