import React, { useState, useEffect, useContext } from "react";
import { Table, Input, notification, Button } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { companysListGql } from "./gqlTags";
import columns from "./columnsTable";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

// components
import ModalNewDoctor from "./modales/modalNew";
import ModalDetail from "./modales/modalDetail";
import ModalDelete from "./modales/modalDelete";

// context
import { ContextTablaEmpresas } from "./index";
import { SET_MODAL_NEW_OPEN, SET_INITIAL_STATE, SET_REFETCH } from "./store/actions";

const TableDoctor = () => {
  const { state, dispatch } = useContext(ContextTablaEmpresas);
  const [paginationVars, setPaginationVars] = useState({
    perPage: 10,
    page: 1,
  });
  const [nombreBuscar, setNombreBuscar] = useState("");
  const [filterDoctor, setFilterDoctor] = useState([]);

  const { data, loading, error, refetch } = useQuery(companysListGql, {
    variables: {
      ...paginationVars,
      filters: filterDoctor,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    dispatch({
      type: SET_REFETCH,
      payload: refetch,
    });
  }, [dispatch, refetch]);

  useEffect(() => {
    const newFilter = [];
    if (nombreBuscar.length > 0) {
      newFilter.push({
        byText: {
          field: "FIRSTNAME",
          value: nombreBuscar,
          or: "LASTNAME",
        },
      });
    }
    setFilterDoctor(newFilter);
    setPaginationVars({
      perPage: 10,
      page: 1,
    });
  }, [nombreBuscar]);

  useEffect(() => {
    if (error && !loading) {
      notification.error({
        message: "Error",
        description: error.graphQLErrors[0]
          ? error.graphQLErrors[0].message
          : "Error al solicitar data",
      });
    }
  }, [error, loading]);

  const getDataForTable = () => {
    if (data) {
      return data.companys.docs;
    } else {
      return [];
    }
  };

  const getPagination = () => {
    return {
      current: paginationVars.page,
      pageSize: paginationVars.perPage,
      total: data ? data.companys.total : 0,
    };
  };

  const handleTableChange = (infoTable) => {
    const { current } = infoTable;
    setPaginationVars({
      ...paginationVars,
      page: current,
    });
  };

  const clickNew = () => {
    dispatch({
      type: SET_INITIAL_STATE,
    });
    dispatch({
      type: SET_MODAL_NEW_OPEN,
      payload: true,
    });
  };

  return (
    <>
      <div className='w-full flex xs:flex-col sm:flex-row mb-3'>
        <Input
          className={"w-full mx-auto sm:mx-0 " + styles.max250px}
          placeholder='Buscar por nombre'
          value={nombreBuscar}
          onChange={(e) => setNombreBuscar(e.target.value)}
        />
        <Button
          onClick={() => clickNew()}
          type='link'
          shape='round'
          className='text-success hover:text-success-lighter ml-auto'
          size='large'
          icon={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faPlus} />}
        >
          Nueva Empresa
        </Button>
      </div>
      {!loading && (
        <>
          <ModalNewDoctor visible={state.modalNewEmpresa} />
          <ModalDetail visible={state.modalOpenInfo} />
          <ModalDelete visible={state.modalOpenDeny} />
        </>
      )}
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={getDataForTable()}
        pagination={getPagination()}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TableDoctor;
