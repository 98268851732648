import React from "react";
import Switch from "antd/lib/switch";
import Typography from "antd/lib/typography";
import propsStatic from "../propsStatic";
import { Space } from "antd";

const { Title } = Typography;

function Age(_props) {
  const switchChange = (checked) => {
    _props.updateAge();
  };

  const props = {
    Switch: {
      onChange: switchChange,
    },
    Title: {
      level: 4,
      style: { opacity: `${_props.underAge ? 0.5 : 1}` },
    },
  };

  return (
    <>
      <Space {...propsStatic.Age}>
        <Switch defaultChecked {...props.Switch} />
        <Title {...props.Title}>Mayor de Edad</Title>
      </Space>
    </>
  );
}

export default Age;
