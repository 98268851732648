import React, { useState, useEffect } from "react";

// ant
import { Modal, Button, Tabs, Upload, notification } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { HandleError } from "utils.js/HandleError";

// canvas
import SignatureCanvas from "react-signature-canvas";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";

// graphql
import { useMutation } from "@apollo/react-hooks";
import { updateMedicSignature, updateSignatureMedicMD } from "../../gqlTags";

// hooks
import useMedia from "hooks/useMedia";

const breakpoint = [1600, 1200, 960, 768];
const minWidth = [750, 550, 450, 400];
const minHeight = [450, 350, 350, 300];

const ModalCambiarFirma = (props) => {
  const queries = breakpoint.map((w) => `(min-width: ${w}px)`);
  const [sigPad, setSigPad] = useState({});
  const [fileList, setFileList] = useState([]);
  const [tabsActive, setTabsActive] = useState("CrearFirma");
  const { Dragger } = Upload;
  const [updateSignature, { data, error, loading }] = useMutation(
    props.dataMedic.role === "doctor" ? updateMedicSignature : updateSignatureMedicMD,
    { fetchPolicy: "no-cache" }
  );

  const widthCan = useMedia(queries, minWidth, 290);
  const heightCan = useMedia(queries, minHeight, 300);

  useEffect(() => {
    if (data) {
      notification.success({
        message: "La firma a sido actualizada",
      });
      props.setModalVisible(false);
    }

    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }

    if (loading) {
      props.setLoadingSignature(loading);
    } else {
      props.setLoadingSignature(false);
    }
  }, [error, data, loading, props]);

  const propsUpload = {
    accept: "image/x-png,image/jpeg",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      return setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const image = new Image();
      image.src = window.URL.createObjectURL(file);
      if (fileList.length === 0) {
        if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
          image.onload = () => {
            if (image.width <= 800 && image.height <= 500) {
              setFileList([...fileList, file]);
            } else {
              notification.error({
                message: "Error",
                description:
                  "La firma es demasiado grande, la firma no puede ser mayor a 800 x 500 px",
              });
            }
          };
        } else {
          notification.error({
            message: "Error",
            description: "El formato de la imagen es invalido",
          });
        }
      } else {
        notification.error({
          message: "Error",
          description: "Solo puede subir una imagen por firma",
        });
      }
      return false;
    },
    fileList,
  };

  const submitModal = async () => {
    switch (tabsActive) {
      case "CrearFirma":
        {
          const { attributes } = sigPad.getTrimmedCanvas();
          const base64 = sigPad.getTrimmedCanvas().toDataURL("image/png");
          const widthCanvas = attributes[0].nodeValue;
          const heightCanvas = attributes[1].nodeValue;
          if (widthCanvas > 1 && heightCanvas > 1) {
            if (widthCanvas <= 800 && heightCanvas <= 500) {
              try {
                await updateSignature({
                  variables: {
                    base64: base64.split("data:image/png;base64,")[1],
                    signature: null,
                  },
                  refetchQueries: ["doctorById", "doctormdById"],
                });
                sigPad.clear();
              } catch (e) {
                console.log(e);
              }
            } else {
              notification.info({
                message: "La firma es demasiado grande, la firma no puede ser mayor a 800 x 500 px",
              });
            }
          } else {
            notification.info({
              message: "Por favor dibuje un firma dentro del recuadro de canvas ",
            });
          }
        }
        break;
      case "subirFirma":
        if (fileList.length) {
          try {
            await updateSignature({
              variables: {
                base64: null,
                signature: fileList[0],
              },
              refetchQueries: ["doctorById", "doctormdById"],
            });
            setFileList([]);
          } catch (e) {
            console.log(e);
          }
        } else {
          notification.info({
            message: "Por favor suba una image jpg, png, jpeg",
          });
        }
        break;
      default:
        return null;
    }
  };

  const customFooter = (
    <div className='flex justify-between '>
      <Button
        type='primary'
        shape='round'
        danger
        size='large'
        loading={loading}
        disabled={loading}
        onClick={() => props.setModalVisible(false)}
      >
        Cerrar
      </Button>
      <Button
        type='primary'
        shape='round'
        size='large'
        loading={loading}
        disabled={loading || error}
        onClick={() => submitModal()}
      >
        Aceptar
      </Button>
    </div>
  );

  return (
    <Modal
      style={{
        minWidth: "22rem",
      }}
      destroyOnClose
      className='ModalDoctor w-4/5 md:w-3/5'
      visible={props.visbleModal}
      onCancel={() => props.setModalVisible(false)}
      footer={customFooter}
      // width='50vw'
      closeIcon={<FontAwesomeIcon className='text-red-600 hover:text-primary' icon={faTimes} />}
    >
      <div className='px-5 m-2'>
        <Tabs
          // className='outline-none'
          onChange={(active) => {
            setTabsActive(active);
          }}
          defaultActiveKey={tabsActive}
          size='large'
          animated
          tabBarStyle={{
            outline: "none",
          }}
        >
          <Tabs.TabPane tab='Crear Firma' key='CrearFirma'>
            <p>
              Utiliza el canvas blanco para poder diseñar tu firma electrónica y poder firmar tus
              prescripciones y recetas de manera digital.
            </p>
            <SignatureCanvas
              penColor='black'
              canvasProps={{
                className: "border-solid border-2 border-gray-600 mx-auto",
                width: widthCan,
                height: heightCan,
              }}
              ref={(ref) => setSigPad(ref)}
            />
            <div className='my-2'>
              <Button
                className='border-none'
                type='default'
                shape='circle'
                size='middle'
                onClick={() => {
                  sigPad.clear();
                }}
                icon={
                  <FontAwesomeIcon
                    className='text-red-600 hover:text-primary mx-2'
                    icon={faTrash}
                  />
                }
              >
                Borrar Canvas
              </Button>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab='Subir Firma' key='subirFirma'>
            <p>
              Si ya tienes una firma electrónica creada en su computadora, puede adjuntarla aquí,
              recuerda que esta firma electrónica será la que utilizaremos en sus recetas y
              prescripciones electrónicas.
            </p>

            <p>El tamaño de la imagen no puede exceder 800 X 500 px</p>

            <div className='mb-4'>
              <Dragger id='uploadPicture' {...propsUpload} className='w-full '>
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>Haga click en esta área para cargar</p>
              </Dragger>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Modal>
  );
};
export default ModalCambiarFirma;
