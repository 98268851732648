import gql from "graphql-tag";

export const getMeInfo = gql`
  query getMeInfo($id: String!) {
    patientById(id: $id) {
      id
      sex
      email
      weight
      height
      lastName
      firstName
      phoneNumber
      dateOfBirth
      typeOfBlood
    }
  }
`;

export const muteMeInfo = gql`
  mutation updateMyInfoPatient(
    $id: String
    $sex: SexEnum
    $email: String
    $weight: Float
    $height: Float
    $lastName: String
    $firstName: String
    $phoneNumber: String
    $dateOfBirth: DateTime
    $typeOfBlood: typeOfBloodEnum
  ) {
    updateMyInfoPatient(
      id: $id
      sex: $sex
      email: $email
      weight: $weight
      height: $height
      lastName: $lastName
      firstName: $firstName
      dateOfBirth: $dateOfBirth
      phoneNumber: $phoneNumber
      typeOfBlood: $typeOfBlood
    ) {
      id
    }
  }
`;

export const upMePic = gql`
  mutation updateMyInfoPatient($id: String, $file: Upload) {
    updateMyInfoPatient(id: $id, picture: $file) {
      id
      pictureURL {
        id
        url
      }
    }
  }
`;

export const getMeConditions = gql`
  query patientById($id: String!) {
    patientById(id: $id) {
      id
      newDrugAllergies
      newMedicalConditions
    }
  }
`;

export const muteMeConditions = gql`
  mutation updateMyInfoPatient($newDrugAllergies: [String!], $newMedicalConditions: [String!]) {
    updateMyInfoPatient(
      newDrugAllergies: $newDrugAllergies
      newMedicalConditions: $newMedicalConditions
    ) {
      id
    }
  }
`;

export const getInfoBeneficiary = gql`
  query beneficiaryById($id: String!) {
    beneficiaryById(id: $id) {
      id
      sex
      email
      weight
      height
      lastName
      firstName
      phoneNumber
      dateOfBirth
      typeOfBlood
    }
  }
`;

export const muteInfoBeneficiary = gql`
  mutation updateBeneficiary(
    $id: String!
    $sex: SexEnum
    $email: String
    $weight: Float
    $height: Float
    $lastName: String!
    $firstName: String!
    $phoneNumber: String
    $dateOfBirth: DateTime
    $typeOfBlood: typeOfBloodEnum
  ) {
    updateBeneficiary(
      id: $id
      sex: $sex
      email: $email
      weight: $weight
      height: $height
      lastName: $lastName
      firstName: $firstName
      phoneNumber: $phoneNumber
      dateOfBirth: $dateOfBirth
      typeOfBlood: $typeOfBlood
    ) {
      id
    }
  }
`;

export const upPicBeneficiary = gql`
  mutation updateBeneficiary($id: String!, $file: Upload) {
    updateBeneficiary(id: $id, picture: $file) {
      id
      pictureURL {
        id
        url
      }
    }
  }
`;

export const getConditionsBeneficiary = gql`
  query beneficiaryById($id: String!) {
    beneficiaryById(id: $id) {
      id
      newDrugAllergies
      newMedicalConditions
    }
  }
`;

export const muteConditionsBeneficiary = gql`
  mutation updateBeneficiary(
    $id: String!
    $newDrugAllergies: [String!]
    $newMedicalConditions: [String!]
  ) {
    updateBeneficiary(
      id: $id
      newDrugAllergies: $newDrugAllergies
      newMedicalConditions: $newMedicalConditions
    ) {
      id
    }
  }
`;

export const getMeBeneficiaries = gql`
  query getMeBeneficiaries($id: String!) {
    patientById(id: $id) {
      id
      pendingBeneficiary
      beneficiaries {
        id
        pic: pictureURL {
          url
        }
        name: fullName
        email
        dateOfBirth
        beneficiaryToPatient
      }
    }
  }
`;

// export const FindBeneficiaries = gql`
//   query FindBeneficiaries($id: String!) {
//     FindBeneficiaries(id: $id) {
//       __typename
//       ... on Patient {
//         # id
//         name: fullName
//         firstName
//         lastName
//         pic: pictureURL {
//           url
//         }
//       }
//       ... on Beneficiary {
//         # id
//         name: fullName
//         firstName
//         lastName
//         pic: pictureURL {
//           url
//         }
//       }
//     }
//   }
// `;
export const FindBeneficiaries = gql`
  query FindBeneficiaries($id: String!) {
    findBeneficiariesAccounts(id: $id) {
      id
      name: fullName
      pic: pictureURL {
        url
      }
    }
    findBeneficiariesToPatients(id: $id) {
      id
      name: fullName
      pic: pictureURL {
        url
      }
    }
  }
`;

export const getCanAddBeneficiary = gql`
  query {
    canAddBeneficiary
  }
`;

export const createBeneficiary = gql`
  mutation createBeneficiary(
    $firstName: String!
    $lastName: String!
    $email: String
    $picture: Upload
    $dateOfBirth: DateTime
    $sex: SexEnum
    $phoneNumber: String
    $weight: Float
    $height: Float
    $newDrugAllergies: [String!]
    $newMedicalConditions: [String!]
    $typeOfBlood: typeOfBloodEnum
  ) {
    createBeneficiary(
      firstName: $firstName
      lastName: $lastName
      email: $email
      picture: $picture
      dateOfBirth: $dateOfBirth
      sex: $sex
      phoneNumber: $phoneNumber
      weight: $weight
      height: $height
      newDrugAllergies: $newDrugAllergies
      newMedicalConditions: $newMedicalConditions
      typeOfBlood: $typeOfBlood
    ) {
      id
    }
  }
`;

export const removeBeneficiary = gql`
  mutation removeBeneficiary($id: String!) {
    deleteBeneficiaryById(id: $id)
  }
`;

export const removeBeneficiaryAcc = gql`
  mutation DeleteBeneficiaryAcc($id: String!) {
    DeleteBeneficiaryAcc(id: $id)
  }
`;

export const getMeAppointments = gql`
  query getMeAppointments {
    appointments(sort: { field: APPOINTMENTDATEANDTIME, direction: DESCENDING }) {
      docs {
        id
        type
        status
        appointmentType
        appointmentSpecialty
        appointmentNumber
        date: appointmentDateAndTime
        description: reasonForConsultation
        medic: doctor {
          name: fullName
          pic: pictureURL {
            url
          }
          specialties {
            name
          }
        }
      }
    }
  }
`;

export const getMeCards = gql`
  query getMeCards($id: String!) {
    patientById(id: $id) {
      id
      paymentMethods {
        id
        name: card
        type: cardType
      }
    }
  }
`;

export const addCard = gql`
  mutation addCard($token: String!) {
    addPaymentMethod(token: $token) {
      id
    }
  }
`;

export const removeCard = gql`
  mutation removeCard($id: String!) {
    deletePaymentMethod(id: $id)
  }
`;

export const getMeAppointmentsDetail = gql`
  query getMeAppointmentsDetail {
    appointments(perPage: 25, sort: { field: APPOINTMENTDATEANDTIME, direction: DESCENDING }) {
      docs {
        id
        type
        status
        appointmentType
        appointmentSpecialty
        appointmentNumber
        reason: reasonForConsultation
        usable
        diagnosis
        prescription
        newSymptoms
        patient {
          id
          dateOfBirth
          name: fullName
          nationalId
        }
        exams {
          id
          title
          media {
            id
            url
            type: mimetype
          }
        }
        medic: doctor {
          id
          minsaNumber
          pic: pictureURL {
            url
          }
          signature {
            url
          }
          sex
          name: fullName
          specialties {
            id
            name
          }
        }
        date: appointmentDateAndTime
      }
    }
  }
`;

export const deleteAttach = gql`
  mutation deleteAttach($id: String!) {
    deleteExamById(id: $id)
  }
`;

export const attachFile = gql`
  mutation attachFile($file: Upload!, $title: String!, $appointment: String!) {
    uploadExam(file: $file, title: $title, appointment: $appointment) {
      id
    }
  }
`;

export const muteAppointmentSymptoms = gql`
  mutation muteAppointmentSymptoms(
    $id: String!
    $status: updateAppointmentStatus!
    $patient: String!
    $newSymptoms: [String!]
  ) {
    updateAppointment(id: $id, status: $status, patient: $patient, newSymptoms: $newSymptoms) {
      id
      newSymptoms
    }
  }
`;

export const getSymptoms = gql`
  query getSymptoms($perPage: Float, $page: Float, $filters: [symptomFilter!]) {
    symptoms(perPage: $perPage, page: $page, filters: $filters) {
      docs {
        value: id
        label: name
      }
      page
      total
      pages
      perPage
    }
  }
`;

export const companyById = gql`
  query companyById($id: String!) {
    companyById(id: $id) {
      id
      email
      firstName
      lastName
      fullName
      pic: pictureURL {
        url
      }
      description
    }
  }
`;

export const getMeAffiliateInfo = gql`
  query getMeAffiliateInfo($id: String!) {
    patientById(id: $id) {
      id
      belongsToCompany {
        id
        email
        firstName
        lastName
        fullName
        pic: pictureURL {
          url
        }
        description
      }
    }
  }
`;

export const muteMeAffiliateInfo = gql`
  mutation useCode($user: String!, $code: String!) {
    useCode(user: $user, code: $code)
  }
`;
export const SEND_BENEFICIARY_EMAIL = gql`
  mutation SendBeneficiaryEmail($email: String!) {
    SendBeneficiaryEmail(email: $email)
  }
`;

export const beneficiaryToPatient = gql`
  mutation beneficiaryToPatient($id: String!) {
    beneficiaryToPatient(id: $id) {
      id
    }
  }
`;

export const removePendingBeneficiary = gql`
  mutation DeleteBeneficiaryPendingEmail($email: String!) {
    DeleteBeneficiaryPendingEmail(email: $email)
  }
`;
