import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { transactionsFilter } from "./gql";
// utils
import { HandleError } from "utils.js/HandleError";

// ant
import Table from "antd/lib/table";
import Input from "antd/lib/input";
import notification from "antd/lib/notification";
// components
import ColumnsTable from "./ColumnsTable";
import SelectorRange from "components/SelectorRange";
// store
import { TransactionsContext } from "../index";
import * as actionName from "../store/actionStore";

const TableTransactions = () => {
  const { state, dispatch } = useContext(TransactionsContext);
  const { data, loading, error } = useQuery(transactionsFilter, {
    fetchPolicy: "no-cache",
    variables: { ...state.filters },
  });

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
  }, [error]);

  const onChangeDate = (value) => {
    dispatch({
      type: actionName.SET_DATE,
      payload: value,
    });
  };

  const onChangeInput = (text) => {
    dispatch({
      type: actionName.SET_TEXT,
      payload: text.target.value,
    });
  };

  const getPagination = () => {
    return {
      showSizeChanger: false,
      current: state.filterspage,
      pageSize: state.filters.perPage,
      total: data ? data.transactionsFilter.total : 0,
    };
  };

  const handleTableChange = (infoTable) => {
    const { current } = infoTable;
    dispatch({
      type: actionName.SET_PAGINATION,
      payload: current,
    });
  };

  return (
    <>
      <div className='w-full flex xs:flex-col sm:flex-row mb-3'>
        <Input
          value={state.filters.text}
          style={{ maxWidth: "250px" }}
          className='w-full mx-auto sm:mx-0 '
          placeholder='Buscar por correo electronico'
          onChange={onChangeInput}
        />
        <div className='mx-3'>
          <SelectorRange
            disabledDates={false}
            onChange={onChangeDate}
            loading={loading}
            disabled={loading || error}
          />
        </div>
      </div>
      <Table
        rowKey={(record) => record.userPlanId}
        onChange={handleTableChange}
        pagination={getPagination()}
        loading={loading}
        columns={ColumnsTable}
        dataSource={data ? data.transactionsFilter.docs : []}
      />
    </>
  );
};
export default TableTransactions;
