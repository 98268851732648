import React from "react";
import Timer from "react-compound-timer";
import { Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faClock } from "@fortawesome/free-solid-svg-icons";

const TimerExpireAppointment = ({ diff }) => {
  const diffMilliseconds = diff < 1 ? 30000 : diff;
  return (
    <div className='w-full flex flex-col'>
      <Timer initialTime={diffMilliseconds} direction='backward'>
        {() => (
          <React.Fragment>
            <Row className='mb-2'>
              <Col className='flex flex-col mx-auto'>
                <FontAwesomeIcon icon={faExclamationCircle} className='mx-auto text-warning' />
                <label className='font-bold text-warning'>
                  La reserva de su cita esta pronto a expirar
                </label>
              </Col>
            </Row>
            <Row gutter={[10, 10]} className='xs:w-full md:w-2/3 lg:w-1/3 mx-auto'>
              <Col xs={24} className='p-0'>
                <label className='text-black text-sm'>Quedán: </label>
              </Col>
              <Col xs={24} className='flex flex-row justify-center content-center'>
                <FontAwesomeIcon icon={faClock} className='mr-2 text-center text-sm self-center' />
                <Timer.Minutes
                  formatValue={(value) => (
                    <label className='text-center text-sm text-black mb-0'>
                      {value.toString() === "0" ? "00" : value}
                    </label>
                  )}
                />
                <label className='text-center text-sm text-black mb-0 mx-2'>:</label>
                <Timer.Seconds
                  formatValue={(value) => (
                    <label className='text-center text-sm text-black mb-0'>
                      {value.toString() === "0" ? "00" : value}
                    </label>
                  )}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Timer>
    </div>
  );
};

export default TimerExpireAppointment;
