import React, { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
// antd
import Card from "antd/lib/card";
import Spin from "antd/lib/spin";
import Notify from "antd/lib/notification";
import Statistic from "antd/lib/statistic";
// icons
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
// helpers
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import { HandleError } from "utils.js/HandleError";
// style
import style from "./style.module.scss";

export default function StatisticCard(_) {
  const { icon, gql, path, title: fnTitle, redirectTo, onClick, ...attr } = _;
  const history = useHistory();
  const { data, loading, error } = useQuery(gql, {
    fetchPolicy: "no-cache",
  });

  const redirect = () => {
    if (redirectTo) {
      history.push(redirectTo);
    }
  };

  const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
  };

  useEffect(() => {
    if (error) Notify.error({ message: HandleError(error) });
  }, [error]);

  const value = get(data, path, 0);

  const title = isFunction(fnTitle) ? fnTitle(value) : fnTitle;

  const props = {
    ...attr,
    value: isFloat(value) ? value.toFixed(2) : value,
    title,
  };

  return (
    <Card
      onClick={redirect}
      className={style.staticsCard + " " + (redirectTo ? style.hoverRedirectTo : "")}
    >
      {loading ? <Spin /> : <Statistic {...props} />}
      <FA icon={icon} />
    </Card>
  );
}

StatisticCard.defaultProps = {
  className: "",
};
