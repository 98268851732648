import jwtDecode from "jwt-decode";
import { URI } from "../utils.js/constants";

let tokenPromise = null;

async function refreshToken() {
  if (tokenPromise) {
    return tokenPromise().then((token) => verifyToken(token));
  }
  tokenPromise = getRefreshToken();
  return tokenPromise;
}

async function verifyToken(token) {
  const decoded = jwtDecode(token);
  const currentTime = Math.round(Date.now() / 1000);
  if (decoded.exp <= currentTime) {
    tokenPromise = getRefreshToken();
    return tokenPromise;
  }
  return token;
}

async function getRefreshToken() {
  const refreshToken = localStorage.getItem("refreshToken");
  const data = JSON.stringify({
    query: `{  refreshToken(refreshToken: "${
      refreshToken || "invalid"
    }"){  token, refreshToken } }`,
  });

  const token = await fetch(URI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: data,
  }).then((response) => response.json());

  if (token.errors) {
    throw new Error("TokenExpiredError: jwt expired");
  }

  const newToken = token.data.refreshToken.token;
  const newRefreshToken = token.data.refreshToken.refreshToken;
  localStorage.setItem("token", newToken);
  localStorage.setItem("refreshToken", newRefreshToken);

  return newToken;
}

const Token = { verifyToken, refreshToken };
export default Token;
