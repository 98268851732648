import React, { useState } from "react";
import { Card } from "antd";
import moment from "moment";
import ModalDetalles from "./modalDetalles";
import { DrawMoney } from "./utils";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  Cell,
} from "recharts";

const CustomTooltipWeek = ({ active, payload, label }) => {
  if (active) {
    const { payload: fullPayload } = payload[0];
    const dateFormat = fullPayload.date
      ? moment(fullPayload.date).year().toString()
      : moment().year().toString();
    const fromDate = moment(dateFormat)
      .add(parseInt(label) - 1, "weeks")
      .startOf("week")
      .format("LL");
    const toDate = moment(dateFormat)
      .add(parseInt(label) - 1, "weeks")
      .endOf("week")
      .format("LL");
    // const to_date = dateFormat.endOf('week').format('LL')
    // var begin = moment(date).startOf('week').isoWeekday(1)
    return (
      <Card>
        <p className='text-center font-semibold'>Semana {label}</p>
        <p className='mb-0'>{fromDate}</p>
        <p className='mb-0 text-center'>-</p>
        <p className='mb-0'>{toDate}</p>
        <p className='mt-3 mb-0'>
          Ganancias: <DrawMoney quantity={fullPayload.totalRevenue} />
        </p>
      </Card>
    );
  }
  return null;
};

const CustomTooltipMonth = ({ active, payload, label }) => {
  if (active) {
    const { payload: fullPayload } = payload[0];
    return (
      <Card>
        <p className='text-center font-semibold'>{label}</p>
        <p className='mt-3 mb-0'>
          Ganancias: <DrawMoney quantity={fullPayload.totalRevenue} />
        </p>
      </Card>
    );
  }
  return null;
};

const ChartConfig = ({ data, selectType }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [infoModal, setInfoModal] = useState(null);
  const onClickBar = (e) => {
    setVisibleModal(true);
    setInfoModal(e.payload);
  };
  const closeModal = () => {
    setVisibleModal(false);
    setInfoModal(null);
  };
  const arrayColors = ["#16a085", "#c0392b"];
  return (
    <>
      <ModalDetalles
        type={selectType}
        info={infoModal}
        visibleModal={visibleModal}
        closeModal={closeModal}
      />
      <ResponsiveContainer height={400}>
        <BarChart data={data}>
          <XAxis dataKey={selectType === "MONTH" ? "month" : "id"} />
          <YAxis />
          <Tooltip
            content={selectType === "MONTH" ? <CustomTooltipMonth /> : <CustomTooltipWeek />}
          />
          <ReferenceLine y={0} stroke='#000' />
          <Bar dataKey='totalRevenue' onClick={onClickBar}>
            {data.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.totalRevenue > 0 ? arrayColors[0] : arrayColors[1]}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default ChartConfig;
