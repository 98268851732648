import gql from "graphql-tag";
import moment from "moment";

export const getTotalApprovedMedic = gql`
  query getTotalApprovedMedic {
    doctors(filters: [{ byText: { field: APPROVALSTATUS, value: "approved" } }]) {
      total
    }
  }
`;

export const getTotalPatient = gql`
  query getTotalPatient {
    patients {
      total
    }
  }
`;

const month = {
  to: moment().endOf("month").toISOString(),
  from: moment().startOf("month").toISOString(),
};

export const getTotalAppointments = gql`
    query getTotalAppointments {
        appointments (
            filters: [
                {
                    byDate: {
                        field: CREATEDAT
                        from: "${month.from}"
                        to: "${month.to}"
                    }
                }
            ]
        ) {
            total
        }
    }
`;

export const getMonthCurrentEarnings = gql`
    query getMonthCurrentEarnings {
        getCurrentEarnings (
            page: 1
            perPage: 4
            from: "${month.from}"
            to: "${month.to}"
        ) {
          total
          current: page
          pageSizepageSize: perPage
          dataSource: docs {
            service
            total
          }
        }
    }
`;

export const getMedicsUnderReview = gql`
  query getMedicsUnderReview($current: Float) {
    doctors(
      page: $current
      perPage: 8
      filters: [{ byText: { field: APPROVALSTATUS, value: "underReview" } }]
    ) {
      total
      current: page
      pageSize: perPage
      dataSource: docs {
        id
        minsaNumber
        fullName
      }
    }
  }
`;

export const getActivePlanTotal = gql`
  query getActivePlanTotal {
    getActivePlanTotal
  }
`;

export const getPlansActiveUsers = gql`
  query getPlansActiveUsers {
    plans(filters: [{ byCondition: { field: COMPRABLE, value: true } }]) {
      docs {
        id
        name
        value: activeTotal
      }
    }
  }
`;
