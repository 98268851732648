import React, { useContext, useEffect } from "react";
import { Modal, Divider, Form, notification } from "antd";
import BodyModal from "./bodyModal";
import FooterModal from "./footerModal";
import { denyDoctorGql, approveDoctorGql } from "../gqlTags";
import { useMutation } from "@apollo/react-hooks";
import { ContextTablaDoctores } from "../index";
import { SET_INITIAL_STATE, SET_CLOSE_MODAL, SET_RANDOM_VALUE_FOR_QUERY } from "../store/actions";
import { getValueAprobalStatus } from "../utils";

const createRandom = () => {
  try {
    const timeStampLocal = Math.round(new Date().getTime() / 1000).toString();
    const randomOne = Math.floor(Math.random() * 3000) + 1;
    return randomOne.toString().concat("_" + timeStampLocal);
  } catch (e) {
    return (Math.floor(Math.random() * 20000) + 1).toString();
  }
};

const DescriptionSuccesStatus = ({ approvalStatus, oldApprovalStatus }) => {
  return (
    <div className='flex flex-col mt-5'>
      <div className='w-full flex-col'>
        <p className='font-semibold mb-3 text-danger'>
          Antiguo Estado: <span className='text-black'>{oldApprovalStatus}</span>
        </p>
        <p className='font-semibold mb-3 text-success'>
          Nuevo Estado: <span className='text-black'>{approvalStatus}</span>
        </p>
      </div>
    </div>
  );
};

const ModalForActions = () => {
  const [form] = Form.useForm();
  const { state, dispatch } = useContext(ContextTablaDoctores);
  const { infoDoctorSelect } = state;

  const [fetchDenyDoctor, { loading: loadingDeny, error: errorDeny, data: dataDeny }] = useMutation(
    denyDoctorGql,
    { fetchPolicy: "no-cache", notifyOnNetworkStatusChange: true }
  );

  const [fetchApproveDoctor, { loading: loadingApprove, error: errorApprove, data: dataApprove }] =
    useMutation(approveDoctorGql, { fetchPolicy: "no-cache", notifyOnNetworkStatusChange: true });

  useEffect(() => {
    if (!loadingDeny) {
      if (errorDeny) {
        notification.error({
          message: "Error",
          description: Array.isArray(errorDeny.graphQLErrors)
            ? errorDeny.graphQLErrors[0].message
            : "Error temporal del servidor",
        });
        dispatch({ type: SET_CLOSE_MODAL });
        dispatch({ type: SET_INITIAL_STATE });
        // este string random causa un refetch de la query de la tabla doctores
        dispatch({ type: SET_RANDOM_VALUE_FOR_QUERY, payload: createRandom() });
      } else if (dataDeny) {
        const { denyDoctor } = dataDeny;
        const { approvalStatus } = denyDoctor;
        notification.success({
          message: infoDoctorSelect ? infoDoctorSelect.fullName : "Dr. N/A",
          duration: 7,
          description: (
            <DescriptionSuccesStatus
              approvalStatus={getValueAprobalStatus(approvalStatus, "table")}
              oldApprovalStatus={getValueAprobalStatus(
                infoDoctorSelect ? infoDoctorSelect.approvalStatus : null,
                "table"
              )}
            />
          ),
        });
        dispatch({ type: SET_CLOSE_MODAL });
        dispatch({ type: SET_INITIAL_STATE });
        // este string random causa un refetch de la query de la tabla doctores
        dispatch({ type: SET_RANDOM_VALUE_FOR_QUERY, payload: createRandom() });
      }
    }
  }, [errorDeny, loadingDeny, dataDeny, dispatch, infoDoctorSelect]);

  useEffect(() => {
    if (!loadingApprove) {
      if (errorApprove) {
        notification.error({
          message: "Error",
          description: Array.isArray(errorApprove.graphQLErrors)
            ? errorApprove.graphQLErrors[0].message
            : "Error temporal del servidor",
        });
        dispatch({ type: SET_CLOSE_MODAL });
        dispatch({ type: SET_INITIAL_STATE });
        // este string random causa un refetch de la query de la tabla doctores
        dispatch({ type: SET_RANDOM_VALUE_FOR_QUERY, payload: createRandom() });
      } else if (dataApprove) {
        const { approveDoctor } = dataApprove;
        const { approvalStatus } = approveDoctor;
        notification.success({
          message: infoDoctorSelect ? infoDoctorSelect.fullName : "Dr. N/A",
          duration: 7,
          description: (
            <DescriptionSuccesStatus
              approvalStatus={getValueAprobalStatus(approvalStatus, "table")}
              oldApprovalStatus={getValueAprobalStatus(
                infoDoctorSelect ? infoDoctorSelect.approvalStatus : null,
                "table"
              )}
            />
          ),
        });
        dispatch({ type: SET_CLOSE_MODAL });
        dispatch({ type: SET_INITIAL_STATE });
        // este string random causa un refetch de la query de la tabla doctores
        dispatch({ type: SET_RANDOM_VALUE_FOR_QUERY, payload: createRandom() });
      }
    }
  }, [errorApprove, loadingApprove, dataApprove, dispatch, infoDoctorSelect]);

  const getVisible = () => {
    if (state.modalInfo || state.modalAceptar || state.modalRechazar) {
      return true;
    }
    return false;
  };

  const getTittle = () => {
    if (state.modalInfo) {
      return "Información del doctor";
    }
    if (state.modalAceptar) {
      return "Aprobar Doctor";
    }
    if (state.modalRechazar) {
      return "Rechazar Doctor";
    }
  };

  const onCancelModal = () => {
    dispatch({ type: SET_CLOSE_MODAL });
    dispatch({ type: SET_INITIAL_STATE });
  };

  const onFinish = async (e) => {
    if (state.modalRechazar) {
      const { razon } = e;
      try {
        await fetchDenyDoctor({
          variables: {
            id: infoDoctorSelect ? infoDoctorSelect.id : "",
            reason: razon,
          },
        });
      } catch (e) {
        notification.error({
          message: "Error",
          description: "Server Error",
        });
      }
    } else if (state.modalAceptar) {
      try {
        await fetchApproveDoctor({
          variables: {
            id: infoDoctorSelect ? infoDoctorSelect.id : "",
          },
        });
      } catch (e) {
        notification.error({
          message: "Error",
          description: "Server Error",
        });
      }
    }
  };

  const afterClose = () => {
    dispatch({ type: SET_RANDOM_VALUE_FOR_QUERY, payload: null });
    dispatch({ type: SET_CLOSE_MODAL });
    dispatch({ type: SET_INITIAL_STATE });
    form.resetFields();
  };

  return (
    <Modal
      title={getTittle()}
      visible={getVisible()}
      destroyOnClose
      style={{ top: 20 }}
      onCancel={onCancelModal}
      footer={null}
      afterClose={afterClose}
      closable={!loadingDeny && !loadingApprove}
      maskClosable={!loadingDeny && !loadingApprove}
    >
      <Form name='formModal' form={form} onFinish={onFinish}>
        {infoDoctorSelect && <BodyModal loading={loadingDeny || loadingApprove} />}
        <Divider />
        <FooterModal loading={loadingDeny || loadingApprove} onCancel={onCancelModal} />
      </Form>
    </Modal>
  );
};

export default ModalForActions;
