import React from "react";
import { Link } from "react-router-dom";
import logo from "assets/img/logo-white.png";

export default function Brand() {
  return (
    <div className='row flex mb-2'>
      <Link to='/md/home'>
        <img alt='easyMDLogo' src={logo} style={{ height: "100px" }} />
      </Link>
    </div>
  );
}
