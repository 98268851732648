import React from "react";
import { Row, Col, Card } from "antd";
import useValidatorCita from "./useValidatorCita";
import LlamadaListenerConfig from "./llamadaListenerConfig";
import ChatPacienteNormal from "./chatPacienteNormal";
import InfoPaciente from "./infoPaciente";
import CitaNoEncontrada from "../components/citaNoEncontrada";
import BrandLoading from "../../../components/CitasUtils/BrandLoading";
import EstadosCita from "../components/estadosCitaPacienteNormal";

const ContainerSala = ({ info }) => {
  const { idAppointment } = info;
  const {
    listenerID,
    dataAppointment,
    isError,
    isFinish,
    notFound,
    isBefore,
    isAfter,
    isInAppointment,
    loadingBrand,
    infoForChat,
    sessionIDCall,
  } = useValidatorCita(idAppointment);

  if (notFound || isError) {
    return <CitaNoEncontrada />;
  }
  if (loadingBrand || !dataAppointment) {
    return <BrandLoading />;
  }

  return (
    <div className='w-full p-5 h-screen mb-5'>
      <Row gutter={[10, 10]} className='h-screen'>
        <Col xs={24} md={24} lg={14} style={{ minHeight: "80vh" }}>
          <Card className='h-full w-full' bodyStyle={{ padding: 0 }}>
            <LlamadaListenerConfig
              listenerID={listenerID}
              infoForChat={infoForChat}
              isBefore={isBefore}
              isInAppointment={isInAppointment}
              isAfter={isFinish || isAfter}
              infoAppointment={dataAppointment}
              isFinish={isFinish}
            />
          </Card>
        </Col>
        <Col xs={24} md={24} lg={10}>
          <ChatPacienteNormal
            isInAppointment={isInAppointment}
            id={idAppointment}
            isFinish={isFinish}
            isAfter={isAfter}
            isBefore={isBefore}
            infoForChat={infoForChat}
            infoAppointment={dataAppointment}
            sendFirstMessage={
              !dataAppointment.log ? false : !dataAppointment.log.firstMessagePatient
            }
          />
        </Col>
        {dataAppointment && (
          <Col xs={24} style={{ minHeight: "100px" }}>
            <Card className='h-full w-full'>
              <InfoPaciente infoAppointment={dataAppointment} />
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ContainerSala;
