import gql from "graphql-tag";

export const companyByIdGql = gql`
  query companyById($id: String!) {
    companyById(id: $id) {
      id
      fullName
      pictureURL {
        url
      }
      role {
        name
      }
    }
  }
`;

// charts

export const getAppointmentMDDataChartGql = gql`
  query getAppointmentMDDataChart($from: DateTime!, $to: DateTime!, $company: String!) {
    getAppointmentMDDataChart(from: $from, to: $to, company: $company) {
      date
      category
      value
    }
  }
`;

export const getAVGForAppointmentsMDGql = gql`
  query getAVGForAppointmentsMD($from: DateTime!, $to: DateTime!, $company: String!) {
    getAVGForAppointmentsMD(from: $from, to: $to, company: $company) {
      date
      category
      value
    }
  }
`;

export const getSymptomsWordCloudGql = gql`
  query getSymptomsWordCloud($from: DateTime!, $to: DateTime!, $company: String!) {
    getSymptomsWordCloud(from: $from, to: $to, company: $company) {
      word
      category
      value
    }
  }
`;

export const getAVGWaitingForAppointmentsMDGql = gql`
  query getAVGWaitingForAppointmentsMD($from: DateTime!, $to: DateTime!, $company: String!) {
    getAVGWaitingForAppointmentsMD(from: $from, to: $to, company: $company) {
      category
      value
    }
  }
`;
