import React from "react";
// antd
import Rate from "antd/lib/rate";
import Card from "antd/lib/card";
// components
import Cover from "./Cover";
// helpers
import map from "lodash/map";
// styles
import style from "./style.module.scss";

const MedicCard = ({ pic, name, rate, title, status, description, specialties }) => {
  const props = {
    Cover: {
      status,
      avatar: pic ? pic.url : null,
    },
    Meta: {
      title,
      description,
    },
  };

  const propsCard = {
    cover: <Cover {...props.Cover} />,
    bordered: false,
    className: style.medic,
  };

  return (
    <Card {...propsCard}>
      <div className={style.detail}>
        <b className={style.name}>{name}</b>
        {map(specialties, (tag, key) => (
          <span key={key} className={style.tag}>
            {tag}
          </span>
        ))}
        <Rate disabled value={rate} />
      </div>
      <Card.Meta {...props.Meta} />
    </Card>
  );
};

MedicCard.defaultProps = {
  title: "Información del Medico",
};

export default MedicCard;
