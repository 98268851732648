import {
  getTotalPatientsNoAffiliateGql,
  getCompanyAppointmentsGql,
  getTotalPatientsAffiliateGql,
} from "./gqlTags";
// icons
import { faUserCheck, faUserClock, faCalendar } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
require("moment/min/locales.min");
moment.locale("es");

export default {
  patientsTrue: (id) => {
    return {
      key: "CodesTrue",
      gql: getTotalPatientsAffiliateGql,
      variables: {
        id,
      },
      path: "getPatientsAffiliated",
      icon: faUserCheck,
      title: "Usuarios Afiliados",
      redirectTo: null,
    };
  },
  patientsFalse: (id) => {
    return {
      key: "Codes",
      gql: getTotalPatientsNoAffiliateGql,
      variables: {
        filters: [
          {
            byId: {
              field: "COMPANY",
              value: id,
            },
            byCondition: {
              field: "ENABLED",
              value: true,
            },
          },
        ],
      },
      path: "codes.total",
      icon: faUserClock,
      title: "Usuarios pendientes de afiliación",
      redirectTo: null,
    };
  },
  totalAppointments: (id) => {
    return {
      key: "Appointments",
      gql: getCompanyAppointmentsGql,
      variables: {
        id: id,
        from: `${moment().startOf("month")}`,
        to: `${moment().endOf("month")}`,
      },
      path: "getCompanyAppointments",
      icon: faCalendar,
      title: "Citas inmediatas mes de " + moment().format("MMMM"),
      redirectTo: null,
    };
  },
};
