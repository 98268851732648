import React, { useContext, useEffect } from "react";
import { Card, Button, notification, Modal } from "antd";
import { HandleError } from "utils.js/HandleError";
import BrandLoading from "components/CitasUtils/BrandLoading/brand";

import { profileMedicContext } from "../../../index";
import * as actionName from "../../storeProfileMedic/actionStoreMedic";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

import { doctorByIdDocument, requestApproval } from "../../gqlTags";
import ListDocuments from "./ListDocuments";

import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QuestionCircleOutlined } from "@ant-design/icons";

const Tap3Medic = () => {
  const { state, dispatch } = useContext(profileMedicContext);
  const [doctorDocument, { loading, error, data }] = useLazyQuery(doctorByIdDocument, {
    fetchPolicy: "no-cache",
  });
  const [setndApproval, reqSetndApproval] = useMutation(requestApproval, {
    fetchPolicy: "no-cache",
  });

  const { confirm } = Modal;

  useEffect(() => {
    if (state.dataMedic.id) {
      doctorDocument({ variables: { id: state.dataMedic.id } });
    }
  }, [state]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
    if (reqSetndApproval.error) {
      notification.error({
        message: "Error",
        description: HandleError(reqSetndApproval.error),
      });
    }
    if (reqSetndApproval.data) {
      dispatch({
        type: actionName.SET_STATUS,
        payload: reqSetndApproval.data.requestApproval.approvalStatus,
      });
      notification.info({
        message: "Sus documentos han pasado a revisión ",
      });
    }
  }, [error, reqSetndApproval.error, reqSetndApproval.data, dispatch]);

  const confirmSetndApproval = () => {
    confirm({
      okText: "Si",
      cancelText: "No",
      title: "¿Estás seguro que quieres solicitar la aprobación de la cuenta?",
      icon: <QuestionCircleOutlined className='text-info' />,
      content: (
        <div>
          <h4 className='text-primary'>
            <strong>Documentos Agregados</strong>
          </h4>
          <ul>
            {data.doctorById.supportingDocuments.map((value, index) => (
              <li key={index}>• {value.title}</li>
            ))}
          </ul>
        </div>
      ),
      onOk: async () => {
        await setndApproval();
      },
    });
  };

  if (loading) {
    return (
      <div className='flex justify-center items-center h-full w-full'>
        <BrandLoading width='35%' />
      </div>
    );
  }

  return (
    <div style={{ width: "98%" }} className='container mx-auto p-2 '>
      <Card title={null} bodyStyle={{ height: "100%" }}>
        <div className='block justify-start md:flex md:justify-between my-4 md:my-2'>
          <h3 className='text-2xl text-primary'>
            <strong>Documentacion de proveedor:</strong>
          </h3>
          <Button
            onClick={() => confirmSetndApproval()}
            type='default'
            shape='round'
            className='button-success'
            size='large'
            loading={reqSetndApproval.loading}
            disabled={
              !!(
                state.dataMedic.approvalStatus !== "DOCUMENTS_REQUIRED" || reqSetndApproval.loading
              )
            }
            icon={<FontAwesomeIcon className='text-white mr-2' size='lg' icon={faPaperPlane} />}
          >
            Solicitar Aprobación
          </Button>
        </div>
        <div className='mt-2 mb-2'>
          <p>
            Ya casi estamos listos! para concluir la creacion de su perfil solo necesitamos que
            adjuntes los sigueintes documentos, esto nos ayudara a evitar fraudes.
          </p>
          <p>
            <strong>Documentos necesarios:</strong>
          </p>
          <ul>
            <li>• Registro de código minsa</li>
            <li>• Cedula o pasaporte</li>
            <li>• Credenciales de especialidad y subespecialidad</li>
            <li>
              • Cuenta de banco al que se le transfiere sus ingresos de la billetera electronica.
            </li>
          </ul>
        </div>

        <h3 className='text-xl text-primary'>Documentos agregados</h3>
        {data && <ListDocuments listDocument={data.doctorById.supportingDocuments} />}
      </Card>
    </div>
  );
};

export default Tap3Medic;
