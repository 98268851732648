import React from "react";
// antd
import Card from "antd/lib/card";
// components
import Table from "./Table";
import columns from "./Colums";
// gql
import { getMedicsUnderReview as gql } from "../gql";

export default function PendingApproval() {
  const props = {
    Card: {
      title: "Aplicaciones pendientes de medicos",
      bodyStyle: {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    Table: {
      gql,
      columns,
      size: "middle",
    },
  };

  return (
    <Card {...props.Card}>
      <Table {...props.Table} />
    </Card>
  );
}
