import React from "react";

import SectionOne from "./component/SectionOne";
import SectionTwo from "./component/SectionTwo";
import SectionThree from "./component/SectionThree";
import SectionFour from "./component/SectionFour";
import SectionFive from "./component/SectionFive";

function Tigo() {
  return (
    <div>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
    </div>
  );
}

export default Tigo;
