import React, { useEffect } from "react";
import LoadingBrand from "./loading";
import { useQuery } from "@apollo/react-hooks";
import gqlAppointmentById from "./gqlAppointmentById";
import { Alert, Button, Card } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faUser } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import ErrorCard from "./errorCard";
import WizardCheckOut from "./wizard";
import moment from "moment";

const VerifyIdAppointment = ({ id }) => {
  const history = useHistory();
  let { loading, error, data } = useQuery(gqlAppointmentById(), {
    variables: { id: id },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  if (loading) {
    return <LoadingBrand />;
  }

  if (!loading && error && !data) {
    return <ErrorCard error={error} message={null} />;
  } else {
    return (
      <div className='w-full min-h-35rem flex flex-col justify-center content-center mb-12'>
        {data.appointmentById.status !== "PENDING" ? (
          <Card
            className='xs:w-2/3 md:w-2/3 lg:w-1/3 bg-transparent mx-auto'
            bodyStyle={{ padding: 0, backgroundColor: "transparent" }}
          >
            <Alert
              message='Esta cita ya ha sido agendada'
              description={
                <InfoAppointmentNoPending date={data.appointmentById.appointmentDateAndTime} />
              }
              type='info'
              className='rounded-md rounded-b-none border-none'
              showIcon
            />
            <p className='m-0 text-center'>
              <Button
                onClick={() => history.push("/patient")}
                type='default'
                shape='round'
                size='large'
                danger
                className='button-success my-2'
              >
                <FontAwesomeIcon icon={faUser} className='mr-2' />
                Ir a Mi Perfil
              </Button>
            </p>
          </Card>
        ) : (
          <WizardCheckOut id={id} dataAppointment={data ? data.appointmentById : null} />
        )}
      </div>
    );
  }
};

const InfoAppointmentNoPending = ({ date }) => {
  const DateString = () => (
    <li className='mb-1'>
      <FontAwesomeIcon icon={faCalendarCheck} className='mr-2' />
      {moment(moment(date), "YYYY-MM-DD hh:mm").format("LLLL a")}
    </li>
  );
  return (
    <ul>
      <li className='text-left mb-1 mt-3'>Fecha de la cita: </li>
      <DateString />
    </ul>
  );
};

export default VerifyIdAppointment;
