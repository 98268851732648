import gql from "graphql-tag";

export const planById = gql`
  query planById($id: String!) {
    planById(id: $id) {
      id
      name
      description
      periods {
        name
        price
      }
      features {
        id
        name
        description
      }
    }
  }
`;

export const mePlans = gql`
  query mePlans {
    me {
      id
      fullName
      plans {
        plan {
          id
          name
        }
      }
    }
  }
`;

export const buyPlan = gql`
  mutation buyPlan(
    $planId: String!
    $paymentToken: String!
    $frequency: paymentFrequency
    $fingerprintSessionId: String
    $mobileNotification: Boolean
    $autoRenew: Boolean
    $coupon: String
  ) {
    buyPlan(
      planId: $planId
      paymentToken: $paymentToken
      frequency: $frequency
      mobileNotification: $mobileNotification
      fingerprintSessionId: $fingerprintSessionId
      autoRenew: $autoRenew
      coupon: $coupon
    )
  }
`;

export const plans = gql`
  query plans($perPage: Float, $page: Float, $filters: [planFilter!]) {
    plans(perPage: $perPage, page: $page, filters: $filters) {
      total
      page
      docs {
        id
        comprable
        name
        description
        features {
          id
          name
          disabled
          description
        }
        periods {
          name
          price
        }
      }
    }
  }
`;

export const planfeatures = gql`
  query planfeatures(
    $perPage: Float
    $page: Float
    $sort: [planfeatureSort!]
    $filters: [planfeatureFilter!]
  ) {
    planfeatures(perPage: $perPage, page: $page, sort: $sort, filters: $filters) {
      total
      page
      docs {
        id
        name
        disabled
        description
      }
    }
  }
`;
export const mePaymentMethods = gql`
  query mePaymentMethods {
    me {
      id
      fullName
      paymentMethods {
        id
        card
        cardType
      }
    }
  }
`;
