import { cloneElement } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
// uitil
import { HandleError } from "utils.js/HandleError";
// antd
import Modal from "antd/lib/modal";
import notification from "antd/lib/notification";

HOCConfirmMutation.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

function HOCConfirmMutation(_) {
  const {
    title,
    content,
    gql,
    children,
    variables,
    mesageError,
    refetchQueries,
    mesageSuccess,
    onSuccess,
    onError,
    ...attr
  } = _;
  const [applyRemove, { loading }] = useMutation(gql, { variables, refetchQueries });

  const onOk = () => {
    applyRemove()
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
        notification.success({
          message: mesageSuccess,
        });
      })
      .catch((e) => {
        if (onError) {
          onError();
        }
        Modal.destroyAll();
        notification.error({
          message: mesageError,
          description: HandleError(e),
        });
      });
  };

  const onClick = () =>
    Modal.confirm({
      onOk,
      title,
      content,
      centered: true,
      confirmLoading: loading,
      cancelButtonProps: {
        disabled: loading,
      },
      ...attr,
    });

  return cloneElement(children, { onClick });
}

HOCConfirmMutation.defaultProps = {
  title: "title",
  content: null,
  children: null,
  variables: {},
  refetchQueries: [],
};

export default HOCConfirmMutation;
