import React, { useContext } from "react";
// antd
import Button from "antd/lib/button";
// icons
import { faBars, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
// context
import Context from "./Context";
// styles
import style from "./style.module.scss";

export default function BtnToggleShowSider() {
  const { overlap, setOverlap } = useContext(Context);

  const propsBtn = {
    size: "large",
    type: "dashed",
    icon: <FA icon={overlap ? faChevronRight : faBars} />,
    // shape: 'circle',
    style: { lineHeight: 0 },
    onClick: () => setOverlap(!overlap),
    className: style.btnOverlapToggle,
  };

  return <Button {...propsBtn} />;
}
