import React, { useState, useEffect } from "react";
import { detect } from "detect-browser";
import { Card, Tooltip } from "antd";
import ChromeLogo from "../../../assets/img/ChromeLogo_easymd.png";
import FirefoxLogo from "../../../assets/img/FirefoxLogo_easymd.png";
import EdgeCroLogo from "../../../assets/img/EdgeLogo_easymd.png";
import OperaLogo from "../../../assets/img/OperaLogo_easymd.png";
const browser = detect();

const BrowserValidator = () => {
  const [supportBrowser, setSupportBrowser] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      switch (browser && browser.name) {
        case "chrome":
          setSupportBrowser(true);
          break;
        case "firefox":
          setSupportBrowser(true);
          break;

        case "opera":
          setSupportBrowser(true);
          break;

        // Added safari supported browser for video calls
        case "safari":
          setSupportBrowser(true);
          break;

        case "edge":
          setSupportBrowser(true);
          break;

        case "edge-chromium":
          setSupportBrowser(true);
          break;
        default:
          setSupportBrowser(false);
      }
    }, 800);
  }, []);
  if (supportBrowser) {
    return null;
  }
  return (
    <Card className='flex items-center mb-3 mx-auto flex-col'>
      <p className='text-danger mb-2'>Navegadores soportados para la video llamada:</p>
      <div className='flex flex-row'>
        <div className='w-1/3 flex justify-center'>
          <Tooltip title='Google Chrome'>
            <img alt='imageLogoGoogleChrome' src={ChromeLogo} />
          </Tooltip>
        </div>
        <div className='w-1/3 flex justify-center'>
          <Tooltip title='Firefox'>
            <img alt='imageLogoFirefox' src={FirefoxLogo} />
          </Tooltip>
        </div>
        <div className='w-1/3 flex justify-center'>
          <Tooltip title='Edge y Edge Chromium'>
            <img alt='imageLogoEdge' src={EdgeCroLogo} />
          </Tooltip>
        </div>
        <div className='w-1/3 flex justify-center'>
          <Tooltip title='Opera'>
            <img alt='imageLogoOpera' src={OperaLogo} />
          </Tooltip>
        </div>
      </div>
    </Card>
  );
};

export default BrowserValidator;
