import React from "react";
// components
import Input from "../InputLg";
import InputCountry from "../InputCountry";
// helpers
import toString from "lodash/toString";

export default {
  Address: {
    name: "address1",
    rules: [
      {
        required: true,
        message: "Requerido!",
      },
    ],
    children: <Input autoComplete='new-password' placeholder='Dirección 1' />,
  },
  Address2: {
    name: "address2",
    children: <Input autoComplete='new-password' placeholder='Dirección 2' />,
  },
  City: {
    name: "city",
    rules: [
      {
        required: true,
        message: "Requerido!",
      },
    ],
    children: <Input autoComplete='new-password' placeholder='Municipio' />,
  },
  State: {
    name: "state",
    dependencies: ["country"],
    rules: [
      {
        required: true,
        message: "Requerido!",
      },
    ],
  },
  Country: {
    name: "country",
    rules: [
      {
        required: true,
        message: "Requerido!",
      },
    ],
    children: <InputCountry />,
  },
  Zip: {
    name: "zipCode",
    rules: [
      {
        required: true,
        message: "Requerido!",
      },
      { min: 5, message: "Codigo Postal incompleto" },
      {
        required: true,
        pattern: new RegExp("^[0-9]*$"),
        message: "Numeros unicamente!",
      },
    ],
    children: (
      // EAS-1629
      <Input maxLength={5} autoComplete='new-password' size='large' placeholder='Codigo postal' />
    ),
    normalize: (val) => toString(val),
  },
};
