import React, { useContext } from "react";
import Modal from "antd/lib/modal";

// componets
import ContendModal from "./ContendModal";

// store
import { AffiliatesContext } from "../../index";
import * as actionName from "../../store/actionStore";

const ModalAffiliates = (_) => {
  const { state, dispatch } = useContext(AffiliatesContext);

  const handleCancel = () => {
    dispatch({
      type: actionName.SET_VISIBLEMODAL,
      payload: false,
    });
  };

  const props = {
    title: "Transacciones realizadas",
    visible: state.activeModal,
    onCancel: handleCancel,
    footer: null,
    destroyOnClose: true,
    className: "w-2/3",
    afterClose: () => {
      dispatch({
        type: actionName.SET_SELECTEDUSER,
        payload: {},
      });
    },
  };

  return (
    <Modal {...props}>
      <ContendModal />
    </Modal>
  );
};
export default ModalAffiliates;
