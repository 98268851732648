import { useMemo, useEffect, useContext } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  usersListGql,
  rolesGql,
  createUser,
  updateUser,
  deleteUser as deleteUserGql,
  createRoleGql,
} from "../gqlTags";
import { get, filter, map } from "lodash";
import { ContextTableUsers } from "../index";
import { SET_USER, SET_ROLES, SET_ROLE } from "../store/actions";
import { tryQuery } from "utils.js/helper";

export default function useQueries() {
  const { state, dispatch } = useContext(ContextTableUsers);
  const { paginationVars, filterUsers, user, role } = state;

  const { data: rolesData } = useQuery(rolesGql, {
    variables: {
      filters: [
        {
          byText: {
            field: "USEDFOR",
            value: "operationsRole",
          },
        },
      ],
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  const rolesDocs = useMemo(
    () =>
      filter(
        get(rolesData, "roles.docs", []),
        (role) => !!role.usedFor && role.usedFor !== "baseRole"
      ),
    [rolesData]
  );
  const roles = map(rolesDocs, (role) => role.id);

  const [getUsers, { data, loading, error, refetch }] = useLazyQuery(usersListGql, {
    variables: {
      ...paginationVars,
      filters: [
        {
          ...(rolesData && {
            byId: {
              field: "ROLE",
              value: roles?.length > 1 ? roles : roles[0],
            },
          }),
        },
        { ...filterUsers },
      ],
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const [postSignupUser, { data: signUpData }] = useMutation(createUser, {
    fetchPolicy: "no-cache",
  });

  const [postEditUser, { data: editUserData }] = useMutation(updateUser, {
    fetchPolicy: "no-cache",
  });

  const [postDeleteUser, { data: deleteData }] = useMutation(deleteUserGql, {
    fetchPolicy: "no-cache",
  });

  const [postCreateRole, { data: createRoleData }] = useMutation(createRoleGql, {
    refetchQueries: ["roles"],
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (rolesData) {
      dispatch({ type: SET_ROLES, payload: rolesDocs });
      getUsers();
    }
  }, [rolesData, rolesDocs, getUsers, dispatch]);

  const { new: newRole, variables: roleVariables } = role;
  useEffect(() => {
    newRole && tryQuery(postCreateRole, { variables: roleVariables });
  }, [newRole, roleVariables, postCreateRole]);

  useEffect(() => {
    createRoleData &&
      dispatch({ type: SET_ROLE, payload: { new: false, loading: false, success: true } });
  }, [createRoleData, dispatch]);

  const { new: newUser, edit, delete: deleteUser, submitEdit, submitDelete, variables } = user;
  useEffect(() => {
    newUser && tryQuery(postSignupUser, { variables });
    edit && submitEdit && tryQuery(postEditUser, { variables });
    deleteUser && submitDelete && tryQuery(postDeleteUser, { variables });
  }, [
    newUser,
    edit,
    deleteUser,
    submitEdit,
    submitDelete,
    variables,
    postSignupUser,
    postEditUser,
    postDeleteUser,
  ]);

  useEffect(() => {
    signUpData &&
      dispatch({ type: SET_USER, payload: { new: false, loading: false, success: true } });
    editUserData &&
      dispatch({ type: SET_USER, payload: { edit: false, loading: false, success: true } });
    deleteData &&
      dispatch({ type: SET_USER, payload: { delete: false, loading: false, success: true } });
  }, [signUpData, editUserData, deleteData, dispatch]);

  return { data, loading, error, refetch };
}
