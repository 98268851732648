import React, { useEffect, useState, useContext } from "react";
import style from "../styles.module.scss";
import {
  List,
  Alert,
  Col,
  Typography,
  Row,
  Avatar,
  Popconfirm,
  Tooltip,
  Pagination,
  Modal,
  Spin,
  notification,
  Input,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faEnvelopeOpenText,
  faTrash,
  faUserMinus,
  faUserClock,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  getCodesGql,
  removeCodeGql,
  sendEmailCodeGql,
  creadeCodeGql,
  removeCodeBatchGql,
  sendBatchEmailCodeGql,
} from "../gqlTags";

import { ContextCompanyCodes } from "../index";

import defaultUser from "../../../../assets/img/userPatient.png";
import { HandleError } from "../../../../utils.js/HandleError";

import ModalNewAffiliate from "./modalNewAffiliate";
import ContentCodesCreate from "./contentCodesCreate";

const { Paragraph } = Typography;

const UserCodes = () => {
  const { state } = useContext(ContextCompanyCodes);

  const DEFAULT_FILTER_CODE = [
    {
      byId: {
        field: "COMPANY",
        value: state.id,
      },
    },
  ];

  const [visibleModalNew, setVisibleModalNew] = useState(false);
  const [dataItems, setDataItems] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(15);
  const [filters, setFilters] = useState(DEFAULT_FILTER_CODE);

  const { loading, data } = useQuery(getCodesGql, {
    variables: {
      perPage: perPage,
      page: currentPage,
      sort: [],
      filters: filters,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const [fetchSendEmailCode, { loading: loadingSendEmail, data: dataSendEmail }] = useMutation(
    sendEmailCodeGql,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["codes", "patients"],
    }
  );

  const [fetchSendBatchEmailCode, { loading: loadingSendBatchEmail, data: dataSendBatchEmail }] =
    useMutation(sendBatchEmailCodeGql, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["codes", "patients"],
    });
  const [fetchDeleteCode, { loading: loadingDelete, data: dataDelete }] = useMutation(
    removeCodeGql,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["codes", "patients"],
    }
  );

  const [fetchNewCode, { loading: loadingNew, data: dataNew }] = useMutation(creadeCodeGql, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    refetchQueries: ["codes"],
  });

  const [fetchRemoveCodes, { loading: loadingRemove, data: dataRemove }] = useMutation(
    removeCodeBatchGql,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["codes"],
    }
  );

  useEffect(() => {
    if (!loading && data) {
      const { total = 0, docs = [] } = data.codes;
      const newData = docs.reduce((accumulator, value) => {
        return [
          ...accumulator,
          {
            ...value,
            title: value.userEmail || "N/A",
            description: value.description || "N/A",
            avatar: defaultUser,
            enabled: value.enabled,
          },
        ];
      }, []);
      setDataItems(newData);
      setTotalData(total);
    }
  }, [loading, data]);

  useEffect(() => {
    if (!loadingDelete && dataDelete) {
      if (dataDelete.removeCode) {
        notification.success({
          message: "Código de afiliación eliminado con éxito",
        });
      } else {
        notification.warning({
          message: "Código de afiliación no pudo ser borrado",
        });
      }
    }
  }, [loadingDelete, dataDelete]);

  useEffect(() => {
    if (!loadingSendEmail && dataSendEmail) {
      if (dataSendEmail.sendEmailCode) {
        notification.success({
          message: "Código reenviado por email",
        });
      } else {
        notification.warning({
          message: "Código no pudo ser reenviado",
        });
      }
    }
  }, [loadingSendEmail, dataSendEmail]);

  useEffect(() => {
    if (!loadingSendBatchEmail && dataSendBatchEmail) {
      if (dataSendBatchEmail.sendBatchEmailCode) {
        notification.success({
          message: "Códigos reenviados por email",
        });
      } else {
        notification.warning({
          message: "Códigos no pudieron ser reenviados",
        });
      }
    }
  }, [loadingSendBatchEmail, dataSendBatchEmail]);

  useEffect(() => {
    if (!loadingNew && !loadingRemove && (dataNew || dataRemove)) {
      setVisibleModalNew(false);

      Modal.info({
        closable: true,
        destroyOnClose: true,
        width: 600,
        centered: true,
        onCancel: () => {},
        title: <p className='font-semibold'>Resultado de la creación de códigos</p>,
        content: (
          <div className={"flex mt-5 flex-col " + style.containerModalCreate}>
            <ContentCodesCreate data={dataNew?.createCode || dataRemove?.removeCodeBatch} />
          </div>
        ),
        okText: "cerrar",
      });
    }
  }, [loadingNew, loadingRemove, dataNew, dataRemove]);

  const onChangeSearch = (e) => {
    const value = e.target.value;

    if (value && value.length) {
      setFilters([
        ...DEFAULT_FILTER_CODE,
        {
          byText: {
            field: "USEREMAIL",
            value: e.target.value.trim(),
          },
        },
      ]);
    } else {
      setFilters(DEFAULT_FILTER_CODE);
    }

    setCurrentPage(1);
  };

  const changePagination = (value) => {
    setCurrentPage(value);
  };

  const clickNew = () => {
    setVisibleModalNew(true);
  };

  const clickDelete = (item) => {
    Modal.confirm({
      closable: true,
      destroyOnClose: true,
      onCancel: () => {},
      cancelText: "Cancelar",
      title: (
        <p className='text-danger'>
          ¿Esta seguro de eliminar el código? <FontAwesomeIcon className='ml-2' icon={faTrash} />
        </p>
      ),
      content: (
        <div className='flex mt-5 flex-col'>
          <p className='text-md font-semibold mb-1 text-left'>User: </p>
          <p className='text-md mb-3 text-left'>{item.userEmail}</p>
          <p className='text-md font-semibold mb-1 text-left'>Código: </p>
          <p className='text-md mb-5 text-left'>{item.code}</p>
        </div>
      ),
      onOk: () => onOkDelete(item),
      okText: "Eliminar",
    });
  };

  const clickSendEmail = async (item) => {
    try {
      await fetchSendEmailCode({
        variables: {
          company: state.id,
          code: item.id,
        },
      });
    } catch (e) {
      notification.error({
        message: "Internal server error ",
        description: HandleError(e),
      });
    }
  };

  const clickSendBatchEmail = async (item) => {
    try {
      await fetchSendBatchEmailCode({
        variables: {
          company: state.id,
        },
      });
    } catch (e) {
      notification.error({
        message: "Internal server error ",
        description: HandleError(e),
      });
    }
  };

  const onOkDelete = async (item) => {
    try {
      await fetchDeleteCode({
        variables: {
          id: item.id,
        },
      });
    } catch (e) {
      notification.error({
        message: "Internal server error ",
        description: HandleError(e),
      });
    }
  };

  const onOkNew = async (remove, items) => {
    try {
      await (!remove ? fetchNewCode : fetchRemoveCodes)({
        variables: {
          userEmail: items,
          company: state.id,
        },
      });
    } catch (e) {
      notification.error({
        message: "Internal server error ",
        description: HandleError(e),
      });
    }
  };

  const isLoadingSpin = loadingDelete || loadingNew || loading || loadingSendEmail;

  return (
    <Spin spinning={isLoadingSpin}>
      {!loading && (
        <ModalNewAffiliate
          onOk={onOkNew}
          loading={isLoadingSpin}
          onCancel={setVisibleModalNew}
          visible={visibleModalNew}
        />
      )}
      <div className='w-full mx-auto flex flex-col h-full xs:mb-5 lg:mb-0'>
        <div className='w-full flex flex-row'>
          <p className='mr-auto font-semibold text-lg mb-3'>Usuarios afiliados</p>
          <div
            className={
              "flex flex-row ml-auto text-lg mb-3 text-success hover:text-success-hover " +
              style.newUser
            }
            onClick={() => clickNew()}
          >
            <FontAwesomeIcon className='text-center mr-2 mt-1' size='md' icon={faPen} />
            <p>Editar Afiliados</p>
          </div>
        </div>

        <div className='w-full flex flex-row'>
          <div className='mr-auto font-semibold text-lg mb-3 xs:w-full md:w-1/2 lg:w-2/3'>
            <Input
              autoComplete='off'
              name='findEmailName'
              id='findEmailId'
              placeholder='Buscar por Email'
              allowClear
              onChange={(e) => onChangeSearch(e)}
            />
          </div>
          <RenderSendEmail onClick={() => clickSendBatchEmail()} batch />,
        </div>

        <Row id='rowFormUsers' gutter={[16, 16]} className={"md:pr-1 md:pl-3 " + style.formDetails}>
          <Col xs={24}>
            <List
              itemLayout='horizontal'
              dataSource={dataItems}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <RenderSendEmail key={0} item={item} onClick={() => clickSendEmail(item)} />,
                    <RenderDelete key={1} onClick={() => clickDelete(item)} />,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<RenderAvatar {...item} />}
                    title={
                      <Paragraph className='mb-1 break-all font-semibold'>{item.title}</Paragraph>
                    }
                    description={<RenderDescription {...item} />}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <Pagination
          className='xs:ml-auto xs:mr-auto lg:mr-0 lg:ml-auto mt-2'
          onChange={changePagination}
          defaultCurrent={1}
          pageSize={perPage}
          disabled={isLoadingSpin}
          current={currentPage}
          total={totalData}
          showSizeChanger={false}
        />
      </div>
    </Spin>
  );
};

const RenderAvatar = (item) => {
  if (item.description && item.description !== "N/A") {
    return (
      <Tooltip title='La afiliación ha sido cancelada por el usuario'>
        <Avatar
          size={48}
          className='bg-transparent'
          icon={<FontAwesomeIcon className='text-warning' icon={faUserMinus} />}
        />
      </Tooltip>
    );
  }
  const className = item.enabled ? "text-gray" : "text-success";
  const icon = item.enabled ? faUserClock : faUserCheck;
  const text = item.enabled ? "Código no utilizado" : "Código utilizado";
  return (
    <Tooltip title={text}>
      <Avatar
        size={48}
        className='bg-transparent'
        icon={<FontAwesomeIcon className={className} icon={icon} />}
      />
    </Tooltip>
  );
};

const RenderDescription = (item) => {
  return (
    <>
      <Paragraph
        code
        disabled={!item.enabled}
        className='mb-1 break-all text-xs '
        copyable={item.enabled}
      >
        {item.code}
      </Paragraph>
      {item.description && item.description !== "N/A" && (
        <Alert banner message={<span className='break all text-xs'>{item.description}</span>} />
      )}
    </>
  );
};

const RenderDelete = ({ onClick }) => {
  return (
    <Tooltip title='Eliminar código de afiliación'>
      <FontAwesomeIcon className='text-danger' onClick={onClick} icon={faTrash} />
    </Tooltip>
  );
};

const RenderSendEmail = ({ batch, item, onClick }) => {
  if (batch) {
    return (
      <Popconfirm
        title='¿ Desea reenviar los códigos pendientes ?'
        okText='Si'
        cancelText='No'
        onConfirm={onClick}
      >
        <Tooltip placement='bottomLeft' title='Reenviar correos electrónicos con código'>
          <FontAwesomeIcon
            className='text-primary cursor-pointer'
            icon={faEnvelopeOpenText}
            size='lg'
          />
        </Tooltip>
      </Popconfirm>
    );
  }
  if (item && item.enabled) {
    return (
      <Popconfirm
        title='¿ Desea Reenviar el código ?'
        okText='Si'
        cancelText='No'
        onConfirm={onClick}
      >
        <Tooltip placement='bottomLeft' title='Reenviar correo electrónico con el código'>
          <FontAwesomeIcon className='text-primary' icon={faEnvelopeOpenText} />
        </Tooltip>
      </Popconfirm>
    );
  } else {
    return null;
  }
};

export default UserCodes;
