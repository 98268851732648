import React from "react";
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function CheckCircle({ children }) {
  return (
    <div className='flex flex-row'>
      <FontAwesomeIcon className='mr-3 mt-1 text-primary text-2xl' icon={faCheckCircle} />
      <span className='text-lg md:text-xl'>{children}</span>
    </div>
  );
}
