import gql from "graphql-tag";

export const completeAppointmentGql = gql`
  mutation updateAppointment($id: String!, $status: updateAppointmentStatus!, $patient: String!) {
    updateAppointment(id: $id, status: $status, patient: $patient) {
      id
    }
  }
`;

export const appointmentByIdGql = gql`
  query appointmentById($id: String!) {
    appointmentById(id: $id) {
      id
      appointmentDateAndTime
      newSymptoms
      reasonForConsultation
      usable
      status
      newCurrentMedication
      patient {
        id
        fullName
        pictureURL {
          url
        }
        openFireUsername
        openFirePassword
        dateOfBirth
        weight
        sex
        height
        newDrugAllergies
        newMedicalConditions
        typeOfBlood
        plans {
          plan {
            name
          }
        }
        belongsToCompany {
          fullName
        }
      }
      doctor {
        id
        fullName
        openFireUsername
        openFirePassword
        pictureURL {
          url
        }
      }
      vonageSessionId
      log {
        firstMessagePatient
        firstMessageDoctor
      }
    }
  }
`;

export const appointmentStatusGql = gql`
  query appointmentById($id: String!) {
    appointmentById(id: $id) {
      status
      usable
      patient {
        id
        fullName
        pictureURL {
          url
        }
      }
      doctor {
        id
      }
    }
  }
`;
