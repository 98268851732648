import React, { useContext } from "react";
// antd
import Card from "antd/lib/card";
import Button from "antd/lib/button";
import Typography from "antd/lib/typography";
// styles
import styles from "../../style.module.scss";
import { GlobalContext } from "views/App";
import getValidNationality from "./getValidNationality";

const { Title } = Typography;

export default function BannerGold() {
  const { state } = useContext(GlobalContext);
  const { user } = state;
  const validNationality = getValidNationality(user?.nationality);
  const props = {
    Card: {
      className: styles.BannerGold,
    },
    Btn: {
      href: validNationality
        ? "https://api.whatsapp.com/send?phone=50587131519&text=Hola%20soy%20afiliado%20EasyMD%2c%20me%20gustar%c3%ada%20comprar%20medicamentos"
        : "https://play.google.com/store/apps/details?id=com.easymdv2.android",
      target: "_blank",
      children: validNationality ? "Comprar" : "Descarga el App",
    },
    Title: {
      level: 3,
      children: validNationality ? "Compra de farmacia en línea" : "Compra de farmacia en línea",
      className: "text-white",
    },
  };

  return (
    <Card {...props.Card} actions={[<Button {...props.Btn} />]}>
      <Title {...props.Title} />
    </Card>
  );
}
