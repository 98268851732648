import { useState, useEffect, useContext } from "react";

// lodash
import { uniqueId, isNull, isString, get, toLowerCase } from "lodash";

import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { appointmentMDGql, appointmentStatusGql } from "./gqlTags";

// context
import { GlobalContext } from "../../App";

// utils
import { verifyIdUserVsDoctor, verifyUsable, verifyFinish, verifyRenderChat } from "./utils";

const UseValidatorCita = (id) => {
  const { state } = useContext(GlobalContext);
  const { user } = state;

  const [dataAppointment, setDataAppointment] = useState(null);
  const [notFound, setNotFound] = useState(null);
  const [isError, setIsError] = useState(null);
  const [isUsable, setIsUsable] = useState(null);
  const [isRenderChat, setIsRenderChat] = useState(null);
  const [isFinish, setIsFinish] = useState(null);
  const [infoForChat, setInfoForChat] = useState(null);
  const [listenerId] = useState(uniqueId("ListenerDoctorMD_"));

  const [getInfoAppointment, { loading, data, error }] = useLazyQuery(appointmentMDGql, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: loadingStatus,
    error: errorStatus,
    data: dataStatus,
    startPolling,
    stopPolling,
    refetch,
  } = useQuery(appointmentStatusGql, {
    variables: { id: id },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (errorStatus && !errorStatus?.networkError) {
      setIsError(true);
    }
    if (error && !error?.networkError) {
      setIsError(true);
    }
  }, [error, errorStatus]);

  useEffect(() => {
    startPolling(5000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  useEffect(() => {
    if (isNull(id) || !isString(id)) {
      setNotFound(true);
    } else {
      getInfoAppointment({
        variables: {
          id: id,
        },
      });
    }
  }, [id, getInfoAppointment]);

  useEffect(() => {
    if (!loadingStatus) {
      if (dataStatus) {
        const { status } = dataStatus.appointmentmdById;
        const isUsableAppointment = verifyUsable(status);
        const isFinishAppointment = verifyFinish(status);
        const isRenderChat = verifyRenderChat(status);
        setIsFinish(isFinishAppointment);
        setIsRenderChat(isRenderChat);
        setIsUsable(isUsableAppointment);
        if (isFinishAppointment) {
          stopPolling();
        }
      } else if (errorStatus) {
        setNotFound(true);
      }
    }
  }, [loadingStatus, dataStatus, errorStatus, stopPolling]);

  useEffect(() => {
    if (!loading) {
      if (data) {
        const appointment = data.appointmentmdById;
        const patient = data.appointmentmdById.patient;
        if (!appointment.doctor) {
          setNotFound(true);
        } else {
          const isValidId = verifyIdUserVsDoctor(user.id, appointment.doctor.id);
          const userChat = {
            hasBlockedMe: false,
            blockedByMe: false,
            role: "default",
            avatar: patient.pictureURL.url,
            _id: patient?.id,
            name: patient?.fullName,
            username: patient?.openFireUsername,
          };
          setInfoForChat(userChat);
          if (isValidId) {
            setDataAppointment(data.appointmentmdById);
          } else {
            setNotFound(true);
          }
        }
      } else if (error) {
        setNotFound(true);
      }
    }
  }, [loading, data, error, user]);

  return {
    dataAppointment: dataAppointment,
    isError,
    notFound: notFound,
    isUsable: isUsable,
    isFinish: isFinish,
    isRenderChat: isRenderChat,
    infoForChat: infoForChat,
    loadingBrand: loading || (!loading && !data && !error),
    refetchStatus: refetch,
    listenerId: listenerId,
  };
};

export default UseValidatorCita;
