import React, { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
// antd
import Card from "antd/lib/card";
import Avatar from "antd/lib/avatar";
import notification from "antd/lib/notification";
import Button from "antd/lib/button";
import Tooltip from "antd/lib/tooltip";
// util
import { HandleError } from "utils.js/HandleError";
// gql
import { getMeAffiliateInfo } from "../../../gql";
// components
import EmptyCompany from "./EmptyCompany";
import HOCConfirmMutation from "components/HOCConfirmMutation";

export default function CardCompany(_) {
  const { variables, Hocc, Btn } = _;
  const { data, loading, error } = useQuery(getMeAffiliateInfo, {
    variables,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
  }, [error]);

  const props = {
    Card: {
      title: <div style={{ marginTop: "-19px" }}>Afiliación Actual</div>,
    },
    Meta: {
      title: data ? data.patientById.belongsToCompany?.fullName : "",
      avatar: <Avatar src={data && data.patientById.belongsToCompany?.pic.url} size={56} />,
      description: data ? data.patientById.belongsToCompany?.description : "",
    },
  };

  if (data && !data.patientById.belongsToCompany) {
    return <EmptyCompany />;
  }

  return (
    <Card
      className='my-2'
      loading={loading}
      title={!loading && data && !error && <Card.Meta {...props.Meta} />}
      extra={
        !loading && (
          <HOCConfirmMutation {...Hocc}>
            <Tooltip title='Eliminar afiliación empresarial '>
              <Button {...Btn} />
            </Tooltip>
          </HOCConfirmMutation>
        )
      }
    />
  );
}
