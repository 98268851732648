import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Card, Alert, Spin, Empty, Statistic } from "antd";
import { Line } from "@ant-design/charts";

import SelectorRange, { getDefaultDates } from "components/SelectorRange";
import LoadingSpin from "./loadingSpin";

import isEmpty from "lodash/isEmpty";

import { getAppointmentMDDataChartGql } from "../gqlTags";

const TotalAppointments = ({ id }) => {
  const [datesSelect, setDatesSelect] = useState(getDefaultDates());
  const [dataChart, setDataChart] = useState([]);
  const [totalGeneral, setTotalGeneral] = useState(0);
  const [totalPsychology, setTotalPsychology] = useState(0);

  const { data, loading, error } = useQuery(getAppointmentMDDataChartGql, {
    variables: {
      from: datesSelect.from,
      to: datesSelect.to,
      company: id,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!loading) {
      if (data && !error) {
        const { getAppointmentMDDataChart } = data;
        let totalPsi = 0;
        let totalGen = 0;
        const newDataParse = [];
        let spanishValue = "";
        getAppointmentMDDataChart.map((value, key) => {
          if (value.category === "psychology") {
            totalPsi += value.value;
            spanishValue = "Psicología";
          }
          if (value.category === "general") {
            totalGen += value.value;
            spanishValue = "General";
          }
          return newDataParse.push({
            ...value,
            category: spanishValue,
          });
        });
        setDataChart(newDataParse);
        setTotalGeneral(totalGen);
        setTotalPsychology(totalPsi);
      } else {
        setDataChart([]);
        setTotalGeneral(0);
        setTotalPsychology(0);
      }
    }
  }, [loading, error, data]);

  const changeValueMonth = (value) => {
    setDatesSelect(value);
  };

  var config = {
    data: dataChart,
    xField: "date",
    yField: "value",
    seriesField: "category",
    color: ["#2497CC", "#7AAF37"],
    point: {
      size: 5,
      shape: "diamond",
    },
  };

  return (
    <div className='flex flex-col h-full'>
      <div className='flex xs:flex-col md:justify-between md:flex-row gap-4'>
        <p className='mb-0 text-lg font-semibold'>Número de consultas inmediatas</p>
        <SelectorRange onChange={changeValueMonth} loading={loading} disabled={loading} />
      </div>
      {error && !loading ? (
        <Alert
          message='Server Error'
          description='Error al obtener la información de las citas'
          type='error'
          className='w-full mx-auto my-auto'
          showIcon
        />
      ) : (
        <div className='flex flex-col h-full'>
          <div className='flex xs:flex-col lg:flex-row gap-4 mt-4'>
            <Card
              className='xs:w-full md:w-full lg:w-3/6 shadow-none'
              bodyStyle={{ padding: "1rem" }}
            >
              <LoadingSpin loading={loading} />
              {!loading && (
                <div className='flex flex-col'>
                  <p className='text-lg mb-0'>Medicina General: </p>

                  <Statistic className='text-2xl mb-0' value={totalGeneral + " consultas"} />
                </div>
              )}
            </Card>
            <Card
              className='xs:w-full md:w-full lg:w-3/6 shadow-none'
              bodyStyle={{ padding: "1rem" }}
            >
              <LoadingSpin loading={loading} />
              {!loading && (
                <div className='flex flex-col'>
                  <p className='text-lg mb-0'>Psicología: </p>
                  <Statistic className='text-2xl mb-0' value={totalPsychology + " consultas"} />
                </div>
              )}
            </Card>
          </div>
          <div className='mt-4 w-full'>
            {isEmpty(dataChart) && !loading ? (
              <Empty className='my-5' description='No se encontraron consultas' />
            ) : (
              <Spin spinning={loading}>
                <Line {...config} />
              </Spin>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TotalAppointments;
