import React from "react";
import { Tabs, Row, Col, Tag, Tooltip, Divider } from "antd";
import get from "lodash/get";
import { faVial } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const { TabPane } = Tabs;

const InfoPaciente = (props) => {
  const { infoAppointment } = props;
  const typeOfBlood = get(infoAppointment, ["patient", "typeOfBlood"], "N/A");
  const plansArray = get(infoAppointment, ["patient", "plans"], []);
  const company = get(infoAppointment, ["patient", "belongsToCompany"], {});

  return (
    <Tabs type='card' className='h-full'>
      <TabPane tab='Paciente' key='1'>
        <p className='break-all font-bold text-lg'>Información del paciente</p>

        <Row gutter={[4, 4]} style={{ overflow: "auto", maxHeight: "200px" }}>
          <Col xs={24} className='grid grid-flow-col grid-cols-2 grid-rows-1 gap-4'>
            <p className='mb-0 font-semibold'>Nombre: </p>
            <p className='mb-0'>{infoAppointment.patient.fullName}</p>
          </Col>
          <Col xs={24} className='grid grid-flow-col grid-cols-2 grid-rows-1 gap-4'>
            <p className='mb-0 font-semibold'>Fecha de nacimiento: </p>
            <p className='mb-0'>
              {infoAppointment.patient.dateOfBirth
                ? moment(infoAppointment.patient.dateOfBirth).format("DD-MM-YYYY")
                : "N/A"}
            </p>
          </Col>
          <Col xs={24} className='grid grid-flow-col grid-cols-2 grid-rows-1 gap-4'>
            <p className='mb-0 font-semibold'>Tipo de sangre: </p>
            {!typeOfBlood || typeOfBlood === "N/A" ? (
              <p className='mb-0'>N/A</p>
            ) : (
              <Tag
                className='mb-0'
                style={{ width: "fit-content", maxWidth: "300px" }}
                icon={<FontAwesomeIcon className='faMessage' icon={faVial} />}
                color='#55acee'
              >
                &nbsp;{typeOfBlood}
              </Tag>
            )}
          </Col>
          <Col xs={24} className='grid grid-flow-col grid-cols-2 grid-rows-1 gap-4'>
            <p className='mb-0 font-semibold'>Peso en libras: </p>
            <p className='mb-0'>
              {infoAppointment.patient.weight ? infoAppointment.patient.weight : "N/A"}
            </p>
          </Col>
          <Col xs={24} className='grid grid-flow-col grid-cols-2 grid-rows-1 gap-4'>
            <p className='mb-0 font-semibold'>Sexo: </p>
            <p className='mb-0 capitalize'>
              {infoAppointment.patient.sex ? infoAppointment.patient.sex : "N/A"}
            </p>
          </Col>
          <Col xs={24} className='grid grid-flow-col grid-cols-2 grid-rows-1 gap-4'>
            <p className='mb-0 font-semibold'>Altura en metros: </p>
            <p className='mb-0 capitalize'>
              {infoAppointment.patient.height ? infoAppointment.patient.height : "N/A"}
            </p>
          </Col>
        </Row>
      </TabPane>
      <TabPane tab='Razón' key='2'>
        <p className='break-all font-bold text-lg'>Razón de la cita</p>

        <Row gutter={[4, 4]} style={{ overflow: "auto", maxHeight: "200px" }} className='p-2'>
          <p className='break-words font-semibold mb-0'>{infoAppointment.reasonForConsultation}</p>
        </Row>

        <Divider className='my-2' />

        <Row gutter={[4, 4]} style={{ overflow: "auto", maxHeight: "200px" }}>
          <Col xs={24} lg={12} className='px-2'>
            <p className='mb-2 font-semibold'>Síntomas: </p>
            <Row>
              {infoAppointment.newSymptoms.map((value, index) => {
                return (
                  <Col key={index}>
                    <Tag key={value + "_" + index} color='#29AAE1' className='p-1 mb-2 rounded-lg'>
                      {value.length > 25 ? (
                        <Tooltip title={value.tittle}>{value.substring(0, 22) + " ..."}</Tooltip>
                      ) : (
                        value
                      )}
                    </Tag>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col xs={24} lg={12} className='px-2'>
            <p className='mb-2 font-semibold'>Medicamentos tomados:</p>
            <Row>
              {infoAppointment.newCurrentMedication.map((value, index) => {
                return (
                  <Col key={index}>
                    <Tag key={value + "_" + index} color='#29AAE1' className='p-1 mb-2 rounded-lg'>
                      {value.length > 25 ? (
                        <Tooltip title={value}>{value.substring(0, 22) + " ..."}</Tooltip>
                      ) : (
                        value
                      )}
                    </Tag>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </TabPane>
      <TabPane tab='Historial' key='3'>
        <p className='break-all font-bold text-lg mb-2'>Historial médico del paciente</p>

        <Row gutter={[4, 4]} style={{ overflow: "auto", maxHeight: "200px" }}>
          <Col xs={24} lg={12} className='px-2'>
            <p className='mb-2 font-semibold'>Condiciones médicas: </p>
            <Row>
              {infoAppointment.patient.newMedicalConditions.map((value, index) => {
                return (
                  <Col key={index}>
                    <Tag key={value + "_" + index} color='#29AAE1' className='p-1 mb-2 rounded-lg'>
                      {value.length > 25 ? (
                        <Tooltip title={value.tittle}>{value.substring(0, 22) + " ..."}</Tooltip>
                      ) : (
                        value
                      )}
                    </Tag>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col xs={24} lg={12} className='px-2'>
            <p className='mb-2 font-semibold'>Alergias a medicamentos:</p>
            <Row>
              {infoAppointment.patient.newDrugAllergies.map((value, index) => {
                return (
                  <Col key={index}>
                    <Tag key={value + "_" + index} color='#29AAE1' className='p-1 mb-2 rounded-lg'>
                      {value.length > 25 ? (
                        <Tooltip title={value}>{value.substring(0, 22) + " ..."}</Tooltip>
                      ) : (
                        value
                      )}
                    </Tag>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </TabPane>
      <TabPane tab='Planes de pacientes' key='4'>
        <p className='break-all font-bold text-lg mb-2'>Detalles de planes de pacientes</p>
        <Row gutter={[4, 4]} style={{ overflow: "auto", maxHeight: "200px" }}>
          <Col className='px-2'>
            <p className='mb-2 font-semibold'>Planes de pacientes: </p>
            <Row>
              {plansArray.map(({ plan }, index) => {
                const name = plan.name;
                return (
                  <Col key={index}>
                    <Tag key={name + "_" + index} color='#29AAE1' className='p-1 mb-2 rounded-lg'>
                      {name.length > 25 ? (
                        <Tooltip title={name.tittle}>{name.substring(0, 22) + " ..."}</Tooltip>
                      ) : (
                        name
                      )}
                    </Tag>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        {!!company && (
          <Row gutter={[4, 4]} style={{ overflow: "auto", maxHeight: "200px" }}>
            <Col xs={24} lg={24} className='px-2'>
              <p className='mb-2 font-semibold'>Pertenece a la empresa:</p>
              <h6>{company.fullName}</h6>
            </Col>
          </Row>
        )}
      </TabPane>
    </Tabs>
  );
};

export default InfoPaciente;
