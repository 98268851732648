import React, { useState, useEffect, useContext } from "react";
import { XMPP } from "../../../xmpp";
import { Row, Col, Modal, Button, Divider } from "antd";
import EstadosCitaDoctorNormal from "../components/estadosCitaDoctorNormal";
import LlamadaDiv from "../components/video/indexLlamada";

import { URLPATIENTNORMAL } from "../../../chatCall/utils";

import ButtonLLamarDoctor from "../components/buttonLlamarDoctor";

import moment from "moment";
import { CallChatContext } from "chatCall/store";

require("moment/min/locales.min");
moment.locale("es");

const LlamadaListenerConfig = (props) => {
  const {
    infoAppointment,
    isFinish,
    isAfter,
    isBefore,
    isInAppointment,
    isNormalAppointment,
    infoForChat,
  } = props;
  const [callInProgress, setCallInProgress] = useState(false);
  const [sessionID, setSessionID] = useState(null);
  const [randomForClose, setRandomForClose] = useState(null);
  const [incomingLlamada, setIncomingLlamada] = useState(false);

  const [isWaitForLoginPatient, setIsWaitForLoginPatient] = useState(false);
  const [isBusyOtherCall, setIsBusyOtherCall] = useState(false);
  const [initCallListerner, setInitCallListerner] = useState(false);
  const [showCallAlert, setShowCallAlert] = useState(false);
  const { presences, setCallStatus } = useContext(CallChatContext);

  const getRandomInt = () => {
    const miNewDate = new Date();
    const newRandom = Math.floor(Math.random() * 2500);
    return miNewDate.toLocaleTimeString() + "_" + newRandom;
  };

  useEffect(() => {
    if (!initCallListerner) {
      setInitCallListerner(true);
      XMPP.addCallListener(
        props.listenerID,
        new XMPP.CallListener({
          onIncomingCallReceived(call) {},
          onOutgoingCallAccepted(call) {
            setIsBusyOtherCall(false);
            setCallInProgress(true);
          },
          onOutgoingCallRejected(call) {
            setRandomForClose(getRandomInt());
            setIncomingLlamada(false);
            setCallInProgress(false);
            setIsBusyOtherCall(false);
          },
          onIncomingCallCancelled(call) {
            setRandomForClose(getRandomInt());
            setIncomingLlamada(false);
            setCallInProgress(false);
            setIsBusyOtherCall(false);
          },
          onBusyCall() {
            setIsBusyOtherCall(true);
            setRandomForClose(getRandomInt());
            setIncomingLlamada(false);
            setCallInProgress(false);
          },
        })
      );
    }
  }, [initCallListerner]);

  useEffect(() => {
    return () => {
      XMPP.removeCallListener(props.listenerID);
      terminarSessionLlamada();
    };
  }, []);

  useEffect(() => {
    if (randomForClose) {
      terminarSessionLlamada();
    }
  }, [randomForClose]);

  const terminarSessionLlamada = () => {
    setIsWaitForLoginPatient(false);
    setIsBusyOtherCall(false);
    setSessionID(null);
    setIncomingLlamada(false);
    setCallInProgress(false);
  };

  function getMetaData() {
    const { doctor, id, vonageSessionId } = infoAppointment;
    const { id: doctorId, fullName, openFireUsername, pictureURL } = doctor;

    const metaData = {
      id,
      url: URLPATIENTNORMAL,
      isNormalAppointment: true,
      _id: doctorId,
      name: fullName,
      username: openFireUsername,
      chatWith: infoForChat.username,
      avatar: pictureURL.url,
      vonageSessionId,
    };
    return metaData;
  }

  useEffect(() => {
    if (isFinish && callInProgress) {
      setShowCallAlert(true);
    }
  }, [isFinish, sessionID, callInProgress]);

  function onCallTimeOut() {
    setIncomingLlamada(false);
  }

  const llamar = async () => {
    setIsBusyOtherCall(false);
    setSessionID(null);
    setIncomingLlamada(true);
    setCallInProgress(false);

    const metaData = getMetaData();

    if (presences) {
      for (let key of Object.keys(presences)) {
        if (key.includes(metaData.chatWith)) {
          if (presences[key] === "online") {
            break;
          }
        }
      }
    }

    setIsWaitForLoginPatient(true);
    XMPP.initiateCall(metaData, onCallTimeOut);
    setCallStatus(String("CALL_INITIATED"));
  };

  const disconnectCall = () => {
    setIncomingLlamada(false);
    setCallInProgress(false);
    setIsBusyOtherCall(false);
    const metaData = getMetaData();
    XMPP.disconnectCall(metaData);
    setCallStatus(String("CALL_DISCONNECT"));
  };

  const containerProps = {
    terminarSessionLlamada,
    sessionID,
    infoForChat,
    infoAppointment,
    disconnectCall,
  };

  const callDoctorButton = {
    terminarSessionLlamada: disconnectCall,
    callOngoing: incomingLlamada,
    idAppointment: infoAppointment.id,
    disabled: callInProgress || incomingLlamada || !isInAppointment,
    loading: callInProgress || incomingLlamada,
    onClick: llamar,
    isNormalAppointment,
  };
  const appointmentStatusProps = {
    isFinish,
    isBefore,
    isInAppointment,
    isAfter,
    infoAppointment,
    incomingLlamada,
    isBusyOtherCall,
    isWaitForLoginPatient,
    terminarSessionLlamada,
    setIsBusyOtherCall,
    buttonLlamar: <ButtonLLamarDoctor {...callDoctorButton} />,
  };

  return (
    <Row className='h-full'>
      <Modal
        visible={showCallAlert}
        title='Recordatorio'
        footer={null}
        onCancel={() => setShowCallAlert(false)}
      >
        <h1 style={{ fontSize: "15px" }}>
          <strong>La cita esta por cumplir 30 minutos</strong>
        </h1>
        <Divider />
        <Row justify='end'>
          <Button type='primary' onClick={() => setShowCallAlert(false)}>
            Cerrar
          </Button>
        </Row>
      </Modal>
      {callInProgress ? (
        <Col xs={24}>
          <LlamadaDiv {...containerProps} />
        </Col>
      ) : (
        <Col xs={24} style={{ overflow: "none" }}>
          <EstadosCitaDoctorNormal {...appointmentStatusProps} />
        </Col>
      )}
    </Row>
  );
};

export default React.memo(LlamadaListenerConfig);
