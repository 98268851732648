import moment from "moment";

const initialState = {
  filters: {
    perPage: 15,
    page: 1,
    planId: [],
    text: "",
    from: moment().subtract(1, "year").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
  },
  activeModal: false,
  selectedUser: {},
};

export default initialState;
