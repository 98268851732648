import { useEffect, useContext } from "react";
import { notification } from "antd";
import { ContextTableUsers } from "../index";
import useQueries from "./useQueries";
import {
  SET_MODAL_NEW_USER_OPEN,
  SET_MODAL_NEW_ROLE_OPEN,
  SET_INITIAL_STATE,
  SET_REFETCH,
  SET_FILTER_USERS,
  SET_PAGINATION_VARS,
} from "../store/actions";
import { SET_SEARCH_NAME } from "../store/actions";

export default function useTable() {
  const { data, loading, error, refetch } = useQueries();
  const { state, dispatch } = useContext(ContextTableUsers);
  const { paginationVars, searchName } = state;

  useEffect(() => {
    dispatch({
      type: SET_REFETCH,
      payload: refetch,
    });
  }, [dispatch, refetch]);

  useEffect(() => {
    const newFilter = {};
    if (searchName.length > 0) {
      newFilter.byText = {
        field: "FIRSTNAME",
        value: searchName,
        or: "LASTNAME",
      };
    }
    dispatch({ type: SET_FILTER_USERS, payload: newFilter });
    dispatch({
      type: SET_PAGINATION_VARS,
      payload: {
        perPage: 10,
        page: 1,
      },
    });
  }, [dispatch, searchName]);

  useEffect(() => {
    if (error && !loading) {
      notification.error({
        message: "Error",
        description: error.graphQLErrors[0]
          ? error.graphQLErrors[0].message
          : "Error al solicitar data",
      });
    }
  }, [error, loading]);

  const getDataForTable = () => {
    if (data) {
      return data.users.docs;
    } else {
      return [];
    }
  };

  const getPagination = () => {
    return {
      current: paginationVars.page,
      pageSize: paginationVars.perPage,
      total: data ? data.users.total : 0,
    };
  };

  const handleTableChange = (infoTable) => {
    const { current } = infoTable;
    dispatch({
      type: SET_PAGINATION_VARS,
      payload: {
        ...paginationVars,
        page: current,
      },
    });
  };

  const clickNewUser = () => {
    dispatch({
      type: SET_INITIAL_STATE,
    });
    dispatch({
      type: SET_MODAL_NEW_USER_OPEN,
      payload: true,
    });
  };

  const clickNewRole = () => {
    dispatch({
      type: SET_INITIAL_STATE,
    });
    dispatch({
      type: SET_MODAL_NEW_ROLE_OPEN,
      payload: true,
    });
  };

  const setSearchName = (name) =>
    dispatch({
      type: SET_SEARCH_NAME,
      payload: name,
    });

  return {
    loading,
    getDataForTable,
    getPagination,
    handleTableChange,
    clickNewUser,
    clickNewRole,
    setSearchName,
  };
}
