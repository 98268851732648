import React, { useState, cloneElement, useEffect, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
// antd
import Form from "antd/lib/form";
import Modal from "antd/lib/modal";
import Typography from "antd/lib/typography";
// components
import FormPay from "./FormPay";
import FormCard from "./FormCard";
// static props
import _props from "./staticProps";
// context
import { GlobalContext } from "views/App";
// helpers
import encrypt from "./encrypt";

const { Title } = Typography;

export default function Dialog({ children, onSuccess, onError, gql }) {
  const [form] = Form.useForm();
  const { state } = useContext(GlobalContext);
  const [info, setInfo] = useState({});
  const [visible, setVisible] = useState(false);
  const [add, { loading }] = useMutation(gql);

  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible, form]);

  const onClick = () => setVisible(!visible);

  const onValuesChange = (edit, info) => setInfo({ edit, info });

  const onFinish = (info) => {
    const { id, email } = state.user;
    const token = JSON.stringify(encrypt({ id, email, info }));

    add({ variables: { token } })
      .then((_) => {
        setVisible(false);
        if (onSuccess) onSuccess(_);
      })
      .catch((e) => {
        if (onError) onError(e);
      });
  };

  const props = {
    Modal: {
      ..._props.Modal,
      visible,
      onOk: form.submit,
      keyboard: false,
      onCancel: onClick,
      confirmLoading: loading,
    },
    Form: {
      info,
      form,
      onFinish,
      onValuesChange,
      nam: "card",
      initialValues: {},
    },
  };

  return (
    <>
      {cloneElement(children, { onClick })}
      <Modal {...props.Modal}>
        <Title level={4}>Información de Tarjeta</Title>
        <FormCard {...props.Form} />
        <Title level={4}>Información de pago</Title>
        <FormPay {...props.Form} />
      </Modal>
    </>
  );
}
