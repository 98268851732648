import React, { useState, useEffect, useCallback } from "react";
import { Table, Input, notification, Modal } from "antd";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  patientListGql,
  cancelPlanGql,
  updateInfoPatient,
  getPlans,
  getCompanies,
} from "./gqlTags";
import columns from "./columnsTable";
import styles from "./styles.module.scss";
import { HandleError } from "../../../utils.js/HandleError";
import ContentModalDelete from "./contentModalDelete";
import ModalEdit from "./ModalEditInfo";
import SelectFilterPlans from "./SelectFilterPlans";
import InputCompanyFilter from "components/InputCompanyFilter";

const TableDoctor = () => {
  const [paginationVars, setPaginationVars] = useState({
    perPage: 10,
    page: 1,
  });
  const [nombreBuscar, setNombreBuscar] = useState("");
  const [correoBuscar, setCorreoBuscar] = useState("");
  const [companyFilter, setCompanyFilter] = useState("");
  const [filterDoctor, setFilterDoctor] = useState([]);
  const [patientWithPlan, setPatientWithPlan] = useState([]);
  const [selectFilterPlan, setSelectFilterPlan] = useState(null);
  const [notified, setNotified] = useState(false);

  const { loading: loadingPlans, error: errorPlans, data: dataPlans } = useQuery(getPlans);

  const {
    loading: loadingCompanies,
    error: errorCompanies,
    data: dataCompanies,
  } = useQuery(getCompanies);

  const { data, loading, error, refetch } = useQuery(patientListGql, {
    variables: {
      ...paginationVars,
      patientFilter: filterDoctor,
      planFilter: patientWithPlan,
      companyFilter,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const [fetchCancelPlan, { data: dataMutation, loading: loadingMutation, error: errorMutation }] =
    useMutation(cancelPlanGql, {
      variables: {
        ...paginationVars,
        filters: filterDoctor,
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    });

  const [
    postPatientChanges,
    { data: dataPatientMutation, loading: loadingPatientMutation, error: errorPatientMutation },
  ] = useMutation(updateInfoPatient, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const refetchTable = useCallback(() => {
    refetch({
      variables: {
        ...paginationVars,
        filters: filterDoctor,
        companyFilter,
      },
    });
  }, [filterDoctor, companyFilter, paginationVars, refetch]);

  useEffect(() => {
    const newFilter = [];
    if (nombreBuscar.length > 0) {
      newFilter.push({
        byText: {
          field: "FIRSTNAME",
          value: nombreBuscar,
          or: "LASTNAME",
        },
      });
    }
    setFilterDoctor(newFilter);
    setPaginationVars({
      perPage: 10,
      page: 1,
    });
  }, [nombreBuscar]);

  useEffect(() => {
    const newFilter = [];
    if (correoBuscar.length > 0) {
      newFilter.push({
        byText: {
          field: "EMAIL",
          value: correoBuscar,
        },
      });
    }
    setFilterDoctor(newFilter);
    setPaginationVars({
      perPage: 10,
      page: 1,
    });
  }, [correoBuscar]);

  useEffect(() => {
    const newFilterWithPlan = [];
    if (selectFilterPlan != null) {
      newFilterWithPlan.push({
        byCondition: {
          field: "WITHPLAN",
          value: selectFilterPlan,
        },
      });
    }
    setPatientWithPlan(newFilterWithPlan);
    setPaginationVars({
      perPage: 10,
      page: 1,
    });
  }, [selectFilterPlan]);

  useEffect(() => {
    if ((error || errorPlans || errorCompanies) && !loading) {
      notification.error({
        message: "Error",
        description: error.graphQLErrors[0]
          ? error.graphQLErrors[0].message
          : "Error al solicitar data",
      });
    }
  }, [error, errorPlans, errorCompanies, loading]);

  useEffect(() => {
    if (!notified && !loading && (dataMutation || dataPatientMutation)) {
      if (!loadingMutation && dataMutation?.cancelPlan) {
        notification.success({
          message: "El plan se ha eliminado con éxito de la cuenta del paciente",
        });
        setNotified(true);
      }
      if (!loadingPatientMutation && dataPatientMutation) {
        notification.success({
          message: "El cambio de informacion ha sido un éxito en la cuenta del paciente",
        });
        setNotified(true);
      }
      refetchTable();
    } else if (
      (!loadingMutation && errorMutation) ||
      (!loadingPatientMutation && errorPatientMutation)
    ) {
      notification.error({
        message: "Error",
        description: HandleError(errorMutation || errorPatientMutation),
      });
    }
  }, [
    notified,
    dataMutation,
    loadingMutation,
    error,
    loading,
    refetchTable,
    errorMutation,
    dataPatientMutation,
    loadingPatientMutation,
    errorPatientMutation,
  ]);

  const getDataForTable = () => {
    if (data) {
      return data.usersWithPlan.docs;
    } else {
      return [];
    }
  };

  const getPagination = () => {
    return {
      current: paginationVars.page,
      pageSize: paginationVars.perPage,
      total: data ? data.usersWithPlan.total : 0,
      showSizeChanger: false,
    };
  };

  const handleTableChange = (infoTable) => {
    const { current } = infoTable;
    setPaginationVars({
      ...paginationVars,
      page: current,
    });
  };

  const deletePlanPatient = async (id, patientName, plans, email, company) => {
    Modal.info({
      title: "Planes del paciente",
      className: "w-2/4",
      content: (
        <ContentModalDelete
          idUser={id}
          plans={plans}
          email={email}
          name={patientName}
          company={Boolean(company?.companyPlan)}
          deletePlan={onOkDeletePlan}
        />
      ),
      okText: "Cerrar",
    });
  };

  const onOkDeletePlan = async (idPlan, idUser) => {
    try {
      setNotified(false);
      Modal.destroyAll();
      await fetchCancelPlan({
        variables: {
          userId: idUser,
          planId: idPlan,
        },
      });
    } catch (error) {
      refetchTable();
    }
  };

  const editPatient = async (id, patientName, plans, email, phoneNumber, company) => {
    Modal.info({
      title: "Editar datos del paciente",
      className: "w-2/4",
      content: (
        <ModalEdit
          idUser={id}
          plans={plans}
          planslist={dataPlans}
          email={email}
          name={patientName}
          phoneNumber={phoneNumber}
          company={company}
          companylist={dataCompanies}
          confirmChange={onOkEditPatient}
        />
      ),
      okText: "Cerrar",
    });
  };

  const onOkEditPatient = async (idUser, newVariables) => {
    try {
      setNotified(false);
      Modal.destroyAll();
      await postPatientChanges({
        variables: {
          id: idUser,
          ...newVariables,
        },
      });
    } catch (error) {
      refetchTable();
    }
  };

  return (
    <>
      <div className='w-full flex xs:flex-col sm:flex-row mb-3'>
        <Input
          className={"w-full mx-auto sm:mx-0 " + styles.max250px}
          placeholder='Buscar por nombre'
          value={nombreBuscar}
          onChange={(e) => setNombreBuscar(e.target.value)}
        />
        <Input
          className={"w-full mx-auto sm:mx-0 sm:ml-3 " + styles.max250px}
          placeholder='Buscar por correo'
          value={correoBuscar}
          onChange={(e) => setCorreoBuscar(e.target.value)}
        />{" "}
        <SelectFilterPlans value={selectFilterPlan} setValue={setSelectFilterPlan} />
        <InputCompanyFilter
          companies={dataCompanies}
          value={companyFilter}
          setValue={setCompanyFilter}
        />
      </div>
      <Table
        columns={columns(
          deletePlanPatient,
          editPatient,
          loading || loadingMutation || loadingPlans || loadingCompanies
        )}
        rowKey={(record) => record.id}
        dataSource={getDataForTable()}
        pagination={getPagination()}
        loading={
          loading || loadingMutation || loadingPatientMutation || loadingPlans || loadingCompanies
        }
        onChange={handleTableChange}
      />
    </>
  );
};

export default TableDoctor;
