import React, { useContext } from "react";
import { SET_INITIAL_STATE } from "../store/actions";
import { Modal, Alert, Avatar, Empty, Spin } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { couponById } from "../gqlTags";
import { ContextTablaCoupons } from "../index";
import moment from "moment";

const ModalDetail = ({ visible }) => {
  const { state, dispatch } = useContext(ContextTablaCoupons);

  const afterClose = () => {
    dispatch({ type: SET_INITIAL_STATE });
  };

  return (
    <Modal
      title='Detalles del cupon'
      visible={visible}
      destroyOnClose
      onCancel={afterClose}
      footer={null}
      afterClose={afterClose}
      maskClosable={false}
    >
      {visible && state.idCouponSelect ? <BodyDetails id={state.idCouponSelect} /> : null}
    </Modal>
  );
};

const BodyDetails = ({ id }) => {
  const { loading, error, data } = useQuery(couponById, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      id: id,
    },
  });

  if (loading) {
    return (
      <div className='w-full flex justify-center content-center self-center'>
        <Spin />
      </div>
    );
  }
  if (error) {
    return (
      <div className='w-full'>
        <Alert
          message='Error'
          description='Error al obtener la información del cupon'
          type='error'
          className='w-full'
          showIcon
        />
      </div>
    );
  }
  if (data) {
    return (
      <>
        <div className='flex flex-col'>
          <h1 className='text-2xl font-semibold mx-auto mb-2'>{data.couponById.coupon}</h1>
          <p className='mb-0 text-primary font-semibold'>Fecha de creación del cupon </p>
          <p className='mb-2 text-black'>{moment(data.couponById.updatedAt).format("LLLL")}</p>
          <p className='mb-0 text-primary font-semibold'>Duracion</p>
          <p className='mb-2 text-black'>{data.couponById.duration}</p>
          <p className='mb-0 text-primary font-semibold'>Tipo</p>
          <p className='mb-2 text-black'>{data.couponById.type}</p>
          <p className='mb-0 text-primary font-semibold'>Cantidad</p>
          <p className='mb-2 text-black'>{data.couponById.amount}</p>
        </div>
        <div className='xs:ml-5 md:ml-0 xs:mt-0 md:mt-3 flex flex-wrap '>
          <div className='w-full'>
            <p className='mb-0 text-primary font-semibold'>Descripción</p>
            <span className='mb-3 text-black'>
              {data.couponById.description ? (
                data.couponById.description.length < 1 ? (
                  <Empty description='Sin descripción' />
                ) : (
                  data.couponById.description
                )
              ) : (
                <Empty description='Sin descripción' />
              )}
            </span>
          </div>
        </div>
      </>
    );
  }
};
export default ModalDetail;
