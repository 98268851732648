import React, { useContext } from "react";
import { SET_INITIAL_STATE } from "../store/actions";
import { Modal, notification } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { createCoupon } from "../gqlTags";

import { ContextTablaCoupons } from "../index";
import FormCoupon from "../../../../components/FormCoupon";
import { HandleError } from "../../../../utils.js/HandleError";

const ModalNew = ({ visible }) => {
  const { state, dispatch } = useContext(ContextTablaCoupons);

  const afterClose = () => {
    dispatch({ type: SET_INITIAL_STATE });
  };

  const refetchCoupons = () => {
    dispatch({ type: SET_INITIAL_STATE });
    state.refetch();
  };

  return (
    <Modal
      title='Agregar nuevo cupon'
      style={{
        minWidth: "20rem",
      }}
      visible={visible}
      destroyOnClose
      width='50vw'
      onCancel={afterClose}
      footer={null}
      afterClose={afterClose}
      maskClosable={false}
    >
      {visible ? <BodyModalNew closeAndRefetch={refetchCoupons} /> : null}
    </Modal>
  );
};

const BodyModalNew = ({ closeAndRefetch }) => {
  const [postCreateCoupon, { loading, error, data }] = useMutation(createCoupon, {
    fetchPolicy: "no-cache",
  });

  const onFinish = async (values) => {
    if (values.description === undefined) {
      values.description = "";
    }
    try {
      await postCreateCoupon({ variables: { ...values } });
    } catch (e) {
      notification.error({
        message: "Internal server error ",
        description: HandleError(e),
      });
    }
  };

  return (
    <FormCoupon
      loading={loading}
      data={data}
      error={error}
      onFinish={onFinish}
      successCreate={closeAndRefetch}
      title='Creación de nuevo Cupon'
    />
  );
};

export default ModalNew;
