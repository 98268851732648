import React from "react";
// icons
import { faUser, faEnvelope, faLock, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isValidPhoneNumber } from "libphonenumber-js";

const isPhoneNumberNic = (value) => {
  return isValidPhoneNumber(String(value), "NI");
};

export default (edit) => {
  return {
    firstName: {
      item: {
        label: null,
        name: "firstName",
        rules: [{ required: true, message: "Por favor ingrese su Primer Nombre!" }],
      },
      input: {
        disabled: false,
        size: "large",
        prefix: <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faUser} />,
        className: "rounded",
        placeholder: "Primer Nombre",
      },
    },
    lastName: {
      item: {
        label: null,
        name: "lastName",
        rules: [{ required: true, message: "Por favor ingrese su Primer Apellido!" }],
      },
      input: {
        disabled: false,
        size: "large",
        prefix: <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faUser} />,
        className: "rounded",
        placeholder: "Primer Apellido",
      },
    },
    phoneNumber: {
      item: {
        label: null,
        name: "phoneNumber",
        rules: [
          { required: false },
          () => ({
            validator(rule, value) {
              if (value && !isPhoneNumberNic(value)) {
                return Promise.reject("Ingrese un número de teléfono valido!");
              } else {
                return Promise.resolve();
              }
            },
          }),
        ],
      },
      input: {
        disabled: false,
        size: "large",
        prefix: <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faPhone} />,
        className: "rounded",
        placeholder: "Número de teléfono",
      },
    },
    email: {
      item: {
        label: null,
        name: "email",
        rules: [
          {
            required: true,
            message: "Por favor ingrese el Correo Electrónico del usuario!",
          },
          {
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Por favor ingrese un correo valido!",
          },
        ],
      },
      input: {
        disabled: false,
        size: "large",
        prefix: <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faEnvelope} />,
        className: "rounded",
        placeholder: "micorreo@email.com",
      },
    },
    password: {
      item: {
        label: null,
        name: "password",
        rules: [
          {
            required: !edit ? true : false,
            message: "Por favor ingrese su correo Contraseña!",
          },
          {
            pattern: /^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
            message: "La contraseña no cumple los requisitos mínimos!",
          },
        ],
      },
      input: {
        disabled: false,
        autoComplete: "new-password",
        size: "large",
        prefix: <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLock} />,
        className: "rounded",
        placeholder: "Contraseña",
      },
    },
    confirmPassword: {
      item: {
        autoComplete: "new-password",
        label: null,
        name: "confirmPassword",
        rules: [
          {
            required: true,
            message: "Por favor confirme su contraseña !",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject("Las dos contraseñas que ingresaste no coinciden!");
            },
          }),
        ],
      },
      input: {
        disabled: false,
        size: "large",
        prefix: <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLock} />,
        className: "rounded",
        placeholder: "Confirmar contraseña",
      },
    },
    role: {
      item: {
        name: "role",
        label: null,
        rules: [{ required: true, message: "Es necesario asignar un rol." }],
      },
      select: {
        disabled: false,
        mode: "single",
        allowClear: true,
        showArrow: true,
        bordered: true,
        size: "large",
        className: "rounded",
        style: { width: "100%" },
        placeholder: "Rol",
      },
    },
  };
};
