import React from "react";
import PlanProvider from "./Provider";
import Plans from "./Plans";

export default function () {
  return (
    <PlanProvider>
      <Plans />
    </PlanProvider>
  );
}
