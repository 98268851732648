import React, { useContext, Fragment } from "react";
// antd
import Typography from "antd/lib/typography";
// components
import Title from "./Title";
import Medic from "./Medic";
// context
import Context from "./Context";
// helper
import map from "lodash/map";
import join from "lodash/join";

const P = Typography.Paragraph;

export default function General() {
  const { reason, patient, medic } = useContext(Context);

  const props = {
    P: {
      ellipsis: {
        row: 3,
        expandable: false,
      },
      children: reason,
    },
    Medic: {
      pic: medic?.pic.url,
      name: medic?.name,
    },
  };

  return (
    <Fragment>
      <Title>Razón de la Cita</Title>
      {/* <P {...props.P} /> */}
      <P {...props.P}>
      {props.P?.children?.length > 50
    ? props.P.children.substring(0, 50) + "..."
    : props.P.children}
</P>
      <Title level={4}>Paciente</Title>
      <div className='mt-3'>
          <p className='break-words mb-0' style={{ maxHeight: "200px", overflowY: "auto" }}>
          {patient.name}
          </p>
        </div>
      {medic && (
        <Fragment>
          <Title level={4}>Médico</Title>
          <Medic {...props.Medic} />
          {join(map(medic.specialties, "name"), ", ")}
        </Fragment>
      )}
    </Fragment>
  );
}
