import React from "react";

import Card from "antd/lib/card";
import Rate from "antd/lib/rate";

import defaultImage from "../../../../assets/img/viewFamily/avatarFamily.png";

const CardTestimonials = (_) => {
  const { name = "José López", job = "Constructor", image = defaultImage, start = 5 } = _;

  const textDescriptio = _.description
    ? _.description
    : "Lorem ipsum dolor sit amet, ea pri inani simul animal. Mea at movet insolens constituto, no esse perfecto qui. Id delectus oportere rationibus mea, cu mel mazim sadipscing. Nam et ornatus quaestio salutatus, ea eros meis usu.";
  return (
    <Card className={_.className} title={null} bordered={false}>
      <div className='flex flex-col lg:flex-row items-center lg:items-start'>
        <img style={{ width: "80px", height: "auto" }} src={image} alt='Logo' />
        <div className='w-full flex flex-col items-center lg:items-start mx-2 mt-3'>
          <h3 className='mb-0'>
            <strong>{name}</strong>
          </h3>
          <span>{job}</span>
          <div className='h-2 bg-success w-full lg:w-1/4 rounded-full mt-1' />
          <Rate disabled defaultValue={start} />
        </div>
      </div>
      <div style={{ maxHeight: "135px", minHeight: "135px" }} className='overflow-auto'>
        <p title={textDescriptio} className='mt-2 text-justify'>
          {textDescriptio}
        </p>
      </div>
    </Card>
  );
};

export default CardTestimonials;
