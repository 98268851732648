import React from "react";
import Lottie from "react-lottie";
import animation from "assets/lottie/easyMD_loading.json";

const props = {
  width: "25vmax",
  options: {
    loop: true,
    autoplay: true,
    animationData: animation,
  },
};

export default function Loading() {
  return <Lottie {...props} />;
}
