import React, { useContext } from "react";
import { Popover, Tag, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import * as actionNames from "./store/actions";
import { ContextTablaDoctoresMD } from "./index";
import RowAvatar from "components/RowAvatar";

const columns = [
  {
    title: "Nombre",
    dataIndex: "fullName",
    className: "text-center p-1",
    render: (row, fullRow) => (
      <RowAvatar
        text={row}
        row={fullRow}
        description={
          <p className='text-left pb-1 mb-1'>
            # MINSA: <strong>{fullRow.minsaNumber}</strong>
          </p>
        }
      />
    ),
  },
  // {
  //   title: '# MINSA',
  //   dataIndex: 'minsaNumber',
  //   className: 'text-center'
  // },
  {
    title: "Especialidad",
    dataIndex: "specialties",
    className: "text-center w-1/6",
    render: (row, fullRow) => renderSpecialties(row, fullRow),
  },
  {
    title: "Detalles",
    dataIndex: "custom_verInfo",
    className: "text-center w-5por",
    render: (row, fullRow) => <RenderInfo row={row} fullRow={fullRow} />,
  },
  {
    title: "Acciones",
    dataIndex: "custom_acciones",
    className: "text-center w-5por",
    render: (row, fullRow) => <RenderActionsAprobarRechazar fullRow={fullRow} />,
  },
];

const RenderInfo = ({ fullRow }) => {
  const { dispatch } = useContext(ContextTablaDoctoresMD);
  const clickInfo = () => {
    dispatch({
      type: actionNames.SET_INFO_DOCTOR,
      payload: fullRow.id,
    });
    dispatch({
      type: actionNames.SET_MODAL_INFO_OPEN,
      payload: true,
    });
  };
  return (
    <Button
      type='default'
      shape='round'
      size='small'
      className='border-0 shadow-none'
      onClick={() => clickInfo()}
    >
      <FontAwesomeIcon icon={faEye} />
    </Button>
  );
};

const RenderActionsAprobarRechazar = ({ fullRow }) => {
  const { dispatch } = useContext(ContextTablaDoctoresMD);
  const { id } = fullRow;

  const openDeclinar = () => {
    dispatch({
      type: actionNames.SET_INFO_DOCTOR,
      payload: id,
    });
    dispatch({
      type: actionNames.SET_MODAL_DENY_OPEN,
      payload: true,
    });
  };

  return (
    <Button type='primary' danger shape='round' size='small' onClick={() => openDeclinar()}>
      {" "}
      Eliminar
    </Button>
  );
};

const renderSpecialties = (row, fullRow) => {
  if (row) {
    if (row.length > 1) {
      return (
        <Popover
          content={
            <div className='w-full flex flex-col'>
              <p className='font-semibold text-center'>{fullRow.fullName}</p>
              <ul>
                {row.map((value, index) => {
                  return <li key={index}>{value.name}</li>;
                })}
              </ul>
            </div>
          }
        >
          <div className='flex flex-row justify-center'>
            <span>{row[0].name},</span>
            <Tag
              color='blue'
              className='ml-1 flex flex-row justify-center content-center rounded-lg px-1'
            >
              <span className='text-2xl self-center'>+</span>
              {row.length - 1}
            </Tag>
          </div>
        </Popover>
      );
    } else if (row.length === 1) {
      return <span>{row[0].name}</span>;
    }
  }
  return "N/A";
};

export default columns;
