import React from "react";
import moment from "moment";

// lodash
import toInteger from "lodash/toInteger";

export const ColumnsTableTransactions = [
  {
    title: "Monto",
    dataIndex: "amount",
    width: "110px",
    className: "text-center",
    render: (text, row) => (
      <span className='text-left'>
        {text}&nbsp;{row.currency}
      </span>
    ),
  },
  // {
  //   title: 'Estado',
  //   dataIndex: 'status',
  //   width: '110px',
  //   className: 'text-center'
  // },
  {
    title: "Tipo",
    dataIndex: "type",
    width: "110px",
    className: "text-center",
  },
  {
    title: "Número de confirmación",
    dataIndex: "confirmationNumber",
    className: "text-center",
  },
  {
    title: "Fecha de creación",
    dataIndex: "createdAt",
    className: "text-center",
    render: (text) => {
      return <span>{text ? moment(toInteger(text)).format("DD/MM/YYYY") : "N/A"}</span>;
    },
  },
  {
    title: "Descripción",
    dataIndex: "description",
    className: "text-center",
    render: (text) => <span>{text}</span>,
  },
];
