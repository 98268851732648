import React from "react";
import Avatar from "antd/lib/avatar";
import { Card, Col, Row, Tag, Tooltip } from "antd";
import TimeLabel from "../timeLabel";
import { VideoCameraOutlined, WechatOutlined } from "@ant-design/icons";

import styles from "../styles.module.scss";

const DetailAppointment = (props) => {
  return (
    <div className='w-full flex flex-col'>
      <Card bordered={false} bodyStyle={{ padding: "0.75rem" }}>
        <div className='w-full flex xs:flex-col md:flex-row items-center'>
          <div className='md:w-1/6'>
            <Avatar size={64} src={props.picture} />
          </div>
          <div className='flex flex-col md:pl-5 md:w-3/6'>
            <p className='font-semibold mb-1 '>{props.name}</p>
            <TimeLabel time={props.time} />
          </div>
          <div className='flex flex-col md:w-2/6'>
            <Tag
              icon={props.isVideo ? <VideoCameraOutlined /> : <WechatOutlined />}
              color='#67D5F0'
              className={"text-center mx-auto w-full " + styles.tagAppointment}
            >
              {props.textType}
            </Tag>
            <Tag className={"my-1 mx-auto text-center w-full " + styles.tagAppointment}>
              {props.specialty}
            </Tag>
          </div>
        </div>

        <div className='mt-3'>
          <p className='font-bold mb-1'>Razón de la cita:</p>
          <p className='break-words mb-0' style={{ maxHeight: "200px", overflowY: "auto" }}>
            {props.reasonForConsultation}
          </p>
        </div>
        <div className='mt-3'>
          <p className='font-bold mb-1'>Síntomas:</p>
          <Row style={{ maxHeight: "300px", overflowY: "auto" }}>
            {props.newSymptoms.map((value, index) => {
              return (
                <Col key={index}>
                  <Tag
                    key={value + "_" + index}
                    color='#29AAE1'
                    className='py-1 px-2 mb-2 rounded-lg flex justify-center'
                    style={{ minWidth: "60px" }}
                  >
                    {value.length > 25 ? (
                      <Tooltip title={value.tittle}>{value.substring(0, 22) + " ..."}</Tooltip>
                    ) : (
                      value
                    )}
                  </Tag>
                </Col>
              );
            })}
          </Row>
        </div>

        <div className='mt-3'>
          <p className='font-bold mb-1'>Condiciones médicas:</p>
          <Row style={{ maxHeight: "300px", overflowY: "auto" }}>
            {props.newMedicalConditions.map((value, index) => {
              return (
                <Col key={index}>
                  <Tag
                    key={value + "_" + index}
                    color='#29AAE1'
                    className='py-1 px-2 mb-2 rounded-lg flex justify-center'
                    style={{ minWidth: "60px" }}
                  >
                    {value.length > 25 ? (
                      <Tooltip title={value.tittle}>{value.substring(0, 22) + " ..."}</Tooltip>
                    ) : (
                      value
                    )}
                  </Tag>
                </Col>
              );
            })}
          </Row>
        </div>

        {props.footer}
      </Card>
    </div>
  );
};

DetailAppointment.defaultProps = {
  footer: null,
};

export default DetailAppointment;
