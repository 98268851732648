import * as moment from "moment";
// helpers
import map from "lodash/map";
import { statusName } from "utils.js/statusColor";

export default function (params) {
  const { start, end, callBack, onChange, slots } = params;

  if (onChange) {
    onChange({
      from: moment(start).subtract(1, "month").toISOString(),
      to: moment(end).toISOString(),
    });
  }

  const events = map(slots, ({ status, from, to }) => {
    const { labelCalendar, className } = statusName(status);

    return {
      status,
      end: moment(to).add(20, "minutes").toDate(),
      title: labelCalendar || "+",
      start: moment(from).toDate(),
      disabled: ["SCHEDULED", "PENDING", "COMPLETE"].includes(status),
      classNames: className || "add",
    };
  });

  callBack(events);
}
