import * as actionName from "./actions";
import initialState from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionName.SET_INITIAL_STATE:
      return {
        ...initialState,
      };
    case actionName.SET_MODAL_ACEPTAR:
      return {
        ...state,
        modalRechazar: false,
        modalInfo: false,
        modalAceptar: action.payload,
      };

    case actionName.SET_MODAL_RECHAZAR:
      return {
        ...state,
        modalRechazar: action.payload,
        modalInfo: false,
        modalAceptar: false,
      };

    case actionName.SET_MODAL_INFO:
      return {
        ...state,
        modalRechazar: false,
        modalInfo: action.payload,
        modalAceptar: false,
      };

    case actionName.SET_INFO_DOCTOR:
      return {
        ...state,
        infoDoctorSelect: action.payload,
      };

    case actionName.SET_CLOSE_MODAL:
      return {
        ...state,
        modalRechazar: false,
        modalInfo: false,
        modalAceptar: false,
      };

    case actionName.SET_RANDOM_VALUE_FOR_QUERY:
      return {
        ...state,
        randomValue: action.payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;
