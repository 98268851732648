import React, { useState } from "react";
import PlanDescription from "./planDescription";
import PlanContact from "./planContact";
import Button from "antd/lib/button";
import Divider from "antd/lib/divider";
import RenderCheck from "./renderCheck";
import Typography from "antd/lib/typography";

const MobileComponent = (props) => {
  const [periodSelect, setPeriodSelect] = useState("MONTHLY");
  return (
    <div className='w-full h-full'>
      <div className='flex flex-row items-center justify-center mb-8'>
        <Button
          style={{
            height: "55px",
          }}
          className={`border-none m-2 ${
            periodSelect === "MONTHLY" && "shadow-sm text-white button-success"
          }`}
          type='default'
          shape='round'
          size='large'
          onClick={() => setPeriodSelect("MONTHLY")}
        >
          <strong>Mensual</strong>
        </Button>
        <Button
          className={`border-none m-2 ${
            periodSelect === "YEARLY" && "shadow-sm text-white button-success"
          }`}
          style={{
            height: "55px",
          }}
          type='default'
          shape='round'
          size='large'
          onClick={() => setPeriodSelect("YEARLY")}
        >
          <strong>Anual</strong>
        </Button>
      </div>
      <Divider />
      {props.plans.map((value, index) => (
        <div key={index}>
          <PlanDescription openModal={props.openModal} periodSelect={periodSelect} plan={value} />
          {value.features.map((valueFeature, indexFeature) => (
            <div className='w-full flex flew-row bg-gray-100' key={indexFeature}>
              <div className='ml-3'>
                <RenderCheck colorCol={true} size={"3x"} />
              </div>
              <div className={`flex flex-col mx-3 my-3`}>
                <p className='mb-0 text-xl block capitalize'>
                  <strong>{valueFeature.name}</strong>
                </p>
                <Typography ellipsis={{ row: 2 }}>{valueFeature.description}</Typography>
              </div>
            </div>
          ))}
          <Divider />
        </div>
      ))}
      <div className='mx-2'>
        <PlanContact />
        {props.features.map((value, index) => (
          <div className='w-full flex flew-row bg-gray-100' key={index}>
            <div className='ml-3'>
              <RenderCheck colorCol={true} size={"3x"} />
            </div>
            <div className={`flex flex-col mx-3 my-3`}>
              <p className='mb-0 text-xl block capitalize'>
                <strong>{value.name}</strong>
              </p>
              <Typography ellipsis={{ row: 2 }}>{value.description}</Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileComponent;
