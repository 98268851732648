import gql from "graphql-tag";

export const companyByIdGql = gql`
  query companyById($id: String!) {
    companyById(id: $id) {
      id
      fullName
      firstName
      lastName
      phoneNumber
      description
      email
      contactEmail
      contactName
      contactPhoneNumber
      userCap
      whiteLabel
      role {
        name
      }
      pictureURL {
        url
      }
      plans {
        plan {
          id
          name
        }
        period {
          price
        }
        expireAt
        status
      }
    }
  }
`;

export const updateCompanyPicture = gql`
  mutation updateCompany($id: String!, $file: Upload) {
    updateCompany(id: $id, picture: $file) {
      id
      pictureURL {
        id
        url
      }
    }
  }
`;

export const updateCompanyInfo = gql`
  mutation updateCompany(
    $id: String!
    $firstName: String
    $phoneNumber: String
    $description: String
    $contactName: String
    $contactEmail: String
    $contactPhoneNumber: String
    $lastName: String
    $companyPlan: String
    $whiteLabel: Boolean
  ) {
    updateCompany(
      id: $id
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      description: $description
      contactName: $contactName
      contactEmail: $contactEmail
      contactPhoneNumber: $contactPhoneNumber
      companyPlan: $companyPlan
      whiteLabel: $whiteLabel
    ) {
      id
    }
  }
`;

export const removeCodeGql = gql`
  mutation removeCode($id: String!) {
    removeCode(id: $id)
  }
`;

export const creadeCodeGql = gql`
  mutation createCode($userEmail: [String!]!, $company: String!) {
    createCode(userEmail: $userEmail, company: $company) {
      email
      createCode
      message
    }
  }
`;

export const removeCodeBatchGql = gql`
  mutation removeCodeBatch($userEmail: [String!]!, $company: String!) {
    removeCodeBatch(userEmail: $userEmail, company: $company) {
      email
    }
  }
`;

export const getCodesGql = gql`
  query codes($perPage: Float, $page: Float, $sort: [codeSort!], $filters: [codeFilter!]) {
    codes(perPage: $perPage, page: $page, sort: $sort, filters: $filters) {
      total
      pages
      docs {
        id
        enabled
        description
        code
        userEmail
      }
    }
  }
`;

export const sendEmailCodeGql = gql`
  mutation sendEmailCode($company: String!, $code: String!) {
    sendEmailCode(company: $company, code: $code)
  }
`;

export const sendBatchEmailCodeGql = gql`
  mutation sendBatchEmailCode($company: String!) {
    sendBatchEmailCode(company: $company)
  }
`;

/// cards

export const getTotalPatientsAffiliateGql = gql`
  query getPatientsAffiliated($id: String!) {
    getPatientsAffiliated(id: $id)
  }
`;

export const getTotalPatientsNoAffiliateGql = gql`
  query codes($filters: [codeFilter!]) {
    codes(filters: $filters) {
      total
    }
  }
`;

export const getCompanyAppointmentsGql = gql`
  query getCompanyAppointments($id: String!, $from: DateTime!, $to: DateTime!) {
    getCompanyAppointments(id: $id, from: $from, to: $to)
  }
`;

/// /
export const getPlansPrice = gql`
  query getPlansPrice {
    plans {
      docs {
        id
        name
        disabled
        periods {
          price
          __typename @include(if: false)
        }
        __typename @include(if: false)
      }
    }
  }
`;
