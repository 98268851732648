import React, { useState } from "react";
import { Modal, Form, Button, Input, Divider } from "antd";
import validatorRules from "../editInfo/validatorRules";

import uniqBy from "lodash/uniqBy";

const ModalNewAffiliate = ({ visible = false, onOk, onCancel, loading }) => {
  const [remove, setRemove] = useState(false);
  const [form] = Form.useForm();

  const onFinishForm = (values) => {
    const arrayEmails = values?.email?.split(",") || [];
    const emailsTrim = arrayEmails.reduce((accumulator, _value) => {
      accumulator.push(_value.trim());
      return accumulator;
    }, []);
    // pasar al padre para la mutacion
    onOk(remove, uniqBy(emailsTrim));
  };

  return (
    <Modal
      title={null}
      visible={visible}
      footer={null}
      destroyOnClose
      maskClosable={false}
      onCancel={() => {
        if (!loading) {
          onCancel(false);
        }
      }}
    >
      <div className='mt-5 flex flex-col'>
        <p className='font-semibold text-xl mb-3 text-center'>Editar afiliado(s)</p>
        <p className='mb-5'>Agregar o remover correo(s) electrónico(s), separarlos con coma.</p>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinishForm}
          scrollToFirstError
          className='w-full flex flex-col'
        >
          <div className='flex flex-row w-full mb-3 mx-auto'>
            <Form.Item
              validateTrigger={["onBlur"]}
              label='Email(s) '
              className='w-full'
              name='email'
              disable={loading}
              rules={[validatorRules.required, validatorRules.multiplesEmails]}
            >
              <Input.TextArea
                disable={loading}
                rows={8}
                placeholder='Correo electrónico del usuario'
              />
            </Form.Item>
          </div>

          <Divider className='w-full my-3' />

          <Form.Item className=' mt-auto mb-2'>
            <div className='w-full flex flex-row justify-between items-center'>
              <Button
                disabled={loading}
                type='default'
                htmlType='submit'
                size='large'
                className='button-success'
                onClick={() => setRemove(true)}
              >
                Remover Código
              </Button>
              <Button
                disabled={loading}
                type='primary'
                htmlType='submit'
                size='large'
                className='button-primary'
                onClick={() => setRemove(false)}
              >
                Enviar Código
              </Button>{" "}
            </div>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalNewAffiliate;
