import React, { useContext, useEffect, useState, useCallback } from "react";
// componets
import FormMiInformacion from "./FormMiInformacion";
import FirmaDoctor from "./FirmaDoctor";
import CardMiSemanaDeTrabajo from "./CardSemanaDeTrabajo";

// store
import * as actionName from "../../storeProfileMedic/actionStoreMedic";
import { approvalStatusDoctor } from "utils.js/statusColor";
import { profileMedicContext } from "../../../index";
import { GlobalContext } from "../../../../App";

// ant
import { Card, Button, Alert } from "antd";

// css
import style from "components/Medic/style.module.scss";

const Tap1Medic = () => {
  const { state, dispatch } = useContext(profileMedicContext);
  const { state: stateGlobal } = useContext(GlobalContext);
  const [visbleAlertSignature, setVisbleAlertSignature] = useState(false);
  const { user } = stateGlobal;
  const { approvalStatus } = state.dataMedic;
  const { balance, loading } = state;
  const { label: labelAlert, type: typeStatus, typeAlert } = approvalStatusDoctor(approvalStatus);

  useEffect(() => {
    if (user) {
      if (user.role.name === "doctorMD") {
        dispatch({
          type: actionName.SET_TABS,
          payload: "4",
        });
      }
    }
  }, [user, dispatch]);

  const alertSignature = useCallback(() => {
    if (state.dataMedic) {
      const image = new Image();
      image.src = state.dataMedic.signature;
      image.onload = () => {
        if (image.width > 800 && image.height > 500) {
          setVisbleAlertSignature(true);
        } else {
          setVisbleAlertSignature(false);
        }
      };
    }
  }, [state.dataMedic]);

  useEffect(() => alertSignature(), [alertSignature]);

  const contedAlertWarning = (label) => {
    return (
      <div className='block justify-start md:flex md:justify-between'>
        <span>{label}</span>
        <Button
          size='small'
          onClick={() => {
            dispatch({
              type: actionName.SET_TABS,
              payload: "3",
            });
          }}
          type='link'
        >
          Agregar Documentos
        </Button>
      </div>
    );
  };

  const configCards = {
    card1: {
      visible: user.role.name === "doctor",
      className:
        user.role.name === "doctor"
          ? "col-span-12 md:col-span-6 lg:col-span-3 mr-4 pb-2 md:m-0 block h-full"
          : "",
    },
    card2: {
      visible: true,
      className:
        user.role.name === "doctor"
          ? "col-span-12 md:col-span-6 h-full mb-0 mt-2"
          : "xs:col-span-12 md:col-span-7",
    },
    card3: {
      visible: true,
      className:
        user.role.name === "doctor"
          ? "col-span-12 lg:col-span-3 mt-2 h-full"
          : "xs:col-span-12 md:col-span-5",
    },
    card4: {
      visible: user.role.name === "doctor",
      className: user.role.name === "doctor" ? "col-span-12 h-full mb-12 md:mb-3" : "",
    },
  };

  return (
    <div
      style={{ width: "98%" }}
      className={
        "grid grid-cols-12 gap-4 mx-auto pt-3 " + (user.role.name === "doctor" ? "h-full" : "")
      }
    >
      {approvalStatus !== "APPROVED" && user.role.name === "doctor" && (
        <div className='col-span-12 mb-1 mt-2 ml-2 mr-2 lg:mr-0'>
          <Alert
            className={`border-${typeStatus}`}
            message={typeStatus === "warning" ? contedAlertWarning(labelAlert) : labelAlert}
            type={typeAlert}
            showIcon
          />
        </div>
      )}

      {configCards.card1.visible && (
        <div className={configCards.card1.className}>
          <Card
            loading={loading}
            className='w-full m-2 bg-warning rounded-lg p-4 h-1/2'
            bodyStyle={{
              margin: "-1.5rem",
            }}
          >
            <h6 className='text-white text-lg mb-2'>
              <strong>Mi Cartera Electronica</strong>
            </h6>
            <h4 className='text-white text-2xl mb-2'>
              <strong>$ {Number(balance).toFixed(2)}</strong>
            </h4>
            <p className='text-white '>Ganancias reflejadas del ultimo corte al dia de hoy</p>
          </Card>

          <Card
            className='w-full m-2 bg-success rounded-lg p-4 h-1/2'
            bodyStyle={{
              margin: "-1.5rem",
            }}
          >
            <h6 className='text-white text-lg mb-2'>
              <strong>AYUDA</strong>
            </h6>
            <p className='text-white'>El equipo administrativo de EasyMD esta qui para ayudarte!</p>
            <p className='text-white '>
              correo: <a href='mailto:info@easymd.co'>info@easymd.co</a>
            </p>

            <Button type='primary' shape='round' size='large'>
              +505 7872 6684
            </Button>
          </Card>
        </div>
      )}

      {configCards.card2.visible && (
        <div className={configCards.card2.className}>
          <Card
            className={`rounded-lg p-4 h-full ${style.cardShadow}`}
            bodyStyle={{
              margin: "-1.5rem",
              maxHeight: user.role.name === "doctor" ? "29.125rem" : "35rem",
            }}
          >
            {<FormMiInformacion role={user.role.name} />}
          </Card>
        </div>
      )}

      {configCards.card3.visible && (
        <div style={{ minHeight: "16rem" }} className={configCards.card3.className}>
          <Card className='w-full h-full' title='Mi Firma'>
            {visbleAlertSignature && (
              <Alert
                className='my-2'
                message='Tu firmal excede el tamaño máximo de 800 x 500 px'
                description={null}
                type='error'
                showIcon
              />
            )}

            <div className={` rounded-lg  h-full w-full ${style.cardShadow} flex flex-col`}>
              <div
                style={{
                  minHeight: "16rem",
                  position: "relative",
                  display: "flex",
                  flexGrow: "1",
                }}
              >
                <FirmaDoctor />
              </div>
            </div>
          </Card>
        </div>
      )}

      {configCards.card4.visible && (
        <div className={configCards.card4.className}>
          <Card
            className=' rounded-lg p-4 cardShadow'
            bodyStyle={{
              margin: "-1.5rem",
            }}
          >
            <CardMiSemanaDeTrabajo />
          </Card>
        </div>
      )}
    </div>
  );
};

export default Tap1Medic;
