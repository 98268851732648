import React, { useState, useEffect, forwardRef } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
// components
import Selector from "./Selector";
// helper
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import findIndex from "lodash/findIndex";

function SelectWordCloud(props, ref) {
  const { gql, path, lazyQuery = false, value, onChange, ...attr } = props;

  const [values, setValues] = useState(value || []);
  const [variables, setVariables] = useState({});

  const [fetch, { loading, data }] = useLazyQuery(gql, {
    variables,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!lazyQuery) fetch();
  }, []);

  useEffect(() => {
    if (onChange) onChange(map(values, "value"));
  }, [values]);

  const onSearch = (value = "") => {
    if (isEmpty(value)) setVariables({});
    else {
      const filters = [{ byText: { field: "NAME", value } }];
      setVariables({ filters, page: 1, perPage: 25 });
    }
  };

  const onSelect = (_, opt) => setValues([opt, ...values]);

  const onRemove = (val) => {
    const index = findIndex(values, ["label", val]);
    const newValues = [...values.slice(0, index), ...values.slice(index + 1)];
    setValues(newValues);
  };

  const options = lazyQuery && isEmpty(variables) ? [] : get(data, path, []);

  const propsSelect = {
    ref,
    loading,
    onSearch,
    onSelect,
    onRemove,
    options,
    values: map(values, "label"),
    ...attr,
  };

  return <Selector {...propsSelect} />;
}

export default forwardRef(SelectWordCloud);
