import React from "react";
// components
import CarouselMD from "components/CarouselMD";
import CardTestimonials from "./CardTestimonials";
import dataSourceCarouselFamily from "../../../../assets/dataSource/dataSourceCarouselFamily";

export default function SectionTwo() {
  return (
    <div className='p-2 my-4 md:my-8 lg:my-10'>
      <div className='container mx-auto'>
        <CarouselMD>
          {dataSourceCarouselFamily.map((value, index) => (
            <CardTestimonials key={index} className='mx-2 my-6 mx-auto lg:w-3/4' {...value} />
          ))}
        </CarouselMD>
      </div>
    </div>
  );
}
