import React, { useState, useContext, useEffect } from "react";
import { Form, Button, Row, Col, Divider, notification, Spin, Select } from "antd";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { updateCompanyInfo, getPlansPrice } from "../gqlTags";
import { ContextCompanyCodes } from "../index";
import { HandleError } from "../../../../utils.js/HandleError";

const EditInfo = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { state } = useContext(ContextCompanyCodes);
  const { loading } = state;
  const plan = state.data.plans && state.data.plans.at(-1);
  const [cancelled, setCancelled] = useState(false);

  const [postPlanChange, { loading: loadingMutation, error: errorMutation, data: dataMutation }] =
    useMutation(updateCompanyInfo, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["companyById"],
    });

  const { loading: loadingPlans, error: errorPlans, data: dataPlans } = useQuery(getPlansPrice);

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById("rowFormDetails");
      if (element) {
        element.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    }, 700);
  }, []);

  useEffect(() => {
    if (errorMutation || errorPlans) {
      notification.error({
        message: "Error",
        description: HandleError(errorMutation || errorPlans),
      });
    }
  }, [errorMutation, errorPlans]);

  useEffect(() => {
    if (!loadingMutation && dataMutation) {
      notification.success({
        message: "Plan de la empresa actualizados",
      });
    }
  }, [loadingMutation, dataMutation]);

  const onFinishForm = async (values) => {
    try {
      await postPlanChange({
        variables: {
          id: state.id,
          companyPlan: values.plan,
        },
      });
      setCancelled(false);
    } catch (e) {
      notification.error({
        message: "Internal server error ",
        description: HandleError(e),
      });
    }
  };

  const cancelClickHandler = async () => {
    try {
      await postPlanChange({
        variables: {
          id: state.id,
          companyPlan: "cancel",
        },
      });
      setCancelled(true);
      form.setFieldsValue({ plan: "+ Agregar Plan" });
    } catch (e) {
      notification.error({
        message: "Internal server error ",
        description: HandleError(e),
      });
    }
  };

  return (
    <Spin spinning={loadingMutation || loading}>
      <div className='w-full mx-auto flex flex-col h-full pt-10'>
        <Form
          form={form}
          layout='vertical'
          className='flex flex-col h-full'
          scrollToFirstError
          onFinish={onFinishForm}
        >
          <Row id='rowFormDetails' gutter={[16, 16]} className='md:pr-1 md:pl-3'>
            <Col xs={24}>
              <Divider className='m-0 text-primary font-semibold ' orientation='left' plain>
                Plan
              </Divider>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name='plan' label={null} required={false}>
                <Select
                  disabled={loadingPlans || errorPlans}
                  loading={loadingPlans}
                  defaultValue={
                    !cancelled && plan?.status === "ACTIVE" ? plan?.plan?.id : "+ Agregar Plan"
                  }
                  mode='single'
                  bordered
                  className='rounded'
                  style={{ width: "100%" }}
                  placeholder='+ Agregar Plan'
                >
                  {dataPlans?.plans.docs.slice(2).map((plan, i) => (
                    <Option value={plan.id} key={i}>
                      {plan.name}
                      {plan.periods.length > 0 && " - $" + plan.periods[0].price}
                    </Option>
                  ))}
                </Select>
              </Form.Item>{" "}
            </Col>
            <Col xs={24} md={12}>
              <div className='flex items-center'>
                {!cancelled && plan?.status === "ACTIVE" && (
                  <p className='mb-0 mt-2 capitalize'>
                    Expiracion: {moment(plan.expireAt).format("MMMM D YYYY")}
                  </p>
                )}
              </div>
            </Col>
          </Row>

          <Form.Item className='mt-auto mb-1 mx-auto'>
            <Button
              disabled={loading || loadingMutation}
              type='primary'
              htmlType='submit'
              size='large'
              className='button-success'
            >
              {plan?.status === "ACTIVE" && !cancelled ? "Cambiar Plan" : "Agregar Plan"}
            </Button>
            {plan?.status === "ACTIVE" && (
              <Button
                disabled={loading || loadingMutation}
                type='primary'
                danger
                onClick={cancelClickHandler}
                size='large'
                className='button ml-10'
              >
                Cancelar Plan
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default EditInfo;
