import React from "react";
import { Form, Input } from "antd";

const FormModalEdit = (props) => {
  const { form } = props;
  return (
    <Form
      form={form}
      layout='horizontal'
      name='basic'
      initialValues={{ remember: true }}
      onFinish={(valueform) => props.onFinishForm(valueform)}
    >
      <label className='mt-2 text-lg text-primary' htmlFor='nameItem'>
        <strong>Nombre del ítem</strong>
      </label>
      <Form.Item
        className='mt-2'
        label={null}
        name='nameItem'
        rules={[
          {
            required: true,
            message: "Por favor ingrese el Nombre del Item!",
          },
        ]}
      >
        <Input
          id='nameItem'
          disabled={props.loading}
          loading={props.loading}
          size='large'
          className='rounded-lg'
          placeholder='Nombre del Item'
        />
      </Form.Item>

      <label className='mt-2 text-lg text-primary' htmlFor='descriptionItem'>
        <strong>Descripción del Ítem</strong>
      </label>
      <Form.Item className='mt-2' label={null} name='descriptionItem'>
        <Input.TextArea
          rows={3}
          id='descriptionItem'
          disabled={props.loading}
          loading={props.loading}
          size='large'
          className='rounded-lg'
          placeholder='Descripción del Item'
        />
      </Form.Item>
    </Form>
  );
};

export default FormModalEdit;
