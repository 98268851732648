import React from "react";
import Lottie from "react-lottie";
// antd
import Button from "antd/lib/button";
// components
import AddBeneficiary from "components/Person/AddBeneficiary";
// styles
import style from "./style.module.scss";
// svg
import animationData from "assets/lottie/add.json";

const props = {
  Lottie: {
    width: "100%",
    height: "100%",
    options: {
      loop: false,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    },
    isClickToPauseDisabled: true,
  },
  Btn: {
    type: "primary",
    children: "NUEVO BENEFICIARIO",
  },
};

export default function Empty() {
  return (
    <div className={style.emptyList}>
      <Lottie {...props.Lottie} />
      <p>No tienes beneficiarios…</p>
      <AddBeneficiary>
        <Button {...props.Btn} />
      </AddBeneficiary>
    </div>
  );
}
