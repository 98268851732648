import React from "react";
import Brand from "../../../components/CitasUtils/BrandLoading/brand";

const Loading = () => {
  return (
    <div className='flex h-screen py-5'>
      <div className='self-center mx-auto'>
        <Brand />
        <p className='mx-auto text-primary text-center font-bold'>
          Cargando información de la cita
        </p>
      </div>
    </div>
  );
};

export default Loading;
