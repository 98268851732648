import React, { useContext } from "react";
import CardDoctorCita from "../cardDoctorCita";
import { Empty } from "antd";

import { CheckOutContext } from "../index";

const DetalleConsulta = () => {
  const { state } = useContext(CheckOutContext);
  const { paciente, razonCita, sintomas, condicionesMedicas, medicamentos, alergias } = state;

  const isEmpty = () => {
    if (!paciente && !razonCita && !sintomas && !condicionesMedicas && !medicamentos) {
      return true;
    }
    return false;
  };

  return (
    <div className='w-full flex flex-col h-full'>
      <p className='text-2xl font-bold text-center'>Detalles de la consulta</p>

      {isEmpty() ? (
        <div className='h-full flex flex-col mx-3'>
          <p className='text-sm mb-5 text-center'>
            {" "}
            Los detalles de la consulta que vas anotando aparecerán y serán compartidos al
            profesional de salud de manera segura (HIPAA){" "}
          </p>
          <Empty description={false} className='my-auto' />
        </div>
      ) : (
        <div className='grid grid-cols-1 md:grid-cols-2 mb-5'>
          <div className='w-1/2 xs:w-full bg-white flex flex-col px-3'>
            <p className='text-lg font-bold text-left break-words'>Información general</p>

            {paciente && (
              <div className='inline-block mb-2'>
                <p className='font-bold text-left mb-1 break-words'> Nombre del paciente: </p>
                <div className='ml-2'>
                  <label className='break-all'>{paciente.label}</label>
                </div>
              </div>
            )}

            {razonCita && (
              <div className='inline-block mb-2'>
                <p className='font-bold text-left mb-1 break-words'> Razón de la cita: </p>
                <div className='ml-2'>
                  <label className='break-all'>{razonCita}</label>
                </div>
              </div>
            )}

            {sintomas && (
              <div className='inline-block mb-2'>
                <p className='font-bold text-left mb-3'> Síntomas: </p>
                <ul className='list-disc list-inside ml-2'>
                  {sintomas.map((value, index) => {
                    return (
                      <li key={index} className='break-all'>
                        {value}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
          <div className='w-1/2 xs:w-full bg-info-lighter px-3 rounded-sm'>
            <p className='text-lg font-bold text-left break-words'>Historial médico</p>

            {condicionesMedicas && (
              <div className='flex flex-col mb-2'>
                <p className='font-bold text-left mb-3'> Condiciones médicas: </p>
                <ul className='list-disc list-inside ml-2'>
                  {condicionesMedicas.map((value, index) => {
                    return (
                      <li key={index} className='break-all'>
                        {value}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {medicamentos && (
              <div className='flex flex-col mb-2'>
                <p className='font-bold text-left mb-3'> Medicamentos tomados por el paciente: </p>
                <ul className='list-disc list-inside ml-2'>
                  {medicamentos.map((value, index) => {
                    return (
                      <li key={index} className='break-all'>
                        {value}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {alergias && (
              <div className='flex flex-col mb-2'>
                <p className='font-bold text-left mb-3'> Alergias: </p>
                <ul className='list-disc list-inside ml-2'>
                  {alergias.map((value, index) => {
                    return (
                      <li key={index} className='break-all'>
                        {value}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}

      <CardDoctorCita />
    </div>
  );
};

export default DetalleConsulta;
