import React, { useState, useEffect } from "react";
import Cards from "react-credit-cards";
// helpers
import first from "lodash/first";

export default function InputCard({ value, val, onChange }) {
  const [infoCard, setInfoCard] = useState({
    cvc: "",
    expiry: "",
    name: "",
    number: "",
    cardType: "",
  });

  useEffect(() => {
    const { edit, info } = val || {};
    if (edit && info) {
      const {
        cardType,
        lastName = "",
        firstName = "",
        cardNumber: number = "",
        securityCode: cvc = "",
        expirationYear: year = "",
        expirationMonth: month = "",
      } = info;

      const key = first(Object.keys(edit));
      const name = `${firstName} ${lastName}`;
      const expiry = `${month}/${year}`;

      const focused = key.includes("expir")
        ? "expiry"
        : key.includes("ame")
        ? "name"
        : key.includes("card")
        ? "number"
        : key.includes("securit")
        ? "cvc"
        : key;
      setInfoCard({ name, number, cvc, expiry, focused, cardType });
    }
  }, [val]);

  const props = {
    ...infoCard,
    callback: ({ issuer }) => onChange(issuer),
  };

  return <Cards {...props} />;
}
