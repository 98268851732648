import React from "react";
// antd
import Card from "antd/lib/card";
import Typography from "antd/lib/typography";
import Button from "antd/lib/button";
// components
// import { Btn } from 'views/Patient/WithOutAppointment'
// style
import style from "../../style.module.scss";

const { Title } = Typography;

export default function BannerGreen() {
  const props = {
    Card: {
      className: style.BannerGreen,
    },
    // EAS-1362
    // redirect to given whatsApp nubmer Fixed
    Btn: {
      href: "https://api.whatsapp.com/send?phone=50578726684&text=Hola%20soy%20afiliado%20EasyMD%2C%20me%20gustar%C3%ADa%20ordenar%20ex%C3%A1menes%20a%20domicilio",
      target: "_blank",
      children: "¿Necesitas ayuda?",
    },
    Title: {
      level: 3,

      className: "text-white",
    },
  };

  return (
    // EAS-1362
    <Card {...props.Card} actions={[<Button {...props.Btn} />]}>
      <Title {...props.Title} />
      <p>Contáctate con nuestro equipo de soporte +505 7872 6684</p>
    </Card>
  );
}
