import moment from "moment";

function getDefaultWorkingDays() {
  // Monday to Friday : 8am to 4:30 pm
  // Saturday         : 8am to 12pm
  var workingDays = [
    {
      from: moment("1 8:00", "d HH:mm").toISOString(),
      to: moment("1 16:30", "d HH:mm").toISOString(),
    },
    {
      from: moment("2 8:00", "d HH:mm").toISOString(),
      to: moment("2 16:30", "d HH:mm").toISOString(),
    },
    {
      from: moment("3 8:00", "d HH:mm").toISOString(),
      to: moment("3 16:30", "d HH:mm").toISOString(),
    },
    {
      from: moment("4 8:00", "d HH:mm").toISOString(),
      to: moment("4 16:30", "d HH:mm").toISOString(),
    },
    {
      from: moment("5 8:00", "d HH:mm").toISOString(),
      to: moment("5 16:30", "d HH:mm").toISOString(),
    },
    {
      from: moment("6 8:00", "d HH:mm").toISOString(),
      to: moment("6 12:00", "d HH:mm").toISOString(),
    },
  ];

  return workingDays;
}

export default getDefaultWorkingDays;
