import React from "react";
// antd
import Form from "antd/lib/form";
import Space from "antd/lib/space";
// inputs
import input from "./input/card";
// components
import InputCard from "./InputCard";
// styles
import "react-credit-cards/es/styles-compiled.css";

const props = {
  SpaceMain: {
    size: 0,
    align: "center",
    style: {
      display: "grid",
      gridAutoFlow: "row",
      gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr) )",
    },
  },
  Space: {
    size: 0,
    align: "center",
    style: {
      display: "grid",
      gridAutoFlow: "column",
      gridGap: 10,
      gridTemplateColumns: "1fr 1fr 1fr",
    },
  },
};

export default function FormCard({ info, ...attr }) {
  return (
    <Form {...attr}>
      <Space {...props.SpaceMain}>
        <div>
          <Form.Item
            {...input.Number}
            rules={[
              // EAS-1053 : Main input rules are not populated in card number
              ...input.Number.rules,
              () => ({
                validator() {
                  // Fixing: Cannot read property cartType of undefined. Info is undefined when there is no card number.
                  if (info?.info?.cardType !== "amex") {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Las tarjetas American Express no son admitidas!")
                  );
                },
              }),
            ]}
          />
          <Space {...props.Space}>
            <Form.Item {...input.Month} />
            <Form.Item {...input.Year} />
            <Form.Item {...input.CVC} />
          </Space>
          <Form.Item {...input.FirstName} />
          <Form.Item {...input.LastName} />
        </div>
        <Form.Item name='cardType'>
          <InputCard val={info} />
        </Form.Item>
      </Space>
    </Form>
  );
}
