import React from "react";
import BrandLoading from "../../../components/CitasUtils/BrandLoading/brand";
import BrowserValidator from "./browserValidator";

const EstadoCita = (props) => {
  const MessageAppointment = (props) => {
    if (props.isFinish) {
      return (
        <div className='flex flex-col'>
          <p className='break-words font-bold text-xl mb-1 text-primary-hoverDark text-center'>
            La cita ha concluido
          </p>
          <p className='break-words font-bold text-xl mb-1 text-primary-hoverDark text-center'>
            Gracias por tu tiempo!
          </p>
        </div>
      );
    }
    return (
      <div className='flex flex-col'>
        <BrowserValidator />
        {props.buttonLlamar}
      </div>
    );
  };

  return (
    <div className='flex flex-col h-full w-full'>
      <div className='mx-auto my-auto h-auto w-4/5 justify-center'>
        <div className='bg-transparent mb-3 flex justify-center'>
          <BrandLoading />
        </div>
        <MessageAppointment {...props} />
      </div>
    </div>
  );
};

export default EstadoCita;
