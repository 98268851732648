import React, { useContext } from "react";
import moment from "moment";
import { statusName } from "utils.js/statusColor";

// ant
import { Card, Empty, Spin, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

// Store
import { profileMedicContext } from "../../index";
import * as actionName from "../storeProfileMedic/actionStoreMedic";

const CardcitaAgendada = (props) => {
  const { data, loading, error } = props;

  const antIcon = <LoadingOutlined style={{ fontSize: 54 }} spin />;

  if (loading) {
    return (
      <div className='h-32 w-full flex justify-center items-center'>
        <Spin indicator={antIcon} />
      </div>
    );
  }

  if (error) {
    return <Empty description={<span>No hay Citas Agendades</span>} />;
  }

  if (data) {
    if (data.appointments.docs.length > 0) {
      return data.appointments.docs.map((value, index) => (
        <CardContend key={index} valueCitas={value} />
      ));
    } else {
      return <Empty description={<span>No hay Citas Agendades</span>} />;
    }
  }
};

const CardContend = (props) => {
  const { valueCitas } = props;
  const { dispatch } = useContext(profileMedicContext);

  const redirecCita = () => {
    dispatch({
      type: actionName.SET_TABS,
      payload: "2",
    });
  };

  return (
    <Card
      onClick={() => redirecCita()}
      className={`my-4 cursor-pointer rounded-lg p-2 shadow-sm hover:shadow-2xl border-l-0 border-t-0 border-b-0 border-r-8 border-${
        statusName(valueCitas.status).type
      } `}
      bodyStyle={{
        margin: "-1.5rem",
        maxHeight: "10rem",
      }}
    >
      <div className='grid grid-cols-12 gap-1'>
        <div className='col-span-2 flex items-center'>
          <strong>
            <span style={{ color: "#4D5580" }} className='text-3xl font-oswald'>
              {moment(valueCitas.appointmentDateAndTime).format("DD")}
            </span>
          </strong>
        </div>
        <div className='col-span-4 flex-row items-center justify-start  border-r-2 border-primary grid'>
          <span style={{ color: "#4D5580" }} className='text-xs leading-tight'>
            <strong>{moment(valueCitas.appointmentDateAndTime).format("MMMM YYYY")}</strong>
            <br />
            {moment(valueCitas.appointmentDateAndTime).format("dddd")}
          </span>
        </div>
        <div className='col-span-6 flex items-center justify-start pl-2'>
          <Tooltip title={statusName(valueCitas.status).label}>
            <span title={valueCitas.reasonForConsultation} className='text-xs truncate'>
              {valueCitas.reasonForConsultation || "N/A"}
            </span>
          </Tooltip>
        </div>
      </div>
    </Card>
  );
};

export default CardcitaAgendada;
