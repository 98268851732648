import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Card, Alert, Statistic } from "antd";

import SelectorRange, { getDefaultDates } from "components/SelectorRange";
import LoadingSpin from "./loadingSpin";

import { secondsToString } from "../utils";

import { getAVGWaitingForAppointmentsMDGql } from "../gqlTags";

const AvgWaitingAppointments = ({ id }) => {
  const [datesSelect, setDatesSelect] = useState(getDefaultDates());
  const [totalGeneral, setTotalGeneral] = useState(0);
  const [totalPsychology, setTotalPsychology] = useState(0);

  const { data, loading, error } = useQuery(getAVGWaitingForAppointmentsMDGql, {
    variables: {
      from: datesSelect.from,
      to: datesSelect.to,
      company: id,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!loading) {
      if (data && !error) {
        const { getAVGWaitingForAppointmentsMD } = data;
        let totalPsi = 0;
        let totalGen = 0;
        let countPsi = 0;
        let countGen = 0;
        // let spanishValue = ''
        getAVGWaitingForAppointmentsMD.map((value) => {
          if (value.category === "psychology") {
            totalPsi += value.value;
            countPsi++;
            // spanishValue = 'Psicología'
          } else if (value.category === "general") {
            totalGen += value.value;
            countGen++;
            // spanishValue = 'General'
          }
          return null;
        });
        setTotalGeneral(countGen > 0 ? secondsToString(totalGen / countGen) : "00:00:00");
        setTotalPsychology(countPsi > 0 ? secondsToString(totalPsi / countPsi) : "00:00:00");
      } else {
        setTotalGeneral(0);
        setTotalPsychology(0);
      }
    }
  }, [loading, error, data]);

  const changeValueMonth = (value) => {
    setDatesSelect(value);
  };

  return (
    <div className='flex flex-col h-full'>
      <div className='flex xs:flex-col md:justify-between md:flex-row gap-4'>
        <p className='mb-0 text-lg font-semibold'>Tiempo promedio de espera</p>
        <SelectorRange onChange={changeValueMonth} loading={loading} disabled={loading} />
      </div>

      {error && !loading ? (
        <Alert
          message='Server Error'
          description='Error al obtener la información de las citas'
          type='error'
          className='w-full mx-auto my-auto'
          showIcon
        />
      ) : (
        <div className='flex flex-col h-full'>
          <div className='flex xs:flex-col lg:flex-row gap-4 mt-4'>
            <Card
              className='xs:w-full md:w-full lg:w-3/6 shadow-none'
              bodyStyle={{ padding: "1rem" }}
            >
              <LoadingSpin loading={loading} />
              {!loading && (
                <div className='flex flex-col'>
                  <p className='text-lg mb-0'>Medicina General: </p>
                  <Statistic className='text-2xl mb-0' title={null} value={totalGeneral} />
                </div>
              )}
            </Card>

            <Card
              className='xs:w-full md:w-full lg:w-3/6 shadow-none'
              bodyStyle={{ padding: "1rem" }}
            >
              <LoadingSpin loading={loading} />
              {!loading && (
                <div className='flex flex-col'>
                  <p className='text-lg mb-0'>Psicología: </p>
                  <Statistic className='text-2xl mb-0' title={null} value={totalPsychology} />
                </div>
              )}
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvgWaitingAppointments;
