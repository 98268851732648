import React from "react";
// antd
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "antd/lib/button";

export default function FormCode(_) {
  const props = {
    Item: {
      name: "code",
      rules: [
        {
          required: true,
          message: "Port favor ingresa el código de afiliación !",
        },
      ],
    },
    Input: {
      size: "large",
      className: "text-center",
      placeholder: "Ingresar código",
      disabled: !!(_.stateButton.enabled || _.stateButton.loading),
    },
    Button: {
      size: "large",
      htmlType: "submit",
      type: "primary",
      block: true,
      children: "USAR",
      loading: _.stateButton.loading,
      disabled: !!(_.stateButton.enabled || _.stateButton.loading),
    },
  };

  return (
    <Form {..._.form}>
      <Form.Item {...props.Item}>
        <Input {...props.Input} />
      </Form.Item>
      <Button {...props.Button} />
    </Form>
  );
}
