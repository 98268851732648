import gql from "graphql-tag";

export const doctorListGql = gql`
  query doctors($perPage: Float, $page: Float, $sort: [doctorSort!], $filters: [doctorFilter!]) {
    doctors(perPage: $perPage, page: $page, sort: $sort, filters: $filters) {
      total
      pages
      docs {
        id
        fullName
        minsaNumber
        email
        balance
      }
    }
  }
`;

export const info_doctorByIdGql = gql`
  query doctorById($id: String!) {
    doctorById(id: $id) {
      id
      sex
      fullName
      approvalStatus
      minsaNumber
      amountOfReviews
      rating
      balance
      email
      phoneNumber
      description
      approvalStatus
      signature {
        url
        mimetype
      }
      pictureURL {
        url
      }
      supportingDocuments {
        id
        title
        media {
          id
          url
          mimetype
        }
      }
      specialties {
        id
        name
      }
    }
  }
`;

export const payDoctorGql = gql`
  mutation payDoctor($id: String!, $amount: Float!) {
    payDoctor(id: $id, amount: $amount) {
      balance
    }
  }
`;
