import * as actionName from "./actions";
import initialState from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionName.SET_INITIAL_STATE:
      return {
        ...initialState,
        refetch: state.refetch,
      };

    case actionName.SET_INFO_COUPON:
      return {
        ...state,
        idCouponSelect: action.payload,
      };

    case actionName.SET_REFETCH:
      return {
        ...state,
        refetch: action.payload,
      };

    case actionName.SET_MODAL_INFO_OPEN:
      return {
        ...state,
        modalOpenInfo: action.payload,
        modalOpenDeny: false,
        modalNewCoupon: false,
        modalEditCoupon: false,
      };

    case actionName.SET_MODAL_DENY_OPEN:
      return {
        ...state,
        modalOpenInfo: false,
        modalOpenDeny: action.payload,
        modalNewCoupon: false,
        modalEditCoupon: false,
      };

    case actionName.SET_MODAL_EDIT_OPEN:
      return {
        ...state,
        modalOpenInfo: false,
        modalOpenDeny: false,
        modalNewCoupon: false,
        modalEditCoupon: action.payload,
      };

    case actionName.SET_MODAL_NEW_OPEN:
      return {
        ...state,
        modalOpenInfo: false,
        modalOpenDeny: false,
        modalNewCoupon: action.payload,
        modalEditCoupon: false,
      };

    default:
      return { ...state };
  }
};

export default reducer;
