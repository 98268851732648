import React, { useContext } from "react";
// antd
import Btn from "antd/lib/button";
// context
import Context from "./Context";

export default function BtnDetails() {
  const { setVisible, visible } = useContext(Context);

  const props = {
    size: "small",
    type: "primary",
    shape: "round",
    onClick: () => setVisible(!visible),
    children: "DETALLES",
  };

  return <Btn {...props} />;
}
