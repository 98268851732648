import React from "react";
// icons
import { faUser, faEnvelope, faLock, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isValidPhoneNumber } from "libphonenumber-js";

const isPhoneNumberNic = (value) => {
  return isValidPhoneNumber(value, "NI");
};
const maxFirstNameLength = 15;
export default {
  firstName: {
    item: {
      label: null,
      name: "firstName",
      rules: [
        { required: true, message: "Por favor ingrese su Primer Nombre!" },
        {
          max: maxFirstNameLength,
          message: `El Primer Nombre debe tener como máximo ${maxFirstNameLength} caracteres.`,
        },
      ],
    },
    input: {
      disabled: false,
      size: "large",
      prefix: <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faUser} />,
      className: "rounded",
      placeholder: "Primer Nombre",
    },
  },
  lastName: {
    item: {
      label: null,
      name: "lastName",
      rules: [
        { required: true, message: "Por favor ingrese su Primer Apellido!" },
        {
          max: maxFirstNameLength,
          message: `El Primer Apellido debe tener como máximo ${maxFirstNameLength} caracteres.`,
        },
      ],
    },
    input: {
      disabled: false,
      size: "large",
      prefix: <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faUser} />,
      className: "rounded",
      placeholder: "Primer Apellido",
    },
  },
  phoneNumber: {
    item: {
      label: null,
      name: "phoneNumber",
      rules: [
        ({ getFieldValue }) => ({
          validator(rule, value) {
            const email = getFieldValue("email");
            if (email) {
              return Promise.resolve();
            }
            if (!value && !email) {
              return Promise.reject("Ingrese un número de teléfono o correo");
            }
            if (isPhoneNumberNic(value)) {
              return Promise.resolve();
            } else {
              return Promise.reject("Ingrese un número de teléfono valido!");
            }
          },
        }),
      ],
    },
    input: {
      disabled: false,
      size: "large",
      prefix: <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faPhone} />,
      className: "rounded",
      placeholder: "Número de teléfono",
    },
  },
  email: {
    item: {
      label: null,
      name: "email",
      rules: [
        ({ getFieldValue }) => ({
          validator(rule, value) {
            const phoneNumber = getFieldValue("phoneNumber");
            if (!value && !phoneNumber) {
              return Promise.reject("Ingrese un correo o número de teléfono");
            }
            return Promise.resolve();
          },
        }),
        {
          pattern:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "Por favor ingrese un correo valido!",
        },
      ],
    },
    input: {
      disabled: false,
      size: "large",
      prefix: <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faEnvelope} />,
      className: "rounded",
      placeholder: "micorreo@email.com",
    },
  },
  password: {
    item: {
      label: null,
      name: "password",
      rules: [
        {
          required: true,
          message: "Por favor ingrese su correo Contraseña!",
        },
        {
          pattern: /^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
          message: "La contraseña no cumple los requisitos mínimos!",
        },
      ],
    },
    input: {
      disabled: false,
      autoComplete: "new-password",
      size: "large",
      prefix: <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLock} />,
      className: "rounded",
      placeholder: "Contraseña",
    },
  },
  confirmPassword: {
    item: {
      autoComplete: "new-password",
      label: null,
      name: "confirmPassword",
      rules: [
        {
          required: true,
          message: "Por favor confirme su contraseña !",
        },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject("Las dos contraseñas que ingresaste no coinciden!");
          },
        }),
      ],
    },
    input: {
      disabled: false,
      size: "large",
      prefix: <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLock} />,
      className: "rounded",
      placeholder: "Confirmar contraseña",
    },
  },
  termsAndConditions: {
    item: {
      className: "inline-block",
      name: "termsAndConditions",
      valuePropName: "checked",
      rules: [
        () => ({
          validator(rule, value) {
            if (value) {
              return Promise.resolve();
            }
            return Promise.reject("Por favor acepta los términos y condiciones!");
          },
        }),
      ],
    },
    checkbox: {
      disabled: false,
    },
    button: {
      className: "pl-1",
      disabled: false,
      loading: false,
      type: "link",
      onClick: () => console.log("sebmit button"),
    },
  },
  nationality: {
    item: {
      name: "nationality",
      label: null,
      rules: [{ required: true, message: "Es necesario asignar una nacionalidad." }],
    },
    select: {
      disabled: false,
      mode: "single",
      allowClear: true,
      showArrow: true,
      bordered: true,
      size: "large",
      className: "rounded",
      style: { width: "100%" },
      placeholder: "Pais de Residencia",
    },
  },
};
