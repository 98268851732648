import React from "react";
import VerifyLoginWrapper from "../../../components/VerifyLoginWrapper";
import VerifyAppointment from "./verifyAppointment";

// Usar VerifyLoginWrapper para validar que el usuario esta login
const Index = (props) => {
  const { state: stateUrl } = props.location || null;
  /* useEffect(() => {
        if (!stateUrl) {
            props.history.push('/md/encuentra/especialistas')
        } else {
            if(!stateUrl.idDoctor || !moment(stateUrl.date).isValid()) {
                props.history.push('/md/encuentra/especialistas')
            }
        }
    }, []) */

  return (
    <VerifyLoginWrapper>
      <VerifyAppointment citaInfo={stateUrl} />
    </VerifyLoginWrapper>
  );
};

export default Index;
