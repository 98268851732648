import React from "react";
import { Avatar } from "antd";

const BodyModal_Aprobar = ({ fullName, picture }) => {
  return (
    <div className='w-full flex flex-col'>
      <p className='text-3xl text-center self-center mb-5'>
        ¿Esta seguro de aprobar a este doctor?
      </p>
      <p className='font-bold mb-0 text-center text-xl'>Dr. {fullName}</p>
      <Avatar className='mx-auto mb-2 mt-5' shape='square' size={128} src={picture} />
    </div>
  );
};

export default BodyModal_Aprobar;
