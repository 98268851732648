import React from "react";
import { useHistory } from "react-router-dom";
import Button from "antd/lib/button";

export default function BtnCreateAccount(attrs) {
  const history = useHistory();

  const props = {
    type: "default",
    shape: "round",
    size: "large",
    className: "btn-primary mr-4",
    onClick: () => history.push("/md/auth/signup"),
    children: "Crear cuenta",
    ...attrs,
  };

  return <Button {...props} />;
}
