export const SET_INITIAL_STATE = "SETINITIALSTATE";

export const SET_APPOINTMENT_DATA = "SETAPPOINTMENTDATA";

//Paso 1 de detalle paciente
export const SET_PATIENT = "SETPATIENT";
export const SET_SINTOMAS = "SETSINTOMAS";
export const SET_RAZON_CITA = "SETRAZONCITA";
export const SET_EXAMS_FILES = "SET_EXAMS_FILES";

//Paso 2 de detalle paciente
export const SET_MEDICAL_CONDITION = "SETMEDICALCONDITION";
export const SET_ALERGY = "SETALERGY";
export const SET_DRUG = "SET_DRUG";

//Paso 3 de metodo de pago
export const SET_OTHER_METHOD = "SETOTHERMETHOD";
export const SET_MY_METHOD = "SETMYMETHOD";
export const SET_TAB_METHOD = "SETTABMETHOD";
export const SET_SAVE_OTHER_METHOD = "SETSAVEOTHERMETHOD";

//Paso final, crear Cita
export const SET_LOADING_CREATE_APPOINTMENT = "SET_LOADING_CREATE_APPOINTMENT";
export const SET_APPOINTMENT_SUCCESS = "SET_APPOINTMENT_SUCCESS";
