import React, { useContext, useEffect } from "react";
import { SET_INITIAL_STATE } from "../store/actions";
import { Alert, Modal, notification } from "antd";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { updateCoupon, couponById } from "../gqlTags";
import FormCoupon from "../../../../components/FormCoupon";

import { ContextTablaCoupons } from "../index";
import { HandleError } from "../../../../utils.js/HandleError";

const ModalUpdate = ({ visible }) => {
  const { state, dispatch } = useContext(ContextTablaCoupons);

  const afterClose = () => {
    dispatch({ type: SET_INITIAL_STATE });
  };

  const refetchCoupons = () => {
    dispatch({ type: SET_INITIAL_STATE });
    state.refetch();
  };

  return (
    <Modal
      title='Actualizar Cupon'
      visible={visible}
      destroyOnClose
      onCancel={afterClose}
      footer={null}
      afterClose={afterClose}
      maskClosable={false}
    >
      {visible && state.idCouponSelect ? (
        <BodyUpdate
          id={state.idCouponSelect}
          onCancel={afterClose}
          closeAndRefetch={refetchCoupons}
        />
      ) : null}
    </Modal>
  );
};

const BodyUpdate = ({ id, closeAndRefetch, onCancel }) => {
  const { loading, error, data } = useQuery(couponById, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      id,
    },
  });

  const [postUpdateCoupon, { loading: loadingMutation, data: dataMutation }] = useMutation(
    updateCoupon,
    { fetchPolicy: "no-cache" }
  );

  const onFinish = async (values) => {
    try {
      const { status, type, ...rest } = values;
      await postUpdateCoupon({
        variables: {
          id,
          type: type.toLowerCase(),
          ...rest,
        },
      });
    } catch (error) {
      notification.error({
        message: "Internal server error ",
        description: HandleError(error),
      });
    }
  };
  useEffect(() => {
    if (!loadingMutation) {
      if (dataMutation) {
        if (dataMutation.updateCoupon) {
          notification.success({
            message: "Cupon editado",
          });
        } else {
          notification.warning({
            message: "El cupon no pudo ser editado",
          });
        }
        closeAndRefetch();
      }
    }
  }, [loadingMutation, dataMutation, closeAndRefetch]);
  if (error) {
    return (
      <div className='w-full'>
        <Alert
          message='Error'
          description='Error al obtener la información del cupon'
          type='error'
          className='w-full'
          showIcon
        />
      </div>
    );
  }

  return (
    <FormCoupon
      loading={loading || loadingMutation}
      infoCoupon={data?.couponById}
      onFinish={onFinish}
      successCreate={closeAndRefetch}
      title={data?.couponById.coupon}
    />
  );
};

export default ModalUpdate;
