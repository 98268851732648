import React from "react";
// components
import DownloadApps from "components/DownloadApps";
// css
import style from "./styleFour.module.scss";

export default function SectionFour() {
  return (
    <div className={style.containerHomeFive}>
      <div className='container mx-auto'>
        <div className='w-full md:w-7/12 lg:w-1/2 xl:w-5/12'>
          <h3
            style={{ color: "#373A4A" }}
            className='text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl leading-tight'
          >
            <span>Este puede ser el comienzo</span> <br />
            <span className='text-primary'>de una relación saludable</span>
          </h3>
          <p className='my-6 xl:text-lg 2xl:text-xl'>
            Para descargar la aplicación, abra su cámara y apunte hacia el código QR durante 2
            segundos o toque los botones a continuación si está en un dispositivo móvil.
          </p>
        </div>
        <div className='md:w-5/12 lg:w-1/4'>
          <DownloadApps />
        </div>
        <div className='md:w-5/12 lg:w-1/4 mt-2'>
          <a
            className='ant-btn text-lg mt-2 ant-btn-primary ant-btn-round'
            href='https://drive.google.com/file/d/14RQPfoY2oP-8Z4VSBhbplZSnQbS-UOiu/view?usp=sharing'
          >
            Descargar PDF con Alcances y Restricciones
          </a>
        </div>
        <div className='md:w-5/12 lg:w-1/4 mt-2'>
          <a
            className='ant-btn text-lg mt-2 ant-btn-primary ant-btn-round'
            href='https://drive.google.com/file/d/1OkWebY4VthHbH8_NufqoggM0wrWwU9Df/view?usp=sharing'
          >
            Descargar Manual de Uso en PDF
          </a>
        </div>
      </div>
    </div>
  );
}
