export const SET_INITIAL_STATE = "SETINITIALSTATE";

export const SET_USER = "SET_USER";
export const SET_MODAL_INFO_OPEN = "SETMODALINFOOPEN";
export const SET_MODAL_DELETE_OPEN = "SETMODALDELETEOPEN";
export const SET_MODAL_NEW_USER_OPEN = "SETMODALNEWUSEROPEN";
export const SET_MODAL_NEW_ROLE_OPEN = "SETMODALNEWROlEOPEN";
export const SET_MODAL_EDIT_OPEN = "SETMODALEDITOPEN";
export const SET_REFETCH = "SETREFETCH";
export const SET_PAGINATION_VARS = "SET_PAGINATION_VARS";
export const SET_SEARCH_NAME = "SET_SEARCH_NAME";
export const SET_FILTER_USERS = "SET_FILTER_USERS";
export const SET_ROLES = "SET_ROLES";
export const SET_ROLE = "SET_ROLE";
