import Add from "./AddDialog";
// import Info from './Profile'
import Card from "./Card";
// import Group from './Group'

export default {
  Add,
  // Info,
  Card,
};
