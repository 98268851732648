import gql from "graphql-tag";

export const companysListGql = gql`
  query companys($perPage: Float, $page: Float, $sort: [companySort!], $filters: [companyFilter!]) {
    companys(perPage: $perPage, page: $page, sort: $sort, filters: $filters) {
      total
      page
      docs {
        id
        phoneNumber
        pictureURL {
          id
          url
        }
        fullName
        email
        userCap
        totalAffiliateUsers
      }
    }
  }
`;

export const updateCompany = gql`
  mutation updateCompany(
    $id: String!
    $firstName: String!
    $lastName: String!
    $userCap: Float!
    $description: String
  ) {
    updateCompany(
      id: $id
      firstName: $firstName
      lastName: $lastName
      userCap: $userCap
      description: $description
    ) {
      id
      fullName
    }
  }
`;

export const companyById = gql`
  query companyById($id: String!) {
    companyById(id: $id) {
      createdAt
      firstName
      lastName
      email
      userCap
      totalAffiliateUsers
      description
      pictureURL {
        id
        url
      }
    }
  }
`;

export const rechazarAprobarDoctorByIdGql = gql`
  query companyById($id: String!) {
    companyById(id: $id) {
      id
      fullName
      # minsaNumber
      pictureURL {
        url
      }
    }
  }
`;

export const deleteCompanyUser = gql`
  mutation deleteCompanyUser($id: String!) {
    deleteCompanyUser(id: $id)
  }
`;

export const createCompany = gql`
  mutation createCompany(
    $firstName: String!
    $lastName: String!
    $email: String!
    $userCap: Float!
    $password: String!
    $confirmPassword: String!
    $description: String
    $companyPlan: [String!]
  ) {
    createCompany(
      firstName: $firstName
      lastName: $lastName
      email: $email
      userCap: $userCap
      password: $password
      confirmPassword: $confirmPassword
      description: $description
      companyPlan: $companyPlan
    ) {
      id
      fullName
    }
  }
`;

export const getPlansPrice = gql`
  query getPlansPrice {
    plans {
      docs {
        id
        name
        disabled
        periods {
          price
          __typename @include(if: false)
        }
        __typename @include(if: false)
      }
    }
  }
`;

export const getCompanyByIdCSV = gql`
  query getCompanyByIdCSV($id: String!) {
    getCompanyByIdCSV(id: $id) {
      download
    }
  }
`;

export const getPendingCodeCompanyByIdCSV = gql`
  query getPendingCodeCompanyByIdCSV($id: String!) {
    getPendingCodeCompanyByIdCSV(id: $id) {
      download
    }
  }
`;
