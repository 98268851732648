import React from "react";
// antd
import Tag from "antd/lib/tag";
import Empty from "antd/lib/empty";
// styles
import style from "./style.module.scss";
// helpers
import isEmpty from "lodash/isEmpty";

export default function tags(_) {
  const { values, onRemove, emptyText, disabled, showEmpty } = _;

  const props = {
    Empty: {
      image: Empty.PRESENTED_IMAGE_SIMPLE,
      className: "mx-auto",
      description: emptyText,
    },
  };

  return isEmpty(values) ? (
    showEmpty ? (
      <Empty {...props.Empty} />
    ) : null
  ) : (
    <div className={style.Tags}>
      {values.map((value, key) => {
        const closable = disabled ? false : !!onRemove;
        const onClose = () => closable && onRemove(value);

        const propsTag = {
          onClose,
          closable,
          key: `${value}_${key}`,
          title: value,
          color: "#29AAE1",
          children: value,
          className: "px-2 py-1 mb-2 rounded-lg",
          // EAS-1650
          style: {
            wordBreak: "break-word", // Break words when they exceed the container's width
            whiteSpace: "pre-wrap",
          },
        };

        return <Tag {...propsTag} />;
      })}
    </div>
  );
}
