import NewRoute from "./newRoutes";
import Diary from "../views/microSalud/Appointments/Diary";
import Encuentra from "../views/microSalud/encuentra";

import LoginMD from "../views/microSalud/Login";
import EmailValidation from "../views/EmailValidation";
import SigNup from "../views/microSalud/CrearCuenta";
import PlanesEasyMD from "../views/easyMD/planesEasyMD";
import ResetPassword from "../views/ResetPassword";
import MobileRequestPayment from "../views/mobileRequestPayment";
import TermsAndConditionsApp from "../views/easyMD/TermsAndConditionsApp";
import Tigo from "../views/easyMD/tigo";

import VerifyAppointment_2 from "../views/easyMD/verifyAppointment";
import CheckOut_2 from "../views/easyMD/checkOut";

const routes = [
  {
    path: "/home",
    name: "Home",
    icon: "",
    component: NewRoute,
    layout: "/md",
    navbar: "true",
    mapInSwitch: true,
  },
  {
    path: "/encuentra/especialistas",
    name: "Find",
    icon: "",
    component: Encuentra,
    layout: "/md",
    navbar: "true",
    mapInSwitch: true,
  },
  {
    path: "/privacyPolicy",
    name: "Política de Privacidad para aplicaciones móviles",
    icon: "",
    component: TermsAndConditionsApp,
    layout: "/md",
    navbar: "true",
    mapInSwitch: false,
  },
  {
    path: "/familiar",
    name: "planes familiares",
    icon: "",
    component: NewRoute,
    layout: "/md",
    navbar: "true",
    mapInSwitch: true,
  },
  {
    path: "/empresa",
    name: "beneficio de salud tu empresa",
    icon: "",
    component: NewRoute,
    layout: "/md",
    navbar: "true",
    mapInSwitch: true,
  },
  // {
  //   path: "/tecnologia",
  //   name: "beneficio de salud tu empresa",
  //   icon: "",
  //   component: ViewTechnology,
  //   layout: "/md",
  //   navbar: "true",
  //   mapInSwitch: true,
  // },
  {
    path: "/planesEasyMD",
    name: "PlanesEasyMD",
    icon: "",
    component: PlanesEasyMD,
    layout: "/md",
    navbar: "true",
    mapInSwitch: true,
  },
  {
    path: "/auth/login/:id",
    name: "Login",
    icon: "",
    component: LoginMD,
    layout: "/md",
    navbar: "true",
    mapInSwitch: false,
  },
  {
    path: "/auth/login",
    name: "Login",
    icon: "",
    component: LoginMD,
    layout: "/md",
    navbar: "true",
    mapInSwitch: false,
  },
  {
    path: "/auth/signup/:id",
    name: "signup",
    icon: "",
    component: SigNup,
    layout: "/md",
    navbar: "true",
    mapInSwitch: false,
  },
  {
    path: "/auth/signup",
    name: "signup",
    icon: "",
    component: SigNup,
    layout: "/md",
    navbar: "true",
    mapInSwitch: false,
  },
  // {
  //   path: '/auth/doctor/signup',
  //   name: 'signup',
  //   icon: '',
  //   component: CrearCuentaDoctor,
  //   layout: '/md',
  //   navbar: 'true',
  //   mapInSwitch: false
  // },
  {
    path: "/auth/verify/:id",
    name: "verify",
    icon: "",
    component: EmailValidation,
    layout: "/md",
    navbar: "true",
    mapInSwitch: false,
  },
  {
    path: "/auth/reset/:id",
    name: "verify",
    icon: "",
    component: ResetPassword,
    layout: "/md",
    navbar: "true",
    mapInSwitch: false,
  },
  {
    path: "/appointment",
    name: "SignUp",
    component: Diary,
    layout: "/md",
    mapInSwitch: false,
  },
  {
    path: "/appointment/checkout/:id",
    name: "NewAppointment",
    component: CheckOut_2,
    layout: "/md",
    mapInSwitch: false,
  },
  {
    path: "/appointment/verify",
    name: "NewAppointment",
    component: VerifyAppointment_2,
    layout: "/md",
    mapInSwitch: false,
  },
  {
    path: "/checkout/app/:id",
    name: "mobileCheckOut",
    component: MobileRequestPayment,
    layout: "/md",
    mapInSwitch: false,
  },
  {
    path: "/alianza/tigo",
    name: "Alianza Tigo",
    component: Tigo,
    layout: "/md",
    navbar: "true",
    mapInSwitch: true,
  },
];

export default routes;
