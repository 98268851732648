import React, { useState, useContext, useEffect } from "react";
import { Button, Popover, notification } from "antd";
import { CheckOutContext } from "../index";
import Paso1 from "./paso1";
import Paso2 from "./paso2";
import Paso3 from "./paso3";
import ModalConfirmAppointment from "./modalConfirmAppointment";

import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import { SET_LOADING_CREATE_APPOINTMENT, SET_APPOINTMENT_SUCCESS } from "../../store/actions";

import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getSessionFingerPrint } from "./utils";

const DetallePaciente = () => {
  const { state, dispatch } = useContext(CheckOutContext);
  const {
    paciente,
    razonCita,
    sintomas,
    condicionesMedicas,
    alergias,
    medicamentos,
    idAppointment,
    metodoDePago,
    savePaymentMethod,
  } = state;
  const [pasoSeleccionado, setPasoSeleccionado] = useState(1);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [visiblePopover, setVisiblePopover] = useState(false);

  const updateAppointmentGql = gql`
    mutation updateAppointment(
      $id: String!
      $patient: String!
      $status: updateAppointmentStatus!
      $reasonForConsultation: String
      $newSymptoms: [String!]
      $newDrugAllergies: [String!]
      $newCurrentMedication: [String!]
      $newMedicalConditions: [String!]
      $paymentToken: String
      $savePaymentMethod: Boolean
      $fingerprintSessionId: String
      $mobileNotification: Boolean
    ) {
      updateAppointment(
        id: $id
        patient: $patient
        status: $status
        reasonForConsultation: $reasonForConsultation
        newSymptoms: $newSymptoms
        newDrugAllergies: $newDrugAllergies
        newCurrentMedication: $newCurrentMedication
        newMedicalConditions: $newMedicalConditions
        paymentToken: $paymentToken
        savePaymentMethod: $savePaymentMethod
        fingerprintSessionId: $fingerprintSessionId
        mobileNotification: $mobileNotification
      ) {
        id
      }
    }
  `;

  const [fetchCreateAppointment, { loading, error, data }] = useMutation(updateAppointmentGql, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (
      !!localStorage.getItem("mobileNotificationAppointment") &&
      !localStorage.getItem("refreshToken")
    ) {
      setPasoSeleccionado(3);
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      if (data) {
        localStorage.removeItem("mobileNotificationAppointment");
        dispatch({
          type: SET_APPOINTMENT_SUCCESS,
          payload: {
            citaAgendadaConExito: true,
          },
        });
      } else if (error) {
        setVisibleModalConfirm(false);
        setVisiblePopover(false);
        notification.error({
          message: "Error",
          description: Array.isArray(error.graphQLErrors)
            ? error.graphQLErrors[0].message
            : "Error temporal del servidor, no se puede agendar la cita",
        });
      }
    }
  }, [loading, error, data, dispatch]);

  const siguientePaso = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setPasoSeleccionado(pasoSeleccionado + 1);
  };

  const anteriorPaso = () => {
    setPasoSeleccionado(pasoSeleccionado - 1);
  };

  const handleVisiblePopover = (visible) => {
    if (!visible) {
      setVisiblePopover(false);
    } else {
      const { infoPropio, infoOther, tabActiva, isFullOther } = metodoDePago;
      let metodoSeleccionado = false;
      switch (pasoSeleccionado) {
        case 1:
          if (paciente && razonCita) {
            setVisiblePopover(false);
            siguientePaso();
          } else {
            setVisiblePopover(true);
          }
          break;

        case 2:
          setVisiblePopover(false);
          siguientePaso();
          break;

        case 3:
          if (tabActiva.toString() === "1" && !!infoPropio) {
            metodoSeleccionado = true;
          }
          if (tabActiva.toString() === "2" && infoOther && isFullOther) {
            metodoSeleccionado = true;
          }
          if (metodoSeleccionado) {
            setVisiblePopover(false);
          } else {
            setVisiblePopover(true);
          }
          break;

        default:
          setPasoSeleccionado(1);
          setVisibleModalConfirm(false);
          setVisiblePopover(false);
      }
    }
  };

  const onClickAgendarCita = () => {
    const { infoPropio, infoOther, tabActiva, isFullOther } = metodoDePago;
    let metodoSeleccionado = false;
    if (tabActiva.toString() === "1" && infoPropio) {
      metodoSeleccionado = true;
    }
    if (tabActiva.toString() === "2" && infoOther && isFullOther) {
      metodoSeleccionado = true;
    }
    if (metodoSeleccionado) {
      setVisibleModalConfirm(true);
    }
  };

  const confirmAgendarCita = () => {
    setVisibleModalConfirm(false);
    dispatch({
      type: SET_LOADING_CREATE_APPOINTMENT,
      payload: {
        creandoCitaLoading: true,
      },
    });
    fetchMutacionAgendarCita();
  };

  const fetchMutacionAgendarCita = async () => {
    try {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      const fingerprintSessionId = await getSessionFingerPrint();
      let variables = {
        id: idAppointment,
        patient: paciente ? paciente.value : null,
        status: "SCHEDULED",
        reasonForConsultation: razonCita,
        newSymptoms: getOnlyValues(sintomas),
        newDrugAllergies: getOnlyValues(alergias),
        newCurrentMedication: getOnlyValues(medicamentos),
        newMedicalConditions: getOnlyValues(condicionesMedicas),
        paymentToken:
          metodoDePago.tabActiva.toString() === "1"
            ? metodoDePago.infoPropio
            : JSON.stringify(metodoDePago.infoOther),
        savePaymentMethod: savePaymentMethod,
        fingerprintSessionId: fingerprintSessionId.toString(),
      };
      if (localStorage.getItem("mobileNotificationAppointment")) {
        variables = {
          ...variables,
          mobileNotification: !!localStorage.getItem("mobileNotificationAppointment"),
        };
      }
      await fetchCreateAppointment({
        variables: variables,
      });
    } catch (e) {
      dispatch({
        type: SET_LOADING_CREATE_APPOINTMENT,
        payload: {
          creandoCitaLoading: false,
        },
      });
      setVisibleModalConfirm(false);
      setVisiblePopover(false);
      notification.error({
        message: "Error",
        description: "Error al agendar la cita",
      });
    }
  };

  const getOnlyValues = (info) => {
    if (!info) {
      return [];
    }
    const arrayValues = [];
    info.forEach((value) => {
      if (value.value) {
        arrayValues.push(value.value);
      } else {
        arrayValues.push(value);
      }
    });
    return arrayValues;
  };

  return (
    <div className='w-full h-full flex flex-col'>
      <ModalConfirmAppointment
        visible={visibleModalConfirm}
        onClose={() => setVisibleModalConfirm(false)}
        onOk={() => confirmAgendarCita()}
      />

      <div className='h-auto mb-5'>
        <Paso1 className={pasoSeleccionado !== 1 ? "hidden" : ""} />

        <Paso2 className={pasoSeleccionado !== 2 ? "hidden" : ""} />

        <Paso3 className={pasoSeleccionado !== 3 ? "hidden" : ""} />
      </div>

      <div className='mt-auto w-full flex justify-between'>
        {pasoSeleccionado > 1 && (
          <Button
            type='default'
            shape='round'
            size='large'
            className=''
            onClick={() => anteriorPaso()}
          >
            Atras
          </Button>
        )}

        <Popover
          content={
            <PopoverContent
              pasoSeleccionado={pasoSeleccionado}
              paciente={paciente}
              razonCita={razonCita}
              sintomas={sintomas}
              condicionesMedicas={condicionesMedicas}
              alergias={alergias}
              medicamentos={medicamentos}
            />
          }
          title='Campos requeridos'
          trigger='click'
          visible={visiblePopover}
          onVisibleChange={handleVisiblePopover}
        >
          {pasoSeleccionado !== 3 ? (
            <Button type='primary' shape='round' size='large' className='ml-auto'>
              Siguiente
            </Button>
          ) : (
            <Button
              onClick={() => onClickAgendarCita()}
              type='primary'
              shape='round'
              size='large'
              className='ml-auto'
            >
              Agendar Cita <FontAwesomeIcon icon={faCalendarCheck} className='ml-2 self-center' />
            </Button>
          )}
        </Popover>
      </div>
    </div>
  );
};

const PopoverContent = ({
  pasoSeleccionado,
  paciente,
  razonCita,
  condicionesMedicas,
  medicamentos,
}) => {
  switch (pasoSeleccionado) {
    case 1:
      return (
        <div className='flex flex-col'>
          {!paciente && (
            <p>
              {" "}
              * Seleccionar al <span className='font-bold'>Paciente</span>
            </p>
          )}
          {!razonCita && (
            <p>
              {" "}
              * Escribir una <span className='font-bold'>Razón de la cita</span>
            </p>
          )}
        </div>
      );
    case 2:
      return (
        <div className='flex flex-col'>
          {!medicamentos && (
            <p>
              {" "}
              * Seleccionar <span className='font-bold'>Medicamentos</span>
            </p>
          )}
          {!condicionesMedicas && (
            <p>
              {" "}
              * Seleccionar <span className='font-bold'>Condiciones médicas</span>
            </p>
          )}
        </div>
      );
    case 3:
      return (
        <p>
          {" "}
          * Seleccionar un <span className='font-bold'>Método de pago</span>
        </p>
      );
    default:
      return null;
  }
};

export default DetallePaciente;
