import React, { useContext, useEffect } from "react";

// utils
import { HandleError } from "utils.js/HandleError";

import Table from "antd/lib/table";
import notification from "antd/lib/notification";
import { ColumnsTableTransactions as columns } from "./ColumnsTableTransactions";

import { useQuery } from "@apollo/react-hooks";
import { patientsTransactions } from "../gql";

// store
import { AffiliatesContext } from "../../index";
// import * as actionName from '../../store/actionStore'

const TableTransactions = (_) => {
  const { state } = useContext(AffiliatesContext);

  const { data, loading, error } = useQuery(patientsTransactions, {
    fetchPolicy: "no-cache",
    variables: { userPlanId: state.selectedUser.userPlanId },
  });

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
  }, [error]);

  const props = {
    columns,
    dataSource: data ? data.patientsTransactions : [], // getData(),
    className: "m-5",
    pagination: {
      showSizeChanger: false,
      pageSize: 10,
      total: data ? data.patientsTransactions.length : 0,
    },
    loading,
    scroll: { y: 350 },
  };

  return <Table {...props} rowKey={(record) => record.confirmationNumber} />;
};

export default TableTransactions;
