import gql from "graphql-tag";

export const getChartEarningsGql = gql`
  query getChartEarnings($type: earningsDataType) {
    getChartEarnings(type: $type) {
      id
      month
      payments
      paymentAmount
      authorization
      authorizationAmount
      appointment
      appointmentAmount
      plan
      planAmount
      withdrawals
      withdrawalAmount
      totalRevenue
      date
      reversals
      reversalAmount
    }
  }
`;
