import React, { Fragment } from "react";
import moment from "moment";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
// antd
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
// styles
import style from "./style.module.scss";

export default ({ event, onSelect }) => {
  moment.locale("es-ES");

  const modal = Modal.confirm();

  const propsBtn = {
    type: "success",
    icon: <FA icon={faBook} />,
    onClick: () => {
      modal.destroy();
      onSelect(event);
    },
    children: <span className='ml-2'>Agendar</span>,
    className: "button-success",
  };

  modal.update({
    title: "Desea agendar la cita...",
    content: (
      <Fragment>
        <p className='my-6'>{moment(event.start).format("dddd DD MMMM hh:mm a")} ?</p>
        <Button {...propsBtn} />
      </Fragment>
    ),
    centered: true,
    maskStyle: {
      opacity: 0.75,
      position: "absolute",
      backgroundColor: "white",
    },
    className: style.Dialog,
    cancelText: "Seguir buscando...",
    getContainer: ".site-layout-content",
    okButtonProps: {
      style: {
        display: "none",
      },
    },
    cancelButtonProps: {
      type: "link",
      style: {
        paddingRight: 0,
        color: "#DB5151",
      },
    },
  });
};
