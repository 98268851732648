import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
// antd
import Form from "antd/lib/form";
import Modal from "antd/lib/modal";
import notify from "antd/lib/notification";
// components
import FormPrice from "./Form";
// gql
import { updateAppointmentPrice } from "../gqlTags";
import isEqual from "lodash/isEqual";
import toNumber from "lodash/toNumber";
import { HandleError as handleError } from "utils.js/HandleError";

export default function Dialog({ info, visible, ...modal }) {
  const { id } = info;
  const [form] = Form.useForm();
  const [variables, setVariables] = useState({ id, price: null });
  const [mutation, { error, loading }] = useMutation(updateAppointmentPrice, {
    variables,
    refetchQueries: ["doctors"],
  });

  useEffect(() => {
    if (error) notify.error({ message: handleError(error) });
  }, [error]);

  const handleValueChange = (value) => {
    //  EAS-897
    // User is able to enter and update "EasyMd price" more than "consultation price" in Admin Webapp , Hence Consultation discount amount in Specialists is appearing negative is fixed
    let newPrice = value.newPrice;
    if (newPrice.length > 1) {
      const price = toNumber(newPrice.slice(1));
      if (price > info.appointmentPrice) {
        return alert("El precio debe ser menor que Consulta de precios");
      }
      setVariables({ ...variables, price });
    } else if (newPrice === "$") {
      setVariables({ ...variables, price: null });
    }
  };

  const onFinish = () => {
    mutation().then((_) => {
      const message = "Precio de cita EasyMD actualizado";
      notify.success({ message });
      modal.onCancel();
    });
  };

  const props = {
    Modal: {
      visible,
      onOk: form.submit,
      title: "Editar precio por cita EasyMD",
      centered: true,
      cancelButtonProps: {
        style: { display: "none" },
      },
      okText: "Actualizar",
      okButtonProps: {
        loading,
        disabled:
          loading ||
          isEqual(info.appointmentPriceEasyMD, variables.price) ||
          variables.price === null,
      },
      ...modal,
    },
    Form: {
      form,
      onFinish,
      name: "FormPrice",
      initialValues: info,
      onValuesChange: (_, value) => handleValueChange(value),
      hideRequiredMark: true,
    },
  };

  return (
    <Modal {...props.Modal}>
      <FormPrice {...props.Form} />
    </Modal>
  );
}
