import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
// antd
import Tabs from "antd/lib/tabs";
import BtnTrigger from "layouts/profile/BtnToggleShowSider";
// components
import Home from "./Home";
import Appointment from "./Appointments";
import Affiliation from "./Affiliation";
import WizardMD from "components/WizardMD";
// context
import Context from "views/Patient/Context";
// helpers
import get from "lodash/get";
import ModalPlans from "components/ModalPlans";

export default function TabsContainer() {
  const { appointment, setAppointment } = useContext(Context);
  const [activeKey, setActiveKey] = useState("1");
  const history = useHistory();

  useEffect(() => {
    if (appointment && activeKey !== "2") {
      setActiveKey("2");
    }
  }, [appointment, activeKey]);

  useEffect(() => {
    const tab = get(history, "location.tab", "");
    if (tab.includes("appointment")) setActiveKey("2");
    else setActiveKey("1");
  }, [history]);

  const onTabClick = (key) => {
    if (appointment && activeKey === "2" && key !== activeKey) {
      setAppointment(null);
    }
    setActiveKey(key);
  };

  const props = {
    Tabs: {
      activeKey,
      onTabClick,
      size: "large",
      tabBarExtraContent: [
        <BtnTrigger key='BtnTrigger' />,
        <WizardMD key='WizardMD' classNameButton='hidden xl:inline-block' />,
      ],
    },
  };

  return (
    <>
      <Tabs {...props.Tabs}>
        <Tabs.TabPane tab='Inicio' key='1'>
          <Home />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Historial de citas' key='2'>
          <Appointment />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Afiliación' key='3'>
          <Affiliation />
        </Tabs.TabPane>
      </Tabs>
      <ModalPlans />
    </>
  );
}
