import gql from "graphql-tag";

export const drugs = gql`
  query drugs($perPage: Float, $page: Float, $filters: [drugFilter!]) {
    drugs(perPage: $perPage, page: $page, filters: $filters) {
      total
      page
      docs {
        id
        name
        description
      }
    }
  }
`;
export const createDrug = gql`
  mutation createDrug($bulkNames: [String!]) {
    createDrug(bulkNames: $bulkNames) {
      id
      name
      description
    }
  }
`;

export const updateDrug = gql`
  mutation updateDrug($id: String!, $name: String, $description: String) {
    updateDrug(id: $id, name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const drugById = gql`
  query drugById($id: String!) {
    drugById(id: $id) {
      id
      name
      description
    }
  }
`;

export const updateSymptom = gql`
  mutation updateSymptom($id: String!, $name: String, $description: String) {
    updateSymptom(id: $id, name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const createSymptom = gql`
  mutation createSymptom($bulkNames: [String!]) {
    createSymptom(bulkNames: $bulkNames) {
      id
      name
      description
    }
  }
`;

export const deleteDrugById = gql`
  mutation deleteDrugById($id: String!) {
    deleteDrugById(id: $id)
  }
`;

export const symptoms = gql`
  query symptoms($perPage: Float, $page: Float, $filters: [symptomFilter!]) {
    symptoms(perPage: $perPage, page: $page, filters: $filters) {
      total
      page
      docs {
        id
        name
        description
      }
    }
  }
`;

export const symptomById = gql`
  query symptomById($id: String!) {
    symptomById(id: $id) {
      id
      name
      description
    }
  }
`;

export const deleteSymptomById = gql`
  mutation deleteSymptomById($id: String!) {
    deleteSymptomById(id: $id)
  }
`;

export const medicalconditions = gql`
  query medicalconditions($perPage: Float, $page: Float, $filters: [medicalconditionFilter!]) {
    medicalconditions(perPage: $perPage, page: $page, filters: $filters) {
      total
      page
      docs {
        id
        name
        description
      }
    }
  }
`;

export const createMedicalCondition = gql`
  mutation createMedicalCondition($bulkNames: [String!]) {
    createMedicalCondition(bulkNames: $bulkNames) {
      id
      name
      description
    }
  }
`;

export const medicalconditionById = gql`
  query medicalconditionById($id: String!) {
    medicalconditionById(id: $id) {
      id
      name
      description
    }
  }
`;

export const updateMedicalCondition = gql`
  mutation updateMedicalCondition($id: String!, $name: String, $description: String) {
    updateMedicalCondition(id: $id, name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const deleteMedicalconditionById = gql`
  mutation deleteMedicalconditionById($id: String!) {
    deleteMedicalconditionById(id: $id)
  }
`;
