import gql from "graphql-tag";

export const specialties = gql`
  query specialties($perPage: Float, $page: Float, $filters: [specialtieFilter!]) {
    specialties(perPage: $perPage, page: $page, filters: $filters) {
      total
      page
      docs {
        value: id
        label: name
      }
    }
  }
`;

export const languages = gql`
  query languages($perPage: Float, $page: Float, $filters: [languageFilter!]) {
    languages(perPage: $perPage, page: $page, filters: $filters) {
      total
      page
      docs {
        id
        name
      }
    }
  }
`;

export const uploadSupportingDocument = gql`
  mutation uploadSupportingDocument($file: Upload!, $title: String!, $doctor: String!) {
    uploadSupportingDocument(file: $file, title: $title, doctor: $doctor) {
      id
      title
      media {
        url
      }
    }
  }
`;

export const deleteSupportingdocument = gql`
  mutation deleteSupportingdocument($id: String!) {
    deleteSupportingdocumentById(id: $id)
  }
`;

export const requestApproval = gql`
  mutation requestApproval {
    requestApproval {
      id
      fullName
      approvalStatus
    }
  }
`;

export const doctorByIdDocument = gql`
  query doctorByIdDocument($id: String!) {
    doctorById(id: $id) {
      id
      fullName
      supportingDocuments {
        id
        title
        media {
          id
          url
          mimetype
        }
      }
    }
  }
`;

export const doctorById = gql`
  query doctorById($id: String!) {
    doctorById(id: $id) {
      id
      approvalStatus
      fullName
      firstName
      email
      balance
      lastName
      signature {
        id
        url
      }
      pictureURL {
        id
        url
      }
      phoneNumber
      minsaNumber
      specialties {
        id
        name
      }
      languages {
        id
        name
      }
      description
      appointmentPrice
    }
  }
`;
// Doctor MD By Id

export const doctorMDById = gql`
  query doctormdById($id: String!) {
    doctormdById(id: $id) {
      id
      fullName
      firstName
      lastName
      email
      signature {
        id
        url
      }
      pictureURL {
        id
        url
      }
      phoneNumber
      minsaNumber
      specialties {
        id
        name
      }
      languages {
        id
        name
      }
      description
    }
  }
`;
// End DoctorMD By Id

export const googleCalendarAuthGql = gql`
  query googleCalendarAuth($email: String!) {
    googleCalendarAuth(email: $email)
  }
`;
export const doctorByIdWorkingDays = gql`
  query doctorById($id: String!) {
    doctorById(id: $id) {
      id
      fullName
      activeCalendar
      role {
        id
        name
      }
      workingDays {
        from
        to
      }
    }
  }
`;

export const updateMedicWorkingDays = gql`
  mutation updateMedicWorkingDays($workingDays: [intervalInput!]) {
    updateDoctor(workingDays: $workingDays) {
      id
      fullName
      workingDays {
        from
        to
      }
    }
  }
`;
export const updateActiveCalendarGql = gql`
  mutation updateActiveCalendar($activeCalendar: activeCalendar!) {
    updateDoctor(activeCalendar: $activeCalendar) {
      id
      activeCalendar
    }
  }
`;

export const updateMedicInfo = gql`
  mutation updateMedicInfo(
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $minsaNumber: String
    $description: String
    $specialties: [String!]
    $languages: [String!]
    $appointmentPrice: Float
  ) {
    updateDoctor(
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      minsaNumber: $minsaNumber
      specialties: $specialties
      languages: $languages
      description: $description
      appointmentPrice: $appointmentPrice
    ) {
      id
      firstName
      lastName
      fullName
      appointmentPrice
      phoneNumber
      minsaNumber
      specialties {
        id
        name
      }
      languages {
        id
        name
      }
    }
  }
`;

// doctorMD
export const updateMedicMDInfo = gql`
  mutation updateDoctorMD(
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $minsaNumber: String
    $description: String
    $specialties: [String!]
    $languages: [String!]
  ) {
    updateDoctorMD(
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      minsaNumber: $minsaNumber
      specialties: $specialties
      languages: $languages
      description: $description
    ) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      minsaNumber
      specialties {
        id
        name
      }
      languages {
        id
        name
      }
    }
  }
`;
// end doctorMD

export const updateMedicPicture = gql`
  mutation updateMedicPicture($id: String, $file: Upload) {
    updateDoctor(id: $id, picture: $file) {
      id
      pictureURL {
        id
        url
      }
    }
  }
`;

// foto de perfil DoctorMd
export const updateMedicPictureMD = gql`
  mutation updateMedicPicture($id: String, $file: Upload) {
    updateDoctorMD(id: $id, picture: $file) {
      id
      pictureURL {
        id
        url
      }
    }
  }
`;

export const updateMedicSignature = gql`
  mutation updateSignature($base64: String, $signature: Upload) {
    updateSignature(base64: $base64, signature: $signature) {
      id
      signature {
        id
        url
      }
    }
  }
`;

// firma de doctorMD
export const updateSignatureMedicMD = gql`
  mutation updateSignatureMD($base64: String, $signature: Upload) {
    updateSignatureMD(base64: $base64, signature: $signature) {
      id
      signature {
        id
        url
      }
    }
  }
`;
/// / fin firma de doctorMD

export const appointmentsDoctor = gql`
  query appointmentsDoctor($perPage: Float, $page: Float, $filters: [appointmentFilter!]) {
    appointments(
      perPage: $perPage
      page: $page
      filters: $filters
      sort: { field: APPOINTMENTDATEANDTIME, direction: DESCENDING }
    ) {
      total
      page
      docs {
        doctor {
          id
          fullName
          signature {
            url
          }
          minsaNumber
          sex
        }
        patient {
          fullName
          phoneNumber
          dateOfBirth
          weight
          sex
          nationalId
        }
        exams {
          id
          title
          media {
            id
            mimetype
            url
          }
        }
        symptoms: newSymptoms
        id
        usable
        status
        reasonForConsultation
        appointmentDateAndTime
        diagnosis
        prescription
        appointmentNumber
      }
    }
  }
`;
