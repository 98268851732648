import React from "react";

import { Card, Button } from "antd";
import BrandLoading from "../../../components/CitasUtils/BrandLoading/brand";

import LlamadaListenerConfig from "./llamadaListenerConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const VideoLlamadaPacienteMD = ({ isRenderChat, isFinish, infoAppointment, infoForChat }) => {
  if (!isRenderChat && !isFinish) {
    return (
      <Card className='h-full w-full'>
        <div className='flex flex-col justify-center content-center w-full h-full'>
          <CustomPendingToDoctor />
        </div>
      </Card>
    );
  }
  if (isFinish) {
    return (
      <Card className='h-full w-full'>
        <div className='flex flex-col justify-center content-center w-full h-full'>
          <EndCita />
        </div>
      </Card>
    );
  } else {
    return (
      <Card className='h-full w-full' bodyStyle={{ padding: 0 }}>
        <LlamadaListenerConfig infoAppointment={infoAppointment} infoForChat={infoForChat} />
      </Card>
    );
  }
};

const CustomPendingToDoctor = () => {
  return (
    <>
      <Button
        type='default'
        shape='round'
        size='large'
        danger
        className='mx-auto'
        onClick={() => (window.location = "/patient")}
      >
        <FontAwesomeIcon icon={faUser} className='mr-2 hover:text-danger-hover' />
        Ir a mi perfil
      </Button>

      <BrandLoading width='50%' />
      <p className='text-center text-2xl font-bold text-primary mt-3'>
      No salga de esta pagina, 
      </p>
      <p className='text-center text-2xl font-bold text-primary'>un medico tomará su consulta en un momento</p>
      <p
        className='relative text-gray-600 text-base text-center font-light m-0'
        style={{ top: "55px" }}
      >
        Esta conversación será privada
      </p>
    </>
  );
};

const EndCita = () => {
  return (
    <>
      <Button
        type='default'
        shape='round'
        size='large'
        danger
        className='mx-auto'
        onClick={() => (window.location = "/patient")}
      >
        <FontAwesomeIcon icon={faUser} className='mr-2 hover:text-danger-hover' />
        Ir a mi perfil
      </Button>

      <BrandLoading width='60%' />
      <div className='flex flex-col'>
        <p className='break-words font-bold text-xl mb-1 text-primary-hoverDark text-center'>
          La cita ha concluido
        </p>
        <p className='break-words font-bold text-xl mb-1 text-primary-hoverDark text-center'>
          Gracias por tu tiempo!
        </p>
      </div>
    </>
  );
};

export default VideoLlamadaPacienteMD;
