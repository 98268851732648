import React, { useContext } from "react";
import { SET_INITIAL_STATE } from "../store/actions";
import { Modal } from "antd";

import BodyDetailsCompany from "./BodyDetailsCompany";

import { ContextTablaEmpresas } from "../index";

const ModalDetail = ({ visible }) => {
  const { state, dispatch } = useContext(ContextTablaEmpresas);

  const afterClose = () => {
    dispatch({ type: SET_INITIAL_STATE });
  };

  return (
    <Modal
      title='Detalles del la empresa'
      visible={visible}
      destroyOnClose
      onCancel={afterClose}
      footer={null}
      afterClose={afterClose}
      maskClosable={false}
    >
      {visible && state.idEmpresaSelect ? (
        <BodyDetailsCompany id={state.idEmpresaSelect} isNormalDoctor={false} />
      ) : null}
    </Modal>
  );
};

export default ModalDetail;
