import React, { useEffect, useContext } from "react";
import Brand from "components/CitasUtils/BrandLoading/brand";
import get from "lodash/get";
import lowerCase from "lodash/lowerCase";

import { useLazyQuery } from "@apollo/react-hooks";
import { getInfoRequestMobileGQL, meGQL } from "./gqlTags";

import Lottie from "react-lottie";
import expiredTime from "assets/lottie/expiredTime.json";

// context
import { GlobalContext } from "views/App";

// utils
import { logoutNormal } from "layouts/utils";

const MobileRequestPayment = (props) => {
  const { dispatch } = useContext(GlobalContext);

  const [fetchInfo, { loading, data, error }] = useLazyQuery(getInfoRequestMobileGQL(), {
    fetchPolicy: "no-cache",
  });

  const [fetchMeGql, { loading: loadingMe, data: dataMe, error: errorMe }] = useLazyQuery(meGQL(), {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    setTimeout(() => {
      fetchInfo({
        variables: {
          url: get(props, ["match", "params", "id"], null),
        },
      });
    }, 3000);
  }, []);

  useEffect(() => {
    if (data && !loading && !error) {
      const { token } = data.getInfoRequestMobile;

      logoutNormal();
      dispatch({ type: "SETUSER", payload: null });

      localStorage.setItem("token", token);

      // with new token, fetch ME gql before redirect
      fetchMeGql();
    }
  }, [data, loading, error, dispatch]);

  useEffect(() => {
    if (!loadingMe && dataMe && !errorMe) {
      dispatch({ type: "SETUSER", payload: dataMe.me });
      const { info, type, id } = data.getInfoRequestMobile;

      if (lowerCase(type) === "plan") {
        props.history.push({
          pathname: "/md/planesEasyMD",
          state: {
            id: id,
            frequency: info,
          },
        });
      } else if (lowerCase(type) === "appointment") {
        localStorage.setItem("mobileNotificationAppointment", true);
        props.history.push("/md/appointment/checkout/" + id);
      }
    }
  }, [dataMe, loadingMe, errorMe, dispatch, data, props]);

  if (error || errorMe) {
    return (
      <div className='flex h-screen py-3'>
        <div className='mt-12 mx-auto'>
          <Lottie
            isClickToPauseDisabled
            options={{
              loop: true,
              autoplay: true,
              animationData: expiredTime,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height='auto'
            width='250px'
          />
          <p className='font-semibold text-danger text-lg text-center my-3'>
            ¡ El enlace que has seguido ha caducado !
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className='flex h-screen py-5'>
      <div className='self-center mx-auto'>
        <Brand />
        <p className='mx-auto text-primary text-center font-bold'>
          Cargando información, espere un momento por favor
        </p>
      </div>
    </div>
  );
};

export default React.memo(MobileRequestPayment);
