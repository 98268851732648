import React from "react";

// components
import Canvas from "./Canvas";

// styles
import styles from "../styles.module.scss";

// ant
import Tooltip from "antd/lib/tooltip";

export default function Price(doctor) {
  const props = {
    span: {
      // style: { marginBottom: '-20px' },
      children: "$" + doctor.appointmentPriceEasyMD,
      className: `${styles.editable} cursor-pointer`,
    },
    Tooltip: {
      title: "Click para editar el precio para cita",
    },
  };

  return (
    <div
      className={`flex items-center justify-center ${styles.priceContainer}`}
      style={{ position: "relative" }}
    >
      <Canvas info={doctor}>
        <Tooltip {...props.Tooltip}>
          <span {...props.span} />
        </Tooltip>
      </Canvas>
    </div>
  );
}
