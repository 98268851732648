import React, { useState, useEffect, createRef } from "react";
import gql from "graphql-tag";
import dotenv from "dotenv";
import { useLazyQuery } from "@apollo/react-hooks";
// antd
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
//
import ReCAPTCHA from "react-google-recaptcha";

import "./stylesOverride.css";

dotenv.config();

const SITEKEY = process.env.REACT_APP_RECAPTCHA;

const ContactForm = ({ onSuccessEmail }) => {
  const [form] = Form.useForm();
  const recaptchaRef = createRef();
  const [triggers, setTriggers] = useState(["onSubmit"]);

  const formContactGql = () => {
    const schema = `query  sendContactForm($email: String! $name: String! $subject: String!) {
                    sendContactForm(email: $email name: $name subject: $subject)
                }
            `;
    return gql`
      ${schema}
    `;
  };

  const [fetchContactForm, { loading, data, error }] = useLazyQuery(formContactGql(), {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!loading && (data || error)) {
      onSuccessEmail();
    }
  }, [loading, data, error, onSuccessEmail]);

  const onFinish = (values) => {
    if (!loading) {
      fetchContactForm({
        variables: {
          ...values,
        },
      });
    }
  };

  const onSubmitWithReCAPTCHA = async (valueForm) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      onFinish(valueForm);
    }
  };

  return (
    <Form
      form={form}
      name='contactForm'
      style={{ maxWidth: 480 }}
      layout='vertical'
      disabled={loading}
      onFinish={(valueForm) => {
        onSubmitWithReCAPTCHA(valueForm);
      }}
      className='w-full'
      requiredMark={false}
      onFinishFailed={() => setTriggers(["onSubmit", "onBlur"])}
      validateTrigger={triggers}
    >
      <h3 className='text-3xl text-white'>¿Podemos ayudarte?</h3>

      <div className='grid grid-flow-row lg:grid-flow-col lg:gap-4'>
        <Form.Item
          name='name'
          label={<span className='text-white font-semibold'>Nombre</span>}
          disabled={loading}
          rules={[{ required: true, message: "Por favor ingrese su nombre!" }]}
        >
          <Input disabled={loading} className='border-transparent' placeholder='Tu nombre' />
        </Form.Item>

        <Form.Item
          disabled={loading}
          label={<span className='text-white font-semibold'>Email</span>}
          name='email'
          rules={[
            { required: true, message: "Por favor ingrese su email de contacto!" },
            {
              required: true,
              type: "email",
              message: "El email no es valido!",
            },
          ]}
        >
          <Input
            disabled={loading}
            className='border-transparent'
            placeholder='Tu email'
            type='email'
          />
        </Form.Item>
      </div>

      <Form.Item
        disabled={loading}
        label={<span className='text-white font-semibold'>Mensaje</span>}
        name='subject'
        rules={[{ required: true, message: "Por favor ingrese el mensaje que desea enviar!" }]}
      >
        <Input.TextArea
          rows={3}
          disabled={loading}
          className='border-transparent'
          placeholder='Mensaje'
        />
      </Form.Item>

      <div className='grid grid-flow-cols gap-5 lg:gap-0 lg:grid-cols-3'>
        <Button
          type='primary'
          size='large'
          className='bg-success'
          shape='round'
          htmlType='submit'
          disabled={loading}
          loading={loading}
        >
          Enviar
        </Button>
        <ReCAPTCHA
          className='flex justify-center lg:justify-end lg:col-start-2 lg:col-span-3'
          badge='inline'
          ref={recaptchaRef}
          sitekey={SITEKEY}
        />
      </div>
    </Form>
  );
};

export default React.memo(ContactForm);
