import React, { useEffect, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
// antd
import notify from "antd/lib/notification";
import Checkbox from "antd/lib/checkbox";
// gql
import { updatePlanFeature } from "views/Admin/Plans/gql";
// helpers
import get from "lodash/get";
import lowerCase from "lodash/lowerCase";
// Context
import PlanContext from "../Context";

export default function CheckFeature(id, planId, initialFeatures = []) {
  const { setStatus } = useContext(PlanContext);
  const [apply, { loading }] = useMutation(updatePlanFeature, {
    refetchQueries: ["getPlansSource"],
    awaitRefetchQueries: true,
  });

  const setNewFeatures = (value) => {
    setStatus({ loading: true });
    let newArray = [];
    if (value.target.checked) {
      newArray = [id, ...initialFeatures];
    } else {
      const index = initialFeatures.indexOf(id);
      newArray = [...initialFeatures.slice(0, index), ...initialFeatures.slice(index + 1)];
    }
    apply({
      variables: { id: planId, features: newArray },
    })
      .then((_) => {
        const plan = get(_, "data.updatePlan.name", "");
        const message = lowerCase(plan).includes("plan ")
          ? `${plan} Actualizado.`
          : `Plan ${plan} Actualizado.`;
        notify.success({ message });
        setStatus({ loading: false });
      })
      .catch((_) => {
        const message = "No fue posible actualizar el plan.";
        notify.error({ message });
        setStatus({ loading: false });
      });
  };

  const props = {
    checked: initialFeatures.includes(id),
    onChange: (value) => setNewFeatures(value),
    disabled: loading,
  };
  return <Checkbox {...props} />;
}
