import { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import {
  appointmentMDListPendingGql,
  appointmentMDListOngoingGql,
  appointmentMDIntervalPending,
  takeAppointmentMDGql,
  appointmentMDStatusGql,
} from "./gqlTags";
import { GlobalContext } from "../../../../App";
import head from "lodash/head";
import get from "lodash/get";
import { notification } from "antd";
import { HandleError } from "../../../../../utils.js/HandleError";

import { Howl, Howler } from "howler";
import soundForNotification from "../../../../../assets/sound/tone_easymd.wav";

const UseFetchDataMD = () => {
  const { state } = useContext(GlobalContext);
  const { user } = state;

  const [perPage] = useState(25);
  const [totalPending, setTotalPending] = useState(0);
  const [pagesPending, setPagesPending] = useState(0);
  const [currentPagePending, setCurrentPagePending] = useState(1);
  const [dataListPending, setDataListPending] = useState([]);
  const [dataListOngoing, setDataListOngoing] = useState([]);

  const [visibleDetail, setVisibleDetail] = useState(false);
  const [idDetail, setIdDetail] = useState(null);

  const [soundConfig] = useState(
    new Howl({
      src: [soundForNotification],
      loop: true,
      rate: 1,
    })
  );

  const [sort] = useState([
    {
      field: "CREATEDAT",
      direction: "DESCENDING",
    },
  ]);
  const [filterPending] = useState([
    {
      byText: {
        field: "STATUS",
        value: "pending",
      },
    },
  ]);

  const [filterOngoing] = useState([
    {
      byId: {
        field: "DOCTOR",
        value: user.id,
      },
      byText: {
        field: "STATUS",
        value: "ongoing",
      },
    },
  ]);

  // mutacion para asignarse la cita
  const [fetchTakeAppointment, { loading: loadingMutation, data: dataMutation }] = useMutation(
    takeAppointmentMDGql,
    { fetchPolicy: "no-cache" }
  );

  // mutacion para asignarse la cita
  const [
    getAppointmentStatus,
    { loading: getAppointmentStatusLoading, data: getAppointmentStatusData },
  ] = useLazyQuery(appointmentMDStatusGql, { fetchPolicy: "no-cache" });

  /// Lista para Card Izquierdo, Infinite Scroll
  // 1 data push al montarser y al paginar con scroll
  const {
    loading: loadingPending,
    error: errorPending,
    data: dataPending,
    refetch: refetchPending,
  } = useQuery(appointmentMDListPendingGql, {
    variables: {
      perPage: perPage,
      page: currentPagePending,
      sort: sort,
      filters: filterPending,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  // 2 data Interval para verificar nueva data
  const {
    loading: loadingIntervalPending,
    data: dataIntervalPending,
    startPolling: startIntervalPending,
    stopPolling: stopIntervalPending,
  } = useQuery(appointmentMDIntervalPending, {
    variables: {
      perPage: 1,
      page: 1,
      sort: sort,
      filters: filterPending,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  /// End querys Card Izquierdo

  // Lista de Card Derecho, Mis Citas Asignadas
  const {
    data: dataOngoing,
    loading: loadingOngoing,
    error: errorOngoing,
    refetch: refetchOngoing,
  } = useQuery(appointmentMDListOngoingGql, {
    variables: {
      perPage: perPage,
      page: 1,
      sort: sort,
      filters: filterOngoing,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  // data Interval para verificar nueva data
  const {
    loading: loadingIntervalOngoing,
    data: dataIntervalOngoing,
    startPolling: startIntervalOngoing,
    stopPolling: stopIntervalOngoing,
  } = useQuery(appointmentMDListOngoingGql, {
    variables: {
      perPage: perPage,
      page: 1,
      sort: sort,
      filters: filterOngoing,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  /// End querys Card Derecho

  useEffect(() => {
    Howler.stop();
    setTimeout(() => {
      startIntervalPending(15000);
      startIntervalOngoing(15000);
    }, 10000);
    return () => {
      Howler.stop();
      stopIntervalPending();
      stopIntervalOngoing();
    };
  }, []);

  useEffect(() => {
    if (!loadingMutation && dataMutation) {
      closeModalDetail();
      refetchAll();
    }
  }, [loadingMutation, dataMutation]);

  useEffect(() => {
    if (!loadingPending) {
      if (dataPending) {
        const { total, pages, docs } = dataPending.appointmentmds;
        setPagesPending(pages);
        setTotalPending(total);
        setDataListPending(dataListPending ? [...dataListPending, ...docs] : docs);
      }
    }
  }, [dataPending, loadingPending, errorPending]);

  useEffect(() => {
    if (!loadingIntervalPending) {
      if (dataIntervalPending) {
        verifyRefetchIntervalPending();
      }
    }
  }, [dataIntervalPending, loadingIntervalPending]);

  useEffect(() => {
    if (!loadingOngoing) {
      if (dataOngoing) {
        const { docs } = dataOngoing.appointmentmds;
        setDataListOngoing(docs || []);
      }
    }
  }, [dataOngoing, loadingOngoing, errorOngoing]);

  useEffect(() => {
    if (!loadingIntervalOngoing) {
      if (dataIntervalOngoing) {
        const { docs } = dataIntervalOngoing.appointmentmds;
        setDataListOngoing(docs || []);
      }
    }
  }, [dataIntervalOngoing, loadingIntervalOngoing]);

  const soundNotification = () => {
    if ((dataPending || errorPending) && !loadingPending) {
      soundConfig.play();
      setTimeout(() => {
        soundConfig.stop();
      }, 8000);
    }
  };

  const verifyRefetchIntervalPending = () => {
    const idFirstOfArray = getFirstId(dataListPending);
    const idInterval = getFirstId(dataIntervalPending.appointmentmds.docs);
    if (idFirstOfArray !== idInterval) {
      soundNotification();
      refetchAll();
    }
  };

  const refetchAll = () => {
    setDataListPending([]);
    setCurrentPagePending(1);
    refetchPending({
      perPage: perPage,
      page: 1,
      sort: sort,
      filters: filterPending,
    });
    refetchOngoing({
      perPage: perPage,
      page: 1,
      sort: sort,
      filters: filterOngoing,
    });
  };

  useEffect(() => {
    const appointment = getAppointmentStatusData?.appointmentmdById;
    if (appointment?.status) {
      if (appointment?.status === "PENDING") {
        return fetchTakeAppointment({
          variables: {
            id: appointment?.id,
          },
        });
      } else if (appointment?.status === "CANCELED") {
        notification.error({
          message: "No puedo tomar esta cita",
          description: "Paciente canceló la cita",
        });
      } else {
        notification.error({
          message: "Refrescando citas",
          description: "El estado de esta cita ya no está pendiente",
        });
      }
      refetchAll();
    }
  }, [getAppointmentStatusData]);

  const takeAppointment = async (id) => {
    getAppointmentStatus({ variables: { id } });
  };

  const closeModalDetail = () => {
    setIdDetail(null);
    setVisibleDetail(false);
  };

  const openModalDetail = (id) => {
    setIdDetail(id);
    setVisibleDetail(true);
  };

  const handleLoadMore = () => {
    if (!loadingPending) {
      setCurrentPagePending(currentPagePending + 1);
    }
  };

  const hasMoreCalc = () => {
    if (!dataPending) {
      return false;
    }
    if (totalPending / perPage > currentPagePending) {
      return true;
    }
    return false;
  };

  const getFirstId = (dataQuery) => {
    const firts = head(dataQuery);
    return get(firts, "id");
  };

  return {
    loadingFull:
      (!dataPending && !errorPending) || (!dataOngoing && !errorOngoing) || loadingMutation,
    isEmpty: true,
    refetchAll: () => refetchAll(),
    dataPending: dataListPending,
    dataOngoing: dataListOngoing,
    errorPending: !loadingPending && errorPending,
    errorOngoing: !loadingOngoing && errorOngoing,
    loadingPending: loadingPending || loadingMutation || getAppointmentStatusLoading,
    totalPending: totalPending,
    pagesPending: pagesPending,
    loadingOngoing: loadingOngoing || loadingMutation,
    handleLoadMore: () => handleLoadMore(),
    takeAppointment: (id) => takeAppointment(id),
    hasMoreCalc: hasMoreCalc(),
    visibleDetail: visibleDetail,
    closeModalDetail: () => closeModalDetail(),
    openModalDetail: (id) => openModalDetail(id),
    idDetail: idDetail,
  };
};

export default UseFetchDataMD;
