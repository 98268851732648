import React from "react";
import { Collapse } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import PanelContent from "./panelContent";

const { Panel } = Collapse;

const Header = () => {
  return (
    <p className='m-0 flex items-center'>
      Examenes médicos <CameraOutlined className='ml-3' />
    </p>
  );
};

const AdjuntosCollapse = () => {
  return (
    <Collapse bordered={false} defaultActiveKey={["1"]} accordion>
      <Panel header={<Header />} key='1'>
        <PanelContent />
      </Panel>
    </Collapse>
  );
};

export default AdjuntosCollapse;
