import React, { useState, useEffect, useCallback } from "react";
import Timer from "react-compound-timer";
import { Row, Col } from "antd";
import moment from "moment";

export const getDiffMilliseconds = (date) => {
  const fechaActual = moment(new Date(), "YYYY-MM-DD hh:mm").toLocaleString();
  const fechaAppointment = moment(date);
  var a = moment(fechaActual);
  var b = moment(fechaAppointment);
  const diff = b.diff(a);
  return diff;
};

const CuentaRegresiva = (props) => {
  const [diffTime, setDiffTime] = useState(0);
  const [timerProps, setTimerProps] = useState({
    days: false,
    hours: false,
    minutes: false,
    seconds: false,
  });

  const getInitialTime = useCallback(
    (appointmentDate) => {
      if (!appointmentDate) {
        setDiffTime(0);
        setTimerProps({
          days: false,
          hours: false,
          minutes: false,
          seconds: false,
        });
      } else {
        const finalTimer = {
          ...timerProps,
          days: false,
          hours: true,
          minutes: true,
          seconds: true,
        };

        const fechaActual = moment(new Date(), "YYYY-MM-DD hh:mm").toLocaleString();
        const fechaAppointment = moment(appointmentDate);
        var a = moment(fechaActual);
        var b = moment(fechaAppointment);

        const diasX1 = 86400000;
        const diasX3 = diasX1 * 3;

        const diff = b.diff(a);

        if (diff >= diasX1) {
          finalTimer.days = true;
        } else {
          if (diff >= diasX3) {
            finalTimer.days = true;
            finalTimer.hours = true;
            finalTimer.seconds = true;
          }
        }
        setDiffTime(diff);
        setTimerProps(finalTimer);
      }
    },
    [timerProps]
  );

  useEffect(() => {
    getInitialTime(props.date);
  }, [props.date]);

  if (diffTime === 0) {
    return null;
  }

  return (
    <Timer initialTime={diffTime} direction='backward'>
      {() => (
        <>
          <Row gutter={[10, 10]} className='mb-3 flex flex-row justify-center row-gap-6'>
            {timerProps.days && (
              <Col xs={12} sm={12} lg={6} className='h-20 bg-transparent px-2 py-2'>
                <div className='py-2 px-2 flex flex-col bg-transparent'>
                  <div className='bg-transparent w-full flex flex-col justify-center align-middle'>
                    <>
                      <Timer.Days
                        formatValue={(value) => (
                          <label
                            className={
                              (props.textSize ? props.textSize : "") +
                              "text-2xl text-center text-black font-semibold mb-0"
                            }
                          >
                            {value}
                          </label>
                        )}
                      />
                    </>
                  </div>
                  <p className='mb-0 mx-auto font-bold text-sm text-black'>Días</p>
                </div>
              </Col>
            )}
            {timerProps.hours && (
              <Col xs={12} sm={12} lg={6} className='h-20 bg-transparent px-2 py-2'>
                <div className='py-2 px-2 flex flex-col bg-transparent'>
                  <div className='bg-transparent w-full flex flex-col justify-center align-middle'>
                    <>
                      <Timer.Hours
                        formatValue={(value) => (
                          <label
                            className={
                              (props.textSize ? props.textSize : "") +
                              "text-2xl text-center text-black font-semibold mb-0"
                            }
                          >
                            {value}
                          </label>
                        )}
                      />
                    </>
                  </div>
                  <p className='mb-0 mx-auto font-semibold text-sm text-black'>Horas</p>
                </div>
              </Col>
            )}

            {timerProps.minutes && (
              <Col xs={12} sm={12} lg={6} className='h-20 bg-transparent px-2 py-2'>
                <div className='py-2 px-2 flex flex-col bg-transparent'>
                  <div className='bg-transparent w-full flex flex-col justify-center align-middle'>
                    <>
                      <Timer.Minutes
                        formatValue={(value) => (
                          <label
                            className={
                              (props.textSize ? props.textSize : "") +
                              "text-2xl text-center text-black font-semibold mb-0"
                            }
                          >
                            {value}
                          </label>
                        )}
                      />
                    </>
                  </div>
                  <p className='mb-0 mx-auto font-semibold text-sm text-black'>Minutos</p>
                </div>
              </Col>
            )}

            {timerProps.seconds && (
              <Col xs={12} sm={12} lg={6} className='h-20 bg-transparent px-2 py-2'>
                <div className='py-2 px-2 flex flex-col bg-transparent'>
                  <div className='bg-transparent w-full flex flex-col justify-center align-middle'>
                    <>
                      <Timer.Seconds
                        formatValue={(value) => (
                          <label
                            className={
                              (props.textSize ? props.textSize : "") +
                              "text-2xl text-center text-black font-semibold mb-0"
                            }
                          >
                            {value}
                          </label>
                        )}
                      />
                    </>
                  </div>
                  <p className='mb-0 mx-auto font-semibold text-sm text-black'>Segundos</p>
                </div>
              </Col>
            )}
          </Row>
        </>
      )}
    </Timer>
  );
};

export default CuentaRegresiva;
