import * as actionName from "./actionStoreMedic";

import initialState from "./initialStateStoreMedic";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionName.SET_MEDIC:
      return {
        ...action.payload,
      };

    case actionName.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case actionName.SET_TABS:
      return {
        ...state,
        tabsActive: action.payload,
      };

    case actionName.SET_STATUS:
      return {
        ...state,
        dataMedic: {
          ...state.dataMedic,
          approvalStatus: action.payload,
        },
      };

    default:
      return { ...state };
  }
};

export default reducer;
