import React, { useContext } from "react";
import { Card } from "antd";

import TitleCompany from "./titleCompany";
import EditInfo from "./editInfo";
import EditPlan from "./editPlan";
import UserCodes from "./userCodes";

import { ContextCompanyCodes } from "./index";

import style from "./styles.module.scss";
import props from "./propsStatistic";
import Statistic from "../../../components/StatisticCard";

const ContentCompany = () => {
  const { state } = useContext(ContextCompanyCodes);

  return (
    <div className='w-full min-h-25rem px-5 mb-3'>
      <TitleCompany />
      <div className='w-full mb-5 mt-2'>
        <div className='flex -mx-2'>
          <div className='xs:w-1/2 md:w-1/2 lg:w-2/6 px-2'>
            <Statistic {...props.patientsTrue(state.id)} />
          </div>
          <div className='xs:w-1/2 md:w-1/2 lg:w-2/6 px-2'>
            <Statistic {...props.patientsFalse(state.id)} />
          </div>
          <div className='xs:w-1/2 md:w-1/2 lg:w-2/6 px-2'>
            <Statistic {...props.totalAppointments(state.id)} />
          </div>
        </div>
      </div>
      <div className={"flex xs:flex-col lg:flex-row gap-5 " + style.contentCompany}>
        <Card className='xs:w-full md:w-full lg:w-3/6 xl:w-6/12'>
          <EditInfo />
          <EditPlan />
        </Card>
        <Card className='xs:w-full md:w-full lg:w-3/6 xl:w-6/12'>
          <UserCodes />
        </Card>
      </div>
    </div>
  );
};

export default ContentCompany;
