import React, { useContext } from "react";
// components
import User from "components/User";
import Person from "components/Person";
import Beneficiaries from "./Beneficiaries";
// context
import Context from "./Context";
// gql
import { upMePic as upPic } from "./gql";
// icons
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { GlobalContext } from "views/App";
// helpers
import get from "lodash/get";

const Title = ({ className, ...attr }) => <p className={`title ${className}`} {...attr} />;

export default function Sider() {
  const { state } = useContext(GlobalContext);
  const { person, setPerson } = useContext(Context);
  const { pictureURL, fullName, id } = state.user;

  const props = {
    Person: {
      id,
      gql: { upPic },
      pic: get(pictureURL, "url"),
      size: 40,
      name: fullName,
      label: "Titular",
      onSelect: setPerson,
      selected: person === id,
    },
  };

  return (
    <div className='p-5'>
      <User>
        <Title className='cursor-pointer mr-2'>
          <span className='mr-3'>Mi perfil</span>
          <FA icon={faChevronDown} className='text-base' />
        </Title>
      </User>
      <Person.Card {...props.Person} />
      <Title className='mt-5'>Mis Beneficiarios</Title>
      <Beneficiaries />
    </div>
  );
}
