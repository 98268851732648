import React, { useContext, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import QueueAnim from "rc-queue-anim";
// antd
import Card from "antd/lib/card";
import Spin from "antd/lib/spin";
import Empty from "antd/lib/empty";
import Radio from "antd/lib/radio";
import Notify from "antd/lib/notification";
// components
import Dialog from "./Dialog";
import BtnAdd from "./BtnAdd";
import CardPay from "./Card";
// gql
import { addCard, getMeCards, removeCard } from "views/Patient/gql";
// helpers
import get from "lodash/get";
import head from "lodash/head";
import keys from "lodash/keys";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
// context
import { GlobalContext } from "views/App";
// styles
import style from "./style.module.scss";
import { HandleParseError } from "utils.js/HandleError";

const msg = <p style={{ width: "auto", transform: "translateX(-50%)" }}>Muy pronto!</p>;

function MethodPayment({ Wrapper, disabled = false, ..._ }) {
  const { state } = useContext(GlobalContext);
  const variables = { id: state.user.id };

  const { data, loading, refetch, error } = useQuery(getMeCards, {
    variables,
    fetchPolicy: "no-cache",
  });

  const [remove] = useMutation(removeCard, { refetchQueries: ["getMeCards"] });

  const cards = get(data, ["patientById", "paymentMethods"], []);

  useEffect(() => {
    if (error) notifyError(error);
  }, [error]);

  const notifyError = (e) => {
    const error = HandleParseError(String(e).replace("Error: GraphQL error:", "").trim());
    error?.isCreditCard
      ? Notify.error({ message: "Error en el métodos de pago", description: error.isCreditCard })
      : Notify.error({ message: "Error al subir esta tarjeta ya esta agregada" });
  };

  const notifySuccess = (i) => {
    const hookName = head(keys(i.data));

    if (hookName.includes("add")) {
      Notify.success({ message: "Métodos de pago agregado con exito!" });
    }

    if (hookName.includes("delete")) {
      Notify.success({ message: "Métodos de pago removido con exito!" });
    }

    refetch();
  };

  const props = {
    Wrapper: {
      title: "Métodos de pago",
      extra: loading ? <Spin /> : null,
    },
    Dialog: {
      gql: addCard,
      onError: notifyError,
      children: <BtnAdd />,
      onSuccess: notifySuccess,
    },
    Spin: {
      spinning: disabled,
      indicator: disabled ? msg : null,
    },
    Queue: {
      type: ["right", "left"],
      ease: ["easeOutQuart", "easeInOutQuart"],
      className: _.onChange && !isEmpty(cards) ? style.radio : null,
      component: _.onChange && !isEmpty(cards) ? Radio.Group : "div",
      leaveReverse: true,
      ..._,
      onChange: (target) => getFullInfoCard(target),
    },
    Empty: {
      key: "0",
      description: false,
    },
  };

  const getFullInfoCard = (target) => {
    const idPayment = get(target, ["target", "value"], null);
    const fullInfo = find(cards, { id: idPayment });
    _.onChange(target, fullInfo);
  };

  const onRemove = (id) => {
    const variables = { id };

    remove({ variables }).then(notifySuccess).catch(notifyError);
  };

  return (
    <Spin {...props.Spin}>
      <Wrapper {...props.Wrapper}>
        <QueueAnim {...props.Queue}>
          {!loading && isEmpty(cards) && <Empty {...props.Empty} />}

          {_.onChange
            ? cards.map(({ id, ...o }) => {
                const r = id === _.value ? null : onRemove;
                const p = { ...o, id, onRemove: r };
                return (
                  <Radio key={id} value={id}>
                    <CardPay {...p} />
                  </Radio>
                );
              })
            : cards.map((o) => {
                const p = { ...o, key: o.id, onRemove };
                return <CardPay {...p} />;
              })}
        </QueueAnim>
        <Dialog {...props.Dialog} />
      </Wrapper>
    </Spin>
  );
}

MethodPayment.defaultProps = {
  Wrapper: Card,
};

export default MethodPayment;
