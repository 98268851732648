import React, { useState, useContext } from "react";
import { Card, Tag, Button, Empty } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../../App";
import { statusUsable } from "utils.js/statusColor";
import ModalPreviewFile from "components/ModalPreviewFile";
import BadgeStatus from "../../../../Patient/Tabs/Appointments/Card/Badge.js";
import BrandLoading from "components/CitasUtils/BrandLoading/brand";
import BtnPDF from "./BtnPDF";

// componentes
import { /* CarAvatarMedic */ CardExamen } from "./CardMedic";
// import CornerRibbons from 'components/CornerRibbons'

// icon
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardCitaMedic = (props) => {
  const { loading, appointments } = props;
  const [visibleModalExman, setVisibleModalExman] = useState(false);
  const [selectedExam, setSelectedExam] = useState({
    name: "",
    type: "",
    url: "",
  });
  const history = useHistory();
  const { state: globalState } = useContext(GlobalContext);
  const { user } = globalState;
  const role = user.role.name;

  if (loading) {
    return (
      <div className='flex justify-center items-center h-full w-full'>
        <BrandLoading width='35%' />
      </div>
    );
  }

  const sumitEye = (valueExam) => {
    setSelectedExam({
      name: valueExam.title,
      type: valueExam.media.mimetype,
      url: valueExam.media.url,
    });
    setVisibleModalExman(true);
  };

  const record = {
    medic: {
      name: appointments.doctor.fullName,
      specialties: appointments.doctor.specialties,
      minsaNumber: appointments.doctor.minsaNumber,
      signature: appointments.doctor.signature,
      sex: appointments.doctor.sex,
    },
    patient: {
      name: appointments.patient.fullName,
      dateOfBirth: appointments.patient.dateOfBirth,
      identification: appointments.patient.nationalId,
    },
    date: appointments.appointmentDateAndTime,
    diagnosis: appointments.diagnosis,
    prescription: appointments.prescription,
    appointmentNumber: appointments.appointmentNumber,
  };

  return (
    <div className='mx-auto my-5 w-11/12'>
      {
        <ModalPreviewFile
          visible={visibleModalExman}
          fileName={selectedExam.name}
          mimeType={selectedExam.type}
          url={selectedExam.url}
          onClose={() => {
            setVisibleModalExman(false);
            setSelectedExam({
              name: "",
              type: "",
              url: "",
            });
          }}
        />
      }

      <Card extra={<BadgeStatus status={appointments.status} />} title={null} loading={loading}>
        {
          // <CornerRibbons
          //   position='top_right'
          //   type={statusName(appointments.status).type}
          //   label={statusName(appointments.status).label}
          // />
        }

        <div className='grid grid-cols-12 gap-4'>
          <div className='col-span-12 md:col-span-4 '>
            <h3 className='text-xl text-primary truncate'>
              <strong>Razón de la cita</strong>
            </h3>
            {/* <div style={{ minHeight: "5rem", maxHeight: "5rem" }} className='overflow-auto'>
              <p>{appointments.reasonForConsultation || "N/A"}</p>
            </div> */}
            <div className='mt-3'>
              {/* <p className='font-bold mb-1'>Razón de la cita:</p> */}
              <p className='break-words mb-0' style={{ maxHeight: "200px", overflowY: "auto" }}>
                {appointments.reasonForConsultation}
              </p>
            </div>
            <p className='text-xl text-primary mt-6 mb-1'>
              <strong>Informacion del paciente</strong>
            </p>
            <div className='grid grid-cols-12 gap-4 mt-4'>
              <div className='col-span-12 my-1'>
                <div className='flex md:flex-row xs:flex-col justify-between'>
                  <p className='mb-3 md:mb-1 md:w-6/6 xs:w-full text-left'>
                    <strong>Nombre:</strong>
                  </p>
                  <p className='mb-3 md:mb-1 md:w-6/6 xs:w-full text-left ml-2'>
                    {appointments.patient.fullName || "N/A"}
                  </p>
                </div>
                <div className='col-span-12 my-1'>
                  <div className='flex md:flex-row xs:flex-col justify-between'>
                    <p className='mb-3 md:mb-1 md:w-1/2 xs:w-full text-left'>
                      <strong>Fecha de nacimiento:</strong>
                    </p>
                    <p className='mb-3 md:mb-1 md:w-1/2 xs:w-full text-left ml-2'>
                      {appointments.patient.dateOfBirth
                        ? moment(appointments.patient.dateOfBirth).format("DD MMMM YYYY")
                        : "N/A"}
                    </p>
                  </div>
                </div>
                <div className='col-span-12 my-1'>
                  <div className='flex md:flex-row xs:flex-col justify-between'>
                    <p className='mb-3 md:mb-1 md:w-1/2 xs:w-full text-left'>
                      <strong>Peso en Libras:</strong>
                    </p>
                    <p className='mb-3 md:mb-1 md:w-1/2 xs:w-full text-left ml-2'>
                      {appointments.patient.weight || "N/A"}
                    </p>
                  </div>
                </div>
                <div className='col-span-12 my-1'>
                  <div className='flex md:flex-row xs:flex-col justify-between'>
                    <p className='mb-3 md:mb-1 md:w-1/2 xs:w-full text-left'>
                      <strong>Sexo:</strong>
                    </p>
                    <p className='mb-3 md:mb-1 md:w-1/2 xs:w-full text-left ml-2'>
                      {appointments.patient.sex
                        ? appointments.patient.sex === "male"
                          ? "masculino"
                          : "femenino"
                        : "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {appointments.doctorMD ? (
            <div className='col-span-12 md:col-span-4'>
              <p className='text-xl text-primary mb-1'>
                <strong>Informacion del doctor</strong>
              </p>{" "}
              <div className='col-span-12 my-1'>
                <div className='flex md:flex-row xs:flex-col justify-between'>
                  <p className='mb-3 md:mb-1 md:w-1/2 xs:w-full text-left'>
                    <strong>Numero MINSA:</strong>
                  </p>
                  <p className='mb-3 md:mb-1 md:w-1/2 xs:w-full text-left ml-2'>
                    {role === "doctor"
                      ? appointments.doctorMD.minsaNumber
                      : appointments.doctor.minsaNumber}
                  </p>
                </div>
              </div>
              <div className='flex md:flex-row xs:flex-col justify-between'>
                <p className='mb-3 md:mb-1 md:w-6/6 xs:w-full text-left'>
                  <strong>Nombre:</strong>
                </p>
                <p className='mb-3 md:mb-1 md:w-6/6 xs:w-full text-left ml-2'>
                  {role === "doctor"
                    ? appointments.doctorMD.fullName
                    : appointments.doctor.fullName || "N/A"}
                </p>
              </div>
              {role === "doctorMD" ? (
                <div className='col-span-12 my-1'>
                  <div className='flex md:flex-row xs:flex-col justify-between'>
                    <p className='mb-3 md:mb-1 md:w-1/2 xs:w-full text-left'>
                      <strong>Especialidades:</strong>
                    </p>
                    <ul className='mb-3 md:mb-1 md:w-1/2 xs:w-full text-left ml-2'>
                      {" "}
                      {appointments.doctor.specialties.length >= 1
                        ? appointments.doctor.specialties.map((value, index) => (
                            <li key={index}>{value.name}</li>
                          ))
                        : null}
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          {appointments.exams ? (
            <div className='col-span-12 md:col-span-4  '>
              <h3 className='text-xl text-primary'>
                <strong>Examenes del Paciente</strong>
              </h3>
              <div style={{ maxHeight: "19rem", minHeight: "19rem" }} className='overflow-auto'>
                {appointments.exams.length > 0 ? (
                  appointments.exams.map((value, index) => (
                    <CardExamen
                      onClickEye={() => sumitEye(value)}
                      nameTitle={value.title}
                      key={index}
                    />
                  ))
                ) : (
                  <Empty description={<span>Sin Examenes</span>} />
                )}
              </div>
            </div>
          ) : null}
          <div className='col-span-12 md:col-span-4 '>
            <h3 className='text-xl text-primary'>
              <strong>Lista de síntomas</strong>
            </h3>
            <div className='my-2 overflow-auto w-full h-40'>
              {appointments.symptoms.length > 0 ? (
                appointments.symptoms.map((value, index) => (
                  <Tag key={index} className='rounded-lg px-3 py-2 my-1' color='#29AAE1'>
                    {value}
                  </Tag>
                ))
              ) : (
                <Empty description={<span>Sin síntomas</span>} />
              )}
            </div>
            <div className='flex justify-between items-center'>
              <h3 className='text-xl text-primary'>
                <strong>Fecha y Hora:</strong>
              </h3>
            </div>

            <div className='flex justify-start'>
              <FontAwesomeIcon className='text-primary my-auto mr-2' size='lg' icon={faClock} />
              <p className='text-primary my-auto'>
                {
                  // Jueves 24 de Octubre 2020
                }
                {`${moment(appointments.appointmentDateAndTime).format("dddd DD")} de ${moment(
                  appointments.appointmentDateAndTime
                ).format("MMMM YYYY hh:mm a")}`}
              </p>
            </div>
            <div className='mt-3 flex justify-end'>
              {appointments.usable !== "NOT_SCHEDULED" && (
                <Button
                  onClick={() => window.open(`/cita/doctor/llamada/${appointments.id}`, "_self")}
                  disabled={statusUsable(appointments.usable).disabledDoctor}
                  type={
                    statusUsable(appointments.usable).color === "primary" ? "primary" : "default"
                  }
                  shape='round'
                  className={statusUsable(appointments.usable).color}
                  size='large'
                >
                  {statusUsable(appointments.usable).labelDoctor}
                </Button>
              )}
              {appointments.status === "COMPLETE" && <BtnPDF record={record} />}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

CardCitaMedic.defaultProps = {
  loading: false,
  appointments: null,
  error: false,
};

export default CardCitaMedic;
