import jwt from "jsonwebtoken";
import get from "lodash/get";
import { useEffect } from "react";

export const getDecoded = async (sliceHash, sliceKey) => {
  return new Promise((resolver, reject) => {
    try {
      jwt.verify(sliceHash, sliceKey, (_, decode) => {
        if (decode) {
          console.log("🚀 ~ file: getDecoded.js:8 ~ jwt.verify ~ decode", decode);
          return resolver(decode);
        }
      });
    } catch (error) {
      console.log(error);
      return reject();
    }
  });
};

export function useCouponCode(match) {
  useEffect(() => {
    const couponCode = get(match, ["params", "id"], null);
    if (couponCode && String(couponCode).includes("coupon")) {
      localStorage.setItem("coupon", String(couponCode).slice(7));
    }
  }, [match]);
}
