import React, { useEffect, useContext, useState } from "react";
import { Upload, Modal, Button, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { SET_EXAMS_FILES } from "../../store/actions";
import { CheckOutContext } from "../index";

import gql from "graphql-tag";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

import ModalUpload from "./modalUpload";
import defaultPdfLogo from "../../../../../assets/img/pdf-icon-png.png";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// https://codesandbox.io/s/qm8s0?file=/index.js:595-728
const PanelContent = () => {
  const appointmentByIdGQL = () => {
    const schema = `query appointmentById($id: String!) {
            appointmentById(id: $id) {
                exams {
                    id
                    name: title
                    media {
                        uid: id 
                        url: url
                        mimetype
                    }
                }
                }
            }
        `;
    return gql`
      ${schema}
    `;
  };

  const deleteExamByIdGQL = () => {
    const schema = `mutation deleteExamById($id: String!) { 
            deleteExamById(id: $id)
        }`;
    return gql`
      ${schema}
    `;
  };

  const [fetchListExams, { loading: loadingExams, error: errorExams, data: dataExams }] =
    useLazyQuery(appointmentByIdGQL(), { fetchPolicy: "no-cache" });
  const [fetchDeleteExam, { loading: loadingDeleteExam }] = useMutation(deleteExamByIdGQL(), {
    fetchPolicy: "no-cache",
    onCompleted() {
      onRefetchImages();
    },
  });
  const { state, dispatch } = useContext(CheckOutContext);
  const [isPdfPreview, setIsPdfPreview] = useState(false);
  const [uploadVisible, setUploadVisible] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [filePreviewSelected, setFilePreviewSelected] = useState(null);

  const { adjuntos, idAppointment } = state;

  useEffect(() => {
    if (!errorExams && !loadingExams) {
      if (dataExams) {
        const newAdjuntos = [];
        dataExams.appointmentById.exams.forEach((value) => {
          if (value.media) {
            newAdjuntos.push({
              ...value.media,
              name: value.name,
              idExam: value.id,
            });
          }
        });
        dispatch({
          type: SET_EXAMS_FILES,
          payload: {
            adjuntos: newAdjuntos,
          },
        });
      }
    }
  }, [loadingExams, dataExams, errorExams, dispatch]);

  const getAdjuntosFinalFormat = () => {
    if (adjuntos) {
      const finalAdjuntos = [];
      adjuntos.forEach((value, index) => {
        finalAdjuntos.push({
          ...value,
          url: validateIsPdfMimeType(value.mimetype) ? defaultPdfLogo : value.url,
          urlDownload: value.url,
          idExam: value.idExam,
        });
      });
      return finalAdjuntos;
    }
    return [];
  };

  const validateIsPdfMimeType = (mimeType) => {
    let isPdf = false;
    switch (mimeType) {
      case "application/pdf":
        isPdf = true;
        break;
      case "pdf":
        isPdf = true;
        break;
      default:
        isPdf = false;
    }
    return isPdf;
  };

  const handlePreview = async (file) => {
    const { mimetype } = file;
    const isPdf = validateIsPdfMimeType(mimetype);

    setFilePreviewSelected(file);
    setIsPdfPreview(isPdf);
    setPreviewVisible(true);
  };

  const handleOnRemove = async (remove) => {
    try {
      await fetchDeleteExam({
        variables: {
          id: remove.idExam,
        },
      });
    } catch (e) {
      onRefetchImages();
    }
  };

  const closeModalPreview = () => {
    setPreviewVisible(false);
    setIsPdfPreview(false);
    setFilePreviewSelected(null);
  };

  const closeModalUpload = () => {
    setUploadVisible(false);
  };

  const openModalUpload = () => {
    setUploadVisible(true);
  };

  const onRefetchImages = () => {
    closeModalPreview();
    setUploadVisible(false);
    fetchListExams({
      variables: {
        id: idAppointment,
      },
    });
  };
  // loadingDeleteExam
  return (
    <Spin spinning={loadingDeleteExam || loadingDeleteExam}>
      <div className='grid grid-cols-1 gap-4'>
        <div className='w-full md:w-2/3 flex mx-auto py-3'>
          <Button
            type='default'
            block
            className='self-center border border-dashed border-success h-16'
            onClick={openModalUpload}
          >
            <PlusOutlined className='text-success' />
          </Button>
        </div>
        <div className='w-full mx-auto'>
          <Upload
            listType='picture-card'
            fileList={getAdjuntosFinalFormat()}
            onPreview={handlePreview}
            onRemove={handleOnRemove}
            openFileDialogOnClick={false}
            showUploadList={{
              showDownloadIcon: false,
              showPreviewIcon: true,
              showRemoveIcon: true,
            }}
          />
        </div>

        {previewVisible && filePreviewSelected && (
          <Modal visible title='' footer={null} onCancel={closeModalPreview}>
            <p className='text-2xl font-semibold w-5/6 mb-3 capitalize'>
              {filePreviewSelected.name}
            </p>
            {isPdfPreview ? (
              <a target='blank' href={filePreviewSelected.urlDownload} className='mx-auto' download>
                <FontAwesomeIcon icon={faDownload} className='mr-2 self-center' /> Descargar PDF
              </a>
            ) : (
              <img
                alt='preview_image'
                className='mx-auto'
                style={{ width: "70%" }}
                src={filePreviewSelected.url}
              />
            )}
          </Modal>
        )}

        {uploadVisible && (
          <ModalUpload visible onClose={closeModalUpload} onRefetchImages={onRefetchImages} />
        )}
      </div>
    </Spin>
  );
};

export default PanelContent;
