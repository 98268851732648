import React from "react";
import { Form, Input } from "antd";

const FormModalAdd = (props) => {
  const newArrayItem = (stringItems) => {
    const newItems = stringItems.NewItems.split(",");
    props.onFinishForm(newItems);
  };

  return (
    <Form
      form={props.form}
      layout='horizontal'
      name='basic'
      initialValues={{ remember: true }}
      onFinish={(valueform) => newArrayItem(valueform)}
    >
      <Form.Item
        className='mt-10'
        label={null}
        name='NewItems'
        rules={[
          {
            required: true,
            message: "Por favor ingrese el nombre de los ítems ",
          },
        ]}
      >
        <Input.TextArea
          rows={4}
          // disabled={props.loading}
          size='large'
          className='rounded'
          placeholder='Ingrese el nombre del ítems'
        />
      </Form.Item>
    </Form>
  );
};

export default FormModalAdd;
