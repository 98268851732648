import React from "react";
import PropTypes from "prop-types";

import Translator from "../resources/localization/translator";
import { theme } from "../resources/theme";

import blueFile from "./resources/senderfile.png";
import { Spin } from "antd";
import heic2any from "heic2any";
import "./Style.scss";
import checkImageFormat from "utils.js/checkImageFormat";

class SenderFileMessageBubble extends React.Component {
  messageFrom = "sender";

  constructor(props) {
    super(props);

    const message = Object.assign({}, props.message, {
      messageFrom: this.messageFrom,
    });

    this.state = {
      message: message,
      isHovering: false,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    const previousMessageStr = JSON.stringify(prevProps.message);
    const currentMessageStr = JSON.stringify(this.props.message);

    if (previousMessageStr !== currentMessageStr) {
      const message = Object.assign({}, this.props.message, {
        messageFrom: this.messageFrom,
      });
      this.setState({ message: message });
    }
  }

  handleMouseHover = () => {
    this.setState(this.toggleHoverState);
  };

  toggleHoverState = (state) => {
    return {
      isHovering: !state.isHovering,
    };
  };

  handleDownloadClick = async () => {
    try {
      // Convert the HEIC file to JPEG
      if (this.state.loading) return;
      this.setState({ loading: true });
      const response = await fetch(this.state.message.file);
      const blob = await response.blob();
      const jpegData = await heic2any({ blob, quality: 0.1 });

      // Create a new Blob with the JPEG data
      const jpegBlob = new Blob([jpegData], { type: "image/jpeg" });

      // Create a URL for the Blob
      const jpegUrl = URL.createObjectURL(jpegBlob);

      // Create an anchor element to trigger the download
      const a = document.createElement("a");
      a.href = jpegUrl;
      let name = this?.state?.message?.file?.split("/").pop();
      a.download = name?.split(".")[0];
      a.click();

      // Revoke the URL to free up resources
      URL.revokeObjectURL(jpegUrl);
      this.setState({ loading: false });
    } catch (error) {
      console.error("Error converting and downloading the file:", error);
    }
  };

  render() {
    const { file, file_type } = this.state?.message;
    return (
      <div className='sender-bubble-container'>
        {!checkImageFormat(file, file_type) ? (
          <div style={{ cursor: "pointer", paddingTop: "10px" }}>
            <a
              onClick={this.handleDownloadClick}
              rel='noopener noreferrer'
              className='sender-bubble-center'
            >
              <img src={blueFile} alt='file' />
              {!this.state.loading && this.props.download && (
                <p style={{ color: "#fff" }}>{"Descargar archivo"}</p>
              )}
            </a>
          </div>
        ) : (
          <div style={{ cursor: "pointer", paddingTop: "10px" }}>
            <a
              href={this.state?.message?.file}
              rel='noopener noreferrer'
              className='sender-bubble-center'
            >
              <img src={blueFile} alt='file' />
              {this.props.download && <p style={{ color: "#fff" }}>{"Descargar archivo"}</p>}
            </a>
          </div>
        )}
        {!this.props.download && (
          <div className='sender-bubble-download'>
            <Spin />
            <p style={{ color: "#fff" }}>{"enviando..."}</p>
          </div>
        )}
        {this.state.loading && (
          <div className='sender-bubble-download'>
            <Spin />
            <p style={{ color: "#fff" }}>{"Descargando..."}</p>
          </div>
        )}
      </div>
    );
  }
}

// Specifies the default values for props:
SenderFileMessageBubble.defaultProps = {
  lang: Translator.getDefaultLanguage(),
  theme: theme,
};

SenderFileMessageBubble.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.object,
};

export default SenderFileMessageBubble;
