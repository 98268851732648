import { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import {
  getPatientsCSV,
  getCompanyUsersCSV,
  getAllUsersCompanyCSV,
  getUsersWithPlanCSV,
  getUsersWithOutPlanCSV,
  getAppointmentsUsersCSV,
  getAppointmentsUsersCompanyCSV,
  getEarningsCSV,
  getTransactionsCSV,
  getTransactionsReviewCSV,
  getActiveCompanyPlans,
  getActivePatientPlans,
  getActivePlans,
  getTotalPlans,
  getTotalAppointments,
} from "./gqlTags";
import { get } from "lodash";
import { getDefaultDates } from "components/SelectorRange";

function useDownloadCsv() {
  const [dateFilter, setDateFilter] = useState(false);
  const [type, setType] = useState("patients");
  const [date, setDate] = useState(getDefaultDates(false, true));
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getProperty = {
    patients: "getPatientsCSV",
    companyUsers: "getCompanyUsersCSV",
    allCompanyUsers: "getAllUsersCompanyCSV",
    usersWithPlan: "getUsersWithPlanCSV",
    usersWithOutPlan: "getUsersWithOutPlanCSV",
    earnings: "getEarningsCSV",
    appointmentsUsers: "getAppointmentsUsersCSV",
    appointmentsUsersCompany: "getAppointmentsUsersCompanyCSV",
    transactionsReview: "getTransactionsReviewCSV",
    activeCompanyPlans: "getActiveCompanyPlans",
    activePatientPlans: "getActivePatientPlans",
    activePlans: "getActivePlans",
    totalPlans: "getTotalPlans",
    totalAppointments: "getTotalAppointments",
  };

  const getQuery = {
    patients: getPatientsCSV,
    companyUsers: getCompanyUsersCSV,
    allCompanyUsers: getAllUsersCompanyCSV,
    usersWithPlan: getUsersWithPlanCSV,
    usersWithOutPlan: getUsersWithOutPlanCSV,
    appointmentsUsers: getAppointmentsUsersCSV,
    appointmentsUsersCompany: getAppointmentsUsersCompanyCSV,
    earnings: getEarningsCSV,
    transactions: getTransactionsCSV,
    transactionsReview: getTransactionsReviewCSV,
    activeCompanyPlans: getActiveCompanyPlans,
    activePatientPlans: getActivePatientPlans,
    activePlans: getActivePlans,
    totalPlans: getTotalPlans,
    totalAppointments: getTotalAppointments,
  };

  const { loading, error, data } = useQuery(getQuery[type], {
    fetchPolicy: "no-cache",
    variables: { ...(!dateFilter && { all: !dateFilter }), ...(dateFilter && { ...date }) },
  });

  const onReportChange = (value) => setType(value);
  const onDateChange = (value) => setDate(value);
  const downloadLink = get(data, `${getProperty[type]}.download`);

  const clickDateFilter = () => {
    setDateFilter((prev) => !prev);
  };

  return {
    downloadLink,
    loading,
    error,
    isModalVisible,
    dateFilter,
    clickDateFilter,
    onReportChange,
    onDateChange,
    showModal,
    handleCancel,
  };
}

export default useDownloadCsv;
