const initialState = {
  modalInfo: false,
  modalAceptar: false,
  modalRechazar: false,
  infoDoctorSelect: null,
  loadingModal: false,
  randomValue: null,
};

export default initialState;
