import gql from "graphql-tag";

export const appointmentMDStatusGql = gql`
  query appointmentmdById($id: String!) {
    appointmentmdById(id: $id) {
      status
    }
  }
`;

export const appointmentStatusGql = gql`
  query appointmentById($id: String!) {
    appointmentById(id: $id) {
      usable
    }
  }
`;
