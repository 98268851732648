import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Form, Input, Button, Tooltip, Tag, notification, Empty, Card } from "antd";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import defaultPdfLogo from "../../../assets/img/pdf-icon-png.png";

import ModalPreviewFile from "../../../components/ModalPreviewFile";

const FormUpdateAppointment = (props) => {
  const appointmentByIdGQL = () => {
    const schema = `query appointmentById($id: String!) {
            appointmentById(id: $id) {
                id
                prescription
                diagnosis
                status
                patient {
                    id
                }
                exams {
                    id
                    title
                    media {
                        url
                        mimetype
                    }
                }
            }
        }
        `;
    return gql`
      ${schema}
    `;
  };

  const updateAppointmentGql = () => {
    return gql`
      mutation updateAppointment(
        $id: String!
        $prescription: [String!]
        $diagnosis: String
        $status: updateAppointmentStatus!
        $patient: String!
      ) {
        updateAppointment(
          id: $id
          prescription: $prescription
          diagnosis: $diagnosis
          status: $status
          patient: $patient
        ) {
          id
        }
      }
    `;
  };

  const {
    loading: loadingAppointment,
    error: errorAppointment,
    data: dataAppointment,
  } = useQuery(appointmentByIdGQL(), {
    variables: { id: props.idAppointment },
    fetchPolicy: "no-cache",
  });

  const [
    fetchCreateAppointment,
    { loading: loadingMutation, error: errorMutation, data: dataMutation },
  ] = useMutation(updateAppointmentGql(), { fetchPolicy: "no-cache" });

  // const [listamedicamentos, setListamedicamentos] = useState([])
  const [listaPreescripcion, setListaPreescripcion] = useState([]);
  const [diagnosticoNotas, setDiagnosticoNotas] = useState();
  const [previewVisible, setPreviewVisible] = useState(false);
  // const [isPdfPreview, setIsPdfPreview] = useState(false)
  const [examsFormat, setExamsFormat] = useState(null);
  const [filePreviewSelected, setFilePreviewSelected] = useState(null);
  const [form] = Form.useForm();

  const getAdjuntosFinalFormat = useCallback((exams) => {
    if (exams) {
      const finalAdjuntos = [];
      exams.forEach((value) => {
        finalAdjuntos.push({
          ...value.media,
          name: value.title,
          idExam: value.id,
          url: validateIsPdfMimeType(value.media.mimetype) ? defaultPdfLogo : value.media.url,
          urlDownload: value.media.url,
        });
      });
      setExamsFormat(finalAdjuntos);
    } else {
      setExamsFormat(null);
    }
  }, []);

  useEffect(() => {
    if (dataAppointment && !loadingAppointment) {
      getAdjuntosFinalFormat(dataAppointment.appointmentById.exams);
      // setListamedicamentos(dataAppointment.appointmentById.prescription)
      setDiagnosticoNotas(dataAppointment.appointmentById.diagnosis);
      setListaPreescripcion(dataAppointment.appointmentById.prescription);
    }
  }, [loadingAppointment, errorAppointment, dataAppointment, getAdjuntosFinalFormat]);

  useEffect(() => {
    if (!loadingMutation && dataMutation) {
      notification.success({
        message: "Diagnóstico guardado",
        description: "El diagnóstico se guardo con éxito",
      });
    }
  }, [loadingMutation, errorMutation, dataMutation]);

  const validateIsPdfMimeType = (mimeType) => {
    let isPdf = false;
    switch (mimeType) {
      case "application/pdf":
        isPdf = true;
        break;
      case "pdf":
        isPdf = true;
        break;
      default:
        isPdf = false;
    }
    return isPdf;
  };

  const handlePreview = async (file) => {
    setFilePreviewSelected(file);
    setPreviewVisible(true);
  };

  const finishForm = async () => {
    try {
      const variables = {
        id: props.idAppointment,
        prescription: listaPreescripcion,
        diagnosis: diagnosticoNotas,
        status: dataAppointment.appointmentById.status,
        patient: dataAppointment.appointmentById.patient.id,
      };
      await fetchCreateAppointment({
        variables: variables,
      });
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error al actualizar la cita",
      });
    }
  };

  const onChangeTextArea = (value) => {
    setDiagnosticoNotas(value.target.value);
  };

  const addNewTagPree = (value) => {
    setListaPreescripcion([...listaPreescripcion, value]);
  };

  const removeTagPree = (index) => {
    const newArray = [];
    listaPreescripcion.forEach((value, i) => {
      if (i !== index) {
        newArray.push(value);
      }
    });
    setListaPreescripcion(newArray);
  };

  return (
    <Row>
      <Col xs={24}>
        <ModalPreviewFile
          visible={previewVisible}
          fileName={filePreviewSelected ? filePreviewSelected.name : null}
          mimeType={filePreviewSelected ? filePreviewSelected.mimetype : null}
          url={filePreviewSelected ? filePreviewSelected.urlDownload : null}
          onClose={() => setPreviewVisible(!previewVisible)}
        />
        <Form
          layout='vertical'
          className='flex flex-col'
          form={form}
          initialValues={{ diagnosticoNotas: "" }}
        >
          <Form.Item
            className='mb-0'
            label={<label className='font-bold text-lg'>Exámenes/Fotos</label>}
          >
            <div className='w-full h-auto p-2' style={{ maxHeight: "250px", overflow: "auto" }}>
              {!examsFormat ? (
                <Empty description={false} />
              ) : examsFormat.length === 0 ? (
                <Empty description={false} />
              ) : (
                examsFormat.map((value, index) => {
                  return (
                    <Card
                      key={index}
                      className='w-full mb-3 flex flex-col justify-between'
                      bodyStyle={{ padding: 0 }}
                    >
                      <Row className='p-3'>
                        <Col xs={24} className='grid grid-flow-col grid-cols-2 grid-rows-1 gap-4'>
                          <p className='mb-0'>{value.name}</p>
                          <p className='mb-0 capitalize flex flex-row-reverse'>
                            <Button
                              onClick={() => handlePreview(value)}
                              size='small'
                              type='default'
                              shape='round'
                              className='border-0 p-0'
                            >
                              <FontAwesomeIcon icon={faEye} className='self-center' />
                            </Button>
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  );
                })
              )}
            </div>
          </Form.Item>

          <Form.Item
            className='mb-4'
            label={<label className='font-bold text-lg'>diagnóstico/Notas</label>}
          >
            <Input.TextArea
              disabled={loadingMutation || loadingMutation}
              onChange={onChangeTextArea}
              value={diagnosticoNotas}
              placeholder='Diagnóstico y notas sobre la cita'
              rows={12}
            />
          </Form.Item>
          <Form.Item
            label={
              <label className='font-bold text-lg' name='prescripcionMedica'>
                Prescripción medica
              </label>
            }
          >
            <PreescriptionTag newTag={addNewTagPree} />
            <Row
              gutter={[0, 4]}
              className='mt-2 py-2'
              style={{ maxHeight: "250px", overflow: "auto" }}
            >
              {listaPreescripcion.map((value, index) => {
                return (
                  <Col xs={24} key={index}>
                    <Tag
                      key={value + "_" + index}
                      color='default'
                      className='p-1 px-2 mb-2 rounded-lg border border-success-lighter'
                      closable
                      onClose={() => removeTagPree(index)}
                    >
                      {value.length > 25 ? (
                        <Tooltip title={value}>{value.substring(0, 22) + " ..."}</Tooltip>
                      ) : (
                        value
                      )}
                    </Tag>
                  </Col>
                );
              })}
            </Row>
          </Form.Item>
          <Form.Item label={null} className='self-center justify-end'>
            <Button
              type='primary'
              shape='round'
              size='large'
              className='mx-auto'
              onClick={finishForm}
              disabled={loadingMutation || loadingMutation}
            >
              Enviar diagnóstico y receta{" "}
              <FontAwesomeIcon icon={faEdit} className='ml-2 self-center' />
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const PreescriptionTag = (props) => {
  const { Search } = Input;
  const [valueInput, setValue] = useState("");

  const agregarTag = (e, event) => {
    if (e.length > 0) {
      setValue("");
      props.newTag(e);
    } else {
      setValue("");
    }
  };

  return (
    <Search
      placeholder='Detalles'
      enterButton='+ Agregar'
      size='large'
      allowClear
      onChange={(e) => setValue(e.target.value)}
      value={valueInput}
      onSearch={(value, event) => agregarTag(value, event)}
    />
  );
};

export default FormUpdateAppointment;
