import * as actionName from "./actions";
import initialState from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionName.SET_INITIAL_STATE:
      return {
        ...initialState,
        roles: state.roles,
        refetch: state.refetch,
      };

    case actionName.SET_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };

    case actionName.SET_REFETCH:
      return {
        ...state,
        refetch: action.payload,
      };

    case actionName.SET_MODAL_INFO_OPEN:
      return {
        ...state,
        modalOpenInfo: action.payload,
        modalOpenDelete: false,
        modalNewUser: false,
        modalEditUser: false,
      };

    case actionName.SET_MODAL_DELETE_OPEN:
      return {
        ...state,
        modalOpenInfo: false,
        modalOpenDelete: action.payload,
        modalNewUser: false,
        modalEditUser: false,
      };

    case actionName.SET_MODAL_EDIT_OPEN:
      return {
        ...state,
        modalOpenInfo: false,
        modalOpenDelete: false,
        modalNewUser: false,
        modalEditUser: action.payload,
      };

    case actionName.SET_MODAL_NEW_USER_OPEN:
      return {
        ...state,
        modalOpenInfo: false,
        modalOpenDelete: false,
        modalNewUser: action.payload,
        modalNewRole: false,
        modalEditUser: false,
      };

    case actionName.SET_MODAL_NEW_ROLE_OPEN:
      return {
        ...state,
        modalOpenInfo: false,
        modalOpenDelete: false,
        modalNewUser: false,
        modalNewRole: action.payload,
        modalEditUser: false,
      };

    case actionName.SET_PAGINATION_VARS:
      return {
        ...state,
        paginationVars: action.payload,
      };

    case actionName.SET_SEARCH_NAME:
      return {
        ...state,
        searchName: action.payload,
      };

    case actionName.SET_FILTER_USERS:
      return {
        ...state,
        filterUsers: { ...state.filterUsers, ...action.payload },
      };

    case actionName.SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };

    case actionName.SET_ROLE:
      return {
        ...state,
        role: action.payload,
      };

    default:
      return { ...state };
  }
};

export default reducer;
