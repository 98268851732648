import React, { useState } from "react";
import Lottie from "react-lottie";
// components
import ContactForm from "layouts/site/footer/ContactForm";
import SendEmailLottie from "assets/lottie/sendEmail.json";

export default function ContactUs() {
  const [renderForm, setRenderForm] = useState(true);

  const onSuccessEmail = () => {
    setRenderForm(false);
    setTimeout(() => {
      setRenderForm(true);
    }, 10000);
  };

  const props = {
    lottie: {
      isClickToPauseDisabled: true,
      options: {
        loop: true,
        autoplay: true,
        animationData: SendEmailLottie,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      },
      height: "auto",
      width: "150px",
    },
  };

  return renderForm ? (
    <ContactForm onSuccessEmail={onSuccessEmail} />
  ) : (
    <div className='w-full h-full p-8'>
      <Lottie {...props.lottie} />
      <p className='font-semibold text-white md:text-sm xl:text-lg text-center my-3'>
        Su mensaje se ha enviado con éxito. Muchas gracias. En breve recibirá una respuesta.
      </p>
    </div>
  );
}
