import React from "react";
import { Button, Modal } from "antd";

import DetailAppointment from "./index";
import { useQuery } from "@apollo/react-hooks";
import { appointmentByMDDetailGql } from "../gqlTags";
import Spin from "antd/lib/spin";

import { getDataForCard } from "../utils";
import Empty from "antd/lib/empty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileVideo } from "@fortawesome/free-solid-svg-icons";
import Popconfirm from "antd/lib/popconfirm";

const ModalDetail = ({ visible, afterClose, id, takeAppointment, loading }) => {
  return (
    <Modal
      title='Detalles de la cita'
      visible={visible}
      destroyOnClose
      onCancel={afterClose}
      footer={null}
      afterClose={afterClose}
      maskClosable={false}
    >
      {visible && id ? (
        <BodyModalNew id={id} takeAppointment={takeAppointment} loading={loading} />
      ) : null}
    </Modal>
  );
};

const BodyModalNew = ({ id, takeAppointment, loading: loadingMutation }) => {
  const { loading, error, data } = useQuery(appointmentByMDDetailGql, {
    variables: {
      id: id,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    return (
      <div style={{ minHeight: "200px" }} className='w-full flex'>
        <Empty
          className='mt-5'
          description={<p className='text-danger'>Error al obtener las citas</p>}
        />
      </div>
    );
  } else if (loading && !data && !error) {
    return (
      <div style={{ minHeight: "200px" }} className='w-full flex'>
        <Spin spinning className='mx-auto my-auto' />
      </div>
    );
  } else {
    const dataForDetail = getDataForCard(data.appointmentmdById);
    return (
      <div className='w-full flex flex-col xs:px-3 md:px-12 justify-items-center'>
        <DetailAppointment {...dataForDetail} />
        <div className='w-full mt-5 flex flex-col'>
          <Popconfirm
            title='¿ Asignar esta cita a mi perfil ?'
            onConfirm={() => takeAppointment(id)}
            okText='Si'
            cancelText='No'
            disabled={loading}
          >
            <Button
              type='default'
              shape='round'
              disabled={loading || loadingMutation}
              className='mx-auto my-auto button-success xs:mb-5 lg:mb-auto'
            >
              <FontAwesomeIcon icon={faFileVideo} className='mr-2' />
              Asignar
            </Button>
          </Popconfirm>
        </div>
      </div>
    );
  }
};

export default ModalDetail;
