import React from "react";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

import Lottie from "react-lottie";
import animationArrowDown from "../../../../assets/lottie/arrowScrollDown.json";

// css
import style from "../styles.module.scss";

const SectionOne = () => {
  const defaultOptionsAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationArrowDown,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  let smsMessage = "";
  if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
    smsMessage = "sms:400?body=SALUD";
  } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    smsMessage = "sms:400&body=SALUD";
  }
  return (
    <div className={`${style.containerHome} bg-no-repeat bg-cover pt-16 lg:pt-32`}>
      <div className='flex flex-col justify-center items-center py-3'>
        <div className={`${style.divGlass} p-12 flex flex-col justify-center items-center`}>
          <h3
            style={{ color: "#373A4A", zIndex: 1 }}
            className='text-3xl lg:text-5xl mb-1 text-center leading-8 md:leading-10'
          >
            <strong> Cuida de tu salud</strong>
          </h3>
          <h3
            style={{ zIndex: 1 }}
            className='text-primary text-3xl  lg:text-5xl text-center leading-8 md:leading-10'
          >
            <strong>sin atrasos ni complicaciones.</strong>
          </h3>
          {smsMessage && (
            <a href={smsMessage} className='ant-btn text-lg mt-2 ant-btn-primary ant-btn-round'>
              Activa tu Plan Ya
            </a>
          )}
          <p className='text-primary text-3xl mt-2 lg:text-5xl text-center leading-8 md:leading-10'>
            Envía <strong>SALUD</strong> al <strong>400</strong>
          </p>
          {
            //  <Button
            //   type='link'
            //   size='large'
            //   className={`${style.linkBlue} ml-1 text-lg`}
            //   onClick={() => history.push('/md/planesEasyMD')}
            // >
            //   <strong className='hover:underline'>
            //       Ver planes Easy MD
            //   </strong>
            //   <FontAwesomeIcon
            //     className='ml-1'
            //     icon={faAngleRight}
            //   />
            // </Button>
          }
          <div className='w-ful mt-6 hidden'>
            <Lottie isClickToPauseDisabled width='50%' options={defaultOptionsAnimation} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
