import React from "react";
import QueueAnim from "rc-queue-anim";
// components
import Info from "./Info";
import Conditions from "./Condition";
import BannerConsulta from "./BannerConsulta";
import BannerGold from "./BannerGold";
import BannerBlue from "./BannerBlue";
import BannerGreen from "./BannerGreen";
import Appointments from "./Appointments";
import MethodPayment from "./MethodPayment";
// styles
import style from "../../style.module.scss";

const props = {
  Queue: {
    key: "tabs",
    type: "bottom",
    component: "section",
    className: style.gridHome,
  },
};

export default function HomeTab() {
  return (
    <QueueAnim {...props.Queue}>
      <BannerConsulta key='1' />
      <BannerGold key='2' />
      <BannerBlue key='3' />
      <BannerGreen key='4' />
      <Appointments key='5' />
      <Info key='6' />
      <Conditions key='7' />
      <MethodPayment key='8' />
    </QueueAnim>
  );
}
