const initialState = {
  modalOpenInfo: false,
  modalOpenDeny: false,
  modalNewCoupon: false,
  modalEditCoupon: false,
  idCouponSelect: null,
  refetch: () => {},
};

export default initialState;
