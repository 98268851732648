import React, { useEffect } from "react";

// Mutations
import { useMutation } from "@apollo/react-hooks";
import { deletePlanFeatureById } from "../gql";

import { ExclamationCircleOutlined } from "@ant-design/icons";

// Styles
import inputs from "./inputs";
import styles from "../style.module.scss";

// Ant Design
import { Button, Modal, notification } from "antd";
const { confirm } = Modal;

export default function DeleteFeatureButton({ id }) {
  const [deleteFeatureMutation, { error }] = useMutation(deletePlanFeatureById);

  const props = {
    className: styles.deleteFeatureButton,
    ...inputs.actions.remove,
    onClick: () => {
      onDeletePlan();
    },
  };

  const deleteFeature = () => {
    deleteFeatureMutation({
      variables: {
        id,
      },
      refetchQueries: ["getPlansSource"],
    }).then(() => {
      notification.success({ message: "Característica eliminada con éxito" });
    });
  };

  useEffect(() => {
    if (error) {
      notification.error({ message: "Error al eliminar el plan" });
    }
  }, [error]);

  const onDeletePlan = () => {
    confirm({
      title: "Eliminar característica",
      icon: <ExclamationCircleOutlined />,
      content: "Estás seguro que deseas eliminar la característica?",
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk: deleteFeature,
    });
  };

  return <Button {...props} />;
}
