import React from "react";
import { Button, Card, Form, Modal } from "antd";
import { useMarketing } from "./hook";
import InputForm from "../../../components/InputForm";

export default function Marketing() {
  const {
    form,
    loading,
    mobile,
    visibleContent,
    disabledFinish,
    onValuesChange,
    setVisibleContent,
    sendNotification,
  } = useMarketing();
  return (
    <div className='m-3 p-3 w-full mx-auto md:w-3/5'>
      <h2 className='text-2xl'>
        <strong>Mercadeo</strong>
      </h2>
      <div className='mt-5'>
        <Card bodyStyle={{ padding: "3rem" }} title={"Notificacion"}>
          <div className='flex'>
            <Form
              form={form}
              layout='horizontal'
              validateTrigger='onBlur'
              name='notification'
              onValuesChange={onValuesChange}
              onFinish={(values) => sendNotification(values)}
              className='w-full mb-8'
            >
              <div className='w-full flex flex-row gap-5'>
                <div className='flex-1'>
                  <InputForm
                    typeInput='select'
                    seeTooltip={false}
                    item={{
                      label: null,
                      name: "medium",
                      rules: [{ required: true, message: "Por favor selecionar!" }],
                    }}
                    select={{
                      disabled: false,
                      mode: "single",
                      allowClear: true,
                      showArrow: true,
                      bordered: true,
                      size: "large",
                      className: "w-full rounded",
                      placeholder: "Medio",
                    }}
                    options={["MOBILE", "EMAIL"]}
                  />
                </div>
                <div className='flex-1'>
                  <InputForm
                    typeInput='select'
                    seeTooltip={false}
                    item={{
                      label: null,
                      name: "recipients",
                      rules: [{ required: true, message: "Por favor selecionar!" }],
                    }}
                    select={{
                      disabled: false,
                      mode: "single",
                      allowClear: true,
                      showArrow: true,
                      bordered: true,
                      size: "large",
                      className: "w-full rounded",
                      placeholder: "Remitentes",
                    }}
                    options={["ACTIVE_PLANS"]}
                  />
                </div>
              </div>
              <div className='flex justify-end'>
                <Button
                  disabled={disabledFinish || loading}
                  loading={loading}
                  type='default'
                  shape='round'
                  size='large'
                  className='my-5 mx-1 shadow-lg md:my-0'
                  onClick={() => setVisibleContent((v) => !v)}
                >
                  Editar Contenido
                </Button>
              </div>
            </Form>
            <Modal
              destroyOnClose
              style={{ minWidth: "20rem" }}
              className='w-9/12 md:w-10/12 lg:w-7/12'
              // width='50vw'
              title={"Contenido"}
              footer={null}
              visible={visibleContent}
              onCancel={() => setVisibleContent(false)}
            >
              <Form
                form={form}
                layout='horizontal'
                validateTrigger='onBlur'
                name='notification'
                onFinish={(values) => sendNotification(values)}
                className='w-full mb-8'
              >
                <InputForm
                  item={{
                    label: null,
                    name: "subject",
                    rules: [{ required: true, message: "Por favor ingrese texto!" }],
                  }}
                  input={{
                    disabled: false,
                    size: "large",
                    className: "rounded",
                    placeholder: "Asunto",
                  }}
                />
                {!mobile && (
                  <>
                    <InputForm
                      item={{
                        label: null,
                        name: "heading",
                        rules: [{ required: true, message: "Por favor ingrese texto!" }],
                      }}
                      input={{
                        disabled: false,
                        size: "large",
                        className: "rounded",
                        placeholder: "Titulo",
                      }}
                    />
                    <InputForm
                      item={{
                        label: null,
                        name: "subHeading",
                      }}
                      input={{
                        disabled: false,
                        size: "large",
                        className: "rounded",
                        placeholder: "Sub Titulo",
                      }}
                    />
                  </>
                )}
                <InputForm
                  typeInput='textArea'
                  item={{
                    label: null,
                    name: "body",
                    rules: [{ required: true, message: "Por favor ingrese texto!" }],
                  }}
                  input={{
                    disabled: false,
                    size: "large",
                    className: "rounded",
                    placeholder: "Mensaje",
                  }}
                />{" "}
                <div className='flex justify-end'>
                  <Button
                    disabled={loading}
                    loading={loading}
                    htmlType='submit'
                    type='primary'
                    shape='round'
                    size='large'
                    className='my-5 mx-1 shadow-lg md:my-0 mt-5'
                  >
                    Enviar
                  </Button>
                </div>
              </Form>
            </Modal>
          </div>
        </Card>
      </div>
    </div>
  );
}
