import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { Button, Input, Spin, notification, Tag } from "antd";
import { useLazyQuery } from "@apollo/react-hooks";
import { HandleError } from "utils.js/HandleError";

const verifyCoupon = gql`
  query verifyCoupon($coupon: String!) {
    verifyCoupon(coupon: $coupon) {
      id
      coupon
      amount
      type
      duration
      status
    }
  }
`;

export default function InputCoupon({ activeCoupon, cardType }) {
  const [coupon, setCoupon] = useState();
  const [data, setData] = useState();
  const [notified, setNotified] = useState(false);
  const [getVerifyCoupon, { data: queryData, error, loading }] = useLazyQuery(verifyCoupon, {
    fetchPolicy: "no-cache",
  });
  const couponURL = localStorage.getItem("coupon") || null;

  useEffect(() => {
    if (couponURL?.includes("mastercard") && cardType?.includes("mastercard"))
      getVerifyCoupon({ variables: { coupon: couponURL } });
  }, [getVerifyCoupon, cardType, couponURL]);

  useEffect(() => {
    if (
      queryData?.verifyCoupon.coupon === "mastercard" &&
      couponURL?.includes("mastercard") &&
      !cardType?.includes("mastercard")
    ) {
      activeCoupon(null);
      setData((_) => undefined);
    } else {
      activeCoupon(queryData?.verifyCoupon);
      setData((_) => queryData);
    }
  }, [queryData, couponURL, cardType, activeCoupon, data]);

  useEffect(() => {
    if (data?.verifyCoupon) {
      if (!notified) {
        notification.success({
          message: "Cupon redimido con exito",
        });
        setNotified(true);
      }
    }
    if (error && !notified) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
      setNotified(true);
    }
  }, [data, error, activeCoupon, notified, cardType, couponURL]);

  const handleChange = (e) => {
    e.preventDefault();
    setNotified(false);
    setCoupon(e.target.value);
  };

  const handleClick = () => {
    getVerifyCoupon({ variables: { coupon } });
  };

  return (
    <>
      {loading ? (
        <Spin />
      ) : data ? (
        <Tag className='font-semibold text-base p-2'>
          {coupon} redimido{" "}
          {data.verifyCoupon.duration && `- valido por ${data.verifyCoupon.duration} mes`}
          {data.verifyCoupon.duration > 1 && "es"}
        </Tag>
      ) : (
        <>
          <Input placeholder='Cupon' onChange={handleChange} />
          <Button onClick={handleClick}>Redimir Cupon</Button>
        </>
      )}
    </>
  );
}
