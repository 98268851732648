import React, { useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import Button from "antd/lib/button";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import notify from "antd/lib/notification";
import get from "lodash/get";

function UpdateInfoButton({ mutation, variables, person, isMain, successfullMutation }) {
  const [muteInfo, stateMute] = useMutation(mutation, {
    variables: { id: person },
    fetchPolicy: "no-cache",
    refetchQueries: isMain ? ["ME"] : ["getMeBeneficiaries"],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    const { error, loading } = stateMute;
    if (!loading && error) {
      if (error.graphQLErrors) {
        const description = get(error.graphQLErrors, [0, "message"], error.graphQLErrors);
        notify.error({
          message: "Error al actualizar info personal !!",
          description,
        });
      }
    }
  }, [stateMute]);

  const clickHandler = (variables) => {
    variables && muteInfo({ variables }).then(() => successfullMutation(true));
  };

  return (
    <Button
      type='primary'
      loading={stateMute.loading}
      onClick={() => clickHandler(variables)}
      icon={<FontAwesomeIcon size='sm' icon={faSave} />}
      // EAS-1651
      title='Guardar cambios'
    />
  );
}

export default UpdateInfoButton;
