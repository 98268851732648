import React from "react";
import { notification } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import FormCrearCuentaDoctor from "../../../components/FormCrearCuentaDoctor";
import { HandleError } from "utils.js/HandleError";
import { upperCase } from "lodash";

const signupDoctor = gql`
  mutation signupDoctor(
    $email: String!
    $password: String!
    $confirmPassword: String!
    $firstName: String!
    $lastName: String!
    $sex: SexEnum!
    $specialties: [String!]!
    $minsaNumber: String!
    $nationality: NationalityEnum
  ) {
    signupDoctor(
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      firstName: $firstName
      lastName: $lastName
      sex: $sex
      specialties: $specialties
      minsaNumber: $minsaNumber
      nationality: $nationality
    ) {
      id
      fullName
      email
      minsaNumber
      specialties {
        id
        name
      }
    }
  }
`;

const CrearCuentaDoctor = (props) => {
  const { isNicaragua, isPanama, dispatch } = props;
  const [fetchSignupDoctor, { loading, error, data }] = useMutation(signupDoctor, {
    fetchPolicy: "no-cache",
  });

  const onFinish = async (values) => {
    const { numeroMINSA: minsaNumber, selectSexo: sex, ...restValues } = values;
    restValues.nationality = upperCase(values.nationality);
    if (restValues.phoneNumber) restValues.phoneNumber = String(restValues.phoneNumber);
    try {
      await fetchSignupDoctor({
        variables: {
          minsaNumber: String(minsaNumber),
          sex,
          ...restValues,
        },
      });
    } catch (error) {
      notification.error({
        message: "Internal server error ",
        description: HandleError(error),
      });
    }
  };

  const formProps = {
    loading,
    data,
    error,
    onFinish,
    title: "Bienvenido a EasyMD Doctor",
    description: `Desde hoy, tiene disponible la promoción de su consulta en Internet,
            la gestión de las visitas y la comunicación con sus pacientes en una sola herramienta
            diseñada para profesionales de la salud.`,
    isNicaragua,
    isPanama,
    dispatch,
  };

  return <FormCrearCuentaDoctor {...formProps} />;
};

export default CrearCuentaDoctor;
