import React, { useState, useEffect, useCallback } from "react";
import { Modal, Spin } from "antd";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModalPreviewFile = (props) => {
  const [isPdfPreview, setIsPdfPreview] = useState(false);
  const [firtsMount, setFirtsMount] = useState(true);

  const validateIsPdfMimeType = useCallback((mimeType) => {
    let isPdf = false;
    switch (mimeType) {
      case "application/pdf":
        isPdf = true;
        break;
      case "pdf":
        isPdf = true;
        break;
      default:
        isPdf = false;
    }
    setIsPdfPreview(isPdf);
    setFirtsMount(false);
  }, []);

  useEffect(() => {
    if (props.visible) {
      validateIsPdfMimeType(props.mimeType);
    }
  }, [validateIsPdfMimeType, props]);

  return (
    <Modal visible={props.visible} title='' footer={null} onCancel={props.onClose} destroyOnClose>
      {firtsMount ? (
        <Spin>
          <div className='h-32 w-full my-5' />
        </Spin>
      ) : (
        <div className='p-2 my-3 flex flex-col'>
          <p className='text-2xl font-semibold text-primary break-words mb-2'>{props.fileName}</p>
          {isPdfPreview ? (
            <a target='blank' href={props.url} className='mx-auto my-5' download>
              <FontAwesomeIcon
                icon={faDownload}
                className='mr-2 self-center hover:text-primary-hoverDark'
              />{" "}
              Descargar PDF
            </a>
          ) : (
            <>
              <img
                alt='preview_image'
                className='mx-auto'
                style={{ width: "80%" }}
                src={props.url}
              />
              <a
                target='blank'
                href={props.url}
                className='mx-auto mt-3 mb-3 hover:text-primary-hoverDark'
                download
              >
                <FontAwesomeIcon icon={faDownload} className='mr-2 self-center' /> Descargar
              </a>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

ModalPreviewFile.defaultProps = {
  visible: false,
  fileName: null,
  mimeType: null,
  url: null,
  onClose: () => {},
};

export default ModalPreviewFile;
