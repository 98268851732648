import React from "react";
import { Link } from "react-router-dom";
// images
import imageQr from "assets/img/QrTest.png";
import imageGooglePlay from "assets/img/googlePlay.png";
import imageAppStore from "assets/img/appStore.png";

export default function DownloadApps() {
  return (
    <div className='grid gap-4 grid-cols-3 2xl:gap-6'>
      <img src={imageQr} alt='QrTest' />
      <Link
        to={{
          pathname: "https://play.google.com/store/apps/details?id=com.easymdv2.android",
        }}
        target='_blank'
      >
        <img src={imageGooglePlay} alt='googleplay' />
      </Link>
      <Link
        to={{
          pathname: "https://apps.apple.com/es/app/easymd/id1579924481",
        }}
        target='_blank'
      >
        <img src={imageAppStore} alt='appStore' />
      </Link>
    </div>
  );
}
