import React, { useEffect } from "react";

import { faGift, faSortNumericDownAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input, InputNumber, Button, notification, Select } from "antd";

// utils
import { HandleError } from "utils.js/HandleError";

const FormCoupun = (props) => {
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    if (props.error) {
      notification.error({
        message: "Error",
        description: HandleError(props.error),
      });
    }
    if (props.data) {
      notification.success({
        message: props.infoCoupon ? "Cupon Editado" : "Cupon Creado",
      });
      props.successCreate();
    }
  }, [props]);

  useEffect(() => {
    if (props.infoCoupon) {
      form.setFields([
        {
          name: "coupon",
          value: props.infoCoupon.coupon,
          errors: null,
        },
        {
          name: "amount",
          value: props.infoCoupon.amount,
          errors: null,
        },
        {
          name: "duration",
          value: props.infoCoupon.duration,
          errors: null,
        },
        {
          name: "type",
          value: props.infoCoupon.type,
          errors: null,
        },
        {
          name: "description",
          value: props.infoCoupon.description,
          errors: null,
        },
      ]);
    }
  }, [form, props.infoCoupon]);

  return (
    <div className='w-full lg:w-full '>
      <h3 className='mt-3 mb-8 text-2xl'>
        <strong>{props.title}</strong>
      </h3>
      <Form
        form={form}
        layout='horizontal'
        validateTrigger='onBlur'
        name='basic'
        initialValues={{ remember: true }}
        onFinish={(valueForm) => props.onFinish(valueForm)}
      >
        <div className='grid grid-cols-12 gap-2'>
          <div className='col-span-12 md:col-span-6'>
            <Form.Item
              label={null}
              name='coupon'
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese el cupon!",
                },
              ]}
            >
              <Input
                disabled={props.loading || props.data}
                size='large'
                prefix={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faGift} />}
                className='rounded'
                placeholder='Cupon'
              />
            </Form.Item>
          </div>

          <div className='col-span-12 md:col-span-6'>
            <Form.Item label='Repeticiones' name='duration' required={false}>
              <InputNumber
                disabled={props.loading || props.data}
                size='large'
                className='rounded'
                maxLength={2}
                max={12}
                min={1}
                placeholder='Mes'
              />
            </Form.Item>
          </div>

          <div className='col-span-12 md:col-span-6'>
            <Form.Item
              label={null}
              name='amount'
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese la cantidad!",
                  pattern: new RegExp(/\d+/g),
                },
              ]}
            >
              <Input
                disabled={props.loading || props.data}
                size='large'
                prefix={
                  <FontAwesomeIcon
                    className='text-center mr-2'
                    size='lg'
                    icon={faSortNumericDownAlt}
                  />
                }
                className='rounded'
                placeholder='Cantidad'
              />
            </Form.Item>
          </div>

          <div className='col-span-12 md:col-span-6'>
            <Form.Item label={null} name='type' required={false}>
              <Select
                disabled={props.loading || props.data}
                loading={props.loading}
                mode='single'
                allowClear
                showArrow
                bordered
                size='large'
                className='rounded'
                style={{ width: "100%" }}
                placeholder='Monto'
              >
                <Option value='monto'>Monto</Option>
                <Option value='porcentaje'>Porcentaje</Option>
              </Select>
            </Form.Item>
          </div>

          <div className='col-span-12 h-full'>
            <Form.Item className='mb-1' label={null} name='description'>
              <Input.TextArea
                className='rounded w-full'
                placeholder='Descripción del cupon'
                rows={2}
              />
            </Form.Item>
          </div>

          <div className='flex xs:flex-col md:flex-row justify-between col-span-12'>
            <Form.Item className=''>
              <Button
                disabled={props.loading || props.data}
                loading={props.loading}
                htmlType='submit'
                type={`${props.infoCoupon ? "default" : "primary"} `}
                shape='round'
                size='large'
                className={`xs:mx-auto md:mx-4 ${props.infoCoupon && "button-success"}`}
              >
                {props.infoCoupon ? "Editar cupon" : "Crear cupon"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

FormCoupun.defaultProps = {
  infoCoupon: null,
};

export default FormCoupun;
