import React from "react";
import Empty from "antd/lib/empty";

import isEmpty from "lodash/isEmpty";

import MyAppointments from "./myAppointments";

const OnGoingAppointmentsMD = (props) => {
  if (props.error) {
    return (
      <Empty
        className='mt-32'
        description={<p className='text-danger'>Error al obtener las citas</p>}
      />
    );
  }
  if (isEmpty(props.data)) {
    return <Empty className='mt-32' description='No hay citas' />;
  }
  return <MyAppointments {...props} />;
};

OnGoingAppointmentsMD.defaultProps = {
  total: 0,
  data: [],
  error: null,
  loading: false,
};

export default OnGoingAppointmentsMD;
