import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, Tooltip, Tag, notification } from "antd";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FormUpdateAppointment = (props) => {
  const appointmentByIdGQL = () => {
    const schema = `query appointmentmdById($id: String!) {
            appointmentmdById(id: $id) {
                id
                prescription
                diagnosis
                status
                patient {
                    id
                }
            }
        }
        `;
    return gql`
      ${schema}
    `;
  };

  const updateAppointmentGql = () => {
    return gql`
      mutation updateAppointmentMD(
        $id: String!
        $prescription: [String!]
        $diagnosis: String
        $status: updateAppointmentStatus!
        $patient: String!
      ) {
        updateAppointmentMD(
          id: $id
          prescription: $prescription
          diagnosis: $diagnosis
          status: $status
          patient: $patient
        ) {
          id
        }
      }
    `;
  };

  const {
    loading: loadingAppointment,
    error: errorAppointment,
    data: dataAppointment,
  } = useQuery(appointmentByIdGQL(), {
    variables: { id: props.idAppointment },
    fetchPolicy: "no-cache",
  });

  const [
    fetchCreateAppointment,
    { loading: loadingMutation, error: errorMutation, data: dataMutation },
  ] = useMutation(updateAppointmentGql(), { fetchPolicy: "no-cache" });

  // const [listamedicamentos, setListamedicamentos] = useState([])
  const [listaPreescripcion, setListaPreescripcion] = useState([]);
  const [diagnosticoNotas, setDiagnosticoNotas] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    if (dataAppointment && !loadingAppointment) {
      // setListamedicamentos(dataAppointment.appointmentmdById.prescription)
      setDiagnosticoNotas(dataAppointment.appointmentmdById.diagnosis);
      setListaPreescripcion(dataAppointment.appointmentmdById.prescription);
    }
  }, [loadingAppointment, errorAppointment, dataAppointment]);

  useEffect(() => {
    if (!loadingMutation && dataMutation) {
      notification.success({
        message: "Diagnóstico guardado",
        description: "El Diagnóstico se guardo con éxito",
      });
    }
  }, [loadingMutation, errorMutation, dataMutation]);

  const finishForm = async () => {
    try {
      const variables = {
        id: props.idAppointment,
        prescription: listaPreescripcion,
        diagnosis: diagnosticoNotas,
        status: dataAppointment.appointmentmdById.status,
        patient: dataAppointment.appointmentmdById.patient.id,
      };
      await fetchCreateAppointment({
        variables: variables,
      });
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error al actualizar la cita",
      });
    }
  };

  const onChangeTextArea = (value) => {
    setDiagnosticoNotas(value.target.value);
  };

  const addNewTagPree = (value) => {
    setListaPreescripcion([...listaPreescripcion, value]);
  };

  const removeTagPree = (index) => {
    const newArray = [];
    listaPreescripcion.forEach((value, i) => {
      if (i !== index) {
        newArray.push(value);
      }
    });
    setListaPreescripcion(newArray);
  };

  return (
    <Row>
      <Col xs={24}>
        <Form
          layout='vertical'
          className='flex flex-col'
          form={form}
          initialValues={{ diagnosticoNotas: "" }}
        >
          <Form.Item
            className='mb-4'
            label={<label className='font-bold text-lg'>Diagnóstico/Notas</label>}
          >
            <Input.TextArea
              disabled={loadingMutation || loadingMutation}
              onChange={onChangeTextArea}
              value={diagnosticoNotas}
              placeholder='Diagnóstico y notas sobre la cita'
              rows={12}
            />
          </Form.Item>
          <Form.Item
            label={
              <label className='font-bold text-lg' name='prescripcionMedica'>
                Prescripción medica
              </label>
            }
          >
            <PreescriptionTag newTag={addNewTagPree} />
            <Row
              gutter={[0, 4]}
              className='mt-2 py-2'
              style={{ maxHeight: "250px", overflow: "auto" }}
            >
              {listaPreescripcion.map((value, index) => {
                return (
                  <Col xs={24} key={index}>
                    <Tag
                      key={value + "_" + index}
                      color='default'
                      className='p-1 px-2 mb-2 rounded-lg border border-success-lighter'
                      closable
                      onClose={() => removeTagPree(index)}
                    >
                      {value.length > 25 ? (
                        <Tooltip title={value}>{value.substring(0, 22) + " ..."}</Tooltip>
                      ) : (
                        value
                      )}
                    </Tag>
                  </Col>
                );
              })}
            </Row>
          </Form.Item>
          <Form.Item label={null} className='self-center justify-end'>
            <Button
              type='primary'
              shape='round'
              size='large'
              className='mx-auto'
              onClick={finishForm}
              disabled={loadingMutation || loadingMutation}
            >
              Enviar diagnóstico y receta{" "}
              <FontAwesomeIcon icon={faEdit} className='ml-2 self-center' />
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const PreescriptionTag = (props) => {
  const { Search } = Input;
  const [valueInput, setValue] = useState("");

  const agregarTag = (e, event) => {
    if (e.length > 0) {
      setValue("");
      props.newTag(e);
    } else {
      setValue("");
    }
  };

  return (
    <Search
      placeholder='Detalles'
      enterButton='+ Agregar'
      size='large'
      allowClear
      onChange={(e) => setValue(e.target.value)}
      value={valueInput}
      onSearch={(value, event) => agregarTag(value, event)}
    />
  );
};

export default FormUpdateAppointment;
