import React, { useState, useEffect, useCallback } from "react";
import { Table, Input, Button, Modal, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { HandleError } from "utils.js/HandleError";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAdd from "./ModalAdd";
import ModalEdit from "./ModalEdit";

const BasicTable = (props) => {
  const [visbleModal, setvisbleModal] = useState(false);
  const [visbleModalEdit, setvisbleModalEdit] = useState(false);
  const [focusEditItem, setFocusEditItem] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const { Search } = Input;
  const { confirm } = Modal;

  const columns = [
    {
      title: props.plaseholderSearch,
      dataIndex: "name",
    },
    {
      title: "",
      className: "column-money w-5por",
      dataIndex: "action",
    },
  ];

  const deleteMedicine = useCallback(
    (id, title) => {
      confirm({
        title: props.plaseholdeAlertDelete,
        icon: <ExclamationCircleOutlined />,
        content: title,
        okText: "Si",
        okType: "danger",
        cancelText: "No",
        onOk: async () => {
          await props.deleteItem(id);
          notification.success({
            message: "El Item se ha eliminado con éxito!",
          });
        },
      });
    },
    [props, confirm]
  );

  useEffect(() => {
    if (props.error) {
      notification.error({
        message: "Error",
        description: HandleError(props.error),
      });
    }
    if (props.errorDelete) {
      notification.error({
        message: "Error",
        description: HandleError(props.errorDelete),
      });
    }
    if (props.reqUpdateItem) {
      notification.error({
        message: "Error",
        description: HandleError(props.reqUpdateItem),
      });
      setvisbleModalEdit(false);
    }
    if (props.erroraddItem) {
      notification.error({
        message: "Error",
        description: HandleError(props.erroraddItem),
      });
      setvisbleModal(false);
    }
  }, [props.error, props.errorDelete, props.reqUpdateItem, props.erroraddItem]);

  useEffect(() => {
    if (!props.error) {
      if (props.data) {
        const newDataTable = props.data[`${props.path}`].docs.map((value, index) => ({
          key: index + 1,
          id: value.id,
          name: value.name,
          action: (
            <div className='flex justify-center'>
              <Button
                onClick={() => deleteMedicine(value.id, value.name)}
                className='mx-2 px-5 block my-2 md:my-2 md:inline-block'
                type='primary'
                shape='round'
                danger
                size='small'
              >
                Eliminar
              </Button>
              <Button
                onClick={() => {
                  setFocusEditItem(value.id);
                  setvisbleModalEdit(true);
                }}
                type='default'
                shape='round'
                className='button-success mx-2 px-5 block my-2 md:my-2 md:inline-block'
                size='small'
              >
                Editar
              </Button>
            </div>
          ),
        }));
        setDataTable(newDataTable);
      } else {
        setDataTable([]);
      }
    } else {
      setDataTable([]);
    }
  }, [props.data, props.error, deleteMedicine, props.path]);

  const CustomHeader = (
    <div className='grid grid-cols-12 gap-2 items-center'>
      <div className='col-span-12 md:col-span-8'>
        <Search
          placeholder='Buscar por Nombre'
          onSearch={(value) => props.onSearchTable(value)}
          onChange={(value) => props.onSearchTable(value.target.value)}
          className='w-full'
        />
      </div>
      <div className='col-span-12 md:col-span-4 flex justify-end'>
        <Button
          onClick={() => setvisbleModal(true)}
          type='link'
          shape='round'
          className='text-success hover:text-success-lighter'
          size='large'
          icon={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faPlus} />}
        >
          Nuevo ítem
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <ModalAdd
        onAddItem={props.onAddItem}
        visbleModal={visbleModal}
        setvisbleModal={setvisbleModal}
      />
      <ModalEdit
        pathId={props.pathId}
        gqlFindItem={props.gqlFindItem}
        EditItemSubmit={props.EditItemSubmit}
        idEditItem={focusEditItem}
        visbleModal={visbleModalEdit}
        setvisbleModal={setvisbleModalEdit}
      />

      <Table
        loading={props.loading}
        columns={columns}
        dataSource={dataTable}
        onChange={(valueChange) => {
          const { current } = valueChange;
          props.changePage(current);
        }}
        pagination={{
          total: props.data ? props.data[`${props.path}`].total : 0,
          current: props.data ? props.data[`${props.path}`].page : 1,
          pageSize: 5,
        }}
        bordered
        title={() => CustomHeader}
      />
    </>
  );
};

BasicTable.defaultProps = {
  error: false,
  erroraddItem: null,
  plaseholderSearch: "Lorem ipsum",
  plaseholdeAlertDelete: "Lorem ipsum",
  gqlFindItem: null,
  reqUpdateMedicine: false,
  onAddItem: (arratItems) => {
    console.log("NewItems", arratItems);
  },
  changePage: (current) => {
    console.log("current", current);
  },
  deleteItem: (id) => {
    console.log("DeleteItem", id);
  },
  onSearchTable: (value) => {
    console.log(value);
  },
  EditItemSubmit: (id) => {
    console.log("Edit Item", id);
  },
  loading: false,
  // data: []
};

export default BasicTable;
