import React from "react";
import Brand from "./brand";

const BrandLoading = (props) => {
  return (
    <>
      <div className={props.cssClass || "w-screen h-screen flex justify-center items-center"}>
        <Brand />
      </div>
    </>
  );
};

BrandLoading.defaultProps = {
  cssClass: "w-screen h-screen flex justify-center items-center",
};

export default BrandLoading;
