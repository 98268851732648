import gql from "graphql-tag";

export const createPatient = gql`
  mutation createPatient(
    $email: String
    $phoneNumber: String
    $password: String!
    $confirmPassword: String!
    $firstName: String!
    $lastName: String!
    $benefactor: String
    $nationality: NationalityEnum
  ) {
    signupPatient(
      email: $email
      phoneNumber: $phoneNumber
      password: $password
      confirmPassword: $confirmPassword
      firstName: $firstName
      lastName: $lastName
      benefactor: $benefactor
      nationality: $nationality
    ) {
      id
      fullName
    }
  }
`;
