import React from "react";
// import Badge from 'antd/lib/badge'

import Tap1Medic from "./taps1";
import Tap2Medic from "./taps2";
import Tap3Medic from "./taps3";
// import TabSalaEspera from './tabSalaEspera'
import TabSalaEspera from "./tabCitasMD";

const listTabs = [
  {
    tab: "Inicio",
    key: "1",
    children: <Tap1Medic />,
    role: ["doctor", "doctorMD"],
  },
  {
    tab: "Citas",
    key: "2",
    children: <Tap2Medic />,
    role: ["doctor", "doctorMD"],
  },
  {
    tab: "Inf. Adicional",
    key: "3",
    children: <Tap3Medic />,
    role: ["doctor"],
  },
  {
    tab: "Citas Inmediatas",
    key: "4",
    children: <TabSalaEspera />,
    role: ["doctorMD"],
  },
];

export default listTabs;
