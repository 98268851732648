// components
import Plan from "./Plan";
import Feature from "./Feature";
import CheckFeature from "./Feature/Check";
// helpers
import map from "lodash/map";

export default (plans) => [
  {
    key: "name",
    title: "Caracteristicas",
    width: "37%",
    render: Feature,
    editable: true,
    dataIndex: "name",
  },
  ...map(plans, (plan) => {
    const { id, features, ...info } = plan;
    return {
      key: id,
      title: Plan({ id, ...info }),
      render: (feature) => CheckFeature(feature, id, map(features, "id")),
      dataIndex: "id",
      className: "text-center",
    };
  }),
];
