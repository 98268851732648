import React from "react";
import QueueAnim from "rc-queue-anim";
// components
import MonthGain from "./MonthGain";
import Statistic from "./StatisticCard";
import StatisticMultiple from "./StatisticMultipleCards";
import PendingApproval from "./MedicPendingApproval";
import CurrenEarnings from "./CurrentEarnings";
// statics props
import props from "./staticsProps";

export default function Home() {
  return (
    <QueueAnim {...props.Queue}>
      <Statistic {...props.Medic} />
      {/* <Statistic {...props.Gain} /> */}
      <Statistic {...props.Users} />
      <Statistic {...props.Appointments} />
      <Statistic {...props.ActivePlans} />
      <StatisticMultiple {...props.UserPlans} />
      <CurrenEarnings key='table1' />
      <MonthGain key='table2' />
      <PendingApproval key='table3' />
    </QueueAnim>
  );
}
