import React, { useState, useEffect } from "react";
import { Spin, Radio, Empty } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { getChartEarningsGql } from "./gqlTags";
import ChartConfig from "./chartConfig";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

const _typeDate = ["MONTH", "WEEK"];

const ChartEarning = () => {
  const [selectType, setSelectType] = useState(_typeDate[0]);
  const [formatData, setFormatData] = useState([]);

  const { data, loading, error } = useQuery(getChartEarningsGql, {
    variables: {
      type: selectType,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (error || loading) {
      setFormatData([]);
    } else if (data && !loading) {
      const newData = [];
      data.getChartEarnings.forEach((value) => {
        newData.push({
          ...value,
          payments: value.payments ? parseFloat(value.payments) : 0,
          paymentAmount: value.paymentAmount ? parseFloat(value.paymentAmount) : 0,
          authorization: value.authorization ? parseFloat(value.authorization) : 0,
          authorizationAmount: value.authorizationAmount
            ? parseFloat(value.authorizationAmount)
            : 0,
          appointment: value.appointment ? parseFloat(value.appointment) : 0,
          appointmentAmount: value.appointmentAmount ? parseFloat(value.appointmentAmount) : 0,
          plan: value.plan ? parseFloat(value.plan) : 0,
          planAmount: value.planAmount ? parseFloat(value.planAmount) : 0,
          withdrawals: value.withdrawals ? parseFloat(value.withdrawals) : 0,
          withdrawalAmount: value.withdrawalAmount ? parseFloat(value.withdrawalAmount) : 0,
          totalRevenue: value.totalRevenue ? parseFloat(value.totalRevenue) : 0,
          reversals: value.reversals ? parseFloat(value.reversals) : 0,
          reversalAmount: value.reversalAmount ? parseFloat(value.reversalAmount) : 0,
        });
      });
      setFormatData(newData);
    }
  }, [loading, error, data]);

  const onChangeRadio = (e) => {
    setSelectType(e.target.value);
  };

  return (
    <div className='w-full flex flex-col justify-center items-center h-full'>
      <BlockUi
        className='w-full h-full'
        tag='div'
        blocking={loading}
        loader={<Spin spinning={loading} />}
      >
        <div className='w-full mb-3'>
          <Radio.Group onChange={onChangeRadio} defaultValue={_typeDate[0]}>
            <Radio.Button value={_typeDate[0]}>Mes</Radio.Button>
            <Radio.Button value={_typeDate[1]}>Semana</Radio.Button>
          </Radio.Group>
        </div>
        {!loading && !error && data && formatData.length > 0 && (
          <ChartConfig data={formatData} selectType={selectType} />
        )}
        {!loading && data && formatData.length === 0 && <Empty />}
      </BlockUi>
    </div>
  );
};

export default ChartEarning;
