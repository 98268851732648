import React from "react";
import { List, Avatar } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const ContentCodesCreate = ({ data }) => {
  return (
    <List
      itemLayout='horizontal'
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar
                size={32}
                className='bg-transparent'
                icon={
                  <FontAwesomeIcon
                    className={item.createCode ? "text-success" : "text-danger"}
                    icon={item.createCode ? faCheck : faTimes}
                  />
                }
              />
            }
            title={item.email}
            description={
              <p className={item.createCode ? "text-success" : "text-danger"}>{item.message}</p>
            }
          />
        </List.Item>
      )}
    />
  );
};

export default ContentCodesCreate;
