import React, { useState, useEffect } from "react";
import Spin from "antd/lib/spin";
import uniqueId from "lodash/uniqueId";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RenderCheck = ({ idFeature = "", arrayFind = [], colorCol = false, size = "2x" }) => {
  const [isCalcCheck, setIsCalcCheck] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // if pass the prop idFeature, then validate the check
    if (idFeature) {
      let isFind = false;
      arrayFind.forEach((value) => {
        if (value.id === idFeature) {
          isFind = true;
        }
      });
      setIsCalcCheck(isFind);
    } else {
      setIsCalcCheck(true);
    }
    setLoading(false);
  }, [arrayFind, idFeature]);

  if (loading) {
    return (
      <div
        key={uniqueId("check_")}
        className={`py-5 flex justify-center items-center ${colorCol ? "bg-gray-100" : "bg-white"}`}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div
      key={uniqueId("check_")}
      className={`py-5 flex justify-center items-center ${colorCol ? "bg-gray-100" : "bg-white"}`}
    >
      {isCalcCheck && (
        <FontAwesomeIcon
          className='my-1 block text-center text-primary bg-primary-lighter p-2 rounded-full'
          size={size}
          icon={faCheck}
        />
      )}
    </div>
  );
};

export default RenderCheck;
