/* eslint-disable array-callback-return */
import React, { useState, useContext, useEffect } from "react";
import { HandleError } from "utils.js/HandleError";
import FormInfoConsulta from "../../Componets/FormInfConsulta";
import isEqual from "lodash/isEqual";
// ant
import { Form, Input, Select, Tag, notification, Spin } from "antd";
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faStethoscope,
  faPhoneAlt,
  faUserTag,
  faGlobeAmericas,
} from "@fortawesome/free-solid-svg-icons";
// gql
import { useQuery, useMutation } from "@apollo/react-hooks";
// tags
import { specialties, languages, updateMedicInfo, updateMedicMDInfo } from "../../gqlTags";

// State Context
import { profileMedicContext } from "../../../index";

import "./stylesMedic.scss";

import SelectWordCloud from "components/InputWordCloud/Select";

const FormMiInformacion = (props) => {
  const { role } = props;
  const { state } = useContext(profileMedicContext);
  const [form] = Form.useForm();
  const { Option } = Select;
  const [changeSelect, setChangeSelect] = useState(false);
  const [initalValuesInfoConsulta, setInitalValuesInfoConsulta] = useState(null);

  const [variableLanguaje, setVariableLanguaje] = useState({
    perPage: 25,
    page: 1,
    filters: [],
  });

  const reqlanguages = useQuery(languages, {
    variables: { ...variableLanguaje },
    fetchPolicy: "no-cache",
  });

  const [updateInfoDoctor, reQupdateInfoDoctor] = useMutation(
    role === "doctorMD" ? updateMedicMDInfo : updateMedicInfo
  );

  useEffect(() => {
    const newSelectEspecialidad = [];
    const newSelectLenguages = [];

    state.dataMedic.languages.map((value) => {
      newSelectLenguages.push({
        key: value.id,
        value: value.id,
        label: value.name,
      });
    });

    setInitalValuesInfoConsulta({
      appointmentPrice: {
        value: state.dataMedic.appointmentPrice,
        errors: null,
      },
    });

    if (state.dataMedic && !state.loading) {
      state.dataMedic.specialties.map((value) => {
        if (value) {
          newSelectEspecialidad.push({
            key: value.id,
            value: value.id,
            label: value.name,
          });
        }
      });

      form.setFields([
        {
          name: "firstName",
          value: state.dataMedic.firstName,
          errors: null,
        },
        {
          name: "lastName",
          value: state.dataMedic.lastName,
          errors: null,
        },
        {
          name: "phoneNumber",
          value: state.dataMedic.phoneNumber,
          errors: null,
        },
        {
          name: "minsaNumber",
          value: state.dataMedic.minsaNumber,
          errors: null,
        },
        {
          name: "description",
          value: state.dataMedic.description ? state.dataMedic.description : "",
          errors: null,
        },
      ]);
    }

    if (!reqlanguages.loading) {
      form.setFields([
        {
          name: "specialties",
          value: newSelectEspecialidad,
          errors: null,
        },
        {
          name: "languages",
          value: newSelectLenguages,
          errors: null,
        },
      ]);

      setChangeSelect(true);
    }
  }, [state.dataMedic, reqlanguages.loading, form, state.loading]);

  useEffect(() => {
    if (reqlanguages.error) {
      notification.error({
        message: "Error",
        description: HandleError(reqlanguages.error),
      });
    }
    if (reQupdateInfoDoctor.error) {
      notification.error({
        message: "Error",
        description: HandleError(reQupdateInfoDoctor.error),
      });
    }
    if (reQupdateInfoDoctor.data) {
      notification.success({
        message: "La información del médico ha sido actualizada ",
      });
    }
  }, [reqlanguages.error, reQupdateInfoDoctor.error, reQupdateInfoDoctor.data]);

  const getLanguages = () => {
    if (reqlanguages.data) {
      return reqlanguages.data.languages.docs.map((value) => (
        <Option key={`${value.id}`} value={value.id}>
          {value.name}
        </Option>
      ));
    } else {
      return [];
    }
  };

  const searchLanguaje = (valueSelect) => {
    if (valueSelect) {
      setVariableLanguaje({
        ...variableLanguaje,
        filters: [
          {
            byText: {
              field: "NAME",
              value: valueSelect,
            },
          },
        ],
      });
    } else {
      setVariableLanguaje({
        ...variableLanguaje,
        filters: [],
      });
    }
  };

  const tagRenderSelect = (propsTags) => {
    const { label, closable, onClose } = propsTags;
    return (
      <Tag className='rounded' color='#29AAE1' closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  const onFinish = async (valueForm) => {
    const newInitialSpecial = [];
    const newInitialLanguages = [];
    const formSpecial = valueForm.specialties;
    const formLenguages = [];

    state.dataMedic.specialties.map((value) => {
      if (value) {
        newInitialSpecial.push(value.id);
      }
    });
    state.dataMedic.languages.map((value) => {
      newInitialLanguages.push(value.id);
    });

    valueForm.languages.map((value) => {
      formLenguages.push(value.key);
    });

    valueForm.specialties = formSpecial;
    valueForm.languages = formLenguages;

    const initialState = {
      firstName: state.dataMedic.firstName,
      lastName: state.dataMedic.lastName,
      phoneNumber: state.dataMedic.phoneNumber,
      minsaNumber: state.dataMedic.minsaNumber,
      description: state.dataMedic.description,
      specialties: newInitialSpecial,
      languages: newInitialLanguages,
    };

    valueForm.specialties = formSpecial;
    valueForm.languages = formLenguages;
    const isDiff = isEqual(valueForm, initialState);

    try {
      if (!isDiff) {
        await updateInfoDoctor({
          variables: { ...valueForm },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Spin spinning={state.loading || reQupdateInfoDoctor.loading}>
      <h4 className='text-2xl'>
        <strong>Mi información</strong>
      </h4>
      <Form
        form={form}
        layout='horizontal'
        name='basic'
        onFinish={(valueForm) => onFinish(valueForm)}
      >
        <div className='grid grid-cols-12 gap-2'>
          <div className='col-span-12 md:col-span-6 '>
            <Form.Item
              className='mb-1'
              label={null}
              name='firstName'
              rules={[{ required: true, message: "Por favor ingrese su Primer Nombre!" }]}
            >
              <Input
                onBlur={() => form.submit()}
                size='large'
                prefix={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faUser} />}
                className='rounded'
                placeholder='Primer Nombre'
              />
            </Form.Item>
          </div>

          <div className='col-span-12 md:col-span-6 '>
            <Form.Item
              className='mb-1'
              label={null}
              name='lastName'
              rules={[{ required: true, message: "Por favor ingrese su Primer Apellido!" }]}
            >
              <Input
                onBlur={() => form.submit()}
                size='large'
                prefix={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faUser} />}
                className='rounded'
                placeholder='Primer Apellido'
              />
            </Form.Item>
          </div>
          <div className='col-span-12 md:col-span-6 '>
            <Form.Item
              className='mb-1'
              label={null}
              name='phoneNumber'
              rules={[
                {
                  pattern: /^[0-9\b]+$/,
                  message: "Por favor ingrese solo Numeros Enteros!",
                },
              ]}
            >
              <Input
                onBlur={() => form.submit()}
                size='large'
                min={0}
                prefix={
                  <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faPhoneAlt} />
                }
                className='rounded w-full'
                placeholder='Número de teléfono'
              />
            </Form.Item>
          </div>

          <div className='col-span-12 md:col-span-6 '>
            <Form.Item
              className='mb-1'
              label={null}
              name='minsaNumber'
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su numero MINSA",
                },
              ]}
            >
              <Input
                onBlur={() => form.submit()}
                size='large'
                min={0}
                maxLength={15}
                controls={false}
                prefix={
                  <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faStethoscope} />
                }
                className='rounded w-full'
                placeholder='Numero MINSA'
              />
            </Form.Item>
          </div>

          <div className='col-span-12 md:col-span-6 '>
            <Form.Item noStyle>
              {() => {
                return (
                  <Form.Item
                    key={`${changeSelect}:up`}
                    className='mb-1'
                    label={null}
                    name='specialties'
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese almenos una especialidad!",
                      },
                    ]}
                  >
                    <SelectWordCloud
                      gql={specialties}
                      path='specialties.docs'
                      size='large'
                      showEmpty={false}
                      onChange={() => form.submit()}
                      placeholder={
                        <>
                          <FontAwesomeIcon
                            className='text-center mr-2'
                            size='lg'
                            icon={faUserTag}
                          />
                          <span style={{ opacity: "0.4" }}>Selecciona especialidad</span>
                        </>
                      }
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </div>

          <div className='col-span-12 md:col-span-6 '>
            <Form.Item className='mb-1' label={null} name='languages'>
              <Select
                showSearch
                onChange={() => form.submit()}
                disabled={reqlanguages.loading}
                loading={reqlanguages.loading}
                tagRender={tagRenderSelect}
                labelInValue
                mode='multiple'
                size='large'
                className='selectCrearCuentaDoctores'
                placeholder={
                  <>
                    <FontAwesomeIcon
                      className='text-center mr-2'
                      size='lg'
                      icon={faGlobeAmericas}
                    />
                    <span style={{ opacity: "0.4" }}>Lenguajes</span>
                  </>
                }
                onSearch={(e) => searchLanguaje(e)}
                optionFilterProp='children'
                onBlur={() => {
                  form.submit();
                  setVariableLanguaje({
                    perPage: 25,
                    page: 1,
                    filters: [],
                  });
                }}
              >
                {getLanguages()}
              </Select>
            </Form.Item>
          </div>
          <div className='col-span-12 h-full'>
            <Form.Item className='mb-1' label={null} name='description'>
              <Input.TextArea
                onBlur={() => form.submit()}
                className='rounded w-full'
                placeholder='Acerca de mi...'
                rows={4}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
      {role !== "doctorMD" && (
        <div className='grid grid-cols-12 gap-2 mb-5'>
          <div className='col-span-6 h-full'>
            <FormInfoConsulta initalValues={initalValuesInfoConsulta} />
          </div>
        </div>
      )}
    </Spin>
  );
};

export default React.memo(FormMiInformacion);
