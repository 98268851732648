import React, { createContext, useReducer } from "react";
import { Card } from "antd";
import TablePlanilla from "./tablePlanilla";

import reducer from "./store/reducer";
import initialState from "./store/initialState";

export const ContextTablaPlanilla = createContext();

const Planilla = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ContextTablaPlanilla.Provider value={{ state: state, dispatch: dispatch }}>
      <div className='w-full min-h-25rem p-6'>
        <p className='mt-3 text-2xl font-bold mb-0'>Pago de planilla</p>
        <Card className='mt-5' bodyStyle={{ padding: "0.5rem" }} title={null}>
          <TablePlanilla />
        </Card>
      </div>
    </ContextTablaPlanilla.Provider>
  );
};

export default Planilla;
