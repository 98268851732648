import * as actionName from "./actions";
import initialState from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionName.SET_INITIAL_STATE:
      return {
        ...initialState,
      };
    case actionName.SET_APPOINTMENT_DATA:
      return {
        ...state,
        ...action.payload,
      };

    // Paso 1 Detalle Paciente
    case actionName.SET_PATIENT:
      return {
        ...state,
        ...action.payload,
      };

    // Paso 1 Detalle Paciente
    case actionName.SET_RAZON_CITA:
      return {
        ...state,
        ...action.payload,
      };

    // Paso 1 Detalle Paciente
    case actionName.SET_SINTOMAS:
      return {
        ...state,
        ...action.payload,
      };

    // Paso 1 Detalle Paciente
    case actionName.SET_EXAMS_FILES:
      return {
        ...state,
        ...action.payload,
      };

    // Paso 2 Detalle Paciente
    case actionName.SET_MEDICAL_CONDITION:
      return {
        ...state,
        ...action.payload,
      };

    // Paso 2 Detalle Paciente
    case actionName.SET_ALERGY:
      return {
        ...state,
        ...action.payload,
      };

    // Paso 2 Detalle Paciente
    case actionName.SET_DRUG:
      return {
        ...state,
        ...action.payload,
      };

    // Paso 3 Metodo de Pago
    case actionName.SET_MY_METHOD:
      return {
        ...state,
        metodoDePago: {
          ...state.metodoDePago,
          propio: true, // Que la tarjeta es propia y guardada en el perfil
          infoPropio: action.payload.info, // info encriptada o id de token card
        },
      };

    // Paso 3 Metodo de Pago
    case actionName.SET_OTHER_METHOD:
      return {
        ...state,
        metodoDePago: {
          ...state.metodoDePago,
          propio: false, // Que la tarjeta no pertenece a los metodos de pago del usario
          infoOther: action.payload.info, // info encriptada
          cardNumber: action.payload.cardNumber,
          isFullOther: action.payload.isFullOther,
        },
      };

    // Paso 3 Metodo de Pago
    case actionName.SET_TAB_METHOD:
      return {
        ...state,
        metodoDePago: {
          ...state.metodoDePago,
          tabActiva: action.payload,
        },
      };

    case actionName.SET_SAVE_OTHER_METHOD:
      return {
        ...state,
        savePaymentMethod: action.payload,
      };

    // Paso final Detalle Paciente
    case actionName.SET_LOADING_CREATE_APPOINTMENT:
      return {
        ...state,
        ...action.payload,
      };

    // Paso final Detalle Paciente
    case actionName.SET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return { ...state };
  }
};

export default reducer;
