import gql from "graphql-tag";

const gqlAffiliation = {
  remove: gql`
    mutation removeMyCode {
      removeMyCode
    }
  `,
  meUserPlans: gql`
    query meUserPlans {
      me {
        id
        fullName
        plans {
          expireAt
          status
          plan {
            id
            name
            description
            features {
              id
              name
              description
            }
          }
        }
      }
    }
  `,
  cancelPlan: gql`
    mutation cancelPlan($userId: String!, $planId: String!) {
      cancelPlan(userId: $userId, planId: $planId)
    }
  `,
};

export default gqlAffiliation;
