import gql from "graphql-tag";

export const patientsAffiliations = gql`
  query patientsAffiliations(
    $perPage: Float
    $page: Float
    $planId: [String!]
    $text: String
    $from: DateTime!
    $to: DateTime!
  ) {
    patientsAffiliations(
      perPage: $perPage
      page: $page
      planId: $planId
      text: $text
      from: $from
      to: $to
    ) {
      total
      pages
      docs {
        pictureURL
        userPlanId
        fullName
        email
        planName
        planDateAffiliation
        planExpiresAffiliation
        planCancelledAffiliation
        autoRenew
        period
        status
      }
    }
  }
`;
