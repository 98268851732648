import React from "react";
import { Modal, Row, Spin } from "antd";
import googleIntegrationInstrution from "./googleIntegrationInstrution.gif";
function ModalGoogleIntegration({ visbleModal, setModalVisible }) {
  return (
    <Modal
      visible={visbleModal}
      onCancel={() => setModalVisible(false)}
      title='Instrucciones'
      footer={null}
    >
      <h1 style={{ fontSize: "15px" }}>
        <strong>Por favor sigue los siguientes pasos para integrar tu calendario google</strong>
      </h1>
      <h1>1. Ve a configuración y comparte tu calendario</h1>
      <h1>2. Ve a compartir con gente en especifico</h1>
      <h1>3. Presionar en Agregar Persona</h1>
      <h1>
        4. Agrega <strong>easymd-calendar-key@easymd-c98ab.iam.gserviceaccount.com</strong> en la
        lista de personas
      </h1>
      <img
        src={googleIntegrationInstrution}
        alt='googleIntegrationInstrution'
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          margin: "20px 0",
        }}
      />
      <Row justify='center' align='middle'>
        <Spin></Spin>
        <h1 className='ml-2'>Esperando permiso</h1>
      </Row>
    </Modal>
  );
}

export default ModalGoogleIntegration;
