import React, { useReducer, createContext } from "react";
import Card from "antd/lib/card";

import Table from "./Table";

// store
import reducer from "./store/reducerStore";
import initialState from "./store/initialState";
// import * as actionName from './store/actionStore'

// create context Store
export const TransactionsContext = createContext();

const Transactions = () => {
  const [stateLocal, dispatch] = useReducer(reducer, initialState);
  return (
    <TransactionsContext.Provider value={{ state: stateLocal, dispatch: dispatch }}>
      <div className='w-full mx-auto min-h-25rem p-6 '>
        <p className='mt-3 text-2xl font-bold mb-0'>Transacciones</p>
        <Card className='mt-5' bodyStyle={{ padding: "0.5rem" }} title={null}>
          <Table />
        </Card>
      </div>
    </TransactionsContext.Provider>
  );
};

export default Transactions;
