import React from "react";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Typography from "antd/lib/typography";

const FeaturePlan = (_) => {
  const { features } = _;
  return (
    <div className='grid grid-rows-1 grid-cols-2'>
      {features.map((values, index) => (
        <div key={index} className='flex items-start row-span-1 col-span-1 my-2'>
          <Fa
            className='mr-2 block text-center text-primary bg-primary-lighter p-2 rounded-full'
            size='2x'
            icon={faCheck}
          />
          <div>
            <p className='mb-0'>
              <strong>{values.name}</strong>
            </p>
            <Typography ellipsis={{ row: 2 }}>{values.description}</Typography>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeaturePlan;
