import React, { useState } from "react";
import { Card, notification } from "antd";
import { BasicTable } from "components/TableEasyMD";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  medicalconditions,
  deleteMedicalconditionById,
  medicalconditionById,
  updateMedicalCondition,
  createMedicalCondition,
} from "../gqlAdmin";
import { isNumber, isString } from "lodash";

const CondicionesMedicas = () => {
  const [variables, setVariables] = useState({
    perPage: 5,
    page: 1,
    filters: [],
  });

  const { data, loading, error } = useQuery(medicalconditions, {
    variables: { ...variables },
    fetchPolicy: "no-cache",
  });
  const [deletemedicalcondition, reqDeletemedicalcondition] = useMutation(
    deleteMedicalconditionById,
    { refetchQueries: ["medicalconditions"] }
  );
  const [updateCondition, reqUpdateCondition] = useMutation(updateMedicalCondition, {
    refetchQueries: ["medicalconditions"],
  });
  const [addCondition, reqAddCondition] = useMutation(createMedicalCondition, {
    refetchQueries: ["medicalconditions"],
  });

  // if (data) {
  //   console.log(data)
  // }

  const changePage = (page) => {
    if (page && isNumber(page)) {
      setVariables({
        ...variables,
        page,
      });
    } else {
      notification.error({
        message: "Error",
        description: "Índice de página invalido",
      });
    }
  };

  const onSearch = (value) => {
    if (isString(value)) {
      // console.log(value)
      setVariables({
        ...variables,
        filters: [
          {
            byText: { field: "NAME", value: value },
          },
        ],
      });
    }
  };

  const Ondeletemedicalcondition = async (id) => {
    try {
      setVariables({
        perPage: 5,
        page: 1,
        filters: [],
      });
      return deletemedicalcondition({ variables: { id: id } });
    } catch (e) {
      console.log(e);
    }
  };

  const onEdit = (Item) => {
    try {
      const { idItem: id, descriptionItem: description, nameItem: name } = Item;
      return updateCondition({ variables: { id, description, name } });
    } catch (e) {
      console.log(e);
    }
  };

  const onAddItem = (values) => {
    try {
      return addCondition({ variables: { bulkNames: values } });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className='m-3 p-3 w-full mx-auto md:w-3/5'>
      <h2 className='text-2xl'>
        <strong>Condiciones Médicas</strong>
      </h2>
      <div className='mt-5'>
        <Card bodyStyle={{ padding: "0.5rem" }} title={null}>
          <BasicTable
            erroraddItem={reqAddCondition.error}
            onAddItem={onAddItem}
            EditItemSubmit={onEdit}
            reqUpdateItem={reqUpdateCondition.error}
            gqlFindItem={medicalconditionById}
            errorDelete={reqDeletemedicalcondition.error}
            error={error}
            changePage={changePage}
            deleteItem={Ondeletemedicalcondition}
            loading={loading}
            data={data}
            plaseholdeAlertDelete='Seguro que quieres eliminar esta Condicionós Medica?'
            plaseholderSearch='Nombre de la Condicionós Medica'
            onSearchTable={onSearch}
            path='medicalconditions'
            pathId='medicalconditionById'
          />
        </Card>
      </div>
    </div>
  );
};

export default CondicionesMedicas;
