import React, { useEffect, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faPhoneSlash } from "@fortawesome/free-solid-svg-icons";
import { Button, notification, Tooltip } from "antd";
import { useLazyQuery } from "@apollo/react-hooks";
import { appointmentMDStatusGql, appointmentStatusGql } from "./gqlTags";

const ButtonLLamarDoctor = ({
  disabled,
  loading,
  onClick,
  isNormalAppointment,
  idAppointment,
  callOngoing,
  terminarSessionLlamada,
  isBusyOtherCall,
  setIsBusyOtherCall,
}) => {
  const [loadingValidation, setLoadingValidation] = useState(false);
  const [showEndCallButton, setShowEndCallButton] = useState(false);

  const [getInfoAppointment, { loading: loadingQuery, data: dataQuery, error: errorQuery }] =
    useLazyQuery(isNormalAppointment ? appointmentStatusGql : appointmentMDStatusGql, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    });

  const onClickLlamar = () => {
    setLoadingValidation(true);
    getInfoAppointment({
      variables: {
        id: idAppointment,
      },
    });
  };

  const validateStatusAppointment = useCallback(() => {
    if (isNormalAppointment) {
      // IN_APPOINTMENT
      const { usable } = dataQuery.appointmentById;
      if (usable === "IN_APPOINTMENT") {
        onClick();
      } else {
        setLoadingValidation(false);
        openNotification({
          placement: "topLeft",
          description: "La cita no está aún activa",
        });
      }
    } else {
      // COMPLETE
      const { status } = dataQuery.appointmentmdById;
      if (status === "ONGOING") {
        onClick();
      } else if (status === "COMPLETE") {
        setLoadingValidation(false);
        openNotification({
          placement: "topLeft",
          description: "La cita ya fue completada",
        });
      }
    }
  }, [dataQuery, isNormalAppointment]);

  useEffect(() => {
    if (!loadingQuery && !errorQuery) {
      if (dataQuery) {
        validateStatusAppointment();
      }
    } else if (errorQuery) {
      setLoadingValidation(true);
      console.log(errorQuery);
    }
  }, [loadingQuery, dataQuery, errorQuery]);

  useEffect(() => {
    // cuando ya exista una sessionID creada y el listener de llamada ongoing este en estado true
    // esperar unos 3s para mostrar el boton de cortar llamada pendiente
    // este boton cerrara la modal de llamada en el lado del paciente
    if (callOngoing) {
      const endCallTimer = setTimeout(() => {
        setShowEndCallButton(true);
      }, 3000);
      return () => clearTimeout(endCallTimer);
    } else {
      setShowEndCallButton(false);
    }
  }, [callOngoing]);

  const openNotification = ({ placement, description }) => {
    notification.warning({
      message: "No se puede realizar la llamada en este momento",
      description: description,
      placement,
    });
  };
  const openBusyCallNotification = () => {
    notification.info({
      message: "Paciente ocupada",
      description: "Llame después de un tiempo, el paciente está ocupado con otra llamada",
      placement: "topLeft",
    });
  };

  useEffect(() => {
    if (isBusyOtherCall) {
      openBusyCallNotification();
      setIsBusyOtherCall(false);
    }
  }, [isBusyOtherCall]);

  return (
    <div style={{ maxWidth: "500px" }} className='flex w-full mx-auto'>
      <Button
        size='middle'
        type='default'
        shape='round'
        className={
          "button-success h-12 w-full " + (callOngoing && showEndCallButton ? "ml-auto" : "mx-auto")
        }
        icon={<FontAwesomeIcon className='mx-2' size='lg' icon={faPhoneAlt} />}
        style={{
          maxWidth: "200px",
        }}
        disabled={disabled || loadingQuery || loadingValidation}
        loading={loading || loadingQuery || loadingValidation}
        onClick={() => onClickLlamar()}
      >
        <strong>Llamar</strong>
      </Button>
      {callOngoing && showEndCallButton && (
        <Tooltip title='Cortar la llamada'>
          <Button
            className='h-12 ml-8 mr-auto'
            type='danger'
            shape='round'
            size='middle'
            icon={<FontAwesomeIcon className='mx-2' size='lg' icon={faPhoneSlash} />}
            onClick={() => terminarSessionLlamada()}
          />
        </Tooltip>
      )}
    </div>
  );
};

ButtonLLamarDoctor.defaultProps = {
  disabled: true,
  loading: false,
  onClick: () => {},
  isNormalAppointment: true,
  idAppointment: "",
  callOngoing: false,
  terminarSessionLlamada: () => {},
};

export default React.memo(ButtonLLamarDoctor);
