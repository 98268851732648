import { useRef, useEffect } from "react";
import { notification } from "antd";
import { HandleError } from "./HandleError";

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export async function tryQuery(query, variables) {
  return await query(variables).catch((error) => {
    notification.destroy();
    notification.error({
      message: "Error",
      description: HandleError(error),
    });
  });
}
