import { useState, useEffect, useContext, useCallback } from "react";

// lodash
import isNull from "lodash/isNull";
import isString from "lodash/isString";

import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { appointmentByIdGql, appointmentStatusGql } from "./gqlTags";

// context
import { GlobalContext } from "../../App";

// utils
import {
  verifyIdUserVsPatient,
  verifyFinish,
  verifyBefore,
  verifyInAppointment,
  verifyIsAfter,
} from "./utils";
import uniqueId from "lodash/uniqueId";

const UseValidatorCita = (id) => {
  const { state } = useContext(GlobalContext);
  const { user, chatCall: chatCallGlobalState } = state;
  const [dataAppointment, setDataAppointment] = useState(null);
  const [notFound, setNotFound] = useState(null);
  const [isInAppointment, setIsInAppointment] = useState(null);
  const [isFinish, setIsFinish] = useState(null);
  const [isBefore, setIsBefore] = useState(null);
  const [isAfter, setIsAfter] = useState(null);
  const [listenerID] = useState(uniqueId("citaNormalPaciente_"));
  const [infoForChat, setInfoForChat] = useState(null);

  const [getInfoAppointment, { loading, data, error }] = useLazyQuery(appointmentByIdGql, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: loadingStatus,
    error: errorStatus,
    data: dataStatus,
    startPolling,
    stopPolling,
    refetch,
  } = useQuery(appointmentStatusGql, {
    variables: { id: id },
    fetchPolicy: "no-cache",
  });

  const lazyQueryPrincipalRefetch = useCallback(() => {
    try {
      getInfoAppointment({
        variables: {
          id: id,
        },
      });
    } catch (e) {
      console.log("Network Error");
    }
  }, [getInfoAppointment, id]);

  const inspectStatus = useCallback(
    (dataQuery, type) => {
      const isValidId_ = verifyIdUserVsPatient(user.id, dataQuery.patient.id);
      const isFinish_ = verifyFinish(dataQuery.status, dataQuery.usable);
      const isAfter_ = verifyIsAfter(dataQuery.status, dataQuery.usable);
      const isBefore_ = verifyBefore(dataQuery.status, dataQuery.usable);
      const isInAppointment_ = verifyInAppointment(dataQuery.status, dataQuery.usable);

      setIsFinish(isFinish_);
      setIsBefore(isBefore_);
      setIsAfter(isAfter_);
      setIsInAppointment(isInAppointment_);

      if (isValidId_ && type === "main") {
        setDataAppointment(dataQuery);
      } else if (!isValidId_) {
        setDataAppointment(null);
        setNotFound(true);
      }
    },
    [user.id]
  );

  useEffect(() => {
    refetch();
  }, [chatCallGlobalState, refetch]);

  useEffect(() => {
    startPolling(15000);
    return () => {
      stopPolling();
    };
  }, [stopPolling, startPolling]);

  useEffect(() => {
    if (isFinish) {
      stopPolling();
    }
  }, [isFinish, stopPolling]);

  useEffect(() => {
    if (isNull(id) || !isString(id)) {
      setNotFound(true);
    } else {
      lazyQueryPrincipalRefetch();
    }
  }, [id, setNotFound, lazyQueryPrincipalRefetch]);

  useEffect(() => {
    if (!loadingStatus) {
      if (dataStatus) {
        inspectStatus(dataStatus.appointmentById, "refresh");
      } else if (errorStatus) {
        setNotFound(true);
      }
    }
  }, [loadingStatus, dataStatus, errorStatus, inspectStatus]);

  useEffect(() => {
    if (!loading) {
      if (data) {
        const appointment = data.appointmentById;
        const doctor = data.appointmentById.doctor;
        inspectStatus(appointment, "main");
        const userChat = {
          hasBlockedMe: false,
          blockedByMe: false,
          role: "default",
          avatar: doctor.pictureURL.url,
          _id: doctor?.id,
          name: doctor?.fullName,
          username: doctor?.openFireUsername,
        };
        setInfoForChat(userChat);
      } else if (error) {
        setNotFound(true);
      }
    }
  }, [loading, data, error, inspectStatus]);

  return {
    dataAppointment: dataAppointment,
    listenerID: listenerID,
    isError: error || errorStatus,
    notFound: notFound,
    isAfter: isAfter,
    isFinish: isFinish,
    isInAppointment: isInAppointment,
    isBefore: isBefore,
    infoForChat: infoForChat,
    loadingBrand:
      loading || (!loading && !data && !error) || (!loadingStatus && !dataStatus && !errorStatus),
    sessionIDCall: chatCallGlobalState ? chatCallGlobalState.sessionID : null,
  };
};

export default UseValidatorCita;
