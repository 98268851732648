import React, { useState } from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import BrandLoading from "../../../../components/CitasUtils/BrandLoading/brand";
import OpenTok from "components/Vonage";

const ContenedorDeLlamada = (props) => {
  const { terminarSessionLlamada, infoAppointment, infoForChat, disconnectCall } = props;
  const [spinCall, setSpinCall] = useState(true);
  const [countFullCallUsers, setCountFullCallUsers] = useState(0);

  const blockUiProps = {
    className: "w-full h-full",
    tag: "div",
    blocking: spinCall,
    loader: countFullCallUsers > 1 ? <CustomErrorUsers /> : <CustomLoading />,
  };

  return (
    <BlockUi {...blockUiProps}>
      <div className='w-full h-full'>
        <OpenTok
          infoAppointment={infoAppointment}
          terminarSessionLlamada={terminarSessionLlamada}
          setSpinCall={setSpinCall}
          infoForChat={infoForChat}
          setCountFullCallUsers={setCountFullCallUsers}
          disconnectCall={disconnectCall}
        />
      </div>
    </BlockUi>
  );
};

const CustomLoading = () => {
  return (
    <>
      <BrandLoading width='60%' />
      <p className='text-center font-xl font-bold text-primary mt-3'>Conectando llamada ....</p>
      <p className='text-center font-xl font-bold text-primary mt-3'>
        Este proceso puede tardar unos minutos
      </p>
    </>
  );
};

const CustomErrorUsers = () => {
  return (
    <>
      <BrandLoading width='%40' />
      <div className='flex flex-col bg-gray-200 p-2 mt-3'>
        <p className='mx-auto my-auto font-xl font-bold text-danger'>
          ERROR: Solo hay 1 persona en la sala, verificando la conexión ...
        </p>
        <p className='mx-auto mb-auto text-center font-xl font-bold text-danger mt-3'>
          La llamada se cerrará automáticamente en unos segundos.
        </p>
        <p className='mx-auto mb-auto text-center font-xl font-bold text-primary mt-3'>
          !Por favor espere!
        </p>
      </div>
    </>
  );
};

export default React.memo(ContenedorDeLlamada);
