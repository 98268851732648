import React from "react";
// antd
import Card from "antd/lib/card";
import Avatar from "antd/lib/avatar";

// icon
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import style from 'components/Medic/style.module.scss'

import "./stylesTap2.scss";

export const CarAvatarMedic = (props) => {
  const { className } = props;
  const propsCard = {
    bordered: false,
    bodyStyle: {
      paddingBottom: "0.5rem",
      paddingTop: "0.2rem",
      paddingLeft: "0",
      paddingRight: "1.5rem",
    },
  };
  return (
    <Card className={`rounded-full ${className}`} {...propsCard}>
      <div className='flex'>
        <Avatar
          size='large'
          className='my-auto justify-start mx-auto'
          src='https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
        />
        <p className='my-auto mx-auto justify-centerr'>
          <strong>Veronica Silva</strong>
        </p>
      </div>
    </Card>
  );
};

export const CardExamen = (props) => {
  const { className, nameTitle, onClickEye } = props;

  const propsCard = {
    headStyle: { marginTop: "auto", marginBottom: "auto", paddingTop: 0 },
    title: nameTitle,
    extra: (
      <FontAwesomeIcon
        onClick={onClickEye}
        className='text-center mr-2 cursor-pointer hover:text-primary'
        size='lg'
        icon={faEye}
      />
    ),
    bordered: false,
    bodyStyle: { display: "none" },
  };
  return (
    <Card
      className={`my-4 mx-2 rounded-lg hover:shadow-2xl CardExamen ${className}`}
      {...propsCard}
    />
  );
};

CardExamen.defaultProps = {
  nameTitle: "Lorem ipsum",
  className: "",
  onClickEye: () => alert("sumit Eye"),
};

CarAvatarMedic.defaultProps = {
  className: "",
};
