import React from "react";
import ContendTermsAndConditionsApp from "./ContendTermsAndConditionsApp";

const TermsAndConditionsApp = () => {
  return (
    <div className='xs:w-full 3xl:w-2/3 flex flex-col mx-auto mt-8'>
      <div className='w-4/5 mr-auto ml-auto mb-3'>
        <ContendTermsAndConditionsApp />
      </div>
    </div>
  );
};

export default TermsAndConditionsApp;
