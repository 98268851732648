import React, { useState, useEffect, useContext, useCallback } from "react";
import { Table, Input, notification } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { doctorListGql } from "./gqlTags";
import columns from "./columnsTable";

import { ContextTablaPlanilla } from "./index";
import ModalPagarPlanilla from "./modalPagarPlanilla";

const TablePlanilla = () => {
  const { state } = useContext(ContextTablaPlanilla);
  const [paginationVars, setPaginationVars] = useState({
    perPage: 10,
    page: 1,
  });
  const [nombreBuscar, setNombreBuscar] = useState("");
  const [filterDoctor, setFilterDoctor] = useState([]);
  const [sortDoctor] = useState([
    {
      field: "BALANCE",
      direction: "DESCENDING",
    },
  ]);

  const { data, loading, error, refetch } = useQuery(doctorListGql, {
    variables: {
      ...paginationVars,
      filters: filterDoctor,
      sort: sortDoctor,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const sendRefetch = useCallback(async () => {
    await refetch();
  }, [refetch]);

  useEffect(() => {
    if (state.randomValue) {
      sendRefetch();
    }
  }, [state.randomValue, sendRefetch]);

  useEffect(() => {
    const newFilter = [];
    if (nombreBuscar.length > 0) {
      newFilter.push({
        byText: {
          field: "FIRSTNAME",
          value: nombreBuscar,
          or: "LASTNAME",
        },
      });
    }
    setFilterDoctor(newFilter);
    setPaginationVars({
      perPage: 10,
      page: 1,
    });
  }, [nombreBuscar]);

  useEffect(() => {
    if (error && !loading) {
      notification.error({
        message: "Error",
        description: error.graphQLErrors[0]
          ? error.graphQLErrors[0].message
          : "Error al solicitar data",
      });
    }
  }, [error, loading]);

  const getDataForTable = () => {
    if (data) {
      return data.doctors.docs;
    } else {
      return [];
    }
  };

  const getPagination = () => {
    return {
      current: paginationVars.page,
      pageSize: paginationVars.perPage,
      total: data ? data.doctors.total : 0,
    };
  };

  const handleTableChange = (infoTable) => {
    const { current } = infoTable;
    setPaginationVars({
      ...paginationVars,
      page: current,
    });
  };

  return (
    <>
      {!loading && <ModalPagarPlanilla />}
      <div className='w-full flex xs:flex-col sm:flex-row mb-3'>
        <Input
          className='xs:w-2/3 md:w-1/4 lg:w-2/5 xs:mx-auto md:mx-0 '
          style={{ maxWidth: "250px" }}
          placeholder='Buscar por nombre'
          value={nombreBuscar}
          onChange={(e) => setNombreBuscar(e.target.value)}
        />
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={getDataForTable()}
        pagination={getPagination()}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TablePlanilla;
