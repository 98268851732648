import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
// antd
import Form from "antd/lib/form";
import Modal from "antd/lib/modal";
// components
import FormPlan from "./Form";
// gql
import { createFeature, updateFeature } from "views/Admin/Plans/gql";
// isEqual
import isEqual from "lodash/isEqual";
import notify from "antd/lib/notification";
import { HandleError as handleError } from "utils.js/HandleError";

export default function Dialog({ info = {}, visible, ...modal }) {
  const hasId = !!info.id;
  const gql = hasId ? updateFeature : createFeature;

  const [form] = Form.useForm();
  const [variables, setVariables] = useState(info);
  const [mutation, { error, loading }] = useMutation(gql, {
    variables,
    refetchQueries: ["getPlansSource"],
  });

  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible, form]);

  useEffect(() => {
    if (error) notify.error({ message: handleError(error) });
  }, [error]);

  const onFinish = () => {
    mutation().then((_) => {
      const message = hasId ? "Caracteristica Actualizada" : "Caracteristica Creada";
      notify.success({ message });
      modal.onCancel();
    });
  };

  const props = {
    Modal: {
      visible,
      onOk: form.submit,
      title: hasId ? "Detalle Caracteristica" : "Nueva Caracteristica",
      centered: true,
      cancelButtonProps: {
        style: { display: "none" },
      },
      okText: hasId ? "Actualizar" : "Crear",
      okButtonProps: {
        loading,
        disabled: isEqual(info, variables),
      },
      ...modal,
    },
    Form: {
      form,
      onFinish,
      name: "FormPlan",
      initialValues: info,
      onValuesChange: (_, values) => setVariables(values),
      layout: "vertical",
      hideRequiredMark: true,
    },
  };

  return (
    <Modal {...props.Modal} ok>
      <FormPlan {...props.Form} />
    </Modal>
  );
}
