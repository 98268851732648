import React from "react";

const GetError = ({ error, message }) => {
  if (error && Array.isArray(error.graphQLErrors)) {
    return (
      <div className='w-full flex flex-col p-0'>
        <ul className='mb-0'>
          {error.graphQLErrors.map((value, index) => {
            return (
              <li key={index} className='mb-1'>
                {value.message}
              </li>
            );
          })}
        </ul>
      </div>
    );
  } else {
    return (
      <ul>
        <li className='mb-1'>{message}</li>
      </ul>
    );
  }
};

GetError.defaultProps = {
  message: "Ocurrió un error al obtener la información de la cita",
};

export default GetError;
