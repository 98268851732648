import React, { useState, useContext, useEffect } from "react";
import Modal from "antd/lib/modal";
import PlansTable from "../PlanesEasyMD/Table";
import { GlobalContext } from "views/App";

const ModalWizard = () => {
  const { state } = useContext(GlobalContext);
  const { user } = state;
  const [planModal, setPlanModal] = useState(false);
  const userHasPlan = user.plans.length || user.beneficiaryOf;
  useEffect(() => {
    !userHasPlan && setPlanModal(true);
  }, [userHasPlan]);

  return (
    <Modal
      destroyOnClose
      style={{ minWidth: "20rem" }}
      bodyStyle={{ paddingBottom: "0", minHeight: "500px" }}
      className='w-9/12 md:w-10/12 lg:w-7/12'
      // width='50vw'
      title={null}
      footer={null}
      visible={planModal}
      onOk={() => console.log("sumit ok")}
      onCancel={() => setPlanModal(false)}
    >
      <PlansTable modal={true} />
    </Modal>
  );
};

export default ModalWizard;
