import React from "react";
import moment from "moment";
// ant
import Card from "antd/lib/card";
// lodash
import toInteger from "lodash/toInteger";
import isNull from "lodash/isNull";

const CardJob = (_) => {
  return (
    <Card className='w-full shadow-sm ml-2' title='Información de pago recurrente'>
      {_.AgendaJob ? (
        <div
          className={`grid grid-flow-cols ${
            isNull(_.AgendaJob.failDate) || isNull(_.AgendaJob.failReason)
              ? "grid-cols-3 grid-rows-1"
              : "grid-cols-2 grid-rows-3"
          }   gap-2`}
        >
          <span>
            Monto: <strong>{_.AgendaJob.amount}</strong>
          </span>
          <span className='capitalize'>
            última fecha de pago: &nbsp;
            {_.AgendaJob.lastDatePayment
              ? moment(toInteger(_.AgendaJob.lastDatePayment)).format("DD/MM/YYYY")
              : "N/A"}
          </span>
          <span className='capitalize'>
            próxima fecha de pago: &nbsp;
            {_.AgendaJob.nextDatePayment
              ? moment(toInteger(_.AgendaJob.nextDatePayment)).format("DD/MM/YYYY")
              : "N/A"}
          </span>
          {_.AgendaJob.failDate && (
            <span className='capitalize'>
              Fecha de falla: &nbsp;
              {moment(toInteger(_.AgendaJob.failDate)).format("DD/MM/YYYY")}
            </span>
          )}
          {_.AgendaJob.failReason && (
            <span className='capitalize'>
              Razón de la falla: &nbsp;
              {moment(toInteger(_.AgendaJob.failReason)).format("DD/MM/YYYY")}
            </span>
          )}
        </div>
      ) : (
        <h3>Este plan de usuario no tiene un pago recurrente programado.</h3>
      )}
    </Card>
  );
};

export default CardJob;
