import React, { useContext } from "react";
import Button from "antd/lib/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import * as actionNames from "./store/actions";
import { ContextTablaEmpresas } from "./index";

import { useHistory } from "react-router-dom";
import RowAvatar from "components/RowAvatar";

import DownloadCsv from "./DownloadCsv";

const columns = [
  {
    title: "Nombre",
    dataIndex: "fullName",
    className: "p-1",
    render: (text, row) => (
      <RowAvatar
        text={text}
        row={row}
        description={
          <>
            <p className='text-left pb-1 mb-1'>{row.email}</p>
            <span className='text-left'>
              Teléfono: <strong>{row.phoneNumber || "N/A"}</strong>
            </span>
          </>
        }
      />
    ),
  },
  {
    title: "N° Usuarios",
    dataIndex: "totalAffiliateUsers",
    className: "text-center w-5por",
    // render: (text, row) => (
    //   <span>{row.totalAffiliateUsers}</span>
    // )
  },
  {
    title: "Detalles",
    dataIndex: "custom_verInfo",
    className: "text-center w-5por",
    render: (row, fullRow) => <RenderInfo row={row} fullRow={fullRow} />,
  },
  {
    title: "Acciones",
    dataIndex: "custom_acciones",
    className: "text-center w-1/6",
    render: (row, fullRow) => <RenderActionsAprobarRechazar fullRow={fullRow} />,
  },
];

const RenderInfo = ({ fullRow }) => {
  const { id } = fullRow;
  const { dispatch } = useContext(ContextTablaEmpresas);
  const clickInfo = () => {
    dispatch({
      type: actionNames.SET_INFO_EMPRESA,
      payload: id,
    });
    dispatch({
      type: actionNames.SET_MODAL_INFO_OPEN,
      payload: true,
    });
  };

  return (
    <>
      <Button
        type='default'
        shape='round'
        size='small'
        className='border-0 shadow-none'
        onClick={() => clickInfo()}
      >
        <FontAwesomeIcon icon={faEye} />
      </Button>
      <DownloadCsv type='patient' id={id} />
      <DownloadCsv type='code' id={id} />
    </>
  );
};

const RenderActionsAprobarRechazar = ({ fullRow }) => {
  const { dispatch } = useContext(ContextTablaEmpresas);
  const { id } = fullRow;
  const history = useHistory();

  const openDeclinar = () => {
    dispatch({
      type: actionNames.SET_INFO_EMPRESA,
      payload: id,
    });
    dispatch({
      type: actionNames.SET_MODAL_DENY_OPEN,
      payload: true,
    });
  };

  const openEdit = () => {
    history.push("/admin/empresa/" + id);
  };

  const openInfo = () => {
    history.push("/admin/empresa/grafico/" + id);
  };

  return (
    <div className='flex justify-center'>
      <Button
        className='mx-2'
        type='primary'
        danger
        shape='round'
        size='small'
        onClick={() => openDeclinar()}
      >
        {" "}
        Eliminar
      </Button>
      <Button
        className='button-success mx-2'
        type='default'
        shape='round'
        size='small'
        onClick={() => openEdit()}
      >
        {" "}
        Editar
      </Button>
      <Button
        className='button-info mx-2'
        type='default'
        shape='round'
        size='small'
        onClick={() => openInfo()}
      >
        info
      </Button>
    </div>
  );
};

export default columns;
