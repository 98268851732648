import React from "react";

const ContendTermsAndConditions = () => {
  return (
    <>
      <p className='text-justify'>
        Los siguientes términos y condiciones (“los términos y condiciones”) son aplicables por el
        simple acceso o uso de cualquiera de las aplicaciones o páginas electrónicas que integran la
        plataforma denominada EasyMD, http//www.easymd.co (en adelante como la plataforma) mediante
        la cual se otorgarán servicios (“los servicios”) puestos a disposición por EasyMD, propiedad
        de Microsalud, Sociedad Anónima sociedad debidamente constituida mediante las leyes de
        Nicaragua mediante Escritura Pública número ciento dos de Constitución de Sociedad Anónima y
        Estatutos autorizada en la ciudad de Managua a las ocho de la mañana del día doce de
        Septiembre del año dos mil diecinueve ante los oficios notariales de Martin Moraga Cespedes,
        la cual se encuentra inscrita bajo el Número Único del Folio Personal MG00-22-009765 en
        asiento 1 del Registro Público de la Propiedad Inmueble y Mercantil de Managua.
      </p>
      <p className='text-justify'>
        Cuando nos refiramos a Usuario es la persona que utiliza los servicios brindados a través de
        la plataforma. Y cuando nos refiramos a Microsalud, es la sociedad propietaria de la
        plataforma EsayMD cuyos datos de inscripción se encuentran mencionados en el párrafo que
        antecede.En caso de que no esté de acuerdo con los términos y condiciones, deberá de
        abstenerse de acceder o utilizar la plataforma. Mediante su acceso, suscripción y uso de la
        plataforma y de los servicios contenidos en ella y/o derivados de ella, el Usuario acepta
        expresa e irrevocablemente los términos y condiciones en el territorio en que se encuentre.
        Con esta aceptación, está consintiendo expresamente los mismos y está celebrando un acuerdo
        de uso de la plataforma, por lo que manifiesta haber leído el contenido de todas y cada una
        de las disposiciones y ratifica su contenido.
      </p>
      <p className='text-justify'>
        Microsalud como propietaria de la plataforma de EasyMD podrá modificar de forma unilateral
        los términos y condiciones cuando lo estime conveniente y sin previo aviso, con la única
        obligación de notificar dichas modificaciones a través de un mensaje incluido en la
        plataforma. Las modificaciones serán efectivas después de la comunicación por parte de
        Microsalud, y su posterior acceso o uso continuado después de dicha comunicación constituye
        su consentimiento tácito y vinculante hacia los presentes términos y condiciones y sus
        modificaciones.
      </p>

      <h4 className='underline uppercase'>
        <strong>1. DESCRIPCIÓN DEL SERVICIO</strong>
      </h4>

      <p className='text-justify'>
        EasyMD es una marca dentro de la sociedad Microsalud, cuya finalidad principal es la
        creación, operación y explotación comercial de la plataforma que tiene como objetivo otorgar
        asesoría a cualquier individuo que tenga la necesidad de ser asesorado por un profesional de
        la salud.
      </p>
      <p className='text-justify'>
        La plataforma le permitirá recibir asesoría con un profesional de la salud, a través de una
        video llamada, en la que se emitirán sugerencias, mismas que únicamente se entenderán como
        recomendaciones y que en ningún caso serán obligatorias, en virtud que el usuario podrá
        atenderlas o no.
      </p>
      <p className='text-justify'>
        La utilización de la plataforma es absolutamente voluntaria, por consiguiente, el usuario se
        encuentra en la plena libertad de seguir o no el consejo médico bajo su responsabilidad.
      </p>
      <p className='text-justify'>
        La plataforma resolverá dudas en el enfoque de problemas de salud, de tal forma en que las
        sugerencia o recomendaciones emitidas por un profesional de la salud no deberán ser
        consideradas como un diagnóstico o tratamiento definitivo, ya que en ningún momento la
        plataforma y las recomendaciones sustituye un médico presencial. En caso de ser necesario,
        se aconsejará la revisión por parte de personal médico ajeno o la utilización de
        emergencias.
      </p>
      <p className='text-justify'>
        En ningún momento se entenderá que el fin u objetivo de la plataforma es prestar servicios
        médicos sino servir como intermediario digital entre usuarios y profesionales de la salud.
        Por medio de comunicación electrónica que conlleva el uso de equipos de telecomunicaciones
        interactivas que incluyen, como equipamiento mínimo, audio y vídeo.
      </p>

      <h4 className='underline uppercase'>
        <strong>2. Calidad en la Prestación de Servicios y Pago.</strong>
      </h4>

      <p className='text-justify'>
        El uso de la plataforma únicamente es responsabilidad del usuario y en el caso de que se
        sienta inseguro sobre su estado de salud, deberá consultar a un médico presencial, o bien,
        acudir al hospital o clínica de su preferencia para obtener una valoración apropiada e
        integral.
      </p>

      <p className='text-justify'>
        El usuario expresamente reconoce y acepta que todos y cada uno
        de los riesgos derivados del uso de servicios relacionados con la plataforma son suyos,
        liberando expresamente a MicroSalud y la marca EasyMD de cualquier responsabilidad presente
        o futura que se pudiera presentar relacionada con el uso de la plataforma.
      </p>
      <p className='text-justify'>
        Se recabarán datos relativos a sus antecedentes de salud; así como los antecedentes de
        familiares cercanos, con el objetivo de realizar una asistencia adecuada. Es de suma
        importancia su cooperación y honestidad con respecto a estos datos con el objetivo de
        brindar un consejo médico eficaz, oportuno y apropiado. Microsalud y el personal médico que
        realiza las asistencias no se responsabiliza por las consecuencias derivadas de la omisión
        consciente o inconsciente de datos relativos a sus antecedentes médicos o datos personales.
        En caso de que los médicos que brindan el servicio de asistencia le indiquen al paciente que
        la atención o evaluación de un médico especialista o cualquier otro profesional de la salud
        es requerida, será responsabilidad del paciente hacer el seguimiento correspondiente y
        acudir de forma presencial con el personal externo antes mencionado. En caso de negarse,
        Microsalud y su personal médico, ni los profesionales de la salud afiliados o que utilizan
        la plataforma, no se responsabilizan de las consecuencias derivadas de esta decisión y el
        paciente no será sujeto a recibir ningún tipo de pago o indemnización por este concepto.
      </p>
      <p className='text-justify'>
        Mediante la aceptación a los presentes términos y condiciones, el Usuario renuncia
        expresamente a presentar cualquier tipo de reclamación, demanda, juicio o procedimiento
        legal ante cualquier autoridad Nicaragüense en contra de MicroSalud y la marca EasyMD, así
        como cualquier persona relacionada y/o afiliada a Mircosalud através de la plataforma de
        EasyMD, incluyendo, sin limitar, directores, apoderados, representantes, administradores,
        empleados, accionistas y/o agentes, presentes o anteriores, por cualquier acto que se
        derive, o pudiere derivar, del uso de la plataforma, o de cualquier servicio derivado de
        dicho uso.
      </p>
      <p className='text-justify'>
        Los precios de suscripción al servicio de La Plataforma están publicados en el Sitio y en el
        momento de contratar la suscripción podrán variar en base a acuerdo especial, que deberá
        quedar por escrito autorizado por Microsalud ó bien por un código de promoción que se
        determine, publique y acepte al momento de pagar en el Sitio
      </p>
      <p className='text-justify'>
        Microsalud posee la facultad de modificar la tarifa cobrada por el servicio una vez al año,
        por términos de reajuste. Esta tarifa no puede subir más de un 10% anual, y su objetivo es
        simplemente compensar los efectos inflacionarios. En caso de que Microsalud desee modificar
        la tarifa a un servicio previamente contratado, se le notificará al menos con un mes de
        anticipación, para que el Usuario pueda recuperar su información y salir del servicio si no
        se encuentra de acuerdo.
      </p>

      <h4 className='underline uppercase'>
        <strong>3. Acceso a la Plataforma y limitaciones de la misma.</strong>
      </h4>

      <p className='text-justify'>
        • El servicio de La Plataforma, consiste en permitir al Usuario el acceso al uso online de
        la Plataforma para la administración de consultorios o clínicas durante el periodo de tiempo
        que contrata y mientras mantenga renovando el servicio después de cada vencimiento.
      </p>
      <p className='text-justify'>
        • El usuario al contratar el servicio de La Plataforma, lo hace con un número máximo de
        médicos para acceder a La Plataforma. El Usuario, se compromete a no acceder a La Plataforma
        con más usuarios de los que indica el contrato.
      </p>
      <p className='text-justify'>
        • El Usuario no podrá copiar, modificar, distribuir, vender ni prestar ninguna parte de
        nuestros Servicios ni de la Plataforma, incluyendo el aplicar técnicas de ingeniería inversa
        ni intentar extraer el código fuente del software de La Plataforma.
      </p>
      <p className='text-justify'>
        • El Usuario no deberá usar nuestros Servicios de forma inadecuada. Por ejemplo, no deberá
        interferir con dichos Servicios ni intentar acceder a ellos usando un método distinto a las
        instrucciones proporcionadas. Solo podrá usar los Servicios en la medida en que las leyes y
        la normatividad aplicable lo permitan. Mircosalud se encuentra facultado para suspender o
        cancelar los Servicios si no se cumple con las políticas o condiciones especificadas en el
        presente o si se llega a considerar que la conducta del Usuario puede ser malintencionada.
      </p>
      <p className='text-justify'>
        • El uso de nuestros Servicios no convierte al Usuario en titular de ninguno de los derechos
        de propiedad intelectual de los mismos ni del contenido al que acceda. Solo podrá usar el
        contenido de nuestros Servicios si se autoriza por su titular o si está permitido por la
        ley. Estas condiciones no le otorgan al Usuario el derecho a usar las marcas ni los
        logotipos utilizados en nuestros Servicios. El Usuario se obliga a no eliminar, ocultar ni
        alterar los avisos legales que se muestren en la Plataforma. En relación con el uso de la
        Plataforma, el Usuario podrá recibir avisos de servicio, mensajes administrativos y otro
        tipo de información.
      </p>

      <h4 className='underline uppercase'>
        <strong>4. Limitación de responsabilidad para Microsalud.</strong>
      </h4>

      <p className='text-justify'>
        Microsalud, no proporciona ningún tipo de servicio médico, tales como consultas,
        diagnósticos médicos o psicológicos, entre otros, deslindándose de cualquier responsabilidad
        que se pudiera generar al respecto, siendo bajo la responsabilidad del Usuario, confiar en
        la información que le sea proporcionada a través de La Plataforma por el médico o
        profesional de la salud que haya elegido.
      </p>
      <p className='text-justify'>
        La inclusión de profesionales de la salud, en el directorio de La Plataforma, no implica
        recomendación ni aprobación de los mismos por parte de EasyMD, por lo que cualquier opinión,
        tratamiento, producto, procedimiento, diagnóstico, prescripción, que realicen los médicos y
        profesionales de la salud al Usuario, a través de La Plataforma, son independientes y no
        reflejan opiniones de EasyMD; siendo decisión y responsabilidad del Usuario seguirlas o no,
        liberando desde este momento de cualquier responsabilidad al respecto a EasyMD, ya que ésta
        última no tiene la capacidad de evaluar ni avalar, la calidad o validez de cualquier tipo de
        asesoramiento médico o psicológico.
      </p>
      <p className='text-justify'>
        EL Usuario, es responsable de todo acuerdo que realice con cualquier profesional de la salud
        de La Plataforma.
      </p>
      <p className='text-justify'>
        Microsalud, sus filiales o distribuidores no son responsables de ningún daño directo,
        indirecto, incidental, consecuente, especial, derivados, punitivos; Ni por la pérdida de
        beneficios, ingresos, datos, pérdidas financieras o de otro tipo que surja de o esté
        relacionado de alguna manera con el uso del servicio de La Plataforma. En ningún caso el
        Usuario podrá reclamar a Microsalud o su marca y su único recurso para la insatisfacción del
        Usuario con el servicio es dejar de usar el servicio
      </p>
      <p className='text-justify'>
        Microsalud no será responsable frente a usuarios, profesionales de la salud o cualquier
        persona relacionada con ésta, por cualquier tipo de daño o reclamo derivado de deficiencias
        en los servicios prestados por los profesionales de la salud o por cualquier error, omisión
        y/o falsedad en la información proporcionada, ya sea a través de la plataforma o cualquier
        otro medio.
      </p>
      <p className='text-justify'>
        Al aceptar los presentes términos y condiciones el usuario reconoce que Mircosalud a través
        de su plataforma de EasyMD, no prestan servicios médicos, se prestan por terceros
        independientes, que no son empleados por Microsalud, siendo esta ultima una plataforma de
        contacto entre el Usuario y el Profesional de la Salud, por lo que el Usuario libera
        expresamente a Microsalud de toda responsabilidad asociada con el asesoramiento,
        recomendaciones u otra información que le proporciones los Profesionales de la Salud a
        través de la Plataforma.
      </p>

      <h4 className='underline uppercase'>
        <strong>5. Licencia.</strong>
      </h4>

      <p className='text-justify'>
        Sujeto al cumplimiento de estos Términos y Condiciones, Microsalud le otorga una licencia
        limitada, no exclusiva, no sublicenciable, revocable, y no transferible para: (i) el acceso
        y uso de las Aplicaciones en su dispositivo personal solo en relación con su uso de los
        Servicios; y (ii) el acceso y uso de cualquier contenido, información y material relacionado
        que pueda ponerse a disposición a través de los Servicios, en cada caso solo para su uso
        personal, no comercial. Microsalud y sus licenciantes se reservan cualquier derecho que no
        haya sido expresamente otorgado por el presente.
      </p>

      <h4 className='underline uppercase'>
        <strong>6. Sitios y contenido de terceros.</strong>
      </h4>

      <p className='text-justify'>
        Desde La Plataforma, es posible que se redirija a contenidos de terceros sitios web. Dado
        que La Plataforma no puede controlar siempre los contenidos introducidos por los terceros en
        sus respectivos sitios web, no asume ningún tipo de responsabilidad respecto a dichos
        contenidos. En todo caso, procederá a la retirada inmediata de cualquier contenido que
        pudiera contravenir la legislación nacional o internacional, la moral o el orden público,
        procediendo a la retirada inmediata de la redirección a dicho sitio web, poniendo en
        conocimiento de las autoridades competentes el contenido en cuestión.
      </p>
      <p className='text-justify'>
        La Plataforma no se hace responsable de la información y contenidos almacenados, a título
        enunciativo, pero no limitativo, en foros, chats, generadores de blogs, comentarios, redes
        sociales o cualquier otro medio que permita a terceros publicar contenidos de forma
        independiente en la página web de EasyMD. Sin embargo, , se pone a disposición de todos los
        usuarios, autoridades y fuerzas de seguridad, colaborando de forma activa en la retirada o,
        en su caso, bloqueo de todos aquellos contenidos que puedan afectar o contravenir la
        legislación nacional o internacional, los derechos de terceros o la moral y el orden
        público. En caso de que el usuario considere que existe en el sitio web algún contenido que
        pudiera ser susceptible de esta clasificación, se ruega lo notifique de forma inmediata al
        administrador del sitio web.
      </p>
      <p className='text-justify'>
        Esta Plataforma ha sido revisada y probada para que funcione correctamente. En principio,
        puede garantizarse el correcto funcionamiento los 365 días del año, 24 horas al día. Sin
        embargo, Microsalud no descarta la posibilidad de que existan ciertos errores de
        programación, o que acontezcan causas de fuerza mayor, catástrofes naturales, huelgas o
        circunstancias semejantes que hagan imposible el acceso a la Plataforma.
      </p>
      <p className='text-justify'>
        Direcciones IP <br />
        <br />
        Los servidores de La Plataforma podrán detectar de manera automática la dirección IP y el
        nombre de dominio utilizados por el usuario. Una dirección IP es un número asignado
        automáticamente a un ordenador cuando éste se conecta a Internet. Toda esta información es
        registrada en un fichero de actividad del servidor debidamente inscrito que permite el
        posterior procesamiento de los datos con el fin de obtener mediciones únicamente
        estadísticas que permitan conocer el número de impresiones de páginas, el número de visitas
        realizadas a los servidores web, el orden de visitas, el punto de acceso, etc.
      </p>

      <h4 className='underline uppercase'>
        <strong>
          7. Compatibilidad de dispositivos electrónicos y prohibiciones eléctronicas.
        </strong>
      </h4>

      <p className='text-justify'>
        El óptimo funcionamiento de La Plataforma requiere de por lo menos un navegador o browser
        Google Chrome (LR), Mozilla Firefox (LR), Internet Explorer (versión 8 superior)
      </p>
      <p className='text-justify'>
        Con todo, los programas, equipos y redes computacionales que el Usuario utilice y aquellos
        en los que opera La Plataforma, son herramientas para el procesamiento y transmisión de
        datos que, por su naturaleza, están sujetos a fallas, ya sea en su propio funcionamiento o
        en sus programas; contingencia a que está expuesto cualquier persona, sea el administrador o
        proveedor de dichas herramientas y que no desaparece en virtud de la aceptación de los
        presentes Términos y Condiciones.
      </p>
      <p className='text-justify'>
        Por lo tanto, hasta el máximo permitido por la ley, Microsalud no se hace responsable de la
        falta de disponibilidad y efectivo funcionamiento de La Plataforma; de su falta de utilidad,
        adecuación o validez para satisfacer necesidades, actividades o resultados concretos o
        expectativas de los visitantes o Usuarios; de la existencia de virus, programas maliciosos o
        lesivos; del uso ilícito, negligente, fraudulento, contrario a los presentes términos, a la
        buena fe, a los usos generalmente aceptados y/o al orden público de la Página por parte de
        los visitantes o Usuarios; ni de la falta de licitud, calidad, fiabilidad, utilidad y
        disponibilidad de las promociones, productos y servicios prestados por terceros y puestos a
        disposición de los visitantes o Usuarios del Sitio Web.
      </p>

      <h4 className='underline uppercase'>
        <strong>8. Manejo de Información.</strong>
      </h4>

      <p className='text-justify'>
        Microsalud a través de EasyMD se compromete a no publicar, compartir, transmitir, vender o
        distribuir a cualquier título, la información ingresada por El Usuario a La Plataforma dando
        cumplimiento a la Política de Privacidad de EasyMD la cual es de conformidad a la
        establecida en nuestra legislación en lo referente a la Ley de Datos Personales.
      </p>

      <h4 className='underline uppercase'>
        <strong>9. Datos personales y privacidad.</strong>
      </h4>

      <p className='text-justify'>
        Los criterios sobre la política de privacidad de La Plataforma se enmarcan en: <br /> <br />
        Uso de la información proporcionada: Se concibe por información personal aquella provista
        por el usuario en los formularios de contacto, la cual incluye datos tales como: nombre,
        edad, género, dirección, numero de contacto, correo electrónico. El usuario reconoce que el
        ingreso de información personal lo realiza de manera voluntaria.
      </p>
      <p className='text-justify'>
        El Usuario podrá modificar o actualizar la información suministrada en cualquier momento,
        con el fin de mejorar el servicio que pueda recibir desde La Plataforma. En todo caso la
        información únicamente será utilizada para informar a los usuarios sobre cambios en los
        contenidos, o informar cambios en el servicio al cual se han inscrito.
      </p>
      <p className='text-justify'>
        El objetivo fundamental es garantizar y proteger la privacidad y la confidencialidad de los
        datos de carácter personal de los usuarios, de conformidad con la legislación vigente en
        Nicaragua. La información personal proporcionada por el Usuario, no es visible al público en
        general. En ningún momento y bajo ninguna circunstancia Microsalud a través de EasyMD
        publicará los contenidos de correos electrónicos, o intercambio de correos electrónicos
        entre sus visitantes/usuarios.
      </p>
      <p className='text-justify'>
        El Usuario reconoce que su participación y publicación de comentarios en los espacios de
        foro y blog y/o cualquier otro espacio de participación, será bajo su exclusiva
        responsabilidad. Microsalud no se responsabiliza por cualquier consecuencia derivada del
        ingreso indebido de terceros a la base de datos y/o por alguna falla técnica en el
        funcionamiento y/o conservación de datos en el sistema en cualquiera de las secciones de la
        página.
      </p>

      <h4 className='underline uppercase'>
        <strong>10. Actividades de mantenimiento.</strong>
      </h4>
      <p className='text-justify'>
        Para efectuar los trabajos de mantenimiento de La Plataforma, EasyMD se reserva el derecho
        de suspender el acceso y/o modificar el contenido, así como a eliminar o eshabilitar el
        acceso a la plataforma sin previo aviso.
      </p>
      <p className='text-justify'>
        El acceso a la plataforma depende de la disponibilidad de la red, por lo que EasyMD no será
        responsable por cualquier imposibilidad de acceder a la misma, derivada de circunstancias
        que se encuentren fuera de razonable control, así como por caso fortuito o de fuerza mayor
      </p>

      <h4 className='underline uppercase'>
        <strong>11. No relación Laboral.</strong>
      </h4>
      <p className='text-justify'>
        El Usuario reconoce que los profesionales que aparecen en el directorio de La Plataforma no
        son empleados ni tienen ningún tipo de vinculación contractual como prestadores de servicios
        con EasyMD, ni tienen sociedad, asociación, o figura jurídica análoga a las anteriores, por
        lo que no tienen obligación alguna para con dichos médicos o especialistas de la salud, de
        carácter laboral, fiscal, civil o de cualquier otra naturaleza.
      </p>

      <h4 className='underline uppercase'>
        <strong>12. Legislación aplicable.</strong>
      </h4>
      <p className='text-justify'>
        Para la interpretación y cumplimiento de los presentes TERMINOS Y CONDICIONES, las partes se
        someten expresamente a las Leyes y Tribunales competentes en la ciudad de Managua, República
        de Nicaragua.
      </p>
    </>
  );
};

export default ContendTermsAndConditions;
