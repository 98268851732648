import React from "react";
import { Spin } from "antd";

const LoadingFirts = () => {
  return (
    <div className='h-screen flex flex-col items-center justify-center w-full'>
      <Spin spinning size='large' />
    </div>
  );
};

export default LoadingFirts;
