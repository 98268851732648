import React from "react";
import Logo from "assets/img/EasyMDLogo.svg";

export default () => (
  <img
    style={{
      width: "237.855px",
      height: "51.316px",
    }}
    src={Logo}
    alt='LogoEasy'
  />
);
