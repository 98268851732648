import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "../styles.module.scss";
import Avatar from "antd/lib/avatar";
import { Button, Card, Tooltip } from "antd";
import Spin from "antd/lib/spin";

import { getDataForCard } from "../utils";
import TimeLabel from "../timeLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileVideo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Popconfirm from "antd/lib/popconfirm";

const InfiniteScrollAppointments = ({
  total,
  data,
  loading,
  hasMoreCalc,
  handleLoadMore,
  takeAppointment,
  openModalDetail,
}) => {
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  return (
    <InfiniteScroll
      scrollableTarget='tabDoctor-panel-4'
      dataLength={total}
      next={() => handleLoadMore()}
      hasMore={hasMoreCalc}
      loader={<Spin spinning={loading} />}
      className='w-full px-2 md:pl-6 md:pr-2 flex flex-col gap-8 pb-8'
    >
      {data.map((value, index) => {
        const dataForCard = getDataForCard(value);
        const isDescriptionExpanded = expandedDescriptions[dataForCard.description];
        return (
          <Card key={index} bodyStyle={{ padding: "0" }} className='md:ml-1'>
            <div
              className={
                "w-full h-auto rounded-lg p-3 flex xs:flex-col lg:flex-row " + styles.cardPending
              }
            >
              <div
                className={
                  "xs:w-2/3 lg:w-1/5 flex justify-items-end self-center " + styles.avatarUser
                }
              >
                <Avatar size={64} icon={<img alt='Image_avatar' src={dataForCard.picture} />} />
              </div>
              <div className='flex flex-wrap h-full xs:gap-2 w-full lg:my-auto'>
                <div className='w-full self-center lg:w-4/12 h-full lg:pl-10 xl:pl-10 flex flex-col xs:justify-items-center xs:items-center lg:justify-items-start lg:items-start'>
                  <p className='font-semibold mb-1 '>{dataForCard.name}</p>
                  <TimeLabel time={dataForCard.time} />
                </div>
                <div className={"flex flex-col w-full lg:w-4/12 " + styles.cardReason}>
                <p className={`overflow-hidden ${isDescriptionExpanded ? "max-h-auto" : "max-h-28"} ${isDescriptionExpanded ? "line-clamp-none" : "line-clamp-4"}`}>
                {dataForCard.description}
                  </p>
                  <Tooltip title='Ver detalles de la consulta'>
                    <Button
                      type='default'
                      shape='round'
                      size='small'
                      className='button-info shadow-none xs:mx-auto lg:ml-0 lg:mt-3'
                      onClick={() => openModalDetail(dataForCard.id)}
                    >
                      <FontAwesomeIcon className='mr-2' icon={faInfoCircle} /> Detalles
                    </Button>
                  </Tooltip>
                </div>
                <div className='flex flex-col w-full lg:w-3/12'>
                  <Popconfirm
                    title='¿ Asignar esta cita a mi perfil ?'
                    onConfirm={() => takeAppointment(dataForCard.id)}
                    okText='Si'
                    cancelText='No'
                    disabled={loading}
                  >
                    <Button
                      type='default'
                      shape='round'
                      disabled={loading}
                      className='mx-auto my-auto button-success xs:mb-5 lg:mb-auto'
                    >
                      <FontAwesomeIcon icon={faFileVideo} className='mr-2' />
                      Asignar
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          </Card>
        );
      })}
    </InfiniteScroll>
  );
};

export default InfiniteScrollAppointments;
