import React from "react";

// components
import Canvas from "views/Admin/Plans/Plan/Canvas";

// styles
import styles from "../style.module.scss";

// ant
import Tooltip from "antd/lib/tooltip";

// import DeletePlanButton from './DeletePlanButton'

export default function Plan(plan) {
  // const { id } = plan

  const props = {
    span: {
      // style: { marginBottom: '-20px' },
      children: plan.name,
      className: `${styles.editable} cursor-pointer`,
    },
    Tooltip: {
      title: "Clic para editar el plan ",
    },
  };

  return (
    <div
      className={`flex items-center justify-center ${styles.planContainer}`}
      style={{ position: "relative", left: "16px" }}
    >
      <Canvas info={plan}>
        <Tooltip {...props.Tooltip}>
          <span {...props.span} />
        </Tooltip>
      </Canvas>
      {/* <DeletePlanButton id={id} /> */}
    </div>
  );
}
