import React, { useEffect, useState, useContext } from "react";
import { Rate, Input } from "antd";
import DoctorList from "./doctorsList";
import DoctorSearchBar from "../../../components/DoctorSearchBar";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as actionName from "./store/actions";
import LoadingLottie from "../../../components/LoadingLottie";

import { EncuentraContext } from "../encuentra";

import Lottie from "react-lottie";
import doctorNotFound from "../../../assets/lottie/doctor_not_found.json";

import { isPlainObject } from "lodash";

const doctorsGql = gql`
  query doctors($perPage: Float, $page: Float, $filters: [doctorFilter!]) {
    doctors(
      perPage: $perPage
      page: $page
      sort: [{ field: RATING, direction: DESCENDING }, { field: CREATEDAT, direction: ASCENDING }]
      filters: $filters
    ) {
      docs {
        specialties {
          id
          name
        }
        id
        pictureURL {
          url
        }
        rating
        fullName
        specialties {
          id
          name
        }
        amountOfReviews
        rating
      }
      total
      pages
    }
  }
`;

const Encuentra = () => {
  const { state, dispatch } = useContext(EncuentraContext);
  const { loading, error, data, refetch } = useQuery(doctorsGql, {
    variables: {
      perPage: state.perPage,
      page: state.page,
      filters: state.filter,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  const [firstLoading, setFirstLoading] = useState(true);

  const defaultOptionsAnimation = {
    loop: true,
    autoplay: true,
    animationData: doctorNotFound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (data && !loading) {
      setFirstLoading(false);
      dispatch({
        type: actionName.SET_DATA_QUERY,
        payload: data.doctors,
      });
    } else if (!loading && !error) {
      setFirstLoading(false);
      dispatch({
        type: actionName.SET_EMPTY_DOCTORS,
      });
    }
  }, [data, loading, error, dispatch]);

  const botonFiltrar = () => {
    const newFilter = [
      {
        byText: {
          field: "APPROVALSTATUS",
          value: "approved",
        },
      },
    ];
    // improve
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(state.typeFilters)) {
      if (isPlainObject(value)) {
        newFilter.push(value);
      }
    }
    dispatch({
      type: actionName.SET_FILTER_FINAL,
      payload: newFilter,
    });
    refetch()
      .then((dataGG) => {})
      .catch((e) => {
        dispatch({
          type: actionName.SET_EMPTY_DOCTORS,
        });
      });
  };

  return (
    <>
      <div className='h-auto w-full bg-white shadow-lg border-solid border-t-2 md:px-16'>
        <div className='grid grid-cols-12 px-10 mt-4'>
          <div
            className='
          col-span-12
          my-3

          sm:col-span-12
          sm:my-3

          lg:col-span-4
          lg:my-0
          '
          >
            <Input
              onChange={(e) => {
                dispatch({
                  type: actionName.SET_NAME_DOCTOR,
                  payload: e.target.value,
                });
              }}
              className=' w-full lg:w-4/5 rounded-md'
              placeholder='Buscar por nombre del doctor...'
            />
          </div>
          <div
            className='

          col-span-12
          my-3

          sm:col-span-6
          sm:my-3

          lg:col-span-4
          lg:my-0
          '
          >
            <Rate
              onChange={(e) => {
                dispatch({
                  type: actionName.SET_PUNCTUATION,
                  payload: e,
                });
              }}
              allowHalf
              value={state.typeFilters.byStarts.byNumber.greaterOrEqualThan}
            />
            <span className='mx-2'>Puntuación minima</span>
          </div>
        </div>
        <DoctorSearchBar
          actionName={actionName}
          stateStore={state}
          dispatchStore={dispatch}
          botonFiltrar={botonFiltrar}
        />
      </div>
      <div className='m-8 xs:m-3 md:m-8 lg:mx-16 lg:my-5 xl:mx-32 h-auto'>
        <div className='w-full py-12'>
          {!firstLoading ? (
            data.doctors.docs.length === 0 ? (
              <div className='h-full flex'>
                <div className='mx-auto container'>
                  <div className='w-full'>
                    <Lottie
                      isClickToPauseDisabled
                      options={defaultOptionsAnimation}
                      height='auto'
                      width='50%'
                    />
                  </div>
                </div>
              </div>
            ) : (
              <DoctorList loading={loading} error={error} />
            )
          ) : (
            <div className='h-64 flex'>
              <div className='my-auto'>
                <LoadingLottie />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Encuentra;
