import React, { useState } from "react";
import Button from "antd/lib/button";

import PlanDescription from "./planDescription";
import PlanContact from "./planContact";
import RenderCheck from "./renderCheck";
import Typography from "antd/lib/typography";
import cn from "classnames";

const FullScreenComponent = (props) => {
  const [periodSelect, setPeriodSelect] = useState("MONTHLY");

  const isParNumber = (value) => {
    if (value % 2 === 0) {
      return true;
    } else {
      return false;
    }
  };

  const getClassGrid = () => {
    let defaultClass = "grid-cols-2";
    const lengthPlans = props.plans.length;
    if (lengthPlans > 1) {
      defaultClass = `grid-cols-${lengthPlans + 3}`;
    }
    return defaultClass;
  };

  return (
    <div
      className={cn("w-full flex flex-col", {
        "py-3": !props.modal,
        "pt-10 px-10 pb-0": props.modal,
      })}
    >
      <div
        className={`w-full ${
          props.modal
            ? "flex flex-col-reverse"
            : "grid ".concat(getClassGrid().concat(" ", "gap-0 grid-rows-1"))
        }`}
        style={{ minHeight: "120px" }}
      >
        <div className='col-span-2 flex flex-row items-center justify-center'>
          <Button
            style={{
              height: "55px",
              maxWidth: "55%",
            }}
            className={`border-none m-2 ${
              periodSelect === "MONTHLY" && "shadow-full text-white button-success"
            }`}
            type='default'
            shape='round'
            size='large'
            onClick={() => setPeriodSelect("MONTHLY")}
          >
            <strong>Mensual</strong>
          </Button>
          <Button
            className={`border-none m-2 ${
              periodSelect === "YEARLY" && "shadow-full text-white button-success"
            }`}
            style={{
              height: "55px",
              maxWidth: "55%",
            }}
            type='default'
            shape='round'
            size='large'
            onClick={() => setPeriodSelect("YEARLY")}
          >
            <strong>Anual</strong>
          </Button>
        </div>
        {props.modal ? (
          <div className='flex flex-row justify-around pb-8'>
            {props.plans
              .filter((p) => p.comprable === true)
              .map((value, index) => (
                <div key={index}>
                  <PlanDescription
                    modal={props.modal}
                    openModal={props.openModal}
                    periodSelect={periodSelect}
                    plan={value}
                    isMobileScreen={false}
                  />
                </div>
              ))}
          </div>
        ) : (
          props.plans
            .filter((p) => p.comprable === true)
            .map((value, index) => (
              <div key={index}>
                <PlanDescription
                  modal={props.modal}
                  openModal={props.openModal}
                  periodSelect={periodSelect}
                  plan={value}
                  isMobileScreen={false}
                />
              </div>
            ))
        )}

        {!props.modal && (
          <div className='mx-2'>
            <PlanContact />
          </div>
        )}
      </div>

      {/* FEATURES CHECK LOGIC */}
      <div className={`w-full grid mt-5 ${getClassGrid()} gap-0`}>
        {!props.modal &&
          props.features.map((value, index) => (
            <React.Fragment key={index}>
              <div
                className={`col-span-2 flex flex-col py-3 ${
                  isParNumber(index) ? "bg-gray-100" : "bg-white"
                }`}
                key={index}
              >
                <p className='ml-8 mb-0 text-xl block'>
                  <strong>{value.name}</strong>
                </p>
                <Typography className='ml-8' ellipsis={{ row: 2 }}>
                  {value.description}
                </Typography>
              </div>
              {props.plans.map((valuePlans, indexPlans) => (
                <RenderCheck
                  key={indexPlans}
                  idFeature={value.id}
                  arrayFind={valuePlans.features}
                  colorCol={isParNumber(index)}
                />
              ))}
              {/* Render for <PlanContact/> column, render check ALL */}
              <RenderCheck colorCol={isParNumber(index)} />
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default React.memo(FullScreenComponent);
