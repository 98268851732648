import React, { useState } from "react";
// antd
import Button from "antd/lib/button";
// components
// icons
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import { Input, Modal, Form, notification } from "antd";
import { vastmindzToken } from "../gql";
import { useMutation } from "@apollo/react-hooks";
import { HandleError } from "utils.js/HandleError";

export default function Vastmindz() {
  const [variables, setVariables] = useState();
  const [fetch, { data, loading, error }] = useMutation(vastmindzToken, {
    variables,
    fetchPolicy: "no-cache",
  });

  const props = {
    icon: <Fa icon={faPlus} className='mr-2' />,
    type: "primary",
    ghost: true,
    children: "Vastmindz token",
    onClick: () => setVisible(true),
  };
  const [form] = Form.useForm();
  const [visible, setVisible] = useState();
  const onFinishForm = (values) => {
    setVariables(values);
    fetch()
      .then(() => {
        setVisible(false);
        notification.success({
          message: "Token creado",
        });
      })
      .catch((e) => {
        setVisible(false);
        notification.error({
          message: "Erro al crear token",
          description: HandleError(e),
        });
      });
  };
  return (
    <>
      <Button {...props} />
      <Modal
        title='Vastmindz Token'
        className='w-1/3'
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={form.submit}
      >
        <Form
          form={form}
          layout='horizontal'
          name='basic'
          initialValues={{ remember: true }}
          onFinish={(valueform) => onFinishForm(valueform)}
        >
          <label className='mt-2' htmlFor='rppg_token'>
            <strong>rppg_token</strong>
          </label>
          <Form.Item className='mt-2' label={null} name='rppg_token'>
            <Input id='rppg_token' size='large' className='rounded-lg' />
          </Form.Item>

          <label className='mt-2' htmlFor='descriptionItem'>
            <strong>socket_url</strong>
          </label>
          <Form.Item className='mt-2' label={null} name='socket_url'>
            <Input rows={3} id='socket_url' size='large' className='rounded-lg' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
