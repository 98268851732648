import React, { useState, useContext } from "react";
import { Spin, Button } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import ModalDoctor from "../../../components/ModalDoctor";
import DoctorCard from "../../../components/Cards/DoctorCard";
import LoadingLottie from "../../../components/LoadingLottie";
import { EncuentraContext } from "./index";
import * as actionName from "./store/actions";

import QueueAnim from "rc-queue-anim";

const ContainerCard = ({ loading, error }) => {
  const { user, state, dispatch } = useContext(EncuentraContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const { page, perPage, totalData, listDoctors: items } = state;

  const handleLoadMore = () => {
    if (!loading) {
      dispatch({
        type: actionName.SET_SCROLL_PAGE,
        payload: page + 1,
      });
    }
  };

  const setModalData = (imageUrl, name, id, punctuation, specialty, amountOfReviews) => {
    setDataModal({
      id,
      imageUrl,
      punctuation,
      nameDoctor: name,
      specialty: specialty,
      amountOfReviews,
    });
    setModalVisible(true);
  };

  const hasMoreCalc = () => {
    if (totalData / perPage > page) {
      return true;
    }
    return false;
  };

  if (loading && items.length === 0 && page === 1) {
    return (
      <div className='h-64 flex'>
        <div className='my-auto'>
          <LoadingLottie />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className='h-64 flex'>
        <div className='my-auto'>SERVER ERROR</div>
      </div>
    );
  }

  return (
    <>
      {modalVisible && (
        <ModalDoctor
          // cardLoading
          modalDoctorVisible={modalVisible}
          setModalDoctorVisible={setModalVisible}
          user={user}
          dataDoctor={{
            id: dataModal.id,
            imageUrl: dataModal.imageUrl,
            punctuation: dataModal.punctuation,
            nameDoctor: dataModal.nameDoctor,
            specialty: dataModal.specialty,
            amountOfReviews: dataModal.amountOfReviews,
          }}
        />
      )}

      {totalData > 25 ? (
        <div id='scrollableDiv'>
          <InfiniteScroll
            dataLength={items.length}
            next={() => handleLoadMore()}
            hasMore={hasMoreCalc()}
            loader={null}
          >
            <div style={{ minHeight: "600px" }} className='mb-5 p-2'>
              <RenderItemCard totalData={totalData} items={items} setModalData={setModalData} />
              {loading && (
                <div className='my-5'>
                  <LoadingScroll />
                  <p className='text-success text-center font-semibold'>
                    Cargando lista de doctores
                  </p>
                </div>
              )}
            </div>
          </InfiniteScroll>
        </div>
      ) : (
        <>
          <div style={{ minHeight: "600px" }}>
            <RenderItemCard totalData={totalData} items={items} setModalData={setModalData} />
          </div>
          {loading && <LoadingScroll />}
        </>
      )}

      {hasMoreCalc() && (
        <div className='w-full flex justify-center mt-12'>
          <Button
            loading={loading}
            onClick={() => handleLoadMore()}
            type='primary'
            shape='round'
            className='button-primary  '
            size='large'
          >
            MÀS RESULTADOS
          </Button>
        </div>
      )}
    </>
  );
};

const RenderItemCard = ({ items = [], setModalData, totalData }) => {
  return (
    <QueueAnim
      delay={150}
      duration={600}
      type={["right", "left"]}
      leaveReverse
      className={
        (totalData > 25 ? " pb-12 " : "pb-3") +
        " grid row-gap-8 col-gap-4 grid-cols-1 xs:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 "
      }
    >
      {items.map((item, index) => (
        <DoctorCard
          key={index}
          id={item.id}
          punctuation={item.rating}
          specialty={item.specialties}
          imageUrl={item.pictureURL ? item.pictureURL.url : null}
          name={item.fullName}
          amountOfReviews={item.amountOfReviews}
          openModal={setModalData}
        />
      ))}
    </QueueAnim>
  );
};

const LoadingScroll = () => {
  return (
    <div className='w-full h-32 mt-5 flex justify-center'>
      <Spin className='my-auto' size='large' />
    </div>
  );
};

export default ContainerCard;
