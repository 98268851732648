import find from "lodash/find";
import get from "lodash/get";
export const traductionAprobalStatus = [
  {
    key: "DOCUMENTS_REQUIRED",
    db: "documentsRequired",
    table: "Documentos requeridos",
  },
  {
    key: "UNDER_REVIEW",
    db: "underReview",
    table: "Bajo revisión",
  },
  {
    key: "APPROVED",
    db: "approved",
    table: "Aprobado",
  },
  {
    key: "DENIED",
    db: "denied",
    table: "Denegado",
  },
];
export const getValueAprobalStatus = (key = "", getValue = "") => {
  return get(
    find(traductionAprobalStatus, function (o) {
      return o.key === key;
    }),
    getValue
  );
};
