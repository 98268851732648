import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
// antd
import Table from "antd/lib/table";
// helpers
import get from "lodash/get";
import keys from "lodash/keys";
import first from "lodash/first";

export default function TableFetch({ gql, ...attr }) {
  const [key, setKey] = useState("");
  const [variables, setVariables] = useState({});

  const { data, loading, error } = useQuery(gql, {
    variables,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!error && !loading && data && !key) {
      setKey(first(keys(data)) || "");
    }
  }, [data, error, loading, setKey, key]);

  const onChange = ({ current }, filters, sorter, extra) => {
    setVariables({ current });
  };

  const { dataSource, ...pagination } = get(data, key, {});

  const props = { ...attr, loading, pagination, dataSource, onChange };

  return <Table {...props} />;
}
