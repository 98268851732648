import React from "react";

export const currencyFormat = (num) => {
  if (!num) {
    return "$0.00";
  }
  return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const DrawMoney = ({ quantity }) => {
  const className = quantity < 0 ? "text-danger" : "text-success";
  return <span className={className}>{currencyFormat(quantity)}</span>;
};
