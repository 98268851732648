import React, { useState } from "react";
import ImgDefault from "../../../assets/img/doctorDefaulImg.png";
import Skeleton from "react-loading-skeleton";

import style from "./stylesCardDoctor.module.scss";

const AvatarDoctor = (props) => {
  const [loadinngImage, setLoadinngImage] = useState(true);
  const [errorloadinngImage, setErrorloadinngImage] = useState(false);
  return (
    <>
      {loadinngImage && (
        <div className={`mx-auto bg-cover ${props.className}`}>
          <Skeleton circle height='8rem' width='8rem' />
        </div>
      )}
      <img
        className={`mx-auto shadow-xl rounded-full object-contain ${style.imgCard} ${
          loadinngImage ? "hidden" : null
        }  ${props.className}`}
        src={!errorloadinngImage ? props.url : ImgDefault}
        alt=''
        onLoad={() => setLoadinngImage(false)}
        onError={() => {
          setLoadinngImage(false);
          setErrorloadinngImage(true);
        }}
      />
    </>
  );
};

AvatarDoctor.defaultProps = {
  className: "h-32 w-32",
  url: null,
};

export default AvatarDoctor;
