import { InMemoryCache } from "apollo-boost";
import { ApolloClient } from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { ApolloLink, Observable } from "apollo-link";
import { onError } from "apollo-link-error";
import Token from "./token";
import { getNiceString } from "./utils";
import { URI } from "../utils.js/constants";
import { concat } from "lodash";

const httpLink = createUploadLink({
  uri: URI,
});

const WHITE_LINKS = ["login", "signup"];

function getCSFT(request) {
  const niceString = request.operationName ? getNiceString(request.operationName) : null;
  return {
    nonce: niceString ? niceString.e.toString() : new Date().toLocaleString(),
    boli: niceString ? niceString.iv.toString() : new Date().toLocaleString(),
  };
}

const errorLink = onError(({ graphQLErrors, operation, forward, response }) => {
  let { operationName } = operation;
  if (operationName) {
    operationName = operationName.toLowerCase();
  }
  if (WHITE_LINKS.indexOf(operationName) > -1) {
    return forward(operation);
  } else if (graphQLErrors) {
    const errorMessage = graphQLErrors[0].extensions.code;
    // UNAUTHENTICATED
    if (errorMessage === "UNAUTHENTICATED") {
      // Let's refresh token through async request
      return new Observable((observer) => {
        Token.refreshToken()
          .then((refreshResponse) => {
            operation.setContext(({ headers = {} }) => ({
              headers: {
                // Re-add old headers
                ...headers,
                // Switch out old access token for new one
                authorization: `Bearer ${refreshResponse}` || null,
              },
            }));
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };
            // Retry last failed request
            forward(operation).subscribe(subscriber);
          })
          .catch((error) => {
            // EAS-1231
            client.resetStore();
            // No refresh or client token available, we force user to login
            observer.error(error);
          });
      });
    }
  }
});

const authLink = setContext(async (request, { headers }) => {
  let token = localStorage.getItem("token");
  if (token) {
    token = await Token.verifyToken(token);
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      ...getCSFT(request),
    },
  };
});

const apolloLink = concat(errorLink, concat(authLink, httpLink));
const link = ApolloLink.from(apolloLink);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
