import React from "react";
// antd
import { Input, Select, Radio } from "antd";
// component
import InputNumeric from "components/InputNumeric";
// helpers
import toString from "lodash/toString";
// icons
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export default {
  name: {
    name: "name",
    label: "Nombre",
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
    rules: [
      {
        required: true,
        message: "Requerido!",
      },
      {
        min: 4,
        message: "Nombre muy corto",
      },
    ],
    children: <Input placeholder='Nombre' />,
  },
  country: {
    name: "country",
    label: "Pais",
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
    rules: [
      {
        required: true,
        message: "Requerido!",
      },
    ],
    children: (
      <Select
        disabled={false}
        mode='single'
        allowClear
        showArrow
        bordered
        size='large'
        className='rounded'
        style={{ width: "100%" }}
        placeholder='Pais'
      >
        {["Nicaragua", "Panama"].map((option, i) => (
          <Select.Option key={i} value={option}>
            <span className='capitalize'>{option}</span>
          </Select.Option>
        ))}
      </Select>
    ),
  },
  description: {
    name: "description",
    label: "Descripción",
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
    children: <Input.TextArea rows={1} placeholder='Descripción' />,
  },
  periods: {
    name: {
      // name: 'name',
      label: "Nombre",
      rules: [
        {
          required: true,
          message: "Requerido!",
        },
      ],
      children: <Input placeholder='Nombre' />,
    },
    price: {
      // name: 'price',
      label: "Precio",
      rules: [
        {
          required: true,
          message: "Requerido!",
        },
      ],
      normalize: (n) => toString(n),
      children: <InputNumeric prefix='$' placeholder='0' />,
    },
    frequency: {
      // name: 'paymentFrequency',
      label: "Frecuencia",
      rules: [
        {
          required: true,
          message: "Requerido!",
        },
      ],
      style: { width: 225 },
      labelCol: { span: 9 },
      wrapperCol: { span: 15 },
      children: (
        <Select placeholder='Seleccionar'>
          <Select.Option value='YEARLY'>Anual</Select.Option>
          <Select.Option value='MONTHLY'>Mensual</Select.Option>
        </Select>
      ),
    },
    remove: {
      type: "link",
      icon: <Fa icon={faTrashAlt} />,
      danger: true,
    },
    add: {
      type: "primary",
      icon: <Fa icon={faPlus} className='mr-2' />,
      ghost: true,
      block: true,
      children: "Agregar Período",
    },
  },
  // disabled: {
  //   name: 'disabled',
  //   label: 'Estado',
  //   labelCol: { span: 5 },
  //   wrapperCol: { span: 19 },
  //   children: (
  //     <Radio.Group buttonStyle='solid'>
  //       <Radio.Button value={false}>Disponible</Radio.Button>
  //       <Radio.Button value>Deshabilitado</Radio.Button>
  //     </Radio.Group>
  //   )
  // },
  comprable: {
    key: "comprable",
    name: "comprable",
    label: "Es comprable",
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
    children: (
      <Radio.Group buttonStyle='solid'>
        <Radio.Button value>si</Radio.Button>
        <Radio.Button value={false}>no</Radio.Button>
      </Radio.Group>
    ),
  },
};
