const initialState = {
  modalOpenInfo: false,
  modalOpenDelete: false,
  modalNewUser: false,
  modalNewRole: false,
  modalEditUser: false,
  refetch: () => {},
  searchName: "",
  paginationVars: {
    perPage: 10,
    page: 1,
  },
  filterUsers: [],
  roles: [],
  user: { loading: false, error: "" },
  role: { loading: false, error: "" },
};

export default initialState;
