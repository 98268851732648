import React from "react";
// antd
import Button from "antd/lib/button";
// components
import AddBeneficiary from "components/Person/AddBeneficiary";
// icons
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as AF } from "@fortawesome/react-fontawesome";

export default function BtnAdd({ refetch, person }) {
  const props = {
    Dialog: {
      onSuccess: refetch,
    },
    Btn: {
      type: "dashed",
      icon: <AF icon={faPlus} />,
      block: true,
      children: <span className='ml-3'>Agregar Beneficiarios</span>,
      className: "bg-transparent text-success border-primary",
    },
  };

  return (
    <AddBeneficiary>
      <Button {...props.Btn} />
    </AddBeneficiary>
  );
}
