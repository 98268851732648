import React, { useContext } from "react";
// antd
import Modal from "antd/lib/modal";
// components
import DetailsDoctor from "components/BodyDetailsDoctor";
// context
import Context from "./Context";

export default function Dialog() {
  const { id, setVisible, visible } = useContext(Context);

  const props = {
    visible,
    title: "Información del Médico.",
    footer: null,
    centered: true,
    onCancel: () => setVisible(!visible),
    children: <DetailsDoctor id={id} />,
  };

  return <Modal {...props} />;
}
