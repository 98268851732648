import React, { useContext } from "react";
import { Tag, Button, Card, Tooltip } from "antd";
import { faBook, faPhone, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AvatarCard from "./avatarCard";
import { EncuentraContext } from "../../../views/microSalud/encuentra";

import styles from "./stylesCardDoctor.module.scss";

const DoctorCard = (props) => {
  return (
    <Card
      bodyStyle={{ padding: 0, height: "100%" }}
      className='p-0 rounded-md hover:border-solid hover:border-primary hover:shadow-xl hover:border-opacity-75 shadow-lg'
    >
      <div className='w-full flex h-full'>
        <div className='w-full absolute mt-2 lg:mt-5'>
          <div className='xs:w-3/12 md:w-4/12 lg:w-2/7 h-8 book-mark flex items-center justify-end'>
            <FontAwesomeIcon style={{ color: "#FFE600" }} size='lg' icon={faStar} />
            <span style={{ color: "#FFE600" }} className='mx-2'>
              <strong>{props.punctuation}</strong>
            </span>
          </div>
        </div>

        <div
          className='absolute left-0 right-0 pt-3 cursor-pointer'
          onClick={() =>
            props.openModal(
              props.imageUrl,
              props.name,
              props.id,
              props.punctuation,
              props.specialty,
              props.amountOfReviews
            )
          }
        >
          <AvatarCard url={props.imageUrl} className='h-32 w-32 bg-white' />
        </div>
        <div className='mt-32 w-full px-3 pb-3 flex flex-col'>
          <h4 className='text-center mt-3 text-xl'>
            <strong className='text-base'>{props.name}</strong>
          </h4>
          <div className='text-center  grid-flow-col-dense'>
            <TagSpecialty specialty={props.specialty} />
          </div>

          <div className='mt-auto rounded-md'>
            <Button
              style={{ color: "#B5BEC6", borderColor: "#E2E2E2" }}
              className='bg-primary hover:bg-primary-lighter h-12 md:p-0 w-6/12 inline-block rounded-l-lg  border-l-0 border-t-0  border-b-0 border-r-0 text-white'
              size='middle'
              icon={<FontAwesomeIcon className='mr-2 md:mx-2' size='lg' icon={faBook} />}
              onClick={() =>
                props.openModal(
                  props.imageUrl,
                  props.name,
                  props.id,
                  props.punctuation,
                  props.specialty,
                  props.amountOfReviews
                )
              }
            >
              <strong>Agendar</strong>
            </Button>
            <Button
              disabled
              style={{ color: "#B9C2D0", borderColor: "#E2E2E2" }}
              className='bg-gray-300 h-12 md:p-0 w-6/12 inline-block rounded-r-lg  border-l-2 border-t-0 border-b-0 border-r-0 text-white'
              size='middle'
              icon={<FontAwesomeIcon className='mr-2 md:mx-2' size='lg' icon={faPhone} />}
            >
              <strong>Llamar</strong>
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};

const TagSpecialty = (props) => {
  const { state } = useContext(EncuentraContext);
  if (props.specialty.length === 1) {
    return (
      <div className='flex items-center my-4'>
        <Tag
          style={{ backgroundColor: "#ECF1FF", display: "inline-table" }}
          className='my-2 mx-auto text-primary rounded-full text-base  h-auto py-2 flex-1 hover:text-primary'
        >
          <strong className={"text-base " + styles.tagSpec}>{props.specialty[0].name}</strong>
        </Tag>
      </div>
    );
  } else {
    let newSpecialty = props.specialty;

    if (state.typeFilters.bySpeciality && state.filter.length) {
      newSpecialty = props.specialty.sort((a, b) => {
        return a.id === state.typeFilters.bySpeciality.byId.value ? -1 : 1;
      });
    }

    return (
      <div className='my-4'>
        <Tag
          style={{ backgroundColor: "#ECF1FF" }}
          className='my-2 text-primary rounded-full text-base w-auto h-auto py-2 flex-1 hover:text-primary'
        >
          <strong className={"text-base " + styles.tagSpec}>{newSpecialty[0].name}</strong>
        </Tag>
        <Tooltip
          placement='topLeft'
          title={
            <div>
              <p className='pl-2'>Lista de Especialidades</p>
              <ul className='text-left pl-3 pt-0'>
                {newSpecialty.map(
                  (value, index) => index !== 0 && <li key={value.id}>{value.name}</li>
                )}
              </ul>
            </div>
          }
        >
          <Tag
            style={{ backgroundColor: "#ECF1FF" }}
            className='my-2 text-primary rounded-full text-base w-auto h-auto py-2 flex-1 hover:text-primary'
          >
            <strong className={"text-base " + styles.tagSpec}>
              + {props.specialty.length - 1}
            </strong>
          </Tag>
        </Tooltip>
      </div>
    );
  }
};

export default DoctorCard;
