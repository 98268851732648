import React, { useEffect, useContext, useCallback } from "react";
import Loading from "./loading";
import { GlobalContext } from "../../App";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import { Card, Alert, Button } from "antd";
import { faUserMd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useHistory } from "react-router-dom";

import GetError from "./getError";

import moment from "moment";

require("moment/min/locales.min");
moment.locale("es");

const Index = (props) => {
  const history = useHistory();
  const { state } = useContext(GlobalContext);
  const { user } = state;
  const { citaInfo } = props;
  const createAppointmentGql = gql`
    mutation createAppointment(
      $patient: String!
      $doctor: String!
      $appointmentDateAndTime: DateTime!
    ) {
      createAppointment(
        patient: $patient
        doctor: $doctor
        appointmentDateAndTime: $appointmentDateAndTime
      ) {
        id
      }
    }
  `;
  const [fetchCreateAppointment, { loading, error, data }] = useMutation(createAppointmentGql, {
    fetchPolicy: "no-cache",
  });

  const onMutateCreateAppointment = useCallback(async () => {
    try {
      await fetchCreateAppointment({
        variables: {
          patient: user ? user.id : "null",
          doctor: citaInfo ? citaInfo.idDoctor : "null",
          appointmentDateAndTime: moment(citaInfo.date, "YYYY-MM-DD hh:mm").toISOString(),
        },
      });
    } catch (e) {
      console.log("e");
    }
  }, [citaInfo, user, fetchCreateAppointment]);

  useEffect(() => {
    onMutateCreateAppointment();
  }, [onMutateCreateAppointment]);

  useEffect(() => {
    if (!loading) {
      if (data) {
        const { createAppointment } = data;
        const { id } = createAppointment;
        history.push("/md/appointment/checkout/" + id);
      }
    }
  }, [loading, error, data, history]);

  if (error && !loading) {
    return (
      <div className='w-full xs:h-32 md:min-h-25rem flex flex-col justify-center items-center'>
        <Card
          className='xs:w-2/3 md:w-2/3 lg:w-1/3 bg-transparent'
          bodyStyle={{ padding: 0, backgroundColor: "transparent" }}
          style={{ marginTop: "-5%" }}
        >
          <Alert
            message='Error al solicitar la cita'
            description={<GetError date={citaInfo.date} error={error} />}
            type='error'
            className='rounded-md rounded-b-none border-none'
            showIcon
          />
          <p className='m-0 text-center'>
            <Button
              onClick={() => history.push("/md/encuentra/especialistas")}
              type='default'
              shape='round'
              size='large'
              danger
              className='button-success my-2'
            >
              <FontAwesomeIcon icon={faUserMd} className='mr-2' />
              Ir a Buscar Médico
            </Button>
          </p>
        </Card>
      </div>
    );
  }

  return <Loading />;
};

export default Index;
