import React from "react";
import { Avatar, Empty, Tag } from "antd";
import styles from "./styles.module.scss";
import { getValueAprobalStatus } from "../../views/Admin/Doctores/utils";

const BodyDetailsMD = ({ data }) => {
  const newData = {
    ...data,
    doctormdById: {
      ...data.doctormdById,
      specialties: data.doctormdById.specialties ? data.doctormdById.specialties : [],
      description: data.doctormdById.description ? data.doctormdById.description : "",
      minsaNumber: data.doctormdById.minsaNumber ? data.doctormdById.minsaNumber : "N/A",
      email: data.doctormdById.email ? data.doctormdById.email : "N/A",
      signature: data.doctormdById.signature ? data.doctormdById.signature : null,
      phoneNumber: data.doctormdById.phoneNumber ? data.doctormdById.phoneNumber : "N/A",
    },
  };
  return (
    <div className={styles.bodyModal}>
      <div className='flex xs:flex-col md:flex-row'>
        <div
          className='flex flex-col justify-center xs:mx-auto md:mx-0 xs:w-full md:w-1/4 '
          style={{ maxWidth: "150px" }}
        >
          <Avatar
            className='mx-auto mb-2'
            shape='square'
            size={64}
            src={newData.doctormdById.pictureURL.url}
          />
          <p className='font-semibold mx-auto mb-2'>{newData.doctormdById.fullName}</p>
        </div>
        <div className='ml-5 xs:w-full md:w-3/4'>
          <p className='mb-0 text-primary font-semibold'>Estado</p>
          <p className='mb-2 text-black'>
            {getValueAprobalStatus(newData.doctormdById.approvalStatus, "table")}
          </p>
          <p className='mb-0 text-primary font-semibold'># MINSA</p>
          <p className='mb-2 text-black'>{newData.doctormdById.minsaNumber}</p>
          <p className='mb-0 text-primary font-semibold'>Email</p>
          <p className='mb-2 text-black'>{newData.doctormdById.email}</p>
          <p className='mb-0 text-primary font-semibold'>Teléfono </p>
          <p className='mb-2 text-black'>{newData.doctormdById.phoneNumber}</p>
        </div>
      </div>
      <div className='xs:ml-5 md:ml-0 xs:mt-0 md:mt-3 flex flex-wrap '>
        <div className='xs:w-full md:w-1/2'>
          <p className='mb-0 text-primary font-semibold'>Descripción</p>
          <p className='mb-3 text-black'>
            {newData.doctormdById.description.length < 1 ? (
              <Empty description={false} />
            ) : (
              newData.doctormdById.description
            )}
          </p>
        </div>
        <div className='xs:w-full md:w-1/2'>
          <p className='mb-0 text-primary font-semibold'>Especialidades</p>
          {newData.doctormdById.specialties.length < 1 ? (
            <Empty description={false} />
          ) : (
            newData.doctormdById.specialties.map((value, index) => {
              return (
                <Tag
                  key={index}
                  style={{ backgroundColor: "#ECF1FF" }}
                  title={value.name}
                  className='my-1 text-black-hover rounded-full text-base h-auto py-1 hover:text-primary truncate'
                >
                  <strong className='text-xs'>{value.name}</strong>
                </Tag>
              );
            })
          )}
        </div>
      </div>
      {newData.doctormdById.signature && (
        <div className='xs:ml-5 md:ml-0 xs:mt-0 md:mt-3 flex flex-row py-3 justify-center content-center'>
          <img
            alt='imageDoctor'
            className={styles.imgSignature}
            src={newData.doctormdById.signature.url}
          />
        </div>
      )}
    </div>
  );
};

export default BodyDetailsMD;
