import React, { useEffect, useContext, useReducer } from "react";
// antd
import Form from "antd/lib/form";
import Space from "antd/lib/space";
import Button from "antd/lib/button";
import Divider from "antd/lib/divider";
// inputs
import input from "./inputs";
// helpers
import size from "lodash/size";
import PlanContext from "../Context";

export const types = {
  INITIAL_VALUES: "INITIAL_VALUES",
};

export const reducer = (state, action) => {
  const actions = {
    [types.INITIAL_VALUES]: { ...state, ...action.value },
  };
  return actions[action.type] || { ...state };
};

export default function FormPlan(props) {
  const { country } = useContext(PlanContext);

  const [initialValues, dispatch] = useReducer(reducer, {
    ...(!size(props.initialValues) ? { country } : props.initialValues),
  });
  const setInitialValues = (values) => dispatch({ type: types.INITIAL_VALUES, value: values });

  useEffect(() => {
    size(props.initialValues) < 2 &&
      setInitialValues({
        country: country,
      });
  }, [props.initialValues, country]);

  const formProps = {
    ...props,
    initialValues,
  };

  return (
    <Form {...formProps}>
      <Form.Item {...input.country} />
      <Form.Item {...input.name} />
      {!!size(props.initialValues) && (
        <>
          <Form.Item {...input.description} />
          {
            // <Form.Item {...input.disabled} />
          }
          <Form.Item {...input.comprable} />
          <Form.List name='periods'>
            {(fields, { add, remove }) =>
              setTimeout(() => {
                if (!fields.length) add();
              }, 1) && (
                <>
                  <Divider dashed orientation='left' plain>
                    Períodos
                  </Divider>
                  {fields.map((field) => (
                    <Space key={field.key} align='start' size={10}>
                      {/* <Form.Item */}
                      {/*  name={[field.name, 'name']} */}
                      {/*  fieldKey={[field.fieldKey, 'name']} */}
                      {/*  {...input.periods.name} */}
                      {/* /> */}

                      <Form.Item
                        name={[field.name, "paymentFrequency"]}
                        fieldKey={[field.fieldKey, "paymentFrequency"]}
                        {...input.periods.frequency}
                      />

                      <Form.Item
                        name={[field.name, "price"]}
                        fieldKey={[field.fieldKey, "price"]}
                        {...input.periods.price}
                      />

                      <Button {...input.periods.remove} onClick={() => remove(field.name)} />
                    </Space>
                  ))}
                  <Button {...input.periods.add} onClick={() => add()} />
                </>
              )
            }
          </Form.List>
        </>
      )}
    </Form>
  );
}
