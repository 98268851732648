import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Divider, notification, Spin, Switch } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { updateCompanyInfo } from "../gqlTags";
import validatorRules from "./validatorRules";

import get from "lodash/get";

import { ContextCompanyCodes } from "../index";

import style from "../styles.module.scss";
import { HandleError } from "../../../../utils.js/HandleError";

const EditInfo = () => {
  const [form] = Form.useForm();
  const {
    state: { loading, data, id },
  } = useContext(ContextCompanyCodes);
  const [whiteLabel, setWhiteLabel] = useState(data.whiteLabel);

  const [fetchEditInfo, { loading: loadingMutation, error: errorMutation, data: dataMutation }] =
    useMutation(updateCompanyInfo, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      refetchQueries: ["companyById"],
    });

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById("rowFormDetails");
      if (element) {
        element.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    }, 700);
  }, []);

  useEffect(() => {
    if (errorMutation) {
      notification.error({
        message: "Error",
        description: HandleError(errorMutation),
      });
    }
  }, [errorMutation]);

  useEffect(() => {
    if (!loadingMutation && dataMutation) {
      notification.success({
        message: "Datos de la empresa actualizados",
      });
    }
  }, [loadingMutation, dataMutation]);

  const onFinishForm = async (values) => {
    try {
      await fetchEditInfo({
        variables: {
          id,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber === "" ? null : String(values.phoneNumber),
          description: values.description,
          contactName: values.contactName,
          contactEmail: values.contactEmail,
          contactPhoneNumber:
            values.contactPhoneNumber !== "" ? String(values.contactPhoneNumber) : null,
          whiteLabel,
        },
      });
    } catch (e) {
      notification.error({
        message: "Internal server error ",
        description: HandleError(e),
      });
    }
  };

  return (
    <Spin spinning={loadingMutation || loading}>
      <div className='w-full mx-auto flex flex-col h-full'>
        <div className='flex flex-row'>
          <p className='mr-auto font-bold text-lg mb-3'>Detalles</p>
          <div className='flex flex-row'>
            <p className='mr-1 font-bold text-base'>Whitelabel</p>
            <Switch checked={whiteLabel} onChange={() => setWhiteLabel(!whiteLabel)} />
          </div>
        </div>
        <Form
          form={form}
          layout='vertical'
          initialValues={data}
          className='flex flex-col h-full'
          scrollToFirstError
          onFinish={onFinishForm}
        >
          <Row
            id='rowFormDetails'
            gutter={[16, 16]}
            className={"md:pr-1 md:pl-3 " + style.formDetails}
          >
            <Col xs={24}>
              <Divider className='m-0 text-primary font-semibold' orientation='left' plain>
                Empresa
              </Divider>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                className='mb-0'
                name='firstName'
                label='Nombre'
                rules={[validatorRules.required]}
              >
                <Input placeholder='Nombre de la empresa' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                className='mb-0'
                name='lastName'
                label='Sufijo'
                rules={[validatorRules.required]}
              >
                <Input placeholder='Sufijo de la empresa' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label='Teléfono'
                className='mb-0'
                name='phoneNumber'
                rules={[validatorRules.required]}
              >
                <Input placeholder='Número de teléfono de la empresa' />
              </Form.Item>
            </Col>
            {
              //  <Col xs={24} md={12}>
              //   <Form.Item
              //     label='Número de empleados'
              //     className='mb-0'
              //     name='userCap'
              //     rules={[validatorRules.number]}
              //   >
              //     <Input type='number' placeholder='Capacidad de empleados' />
              //   </Form.Item>
              // </Col>
            }
            {/*

              <Col xs={24} md={12}>
              <Form.Item
                label='Email'
                className='mb-0'
                name='email'
                rules={[validatorRules.required, validatorRules.email]}
              >
                <Input disabled type='email' placeholder='Correo electrónico de la empresa (username)' />
              </Form.Item>
            </Col>
               */}
            <Col xs={24} md={24}>
              <Form.Item label='Descripción' className='mb-0' name='description'>
                <Input.TextArea rows={2} placeholder='Descripción de la empresa' />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Divider className='m-0 text-primary font-semibold ' orientation='left' plain>
                Contacto
              </Divider>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label='Nombre de contacto' className='mb-0' name='contactName'>
                <Input placeholder='Nombre completo del contacto' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label='Teléfono de contacto'
                className='mb-0'
                name='contactPhoneNumber'
                rules={[validatorRules.required]}
              >
                <Input placeholder='Número de teléfono del contacto' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label='Email de contacto'
                className='mb-5'
                name='contactEmail'
                rules={[validatorRules.email]}
              >
                <Input type='email' placeholder='Correo electrónico del contacto' />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item className='mt-auto mb-1 mx-auto'>
            <Button
              disabled={loading || loadingMutation}
              type='primary'
              htmlType='submit'
              size='large'
              className='button-success'
            >
              Guardar cambios
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default EditInfo;
