import React, { useState, useEffect } from "react";
import { Form, Button, Popconfirm, Divider } from "antd";
import FormInfo from "./FormInfo";

export default function ModalEditInfo({
  phoneNumber,
  email,
  idUser,
  plans,
  name,
  confirmChange,
  planslist,
  company,
  companylist,
}) {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({
    email: null,
    phoneNumber: null,
    plan: null,
    company: null,
  });
  const [variables, setVariables] = useState();

  useEffect(() => {
    setInitialValues({
      phoneNumber,
      email,
      plan: plans.at(-1) || null,
      company,
    });
  }, [phoneNumber, email, plans, company]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues, form]);

  const valuesChangeHandler = (values) => {
    initialValues.email === values.email && delete values.email;
    initialValues.phoneNumber === values.phoneNumber
      ? delete values.phoneNumber
      : (values.phoneNumber = String(values.phoneNumber));
    if (initialValues.plan === values.plan || values.plan === undefined) {
      delete values.plan;
    }
    if (
      [initialValues.company?.id, initialValues.company?.fullName].includes(values.belongsToCompany)
    ) {
      delete values.belongsToCompany;
    }
    setVariables(values);
  };

  const props = {
    Form: {
      form,
      onFinish: valuesChangeHandler,
      initialValues,
      onBlur: form.submit,
      planslist,
      companylist,
    },
  };

  return (
    <>
      <div className='flex flex-col justify-center lg:flex-row lg:justify-between mt-5 w-full'>
        <div className='flex flex-col mr-2 w-11/12'>
          <div className='flex flex-row mb-5'>
            <div className='text-primary font-semibold text-2xl'>{name}</div>{" "}
          </div>{" "}
          <FormInfo {...props.Form} />
          <Popconfirm
            title={`¿Está seguro que desea cambiar la informacion de ${name}?`}
            onConfirm={() => confirmChange(idUser, variables)}
            okText='Si'
            cancelText='No'
          >
            <Button style={{ transform: "translateY(175%)", width: "25%" }} type='default' danger>
              Guardar
            </Button>
          </Popconfirm>
        </div>
      </div>
    </>
  );
}
