import React, { useContext } from "react";
// components
import User from "components/User";
// context
import { GlobalContext } from "views/App";

function TopBar() {
  const { state } = useContext(GlobalContext);

  const props = {
    User: {
      ...state.user,
      size: 38,
    },
  };

  return (
    <div className='flex justify-between text-white'>
      <span />
      <User {...props.User} />
    </div>
  );
}

export default TopBar;
