import React, { useState, useRef, useEffect } from "react";

export default function InputNumber(props) {
  const {
    value: initialValue,
    prefix,
    length,
    placeholder,
    form,
    name,
    icon,
    size,
    ...restProps
  } = props;
  const inputRef = useRef();
  const prefixed = prefix || null;
  const [value, changeValue] = useState(
    prefixed ? prefix + initialValue : initialValue ? initialValue : null
  );

  useEffect(() => {
    if (inputRef) inputRef.current.value = value;
    form &&
      form.setFieldsValue({
        [name]: value,
      });
  }, [value, form, name]);

  const keyPressHandler = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const keyDownHandler = (e) => {
    if (prefixed) {
      e.keyCode === 8 && e.target.value.length === 1 && e.preventDefault();
      e.keyCode === 46 && e.target.value.length === 1 && e.preventDefault();
    }
  };
  const changeHandler = (e) => {
    const input = e.target.value;
    if (prefixed) {
      e.target.value = prefixed + input.substr(prefixed.length);
      input.length > 1 && changeValue(prefixed + input.substr(prefixed.length));
    } else input.length && changeValue(input);
  };
  const blurHandler = (e) => {
    let value = e.target.value;
    if (value.length <= 1) {
      if (prefixed) e.target.value = prefixed + value;
      else e.target.value = value;
    }
    value.length && changeValue(value === "" ? null : Number(value));
  };

  const style = {
    ...(icon && { paddingLeft: "27px" }),
    ...(size === "large" && { minHeight: "40px" }),
  };

  const inputProps = {
    ref: inputRef,
    placeholder: placeholder,
    className: "w-full ant-input-affix-wrapper",
    maxLength: length,
    onKeyPress: (e) => keyPressHandler(e),
    onKeyDown: (e) => keyDownHandler(e),
    onChange: (e) => changeHandler(e),
    onBlur: (e) => blurHandler(e),
    style,
    ...restProps,
  };

  return (
    <>
      <input {...inputProps} />
      {icon && <div style={{ width: "20px", transform: "translate(10px, -27px)" }}>{icon}</div>}
    </>
  );
}
