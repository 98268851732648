import React, { Fragment, cloneElement, useState } from "react";
// components
import Dialog from "./Dialog";

export default function ValidatePerson({ children, ...attr }) {
  const [visible, setVisible] = useState(false);
  const onClick = () => setVisible(!visible);

  const props = {
    modal: {
      visible,
      onCancel: onClick,
      ...attr,
    },
  };

  return (
    <Fragment>
      {cloneElement(children, { onClick })}
      <Dialog {...props.modal} />
    </Fragment>
  );
}
