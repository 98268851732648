import React, { useState } from "react";
import { BasicTable } from "components/TableEasyMD";
import { Card, notification } from "antd";
import { isNumber, isString } from "lodash";

import { drugs, deleteDrugById, drugById, updateDrug, createDrug } from "../gqlAdmin";
import { useQuery, useMutation } from "@apollo/react-hooks";

const Medicamentos = (props) => {
  const [variables, setVariables] = useState({
    perPage: 5,
    page: 1,
    filters: [],
  });

  const { data, loading, error } = useQuery(drugs, {
    variables: { ...variables },
    fetchPolicy: "no-cache",
  });
  const [deleteMedicine, reqDeleteMedicine] = useMutation(deleteDrugById, {
    refetchQueries: ["drugs"],
  });
  const [updateMedicine, reqUpdateMedicine] = useMutation(updateDrug, {
    refetchQueries: ["drugs"],
  });
  const [addMedicine, reqAddMedicine] = useMutation(createDrug, { refetchQueries: ["drugs"] });

  const changePage = (page) => {
    if (page && isNumber(page)) {
      setVariables({
        ...variables,
        page,
      });
    } else {
      notification.error({
        message: "Error",
        description: "Índice de página invalido",
      });
    }
  };

  const DeleteMedicine = (id) => {
    try {
      setVariables({
        perPage: 5,
        page: 1,
        filters: [],
      });
      return deleteMedicine({ variables: { id: id } });
    } catch (e) {
      console.log(e);
    }
  };

  const onSearch = (value) => {
    if (isString(value)) {
      setVariables({
        ...variables,
        filters: [
          {
            byText: { field: "NAME", value: value },
          },
        ],
      });
    }
  };

  const onEdit = (Item) => {
    try {
      const { idItem: id, descriptionItem: description, nameItem: name } = Item;
      return updateMedicine({ variables: { id, description, name } });
    } catch (e) {
      console.log(e);
    }
  };

  const onAddItem = (values) => {
    try {
      return addMedicine({ variables: { bulkNames: values } });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className='m-3 p-3 w-full mx-auto md:w-3/5'>
      <h2 className='text-2xl'>
        <strong>Medicamentos</strong>
      </h2>
      <div className='mt-5'>
        <Card bodyStyle={{ padding: "0.5rem" }} title={null}>
          <BasicTable
            erroraddItem={reqAddMedicine.error}
            onAddItem={onAddItem}
            EditItemSubmit={onEdit}
            reqUpdateItem={reqUpdateMedicine.error}
            gqlFindItem={drugById}
            errorDelete={reqDeleteMedicine.error}
            error={error}
            changePage={changePage}
            deleteItem={DeleteMedicine}
            loading={loading}
            data={data}
            plaseholdeAlertDelete='Seguro que quieres eliminar este Medicamento?'
            plaseholderSearch='Nombre del medicamento'
            onSearchTable={onSearch}
            path='drugs'
            pathId='drugById'
          />
        </Card>
      </div>
    </div>
  );
};

export default Medicamentos;
