import React from "react";
import styles from "./stylesPlanDescription.module.scss";
import Button from "antd/lib/button";

import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const planContact = () => {
  // EAS-1361
  // redirect to whatsApp is fixed
  const phoneNumber = "50578726684";
  const message = "";

  const handleWhatsAppRedirect = () => {
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };
  return (
    <div
      className={
        "my-3 w-full h-full flex flex-col hover:bg-primary hover:py-5 " + styles.SelectPlans
      }
    >
      <h4 className={"text-xl text-black text-center capitalize"} title='Plan Empresarial'>
        Plan Empresarial
      </h4>
      <div className='w-full overflow-scroll' style={{ minHeight: "50px", maxHeight: "50px" }}>
        <p className='text-sm text-center' />
      </div>
      <div className={`flex justify-center items-center py-5`}>
        <FontAwesomeIcon className='text-center mr-2 faIcons' size='5x' icon={faBuilding} />
      </div>
      <div className='flex justify-center items-center mb-5 mt-8'>
        {/* <a rel='stylesheet' href='mailto: admin@easymd.com'> */}
        <Button type='default' shape='round' size='large' onClick={handleWhatsAppRedirect}>
          Contáctanos
        </Button>
        {/* </a> */}
      </div>
    </div>
  );
};

export default React.memo(planContact);
