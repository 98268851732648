import React, { useContext } from "react";
import Button from "antd/lib/button";
import * as actionNames from "./store/actions";
import { ContextTableUsers } from "./index";
import RowAvatar from "components/RowAvatar";

const columns = [
  {
    title: "Nombre",
    dataIndex: "fullName",
    className: "p-1",
    render: (text, row) => (
      <RowAvatar
        text={text}
        row={row}
        description={
          <>
            <p className='text-left pb-1 mb-1'>{row.email}</p>
            <span className='text-left'>
              Teléfono: <strong>{row.phoneNumber || "N/A"}</strong>
            </span>
          </>
        }
      />
    ),
  },
  {
    title: "Rol",
    dataIndex: "role",
    className: "text-center w-5por",
    render: (text, row) => (
      <span className='capitalize'>{row.role.name === "root" ? "Admin" : row.role.name}</span>
    ),
  },
  {
    title: "Acciones",
    dataIndex: "custom_acciones",
    className: "text-center w-1/6",
    render: (row, fullRow) => <RenderActions row={row} fullRow={fullRow} />,
  },
];

const RenderActions = ({ fullRow }) => {
  const { dispatch } = useContext(ContextTableUsers);

  const openDelete = () => {
    dispatch({
      type: actionNames.SET_MODAL_DELETE_OPEN,
      payload: true,
    });
    dispatch({ type: actionNames.SET_USER, payload: { ...fullRow, delete: true } });
  };

  const openEdit = () => {
    dispatch({
      type: actionNames.SET_MODAL_EDIT_OPEN,
      payload: true,
    });
    dispatch({ type: actionNames.SET_USER, payload: { ...fullRow, edit: true } });
  };

  return (
    <div className='flex justify-center'>
      <Button
        className='button-danger mx-2'
        type='defualt'
        danger
        shape='round'
        size='small'
        onClick={() => openDelete()}
      >
        {" "}
        Eliminar
      </Button>
      <Button
        className='button-success mx-2'
        type='default'
        shape='round'
        size='small'
        onClick={() => openEdit()}
      >
        {" "}
        Editar
      </Button>
    </div>
  );
};

export default columns;
