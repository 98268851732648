export const getIdAppointment = (props) => {
  let info = {
    idAppointment: null,
    sessionID: null,
  };
  const { location, match } = props;
  if (location) {
    const { state } = location;
    if (state) {
      const { idAppointment, sessionID } = state;
      info = {
        idAppointment: idAppointment,
        sessionID: sessionID,
      };
    } else {
      if (match) {
        const { params } = match;
        if (params) {
          info = {
            idAppointment: params.id,
            sessionID: info ? (info.sessionID ? info.sessionID : null) : null,
          };
        }
      }
    }
  } else {
    if (match) {
      const { params } = match;
      if (params) {
        info = {
          idAppointment: params.id,
          sessionID: info ? (info.sessionID ? info.sessionID : null) : null,
        };
      }
    }
  }
  return info;
};

export const verifyIdUserVsDoctor = (idUser, idDoctor) => {
  return idUser === idDoctor;
};

export const verifyUsable = (status) => {
  /* PENDING
  SCHEDULED
  ONGOING
  COMPLETE
  CANCELED
  AVAILABLE */
  switch (status) {
    case "PENDING":
      return true;
    case "ONGOING":
      return true;
    default:
      return false;
  }
};

export const verifyFinish = (status) => {
  return status === "COMPLETE";
};

export const verifyRenderChat = (status) => {
  return status === "ONGOING";
};
