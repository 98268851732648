import React, { useContext } from "react";

import { Row, Col, Card } from "antd";

import VideoLlamadaPacienteMD from "./videoLlamadaPacienteMD";
import ChatPacienteMD from "./chatPacienteMD";
import CitaNoEncontrada from "../components/citaNoEncontrada";
import useValidatorCita from "./useValidatorCita";
import BrandLoading from "../../../components/CitasUtils/BrandLoading";
import InfoCita from "./infoCita";
import { CallChatContext } from "chatCall/store";
import { getUsername } from "xmpp";

const ContainerSala = ({ info }) => {
  const { idAppointment } = info;
  const {
    dataAppointment,
    isError,
    isFinish,
    isRenderChat,
    notFound,
    loadingBrand,
    infoForChat,
    refetchStatus,
  } = useValidatorCita(idAppointment);

  const { presences } = useContext(CallChatContext);

  if (
    presences[
      getUsername(dataAppointment?.patient?.openFireUsername, true, dataAppointment?.id)
    ] !== "online"
  )
    return <BrandLoading />;

  if (loadingBrand || !dataAppointment) {
    return <BrandLoading />;
  }

  if (notFound || isError) {
    return <CitaNoEncontrada />;
  }
  return (
    <div className='w-full p-5 h-screen mb-5'>
      <Row gutter={[10, 10]} className='h-screen'>
        <Col xs={24} md={24} lg={!isRenderChat ? 24 : 14} style={{ minHeight: "80vh" }}>
          <VideoLlamadaPacienteMD
            refetchStatus={refetchStatus}
            isFinish={isFinish}
            isRenderChat={isRenderChat}
            infoForChat={infoForChat}
            infoAppointment={dataAppointment}
          />
        </Col>
        {isRenderChat && dataAppointment?.doctor?.id && (
          <Col xs={24} md={24} lg={10}>
            <ChatPacienteMD
              id={idAppointment}
              infoForChat={infoForChat}
              infoAppointment={dataAppointment}
              isFinish={isFinish}
              sendFirstMessage={
                !dataAppointment.log
                  ? false
                  : dataAppointment.log.firstMessagePatient
                  ? false
                  : true
              }
            />
          </Col>
        )}
        {dataAppointment && (
          <Col xs={24} style={{ minHeight: "80px" }}>
            <Card className='w-full h-full'>
              <InfoCita infoAppointment={dataAppointment} />
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ContainerSala;
