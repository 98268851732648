import React, { useContext } from "react";
import QueueAnim from "rc-queue-anim";
// antd
import Card from "antd/lib/card";
// components
import CardCode from "views/Patient/Tabs/Affiliation/Code/Card";
import RenderMembership from "./RenderMembership";
// context
import { GlobalContext } from "views/App";

// styles
import style from "./style.module.scss";

const propsQueue = {
  key: "tabs",
  type: "bottom",
  className: style.Affiliation,
  component: "section",
};

export default function Affiliation() {
  const { state } = useContext(GlobalContext);
  const variables = { id: state.user.id };
  const props = {
    Code: {
      idUser: variables.id,
      key: "code",
      enabled: false,
    },
    Affiliation: {
      key: "A",
      title: "Afiliación Actual",
    },
  };

  return (
    <QueueAnim {...propsQueue}>
      <CardCode {...props.Code} />
      <Card {...props.Affiliation}>
        <RenderMembership />
      </Card>
    </QueueAnim>
  );
}
