import React from "react";
import { Card, Button, Select, Spin, Switch } from "antd";
import useDownloadCsv from "components/DownloadCsv/useDownloadCsv";
import SelectorRange from "components/SelectorRange";

function Reports() {
  const {
    downloadLink,
    loading,
    error,
    dateFilter,
    onReportChange,
    onDateChange,
    clickDateFilter,
  } = useDownloadCsv();
  const { Option } = Select;
  return (
    <div className='m-3 p-3 w-full mx-auto md:w-3/5'>
      <h2 className='text-2xl'>
        <strong>Reportes</strong>
      </h2>
      <div className='mt-5'>
        <Card bodyStyle={{ padding: "3rem" }} title={null}>
          <div className='flex flex-row'>
            <Select className='w-1/2 pr-2' defaultValue='patients' onChange={onReportChange}>
              <Option value='earnings'>Ingresos</Option>
              <Option value='transactionsReview'>Resumen Transacciones</Option>
              <Option value='transactions'>Transacciones</Option>
              <Option value='patients'>Pacientes</Option>
              <Option value='usersWithPlan'>Pacientes con Plan</Option>
              <Option value='usersWithOutPlan'>Pacientes sin Plan</Option>
              <Option value='appointmentsUsers'>Consultas de Pacientes</Option>
              <Option value='appointmentsUsersCompany'>Consultas de Pacientes de Compañía</Option>
              <Option value='allCompanyUsers'>Afiliados de Compañía</Option>
              <Option value='activeCompanyPlans'>Compañías Afiliadas</Option>
              <Option value='activePatientPlans'>Pacientes Afiliados</Option>
              <Option value='activePlans'>Afiliados</Option>
              <Option value='totalPlans'>Total Plans</Option>
              <Option value='totalAppointments'>Total Consultas</Option>
            </Select>
            <div className='w-1/3 mx-3 flex flex-row justify-start items-center'>
              <div style={{ transform: "rotate(-90deg)" }}>
                <Switch size='small' defaultChecked onChange={clickDateFilter} />
              </div>
              {!dateFilter ? (
                <div className='w-full flex items-center justify-center relative'>
                  <h5 className='absolute' style={{ top: "-10px" }}>
                    Coleccion completa
                  </h5>
                </div>
              ) : (
                <SelectorRange
                  current
                  disabledDates={false}
                  onChange={onDateChange}
                  loading={loading}
                  disabled={loading || error}
                />
              )}
            </div>
            <Button
              className='w-1/5 ml-4'
              type='primary'
              href={downloadLink}
              download={true}
              disabled={loading || error}
            >
              {loading ? <Spin size='small' /> : error ? "Error" : "Descargar"}
            </Button>{" "}
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Reports;
