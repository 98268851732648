import React, { useState, useEffect } from "react";
import BrandLoading from "../../../components/CitasUtils/BrandLoading";
import { Layout } from "antd";

import ContainerSala from "./containerSala";

import { getIdAppointment } from "./utils";
const { Content } = Layout;

const DoctorMD = (props) => {
  const [infoSession, setInfoSession] = useState(null);

  useEffect(() => {
    setInfoSession(getIdAppointment(props));
  }, [props, setInfoSession]);

  return (
    <Layout className='w-full'>
      <Content className='w-full h-auto'>
        {!infoSession ? <BrandLoading /> : <ContainerSala info={infoSession} />}
      </Content>
    </Layout>
  );
};

export default DoctorMD;
