import React, { useState } from "react";
// antd
import Form from "antd/lib/form";
import Space from "antd/lib/space";
// helpers
import size from "lodash/size";

export default function FormPrice(props) {
  const { initialValues } = props;
  const prefix = "$";
  const [price, changePrice] = useState(prefix + initialValues.appointmentPriceEasyMD);

  const keyPressHandler = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const keyDownHandler = (e) => {
    e.keyCode === 8 && e.target.value.length === 1 && e.preventDefault();
    e.keyCode === 46 && e.target.value.length === 1 && e.preventDefault();
  };
  const changeHandler = (e) => {
    const input = e.target.value;
    e.target.value = prefix + input.substr(prefix.length);
    input.length > 1 && changePrice(prefix + input.substr(prefix.length));
  };
  const blurHandler = (e) => {
    let value = e.target.value;
    if (value.length <= 1) {
      e.target.value = prefix + initialValues.appointmentPriceEasyMD;
    }
    value.length < 1 && changePrice(value);
  };

  const inputProps = {
    price: {
      name: "newPrice",
      label: "Precio EasyMD",
      rules: [
        {
          required: true,
          message: "Requerido!",
        },
      ],
      children: (
        <input
          className='w-1/4 ant-input-affix-wrapper'
          maxLength='4'
          onKeyPress={(e) => keyPressHandler(e)}
          onKeyDown={(e) => keyDownHandler(e)}
          onChange={(e) => changeHandler(e)}
          onBlur={(e) => blurHandler(e)}
        />
      ),
    },
  };

  return (
    <Form {...props} initialValues={{ newPrice: price }}>
      {!!size(initialValues) && (
        <>
          <h4>Doctor: {initialValues.fullName}</h4>
          <h4>Precio Consulta: ${initialValues.appointmentPrice}</h4>
          <Space align='start' size={10}>
            <Form.Item {...inputProps.price} />
          </Space>
        </>
      )}
    </Form>
  );
}
