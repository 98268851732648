import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
// antd
import Table from "antd/lib/table";
import PageHeader from "antd/lib/page-header";
// components
import Add from "views/Admin/Plans/Plan/Add";
import AddFeature from "views/Admin/Plans/Feature/Add";
import Vastmindz from "./Plan/vastmindz";
// gql
import { getPlansSource as gql } from "./gql";
// helpers
import get from "lodash/get";
// columns
import buildCols from "./columns";
// contexts
import PlanContext from "./Context";
import CountrySelect from "./Plan/CountrySelect";

export default function Plans() {
  const { country, status, setCountry, setStatus } = useContext(PlanContext);
  const { data, loading, error } = useQuery(gql, {
    variables: {
      filters: [
        {
          byText: {
            field: "COUNTRY",
            value: country,
          },
        },
      ],
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    error !== status.error && setStatus({ error });
    loading !== status.loading && setStatus({ loading });
  }, [error, loading, status, setStatus]);

  const source = {
    plans: get(data, "plans.docs", []),
    features: get(data, "features.docs", []),
  };

  const countrySelectProps = { country, setCountry };

  const props = {
    Header: {
      title: "Planes",
      extra: [
        <CountrySelect key='country' {...countrySelectProps} />,
        <Vastmindz key='vastmindz' />,
        <Add key='add' />,
        <AddFeature key='gg' />,
      ],
    },
    Section: {
      style: {
        margin: "1rem auto",
        maxWidth: "80%",
      },
    },
    Table: {
      loading: status.loading,
      columns: buildCols(source.plans),
      dataSource: source.features,
      pagination: false,
      className: "mx-4",
    },
  };

  return (
    <section {...props.Section}>
      <PageHeader {...props.Header} />
      <Table {...props.Table} />
    </section>
  );
}
