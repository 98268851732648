import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Card } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import ChatScreen from "components/OpenFire/ChatScreen";

const ChatPacienteMD = ({ id, infoAppointment, infoForChat, sendFirstMessage, isFinish }) => {
  const history = useHistory();

  const irMiPerfil = () => {
    history.push("/patient");
  };

  return (
    <Card className='h-full w-full' bodyStyle={{ padding: 0 }} style={{ background: "#fff" }}>
      <div className={"flex flex-col h-full"}>
        <div className='mx-auto'>
          <Button
            onClick={irMiPerfil}
            type='default'
            size='large'
            shape='round'
            className='button-success m-0 my-2 '
          >
            <FontAwesomeIcon icon={faUser} className='mr-2 hover:text-success-hover' />
            Ir a mi perfil
          </Button>
        </div>
        <div
          className={"mt-0 h-full flex flex-col bg-gray-200 "}
          style={{ minHeight: "75vh", maxHeight: "85vh" }}
        >
          <ChatScreen
            infoAppointment={infoAppointment}
            infoForChat={infoForChat}
            isFinish={isFinish}
          />
        </div>
      </div>
    </Card>
  );
};

export default ChatPacienteMD;
