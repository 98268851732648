import gql from "graphql-tag";

export const rolesGql = gql`
  query roles($filters: [roleFilter!]) {
    roles(filters: $filters) {
      docs {
        id
        name
        usedFor
      }
    }
  }
`;

export const usersListGql = gql`
  query users($perPage: Float, $page: Float, $sort: [userSort!], $filters: [userFilter!]) {
    users(perPage: $perPage, page: $page, sort: $sort, filters: $filters) {
      total
      page
      docs {
        id
        firstName
        lastName
        phoneNumber
        pictureURL {
          id
          url
        }
        fullName
        email
        role {
          id
          name
        }
      }
    }
  }
`;

export const createUser = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $role: String!
    $password: String!
    $confirmPassword: String!
    $phoneNumber: String
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      role: $role
      password: $password
      confirmPassword: $confirmPassword
      phoneNumber: $phoneNumber
    ) {
      id
      fullName
    }
  }
`;

export const updateUser = gql`
  mutation updateUser(
    $id: String!
    $firstName: String
    $lastName: String
    $email: String
    $role: String
    $password: String
    $phoneNumber: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      role: $role
      password: $password
      phoneNumber: $phoneNumber
    ) {
      id
      fullName
    }
  }
`;

export const userById = gql`
  query userById($id: String!) {
    userById(id: $id) {
      createdAt
      firstName
      lastName
      email
      description
      pictureURL {
        id
        url
      }
    }
  }
`;

export const deleteUser = gql`
  mutation deleteUserById($id: String!) {
    deleteUserById(id: $id)
  }
`;

export const createRoleGql = gql`
  mutation createRole($name: String!, $usedFor: String!, $groupPermissions: [String!]) {
    createRole(name: $name, usedFor: $usedFor, groupPermissions: $groupPermissions) {
      id
    }
  }
`;
