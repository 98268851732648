import React from "react";
import { Alert, Avatar, Empty } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { companyById } from "../gqlTags";
import Spin from "antd/es/spin";
import moment from "moment";

const BodyDetailsCompany = ({ id }) => {
  const { loading, error, data } = useQuery(companyById, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      id: id,
    },
  });

  if (loading) {
    return (
      <div className='w-full flex justify-center content-center self-center'>
        <Spin />
      </div>
    );
  }
  if (error) {
    return (
      <div className='w-full'>
        <Alert
          message='Error'
          description='Error al obtener la información del doctor'
          type='error'
          className='w-full'
          showIcon
        />
      </div>
    );
  }
  if (data) {
    return (
      <>
        <div className='flex xs:flex-col md:flex-row'>
          <div
            className='flex flex-col justify-center xs:mx-auto md:mx-0'
            style={{ maxWidth: "150px" }}
          >
            <Avatar
              className='mx-auto mb-2'
              shape='square'
              size={64}
              src={data.companyById.pictureURL.url}
            />
            <p className='font-semibold mx-auto mb-2'>{`${data.companyById.firstName} ${data.companyById.lastName}`}</p>
          </div>
          <div className='ml-5'>
            <p className='mb-0 text-primary font-semibold'>Fecha de creación de la empresa </p>
            <p className='mb-2 text-black'>
              {moment(parseFloat(data.companyById.createdAt)).format("LLLL")}
            </p>
            <p className='mb-0 text-primary font-semibold'>Email</p>
            <p className='mb-2 text-black'>{data.companyById.email}</p>
            <p className='mb-0 text-primary font-semibold'>Cantidad de empleados afiliados</p>
            <p className='mb-2 text-black'>{data.companyById.totalAffiliateUsers}</p>
          </div>
        </div>
        <div className='xs:ml-5 md:ml-0 xs:mt-0 md:mt-3 flex flex-wrap '>
          <div className='w-full'>
            <p className='mb-0 text-primary font-semibold'>Descripción</p>
            <span className='mb-3 text-black'>
              {data.companyById.description ? (
                data.companyById.description.length < 1 ? (
                  <Empty description='Sin descripción' />
                ) : (
                  data.companyById.description
                )
              ) : (
                <Empty description='Sin descripción' />
              )}
            </span>
          </div>
        </div>
      </>
    );
  }
};

export default BodyDetailsCompany;
