import React from "react";
import Empty from "antd/lib/empty";

import InfiniteScrollAppointments from "./infiniteScrollAppointments";

const PendingAppointmentsMD = (props) => {
  if (props.error) {
    return (
      <Empty
        className='mt-32'
        description={<p className='text-danger'>Error al obtener las citas</p>}
      />
    );
  }
  if (props.total === 0) {
    return <Empty className='mt-32' description='No hay citas' />;
  }
  return <InfiniteScrollAppointments {...props} />;
};

PendingAppointmentsMD.defaultProps = {
  total: 0,
  data: [],
  error: null,
  loading: false,
  loadingFull: false,
  hasMoreCalc: false,
  handleLoadMore: () => {},
};

export default PendingAppointmentsMD;
