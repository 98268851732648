import React from "react";
// antd
import Button from "antd/lib/button";
// icons
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BtnAdd(props) {
  const propsBtn = {
    type: "dashed",
    block: true,
    className: "mt-3 text-success border-primary",
    ...props,
  };

  return (
    <Button {...propsBtn}>
      <span className='mr-2'>Añadir método de pago</span>
      <FontAwesomeIcon icon={faPlus} />
    </Button>
  );
}
