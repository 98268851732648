import React from "react";
import { Link } from "react-router-dom";
// antd
import Empty from "antd/lib/empty";
import Button from "antd/lib/button";

const props = {
  Link: {
    component: Button,
    type: "primary",
    to: {
      pathname: "/md/home",
    },
    children: "Buscar Medicos",
  },
  Empty: {
    description: <Btn />,
  },
};

export default function WithOutAppointment() {
  return <Empty {...props.Empty} />;
}

export function Btn() {
  // return <Link {...props.Link} />;
  // EAS-1349
  return <h1>Sin cita</h1>;
}
