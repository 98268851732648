import dotenv from "dotenv";
dotenv.config();

const NODE_ENV = process.env.REACT_APP_NODE_ENV || "development";
const MERCHANT_ID = process.env.REACT_APP_MERCHANT_ID || "development";

const getSessionFingerPrint = () =>
  new Promise((resolve, reject) => {
    const merchantID = MERCHANT_ID;
    const environment = NODE_ENV === "production" ? "live" : "sandbox";
    let orgId = "1snn5n9w";
    let loadedScript = false;
    let loadedImage = false;
    const sessionID = new Date().getTime();
    const printableStatus = document.querySelector("#status");

    // Function to check loading status
    const checkLoaded = () => {
      if (loadedImage && loadedScript) {
        if (printableStatus) {
          printableStatus.innerText = sessionID;
        }
        resolve(sessionID);
      }
    };

    // Use the correct orgId based on the environment
    if (environment.toLowerCase() === "live") {
      orgId = "k8vif92e";
    }

    // Create Image Node
    const img = document.createElement("img");

    // Add the att
    img.src = `https://h.online-metrix.net/fp/clear.png?org_id=${orgId}&session_id=${merchantID}${sessionID}&m=2`;
    img.alt = "";

    // Add Image to the dom
    document.body.appendChild(img);

    // Create Script element
    const tmscript = document.createElement("script");
    tmscript.src = `https://h.online-metrix.net/fp/tags.js?org_id=${orgId}&session_id=${merchantID}${sessionID}`;
    tmscript.type = "text/javascript";

    // Add it to the dom
    document.body.appendChild(tmscript);

    img.addEventListener("load", () => {
      loadedImage = true;
      checkLoaded();
    });
    img.addEventListener("error", (e) => {
      resolve(sessionID);
    });

    tmscript.addEventListener("load", () => {
      loadedScript = true;
      checkLoaded();
    });
    tmscript.addEventListener("error", (e) => {
      resolve(sessionID);
    });

    if (printableStatus) {
      printableStatus.innerText = "Profiling device...";
    }
  });

export default getSessionFingerPrint;
