import React from "react";
//
import Typography from "antd/lib/typography";
// components
import Canvas from "./Canvas";
// styles
import style from "views/Admin/Plans/style.module.scss";
import DeleteFeatureButton from "./DeleteFeatureButton";

const { Paragraph: P } = Typography;

export default function Feature(val, row) {
  const { id, name, description } = row;

  const props = {
    Canvas: {
      info: { id, name, description },
      children: <span className={style.editable}>{val}</span>,
    },
    P: {
      className: "mb-0 text-xs text-gray-500",
      ellipsis: {
        rows: 1,
        expandable: true,
        symbol: "ver todo",
      },
      children: description,
    },
  };

  return (
    <>
      <div className={`flex justify-between ${style.featureContainer}`}>
        <div>
          <Canvas {...props.Canvas} />
          <P {...props.P} />
        </div>
        <div className='flex'>
          <DeleteFeatureButton id={id} />
        </div>
      </div>
    </>
  );
}
