import React from "react";
import Lottie from "react-lottie";
import animationArrowDown from "assets/lottie/arrowScrollDown.json";

export default function ArrowDown() {
  const props = {
    width: "60px",
    options: {
      loop: true,
      autoplay: true,
      animationData: animationArrowDown,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    },
    className: "inline-block",
    isClickToPauseDisabled: true,
  };

  return <Lottie {...props} />;
}
