import gql from "graphql-tag";

export const patientListGql = gql`
  query usersWithPlan(
    $perPage: Float
    $page: Float
    $planFilter: [planFilterCustomInput!]
    $patientFilter: [patientFilterCustomInput!]
    $companyFilter: String
  ) {
    usersWithPlan(
      perPage: $perPage
      page: $page
      planFilter: $planFilter
      patientFilter: $patientFilter
      companyFilter: $companyFilter
    ) {
      total
      pages
      docs {
        id
        pictureURL {
          id
          url
        }
        fullName
        email
        verified
        phoneNumber
        plans {
          autoRenew
          expireAt
          status
          plan {
            id
            name
          }
        }
        belongsToCompany {
          id
          fullName
          companyPlan {
            id
          }
        }
      }
    }
  }
`;

export const cancelPlanGql = gql`
  mutation cancelPlan($planId: String!, $userId: String) {
    cancelPlan(userId: $userId, planId: $planId)
  }
`;
export const updateInfoPatient = gql`
  mutation updateInfoPatient(
    $id: String
    $email: String
    $phoneNumber: String
    $plan: String
    $belongsToCompany: String
  ) {
    updateInfoPatient(
      id: $id
      email: $email
      phoneNumber: $phoneNumber
      plan: $plan
      belongsToCompany: $belongsToCompany
    ) {
      id
    }
  }
`;

export const getPlans = gql`
  query getPlansPrice {
    plans {
      docs {
        id
        name
        disabled
        periods {
          price
          __typename @include(if: false)
        }
        __typename @include(if: false)
      }
    }
  }
`;

export const getCompanies = gql`
  query getCompanies {
    companys(all: true) {
      docs {
        id
        fullName
      }
    }
  }
`;
