import React from "react";
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapMarkedAlt, faPhone } from "@fortawesome/free-solid-svg-icons";

export default function ContactInfo() {
  return (
    <div style={{ maxWidth: 480 }}>
      <div className='flex mb-4'>
        <FontAwesomeIcon className='mr-4 mt-1 text-green' icon={faMapMarkedAlt} />
        <a
          className=' text-white'
          target='_blank'
          rel='noopener noreferrer'
          href='https://g.page/OficentroMetropolitano?share'
        >
          km 9.8 carretera masaya contigo al Hospital Vivian Pellas en Oficentro Metropolitano
        </a>
      </div>

      <div className='flex mb-4'>
        <FontAwesomeIcon className='mr-4 mt-1 text-green' icon={faPhone} />
        <div className='flex flex-col'>
          <a className='text-white mb-2' href='tel:++505578726684'>
            7872 - 6684
          </a>
          {/* <a className='text-white' href='tel:+50578495825'>7849 - 5825</a> */}
        </div>
      </div>

      <div className='flex'>
        <FontAwesomeIcon className='mr-4 mt-1 text-green' icon={faEnvelope} />
        <a className='text-white' href='mailto:info@easymd.co'>
          info@easymd.co
        </a>
      </div>
    </div>
  );
}
