import React from "react";
import styles from "./stylesPlanDescription.module.scss";
import Button from "antd/lib/button";

// icons
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// utils
import isEmpty from "lodash/isEmpty";

const PlanDescription = ({ modal, periodSelect, plan, openModal, disableButton = false }) => {
  const { description, id: idPlan, name, periods } = plan;
  const isValidToBuy = periods && periods.length > 1;
  // EAS-1361
  // redirect to whatsApp is fixed
  const phoneNumber = "50578726684";
  const message = "";

  const handleWhatsAppRedirect = () => {
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };
  return (
    <>
      <div
        className={"my-3 w-full h-full flex flex-col hover:bg-primary px-3 " + styles.SelectPlans}
      >
        <h4
          className='text-xl text-black text-center capitalize'
          title={name}
          id={`planName_${idPlan}`}
        >
          {name}
        </h4>
        {!modal && (
          <div className='w-full overflow-scroll' style={{ minHeight: "50px", maxHeight: "50px" }}>
            {!isEmpty(description) && <p className='text-sm text-center'>{description}</p>}
          </div>
        )}
        {isValidToBuy ? (
          <>
            <div className='flex justify-center items-center mt-3'>
              <FontAwesomeIcon className='text-center mr-2 faIcons' size='3x' icon={faDollarSign} />
              <span className='text-5xl font-bold'>
                {periodSelect === "MONTHLY" ? periods[0].price : periods[1].price}
              </span>
            </div>
            {!modal && (
              <div className='flex justify-center items-center'>
                <span className='text-center'>
                  {periodSelect === "MONTHLY" ? "Por mes" : "Por año"}
                </span>
              </div>
            )}
            <div className='flex justify-center items-center mb-5 mt-8'>
              {!disableButton && (
                <Button
                  className='px-8'
                  type='default'
                  shape='round'
                  size='large'
                  onClick={() =>
                    openModal({ visible: true, frequency: periodSelect, planId: idPlan })
                  }
                >
                  Obtener
                </Button>
              )}
            </div>
          </>
        ) : (
          <div className='flex justify-center items-center mb-5 mt-8'>
            {/* <a rel='stylesheet' href='mailto: admin@easymd.com'> */}
            <Button type='default' shape='round' size='large' onClick={handleWhatsAppRedirect}>
              Contáctanos
            </Button>
            {/* </a> */}
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(PlanDescription);
