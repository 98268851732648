import React, { useContext } from "react";
import Person from "components/Person";
import { updateCompanyPicture } from "../gqlTags";

import get from "lodash/get";

import { ContextCompanyCodes } from "../index";

const TitleCompany = () => {
  const { state } = useContext(ContextCompanyCodes);
  const { data } = state;

  const person = {
    id: get(state, "id"),
    role: get(data, ["role", "name"], "N/A"),
    gql: {
      upPic: updateCompanyPicture,
    },
    pic: get(data, ["pictureURL", "url"]),
    name: null,
    label: null,
    onSelect: () => {},
    isSimple: true,
    selected: false,
    size: 0,
  };

  return (
    <div className='flex flex-row items-center mt-2'>
      <Person.Card {...person} />
      <p className='text-xl font-bold mb-0'>{get(data, "fullName")} </p>
    </div>
  );
};

export default TitleCompany;
