import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Layout } from "antd";
import { microSaludRoutes } from "../../routes";

import View404Router from "../../components/View404Router";
import HeaderHome from "./headerHome";
import FooterHome from "./footer";
{/* EAS 1615 : Remove double whatapp chat button */}
// import WhatsAppChat from "../../components/WhatsAppChat";

const { Content } = Layout;

const HomeLayout = () => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return <Route path={prop.layout + prop.path} component={prop.component} key={key} exact />;
    });
  };

  return (
    <Layout className='layout bg-white'>
      <HeaderHome />
      <Content>
        <div className='site-layout-content'>
          <Switch>
            {getRoutes(microSaludRoutes)}
            <Route component={() => <View404Router />} />
            <Route path='/md'>
              <Redirect to='/md/home' />
            </Route>
          </Switch>
        </div>
      </Content>
      <FooterHome />
   {/* EAS 1615 : Remove double whatapp chat button */}
      {/* <WhatsAppChat /> */}
    </Layout>
  );
};

export default HomeLayout;
