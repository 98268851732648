import gql from "graphql-tag";
import { Form, notification } from "antd";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { HandleError } from "utils.js/HandleError";

const sendMarketingNotification = gql`
  query sendMarketingNotification(
    $medium: MediumEnum!
    $recipients: RecipientsEnum!
    $subject: String!
    $heading: String
    $subHeading: String
    $body: String!
  ) {
    sendMarketingNotification(
      medium: $medium
      recipients: $recipients
      subject: $subject
      heading: $heading
      subHeading: $subHeading
      body: $body
    ) {
      status
    }
  }
`;

export function useMarketing() {
  const [form] = Form.useForm();
  const [visibleContent, setVisibleContent] = useState();
  const [disabledFinish, setDisabledFinish] = useState(true);
  const [mobile, setMobile] = useState();

  const [fetchMarketingNotification, { loading, error, data }] = useLazyQuery(
    sendMarketingNotification,
    {
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (error && !data && !loading) {
      notification.destroy();
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
    if (data) {
      notification.success({
        message: "Notificacion Enviada",
      });
    }
  }, [error, data, loading, form]);

  const sendNotification = (values) => {
    fetchMarketingNotification({ variables: values });
    setVisibleContent(false);
    form.resetFields();
    setDisabledFinish(true);
  };

  const onValuesChange = (_, values) => {
    values.medium && values.recipients && setDisabledFinish(false);
    values.medium === "MOBILE" ? setMobile(true) : setMobile(false);
  };

  return {
    form,
    loading,
    mobile,
    visibleContent,
    disabledFinish,
    setVisibleContent,
    onValuesChange,
    sendNotification,
  };
}
