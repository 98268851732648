import React, { useContext } from "react";
// antd
import Card from "antd/lib/card";
import Typography from "antd/lib/typography";
// styles
import style from "../../style.module.scss";
import Button from "antd/lib/button";
import { GlobalContext } from "views/App";
import getValidNationality from "./getValidNationality";

const { Title } = Typography;

export default function BannerBlue() {
  const { state } = useContext(GlobalContext);
  const { user } = state;
  const validNationality = getValidNationality(user?.nationality);
  const props = {
    Card: {
      className: style.BannerBlue,
    },
    Btn: {
      href: validNationality
        ? "https://api.whatsapp.com/send?phone=50578726684&text=Hola%20soy%20afiliado%20EasyMD%2C%20me%20gustar%C3%ADa%20ordenar%20ex%C3%A1menes%20a%20domicilio"
        : "https://play.google.com/store/apps/details?id=com.easymdv2.android",
      target: "_blank",
      children: validNationality ? "Solicitar" : "Descarga el App",
    },
    Title: {
      level: 3,
      children: validNationality
        ? "Exámenes de laboratorio a domicilio"
        : "Exámenes de laboratorio a domicilio",
      className: "text-white",
    },
  };

  return (
    <Card {...props.Card} actions={[<Button {...props.Btn} />]}>
      <Title {...props.Title} />
    </Card>
  );
}
