import React, { useContext, useEffect, useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Select, Spin } from "antd";
import gql from "graphql-tag";
import { SET_PATIENT, SET_MEDICAL_CONDITION, SET_ALERGY } from "../../store/actions";
import { GlobalContext } from "../../../../App";
import { CheckOutContext } from "../index";

const { Option, OptGroup } = Select;

const SelectPaciente = () => {
  // Traer todos los beneficiarios del titular de la cuenta
  const listOfBeneficiaryGql = gql`
    query patientById($id: String!) {
      patientById(id: $id) {
        id
        newDrugAllergies
        newMedicalConditions
        beneficiaries {
          value: id
          tittle: fullName
        }
      }
    }
  `;

  // Traer la info de un beneficiario, un beneficiario es un paciente tambien
  const infoOfBeneficiaryGql = gql`
    query beneficiaryById($id: String!) {
      beneficiaryById(id: $id) {
        id
        fullName
        newDrugAllergies
        newMedicalConditions
      }
    }
  `;

  const { state: stateCheckOut, dispatch: dispatchCheckOut } = useContext(CheckOutContext);
  const { state: stateGlobal } = useContext(GlobalContext);
  const { user } = stateGlobal;

  const idPatient = stateCheckOut.paciente ? stateCheckOut.paciente.value : { value: null };

  const {
    loading: loadingList,
    error: errorList,
    data: dataList,
    refetch: refetchListBeneficiary,
  } = useQuery(listOfBeneficiaryGql, {
    variables: { id: user.id },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: loadingInfo,
    error: errorInfo,
    data: dataInfo,
  } = useQuery(infoOfBeneficiaryGql, {
    variables: { id: idPatient },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const setNuevasAlergiasMedicamentos = useCallback(
    (newData) => {
      let alergias = null;
      if (newData && newData.length > 0) {
        alergias = newData;
      }
      dispatchCheckOut({
        type: SET_ALERGY,
        payload: {
          alergias: alergias,
        },
      });
    },
    [dispatchCheckOut]
  );

  const setNuevasCondicionesMedicas = useCallback(
    (newData) => {
      let condicionesMedicas = null;
      if (newData && newData.length > 0) {
        condicionesMedicas = newData;
      }
      dispatchCheckOut({
        type: SET_MEDICAL_CONDITION,
        payload: {
          condicionesMedicas: condicionesMedicas,
        },
      });
    },
    [dispatchCheckOut]
  );

  useEffect(() => {
    if (!loadingList) {
      if (dataList && !errorList) {
        if (dataList.patientById.id === idPatient) {
          const newDataMedicalCondition = dataList.patientById.newMedicalConditions;
          const newDataDrugAllergies = dataList.patientById.newDrugAllergies;
          setNuevasCondicionesMedicas(newDataMedicalCondition);
          setNuevasAlergiasMedicamentos(newDataDrugAllergies);
        }
      }
    }
  }, [
    loadingList,
    errorList,
    dataList,
    idPatient,
    setNuevasCondicionesMedicas,
    setNuevasAlergiasMedicamentos,
  ]);

  useEffect(() => {
    if (!loadingInfo && dataInfo) {
      if (idPatient === dataInfo.beneficiaryById.id) {
        const newDataMedicalCondition = errorInfo
          ? []
          : dataInfo.beneficiaryById
          ? dataInfo.beneficiaryById.newMedicalConditions
          : [];
        const newDataDrugAllergies = errorInfo
          ? []
          : dataInfo.beneficiaryById
          ? dataInfo.beneficiaryById.newDrugAllergies
          : [];
        setNuevasCondicionesMedicas(newDataMedicalCondition);
        setNuevasAlergiasMedicamentos(newDataDrugAllergies);
      }
    }
  }, [
    loadingInfo,
    errorInfo,
    dataInfo,
    idPatient,
    setNuevasAlergiasMedicamentos,
    setNuevasCondicionesMedicas,
  ]);

  const onChangeSelectPaciente = async (e) => {
    dispatchCheckOut({
      type: SET_PATIENT,
      payload: {
        paciente: e,
      },
    });
    if (e) {
      if (e.value === user.id) {
        await refetchListBeneficiary({
          variables: { id: user.id },
        });
      }
    }
  };

  return (
    <>
      <Spin spinning={loadingList || loadingInfo}>
        <Select
          dropdownStyle={{ zIndex: 700 }}
          className='w-full mt-2'
          loading={loadingList || loadingInfo}
          disabled={loadingList || loadingInfo}
          labelInValue
          value={stateCheckOut.paciente ? stateCheckOut.paciente : { value: null }}
          placeholder='Seleccionar el paciente para la cita'
          onChange={(e) => onChangeSelectPaciente(e)}
        >
          <OptGroup label='Usuario'>
            <Option value={user.id}>{user.fullName}</Option>
          </OptGroup>

          {!errorList && dataList && dataList.patientById.beneficiaries.length > 0 && (
            <OptGroup label='Beneficiarios'>
              {dataList.patientById.beneficiaries.map((value, index) => (
                <Option value={value.value} key={index}>
                  {value.tittle}
                </Option>
              ))}
            </OptGroup>
          )}
        </Select>
      </Spin>
    </>
  );
};

export default SelectPaciente;
