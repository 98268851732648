import React, { useContext, useState } from "react";
import FormCreditCard from "../MethodPay";
import { Tabs } from "antd";
import { GlobalContext } from "../../views/App";
import { Endcrypt } from "endcrypt";

const newEndcrypt = new Endcrypt();
const { TabPane } = Tabs;

const TokenPaymentMethod = (props) => {
  const [infoCard, setInfoCard] = useState({});
  const [tokenId, setTokenId] = useState(null);
  const { state: stateGobal } = useContext(GlobalContext);

  const onChangeCreditCard = (value, fullInfo) => {
    if (value) {
      setInfoCard({});
      setTokenId({
        ...fullInfo,
      });
      finalFormatInfo({}, { ...fullInfo }, false);
    } else {
      setTokenId(null);
      finalFormatInfo(infoCard, null, false);
    }
  };

  const formatOtherCardNumber = (cardNumber) => {
    let newFormat = "";
    if (!cardNumber) {
      return null;
    }
    if (typeof cardNumber !== "string") {
      return null;
    }
    for (let i = 0; i < cardNumber.length; i++) {
      if (i === 0) {
        newFormat = newFormat.concat(cardNumber.charAt(i));
      } else {
        if (cardNumber.length - 5 < i) {
          newFormat = newFormat.concat(cardNumber.charAt(i));
        } else {
          newFormat = newFormat.concat("X");
        }
      }
    }
    return newFormat;
  };

  const getEnCrypt = (valuesCard) => {
    const plain = {
      card: {
        cardNumber: valuesCard.cardNumber,
        expirationMonth: valuesCard.expirationMonth,
        expirationYear: valuesCard.expirationYear,
        securityCode: valuesCard.securityCode,
        cardType: valuesCard.cardType,
      },
      customer: {
        address1: valuesCard.address1,
        address2: valuesCard.address2,
        city: valuesCard.city,
        country: valuesCard.country,
        email: stateGobal.user.email,
        firstName: valuesCard.firstName,
        lastName: valuesCard.lastName,
        zipCode: valuesCard.zipCode,
        state: valuesCard.state,
      },
    };
    const key = `${props.tokenString}${stateGobal.user.id}`;
    const newEncryptString = newEndcrypt.encryptWithKey(JSON.stringify(plain), key);
    return JSON.stringify(newEncryptString);
  };

  const finalFormatInfo = (_values, _tokenId, _checkedOther) => {
    props.onChange({
      id: _checkedOther ? getEnCrypt(_values) : _tokenId?.id,
      mask: _checkedOther ? formatOtherCardNumber(_values.cardNumber) : _tokenId?.name,
      type: _checkedOther ? _values.cardType : _tokenId?.type,
      save: false,
      isOtherMethod: _checkedOther,
      isAnySelect: _checkedOther || _tokenId !== null,
    });
  };

  return (
    <Tabs type='card' style={{ minHeight: "250px" }} defaultActiveKey='1'>
      <TabPane tab='Mis métodos de pago' key='1'>
        <FormCreditCard value={tokenId?.id || null} onChange={onChangeCreditCard} />
      </TabPane>
    </Tabs>
  );
};

TokenPaymentMethod.defaultProps = {
  onChange: () => {},
  tokenString: "______",
};

export default TokenPaymentMethod;
