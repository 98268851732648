import React, { useContext } from "react";
import imagDefault from "../../../../../assets/img/userPatient.png";
import moment from "moment";
import CardJob from "./CardJob";
// lodash
import toInteger from "lodash/toInteger";
// store
import { AffiliatesContext } from "../../index";

// ant
import Avatar from "antd/lib/avatar";
import Tag from "antd/lib/tag";
import TableTransactions from "./TableTransactions";

import { useQuery } from "@apollo/react-hooks";
import { getAgendaJobActive } from "../gql";

const ContendModal = () => {
  const { state } = useContext(AffiliatesContext);
  const { selectedUser } = state;

  const { data, loading, error } = useQuery(getAgendaJobActive, {
    fetchPolicy: "no-cache",
    variables: { userPlanId: state.selectedUser.userPlanId },
  });

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row'>
        <Avatar
          style={{
            width: "64px",
            height: "64px",
          }}
          className='mr-4'
          src={selectedUser.pictureURL ? selectedUser.pictureURL : imagDefault}
        />
        <div className='w-full grid grid-flow-cols grid-cols-4 gap-2'>
          <div className='block'>
            <h3 className='mb-0'>Paciente</h3>
            <p className='capitalize'>{selectedUser.fullName}</p>
          </div>
          <div className='flex flex-col'>
            <h3 className='mb-0'>Plan</h3>
            <span className='text-left capitalize mb-3'>{selectedUser.planName}</span>
            <div className='flex flex-col'>
              <h3 className='mb-0'>Auto renovación</h3>
              {renderTagPLan(selectedUser.autoRenew)}
            </div>
          </div>
          <div className='flex flex-col'>
            <h3 className='mb-0'>Estado</h3>
            <span className='capitalize'>{selectedUser.status}</span>
            {selectedUser.planCancelledAffiliation &&
              moment(toInteger(selectedUser.planCancelledAffiliation)).format("DD/MM/YYYY")}
            <div className='flex flex-col mt-3'>
              <h3 className='mb-0'>Periodo</h3>
              <span className='capitalize'>{selectedUser.period || "N/A"}</span>
            </div>
          </div>
          <div className='flex flex-col'>
            <div className='flex flex-col'>
              <h3 className='mb-0'>Afiliación </h3>
              <span className='capitalize mb-3'>
                {selectedUser.planDateAffiliation
                  ? moment(toInteger(selectedUser.planDateAffiliation)).format("DD/MM/YYYY")
                  : "N/A"}
              </span>
            </div>
            <div className='flex flex-col'>
              <h3 className='mb-0'>Expiración </h3>
              <span>
                {selectedUser.planExpiresAffiliation
                  ? moment(toInteger(selectedUser.planExpiresAffiliation)).format("DD/MM/YYYY")
                  : "N/A"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full mt-3'>
        <CardJob AgendaJob={data && !loading && !error && data.getAgendaJobActive} />
      </div>
      <div>
        <TableTransactions />
      </div>
    </div>
  );
};

const renderTagPLan = (row) => {
  return (
    <Tag className='w-16 mt-0' color={row ? "processing" : "error"}>
      {row ? "Activo" : "Inactivo"}
    </Tag>
  );
};

export default ContendModal;
