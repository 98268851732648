import React from "react";
import { Row, Col, Divider, Select } from "antd";

export default function InputCompanyChange({ value: initialValue, companies, form }) {
  const { Option } = Select;
  const company = initialValue;

  const changeHandler = (value) => {
    form &&
      form.setFieldsValue({
        belongsToCompany: value === "Remover Compañia" ? null : value,
      });
  };

  return (
    <Row gutter={[16, 16]} className='md:pr-1'>
      <Col xs={24}>
        <Divider className='m-0 text-primary font-semibold ' orientation='left' plain>
          Compañia
        </Divider>
      </Col>
      <Col xs={24} md={12}>
        <Select
          name='company'
          disabled={!companies}
          defaultValue={company ? company : "+ Agregar Compañia"}
          mode='single'
          bordered
          className='rounded'
          style={{ width: "100%" }}
          onChange={changeHandler}
          placeholder='+ Agregar Compañia'
        >
          {company && <Option value='Remover Compañia'>Remover Compañia</Option>}
          {companies?.companys.docs.map((company, i) => (
            <Option value={company.id} key={i}>
              {company.fullName}
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
}
