import React from "react";
import { Button, Empty, Table, Popconfirm, Tag } from "antd";
import moment from "moment";

const ContentModalDelete = ({ plans, email, idUser, name, deletePlan, company }) => {
  const columns = [
    {
      title: "Plan",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Fecha de Expiración",
      dataIndex: "expireAt",
      key: "expireAt",
      render: (text) => moment(text).format("DD/MM/YYYY, h:mm a"),
    },
    {
      title: "Subscripcion",
      dataIndex: "autoRenew",
      key: "autoRenew",
      render: (text, record) => (
        <Tag className='py-1 w-16 text-center' color={record.autoRenew ? "processing" : "error"}>
          {record.autoRenew ? "Activo" : "Inactivo"}
        </Tag>
      ),
    },
    {
      title: "",
      key: "action",
      render: (text, record) =>
        record.autoRenew &&
        !company && (
          <div className='flex items-center justify-center'>
            <Popconfirm
              title='¿Está seguro que desea cancelar el plan al paciente?'
              onConfirm={() => deletePlan(record.id, idUser)}
              okText='Si'
              cancelText='No'
            >
              <Button type='default' danger>
                Cancelar
              </Button>
            </Popconfirm>
          </div>
        ),
    },
  ];

  const data = plans.reduce((acc, value) => {
    return [...acc, { expireAt: value.expireAt, autoRenew: value.autoRenew, ...value.plan }];
  }, []);

  return (
    <>
      <div className='flex flex-col justify-center lg:flex-row lg:justify-between mt-5 w-2/4'>
        <div className='flex flex-col mr-2'>
          <p className='font-semibold mb-1'>Paciente:</p>
          <p className=''>{name}</p>
        </div>
        <div className='flex flex-col mr-2'>
          <p className='font-semibold mb-1'>Email:</p>
          <p className=''>{email}</p>
        </div>
      </div>
      <div className='flex flex-col mt-2' style={{ minHeight: "150px" }}>
        {!plans || plans.length === 0 ? (
          <Empty description={false} />
        ) : (
          <Table pagination={false} columns={columns} dataSource={data} />
        )}
      </div>
    </>
  );
};

export default ContentModalDelete;
