import React, { useState, useEffect, useContext, useCallback } from "react";
import { Table, Input, Select, notification } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { doctorListGql } from "./gqlTags";
import columns from "./columnsTable";
import { getValueAprobalStatus, traductionAprobalStatus } from "./utils";
import styles from "./styles.module.scss";
import ModalForActions from "./modalForActions";
import { ContextTablaDoctores } from "./index";

const { Option } = Select;

const TableDoctor = () => {
  const { state } = useContext(ContextTablaDoctores);
  const [paginationVars, setPaginationVars] = useState({
    perPage: 10,
    page: 1,
  });
  const [estadoAprobacionSelect, setEstadoAprobacionSelect] = useState(null);
  const [nombreBuscar, setNombreBuscar] = useState("");
  const [filterDoctor, setFilterDoctor] = useState([]);

  const { data, loading, error, refetch } = useQuery(doctorListGql, {
    variables: {
      ...paginationVars,
      filters: filterDoctor,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const sendRefetch = useCallback(async () => {
    await refetch();
  }, [refetch]);

  useEffect(() => {
    if (state.randomValue) {
      sendRefetch();
    }
  }, [state.randomValue, sendRefetch]);

  useEffect(() => {
    const newFilter = [];
    if (estadoAprobacionSelect) {
      newFilter.push({
        byText: [
          {
            field: "APPROVALSTATUS",
            value: getValueAprobalStatus(estadoAprobacionSelect, "db"),
          },
        ],
      });
    }
    if (nombreBuscar.length > 0) {
      newFilter.push({
        byText: {
          field: "FIRSTNAME",
          value: nombreBuscar,
          or: "LASTNAME",
        },
      });
    }
    setFilterDoctor(newFilter);
    setPaginationVars({
      perPage: 10,
      page: 1,
    });
  }, [estadoAprobacionSelect, nombreBuscar]);

  useEffect(() => {
    if (error && !loading) {
      notification.error({
        message: "Error",
        description: error.graphQLErrors[0]
          ? error.graphQLErrors[0].message
          : "Error al solicitar data",
      });
    }
  }, [error, loading]);

  const getDataForTable = () => {
    if (data) {
      return data.doctors.docs;
    } else {
      return [];
    }
  };

  const getPagination = () => {
    return {
      current: paginationVars.page,
      pageSize: paginationVars.perPage,
      total: data ? data.doctors.total : 0,
      showSizeChanger: false,
    };
  };

  const handleTableChange = (infoTable) => {
    const { current } = infoTable;
    setPaginationVars({
      ...paginationVars,
      page: current,
    });
  };

  return (
    <>
      {!loading && <ModalForActions />}
      <div className='w-full flex xs:flex-col sm:flex-row mb-3'>
        <Input
          className={"w-full mx-auto sm:mx-0 " + styles.max250px}
          placeholder='Buscar por nombre'
          value={nombreBuscar}
          onChange={(e) => setNombreBuscar(e.target.value)}
        />

        <Select
          className={"w-full mx-auto sm:ml-3 " + styles.max250px}
          placeholder='Estado de aprobacion'
          value={estadoAprobacionSelect}
          onChange={(e) => setEstadoAprobacionSelect(e)}
          allowClear
        >
          {traductionAprobalStatus.map((value, index) => {
            return (
              <Option value={value.key} key={index}>
                {value.table}
              </Option>
            );
          })}
        </Select>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={getDataForTable()}
        pagination={getPagination()}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TableDoctor;
