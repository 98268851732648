import React, { useEffect, useState, useContext } from "react";
// ant
import Card from "antd/lib/card";
import Tag from "antd/lib/tag";
import Alert from "antd/lib/alert";
import Button from "antd/lib/button";
import notification from "antd/lib/notification";
// icons
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// gql
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { planById, buyPlan, mePlans } from "./gqlPlans";
import { HandleError } from "utils.js/HandleError";
// componets
import SessionFingerPrint from "components/SessionFingerPrint";
import CardCReditCard from "components/MethodPay/Card";
import InputCoupon from "components/InputCoupon";
// context
import { GlobalContext } from "views/App";
import * as actionName from "../../store/actions";

const FeaturePlan = (props) => {
  return (
    <div className=' my-1 flex items-center row-span-1 col-span-1'>
      <FontAwesomeIcon
        className='mr-2 block text-center text-primary bg-primary-lighter p-2 rounded-full'
        size='2x'
        icon={faCheck}
      />
      <span>{props.name}</span>
    </div>
  );
};

const CardDetailPlan = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const [fingerPrintLoading, setFingerPrintLoading] = useState(false);
  const { planId, frequency, onCloseModal, paymentMethod } = props;
  const {
    loading: loadingPlan,
    error: errorPlan,
    data: dataPlan,
  } = useQuery(planById, { fetchPolicy: "no-cache", variables: { id: planId } });
  const [setBuyplanMD, reqSetBuyplanMD] = useMutation(buyPlan, { fetchPolicy: "no-cache" });
  const [setMeplans, reqSetMeplans] = useLazyQuery(mePlans, { fetchPolicy: "no-cache" });
  const [coupon, setCoupon] = useState();
  const [activeCoupon, setActiveCoupon] = useState(null);
  const normalPrice =
    frequency === "MONTHLY"
      ? dataPlan?.planById.periods[0].price
      : dataPlan?.planById.periods[1].price;

  useEffect(() => {
    if (errorPlan) {
      onCloseModal();
      notification.error({
        message: "Error",
        description: HandleError(errorPlan),
      });
      localStorage.removeItem("mobileNotification");
    }
  }, [errorPlan, onCloseModal]);

  useEffect(() => {
    if (reqSetMeplans.data) {
      dispatch({
        type: actionName.SET_USER,
        payload: {
          ...state.user,
          plans: reqSetMeplans.data.me.plans,
        },
      });
      notification.success({
        message: "Ahora tu cuenta esta suscrita a un plan EasyMD!",
      });
      onCloseModal();
    }
  }, [reqSetMeplans.data, dispatch, onCloseModal, state]);

  const onSumitBuyPlan = async () => {
    try {
      if (paymentMethod) {
        if (paymentMethod.isAnySelect) {
          setFingerPrintLoading(true);
          const Sessionid = await SessionFingerPrint();
          setFingerPrintLoading(false);
          let dataBuyPlan = {
            planId: planId,
            paymentToken: paymentMethod.id,
            frequency: frequency,
            fingerprintSessionId: Sessionid.toString(),
            autoRenew: paymentMethod.isOtherMethod !== true,
            coupon,
          };
          if (localStorage.getItem("mobileNotification")) {
            dataBuyPlan = {
              ...dataBuyPlan,
              mobileNotification: !!localStorage.getItem("mobileNotification"),
            };
          }

          setBuyplanMD({ variables: dataBuyPlan })
            .then(() => {
              setMeplans();
            })
            .catch((e) => {
              onCloseModal();
              notification.error({
                message: "Error",
                description: HandleError(e),
              });
            });
        } else {
          notification.info({
            message: "Por favor seleccione un método de pago",
          });
        }
      } else {
        notification.info({
          message: "Por favor seleccione un método de pago",
        });
      }
    } catch (e) {
      onCloseModal();
      notification.error({
        message: "Error",
        description: HandleError(e),
      });
    }
  };

  const handleCoupon = (activateCoupon) => {
    if (activateCoupon) {
      const { amount, type } = activateCoupon;
      type === "MONTO" && setActiveCoupon(String(normalPrice - amount));
      type === "PORCENTAJE" && setActiveCoupon(String(normalPrice - normalPrice * (amount / 100)));
      setCoupon(activateCoupon.id);
    } else {
      setCoupon(undefined);
      setActiveCoupon(undefined);
    }
  };

  return (
    <>
      <Card
        className='shadow-xl'
        loading={loadingPlan}
        title='Detalles de la orden'
        headStyle={{ textAlign: "center" }}
        bordered
        bodyStyle={{ height: "100%" }}
      >
        {dataPlan && (
          <>
            <div className='flex justify-between'>
              <h5 className='text-lg'>{dataPlan.planById.name}</h5>
              <span className='text-primary text-lg'>
                {activeCoupon && (
                  <span className='line-through font-semibold text-red-500'>${normalPrice}</span>
                )}
                <strong>{` $${activeCoupon ? activeCoupon : normalPrice}`}</strong>
              </span>
            </div>
            <div className='my-3 flex flex-col justify-start md:flex-row md:justify-between md:items-center'>
              <p>
                {dataPlan.planById.description
                  ? dataPlan.planById.description
                  : "Plan sin descripción "}
              </p>
              <Tag className='m-0 text-center p-2 rounded-full bg-primary border-primary text-base text-white'>
                {frequency === "MONTHLY" ? "Mensual" : "Anual"}
              </Tag>
            </div>

            <div className='grid grid-cols-12 gap-2'>
              {dataPlan.planById.features.map((value, index) => (
                <div key={index} className='col-span-12 md:col-span-6'>
                  <FeaturePlan name={value.name} />
                </div>
              ))}
            </div>

            <div className=' my-3 flex items-center'>
              <div className='flex flex-row w-full'>
                <InputCoupon
                  activeCoupon={handleCoupon}
                  cardType={paymentMethod?.isAnySelect && paymentMethod?.type}
                />
              </div>
            </div>

            <div className=' my-3 flex items-center'>
              <div className='flex flex-col w-full'>
                {paymentMethod ? (
                  paymentMethod.isAnySelect ? (
                    <CardCReditCard name={paymentMethod.mask} type={paymentMethod.type} />
                  ) : (
                    <Alert message='Por favor seleccione un método de pago' type='info' showIcon />
                  )
                ) : (
                  <Alert message='Por favor seleccione un método de pago' type='info' showIcon />
                )}
              </div>
            </div>
          </>
        )}
      </Card>
      <Button
        loading={fingerPrintLoading || reqSetBuyplanMD.loading || reqSetBuyplanMD.loading}
        disabled={fingerPrintLoading || reqSetBuyplanMD.loading || reqSetBuyplanMD.loading}
        className='flex justify-center items-center my-2 w-full border-primary text-primary border-solid border-2'
        type='default'
        size='large'
        onClick={() => onSumitBuyPlan()}
      >
        Comprar Ahora
      </Button>
    </>
  );
};

export default CardDetailPlan;
