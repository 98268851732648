import React, { useEffect } from "react";

import {
  faEnvelope,
  faLock,
  faBuilding,
  // faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input, Button, Tooltip, notification, Select } from "antd";

// utils
import { HandleError } from "utils.js/HandleError";

const FormSignUp = (props) => {
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    if (props.error) {
      notification.error({
        message: "Error",
        description: HandleError(props.error),
      });
    }
    if (props.data) {
      notification.success({
        message: props.infoCompany ? "Empresa Editada" : "Empresa Creado",
      });
      props.successCreate();
    }
  }, [props]);

  useEffect(() => {
    if (props.infoCompany) {
      form.setFields([
        {
          name: "firstName",
          value: props.infoCompany.firstName,
          errors: null,
        },
        {
          name: "lastName",
          value: props.infoCompany.lastName,
          errors: null,
        },
        // {
        //   name: 'userCap',
        //   value: props.infoCompany.userCap,
        //   errors: null
        // },
        {
          name: "description",
          value: props.infoCompany.description,
          errors: null,
        },
      ]);
    }
  }, [form, props.infoCompany]);

  return (
    <div className='w-full lg:w-full '>
      <h3 className='mt-3 mb-8 text-2xl'>
        <strong>{props.title}</strong>
      </h3>
      <Form
        form={form}
        layout='horizontal'
        validateTrigger='onBlur'
        name='basic'
        initialValues={{ remember: true }}
        onFinish={(valueForm) => props.onFinish(valueForm)}
      >
        <div className='grid grid-cols-12 gap-2'>
          <div className='col-span-12 md:col-span-6'>
            <Form.Item
              label={null}
              name='firstName'
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese el nombre de la compañía!",
                },
              ]}
            >
              <Input
                disabled={props.loading || props.data}
                size='large'
                prefix={
                  <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faBuilding} />
                }
                className='rounded'
                placeholder='Nombre de la compañía '
              />
            </Form.Item>
          </div>

          <div className='col-span-12 md:col-span-6'>
            <Form.Item
              label={null}
              name='lastName'
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese el sufijo de la compañía!",
                },
              ]}
            >
              <Input
                disabled={props.loading || props.data}
                size='large'
                prefix={
                  <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faBuilding} />
                }
                className='rounded'
                placeholder='Sufijo de la compañía '
              />
            </Form.Item>
          </div>

          {!props.infoCompany && (
            <div className='col-span-12 md:col-span-6'>
              <Form.Item
                label={null}
                name='email'
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su Correo Electrónico de la compañia!",
                  },
                  {
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Por favor ingrese un correo valido!",
                  },
                ]}
              >
                <Input
                  disabled={props.loading || props.data}
                  size='large'
                  prefix={
                    <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faEnvelope} />
                  }
                  className='rounded'
                  placeholder='micorreo@email.com'
                />
              </Form.Item>
            </div>
          )}

          {
            // <div className={`col-span-12 ${(!props.infoCompany) && 'md:col-span-6'}`}>
            //   <Form.Item
            //     className='mb-1'
            //     label={null}
            //     name='userCap'
            //     rules={[
            //       {
            //         required: true,
            //         message: 'Por favor ingrese el Numero de empleados de la compañia!'
            //       },
            //       {
            //         pattern: /^[0-9\b]+$/,
            //         message: 'Por favor ingrese solo Numeros Enteros!'
            //       }
            //     ]}
            //   >
            //     <Input
            //       size='large'
            //       min={0}
            //       prefix={
            //         <FontAwesomeIcon
            //           className='text-center mr-2'
            //           size='lg'
            //           icon={faUsers}
            //         />
            //       }
            //       className='rounded w-full'
            //       placeholder='Numero de Empleados'
            //     />
            //   </Form.Item>
            // </div>
          }

          {!props.infoCompany && (
            <div className='col-span-12 md:col-span-6'>
              <Tooltip
                placement='topRight'
                title={
                  <div>
                    <p className='pl-2'>Requisitos mínimos de la contraseña </p>
                    <ul className='text-left pl-3 pt-0'>
                      <li>Ocho caracteres mínimo</li>
                      <li>Al menos un Número</li>
                    </ul>
                  </div>
                }
              >
                <Form.Item
                  label={null}
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese su Contraseña!",
                    },
                    {
                      pattern: /^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                      message: "La contraseña no cumple los requisitos mínimos!",
                    },
                  ]}
                >
                  <Input.Password
                    disabled={props.loading || props.data}
                    autoComplete='new-password'
                    size='large'
                    prefix={
                      <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLock} />
                    }
                    className='rounded'
                    placeholder='Contraseña'
                  />
                </Form.Item>
              </Tooltip>
            </div>
          )}

          {!props.infoCompany && (
            <div className='col-span-12 md:col-span-6'>
              <Form.Item
                autoComplete='new-password'
                label={null}
                name='confirmPassword'
                rules={[
                  {
                    required: true,
                    message: "Por favor confirme su contraseña !",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Las dos contraseñas que ingresaste no coinciden!");
                    },
                  }),
                ]}
              >
                <Input.Password
                  disabled={props.loading || props.data}
                  size='large'
                  prefix={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLock} />}
                  className='rounded'
                  placeholder='Confirmar contraseña'
                />
              </Form.Item>
            </div>
          )}

          <div className='col-span-12 md:col-span-6'>
            <Form.Item name='companyPlan' label={null} required={false}>
              <Select
                disabled={props.loading || props.data}
                loading={props.loading}
                // defaultValue={"+ Agregar Plan"}
                mode='single'
                allowClear
                showArrow
                bordered
                size='large'
                className='rounded'
                style={{ width: "100%" }}
                placeholder='+ Agregar Plan'
              >
                {props.plansInfo &&
                  props.plansInfo.plans.docs.slice(2).map((plan, i) => (
                    <Option value={plan.id} key={i}>
                      {plan.name}
                      {plan.periods.length > 0 && " - $" + plan.periods[0].price}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>

          <div className='col-span-12 h-full'>
            <Form.Item className='mb-1' label={null} name='description'>
              <Input.TextArea
                className='rounded w-full'
                placeholder='Descripción de la compañía'
                rows={2}
              />
            </Form.Item>
          </div>

          <div className='flex xs:flex-col md:flex-row justify-between col-span-12'>
            <Form.Item className=''>
              <Button
                disabled={props.loading || props.data}
                loading={props.loading}
                htmlType='submit'
                type={`${props.infoCompany ? "default" : "primary"} `}
                shape='round'
                size='large'
                className={`xs:mx-auto md:mx-4 ${props.infoCompany && "button-success"}`}
              >
                {props.infoCompany ? "Editar cuenta" : "Crear cuenta"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

FormSignUp.defaultProps = {
  infoCompany: null,
};

export default FormSignUp;
