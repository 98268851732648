import React, { useContext } from "react";
import gql from "graphql-tag";
import { Alert } from "antd";

import { SET_MEDICAL_CONDITION, SET_DRUG, SET_ALERGY } from "../../store/actions";
import { CheckOutContext } from "../index";

import Styles from "../styles.module.scss";
import InputWordCloud from "../../../../../components/InputWordCloud";

const medicalconditionsGql = () => {
  return gql`
    query medicalconditions($page: Float, $perPage: Float, $filters: [medicalconditionFilter!]) {
      medicalconditions(page: $page, perPage: $perPage, filters: $filters) {
        docs {
          value: id
          label: name
        }
      }
    }
  `;
};

const drugsGql = () => {
  return gql`
    query drugs($page: Float, $perPage: Float, $filters: [drugFilter!]) {
      drugs(page: $page, perPage: $perPage, filters: $filters) {
        docs {
          value: id
          label: name
        }
      }
    }
  `;
};

// Alergias a medicamentos es consultar medicamentos y seleccionarlos como que el paciente tiene alergia a dicho medicamento
const alergysGql = () => {
  return gql`
    query drugs($page: Float, $perPage: Float, $filters: [drugFilter!]) {
      drugs(page: $page, perPage: $perPage, filters: $filters) {
        docs {
          value: id
          label: name
        }
      }
    }
  `;
};

const Paso2DetallePaciente = (props) => {
  const { state, dispatch } = useContext(CheckOutContext);
  const {
    condicionesMedicas: listaCondicionesMedicas,
    alergias: listaAlergias,
    medicamentos: listamedicamentos,
  } = state;

  /** * Acciones para Alergias ******/

  // Al seleccionar una option en el select de condicion medica
  const onChangeSelectMedicalCondition = (newValue) => {
    dispatch({
      type: SET_MEDICAL_CONDITION,
      payload: {
        condicionesMedicas: newValue ? (newValue.length > 0 ? newValue : null) : null,
      },
    });
  };

  /** * Fin Medicamentos */

  /** * Acciones para Medicamentos ******/

  // Al seleccionar una option en el select de condicion medica
  const onChangeSelectDrugs = (newValue) => {
    dispatch({
      type: SET_DRUG,
      payload: {
        medicamentos: newValue ? (newValue.length > 0 ? newValue : null) : null,
      },
    });
  };

  /** * Fin Medicamentos */

  /** * Acciones para Alergias ******/

  // Al seleccionar una option en el select de condicion medica
  const onChangeSelectAlergys = (newValue) => {
    dispatch({
      type: SET_ALERGY,
      payload: {
        alergias: newValue ? (newValue.length > 0 ? newValue : null) : null,
      },
    });
  };

  /** * Fin Alergias */

  return (
    <div className={"w-full mb-5 " + props.className}>
      <div className='w-full flex flex-col'>
        <p className='text-lg font-bold'>Historial Médico</p>
        <p className='text-sm xs:w-full md:w-4/5'>
          {" "}
          Ayuda a tu médico respondiendo con detalles las siguientes preguntas.{" "}
        </p>
      </div>
      <div className={Styles.stetoscopioImg + " xs:hidden md:block"} />

      <div className='mt-5'>
        <Alert message='Estas preguntas no son obligatorias' type='info' showIcon />
      </div>

      <div className='w-full mt-5'>
        <label className='mb-2'>Condiciones médicas</label>
        <InputWordCloud
          value={listaCondicionesMedicas || []}
          gql={medicalconditionsGql()}
          path='medicalconditions.docs'
          placeholder='Buscar condiciones médicas'
          onChange={onChangeSelectMedicalCondition}
          className='w-full mt-2'
        />
      </div>

      <div className='w-full mt-5'>
        <label className='mb-2'>Medicamentos que el paciente esta tomando actualmente</label>
        <InputWordCloud
          value={listamedicamentos || []}
          gql={drugsGql()}
          path='drugs.docs'
          placeholder='Buscar medicamentos'
          onChange={onChangeSelectDrugs}
          className='w-full mt-2'
        />
      </div>

      <div className='w-full mt-5'>
        <label className='mb-2'>Alergias a medicamentos</label>
        <InputWordCloud
          value={listaAlergias || []}
          gql={alergysGql()}
          path='drugs.docs'
          placeholder='Buscar alergias'
          onChange={onChangeSelectAlergys}
          className='w-full mt-2'
        />
      </div>
    </div>
  );
};

export default Paso2DetallePaciente;
