import gql from "graphql-tag";

export const appointmentByIdGql = gql`
  query appointmentById($id: String!) {
    appointmentById(id: $id) {
      id
      appointmentDateAndTime
      newSymptoms
      reasonForConsultation
      usable
      status
      vonageSessionId
      patient {
        id
        openFireUsername
        openFirePassword
        firstName
        lastName
        fullName
        pictureURL {
          url
        }
      }
      doctor {
        id
        fullName
        rating
        pictureURL {
          url
        }
        openFireUsername
      }
      log {
        firstMessagePatient
        firstMessageDoctor
      }
    }
  }
`;

export const appointmentStatusGql = gql`
  query appointmentById($id: String!) {
    appointmentById(id: $id) {
      status
      usable
      patient {
        id
      }
      doctor {
        id
        fullName
        pictureURL {
          url
        }
      }
    }
  }
`;
