import React, { createContext, useReducer, useEffect, useState, useContext } from "react";
import reducer from "../store/reducer";
import { useQuery } from "@apollo/react-hooks";
import initialState from "../store/initialState";
import { message, Row, Col, Card, Spin, Button } from "antd";
import gqlSimpleInterval from "./gqlSimpleInterval";
import ErrorCard from "../errorCard";
import moment from "moment";

import DetallePaciente from "./detallePaciente";
import DetalleConsulta from "./detalleConsulta";
import TimerExpireAppointment from "./timerExpireAppointment";
import LoginTokenExpired from "./loginTokenExpired";

import { GlobalContext } from "../../../App";
import * as actionName from "../store/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import CitaMedicaCreada from "../../../../assets/lottie/citaMedicaCreada.json";
import Lottie from "react-lottie";

export const CheckOutContext = createContext();

const WizardCheckOut = ({ id, dataAppointment }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { state: globalState } = useContext(GlobalContext);
  const [isErrorAppointment, setIsErrorAppointment] = useState(false);
  const [diffExpireTime, setDiffTime] = useState(2000);
  const [isTokenExpire, setIsTokenExpire] = useState(false);

  const { creandoCitaLoading, citaAgendadaConExito } = state;

  const { loading, error, data, startPolling, stopPolling, refetch } = useQuery(
    gqlSimpleInterval(),
    { variables: { id: id }, fetchPolicy: "no-cache", notifyOnNetworkStatusChange: true }
  );
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    startPolling(75000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  useEffect(() => {
    if (citaAgendadaConExito) {
      stopPolling();
    }
  }, [citaAgendadaConExito, stopPolling]);

  useEffect(() => {
    const adjuntos = [];
    if (dataAppointment.exams) {
      dataAppointment.exams.forEach((value) => {
        if (value.media) {
          adjuntos.push({
            ...value.media,
            name: value.title,
          });
        }
      });
    }

    const isMobile = !!localStorage.getItem("mobileNotificationAppointment");

    if (isMobile && localStorage.getItem("refreshToken")) {
      localStorage.removeItem("mobileNotificationAppointment");
    }

    dispatch({
      type: actionName.SET_APPOINTMENT_DATA,
      payload: {
        idAppointment: id,
        idDoctor: dataAppointment.doctor ? dataAppointment.doctor.id : null,
        appointmentById: dataAppointment,
        adjuntos: adjuntos,
        medicamentos: dataAppointment.newCurrentMedication,
        sintomas: dataAppointment.newSymptoms,
        alergias: dataAppointment.patient ? dataAppointment.patient.newDrugAllergies : [],
        condicionesMedicas: dataAppointment.patient
          ? dataAppointment.patient.newMedicalConditions
          : [],
        paciente: dataAppointment.patient
          ? {
              value: dataAppointment.patient.id,
              label: dataAppointment.patient.fullName,
            }
          : null,
        razonCita: dataAppointment.reasonForConsultation,
      },
    });
  }, [dataAppointment, id]);

  useEffect(() => {
    if (!loading && data && !error) {
      const { appointmentById } = data;
      if (appointmentById.status !== "PENDING") {
        stopPolling();
        setIsErrorAppointment(true);
      } else {
        const ahora = moment(new Date());
        const expira = moment(appointmentById.expiresAt);
        const diff = expira.diff(ahora, "minutes");
        if (diff < 0) {
          stopPolling();
          setIsErrorAppointment(true);
        }
        setDiffTime(diff);
      }
    } else if (error) {
      if (!isTokenExpire && typeof error.message === "string") {
        const minus = error.message.toLowerCase();
        const indexErrorJwt = minus.indexOf("tokenexpirederror");
        if (indexErrorJwt > -1) {
          setIsTokenExpire(true);
        }
      }
      stopPolling();
    }
  }, [loading, error, data, isTokenExpire, stopPolling]);

  useEffect(() => {
    if (diffExpireTime < 7 && diffExpireTime > -1 && !citaAgendadaConExito && !creandoCitaLoading) {
      message.open({
        content: <TimerExpireAppointment diff={diffExpireTime * 1000 * 60} />,
        style: {
          marginTop: "20vh",
        },
        icon: null,
        duration: diffExpireTime > 4 ? 12 : 4,
      });
    }
  }, [diffExpireTime, citaAgendadaConExito, creandoCitaLoading]);

  const setLoginSuccess = () => {
    setIsTokenExpire(false);
    setIsErrorAppointment(false);
    try {
      refetch();
    } catch (e) {}
    startPolling(75000);
  };

  return (
    <CheckOutContext.Provider value={{ state: state, dispatch: dispatch }}>
      {isTokenExpire ? (
        <LoginTokenExpired
          idUser={globalState.user ? globalState.user.id : null}
          setLoginSuccess={setLoginSuccess}
        />
      ) : !isTokenExpire &&
        (error || isErrorAppointment) &&
        !creandoCitaLoading &&
        !citaAgendadaConExito ? (
        <ErrorCard error={error} message='Cita no encontrada' usingTopMargin={false} />
      ) : citaAgendadaConExito ? (
        <div className='flex h-auto mt-5'>
          <div className='flex flex-col w-full my-5'>
            <p className='xs:text-lg md:text-2xl mx-auto text-success font-bold my-3'>
              CITA MÉDICA AGENDADA CON ÉXITO
            </p>
            <div className='flex-1 mx-auto'>
              <Button
                type='default'
                shape='round'
                size='large'
                className='mx-auto button-success w-full'
              >
                <FontAwesomeIcon icon={faUser} className='mr-2 hover:text-success-hover' />
                <Link to='/patient' className='text-white hover:text-white break-words'>
                  {" "}
                  Ir a mi perfil
                </Link>
              </Button>
            </div>
            <CitaCreadaConExito />
          </div>
        </div>
      ) : (
        <Spin spinning={creandoCitaLoading}>
          <Row
            gutter={[20, 10]}
            className='min-h-35rem xs:mt-3 md:mt-0 mx-0 py-5 xs:px-5 sm:px-3 md:px-5 lg:mx-20 mt-5'
          >
            <Col xs={24} md={12} lg={10} className=''>
              <Card className='w-full h-full'>
                <DetallePaciente />
              </Card>
            </Col>
            <Col xs={24} md={12} lg={14} className=''>
              <Card className='w-full h-full' style={{ zIndex: 0 }}>
                <DetalleConsulta />
              </Card>
            </Col>
          </Row>
        </Spin>
      )}
    </CheckOutContext.Provider>
  );
};

const CitaCreadaConExito = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: CitaMedicaCreada,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return <Lottie isClickToPauseDisabled options={defaultOptions} height='100%' width='45%' />;
};

export default WizardCheckOut;
