import gql from "graphql-tag";

export const transactionsFilter = gql`
  query transactionsFilter(
    $perPage: Float
    $page: Float
    $text: String
    $from: DateTime!
    $to: DateTime!
  ) {
    transactionsFilter(perPage: $perPage, page: $page, text: $text, from: $from, to: $to) {
      total
      pages
      docs {
        createdAt
        email
        fullName
        amount
        status
        confirmationNumber
        description
      }
    }
  }
`;
