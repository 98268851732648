import React, { useContext, useState } from "react";
// router
import { useHistory } from "react-router-dom";
// antd
import Menu from "antd/lib/menu";
// items
// menu
import adminRoutes from "routes/adminRoutes";
import companyRoutes from "routes/companyRoutes";

// utils
import get from "lodash/get";

// context
import { GlobalContext } from "views/App";

export default function MainMenu({ onSelect, selected }) {
  const history = useHistory();
  const { state } = useContext(GlobalContext);
  const operationsRoutes = adminRoutes.filter(
    (route) => !route.key.includes("planilla") && !route.key.includes("usuarios")
  );
  const [roleUser] = useState(get(state, ["user", "role", "name"]));
  const userGroup = get(state, ["user", "role", "usedFor"]);
  const [routesForUse] = useState(
    roleUser === "root"
      ? adminRoutes
      : userGroup === "operationsRole"
      ? operationsRoutes
      : companyRoutes
  );

  const onClick = ({ key }) => {
    const rootRoute =
      roleUser === "root" || userGroup === "operationsRole" ? "/admin/" : "/company/";
    history.push(rootRoute + key);
    onSelect(key);
  };

  const props = {
    Menu: {
      onClick,
      mode: "inline",
      theme: "light",
      children: routesForUse.map((i) => (i.useInSidebar ? <Menu.Item {...i} /> : null)),
      selectedKeys: [selected],
    },
  };

  return <Menu {...props.Menu} />;
}
