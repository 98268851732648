const initialState = {
  modalOpenInfo: false,
  modalOpenDeny: false,
  modalNewEmpresa: false,
  modalEditEmpresa: false,
  idEmpresaSelect: null,
  refetch: () => {},
};

export default initialState;
