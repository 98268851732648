import gql from "graphql-tag";

export const couponsListGql = gql`
  query coupons($perPage: Float, $page: Float, $sort: [couponSort!], $filters: [couponFilter!]) {
    coupons(perPage: $perPage, page: $page, sort: $sort, filters: $filters) {
      total
      page
      docs {
        id
        coupon
        description
        amount
        type
        status
        duration
      }
    }
  }
`;

export const updateCoupon = gql`
  mutation updateCoupon(
    $id: String!
    $coupon: String
    $amount: String
    $type: String
    $status: Boolean
    $description: String
    $duration: Float
  ) {
    updateCoupon(
      id: $id
      coupon: $coupon
      amount: $amount
      type: $type
      status: $status
      description: $description
      duration: $duration
    ) {
      id
      coupon
    }
  }
`;

export const createCoupon = gql`
  mutation createCoupon(
    $coupon: String!
    $amount: String!
    $type: String
    $description: String
    $duration: Float
  ) {
    createCoupon(
      coupon: $coupon
      amount: $amount
      type: $type
      description: $description
      duration: $duration
    ) {
      id
      coupon
    }
  }
`;

export const deleteCoupon = gql`
  mutation deleteCouponById($id: String!) {
    deleteCouponById(id: $id)
  }
`;

export const couponById = gql`
  query couponById($id: String!) {
    couponById(id: $id) {
      id
      coupon
      amount
      type
      status
      description
      duration
      updatedAt
    }
  }
`;
