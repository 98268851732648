import defaultImage from '../../assets/img/viewFamily/avatarFamily.png'

const dataSourceCarouselFamily = [
  {
    name: 'Jesus Herrera',
    job: 'Constructor',
    start: 5,
    image: defaultImage,
    description: 'Gracias a EasyMD pude darme cuenta que está infectado con COVID-19. Gracias a mis consultas inmediatas logre darme cuenta de forma rápida y evite ir a la oficina a posiblemente contagiar a los demás.'
  },
  {
    name: 'Dina Aleman',
    job: 'Ama de Casa',
    start: 5,
    image: defaultImage,
    description: 'Cuando mi hija no dejaba de llorar en la noche no sabía que hacer, me iba a ir al hospital, pero me acordé de EasyMD. Llamé a los Doctores de EasyMd a través de videollamada y lograron guiarme y evitar salir corriendo a las 3 am'
  },
  {
    name: 'Jessimey Davila',
    job: 'Contadora',
    start: 5,
    image: defaultImage,
    description: 'Pase toda la mañana con dolor de estómago sin saber que era. Gracias a EasyMD me enviaron a hacer mis exámenes de laboratorio a domicilio a mi lugar de trabajo y lograron diagnosticar una bacteria todo en tan solo un día, sin moverme del trabajo. Gracias a ello pude hacer el cierre en tiempo y forma.'
  }

]

export default dataSourceCarouselFamily
