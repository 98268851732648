const initialState = {
  loading: true,
  error: false,
  tabsActive: null,
  balance: null,
  supportingDocuments: null,
  dataMedic: {
    id: null,
    fullName: null,
    firstName: null,
    lastName: null,
    pictureURL: null,
    phoneNumber: null,
    minsaNumber: null,
    specialties: [],
    languages: [],
    description: null,
    signature: "",
    appointmentPrice: 0,
    approved: null,
  },
};

export default initialState;
