import React from "react";
// data
import data from "country-region-data/data.json";
// antd
import Select from "antd/lib/select";
// helpers
import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";

export default function InputState(_props) {
  const opts = filter(data, ["countryShortCode", _props.country]);

  const options = opts.length
    ? map(get(opts, [0, "regions"], []), ({ name, shortCode }) => ({
        label: name,
        value: shortCode,
      }))
    : [];

  const filterOption = (input, option) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const props = {
    options,
    filterOption,
    size: "large",
    showSearch: true,
    placeholder: "Departamento",
    autoComplete: "new-password",
    ..._props,
  };

  return <Select {...props} />;
}
