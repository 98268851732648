import React from "react";
import { useQuery } from "@apollo/react-hooks";
// components
import Card from "./Card";
// helpers
import get from "lodash/get";
import map from "lodash/map";
// gql
import { getInfoMedic } from "views/microSalud/Appointments/Diary/gql";

export default function Medic({ id }) {
  const { data, loading } = useQuery(getInfoMedic, { variables: { id } });

  const { tags, ...attr } = get(data, "doctorById", {});

  const specialties = map(tags, "name");

  const propsCard = {
    ...attr,
    loading,
    specialties,
  };

  return <Card {...propsCard} />;
}
