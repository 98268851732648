import React from "react";

import { Row, Col, Card } from "antd";

import CitaNoEncontrada from "../components/citaNoEncontrada";
import useValidatorCita from "./useValidatorCita";
import BrandLoading from "../../../components/CitasUtils/BrandLoading";
import styles from "./styles.module.scss";
import ButtonFinish from "./buttonFinish";
import LlamadaListenerConfig from "./llamadaListenerConfig";
import { URLPATIENTCITAMD } from "../../../chatCall/utils";

import FormUpdateAppointment from "./formUpdateAppointment";
import InfoPaciente from "./infoPaciente";

import ChatScreen from "components/OpenFire/ChatScreen";

const ContainerSala = ({ info }) => {
  const { idAppointment } = info;
  const {
    dataAppointment,
    isError,
    isFinish,
    isRenderChat,
    notFound,
    loadingBrand,
    infoForChat,
    refetchStatus,
    listenerId,
  } = useValidatorCita(idAppointment);

  if (notFound || isError) {
    return <CitaNoEncontrada url='/medic' />;
  }

  if (loadingBrand || !dataAppointment) {
    return <BrandLoading />;
  }

  return (
    <div className='w-full h-screen mb-5'>
      <Row gutter={[8, 8]} className=' w-full h-full flex pl-3 pr-2 py-2'>
        <Col xs={24} sm={12} md={14} lg={11} className='h-auto'>
          <Row className='h-full' gutter={[16, 0]}>
            <Col xs={24}>
              <Card className={"w-full h-full " + styles.colVideo} bodyStyle={{ padding: 0 }}>
                <LlamadaListenerConfig
                  isRenderChat={isRenderChat}
                  infoAppointment={dataAppointment}
                  listenerID={listenerId}
                  isFinish={isFinish}
                  infoForChat={infoForChat}
                />
              </Card>
            </Col>
            <Col xs={24} className='pt-2'>
              <Card className={" w-full h-full " + styles.colInfoCita}>
                <InfoPaciente infoAppointment={dataAppointment} />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={12} md={10} lg={6}>
          <Card className={"w-full h-full " + styles.colFormDiagnostico}>
            <FormUpdateAppointment idAppointment={dataAppointment.id} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={7}>
          <Card bodyStyle={{ padding: 0 }} className={"w-full h-full " + styles.colFormDiagnostico}>
            <ButtonFinish
              isFinish={isFinish}
              isRenderChat={isRenderChat}
              infoAppointment={dataAppointment}
              refetch={refetchStatus}
            />
            <div
              className={"mt-0 h-full flex flex-col bg-gray-200 "}
              style={{ minHeight: "80vh", maxHeight: "90vh" }}
            >
              <ChatScreen
                infoAppointment={dataAppointment}
                infoForChat={infoForChat}
                user={infoForChat}
                usable={!isFinish}
                isFinish={isFinish}
                isPacient={false}
                sendFirstMessage={
                  !dataAppointment.log
                    ? false
                    : dataAppointment.log.firstMessageDoctor
                    ? false
                    : true
                }
                metaData={{
                  isNormalAppointment: false,
                  idAppointment: dataAppointment.id,
                  urlForNotification: URLPATIENTCITAMD,
                }}
              />
              {/* {!finishvalidate ? (
                <React.Fragment>
                  <BrandLoading cssClass="w-full flex justify-center items-center mt-auto" />
                  <p className="text-center font-semibold mb-auto">
                    Cargando información del chat
                  </p>
                </React.Fragment>
              ) : (
                
              )} */}
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ContainerSala;
