import React, { useState } from "react";
import { Modal, Button, Form, notification } from "antd";
import FormModalAdd from "./FormModalAdd";

const ModalAdd = (props) => {
  const [loadingAdd, setloadingAdd] = useState(false);
  const [form] = Form.useForm();

  const customFooter = (
    <div className='flex justify-between'>
      <Button
        disabled={loadingAdd}
        onClick={() => props.setvisbleModal(false)}
        type='primary'
        shape='round'
        danger
        size='large'
      >
        Cancel
      </Button>
      <Button
        disabled={loadingAdd}
        loading={loadingAdd}
        onClick={() => form.submit()}
        type='primary'
        shape='round'
        size='large'
      >
        Aceptar
      </Button>
    </div>
  );

  const onAddMutation = async (values) => {
    // console.log('onAddMutation', values)
    try {
      setloadingAdd(true);
      await props.onAddItem(values);
      notification.success({
        message: "El ítem se ha agregado exitosamente!",
      });
      props.setvisbleModal(false);
      form.resetFields();
      setloadingAdd(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      afterClose={() => {
        form.resetFields();
        setloadingAdd(false);
      }}
      destroyOnClose
      title={null}
      closable={false}
      visible={props.visbleModal}
      onOk={() => console.log("dsafaf")}
      onCancel={() => props.setvisbleModal(false)}
      footer={customFooter}
    >
      <h4 className='text-xl'>
        <strong>Añadir nuevo ítem</strong>
      </h4>
      <p>Para tener entradas múltiples, añade los ítems separados por una coma (,)</p>
      <FormModalAdd onFinishForm={onAddMutation} form={form} />
    </Modal>
  );
};

ModalAdd.defaultProps = {
  visbleModal: false,
  setvisbleModal: () => console.log("onCancel"),
};

export default ModalAdd;
