import gql from "graphql-tag";

export const doctorListGql = gql`
  query doctors($perPage: Float, $page: Float, $sort: [doctorSort!], $filters: [doctorFilter!]) {
    doctors(perPage: $perPage, page: $page, sort: $sort, filters: $filters) {
      total
      pages
      docs {
        id
        pictureURL {
          id
          url
        }
        fullName
        approvalStatus
        minsaNumber
        specialties {
          id
          name
        }
        appointmentPrice
        appointmentPriceEasyMD
      }
    }
  }
`;

export const infodoctorByIdGql = gql`
  query doctorById($id: String!) {
    doctorById(id: $id) {
      id
      sex
      fullName
      approvalStatus
      minsaNumber
      amountOfReviews
      rating
      balance
      email
      phoneNumber
      description
      approvalStatus
      signature {
        url
        mimetype
      }
      pictureURL {
        url
      }
      supportingDocuments {
        id
        title
        media {
          id
          url
          mimetype
        }
      }
      specialties {
        id
        name
      }
    }
  }
`;

export const rechazarAprobardoctorByIdGql = gql`
  query doctorById($id: String!) {
    doctorById(id: $id) {
      id
      fullName
      approvalStatus
      minsaNumber
      pictureURL {
        url
      }
    }
  }
`;

export const denyDoctorGql = gql`
  mutation denyDoctor($id: String!, $reason: String) {
    denyDoctor(id: $id, reason: $reason) {
      approvalStatus
    }
  }
`;

export const approveDoctorGql = gql`
  mutation approveDoctor($id: String!) {
    approveDoctor(id: $id) {
      approvalStatus
    }
  }
`;

export const updateAppointmentPrice = gql`
  mutation updateAppointmentPrice($id: String!, $price: Float!) {
    updateAppointmentPriceEasyMD(id: $id, appointmentPriceEasyMD: $price) {
      appointmentPriceEasyMD
    }
  }
`;
