import React, { useEffect, useContext } from "react";
import Select from "antd/lib/select";
import notification from "antd/lib/notification";
import { useQuery } from "@apollo/react-hooks";
import { SelectplansGql } from "./gql";
import { HandleError } from "utils.js/HandleError";
import { isArray } from "lodash";

// store
import { AffiliatesContext } from "../index";
import * as actionName from "../store/actionStore";

const SelectPlan = (_) => {
  const { state, dispatch } = useContext(AffiliatesContext);
  const { Option } = Select;
  const { data, loading, error } = useQuery(SelectplansGql, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
  }, [error]);

  const handleChangeSelectPlan = (value) => {
    if (isArray(value)) {
      dispatch({
        type: actionName.SET_PLANS,
        payload: value,
      });
    } else {
      dispatch({
        type: actionName.SET_PLANS,
        payload: value ? new Array(value) : [],
      });
    }
  };

  return (
    <Select
      value={state.filters.planId}
      loading={loading || _.loading}
      disabled={error || loading || _.loading || _.error}
      className='w-40 mx-3'
      onChange={handleChangeSelectPlan}
      allowClear
      placeholder='Plan del usuario'
    >
      {data &&
        data.plans.docs.map(
          (value, index) =>
            index <= 2 && (
              <Option key={index} value={value.id}>
                {value.name}
              </Option>
            )
        )}
    </Select>
  );
};

export default SelectPlan;
