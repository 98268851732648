import React, { useState } from "react";
import Modal from "antd/lib/modal";
import VerifyLoginWrapper from "components/VerifyLoginWrapper";
import TokenPaymentMethod from "components/TokenPaymentMethod";
import CardDetailPlan from "./CardDetailPlan";

const ModalPlan = (props) => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const { closeModal, dataPlan } = props;

  const onChangePayMethod = (values) => {
    setPaymentMethod(values);
  };

  return (
    <Modal
      destroyOnClose
      closable
      style={{
        minWidth: "20rem",
      }}
      className='xs:w-4/5 md:w-4/5 lg:w-4/6'
      title={null}
      footer={null}
      bodyStyle={{ padding: "0" }}
      visible
      onOk={() => console.log("sumit MOdal")}
      onCancel={() => closeModal()}
    >
      <VerifyLoginWrapper fullScreen={false}>
        <div className='p-4'>
          <div className='flex justify-center items-center'>
            <h2 className='text-xl text-primary'>
              <strong>
                Obtener <span className='text-success'>Plan EasyMD</span>
              </strong>
            </h2>
          </div>
          <div className='grid grid-cols-12 gap-4'>
            <div className='col-span-12 lg:col-span-7 my-2'>
              <TokenPaymentMethod
                onChange={(values) => onChangePayMethod(values)}
                tokenString={dataPlan.planId}
              />
            </div>
            <div className='col-span-12 lg:col-span-5 my-2'>
              <div className='my-6 lg:my-0'>
                <CardDetailPlan
                  onCloseModal={() => closeModal()}
                  paymentMethod={paymentMethod}
                  {...dataPlan}
                />
              </div>
            </div>
          </div>
        </div>
      </VerifyLoginWrapper>
    </Modal>
  );
};

export default ModalPlan;
