import React, { useContext } from "react";
import { Tabs } from "antd";
import FormCreditCard from "components/MethodPay/index";

import { SET_MY_METHOD, SET_TAB_METHOD, SET_SAVE_OTHER_METHOD } from "../../store/actions";
import { CheckOutContext } from "../index";

import Styles from "../styles.module.scss";

const { TabPane } = Tabs;

const Paso3DetallePaciente = (props) => {
  const { dispatch } = useContext(CheckOutContext);

  const onChangeCreditCard = (value) => {
    if (value) {
      dispatch({
        type: SET_MY_METHOD,
        payload: {
          info: value.target.value,
        },
      });
      dispatch({
        type: SET_SAVE_OTHER_METHOD,
        payload: null,
      });
    }
  };

  const onChangeTab = (value) => {
    dispatch({
      type: SET_TAB_METHOD,
      payload: value,
    });
  };

  return (
    <div className={"w-full mb-5 " + props.className}>
      <div className='w-full flex flex-col'>
        <p className='text-lg font-bold'>Método de pago</p>
        <p className='text-sm xs:w-full md:w-4/5'> Pagos electrónicos seguros y facil! </p>
      </div>
      <div className={Styles.stetoscopioImg + " xs:hidden md:block"} />
      <div className='card-container'>
        <Tabs
          type='card'
          style={{ minHeight: "250px" }}
          defaultActiveKey='1'
          onChange={onChangeTab}
        >
          <TabPane tab='Mis métodos de pago' key='1'>
            <FormCreditCard onChange={onChangeCreditCard} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Paso3DetallePaciente;
