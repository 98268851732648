import React, { useState, useContext, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
import { HandleError } from "utils.js/HandleError";
// componet
import ModalAgregarIntervalo from "./ModalAgregarIntervalo";
import { profileMedicContext } from "../../../index";

// ant
import { Tag, Spin, Divider, Button, notification, Empty } from "antd";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// Taps
import {
  googleCalendarAuthGql,
  doctorByIdWorkingDays,
  updateMedicWorkingDays,
  updateActiveCalendarGql,
} from "../../gqlTags";
import { faGoogle, faWindows } from "@fortawesome/free-brands-svg-icons";
import getDefaultWorkingDays from "./getDefaultWorkingDays";
import ModalGoogleIntegration from "./ModalGoogleIntegration";

const CardMiSemanaDeTrabajo = (props) => {
  const [visbleModal, setModalVisible] = useState(false);
  const [visbleGoogleIntegrationModal, setVisbleGoogleIntegrationModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const { state } = useContext(profileMedicContext);
  const [isGoogleCalendarActivated, setIsGoogleCalendarActivated] = useState(false);
  const [
    updateActiveCalendar,
    { loading: updateActiveCalendarLoading, data: updateActiveCalendarData },
  ] = useMutation(updateActiveCalendarGql, {
    refetchQueries: ["doctorById"],
  });

  const [updateWorkingDays, { error: updateWorkingDaysError }] = useMutation(
    updateMedicWorkingDays,
    {
      refetchQueries: ["doctorById"],
    }
  );

  const updateFixedWorkingDays = () => {
    const intervals = getDefaultWorkingDays();
    const workingDays = intervals.map((value) => ({
      from: value.from,
      to: value.to,
    }));
    try {
      updateWorkingDays({
        variables: {
          workingDays,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (updateWorkingDaysError) {
      notification.error({
        message: "Error",
        description: HandleError(updateWorkingDaysError),
      });
    }
  }, [updateWorkingDaysError]);

  const [
    googleCalendarAuth,
    {
      data: googleCalendarAuthData,
      loading: googleCalendarAuthLoading,
      error: googleCalendarAuthError,
      startPolling: startGoogleCalendarAuthPolling,
      stopPolling: stopGoogleCalendarAuthPolling,
    },
  ] = useLazyQuery(googleCalendarAuthGql, {
    variables: { email: state.dataMedic.email },
  });

  const [reqWorkingDays, { data, loading, error, startPolling, stopPolling }] = useLazyQuery(
    doctorByIdWorkingDays,
    {
      variables: { id: state.dataMedic.id },
    }
  );

  useEffect(() => {
    const hasGoogleCalendarAccess = googleCalendarAuthData?.googleCalendarAuth;

    if (visbleGoogleIntegrationModal && hasGoogleCalendarAccess) {
      setVisbleGoogleIntegrationModal(false);
      updateFixedWorkingDays();
      updateActiveCalendar({
        variables: { activeCalendar: "GOOGLE" },
      });
      return;
    } else {
    }
    const activeCalendar = data && data.doctorById.activeCalendar;
    const isGoogleCalendarActivated = activeCalendar === "GOOGLE";
    if (hasGoogleCalendarAccess && isGoogleCalendarActivated) {
      setIsGoogleCalendarActivated(true);
      stopGoogleCalendarAuthPolling();
    }
  }, [googleCalendarAuthData, data, visbleGoogleIntegrationModal, state.dataMedic.id]);

  useEffect(() => {
    const activeCalendar = updateActiveCalendarData?.updateDoctor?.activeCalendar;
    if (activeCalendar) {
      if (activeCalendar === "GOOGLE") {
        setIsGoogleCalendarActivated(true);
      }
      if (activeCalendar === "MANUAL") {
        setIsGoogleCalendarActivated(false);
      }
    }
  }, [updateActiveCalendarData]);
  function switToManualCalendar() {
    updateActiveCalendar({ variables: { activeCalendar: "MANUAL" } });
  }

  const parseInterval = (workingDays) => {
    return workingDays.reduce(
      (x, u) => {
        const day = moment(u.from).day();
        x[day].push(u);
        return x;
      },
      {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      }
    );
  };

  const isManualCalendar = data && data.doctorById.activeCalendar === "MANUAL";

  useEffect(() => {
    if (state.dataMedic.id && typeof startPolling === "function") {
      startPolling(2000);
    }
    return () => {
      if (state.dataMedic.id && typeof stopPolling === "function") {
        stopPolling();
      }
    };
  }, [state.dataMedic.id]);

  var workingDays = data && parseInterval(data.doctorById.workingDays);

  useEffect(() => {
    notification.info({
      message: "Calendario",
      description: "Recuerda actualizar tu horario",
    });
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
  }, [error]);

  useEffect(() => {
    if (state.dataMedic.id) {
      reqWorkingDays();
      googleCalendarAuth();
    }
  }, [state.dataMedic.id]);

  if (error) {
    return <Empty />;
  }

  function handleClick() {
    if (isGoogleCalendarActivated) {
      window.open("https://calendar.google.com/calendar/u/0/", "_blank");
    } else {
      startGoogleCalendarAuthPolling(2000);
      setVisbleGoogleIntegrationModal(true);
    }
  }

  return (
    <Spin wrapperClassName='overflow-auto' spinning={!data || loading}>
      <ModalAgregarIntervalo
        arrayInterval={data ? data.doctorById.workingDays : []}
        selectedDay={selectedDay}
        visbleModal={visbleModal}
        setModalVisible={setModalVisible}
      />
      <ModalGoogleIntegration
        visbleModal={visbleGoogleIntegrationModal}
        setModalVisible={setVisbleGoogleIntegrationModal}
      />
      <div className='flex flex-row'>
        <h4 className='text-2xl'>
          <strong>Mi Semana de Trabajo</strong>
        </h4>
        <Button
          className='pr-1 ml-auto'
          type='text'
          size='large'
          loading={googleCalendarAuthLoading}
          disabled={googleCalendarAuthLoading}
          onClick={handleClick}
        >
          <FontAwesomeIcon
            className='pr-2 text-red-600 hover:text-red-900'
            icon={faGoogle}
            size='lg'
          />
          <span className='font-semibold'>+</span> {!isGoogleCalendarActivated && "Integrar"} Google
          Calendar
        </Button>
        <Button
          className='pr-1'
          type='text'
          size='large'
          href='https://outlook.live.com/calendar/0/view/week'
          target='_blank'
        >
          <FontAwesomeIcon
            className='pr-2 text-blue-600 hover:text-blue-900'
            icon={faWindows}
            size='lg'
          />
          <span className='font-semibold'>+</span> Outlook Calendar
        </Button>
      </div>

      {isGoogleCalendarActivated && (
        <>
          <Button
            type='primary'
            loading={updateActiveCalendarLoading}
            disabled={updateActiveCalendarLoading}
            onClick={switToManualCalendar}
          >
            Cambiar a calendario manual
          </Button>
          <div className='pr-1 ml-auto'>
            <span className='font-bold'>Calendario activado : </span>{" "}
            <FontAwesomeIcon
              className='pr-2 text-red-600 hover:text-red-900'
              icon={faGoogle}
              size='lg'
            />
            <span className='font-semibold'>+</span>Google Calendar
          </div>
        </>
      )}

      <div
        style={{
          minWidth: "60rem",
          overflow: "auto",
        }}
        className='grid grid-cols-14 gap-4 h-full p-3'
      >
        <div className='col-span-2'>
          <h3 className='text-xl mb-0 capitalize text-center'>Lunes</h3>
          {workingDays
            ? workingDays[1].map((u, index) => (
                <TagCitaAgendada
                  totalInterval={data ? data.doctorById.workingDays : []}
                  key={index}
                  valueInterval={u}
                  isManualCalendar={!isGoogleCalendarActivated}
                />
              ))
            : null}
          {!isGoogleCalendarActivated && (
            <TagAgregateCita
              day={1}
              setSelectedDay={setSelectedDay}
              setModalVisible={setModalVisible}
            />
          )}
        </div>
        <div className='col-span-2'>
          <h3 className='text-xl mb-0 capitalize text-center'>martes</h3>
          {workingDays
            ? workingDays[2].map((u, index) => (
                <TagCitaAgendada
                  totalInterval={data ? data.doctorById.workingDays : []}
                  key={index}
                  valueInterval={u}
                  isManualCalendar={!isGoogleCalendarActivated}
                />
              ))
            : null}
          {!isGoogleCalendarActivated && (
            <TagAgregateCita
              day={2}
              setSelectedDay={setSelectedDay}
              setModalVisible={setModalVisible}
            />
          )}
        </div>
        <div className='col-span-2'>
          <h3 className='text-xl mb-0 capitalize text-center'>miercoles</h3>
          {workingDays
            ? workingDays[3].map((u, index) => (
                <TagCitaAgendada
                  totalInterval={data ? data.doctorById.workingDays : []}
                  key={index}
                  valueInterval={u}
                  isManualCalendar={!isGoogleCalendarActivated}
                />
              ))
            : null}
          {!isGoogleCalendarActivated && (
            <TagAgregateCita
              day={3}
              setSelectedDay={setSelectedDay}
              setModalVisible={setModalVisible}
            />
          )}
        </div>
        <div className='col-span-2'>
          <h3 className='text-xl mb-0 capitalize text-center'>jueves</h3>
          {workingDays
            ? workingDays[4].map((u, index) => (
                <TagCitaAgendada
                  totalInterval={data ? data.doctorById.workingDays : []}
                  key={index}
                  valueInterval={u}
                  isManualCalendar={!isGoogleCalendarActivated}
                />
              ))
            : null}
          {!isGoogleCalendarActivated && (
            <TagAgregateCita
              day={4}
              setSelectedDay={setSelectedDay}
              setModalVisible={setModalVisible}
            />
          )}
        </div>
        <div className='col-span-2'>
          <h3 className='text-xl mb-0 capitalize text-center'>viernes</h3>
          {workingDays
            ? workingDays[5].map((u, index) => (
                <TagCitaAgendada
                  totalInterval={data ? data.doctorById.workingDays : []}
                  key={index}
                  valueInterval={u}
                  isManualCalendar={!isGoogleCalendarActivated}
                />
              ))
            : null}
          {!isGoogleCalendarActivated && (
            <TagAgregateCita
              day={5}
              setSelectedDay={setSelectedDay}
              setModalVisible={setModalVisible}
            />
          )}
        </div>
        <div className='col-span-2'>
          <h3 className='text-xl mb-0 capitalize text-center'>sabado</h3>
          {workingDays
            ? workingDays[6].map((u, index) => (
                <TagCitaAgendada
                  totalInterval={data ? data.doctorById.workingDays : []}
                  key={index}
                  valueInterval={u}
                  isManualCalendar={!isGoogleCalendarActivated}
                />
              ))
            : null}
          {!isGoogleCalendarActivated && (
            <TagAgregateCita
              day={6}
              setSelectedDay={setSelectedDay}
              setModalVisible={setModalVisible}
            />
          )}
        </div>
        <div className='col-span-2'>
          <h3 className='text-xl mb-0 capitalize text-center'>domingo</h3>
          {workingDays
            ? workingDays[0].map((u, index) => (
                <TagCitaAgendada
                  totalInterval={data ? data.doctorById.workingDays : []}
                  key={index}
                  valueInterval={u}
                  isManualCalendar={!isGoogleCalendarActivated}
                />
              ))
            : null}
          {!isGoogleCalendarActivated && (
            <TagAgregateCita
              day={7}
              setSelectedDay={setSelectedDay}
              setModalVisible={setModalVisible}
            />
          )}
        </div>
      </div>
    </Spin>
  );
};

const TagAgregateCita = (propsTags) => {
  return (
    <Tag
      onClick={() => {
        propsTags.setSelectedDay(propsTags.day);
        propsTags.setModalVisible(true);
      }}
      className='w-full cursor-pointer border-dashed border-2 border-primary text-success text-base px-4 py-2 mt-2 rounded-lg text-center'
    >
      Añadir <br />
      Intervalo
    </Tag>
  );
};

const TagCitaAgendada = (propsCita) => {
  const { valueInterval, totalInterval, isManualCalendar } = propsCita;

  let newArray = totalInterval.filter((item) => item !== valueInterval);

  newArray = newArray.map((value) => ({ from: value.from, to: value.to }));

  const [updateWorkingDays, { error }] = useMutation(updateMedicWorkingDays, {
    refetchQueries: ["doctorById"],
  });

  const onCloseHandles = () => {
    try {
      updateWorkingDays({
        variables: {
          workingDays: newArray,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
  }, [error]);

  return (
    <div className='py-2'>
      <Tag
        className={`bg-primary text-base rounded-lg border-none py-1 mr-0 ${
          isManualCalendar ? "px-4" : "px-6"
        }`}
      >
        <div className={`grid grid-cols-12 ${isManualCalendar ? "gap-2" : ""}`}>
          <div
            className={`${
              isManualCalendar ? "col-span-11 border-solid border-r-2 border-white" : "col-span-12"
            }`}
          >
            <span className='text-white '>{moment(valueInterval.from).format("hh:mm a")}</span>
            <div className='my-1 mx-auto w-4/12 justify-center'>
              <Divider className='m-0 p-0' />
            </div>
            <span className='text-white '>{moment(valueInterval.to).format("hh:mm a")}</span>
          </div>
          {isManualCalendar && (
            <div className='col-span-1 flex justify-center items-center '>
              <Button onClick={() => onCloseHandles()} className='pr-2' type='text' size='large'>
                <FontAwesomeIcon className=' text-white hover:text-red-600' icon={faTimes} />
              </Button>
            </div>
          )}
        </div>
      </Tag>
    </div>
  );
};

export default CardMiSemanaDeTrabajo;
