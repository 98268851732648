import React, { useContext } from "react";
import gql from "graphql-tag";
import { Input } from "antd";

import SelectPaciente from "./selectPaciente";

import { SET_RAZON_CITA, SET_SINTOMAS } from "../../store/actions";
import { CheckOutContext } from "../index";
import Adjuntos from "../adjuntosCollapse";
import Styles from "../styles.module.scss";
import InputWordCloud from "../../../../../components/InputWordCloud";

const { TextArea } = Input;

const symptomsListGql = () => {
  return gql`
    query symptoms($page: Float, $perPage: Float, $filters: [symptomFilter!]) {
      symptoms(page: $page, perPage: $perPage, filters: $filters) {
        docs {
          value: id
          label: name
        }
      }
    }
  `;
};

const Paso1DetallePaciente = (props) => {
  const { dispatch, state } = useContext(CheckOutContext);
  const { sintomas: listaSintomas } = state;
  const onChangeSelectSearchSintomas = (newValue) => {
    dispatch({
      type: SET_SINTOMAS,
      payload: {
        sintomas: newValue.length > 0 ? newValue : null,
      },
    });
  };

  const onChangeTextAreaRazonCita = (e) => {
    if (e.target) {
      dispatch({
        type: SET_RAZON_CITA,
        payload: {
          razonCita: e.target.value.length > 0 ? e.target.value : null,
        },
      });
    } else {
      dispatch({
        type: SET_RAZON_CITA,
        payload: {
          razonCita: null,
        },
      });
    }
  };

  return (
    <div className={"w-full mb-5 " + props.className}>
      <div className='w-full flex flex-col'>
        <p className='text-lg font-bold'>Detalle del paciente</p>
        <p className='text-sm xs:w-full md:w-4/5'>
          {" "}
          Llena tu Historial Clínico Electrónico de manera RÁPIDA y SEGURA, contestando las
          siguientes preguntas
        </p>
      </div>
      <div className={Styles.stetoscopioImg + " xs:hidden md:block"} />

      <div className='mt-8'>
        <label>Paciente</label>

        <SelectPaciente />
      </div>

      <div className='mt-5'>
        <label>Razón de la cita</label>
        <TextArea
          value={state.razonCita}
          className='mt-2'
          rows={3}
          placeholder='Razón de la cita'
          onChange={onChangeTextAreaRazonCita}
        />
      </div>

      <div className='w-full mt-5'>
        <label className='mb-2'>Síntomas del paciente</label>
        <InputWordCloud
          gql={symptomsListGql()}
          value={listaSintomas || []}
          path='symptoms.docs'
          placeholder='Buscar síntomas'
          onChange={onChangeSelectSearchSintomas}
          className='w-full mt-2'
        />
      </div>

      <div className='w-100 mt-5' style={{ minHeight: "100px" }}>
        <Adjuntos />
      </div>
    </div>
  );
};

export default Paso1DetallePaciente;
