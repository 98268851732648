import React from "react";
//
import CheckCircle from "./index";

export default function ListCheckCircle({ items, ...attrs }) {
  return (
    <ul {...attrs}>
      {items.map((item, key) => (
        <li>
          <CheckCircle key={key}>{item}</CheckCircle>
        </li>
      ))}
    </ul>
  );
}
