import React, { useState } from "react";
import cls from "classnames/bind";
// helpers
import map from "lodash/map";
import join from "lodash/join";
// antd
import Modal from "antd/lib/modal";
import Space from "antd/lib/space";
import Button from "antd/lib/button";
// icons
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
// style
import style from "./style.module.scss";

const cx = cls.bind(style);

export default function Card(props) {
  const { id, name, type, onRemove } = props;
  const [removing, setRemoving] = useState(false);

  const num = `${join(
    map(name.substring(0, name.length - 4), () => "X"),
    ""
  )}${name.substring(name.length - 4, name.length)}`;

  const onClick = () => {
    Modal.confirm({
      onOk: () => {
        onRemove(id);
        setRemoving(true);
      },
      title: "Remover Método te pago",
      okText: "Si",
      cancelText: "No",
    });
  };

  const propsBtnRemove = {
    onClick,
    type: "link",
    icon: <FA icon={faTrashAlt} />,
    danger: true,
    loading: removing,
    disabled: !onRemove || removing,
  };

  const cls = type.includes("ame") ? "amex" : type;

  return (
    <Space className={cx("card", cls)}>
      <span>{num}</span>
      {id && onRemove && <Button {...propsBtnRemove} />}
    </Space>
  );
}
