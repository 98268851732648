import gql from "graphql-tag";

export const getPlansSource = gql`
  query getPlansSource($filters: [planFilter!]) {
    plans(filters: $filters) {
      docs {
        id
        name
        disabled
        comprable
        description
        country
        periods {
          name
          price
          paymentFrequency
          __typename @include(if: false)
        }
        features {
          id
        }
        __typename @include(if: false)
      }
    }
    features: planfeatures {
      docs {
        key: id
        id
        name
        description
      }
    }
  }
`;

export const updatePlanFeature = gql`
  mutation updatePlanFeature($id: String!, $features: [String!]) {
    updatePlan(id: $id, features: $features) {
      name
      features {
        id
      }
    }
  }
`;

export const createPlan = gql`
  mutation createPlan($name: String!, $country: NationalityEnum!) {
    createPlan(name: $name, country: $country) {
      id
    }
  }
`;

export const updatePlanInfo = gql`
  mutation updatePlanInfo(
    $id: String!
    $name: String
    $periods: [periodInput!]
    $disabled: Boolean
    $description: String
    $comprable: Boolean
  ) {
    updatePlan(
      id: $id
      name: $name
      periods: $periods
      disabled: $disabled
      comprable: $comprable
      description: $description
    ) {
      id
    }
  }
`;

export const createFeature = gql`
  mutation createPlanFeature($name: String!, $description: String) {
    createPlanFeature(name: $name, description: $description) {
      id
    }
  }
`;

export const updateFeature = gql`
  mutation updatePlanFeature($id: String!, $name: String!, $description: String) {
    updatePlanFeature(id: $id, name: $name, description: $description) {
      id
    }
  }
`;

export const getPlanFeature = gql`
  query getPlanFeatures {
    planfeatures {
      total
      current: page
      pageSize: perPage
      dataSource: docs {
        id
        name
        description
      }
    }
  }
`;

export const getPlans = gql`
  query getPlans {
    plans {
      total
      current: page
      pageSize: perPage
      dataSource: docs {
        id
        name
        description
      }
    }
  }
`;

export const deletePlanById = gql`
  mutation deletePlanById($id: String!) {
    deletePlanById(id: $id)
  }
`;

export const deletePlanFeatureById = gql`
  mutation deletePlanfeatureById($id: String!) {
    deletePlanfeatureById(id: $id)
  }
`;

export const vastmindzToken = gql`
  mutation vastmindzTokens($rppg_token: String!, $socket_url: String!) {
    vastmindzTokens(rppg_token: $rppg_token, socket_url: $socket_url) {
      id
    }
  }
`;
