import BtnDetail from "./Detail";

export default [
  {
    key: "fullName",
    title: "Nombre Completo",
    dataIndex: "fullName",
  },
  {
    key: "minsaNumber",
    title: "# MINSA",
    dataIndex: "minsaNumber",
    className: "text-center",
  },
  {
    key: "details",
    render: BtnDetail,
    dataIndex: "id",
    className: "text-right",
  },
];
