import React, { useState } from "react";
import { Card, Button } from "antd";
import Person from "components/Person";
import get from "lodash/get";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import moment from "moment";

import props from "../../CompanyCodes/propsStatistic";
import Statistic from "components/StatisticCard";

import { faFilePdf, faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TotalAppointments from "./totalAppointments";
import AvgTimeAppointments from "./avgTimeAppointments";
import AvgWaitingAppointments from "./avgWaitingAppointments";
import WordCloudSymptoms from "./wordCloudSymptoms";

const IDS_VIEW = {
  container: "container_kpi",
  container_no_name: "container_no_name_kpi",
  statistic: "statistic_kpi",
  chart1: "chart1_kpi",
  chart2: "chart2_kpi",
  chart3: "chart3_kpi",
  chart4: "chart4_kpi",
};

const ChartsContainter = ({ data, id }) => {
  const [loadingPdf, setLoadingPdf] = useState(false);

  const person = {
    id: id,
    role: get(data, ["role", "name"], "N/A"),
    gql: {
      upPic: null,
    },
    pic: get(data, ["pictureURL", "url"]),
    name: null,
    label: null,
    onSelect: () => {},
    isSimple: true,
    selected: false,
    size: 0,
  };

  const printDocument = async () => {
    setLoadingPdf(true);

    setTimeout(() => {
      const input = document.getElementById(IDS_VIEW.container_no_name);
      html2canvas(input)
        .then((canvas) => {
          const dateFormat = moment().format("DD-MM-YYYY");
          let imgWidth = 208;
          let imgHeight = (canvas.height * imgWidth) / canvas.width;
          const imgData = canvas.toDataURL("img/png");
          const pdf = new jsPDF("p", "mm", "a4");
          pdf.text(5, 10, get(data, ["fullName"], dateFormat));
          pdf.addImage(imgData, "PNG", 0, 12, imgWidth, imgHeight);
          pdf.save(dateFormat + "_company_export.pdf");
          setTimeout(() => {
            setLoadingPdf(false);
          }, 1000);
        })
        .catch(() => {
          setLoadingPdf(false);
        });
    }, 2000);
  };

  return (
    <div className='w-full min-h-25rem px-4 mb-8' id={IDS_VIEW.container}>
      <div className='flex flex-row items-center mt-1'>
        <Person.Card {...person} />
        <p className='text-xl font-bold mb-0'>{get(data, "fullName")} </p>
        <Button
          type='primary'
          disabled={loadingPdf}
          className='ml-auto'
          onClick={() => printDocument()}
          icon={
            <FontAwesomeIcon
              className='mx-2'
              size='lg'
              icon={loadingPdf ? faCloudDownloadAlt : faFilePdf}
            />
          }
        >
          {loadingPdf ? "Creando PDF ..." : "Exportar a PDF"}
        </Button>
      </div>

      <div id={IDS_VIEW.container_no_name}>
        <div className='w-full mt-1' id={IDS_VIEW.statistic}>
          <div className='flex -mx-2'>
            <div className='xs:w-1/2 md:w-1/2 lg:w-2/6 px-2' id='statistic_1'>
              <Statistic {...props.patientsTrue(id)} className='shadow-none' />
            </div>
            <div className='xs:w-1/2 md:w-1/2 lg:w-2/6 px-2' id='statistic_2'>
              <Statistic {...props.patientsFalse(id)} className='shadow-none' />
            </div>
            <div className='xs:w-1/2 md:w-1/2 lg:w-2/6 px-2' id='statistic_3'>
              <Statistic {...props.totalAppointments(id)} className='shadow-none' />
            </div>
          </div>
        </div>
        <div className='flex xs:flex-col lg:flex-row gap-4 mt-4'>
          <Card
            id={IDS_VIEW.chart1}
            className='xs:w-full md:w-full lg:w-3/6 min-h-200px shadow-none '
            bodyStyle={{ padding: "1rem" }}
          >
            <TotalAppointments id={id} />
          </Card>
          <Card
            id={IDS_VIEW.chart2}
            className='xs:w-full md:w-full lg:w-3/6 min-h-200px shadow-none '
            bodyStyle={{ padding: "1rem" }}
          >
            <AvgTimeAppointments id={id} />
          </Card>
        </div>

        <Card
          id={IDS_VIEW.chart3}
          className='w-full min-h-200px flex-1 mt-4 shadow-none '
          bodyStyle={{ padding: "1rem" }}
        >
          <AvgWaitingAppointments id={id} />
        </Card>
        <Card
          id={IDS_VIEW.chart4}
          className='w-full min-h-200px flex-1 mt-4 shadow-none'
          bodyStyle={{ padding: "1rem" }}
        >
          <WordCloudSymptoms id={id} />
        </Card>
      </div>
    </div>
  );
};

export default ChartsContainter;
