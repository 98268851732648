import React from "react";
import Lottie from "react-lottie";
import EasyMDLoading from "../../../assets/lottie/easyMD_loading.json";

const BrandLoading = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: EasyMDLoading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Lottie isClickToPauseDisabled options={defaultOptions} height={"auto"} width={props.width} />
  );
};

BrandLoading.defaultProps = {
  width: "82%",
};

export default BrandLoading;
