import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Card, Alert, Spin, Empty, Statistic } from "antd";
import { Column } from "@ant-design/charts";

import SelectorRange, { getDefaultDates } from "components/SelectorRange";
import LoadingSpin from "./loadingSpin";

import isEmpty from "lodash/isEmpty";
import { secondsToString } from "../utils";

import { getAVGForAppointmentsMDGql } from "../gqlTags";

const AvgTimeAppointments = ({ id }) => {
  const [datesSelect, setDatesSelect] = useState(getDefaultDates());
  const [dataChart, setDataChart] = useState([]);
  const [totalGeneral, setTotalGeneral] = useState(0);
  const [totalPsychology, setTotalPsychology] = useState(0);

  const { data, loading, error } = useQuery(getAVGForAppointmentsMDGql, {
    variables: {
      from: datesSelect.from,
      to: datesSelect.to,
      company: id,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!loading) {
      if (data && !error) {
        const { getAVGForAppointmentsMD } = data;
        let totalPsi = 0;
        let totalGen = 0;
        let countPsi = 0;
        let countGen = 0;
        const newDataParse = [];
        let spanishValue = "";
        getAVGForAppointmentsMD.map((value) => {
          if (value.category === "psychology") {
            totalPsi += value.value;
            countPsi++;
            spanishValue = "Psicología";
          }
          if (value.category === "general") {
            totalGen += value.value;
            countGen++;
            spanishValue = "General";
          }
          newDataParse.push({
            ...value,
            category: spanishValue,
          });
          return null;
        });
        setDataChart(newDataParse);
        setTotalGeneral(countGen > 0 ? secondsToString(totalGen / countGen) : "00:00:00");
        setTotalPsychology(countPsi > 0 ? secondsToString(totalPsi / countPsi) : "00:00:00");
      } else {
        setDataChart([]);
        setTotalGeneral(0);
        setTotalPsychology(0);
      }
    }
  }, [loading, error, data]);

  const changeValueMonth = (value) => {
    setDatesSelect(value);
  };

  var config = {
    data: dataChart,
    isGroup: true,
    xField: "date",
    yField: "value",
    seriesField: "category",
    color: ["#2497CC", "#7AAF37"],
    yAxis: {
      label: {
        formatter: function formatter(v) {
          if (v === 0 || v === "0") {
            return "00:00:00";
          }
          return secondsToString(v);
        },
      },
    },
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <h5 style={{ marginTop: 16, fontWeight: 400 }}>{title}</h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { name, value, color } = item;
                return (
                  <li
                    key={title}
                    className='g2-tooltip-list-item'
                    data-index={index}
                    style={{ marginBottom: 4, display: "flex", alignItems: "center" }}
                  >
                    <span className='g2-tooltip-marker' style={{ backgroundColor: color }} />
                    <span
                      style={{ display: "inline-flex", flex: 1, justifyContent: "space-between" }}
                    >
                      <span style={{ margiRight: 16 }}>{name}:</span>
                      <span className='ml-3 g2-tooltip-list-item-value'>
                        {secondsToString(value)}
                      </span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      },
    },
  };

  return (
    <div className='flex flex-col h-full'>
      <div className='flex xs:flex-col md:justify-between md:flex-row gap-4'>
        <p className='mb-0 text-lg font-semibold'>Tiempo promedio de consultas</p>
        <SelectorRange onChange={changeValueMonth} loading={loading} disabled={loading} />
      </div>
      {error && !loading ? (
        <Alert
          message='Server Error'
          description='Error al obtener la información de las citas'
          type='error'
          className='w-full mx-auto my-auto'
          showIcon
        />
      ) : (
        <div className='flex flex-col h-full'>
          <div className='flex xs:flex-col lg:flex-row gap-4 mt-4'>
            <Card
              className='xs:w-full md:w-full lg:w-3/6 shadow-none '
              bodyStyle={{ padding: "1rem" }}
            >
              <LoadingSpin loading={loading} />
              {!loading && (
                <div className='flex flex-col'>
                  <p className='text-lg mb-0'>Medicina General: </p>
                  <Statistic className='text-2xl mb-0' title={null} value={totalGeneral} />
                </div>
              )}
            </Card>
            <Card
              className='xs:w-full md:w-full lg:w-3/6 shadow-none '
              bodyStyle={{ padding: "1rem" }}
            >
              <LoadingSpin loading={loading} />
              {!loading && (
                <div className='flex flex-col'>
                  <p className='text-lg mb-0'>Psicología: </p>
                  <Statistic className='text-2xl mb-0' title={null} value={totalPsychology} />
                </div>
              )}
            </Card>
          </div>
          <div className='mt-4 w-full'>
            {isEmpty(dataChart) && !loading ? (
              <Empty className='my-5' description='No se encontraron consultas' />
            ) : (
              <Spin spinning={loading}>
                <Column {...config} />
              </Spin>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AvgTimeAppointments;
