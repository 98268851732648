// import BtnDetail from "./Detail";

export default [
  {
    key: "service",
    title: "Servicio",
    dataIndex: "service",
    className: "capitalize",
  },
  {
    key: "total",
    title: "Total",
    dataIndex: "total",
    className: "text-center",
    render: (row, full) => renderTotal(row, full),
  },
  // {
  //   key: "details",
  //   render: BtnDetail,
  //   dataIndex: "id",
  //   className: "text-right",
  // },
];

const renderTotal = (row, full) => {
  const checkCurrency = {
    plan: "US$",
    merchant: "C$",
    authorized: "US$",
    appointments: "US$",
  };
  return `${checkCurrency[full.service]} ${row.slice(
    0,
    row.indexOf(".") ? row.indexOf(".") + 3 : -1
  )}`;
};
