import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import QueueAnim from "rc-queue-anim";
// gql
import { getMeBeneficiaries, FindBeneficiaries } from "views/Patient/gql";
// components
import Person from "components/Person";
import Empty from "./Empty";
import BtnAdd from "./BtnAdd";
import SkeletonCard from "components/Person/Card/Skeleton";
import UpgradeUnderAge from "./UpgradeUnderAge";
// context
import Context from "views/Patient/Context";
import { GlobalContext } from "views/App";
// gql
import {
  upPicBeneficiary as upPic,
  removeBeneficiary as remove,
  removeBeneficiaryAcc as removeAcc,
  removePendingBeneficiary,
} from "views/Patient/gql";
// helpers
import get from "lodash/get";
// style
import style from "./style.module.scss";

export default function ListBeneficiaries({ capacity = 0 }) {
  const { state } = useContext(GlobalContext);
  const { person, setPerson } = useContext(Context);
  const { data, loading, refetch } = useQuery(getMeBeneficiaries, {
    variables: { id: state.user.id },
    fetchPolicy: "no-cache",
  });

  const {
    data: beneficiaryAcc,
    loading: beneficiaryLoading,
    refetch: beneficiaryRefetch,
  } = useQuery(FindBeneficiaries, {
    variables: { id: state.user.id },
    fetchPolicy: "no-cache",
  });

  const group = get(data, "patientById.beneficiaries", []).filter(
    (beneficiary) => beneficiary.beneficiaryToPatient === (false || null)
  );
  const groupAcc = [
    ...get(beneficiaryAcc, "findBeneficiariesAccounts", []),
    ...get(beneficiaryAcc, "findBeneficiariesToPatients", []),
  ];
  const groupPending = get(data, "patientById.pendingBeneficiary", []);

  const onRemove = () => {
    setPerson(state.user.id);
    refetch();
    beneficiaryRefetch();
  };

  const props = {
    Queue: {
      type: ["right", "left"],
      delay: 750,
      className: style.group,
      component: "section",
      leaveReverse: true,
    },
    BtnAdd: {
      refetch,
    },
    UpgradeUnderAge: {
      group,
    },
  };

  if (loading || beneficiaryLoading)
    return (
      <section className={style.group}>
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </section>
    );

  if (group.length || groupAcc.length || groupPending.length)
    return (
      <QueueAnim {...props.Queue}>
        <UpgradeUnderAge {...props.UpgradeUnderAge} />
        {capacity > group?.length + groupAcc?.length && <BtnAdd {...props.BtnAdd} />}

        {group.map(({ id, pic, ...attr }) => {
          const props = {
            id,
            onRemove,
            key: id,
            pic: pic.url,
            gql: { upPic, remove },
            label: "Beneficiario",
            onSelect: setPerson,
            selected: id === person,
            ...attr,
          };
          return <Person.Card {...props} />;
        })}

        {groupAcc.map(({ id, pic, ...attr }) => {
          const props = {
            id,
            onRemove,
            key: id,
            pic: pic.url,
            gql: { upPic, removeAcc },
            label: "Beneficiario",
            account: true,
            ...attr,
          };
          return <Person.Card {...props} />;
        })}

        {groupPending.map((email, i) => {
          const props = {
            id: email,
            onRemove,
            key: i,
            gql: { removePendingBeneficiary },
            label: "Pendiente",
            onSelect: () => {},
            name: email,
            pending: true,
          };
          return <Person.Card {...props} />;
        })}
      </QueueAnim>
    );
  else return <Empty />;
}
