import { useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";
import { LANDING_URL } from "utils.js/constants";

export default function NewRoute() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.location.href =
      LANDING_URL + (newRoute[pathname.slice(pathname.lastIndexOf("/"))] || "");
  });
  const newRoute = {
    "/home": "/",
    "/familiar": "/",
    "/empresa": "/para-empresas",
  };
  return null;
}
