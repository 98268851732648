import React, { useContext, useEffect } from "react";
import { SET_INITIAL_STATE } from "../store/actions";
import { Alert, Avatar, Button, Divider, Modal, notification, Spin } from "antd";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { deleteDoctormdByIdGql, rechazarAprobarDoctorByIdGql } from "../gqlTags";

import { ContextTablaDoctoresMD } from "../index";
import { HandleError } from "../../../../utils.js/HandleError";

const ModalDelete = ({ visible }) => {
  const { state, dispatch } = useContext(ContextTablaDoctoresMD);

  const afterClose = () => {
    dispatch({ type: SET_INITIAL_STATE });
  };

  const refetchDoctors = () => {
    dispatch({ type: SET_INITIAL_STATE });
    state.refetch();
  };

  return (
    <Modal
      title='Eliminar Doctor MD'
      visible={visible}
      destroyOnClose
      onCancel={afterClose}
      footer={null}
      afterClose={afterClose}
      maskClosable={false}
    >
      {visible && state.idDoctorSelect ? (
        <BodyDelete
          id={state.idDoctorSelect}
          onCancel={afterClose}
          closeAndRefetch={refetchDoctors}
        />
      ) : null}
    </Modal>
  );
};

const BodyDelete = ({ id, closeAndRefetch, onCancel }) => {
  const { loading, error, data } = useQuery(rechazarAprobarDoctorByIdGql, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      id: id,
    },
  });
  const [fetchDeleteDoctorMD, { loading: loadingMutation, data: dataMutation }] = useMutation(
    deleteDoctormdByIdGql,
    { fetchPolicy: "no-cache" }
  );
  const onFinish = async () => {
    try {
      await fetchDeleteDoctorMD({
        variables: {
          id: id,
        },
      });
    } catch (error) {
      notification.error({
        message: "Internal server error ",
        description: HandleError(error),
      });
    }
  };
  useEffect(() => {
    if (!loadingMutation) {
      if (dataMutation) {
        const { deleteDoctormdById } = dataMutation;
        if (deleteDoctormdById) {
          notification.success({
            message: "Doctor eliminado",
          });
        } else {
          notification.warning({
            message: "Doctor no pudo ser eliminado",
          });
        }
        closeAndRefetch();
      }
    }
  }, [loadingMutation, dataMutation, closeAndRefetch]);
  if (error) {
    return (
      <div className='w-full'>
        <Alert
          message='Error'
          description='Error al obtener la información del doctor'
          type='error'
          className='w-full'
          showIcon
        />
      </div>
    );
  }
  return (
    <div className='w-full min-h-200px flex'>
      <Spin className='mx-auto my-auto' spinning={loading} />
      {data && (
        <div className='flex flex-col w-full'>
          <div className='flex flex-col justify-center content-center self-center'>
            <Avatar
              className='mx-auto mb-2'
              shape='square'
              size={64}
              src={data.doctormdById.pictureURL.url}
            />
            <p className='font-semibold mx-auto mb-2'>{data.doctormdById.fullName}</p>
          </div>
          <Divider className='mt-auto mb-0' />
          <div className='flex flex-row justify-between mt-auto'>
            <Button
              onClick={onCancel}
              shape='round'
              loading={loading || loadingMutation}
              size='large'
            >
              Cerrar
            </Button>
            <Button
              type='primary'
              danger
              loading={loading || loadingMutation}
              htmlType='submit'
              shape='round'
              size='large'
              onClick={onFinish}
            >
              Eliminar Doctor MD
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalDelete;
