import React, { useState, useEffect, createRef } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Checkbox, Tooltip, notification, Select, Alert } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";

import SelectWordCloud from "components/InputWordCloud/Select";
import ModalTermsAndConditions from "../ModalTermsAndConditions";
import ContendTermsAndConditions from "../ModalTermsAndConditions/ContendTermsAndConditions";

import gql from "graphql-tag";
import { HOST, LOCAL_HOST } from "utils.js/constants";
import ReCAPTCHA from "react-google-recaptcha";

import { HandleError } from "utils.js/HandleError";
import { animations } from "react-animation";

import "./stylesCrearCuentaDoctor.scss";
import "react-animation/dist/keyframes.css";
import { SET_LOCATION } from "store/actions";

const SITEKEY = process.env.REACT_APP_RECAPTCHA;

const specialties = gql`
  query specialties($perPage: Float, $page: Float, $filters: [specialtieFilter!]) {
    specialties(perPage: $perPage, page: $page, filters: $filters) {
      total
      page
      docs {
        value: id
        label: name
      }
    }
  }
`;

const FormCrearCuentaDoctor = (props) => {
  const { isNicaragua, isPanama, dispatch } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const { Option } = Select;
  const [visbleModal, setvisbleModal] = useState(false);
  const recaptchaRef = createRef();

  useEffect(() => {
    if (isNicaragua || isPanama) {
      form.setFields([
        {
          ...(isNicaragua && { name: "nationality", value: "Nicaragua", error: null }),
          ...(isPanama && { name: "nationality", value: "Panama", error: null }),
        },
      ]);
    }
  }, [isNicaragua, isPanama, form]);

  useEffect(() => {
    if (props.error) {
      notification.error({
        message: "Error",
        description: HandleError(props.error),
      });
    }
    if (props.data) {
      notification.success({
        message: "Doctor Creado",
      });
      if (props.redirect) {
        setTimeout(() => {
          // EAS-1631
          history.push("/md/auth/login", {
            email: form.getFieldValue("email"),
            password: form.getFieldValue("password"),
          });
        }, 4000);
      } else {
        props.successCreate();
      }
    }
  }, [history, props]);

  const onValidateWithReCAPTCHA = async (values) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      props.onFinish(values);
    }
  };

  return (
    <div style={{ animation: animations.fadeIn, duration: 1000 }} className='w-full lg:w-full '>
      {visbleModal && (
        <ModalTermsAndConditions
          visbleModaTermsAndConditions={visbleModal}
          setVisbleModaTermsAndConditions={setvisbleModal}
          setFields={() =>
            form.setFields([
              {
                name: "termsAndConditions",
                value: true,
                errors: null,
              },
            ])
          }
        >
          <ContendTermsAndConditions />
        </ModalTermsAndConditions>
      )}
      <h3 className='mt-3 text-2xl'>
        <strong>{props.title}</strong>
      </h3>
      <p>{props.description}</p>

      <Alert
        className='my-6'
        message={null}
        description='Este módulo de suscripción es exclusivo para profesionales médicos que desean afiliarse a EasyMD, su solicitud deberá ser aprobada por un Administrador para ser parte de la EasyMD.
        Si usted desea registrarse como paciente, por favor hacer clic en el botón “Soy Paciente” en el área superior. '
        type='warning'
        showIcon
        closable
      />

      <Form
        form={form}
        layout='horizontal'
        name='basic'
        validateTrigger='onBlur'
        initialValues={{ remember: true }}
        onFinish={(valueForm) => {
          HOST === LOCAL_HOST ? props.onFinish(valueForm) : onValidateWithReCAPTCHA(valueForm);
        }}
      >
        <div className='grid grid-cols-12 gap-2'>
          <div className='col-span-12 md:col-span-6 lg:col-span-4'>
            <Form.Item
              label={null}
              name='firstName'
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su Primer Nombre!",
                },
              ]}
            >
              <Input
                disabled={props.loading || props.data}
                size='large'
                prefix={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faUser} />}
                className='rounded'
                placeholder='Primer Nombre'
              />
            </Form.Item>
          </div>

          <div className='col-span-12 md:col-span-6 lg:col-span-4'>
            <Form.Item
              label={null}
              name='lastName'
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su Primer Apellido!",
                },
              ]}
            >
              <Input
                disabled={props.loading || props.data}
                size='large'
                prefix={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faUser} />}
                className='rounded'
                placeholder='Primer Apellido'
              />
            </Form.Item>
          </div>

          <div className='col-span-12 md:col-span-6 lg:col-span-4'>
            <Form.Item
              label={null}
              name='email'
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Por favor ingrese su Correo Electrónico valido!",
                },
              ]}
            >
              <Input
                disabled={props.loading || props.data}
                size='large'
                prefix={
                  <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faEnvelope} />
                }
                className='rounded'
                placeholder='micorreo@email.com'
              />
            </Form.Item>
          </div>

          <div className='col-span-12 md:col-span-6 lg:col-span-4'>
            <Form.Item
              label={null}
              name='selectSexo'
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese su sexo!",
                },
              ]}
            >
              <Select
                disabled={props.loading || props.data}
                size='large'
                className='selectCrearCuentaDoctores'
                placeholder='Selecciona tu sexo'
                allowClear
              >
                <Option value='MALE'>Masculino</Option>
                <Option value='FEMALE'>Femenino</Option>
              </Select>
            </Form.Item>
          </div>

          <div className='col-span-12 md:col-span-6 lg:col-span-4'>
            <Form.Item
              label={null}
              name='specialties'
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese almenos una especialidad!",
                },
              ]}
            >
              <SelectWordCloud
                gql={specialties}
                path='specialties.docs'
                size='large'
                showEmpty={false}
                disabled={props.loading}
                placeholder='Selecciona especialidad'
              />
            </Form.Item>
          </div>

          <div className='col-span-12 md:col-span-6 lg:col-span-4'>
            <Form.Item
              className='w-full'
              label={null}
              name='numeroMINSA'
              rules={[
                {
                  required: true,
                  message: isNicaragua
                    ? "Por favor ingrese su numero MINSA"
                    : "Por favor ingrese su numero Registro Profesional",
                },
              ]}
            >
              <Input
                disabled={!!(props.loading || props.data)}
                size='large'
                maxLength={15}
                className='rounded w-full'
                placeholder={isNicaragua ? "Numero MINSA" : "Registro Profesional"}
              />
            </Form.Item>
          </div>

          <div className='col-span-12 md:col-span-6'>
            <Tooltip
              placement='topRight'
              title={
                <div>
                  <p className='pl-2'>Requisitos mínimos de la contraseña</p>
                  <ul className='text-left pl-3 pt-0'>
                    <li>Ocho caracteres mínimo </li>
                    <li>Al menos un Número</li>
                  </ul>
                </div>
              }
            >
              <Form.Item
                label={null}
                name='password'
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su correo Contraseña!",
                  },
                  {
                    pattern: /^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                    message: "La contraseña no cumple los requisitos mínimos!",
                  },
                ]}
              >
                <Input.Password
                  disabled={props.loading || props.data}
                  autoComplete='new-password'
                  size='large'
                  prefix={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLock} />}
                  className='rounded'
                  placeholder='Contraseña'
                />
              </Form.Item>
            </Tooltip>
          </div>

          <div className='col-span-12 md:col-span-6'>
            <Form.Item
              autoComplete='new-password'
              label={null}
              name='confirmPassword'
              rules={[
                {
                  required: true,
                  message: "Por favor confirme su contraseña !",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Las dos contraseñas que ingresaste no coinciden!");
                  },
                }),
              ]}
            >
              <Input.Password
                disabled={props.loading || props.data}
                size='large'
                prefix={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLock} />}
                className='rounded'
                placeholder='Confirmar contraseña'
              />
            </Form.Item>
          </div>
          <div className='col-span-12 md:col-span-6'>
            <Form.Item
              name='nationality'
              label={null}
              rules={[{ required: true, message: "Es necesario que escojas tu ubicación." }]}
            >
              <Select
                disabled={false}
                mode={"single"}
                allowClear={true}
                showArrow={true}
                bordered={true}
                size={"large"}
                className={"rounded"}
                style={{ width: "100%" }}
                placeholder={"Ubicación"}
                onChange={(e) => dispatch && dispatch({ type: SET_LOCATION, payload: e })}
              >
                {["Nicaragua", "Panama"].map((option, i) => (
                  <Option key={i} value={option}>
                    <span className='capitalize'>{option}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className='col-span-12 flex flex-col md:flex-row'>
            <div className='flex flex-col'>
              {props.termsAndCondition && (
                <div className='col-span-6'>
                  <Form.Item
                    className='inline-block'
                    name='termsAndConditions'
                    valuePropName='checked'
                    rules={[
                      () => ({
                        validator(rule, value) {
                          if (value) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Por favor acepta los términos y condiciones!");
                        },
                      }),
                    ]}
                  >
                    <Checkbox
                      disabled={props.loading || props.data}
                      // checked={checkedTermsAndConditions}
                      // onChange={(e) => setCheckedTermsAndConditions(e.target.checked)}
                    >
                      <span>Acepto los</span>
                      <Button
                        className='pl-1'
                        disabled={props.loading || props.data}
                        loading={props.loading}
                        type='link'
                        onClick={() => setvisbleModal(true)}
                      >
                        terminos y condiciones
                      </Button>
                    </Checkbox>
                  </Form.Item>
                </div>
              )}

              <div className='flex justify-between col-span-6'>
                <Form.Item className=''>
                  <Button
                    disabled={props.loading || props.data}
                    loading={props.loading}
                    htmlType='submit'
                    type='primary'
                    shape='round'
                    size='large'
                    className='mx-4'
                  >
                    Crear cuenta
                  </Button>
                </Form.Item>
              </div>
            </div>
            <ReCAPTCHA
              className='flex justify-center lg:justify-end lg:col-start-2 lg:col-span-3 md:ml-auto'
              badge='inline'
              ref={recaptchaRef}
              sitekey={SITEKEY}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

FormCrearCuentaDoctor.defaultProps = {
  redirect: true,
  successCreate: () => {},
  termsAndCondition: true,
};

export default FormCrearCuentaDoctor;
