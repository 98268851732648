import React from "react";
import { Select } from "antd";
export default function CountrySelect({ country, setCountry }) {
  return (
    <Select
      disabled={false}
      mode='single'
      showArrow
      bordered
      className='w-30 rounded'
      placeholder='Pais'
      onSelect={(s) => setCountry(s)}
      value={country}
    >
      {["Nicaragua", "Panama"].map((option, i) => (
        <Select.Option key={i} value={option}>
          <span className='capitalize'>{option}</span>
        </Select.Option>
      ))}
    </Select>
  );
}
