import React, { useEffect } from "react";
import { Card, Form, Input, Button, Tooltip, notification } from "antd";

import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import "./stylesResetPassword.scss";

const passwordReset = gql`
  mutation passwordReset($password: String!, $confirmPassword: String!, $hash: String!) {
    passwordReset(password: $password, confirmPassword: $confirmPassword, hash: $hash)
  }
`;

const ResetPassword = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [fetchPasswordReset, { loading, error, data }] = useMutation(passwordReset, {
    fetchPolicy: "no-cache",
  });

  const onFinish = async (valueForm) => {
    try {
      await fetchPasswordReset({
        variables: {
          password: valueForm.password,
          confirmPassword: valueForm.confirmPassword,
          hash: props.match.params.id,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Internal server error ",
        description: error.graphQLErrors[0].message,
      });
    }

    if (data) {
      notification.success({
        message: "restablecimiento de contraseña de éxito",
        description: "Ahora puede ingresar su nueva contraseña para iniciar sesión",
      });
      setTimeout(() => {
        history.push("/md/auth/login");
      }, 4000);
    }
  }, [error, data, loading, history]);

  return (
    <div className='h-full lg:h-screen divFondoResetPass'>
      <div className='w-full h-full flex justify-center'>
        <div className='flex items-center my-12 w-4/5 sm:w-7/12 md:w-6/12'>
          <Card className='cardReset rounded-b-md w-full' title={null} bordered={false}>
            <div className='flex justify-center'>
              <img
                src={require("../../assets/img/logoEasyMD.png")}
                className='divLogo'
                alt='logo'
              />
            </div>
            <h3 className='text-center text-primary text-xl mt-5'>
              <strong>Restablecer contraseña</strong>
            </h3>
            <div className='mt-8 flex justify-center w-full'>
              <Form
                className='my-4 w-4/5'
                form={form}
                layout='horizontal'
                name='basic'
                initialValues={{ remember: true }}
                onFinish={(valueForm) => onFinish(valueForm)}
              >
                <div className='mx-auto w-full'>
                  <Tooltip
                    placement='topRight'
                    title={
                      <div>
                        <p className='pl-2'>Requisitos mínimos de la contraseña :</p>
                        <ul className='text-left pl-3 pt-0'>
                          <li>Una letra mayúscula</li>
                          <li>Una letra minúscula </li>
                          <li>Ocho caracteres mínimo </li>
                          <li>Un carácter especial </li>
                          <li>Un Número</li>
                        </ul>
                      </div>
                    }
                  >
                    <Form.Item
                      label={null}
                      name='password'
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese su correo Contraseña!",
                        },
                        {
                          pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                          message: "La contraseña no cumple los requisitos mínimos!",
                        },
                      ]}
                    >
                      <Input.Password
                        className='w-full rounded'
                        disabled={loading || data}
                        autoComplete='new-password'
                        size='large'
                        prefix={
                          <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLock} />
                        }
                        placeholder='Contraseña'
                      />
                    </Form.Item>
                  </Tooltip>
                </div>

                <div className='mx-auto w-full'>
                  <Form.Item
                    autoComplete='new-password'
                    label={null}
                    name='confirmPassword'
                    rules={[
                      {
                        required: true,
                        message: "Por favor confirme su contraseña !",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Las dos contraseñas que ingresaste no coinciden!");
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      disabled={loading || data}
                      size='large'
                      prefix={
                        <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faLock} />
                      }
                      className='rounded'
                      placeholder='Confirmar contraseña'
                    />
                  </Form.Item>
                </div>

                <div className='mx-auto mt-4'>
                  <Form.Item className='mx-auto lg:w-1/2'>
                    {!data ? (
                      <Button
                        title='Restablecer mi Contraseña'
                        className='w-full'
                        disabled={loading || data}
                        loading={loading}
                        htmlType='submit'
                        type='primary'
                        shape='round'
                        size='large'
                      >
                        <span className='truncate block'>Restablecer mi Contraseña</span>
                      </Button>
                    ) : (
                      <Button
                        title='Login'
                        className='button-success w-full'
                        type='default'
                        shape='round'
                        size='large'
                        onClick={() => history.push("/md/auth/login")}
                      >
                        Login
                      </Button>
                    )}
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
