import React from "react";
import { Avatar, Empty, Rate, Tabs, Tag } from "antd";
import styles from "./styles.module.scss";
import { getValueAprobalStatus } from "../../views/Admin/Doctores/utils";
import defaultPdfLogo from "../../assets/img/pdf-icon-png.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const { TabPane } = Tabs;

const validateIsPdfMimeType = (mimeType) => {
  let isPdf = false;
  switch (mimeType) {
    case "application/pdf":
      isPdf = true;
      break;
    case "pdf":
      isPdf = true;
      break;
    default:
      isPdf = false;
  }
  return isPdf;
};

const BodyDetails = ({ data }) => {
  const onChangeTab = () => {};
  const newData = {
    ...data,
    doctorById: {
      ...data.doctorById,
      description: data.doctorById.description ? data.doctorById.description : "",
      minsaNumber: data.doctorById.minsaNumber ? data.doctorById.minsaNumber : "N/A",
      email: data.doctorById.email ? data.doctorById.email : "N/A",
      signature: data.doctorById.signature ? data.doctorById.signature : null,
      phoneNumber: data.doctorById.phoneNumber ? data.doctorById.phoneNumber : "N/A",
    },
  };
  return (
    <Tabs onChange={onChangeTab} type='card'>
      <TabPane tab='Detalles' key='1' className={styles.bodyModal}>
        <div className='flex xs:flex-col md:flex-row'>
          <div
            className='flex flex-col justify-center xs:mx-auto md:mx-0'
            style={{ maxWidth: "150px" }}
          >
            <Avatar
              className='mx-auto mb-2'
              shape='square'
              size={64}
              src={newData.doctorById.pictureURL.url}
            />
            <p className='font-semibold mx-auto mb-2'>{newData.doctorById.fullName}</p>
            <Rate
              style={{ marginTop: "-10px !important" }}
              className='mt-0 mx-auto'
              allowClear={false}
              defaultValue={newData.doctorById.rating}
              disabled
            />
          </div>
          <div className='ml-5'>
            <p className='mb-0 text-primary font-semibold'>Estado</p>
            <p className='mb-2 text-black'>
              {getValueAprobalStatus(newData.doctorById.approvalStatus, "table")}
            </p>
            <p className='mb-0 text-primary font-semibold'># MINSA</p>
            <p className='mb-2 text-black'>{newData.doctorById.minsaNumber}</p>
            <p className='mb-0 text-primary font-semibold'>Email</p>
            <p className='mb-2 text-black'>{newData.doctorById.email}</p>
            <p className='mb-0 text-primary font-semibold'>Teléfono </p>
            <p className='mb-2 text-black'>{newData.doctorById.phoneNumber}</p>
          </div>
        </div>
        <div className='xs:ml-5 md:ml-0 xs:mt-0 md:mt-3 flex flex-wrap '>
          <div className='xs:w-full md:w-1/2'>
            <p className='mb-0 text-primary font-semibold'>Descripción</p>
            <p className='mb-3 text-black'>
              {newData.doctorById.description.length < 1 ? (
                <Empty description={false} />
              ) : (
                newData.doctorById.description
              )}
            </p>
          </div>
          <div className='xs:w-full md:w-1/2'>
            <p className='mb-0 text-primary font-semibold'>Especialidades</p>
            {newData.doctorById.specialties.length < 1 ? (
              <Empty description={false} />
            ) : (
              newData.doctorById.specialties.map((value, index) => {
                return (
                  <Tag
                    key={index}
                    style={{ backgroundColor: "#ECF1FF" }}
                    title={value.name}
                    className='my-1 text-black-hover rounded-full text-base h-auto py-1 hover:text-primary truncate'
                  >
                    <strong className='text-xs'>{value.name}</strong>
                  </Tag>
                );
              })
            )}
          </div>
        </div>
        {newData.doctorById.signature && (
          <div className='xs:ml-5 md:ml-0 xs:mt-0 md:mt-3 flex flex-row py-3 justify-center content-center'>
            <img
              alt='imageDoctor'
              className={styles.imgSignature}
              src={newData.doctorById.signature.url}
            />
          </div>
        )}
      </TabPane>
      <TabPane tab='Documentos' key='2' className={styles.bodyModal}>
        <div className='flex flex-wrap'>
          {newData.doctorById.supportingDocuments.length < 1 ? (
            <Empty className='my-5 mx-auto' description={false} />
          ) : (
            newData.doctorById.supportingDocuments.map((value, index) => {
              return (
                <div className='xs:w-full md:w-1/2 flex flex-row my-3 pr-3' key={index}>
                  <div className={"w-1/2 self-start " + styles.contentImgDocument}>
                    <Avatar
                      shape='square'
                      size={80}
                      src={
                        validateIsPdfMimeType(value.media.mimetype)
                          ? defaultPdfLogo
                          : value.media.url
                      }
                    />
                  </div>

                  <div className='flex flex-col w-1/2'>
                    <p className='ml-1'>{value.title}</p>
                    <a
                      className='ml-1'
                      download
                      href={value.media.url}
                      target='blank'
                      alt='preview image'
                    >
                      <FontAwesomeIcon icon={faDownload} className='mr-2 self-center' />
                      Descargar
                    </a>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </TabPane>
    </Tabs>
  );
};

export default BodyDetails;
