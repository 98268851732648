import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button } from "antd";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { ClockCircleOutlined } from "@ant-design/icons";
import { faPhoneAlt, faPhoneSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingLottie from "../components/LoadingLottie";

import CallAnimation from "./callAnimation";

import { Howl } from "howler";
import soundForCall from "../assets/sound/toneCall.wav";

import moment from "moment";
require("moment/min/locales.min");
moment.locale("es");

const ModalAceptarLLamada = (props) => {
  const { aceptarLlamada, cancelarLlamada, callInProgress, metaDataCall } = props;

  const [soundConfig] = useState(
    new Howl({
      src: [soundForCall],
      loop: true,
      rate: 0.7,
    })
  );

  useEffect(() => {
    soundConfig.play();
    document.title = "Llamada del doctor";
    const interval = setInterval(() => {
      const title = document.title;
      if (title === "EasyMD") {
        document.title = "Llamada del doctor";
      } else {
        document.title = "EasyMD";
      }
    }, 900);
    return () => {
      soundConfig.stop();
      clearInterval(interval);
      document.title = "EasyMD";
    };
  }, []);
  const appointmentInfo = null;
  return (
    <Modal
      title={null}
      onOk={aceptarLlamada}
      onCancel={cancelarLlamada}
      footer={null}
      maskClosable={false}
      closable={false}
      visible
      bodyStyle={{ padding: 0 }}
    >
      <div className='bg-gray-dark w-full pt-5 px-3 pb-5' style={{ minHeight: "200px" }}>
        <Row>
          <Col xs={24} className='mb-12'>
            <CallAnimation urlImage={metaDataCall?.avatar} />
            <p className='break-all font-bold text-white my-3 text-2xl text-center'>
              Dr. {metaDataCall?.name}
            </p>
            {appointmentInfo ? (
              <Row gutter={[6, 6]}>
                <Col xs={24}>
                  <p className='text-lg text-white mb-0 text-center'>Cita Programada: </p>
                </Col>
                <Col xs={24}>
                  <div className='flex flex-row'>
                    <p className='text-lg text-white mx-auto break-all'>
                      <ClockCircleOutlined className='text-2xl text-white mr-3' />
                      {moment(appointmentInfo).format("LLLL a")}
                    </p>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row gutter={[6, 6]}>
                <Col xs={24}>
                  <p className='text-lg text-white mb-0 text-center'>Cita Inmediata </p>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row gutter={[6, 6]}>
          <Col xs={24} sm={12} md={12} className='flex'>
            <Button
              className='h-12 mx-auto'
              type='danger'
              shape='round'
              size='middle'
              icon={<FontAwesomeIcon className='mx-2' size='lg' icon={faPhoneSlash} />}
              disabled={callInProgress}
              loading={callInProgress}
              onClick={cancelarLlamada}
            >
              <strong>Cancelar</strong>
            </Button>
          </Col>
          <Col xs={24} sm={12} md={12} className='flex'>
            <Button
              size='middle'
              type='default'
              shape='round'
              className='button-success h-12 mx-auto'
              icon={<FontAwesomeIcon className='mx-2' size='lg' icon={faPhoneAlt} />}
              disabled={callInProgress}
              loading={callInProgress}
              onClick={aceptarLlamada}
            >
              <strong>Aceptar</strong>
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ModalAceptarLLamada;
