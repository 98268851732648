import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Upload, notification, Alert, Input, Form, Popover } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import AnimationUpload from "./animationUpload";

import { CheckOutContext } from "../index";

const ACCEPT_FILE_TYPES = ["pdf", "png", "jpg", "jpeg"];

const ModalUpload = ({ visible, onClose, onRefetchImages }) => {
  const uploadExamGql = gql`
    mutation uploadExam($file: Upload!, $title: String!, $appointment: String!) {
      uploadExam(file: $file, title: $title, appointment: $appointment) {
        id
      }
    }
  `;

  const { state: stateWizardCheckOut } = useContext(CheckOutContext);
  const { idAppointment } = stateWizardCheckOut;

  const [fileToUpload, setFileToUpload] = useState([]);
  const [fileTitle, setFileTitle] = useState("");
  const [alertErrorVisible, setAlertErrorVisible] = useState(false);
  const [visiblePopover, setVisiblePopover] = useState(false);

  const [fetchUploadFile, { data, loading, error }] = useMutation(uploadExamGql);

  const submitFile = async () => {
    try {
      await fetchUploadFile({
        variables: {
          file: fileToUpload[0].file,
          title: fileTitle,
          appointment: idAppointment,
        },
      });
    } catch (e) {
      setAlertErrorVisible(true);
    }
  };

  useEffect(() => {
    if (!error && !loading) {
      if (data) {
        notification.success({
          message: "Archivo subido con éxito",
          description: "",
        });
        onRefetchImages();
      }
    }
  }, [error, data, loading, onRefetchImages]);

  const onChangeUpload = (fileProps) => {
    const { file, onSuccess } = fileProps;
    setTimeout(() => {
      onSuccess("ok");
      setFileToUpload([
        {
          file: file,
          name: file.name,
          uid: file.uid,
        },
      ]);
      setAlertErrorVisible(false);
    }, 300);
  };

  const beforeUpload = (file) => {
    try {
      const fileType = file.type.split("/")[1];
      const { size } = file;
      if (!fileType || ACCEPT_FILE_TYPES.indexOf(fileType.toLowerCase()) < 0) {
        notification.error({
          message: "Error en el formato del archivo",
          description: "Formatos permitidos: png, jpeg, jpg y pdf",
        });
        setAlertErrorVisible(true);
        return false;
      }
      if (isMaximumSize(size)) {
        notification.error({
          message: "Error en el tamaño del archivo",
          description: "El tamaño de un archivo no debe exceder los 5MB",
        });
        setAlertErrorVisible(true);
        return false;
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "No se puede subir el Archivo seleccionado",
      });
      setAlertErrorVisible(false);
      return false;
    }
  };

  const onRemove = (file) => {
    setFileToUpload([]);
    setAlertErrorVisible(false);
  };

  const isMaximumSize = (bytes) => {
    if (!bytes) {
      return true;
    }
    if (bytes < 4000000) {
      return false;
    }
    const kb = bytes / 1024;
    const mb = kb / 1024;
    if (mb > 4.9) {
      return true;
    }
    return false;
  };

  const getError = () => {
    try {
      if (error) {
        return Array.isArray(error.graphQLErrors)
          ? error.graphQLErrors[0].message
          : "Error temporal del servidor, no se puede subir el archivo";
      } else {
        return "No se puede subir el Archivo seleccionado";
      }
    } catch (e) {
      return "No se puede subir el Archivo seleccionado";
    }
  };

  const onChangeFileTitle = (e) => {
    setFileTitle(e.target.value);
  };

  const handleVisiblePopover = (visible) => {
    if (!visible) {
      setVisiblePopover(false);
    } else {
      if (fileToUpload.length === 0 || fileTitle.length === 0) {
        setVisiblePopover(true);
      } else {
        submitFile();
        setVisiblePopover(false);
      }
    }
  };

  return (
    <Modal
      maskClosable={false}
      closable={!loading}
      visible={visible}
      title='Subir examen médico'
      onOk={submitFile}
      onCancel={onClose}
      footer={[
        <Button key='0' onClick={onClose} disabled={loading}>
          Cerrar
        </Button>,
        <Popover
          key='1'
          content={<PopoverContent file={fileToUpload.length} title={fileTitle.length} />}
          title='Campos requeridos'
          trigger='click'
          visible={visiblePopover}
          onVisibleChange={handleVisiblePopover}
        >
          <Button
            key='submit'
            htmlType='submit'
            type='primary'
            disabled={loading}
            loading={loading}
          >
            Subir archivo
          </Button>
        </Popover>,
      ]}
      /* footer={[
             <Button key="back"
                 onClick={onClose}
                 disabled={loading}>
                 Cerrar
             </Button>,
             <Button key="submit" htmlType="submit" type="primary"
                 disabled={loading || fileToUpload.length === 0 || fileTitle.length === 0}
                 loading={loading}
                 onClick={submitFile}
             >
                 Subir archivo
             </Button>
         ]} */
    >
      <div className='w-full flex flex-col' id='containerModalUploadFile'>
        {(error || (!loading && alertErrorVisible)) && (
          <Alert
            closable
            className='w-full mb-5'
            message='Error'
            description={getError()}
            type='error'
            showIcon
          />
        )}

        <div className='mb-3'>
          <label>*Nombre del exámen médico</label>
          <Form.Item>
            <Input.TextArea
              disabled={loading}
              className='mt-2'
              rows={1}
              placeholder='Nombre del exámen médico'
              onChange={onChangeFileTitle}
            />
          </Form.Item>
        </div>

        <div className='w-full'>
          <Upload
            customRequest={onChangeUpload}
            accept='image/png,image/jpeg,.pdf'
            beforeUpload={beforeUpload}
            onRemove={onRemove}
            disabled={loading}
            fileList={fileToUpload}
          >
            <Button disabled={loading}>
              <UploadOutlined /> Seleccionar el Archivo
            </Button>
          </Upload>
        </div>

        {loading && <AnimationUpload />}
      </div>
    </Modal>
  );
};

const PopoverContent = ({ file, title }) => {
  let casoError = "";
  if (file === 0 && title > 0) {
    casoError = "FILE";
  } else {
    if (file === 0 && title === 0) {
      casoError = "ALL";
    } else {
      casoError = "TITTLE";
    }
  }
  switch (casoError) {
    case "FILE":
      return (
        <div className='flex flex-col'>
          <p>
            {" "}
            * Seleccionar el <span className='font-bold'>Archivo (png,jpg,jpeg,pdf)</span>
          </p>
        </div>
      );
    case "TITTLE":
      return (
        <div className='flex flex-col'>
          <p>
            {" "}
            * Nombre del <span className='font-bold'>Exámen Médico</span>
          </p>
        </div>
      );
    case "ALL":
      return (
        <div className='flex flex-col'>
          <p>
            {" "}
            * Seleccionar el <span className='font-bold'>Archivo (png,jpg,jpeg,pdf)</span>
          </p>
          <p>
            {" "}
            * Nombre del <span className='font-bold'>Exámen Médico</span>
          </p>
        </div>
      );
    default:
      return null;
  }
};

export default ModalUpload;
