import * as moment from "moment";
// import esLocale from '@fullcalendar/core/locales/es-us'
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { businessHours } from "views/microSalud/Appointments/Diary/demoEvents.json";
import generateEvents from "./generateEvents";
// calendar
import Confirm from "./Confirm";
// styles
import "@fullcalendar/core/main.css";
import "@fullcalendar/timegrid/main.css";
// helper
import get from "lodash/get";

export default (onSelect, onChange, slots) => ({
  events: ({ start, end }, callBack) => {
    const params = { start, end, callBack, onChange, slots };
    generateEvents(params);
  },
  businessHours,
  header: {
    left: "Title",
    right: "prev title next",
  },
  height: 768,
  // locale: esLocale,
  // minTime: '13:00',
  // maxTime: '15:00',
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
  // firstDay: 1,
  eventClick: ({ event }) => {
    const disabled = get(event, "_def.extendedProps.disabled", false);
    if (!disabled) Confirm({ event, onSelect });
  },
  allDaySlot: false,
  validRange: (nowDate) => ({
    start: nowDate,
    end: moment(nowDate).add(1, "month").format(),
  }),
  titleFormat: {
    day: "numeric",
    year: "numeric",
    month: "long",
  },
  defaultView: "timeGridWeek",
  nowIndicator: true,
  slotLabelFormat: {
    hour: "numeric",
    hour12: true,
  },
  displayEventTime: false,
  eventBorderColor: "transparent",
  columnHeaderFormat: {
    weekday: "long",
  },
  columnHeaderText: function (date) {
    const days = ["DOMINGO", "LUNES", "MARTES", "MIÉRCOLES", "JUEVES", "VIERNES", "SÁBADO"];
    return days[date.getDay()];
  },
  eventBackgroundColor: "#29AAE1",
});
