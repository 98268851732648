import React from "react";
import ChartEarning from "../../../components/ChartEasyMD/ChartEarning";

// antd
import Card from "antd/lib/card";

export default function MonthGain() {
  const props = {
    Card: {
      title: "Ganancias en el presente año",
    },
  };
  return (
    <Card {...props.Card}>
      <ChartEarning />
    </Card>
  );
}
