import React from "react";
import { Alert, Button, Card } from "antd";
import GetError from "./getError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import ErrorCita from "../../../components/CitasUtils/ErrorCita";
import { useHistory } from "react-router-dom";

const ErrorCard = ({ error, message, usingTopMargin }) => {
  const history = useHistory();
  return (
    <div
      className={
        "w-full min-h-25rem flex flex-col pt-0 " + (usingTopMargin ? " xs:mt-20 md:mt-16 " : "")
      }
    >
      <Card
        className='xs:w-2/3 md:w-2/3 lg:w-1/3 bg-transparent mx-auto mt-5'
        bodyStyle={{ padding: 0, backgroundColor: "transparent" }}
        style={{ zIndex: 0 }}
      >
        <Alert
          message='Error'
          description={<GetError error={error} message={message} />}
          type='error'
          className='rounded-md rounded-b-none border-none'
          showIcon
        />
        <p className='m-0 text-center'>
          <Button
            onClick={() => history.push("/patient")}
            type='default'
            shape='round'
            size='large'
            danger
            className='button-success my-2'
          >
            <FontAwesomeIcon icon={faUser} className='mr-2' />
            Ir a Mi Perfil
          </Button>
        </p>
      </Card>
      <ErrorCita styles={{ marginTop: "-4%", zIndex: 1 }} />
    </div>
  );
};

ErrorCard.defaultProps = {
  usingTopMargin: true,
};

export default ErrorCard;
