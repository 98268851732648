import React from "react";

import UseFetchDataMD from "./useFetchDataMD";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { Button, Card, Tooltip, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import PendingAppointmentsMD from "./pendingAppointmentsMD";
import OnGoingAppointmentsMD from "./onGoingAppointmentsMD";
import ModalDetailAppointmentMD from "./detailAppointment/modalDetail";

const TabCitasMD = () => {
  const {
    loadingFull,
    refetchAll,
    dataPending,
    dataOngoing,
    errorPending,
    errorOngoing,
    loadingPending,
    loadingOngoing,
    totalPending,
    hasMoreCalc,
    handleLoadMore,
    takeAppointment,
    visibleDetail,
    closeModalDetail,
    openModalDetail,
    idDetail,
  } = UseFetchDataMD();
  return (
    <BlockUi className='w-full h-full' tag='div' blocking={loadingFull} loader={<div />}>
      <ModalDetailAppointmentMD
        visible={visibleDetail}
        afterClose={closeModalDetail}
        id={idDetail}
        takeAppointment={takeAppointment}
        loading={loadingFull}
      />
      <div
        style={{ width: "98%", minHeight: totalPending === 0 ? "70vh" : "78vh" }}
        className='mx-auto p-2 flex xs:flex-col md:flex-row gap-4'
      >
        <Card className='xs:w-full md:w-3/5' bodyStyle={{ padding: "0.75rem" }}>
          <p className='text-success text-xl font-bold flex'>
            <span className='text-primary'>Citas</span>&nbsp; en espera
            <Tooltip title='Actualizar lista de citas'>
              <Button
                type='default'
                shape='round'
                size='small'
                className='ml-auto mt-auto'
                disabled={loadingPending || loadingFull}
                onClick={() => refetchAll()}
              >
                <FontAwesomeIcon icon={faSyncAlt} />
              </Button>
            </Tooltip>
          </p>
          <Spin spinning={loadingPending}>
            <PendingAppointmentsMD
              total={totalPending}
              data={dataPending}
              error={errorPending}
              loading={loadingPending}
              hasMoreCalc={hasMoreCalc}
              handleLoadMore={handleLoadMore}
              takeAppointment={takeAppointment}
              openModalDetail={openModalDetail}
            />
          </Spin>
        </Card>
        <Card className='xs:w-full md:w-2/5' bodyStyle={{ padding: "0.75rem" }}>
          <p className='text-success text-xl font-bold flex'>
            <span className='text-primary'>Citas</span>&nbsp; asignadas
          </p>
          <Spin spinning={loadingOngoing}>
            <OnGoingAppointmentsMD
              data={dataOngoing}
              loading={loadingOngoing}
              error={errorOngoing}
            />
          </Spin>
        </Card>
      </div>
    </BlockUi>
  );
};

export default TabCitasMD;
