import React from "react";
// icons
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default (edit) => {
  return {
    name: {
      item: {
        label: null,
        name: "name",
        rules: [{ required: true, message: "Es necesario ingresar un nombre!" }],
      },
      input: {
        disabled: false,
        size: "large",
        prefix: <FontAwesomeIcon className='text-center mr-2' size='lg' icon={faUser} />,
        className: "rounded",
        placeholder: " Nombre",
      },
    },
    usedFor: {
      item: {
        label: null,
        name: "usedFor",
        rules: [{ required: true, message: "Es necesario selecionar un grupo!" }],
      },
      select: {
        disabled: false,
        mode: "single",
        allowClear: true,
        showArrow: true,
        bordered: true,
        size: "large",
        className: "rounded",
        style: { width: "100%" },
        placeholder: "Grupo",
      },
    },
    permissions: {
      item: {
        name: "permissions",
        label: null,
        rules: [{ required: true, message: "Es necesario asignar un permiso!" }],
      },
      select: {
        disabled: false,
        mode: "single",
        allowClear: true,
        showArrow: true,
        bordered: true,
        size: "large",
        className: "rounded",
        style: { width: "100%" },
        placeholder: "Permisos",
      },
    },
  };
};
