import React, { useContext } from "react";
import { SET_INITIAL_STATE, SET_ROLE } from "../store/actions";
import { Modal } from "antd";

import { ContextTableUsers } from "../index";
import FormCrearRol from "../../../../components/FormCrearRol";

const ModalNewRole = ({ visible }) => {
  const { state, dispatch } = useContext(ContextTableUsers);
  const { refetch } = state;
  const afterClose = () => {
    dispatch({ type: SET_INITIAL_STATE });
  };
  const refetchUsers = () => {
    dispatch({ type: SET_INITIAL_STATE });
    refetch();
  };

  return (
    <Modal
      title='Rol'
      style={{
        minWidth: "20rem",
      }}
      visible={visible}
      destroyOnClose
      width='50vw'
      onCancel={afterClose}
      footer={null}
      afterClose={afterClose}
      maskClosable={false}
    >
      {visible ? <BodyModal closeAndRefetch={refetchUsers} /> : null}
    </Modal>
  );
};

const BodyModal = ({ closeAndRefetch }) => {
  const { state, dispatch } = useContext(ContextTableUsers);
  const { role, roles } = state;
  const { success, loading, error } = role;
  const group = [...new Set(roles.map((role) => role.usedFor))];

  const onFinish = async (values) => {
    const { permissions, ...restValues } = values;
    dispatch({
      type: SET_ROLE,
      payload: {
        variables: { ...restValues, groupPermissions: permissions },
        new: true,
        loading: true,
        success: false,
      },
    });
  };

  const formProps = {
    success,
    loading,
    groupList: group,
    error,
    onFinish,
    successPost: closeAndRefetch,
    title: "Nuevo Rol",
  };

  return <FormCrearRol {...formProps} />;
};

export default ModalNewRole;
