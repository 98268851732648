import React /*useContext*/ from "react";
import RowAvatar from "components/RowAvatar";
import moment from "moment";
// // ant
// import Tag from "antd/lib/tag";
// import Button from "antd/lib/button";
// import Tooltip from "antd/lib/tooltip";

// // icon
// import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
// import { faEye } from "@fortawesome/free-solid-svg-icons";

// // store
// import { TransactionsContext } from "../index";
// import * as actionName from "../store/actionStore";

// lodash
import toInteger from "lodash/toInteger";

const columns = [
  {
    title: "Paciente",
    dataIndex: "fullName",
    className: "text-center w-3/12",
    render: (text, row) => (
      <RowAvatar
        text={text}
        row={row}
        description={<p className='text-left pb-1 mb-1'>{row.email}</p>}
      />
    ),
  },
  {
    title: "Descripcion",
    dataIndex: "description",
    className: "text-center w-2/12",
    render: (text) => <span>{text || ""}</span>,
  },
  {
    title: "Cantidad",
    dataIndex: "amount",
    className: "text-center w-2/12",
    render: (text) => <span>{`$${text}` || ""}</span>,
  },
  {
    title: "Confirmacion",
    dataIndex: "confirmationNumber",
    className: "text-center w-2/12",
    render: (text) => <span>{text || ""}</span>,
  },
  {
    title: "Fecha",
    dataIndex: "createdAt",
    className: "text-center w-2/12",
    render: (text) => <span>{text ? moment(toInteger(text)).format("DD/MM/YYYY") : "N/A"}</span>,
  },
  {
    title: "Estado",
    dataIndex: "status",
    className: "text-center w-1/12",
    render: (text, row) => {
      return (
        <>
          <span className='capitalize text-center'>{text}</span>
          {row.planCancelledAffiliation && (
            <p className='text-center'>
              {moment(toInteger(row.planCancelledAffiliation)).format("DD/MM/YYYY")}
            </p>
          )}
          <div />
        </>
      );
    },
  },
  // {
  //   title: "Acciones",
  //   className: "text-center w-1/12",
  //   dataIndex: "",
  //   key: "x",
  //   // render: (row) => (
  //   //   // <ButtonVerTransacciones row={row} />
  //   // )
  // },
];

// const ButtonVerTransacciones = props => {
//   const { dispatch } = useContext(TransactionsContext);
//   const { row: selectedUser } = props;

//   const onClickButton = () => {
//     dispatch({
//       type: actionName.SET_VISIBLEMODAL,
//       payload: true,
//     });
//     dispatch({
//       type: actionName.SET_SELECTEDUSER,
//       payload: selectedUser,
//     });
//   };

//   return (
//     <Tooltip title="Ver transacciones">
//       <Button
//         onClick={onClickButton}
//         className="mx-auto px-2"
//         type="primary"
//         shape="round"
//         size="small"
//         icon={<Fa size="sm" icon={faEye} />}
//       />
//     </Tooltip>
//   );
// };

export default columns;
