import React, { useState, useEffect } from "react";
import { Empty } from "antd";
// Taps
import { appointmentsDoctor } from "../../../Profile/gqlTags";
import { useQuery } from "@apollo/react-hooks";

// componets
import CardCitaMedic from "./CardCitaMedic";
import { HandleError } from "utils.js/HandleError";
import notification from "antd/lib/notification";

const Taps2 = () => {
  const [variables] = useState({
    perPage: 25,
    page: 1,
    filters: [],
  });

  const { data, loading, error } = useQuery(appointmentsDoctor, {
    variables: { ...variables },
    fetchPolicy: "no-cache",
    pollInterval: 5000,
  });

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
  }, [error]);

  if (loading) {
    return <CardCitaMedic loading={loading} error={error} />;
  }

  if (error) {
    return (
      <div className='flex items-center justify-center w-full h-full'>
        <Empty description={<p className='text-semibold'>Error al cargar las citas</p>} />
      </div>
    );
  }

  if (!data?.appointments.docs.length) {
    return (
      <div className='flex items-center justify-center w-full h-full'>
        <Empty description={<span>No hay citas agendadas</span>} />
      </div>
    );
  }

  return (
    <>
      {data &&
        !loading &&
        !error &&
        data.appointments.docs.map((value, index) => (
          <CardCitaMedic key={index} loading={loading} error={error} appointments={value || []} />
        ))}
    </>
  );
};

export default Taps2;
