import gql from "graphql-tag";

export const doctorListGql = gql`
  query doctormds(
    $perPage: Float
    $page: Float
    $sort: [doctormdSort!]
    $filters: [doctormdFilter!]
  ) {
    doctormds(perPage: $perPage, page: $page, sort: $sort, filters: $filters) {
      total
      pages
      docs {
        id
        pictureURL {
          id
          url
        }
        fullName
        minsaNumber
        specialties {
          id
          name
        }
      }
    }
  }
`;

export const infoDoctorByIdGql = gql`
  query doctormdById($id: String!) {
    doctormdById(id: $id) {
      id
      sex
      fullName
      approvalStatus
      minsaNumber
      amountOfReviews
      rating
      email
      phoneNumber
      description
      approvalStatus
      signature {
        url
        mimetype
      }
      pictureURL {
        url
      }
      specialties {
        id
        name
      }
    }
  }
`;

export const rechazarAprobarDoctorByIdGql = gql`
  query doctormdById($id: String!) {
    doctormdById(id: $id) {
      id
      fullName
      minsaNumber
      pictureURL {
        url
      }
    }
  }
`;

export const deleteDoctormdByIdGql = gql`
  mutation deleteDoctormdById($id: String!) {
    deleteDoctormdById(id: $id)
  }
`;

export const createDoctorMDGql = gql`
  mutation createDoctorMD(
    $email: String!
    $password: String!
    $confirmPassword: String!
    $firstName: String!
    $lastName: String!
    $sex: SexEnum!
    $minsaNumber: String!
    $specialties: [String!]!
    $nationality: NationalityEnum
  ) {
    createDoctorMD(
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      firstName: $firstName
      lastName: $lastName
      sex: $sex
      specialties: $specialties
      minsaNumber: $minsaNumber
      nationality: $nationality
    ) {
      id
    }
  }
`;
