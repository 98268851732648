import ImgDefault from "../../../../../assets/img/doctorDefaulImg.png";
import truncate from "lodash/truncate";

export const getDataForCard = (data) => {
  return {
    ...data,
    picture: data.patient?.pictureURL?.url || ImgDefault,
    name: data.patient?.fullName || "N/A",
    description: data.reasonForConsultation
      ? truncate(data.reasonForConsultation, {
          length: 120,
        })
      : "N/A",
    time: data.appointmentDateAndTime,
    isVideo: data.appointmentType === "CALL",
    textType: data.appointmentType === "CALL" ? "Videollamada" : "Chat",
    specialty: getNameSpeciality(data.appointmentSpecialty),
    newMedicalConditions: data.patient?.newMedicalConditions || [],
  };
};

const getNameSpeciality = (name) => {
  switch (name) {
    case "GENERAL":
      return "General";
    case "PSYCHOLOGY":
      return "Psicológica";
    default:
      return "N/A";
  }
};
