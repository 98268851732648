import React, { useContext, useState, useEffect } from "react";
import { Spin, Alert } from "antd";
import { ContextTablaDoctores } from "../index";
import { useLazyQuery } from "@apollo/react-hooks";
import BodyDetailsDoctor from "../../../../components/BodyDetailsDoctor";
import BodyModalRechazar from "./bodyModal_Rechazar";
import BodyModalAprobar from "./bodyModal_Aprobar";
import { infodoctorByIdGql, rechazarAprobardoctorByIdGql } from "../gqlTags";

const BodyModal = ({ loading: loadingMutation }) => {
  const { state } = useContext(ContextTablaDoctores);
  const { infoDoctorSelect } = state;
  const [gqlTag] = useState(state.modalInfo ? infodoctorByIdGql : rechazarAprobardoctorByIdGql);
  const [fetchInfo, { loading, error, data }] = useLazyQuery(gqlTag, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!state.modalInfo) {
      fetchInfo({
        variables: {
          id: infoDoctorSelect ? infoDoctorSelect.id : "",
        },
      });
    }
  }, [infoDoctorSelect, state.modalInfo]);

  if (loading) {
    return (
      <Spin spinning>
        <div className='w-full min-h-200px' />
      </Spin>
    );
  }
  if (error) {
    return (
      <div className='w-full min-h-200px'>
        <Alert
          message='Error'
          description='Error al obtener la información del doctor'
          type='error'
          className='w-full'
          showIcon
        />
      </div>
    );
  }

  const RenderBody = () => {
    if (state.modalInfo) {
      return <BodyDetailsDoctor id={infoDoctorSelect ? infoDoctorSelect.id : ""} />;
    }
    if (state.modalAceptar) {
      return (
        <BodyModalAprobar
          loading={loadingMutation}
          fullName={data ? data.doctorById.fullName : "N/A"}
          picture={
            data ? (data.doctorById.pictureURL ? data.doctorById.pictureURL.url : "N/A") : "N/A"
          }
        />
      );
    }
    if (state.modalRechazar) {
      return <BodyModalRechazar data={data} loading={loadingMutation} />;
    }
    return null;
  };

  return (
    <div className='w-full min-h-200px flex content-center'>
      <RenderBody />
    </div>
  );
};

export default BodyModal;
