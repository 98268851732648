import { Endcrypt } from "endcrypt";
// helper
import pick from "lodash/pick";

export default function encrypt({ info, id, email }) {
  const card = pick(info, [
    "cardType",
    "cardNumber",
    "securityCode",
    "expirationYear",
    "expirationMonth",
  ]);

  const customer = pick(info, [
    "city",
    "state",
    "country",
    "zipCode",
    "lastName",
    "address1",
    "address2",
    "firstName",
  ]);

  const data = { card, customer: { ...customer, email } };

  return new Endcrypt().encryptWithKey(JSON.stringify(data), `${id}${email}`);
}
