import gql from "graphql-tag";

export default () => {
  const schema = `query appointmentById($id: String!) {
            appointmentById(id: $id) {
                id
                appointmentDateAndTime 
                status
                expiresAt
                newSymptoms
                newCurrentMedication
                reasonForConsultation
                patient {
                    newMedicalConditions
                    newDrugAllergies
                    id
                    fullName
                }
                createdBy {
                    id
                    }
                    patient {
                        id
                    }
                doctor {
                        id
                        appointmentPrice
                        appointmentPriceEasyMD
                        fullName
                        rating
                        pictureURL {
                            url
                        }
                        specialties {
                            id
                            name
                        }
                }
                exams {
                    id
                    title
                    media {
                        uid: id 
                        name: id
                        url: url
                        mimetype
                    }
                }
                }
            }
        `;
  return gql`
    ${schema}
  `;
};
