import React, { useEffect } from "react";
import Card from "antd/lib/card";
import notification from "antd/lib/notification";
import empyBoxAnimation from "assets/lottie/EmpyBox.json";
import { HandleError } from "utils.js/HandleError";
import Lottie from "react-lottie";
// gql
import { useQuery } from "@apollo/react-hooks";
import { plans, planfeatures } from "./gqlPlans";
// componentes
import PlanAndFeatures from "./planAndFeatures";

import styles from "./styles.module.scss";

import iconMastercard from "../../assets/img/mastercard.png";

export default function TablePlans({ modal }) {
  const couponURL = localStorage.getItem("coupon")?.includes("mastercard") || null;
  const variables = {
    perPage: 4,
    page: 1,
    filters: [
      {
        byCondition: {
          field: "COMPRABLE",
          value: true,
        },
      },
    ],
  };

  const variablesFeatures = {
    perPage: 25,
    page: 1,
    filters: [],
  };

  const { loading, error, data } = useQuery(plans, {
    variables: { ...variables },
    fetchPolicy: "no-cache",
  });

  const reqFeatures = useQuery(planfeatures, {
    variables: { ...variablesFeatures },
    fetchPolicy: "no-cache",
  });

  const defaultOptionsAnimation = {
    loop: true,
    autoplay: true,
    animationData: empyBoxAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }

    if (reqFeatures.error) {
      notification.error({
        message: "Error",
        description: HandleError(reqFeatures.error),
      });
    }
  }, [error, reqFeatures.error]);

  return (
    <>
      {loading || reqFeatures.loading ? (
        <Card
          className={`flex justify-center w-2/3 mx-auto mb-12 ${
            !modal ? styles.borderCardPlans : ""
          }`}
          loading={true}
          style={{ minHeight: "300px" }}
        />
      ) : (
        <div className='flex justify-center w-full'>
          <div
            className={
              data?.plans?.docs.length > 2
                ? "lg:w-11/12 flex mx-auto"
                : "w-10/12 lg:w-4/6 xl:w-2/3 flex mx-auto"
            }
          >
            <Card
              bordered={false}
              className={`w-full mb-12 ${!modal ? styles.borderCardPlans : ""}`}
            >
              {modal && (
                <div className='flex flex-col justify-center items-center pt-5 text-lg'>
                  {couponURL && (
                    <img src={iconMastercard} alt='mastercard' className='w-20 self-start' />
                  )}
                  <h1 className='text-primary text-4xl stroke-2 font-bold text-center'>
                    Planes EasyMD
                  </h1>
                  <h3>
                    A través de los planes EasyMD tendrás acceso a{" "}
                    <b>servicios de salud digitales</b> desde la comodidad de tu casa o trabajo.
                  </h3>
                </div>
              )}
              {((error && !loading) || (reqFeatures.error && !reqFeatures.loading)) && (
                <div className='flex justify-center items-center'>
                  <div className='block'>
                    <Lottie isClickToPauseDisabled width='60%' options={defaultOptionsAnimation} />
                    <h2 className='text-center text-danger text-lg'>Sin planes disponibles </h2>
                  </div>
                </div>
              )}
              {data &&
                reqFeatures.data &&
                !error &&
                !reqFeatures.error &&
                (data.plans.docs.length === 0 || reqFeatures.data.planfeatures.docs.length === 0 ? (
                  <div className='flex justify-center items-center'>
                    <div className='block'>
                      <Lottie
                        isClickToPauseDisabled
                        width='60%'
                        options={defaultOptionsAnimation}
                      />
                      <h2 className='text-center text-danger text-lg'>Sin planes disponibles </h2>
                    </div>
                  </div>
                ) : (
                  <PlanAndFeatures
                    modal={modal}
                    features={reqFeatures.data.planfeatures.docs}
                    plans={data.plans.docs}
                  />
                ))}
            </Card>
          </div>
        </div>
      )}
    </>
  );
}
