import React, { createContext, useReducer, useEffect } from "react";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "../apolloClient";
import ReactPixel from "react-facebook-pixel";
import { Howler } from "howler";

import initialState from "../store/initialState";
import reducer from "../store/reducer";

import RouteConfig from "../layouts/routeConfig";

// import useLocation from "hooks/useLocation";
import LogRocket from "logrocket";
import dotenv from "dotenv";

import "../styles/overrideAntd.less";
import "../styles/components.scss";
// EAS-1231
import { logoutAndClearAll } from "layouts/utils";
// import { SET_LOCATION } from "store/actions";

dotenv.config();

const hist = createBrowserHistory();
export const GlobalContext = createContext();

const ROCKET_APPID = process.env.REACT_APP_ROKETAZO || "development";
const PixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
const IS_LOCALHOST = process.env.REACT_APP_IS_LOCALHOST || "false";

if (IS_LOCALHOST !== "true") {
  LogRocket.init(ROCKET_APPID);
}

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // const { location: stateLocation } = state;
  // const location = useLocation();

  // useEffect(() => {
  //   !stateLocation && dispatch({ type: SET_LOCATION, payload: location });
  // }, [dispatch, stateLocation, location]);

  useEffect(() => {
    Howler.stop();
    if (PixelId) {
      ReactPixel.init(PixelId);
      ReactPixel.pageView();
    }
  }, []);

  useEffect(() => {
    if (state.user && IS_LOCALHOST !== "true") {
      try {
        LogRocket.identify(state.user.id, {
          name: state.user.fullName || "__" + new Date().toString() + "__",
          email: state.user.email || "N/A",
          role: state.user.role ? state.user.role.name : "N/A",
          verified: state.user.verified || "N/A",
          phoneNumber: state.user.phoneNumber || "N/A",
        });
      } catch (e) {
        console.log("eeeeee logR ==> ", e);
      }
    }
  }, [state.user]);

  useEffect(() => {
    // Logout on reset graphql client store
    // EAS-1231
    client.onResetStore(() => {
      logoutAndClearAll();
      dispatch({ type: "SETUSER", payload: null });
      window.location.href = "/md/auth/login";
    });
  }, [client]);

  return (
    <GlobalContext.Provider value={{ state: state, dispatch: dispatch }}>
      <ApolloProvider client={client}>
        <Router history={hist}>
          <RouteConfig />
        </Router>
      </ApolloProvider>
    </GlobalContext.Provider>
  );
};

export default App;
