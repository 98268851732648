import React, { useContext } from "react";
import { Switch, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@apollo/react-hooks";
import { updateCoupon } from "./gqlTags";
import { ContextTablaCoupons } from "./index";
import { SET_INITIAL_STATE } from "./store/actions";

function SwitchStatus({ initialState, id }) {
  const { state, dispatch } = useContext(ContextTablaCoupons);
  const [postUpdateCoupon, { loading }] = useMutation(updateCoupon, {
    fetchPolicy: "no-cache",
  });
  const refetchCoupons = () => {
    dispatch({ type: SET_INITIAL_STATE });
    state.refetch();
  };

  const handleChange = async () => {
    try {
      await postUpdateCoupon({
        variables: {
          id,
          status: !initialState,
        },
      }).then(() => {
        notification.success({
          message: "Estado del cupon cambiado",
        });
        refetchCoupons();
      });
    } catch (error) {
      notification.error({
        message: "No se pudo cambiar el estado del cupon, intente mas tarde",
      });
      refetchCoupons();
    }
  };
  return (
    <>
      <Switch
        loading={loading}
        defaultChecked={initialState}
        onChange={handleChange}
        checkedChildren={<FontAwesomeIcon icon={faCheck} />}
        unCheckedChildren={<FontAwesomeIcon icon={faTimes} />}
      />
    </>
  );
}

export default SwitchStatus;
