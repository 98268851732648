import React from "react";
// components
import ArrowDown from "components/ArrowDown";
import ListCheckCircle from "components/Checkcircle/List";
import BtnCreateAccount from "components/BtnCreateAccount";
// css
import style from "./styleHero.module.scss";

export default function SectionThree() {
  return (
    <div className={style.hero}>
      <div className='container mx-auto'>
        <div className='md:w-7/12'>
          <h3
            style={{ color: "#373A4A", fontWeight: 600 }}
            className='text-4xl md:text-5xl xl:text-6xl leading-tight'
          >
            <span>Tecnología diseñada</span>
            <span className='text-primary'> para tu comodidad</span>
          </h3>

          <p className='text-lg font-light'>
            Pioneros en salud digital. Valoramos la importancia de tu tiempo por eso hemos creado
            una solución rápida y segura para recibir atención de salud.
          </p>

          <p className='text-lg font-bold'>¿Qué incluye mi plan easyMD?:</p>

          <ListCheckCircle
            className='grid md:grid-cols-2 gap-4 font-light'
            items={[
              "Consultas ilimitadas 24/7 con medico general (chat)",
              "Consultas con psicólogo en horas laborales de 8:00 am a 5:00 pm de lunes a viernes",
              "Tratamientos dentales en Clínica Orallis con descuentos de hasta 50%, en dependencia al tipo de tratamiento",
              "Citas con especialistas privados con descuentos de hasta 50%",
              "1 examen de laboratorio gratuito mensual por afiliado en laboratorios Medlab siempre y cuando se haga mínimo un examen adicional.",
              "Descuentos para afiliados de hasta 50% en Clínica de Fisioterapia Fisiomed",
              "Examen visual gratis en Ópticas Matamoros para todos los afiliados.",
            ]}
          />

          <div className='mt-8'>
            <BtnCreateAccount />
          </div>

          <div className='hidden lg:block mt-4 w-20'>
            <ArrowDown />
          </div>
        </div>
      </div>
    </div>
  );
}
