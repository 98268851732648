import React, { useEffect, useState } from "react";
import { Select } from "antd";

export default function InputCompanyFilter({ value: initialValue, setValue, companies }) {
  const { Option } = Select;
  const [valueSelect, setValueSelect] = useState(null);

  useEffect(() => {
    if (initialValue == null) {
      setValueSelect(null);
    } else if (initialValue) {
      setValueSelect(initialValue);
    } else {
      setValueSelect(null);
    }
  }, [initialValue]);

  const changeHandler = (value) => {
    setValue(value);
  };

  return (
    <Select
      name='company'
      disabled={!companies}
      allowClear
      onChange={changeHandler}
      placeholder='Buscar Compañia'
      value={valueSelect}
      style={{ width: "100%" }}
    >
      {companies?.companys.docs.map((company, i) => (
        <Option value={company.id} key={i}>
          {company.fullName}
        </Option>
      ))}
    </Select>
  );
}
