import React from "react";
import { Tag, Button, Tooltip } from "antd";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import { faEye, faEdit } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import filter from "lodash/filter";
import RowAvatar from "components/RowAvatar";

const columns = (deletePlan, editPatient, loading) => {
  return [
    {
      title: "Nombre",
      dataIndex: "fullName",
      render: (text, row) => (
        <RowAvatar
          text={text}
          row={row}
          description={
            <>
              <p className='text-left pb-1 mb-1'>{row.email}</p>
              <span>
                Teléfono: <strong>{row.phoneNumber || "N/A"}</strong>
              </span>
            </>
          }
        />
      ),
    },
    {
      title: "Planes",
      dataIndex: "plans",
      className: "text-center w-1/6",
      width: 180,
      render: (row, fullRow) => renderPlan(row, fullRow, deletePlan, loading),
    },
    {
      title: "Verificado",
      dataIndex: "verified",
      className: "text-center w-1/6",
      render: (row) => renderVerified(row),
    },
    {
      title: "Acciones",
      className: "text-center w-1/6",
      width: 180,
      render: (fullRow) => renderEdit(fullRow, editPatient, loading),
    },
  ];
};

const renderPlan = (row, fullRow, deletePlan, loading) => {
  if (!row || row.length === 0) {
    return "N/A";
  }
  const { id, fullName: namePatient, email, belongsToCompany: company } = fullRow;

  const counterPlans = filter(row, (value) => {
    if (!moment().isAfter(moment(value.expireAt).utc(), "days")) {
      return value;
    }
  });

  return (
    <Tooltip title='Ver detalle'>
      <Button
        className='mx-auto px-2'
        type='primary'
        shape='round'
        size='small'
        icon={<Fa className='mr-2' size='sm' icon={faEye} />}
        onClick={() => deletePlan(id, namePatient, row, email, company)}
      >
        <span>{counterPlans.length}</span>
      </Button>
    </Tooltip>
  );
};

const renderVerified = (row) => {
  return <Tag color={row ? "processing" : "error"}>{row ? "Activo" : "Inactivo"}</Tag>;
};

const renderEdit = (fullRow, editPatient) => {
  if (!fullRow || fullRow.length === 0) {
    return "N/A";
  }
  const {
    id,
    fullName: namePatient,
    plans,
    email,
    phoneNumber,
    belongsToCompany: company,
  } = fullRow;

  return (
    <Tooltip title='Editar detalles'>
      <Button
        className='mx-auto px-2'
        type='primary'
        shape='round'
        size='small'
        icon={<Fa size='sm' icon={faEdit} />}
        onClick={() => editPatient(id, namePatient, plans, email, phoneNumber, company)}
      ></Button>
    </Tooltip>
  );
};

export default columns;
