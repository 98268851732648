import gql from "graphql-tag";

export const infoDoctorByIdGql = gql`
  query doctorById($id: String!) {
    doctorById(id: $id) {
      id
      sex
      fullName
      approvalStatus
      minsaNumber
      amountOfReviews
      rating
      balance
      email
      phoneNumber
      description
      approvalStatus
      signature {
        url
        mimetype
      }
      pictureURL {
        url
      }
      supportingDocuments {
        id
        title
        media {
          id
          url
          mimetype
        }
      }
      specialties {
        id
        name
      }
    }
  }
`;

export const infoDoctorMDByIdGql = gql`
  query doctormdById($id: String!) {
    doctormdById(id: $id) {
      id
      fullName
      minsaNumber
      email
      phoneNumber
      description
      approvalStatus
      signature {
        url
        mimetype
      }
      pictureURL {
        url
      }
      specialties {
        id
        name
      }
    }
  }
`;
