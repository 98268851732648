import React from "react";
// components
import MethodPayment from "components/MethodPay";
// styles
import style from "../../style.module.scss";

export default function CardOfCards() {
  return (
    <div className={style.MethodPayment}>
      <MethodPayment className='grid' />
    </div>
  );
}
