import React, { useState, useEffect, useContext } from "react";
import { Empty, Modal, notification } from "antd";
import CardDocument from "./CardDocument";
import ModalPreviewFile from "components/ModalPreviewFile";
import ModalAgregarDocumento from "./ModalAgregarDocumennto";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { deleteSupportingdocument } from "../../gqlTags";
import { HandleError } from "utils.js/HandleError";
import { profileMedicContext } from "../../../index";

const ListDocuments = (props) => {
  const { state } = useContext(profileMedicContext);
  const [visibleModalExman, setVisibleModalExman] = useState(false);
  const [visibleModalAgregate, setVisibleModalAgregate] = useState(false);
  const [selectedExam, setSelectedExam] = useState({
    name: "",
    type: "",
    url: "",
  });

  const [deleteDocument, { error }] = useMutation(deleteSupportingdocument, {
    refetchQueries: ["doctorByIdDocument"],
  });

  const { listDocument } = props;
  const { confirm } = Modal;

  const sumitEye = (valueExam) => {
    setSelectedExam({
      name: valueExam.title,
      type: valueExam.media.mimetype,
      url: valueExam.media.url,
    });
    setVisibleModalExman(true);
  };

  const sumitTrash = (id, title) => {
    confirm({
      title: "Seguro que quieres eliminar este Documento?",
      icon: <ExclamationCircleOutlined />,
      content: title,
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        await deleteDocument({ variables: { id: id } });
        notification.success({
          message: "El documento se ha eliminado con éxito!",
        });
      },
    });
  };

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
  }, [error]);

  const contendCard =
    listDocument.length === 0 ? (
      <div className='mx-auto'>
        <Empty description={<span>No hay Documentos</span>} />
      </div>
    ) : (
      listDocument.map((value) => (
        <CardDocument
          onClickEye={() => sumitEye(value)}
          sumitTrash={sumitTrash}
          {...value}
          key={value.id}
        />
      ))
    );

  return (
    <>
      <ModalPreviewFile
        visible={visibleModalExman}
        fileName={selectedExam.name}
        mimeType={selectedExam.type}
        url={selectedExam.url}
        onClose={() => {
          setVisibleModalExman(false);
          setSelectedExam({
            name: "",
            type: "",
            url: "",
          });
        }}
      />
      <ModalAgregarDocumento
        visibleModalAgregate={visibleModalAgregate}
        setVisibleModalAgregate={setVisibleModalAgregate}
      />
      {contendCard}
      {state.dataMedic.approvalStatus === "DOCUMENTS_REQUIRED" && (
        <div
          onClick={() => setVisibleModalAgregate(true)}
          className='w-full h-10 border-dashed border-2 border-primary mt-4 mb-2 text-center p-2 cursor-pointer hover:border-primary-lighter'
        >
          <span className='text-success hover:text-success'>Adjuntar Documento</span>
        </div>
      )}
    </>
  );
};

export default ListDocuments;
