import React, { useContext } from "react";
import { SET_INITIAL_STATE } from "../store/actions";
import { Modal } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { createDoctorMDGql } from "../gqlTags";

import { ContextTablaDoctoresMD } from "../index";
import FormCrearCuentaDoctor from "../../../../components/FormCrearCuentaDoctor";

const ModalNew = ({ visible }) => {
  const { state, dispatch } = useContext(ContextTablaDoctoresMD);

  const afterClose = () => {
    dispatch({ type: SET_INITIAL_STATE });
  };

  const refetchDoctors = () => {
    dispatch({ type: SET_INITIAL_STATE });
    state.refetch();
  };

  return (
    <Modal
      width={800}
      title='Agregar nuevo Doctor MD'
      visible={visible}
      destroyOnClose
      onCancel={afterClose}
      footer={null}
      afterClose={afterClose}
      maskClosable={false}
    >
      {visible ? <BodyModalNew closeAndRefetch={refetchDoctors} /> : null}
    </Modal>
  );
};

const BodyModalNew = ({ closeAndRefetch }) => {
  const [fetchSignupDoctor, { loading, error, data }] = useMutation(createDoctorMDGql, {
    fetchPolicy: "no-cache",
  });
  const onFinish = async (values) => {
    const { numeroMINSA: minsaNumber, selectSexo: sex, ...restValues } = values;
    restValues.nationality = restValues.nationality.toUpperCase();
    if (restValues.phoneNumber) restValues.phoneNumber = String(restValues.phoneNumber);
    await fetchSignupDoctor({
      variables: {
        minsaNumber: String(minsaNumber),
        sex,
        ...restValues,
      },
    }).catch((e) => console.log(e));
  };

  return (
    <FormCrearCuentaDoctor
      loading={loading}
      data={data}
      error={error}
      redirect={false}
      termsAndCondition={false}
      onFinish={onFinish}
      successCreate={closeAndRefetch}
      title='Creación de nuevo Doctor MD'
      description='Información básica.'
    />
  );
};

export default ModalNew;
