import React, { useState, useEffect } from "react";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import Lottie from "react-lottie";
import loadingLottie from "../../assets/lottie/MicroS_Loading.json";
import ErrorLottie from "../../assets/lottie/Errorg.json";
import VerificationEmail from "../../assets/lottie/EmailSuccess.json";

import { Button } from "antd";
import { NavLink } from "react-router-dom";

const verifyEmail = gql`
  query emailVerification($hash: String!) {
    emailVerification(hash: $hash)
  }
`;

const EmailValidation = (props) => {
  const [messageText, setMessageText] = useState("");
  const [typeAnimation, setTypeAnimation] = useState({
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  });

  const { loading, error, data } = useQuery(verifyEmail, {
    variables: {
      hash: props.match.params.id,
    },
  });

  useEffect(() => {
    if (data) {
      setTypeAnimation({
        ...typeAnimation,
        animationData: VerificationEmail,
      });
      setMessageText("Gracias Su correo electrónico ha sido verificado correctamente");
      setTimeout(() => {
        props.history.push("/md/auth/login");
      }, 6000);
    }

    if (error) {
      setTypeAnimation({
        ...typeAnimation,
        animationData: ErrorLottie,
      });
      console.log(error);
      setMessageText("Su correo electrónico no se ha verificado correctamente!");
    }
    if (loading) {
      setTypeAnimation({
        ...typeAnimation,
        animationData: loadingLottie,
      });
      setMessageText("Se está verificando su correo electrónico!");
    }
  }, [data, error, loading, props, typeAnimation]);

  return (
    <div className='h-screen flex justify-center'>
      <div className='my-auto w-1/2'>
        <div className='w-full'>
          <Lottie
            options={
              !error
                ? typeAnimation
                : {
                    loop: true,
                    autoplay: true,
                    animationData: ErrorLottie,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }
            }
            // options={typeAnimation}
            height='70%'
            width='70%'
          />
          <h3 className='text-center p-2'>
            <strong>{messageText}</strong>{" "}
          </h3>
          <p className='text-center p-2'>
            {data && data.emailVerification ? "Redirigir para iniciar sesión ..." : ""}
          </p>
          {data && (
            <NavLink to='/md/auth/login' className='flex justify-center'>
              <Button disabled={loading} type='default' shape='round' className='button-success'>
                INICIAR SESIÓN EN MI CUENTA
              </Button>
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailValidation;
