import {
  getActivePlanTotal,
  getPlansActiveUsers,
  getTotalApprovedMedic,
  getTotalPatient,
  getTotalAppointments,
} from "./gql";
// icons
import {
  faUserMd,
  faUsers,
  faBriefcaseMedical,
  faFileMedical,
  faBookMedical,
} from "@fortawesome/free-solid-svg-icons";
// styles
import style from "./style.module.scss";

export default {
  Queue: {
    key: "tabs",
    type: "bottom",
    component: "section",
    className: style.home,
    redirectTo: null,
  },
  Medic: {
    key: "Medic",
    gql: getTotalApprovedMedic,
    path: "doctors.total",
    icon: faUserMd,
    title: (val) => (val === 1 ? "Doctor Aprobado" : "Doctores Aprobados"),
    redirectTo: "/admin/medico",
  },
  Users: {
    key: "Users",
    gql: getTotalPatient,
    path: "patients.total",
    icon: faUsers,
    title: (val) => (val === 1 ? "Usuario Registrado" : "Usuarios Registrados"),
    redirectTo: "/admin/paciente",
  },
  Appointments: {
    key: "Appointments",
    gql: getTotalAppointments,
    path: "appointments.total",
    icon: faBookMedical,
    title: (val) => (val === 1 ? "Cita Agendada este mes" : "Citas Agendadas este mes"),
    redirectTo: null,
  },
  ActivePlans: {
    key: "ActivePlans",
    gql: getActivePlanTotal,
    path: "getActivePlanTotal",
    icon: faBriefcaseMedical,
    title: (val) => (val === 1 ? "Plan Activo" : "Planes Activos"),
    redirectTo: "/admin/Afiliados",
  },
  UserPlans: {
    key: "UserPlans",
    gql: getPlansActiveUsers,
    path: "plans.docs",
    icon: faFileMedical,
    redirectTo: "/admin/Afiliados",
  },
};
