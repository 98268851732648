import React, { useContext, useEffect } from "react";
import { SET_INITIAL_STATE, SET_USER } from "../store/actions";
import { Alert, Avatar, Button, Divider, Modal, notification, Spin } from "antd";

import { ContextTableUsers } from "../index";
import { HandleError } from "../../../../utils.js/HandleError";

const ModalDelete = ({ visible }) => {
  const { state, dispatch } = useContext(ContextTableUsers);
  const { user } = state;

  const afterClose = () => {
    dispatch({ type: SET_INITIAL_STATE });
  };

  const refetchDoctors = () => {
    dispatch({ type: SET_INITIAL_STATE });
    state.refetch();
  };

  return (
    <Modal
      title='Eliminar Usuario'
      visible={visible}
      destroyOnClose
      onCancel={afterClose}
      footer={null}
      afterClose={afterClose}
      maskClosable={false}
    >
      {visible && user.id ? (
        <BodyDelete user={user} onCancel={afterClose} closeAndRefetch={refetchDoctors} />
      ) : null}
    </Modal>
  );
};

const BodyDelete = ({ user, closeAndRefetch, onCancel }) => {
  const { dispatch } = useContext(ContextTableUsers);

  const onFinish = async () => {
    try {
      console.log("fin");
      dispatch({ type: SET_USER, payload: { submitDelete: true, variables: { id: user.id } } });
    } catch (error) {
      notification.error({
        message: "Internal server error ",
        description: HandleError(error),
      });
    }
  };
  useEffect(() => {
    if (user.submitDelete && user.success) {
      notification.success({
        message: "Usuario eliminado",
      });
      closeAndRefetch();
    } else if (user.error) {
      notification.warning({
        message: "El usuario no pudo ser eliminado",
      });
      closeAndRefetch();
    }
  }, [user.success, user.submitDelete, user.error, closeAndRefetch]);

  if (user.error.length > 1) {
    return (
      <div className='w-full'>
        <Alert
          message='Error'
          description='Error al obtener la información del usuario'
          type='error'
          className='w-full'
          showIcon
        />
      </div>
    );
  }
  return (
    <div className='w-full min-h-200px flex'>
      <Spin className='mx-auto my-auto' spinning={user.loading} />
      {user.delete && (
        <div className='flex flex-col w-full'>
          <div className='flex flex-col justify-center content-center self-center'>
            <Avatar className='mx-auto mb-2' shape='square' size={64} src={user.pictureURL.url} />
            <p className='font-semibold mx-auto mb-2'>{user.fullName}</p>
          </div>
          <Divider className='mt-auto mb-0' />
          <div className='flex flex-row justify-between mt-auto'>
            <Button onClick={onCancel} shape='round' loading={user.loading} size='large'>
              Cerrar
            </Button>
            <Button
              type='primary'
              danger
              loading={user.loading}
              htmlType='submit'
              shape='round'
              size='large'
              onClick={onFinish}
            >
              Eliminar Usuario
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalDelete;
