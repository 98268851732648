import gql from "graphql-tag";

export const getPatientsCSV = gql`
  query getPatientsCSV($all: Boolean, $from: DateTime, $to: DateTime) {
    getPatientsCSV(all: $all, from: $from, to: $to) {
      download
    }
  }
`;

export const getCompanyUsersCSV = gql`
  query getCompanyUsersCSV($all: Boolean, $from: DateTime, $to: DateTime) {
    getCompanyUsersCSV(all: $all, from: $from, to: $to) {
      download
    }
  }
`;

export const getAllUsersCompanyCSV = gql`
  query getAllUsersCompanyCSV($all: Boolean, $from: DateTime, $to: DateTime) {
    getAllUsersCompanyCSV(all: $all, from: $from, to: $to) {
      download
    }
  }
`;

export const getUsersWithPlanCSV = gql`
  query getUsersWithPlanCSV($all: Boolean, $from: DateTime, $to: DateTime) {
    getUsersWithPlanCSV(all: $all, from: $from, to: $to) {
      download
    }
  }
`;

export const getUsersWithOutPlanCSV = gql`
  query getUsersWithOutPlanCSV($all: Boolean, $from: DateTime, $to: DateTime) {
    getUsersWithOutPlanCSV(all: $all, from: $from, to: $to) {
      download
    }
  }
`;

export const getAppointmentsUsersCompanyCSV = gql`
  query getAppointmentsUsersCompanyCSV($all: Boolean, $from: DateTime, $to: DateTime) {
    getAppointmentsUsersCompanyCSV(all: $all, from: $from, to: $to) {
      download
    }
  }
`;

export const getAppointmentsUsersCSV = gql`
  query getAppointmentsUsersCSV($all: Boolean, $from: DateTime, $to: DateTime) {
    getAppointmentsUsersCSV(all: $all, from: $from, to: $to) {
      download
    }
  }
`;

export const getEarningsCSV = gql`
  query getEarningsCSV($all: Boolean, $from: DateTime, $to: DateTime) {
    getEarningsCSV(all: $all, from: $from, to: $to) {
      download
    }
  }
`;

export const getTransactionsCSV = gql`
  query getTransactionsCSV($all: Boolean, $from: DateTime, $to: DateTime) {
    getTransactionsCSV(all: $all, from: $from, to: $to) {
      download
    }
  }
`;

export const getTransactionsReviewCSV = gql`
  query getTransactionsReviewCSV($all: Boolean, $from: DateTime, $to: DateTime) {
    getTransactionsReviewCSV(all: $all, from: $from, to: $to) {
      download
    }
  }
`;

export const getActiveCompanyPlans = gql`
  query getActiveCompanyPlans($all: Boolean, $from: DateTime, $to: DateTime) {
    getActiveCompanyPlans(all: $all, from: $from, to: $to) {
      download
    }
  }
`;

export const getActivePatientPlans = gql`
  query getActivePatientPlans($all: Boolean, $from: DateTime, $to: DateTime) {
    getActivePatientPlans(all: $all, from: $from, to: $to) {
      download
    }
  }
`;
export const getActivePlans = gql`
  query getActivePlans($all: Boolean, $from: DateTime, $to: DateTime) {
    getActivePlans(all: $all, from: $from, to: $to) {
      download
    }
  }
`;

export const getTotalPlans = gql`
  query getTotalPlans($all: Boolean, $from: DateTime, $to: DateTime) {
    getTotalPlans(all: $all, from: $from, to: $to) {
      download
    }
  }
`;

export const getTotalAppointments = gql`
  query getTotalAppointments($all: Boolean, $from: DateTime, $to: DateTime) {
    getTotalAppointments(all: $all, from: $from, to: $to) {
      download
    }
  }
`;
