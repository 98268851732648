import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBook, faVideo } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Card, Tag, Rate, notification } from "antd";
import { HandleError } from "utils.js/HandleError";

import { Link } from "react-router-dom";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import Skeleton from "react-loading-skeleton";

import ImgModal from "../../assets/img/Enmascarar_grupo_8.png";

import "./StylesModalDoctor.scss";

const doctorById = gql`
  query doctorById($id: String!) {
    doctorById(id: $id) {
      id
      fullName
      description
      minsaNumber
      appointmentPrice
      appointmentPriceEasyMD
      languages {
        id
        name
      }
    }
  }
`;

const currencyFormat = (num) => {
  if (!num) {
    return "Sin especificar";
  }
  return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const ModalDoctor = (props) => {
  const { user } = props;
  const { loading, error, data } = useQuery(doctorById, {
    variables: { id: props.dataDoctor.id },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
  }, [error]);

  return (
    <Modal
      style={{
        minWidth: "20rem",
      }}
      className='ModalDoctor'
      visible
      onCancel={() => props.setModalDoctorVisible(false)}
      footer={null}
      width='70vw'
      closeIcon={<FontAwesomeIcon className='text-white hover:text-primary' icon={faTimes} />}
    >
      <div className='w-full h-24 bg-cover titleCard' />
      <div className='p-5'>
        <div className='grid grid-cols-12 gap-4 h-full'>
          <div className='col-span-12 md:col-span-12 lg:col-span-4'>
            <Card className='w-full shadow-2xl flex cardDoctor rounded-lg' bordered={false}>
              <div className='w-full h-auto mt-3'>
                <div className='flex justify-end top-0 right-0 h-8'>
                  <div className='flex items-center mr-1'></div>
                </div>
              </div>

              <div className='bg-contain w-full bg-scroll flex justify-center'>
                <img
                  style={{ height: "170px" }}
                  src={props.dataDoctor.imageUrl ? props.dataDoctor.imageUrl : ImgModal}
                  alt=''
                />
              </div>

              <div className='pt-3 w-full'>
                <h3 className='text-center text-sm mt-0'>
                  <strong>{props.dataDoctor.nameDoctor}</strong>
                </h3>

                <div className='w-full text-center px-5 grid-flow-col-dense'>
                  {props.dataDoctor.specialty.map((value, index) => (
                    <Tag
                      key={index}
                      style={{ backgroundColor: "#ECF1FF" }}
                      className='tagSpec mt-2 text-primary rounded-full text-base w-auto h-auto py-2 flex-1 hover:text-primary'
                    >
                      <strong>{value.name}</strong>
                    </Tag>
                  ))}
                </div>

                <div className='w-full flex justify-center mt-2'>
                  <Rate disabled allowHalf defaultValue={props.dataDoctor.punctuation} />
                </div>

                <p className='text-center'>
                  {`Basado en ${
                    props.dataDoctor.amountOfReviews <= 10 || props.dataDoctor.amountOfReviews
                      ? "menos de 10"
                      : props.dataDoctor.amountOfReviews
                  } calificaciones`}
                </p>
              </div>

              <div className='mt-3 relative w-full'>
                <Link
                  className='text-white'
                  to={{
                    pathname: "/md/appointment",
                    state: { idDoctor: props.dataDoctor.id },
                  }}
                >
                  <Button
                    type='primary'
                    size='middle'
                    disabled={props.cardLoading}
                    className='
                  bg-primary
                  h-12 w-6/12
                  inline-block
                  text-white
                  border-none
                  '
                    icon={<FontAwesomeIcon className='mx-2' size='lg' icon={faBook} />}
                    // icon={<SearchOutlined />}
                  >
                    <strong>Agendar</strong>
                  </Button>
                </Link>
                <Button
                  disabled
                  type='default'
                  className='
                  button-success
                  h-12 w-6/12
                  inline-block
                  border-none
                  text-white
                  '
                  size='middle'
                  icon={<FontAwesomeIcon className='mx-2' size='lg' icon={faVideo} />}
                  // icon={<SearchOutlined />}
                >
                  <strong>Llamar</strong>
                </Button>
              </div>
            </Card>
          </div>
          <div className='col-span-12 md:col-span-12 lg:col-span-8'>
            <div className='px-6 pt-3'>
              <h3 className='text-left text-2xl mt-0'>
                <strong>Información del Medico</strong>
              </h3>

              {loading ? (
                <div className='pt-3 w-full mx-auto my-1'>
                  <Skeleton count={6} />
                </div>
              ) : data && data.doctorById.description ? (
                <p>{data.doctorById.description}</p>
              ) : (
                <p>Sin descripción</p>
              )}
            </div>
            <div className='grid grid-cols-12 gap-4 px-6 pt-3'>
              <div className='col-span-5'>
                {loading ? (
                  <div className='pt-3 w-full mx-auto my-1'>
                    <Skeleton count={2} />
                  </div>
                ) : (
                  <>
                    <span className='text-primary'>
                      <strong>Número MINSA</strong>
                    </span>
                    <p>{data ? data.doctorById.minsaNumber : null}</p>
                  </>
                )}
              </div>
              <div className='col-span-5'>
                {loading ? (
                  <div className='pt-3 w-full mx-auto my-1'>
                    <Skeleton count={2} />
                  </div>
                ) : (
                  <>
                    <span className='text-primary'>
                      <strong>Idiomas</strong>
                    </span>
                    <ul>
                      {data && data.doctorById.languages.length > 0 ? (
                        <>
                          <li>Español</li>
                          <li>Ingles</li>
                        </>
                      ) : (
                        <li>Ninguno</li>
                      )}
                    </ul>
                  </>
                )}
              </div>

              <div className='col-span-12 w-full flex flex-col'>
                {loading ? (
                  <div className='pt-3 w-full mx-auto my-1'>
                    <Skeleton count={2} />
                  </div>
                ) : (
                  <React.Fragment>
                    <span className='text-primary'>
                      <strong>
                        Precio de consulta{" "}
                        {data?.doctorById.appointmentPriceEasyMD > 0 && " EasyMD"}
                      </strong>
                    </span>
                    <div>
                      <span
                        className={data?.doctorById.appointmentPriceEasyMD > 0 && "line-through"}
                      >
                        {data && currencyFormat(data.doctorById.appointmentPrice)}
                      </span>
                      {data?.doctorById.appointmentPriceEasyMD > 0 && (
                        <strong className='pl-1'>
                          {currencyFormat(data.doctorById.appointmentPriceEasyMD)}
                        </strong>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDoctor;
