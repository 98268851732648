import React from "react";
import Lottie from "react-lottie";
import UploadLottie from "../../../../../assets/lottie/uploadLottie.json";

const AnimationUpload = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: UploadLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className='w-full mx-auto my-5'>
      <Lottie isClickToPauseDisabled options={defaultOptions} height='100px' width='100px' />
    </div>
  );
};

export default AnimationUpload;
