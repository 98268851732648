import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const GetError = ({ date, error }) => {
  const DateString = () => (
    <li className='mb-1'>
      <FontAwesomeIcon icon={faCalendarCheck} className='mr-2' />
      {moment(moment(date), "YYYY-MM-DD hh:mm").format("LLLL a")}
    </li>
  );
  if (error && Array.isArray(error.graphQLErrors)) {
    return (
      <div className='w-full flex flex-col'>
        <ul>
          {error.graphQLErrors.map((value, index) => {
            return (
              <li key={index} className='mb-1'>
                {value.message}
              </li>
            );
          })}
          <li className='text-left mb-1 mt-3'>Fecha de la cita solicitada: </li>
          <DateString />
        </ul>
      </div>
    );
  } else {
    return (
      <ul>
        <li className='mb-1'>Ocurrió un error con los campos seleccionados</li>
        <li className='text-left mb-1 mt-3'>Fecha de la cita solicitada: </li>
        <DateString />
      </ul>
    );
  }
};

export default GetError;
