import React from "react";
import { Modal, Divider } from "antd";
import { DrawMoney, currencyFormat } from "./utils";
import moment from "moment";

const ModalDetalles = ({ visibleModal, closeModal, info, type }) => {
  return (
    <Modal
      title='Detalles'
      visible={visibleModal}
      footer={null}
      onOk={() => closeModal()}
      onCancel={() => closeModal()}
    >
      {type === "MONTH" && <TitleMonth info={info} />}
      {type === "WEEK" && <TitleWeek info={info} />}
      <Body info={info} />
    </Modal>
  );
};

const Body = ({ info }) => {
  if (!info) {
    return null;
  }
  return (
    <>
      <Divider />
      <div className='flex flex-wrap md:w-2/3 mx-auto'>
        <p className='font-bold text-left w-full mb-0'>Pagos recibidos</p>
        <p className='font-semibold text-left w-full mb-0 mt-1'>Pagos</p>
        <p className='xs:w-full md:w-1/3 mb-0'>Total: </p>
        <p className='xs:w-full md:w-2/3 mb-0'>{info.payments}</p>
        <p className='xs:w-full md:w-1/3 mb-0'>Dinero: </p>
        <p className='xs:w-full md:w-2/3 mb-0'>{currencyFormat(info.paymentAmount)}</p>
        <p className='font-semibold text-left w-full mb-0 mt-1'>Autorizados</p>
        <p className='xs:w-full md:w-1/3 mb-0'>Total: </p>
        <p className='xs:w-full md:w-2/3 mb-0'>{info.authorization}</p>
        <p className='xs:w-full md:w-1/3 mb-0'>Dinero: </p>
        <p className='xs:w-full md:w-2/3 mb-0'>{currencyFormat(info.authorizationAmount)}</p>{" "}
        <p className='font-semibold text-left w-full mb-0 mt-1'>Citas</p>
        <p className='xs:w-full md:w-1/3 mb-0'>Total: </p>
        <p className='xs:w-full md:w-2/3 mb-0'>{info.appointment}</p>
        <p className='xs:w-full md:w-1/3 mb-0'>Dinero: </p>
        <p className='xs:w-full md:w-2/3 mb-0'>{currencyFormat(info.appointmentAmount)}</p>{" "}
        <p className='font-semibold text-left w-full mb-0 mt-1'>Plan</p>
        <p className='xs:w-full md:w-1/3 mb-0'>Total: </p>
        <p className='xs:w-full md:w-2/3 mb-0'>{info.plan}</p>
        <p className='xs:w-full md:w-1/3 mb-0'>Dinero: </p>
        <p className='xs:w-full md:w-2/3 mb-0'>{currencyFormat(info.planAmount)}</p>
        <Divider />
        <p className='font-semibold text-left w-full mb-0'>Depositos a doctores</p>
        <p className='xs:w-full md:w-1/3 mb-0'>Total: </p>
        <p className='xs:w-full md:w-2/3 mb-0'>{info.withdrawals}</p>
        <p className='xs:w-full md:w-1/3 mb-0'>Dinero: </p>
        <p className='xs:w-full md:w-2/3 mb-0'>{currencyFormat(info.withdrawalAmount)}</p>
        <Divider />
        <p className='font-semibold text-left w-full mb-0'>Reembolsos</p>
        <p className='xs:w-full md:w-1/3 mb-0'>Total: </p>
        <p className='xs:w-full md:w-2/3 mb-0'>{info.reversals}</p>
        <p className='xs:w-full md:w-1/3 mb-0'>Dinero: </p>
        <p className='xs:w-full md:w-2/3 mb-0'>{currencyFormat(info.reversalAmount)}</p>
        <Divider />
        <p className='font-semibold text-left w-full mb-0 text-xl'>Ganancias:</p>
        <p className='font-semibold text-left w-full mb-0 text-xl'>
          {<DrawMoney quantity={info.totalRevenue} />}
        </p>
      </div>
    </>
  );
};

const TitleMonth = ({ info }) => {
  if (!info) {
    return null;
  }
  return <p className='text-2xl font-semibold text-center my-0'>{info.month}</p>;
};

const TitleWeek = ({ info }) => {
  if (!info) {
    return null;
  }
  const { date, id } = info;
  const dateFormat = date ? moment(date).year().toString() : moment().year().toString();
  const fromDate = moment(dateFormat)
    .add(parseInt(id) - 1, "weeks")
    .startOf("week")
    .format("LL");
  const toDate = moment(dateFormat)
    .add(parseInt(id) - 1, "weeks")
    .endOf("week")
    .format("LL");
  return (
    <div className='flex flex-col'>
      <p className='text-center text-2xl font-semibold font-semibold mb-1'>Semana {id}</p>
      <p className='mb-0 text-xl mb-1 text-center'>
        {fromDate}
        <span className='mx-2'>-</span>
        {toDate}
      </p>
    </div>
  );
};

export default ModalDetalles;
