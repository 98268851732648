import React from "react";
// antd
import Form from "antd/lib/form";
import Space from "antd/lib/space";
// inputs definitions
import input from "./inputs/info";

const props = {
  Space: {
    size: 0,
    align: "center",
    style: {
      display: "grid",
    },
  },
};

export default function FormInfo(_props) {
  const { isMain, ...formProps } = _props;
  const validateMessages = {
    // Customize error messages for Form.Item components
    types: {
      number: "Invalid number format",
    },
  };
  return (
    <Form {...formProps} validateMessages={validateMessages}>
      <Space {...props.Space}>
        <Form.Item {...input.name} />
        <Form.Item {...input.lastName} />
        <Form.Item {...input.email} />
      </Space>
      <Space {...props.Space}>
        <Form.Item {...input.birthday(isMain)} />
        <Form.Item {...input.phone} />
      </Space>
      <Space {...props.Space}>
        <Form.Item {...input.height} />
        <Form.Item {...input.weight} />
      </Space>
      <Space {...props.Space}>
        <Form.Item {...input.typeOfBlood} />
        <Form.Item {...input.sex} />
      </Space>
    </Form>
  );
}
