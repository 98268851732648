import React from "react";
// data
import data from "country-region-data/data.json";
// antd
import Select from "antd/lib/select";
// helpers
import map from "lodash/map";
import filter from "lodash/filter";

export default function InputCountry(_props) {
  const options = map(data, ({ countryName, countryShortCode }) => ({
    label: countryName,
    value: countryShortCode,
  }));

  const nicOption = {
    label: "Nicaragua",
    value: "NI",
  };

  const newOption = filter(options, (value) => {
    return value.value !== "NI";
  });

  newOption.unshift(nicOption);

  const filterOption = (input, option) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const props = {
    options: newOption,
    filterOption,
    size: "large",
    showSearch: true,
    placeholder: "País",
    // autoComplete: 'new-password',
    ..._props,
  };

  return <Select {...props} />;
}
