import React, { useEffect, useContext } from "react";
import { Modal, Button, notification, Form, Input } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { uploadSupportingDocument } from "../../gqlTags";
import { HandleError } from "utils.js/HandleError";
import { UploadOutlined } from "@ant-design/icons";
import InputFile from "views/Patient/Tabs/Appointments/Card/Attachment/InputFile";
import { includes } from "lodash";

import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { profileMedicContext } from "../../../index";

const ModalAgregarDocumento = (props) => {
  const { state } = useContext(profileMedicContext);
  const [agregateDocument, { error, loading }] = useMutation(uploadSupportingDocument, {
    refetchQueries: ["doctorByIdDocument"],
  });
  const [form] = Form.useForm();

  const asepFile = "image/png,image/x-png,image/jpeg,application/pdf";

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
  }, [error]);

  const sumitOnOk = async (valueform) => {
    try {
      const variables = {
        file: valueform.file,
        title: valueform.title,
        doctor: state.dataMedic.id,
      };
      await agregateDocument({ variables: { ...variables } });
      notification.success({ message: "Archivo agregado con exito!" });
      props.setVisibleModalAgregate(false);
      form.resetFields();
    } catch (e) {
      console.log(e);
    }
  };

  const isMaximumSize = (bytes) => {
    if (!bytes) {
      return true;
    }
    if (bytes < 4000000) {
      return false;
    }
    const kb = bytes / 1024;
    const mb = kb / 1024;
    if (mb > 4.9) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      title='Agregar Documento'
      visible={props.visibleModalAgregate}
      bodyStyle={{ paddingTop: "1rem", paddingBottom: "1rem" }}
      onOk={() => sumitOnOk()}
      onCancel={() => {
        form.resetFields();
        props.setVisibleModalAgregate(false);
      }}
      footer={
        <div className='flex justify-between'>
          <Button
            onClick={() => props.setVisibleModalAgregate(false)}
            size='large'
            disabled={loading}
            loading={loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => form.submit()}
            type='primary'
            size='large'
            disabled={loading}
            loading={loading}
          >
            Agregar
          </Button>
        </div>
      }
    >
      <p>
        <strong>El formato de los documentos:</strong>
      </p>
      <ul>
        <li>• Imagen png</li>
        <li>• Imagen jpeg</li>
        <li>• Documento Pdf</li>
        <li>• El documento no debe de pesar más de 5 Megas</li>
      </ul>
      <Form
        form={form}
        layout='horizontal'
        name='basic'
        initialValues={{ remember: true }}
        onFinish={(valueform) => sumitOnOk(valueform)}
      >
        <Form.Item
          label={null}
          name='title'
          rules={[
            {
              required: true,
              message: "Por favor agregar un nombre al documento!",
            },
          ]}
        >
          <Input
            disabled={props.loading}
            size='large'
            prefix={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faFile} />}
            className='rounded'
            placeholder='Nombre del Archivo'
          />
        </Form.Item>
        <Form.Item
          name='file'
          label={null}
          rules={[
            {
              required: true,
              message: "Por favor agregar un documento!",
            },
            () => ({
              validator: async (rule, value) => {
                if (!value) {
                  return Promise.resolve();
                }
                if (value.type === "") {
                  throw new Error("Formato del archivo invalido!");
                }
                if (includes(asepFile, value.type)) {
                  if (isMaximumSize(value.size)) {
                    throw new Error("El archivo no debe de pesar más de 5 megas!");
                  } else {
                    return Promise.resolve();
                  }
                  // return Promise.resolve()
                } else {
                  throw new Error("Formato del archivo invalido!");
                }
              },
            }),
          ]}
        >
          <InputFile accept={asepFile}>
            <Button>
              <UploadOutlined />
              Subir documento
            </Button>
          </InputFile>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAgregarDocumento;
