import React from "react";
// antd
import Select from "antd/lib/select";
// components
import Input from "../InputLg";
import InputNum from "components/InputNumeric";
// helpers
import map from "lodash/map";
import toString from "lodash/toString";

const year = new Date().getFullYear();
const years = [];
for (let i = year; i < year + 8; i++) years.push(i.toString());

export default {
  Number: {
    name: "cardNumber",
    rules: [
      {
        required: true,
        message: "Requerido!",
      },
      {
        min: 15,
        message: "Mínimo 15 números",
      },
      {
        required: true,
        pattern: new RegExp("^[0-9]*$"),
        message: "Numeros unicamente!",
      },
    ],
    children: (
      <InputNum
        size='large'
        maxLength={16}
        autoComplete='new-password'
        placeholder='Numero de tarjeta'
      />
    ),
    normalize: (val) => toString(val),
    validateTrigger: "onBlur",
  },
  Month: {
    name: "expirationMonth",
    rules: [
      {
        required: true,
        message: "Requerido!",
      },
    ],
    children: (
      <Select size='large' className='rounded-lg' autoComplete='new-password' placeholder='mes'>
        {map(["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"], (m) => (
          <Select.Option value={m}>{m}</Select.Option>
        ))}
      </Select>
    ),
  },
  Year: {
    name: "expirationYear",
    rules: [
      {
        required: true,
        message: "Requerido!",
      },
    ],
    children: (
      <Select size='large' className='rounded-lg' autoComplete='new-password' placeholder='año'>
        {years.map((y) => (
          <Select.Option value={y}>{y}</Select.Option>
        ))}
      </Select>
    ),
  },
  CVC: {
    name: "securityCode",
    rules: [
      {
        required: true,
        message: "Requerido!",
      },
    ],
    children: <Input maxLength={3} autoComplete='new-password' placeholder='CVC' />,
  },
  FirstName: {
    name: "firstName",
    rules: [
      {
        required: true,
        message: "Requerido!",
      },
    ],
    children: <Input autoComplete='new-password' placeholder='Nombre' />,
  },
  LastName: {
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Requerido!",
      },
    ],
    children: <Input autoComplete='new-password' placeholder='Apellido' />,
  },
};
