import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Card } from "antd";
import { URLDOCTORNORMAL } from "../../../chatCall/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import get from "lodash/get";
import ChatSkeleton from "../../../components/CitasUtils/ChatSkeleton";
import BrandLoading from "../../../components/CitasUtils/BrandLoading";
import ChatScreen from "components/OpenFire/ChatScreen";

const ChatPacienteNormal = ({
  id,
  infoForChat,
  isInAppointment,
  infoAppointment,
  sendFirstMessage,
  isFinish,
  isAfter,
  isBefore,
}) => {
  const history = useHistory();

  const irMiPerfil = () => {
    history.push("/patient");
  };

  return (
    <Card className='h-full w-full' bodyStyle={{ padding: 0 }}>
      <div className={"flex flex-col h-full"}>
        <div className='mx-auto'>
          <Button
            onClick={irMiPerfil}
            type='default'
            size='large'
            shape='round'
            className='button-success my-2 '
          >
            <FontAwesomeIcon icon={faUser} className='mr-2 hover:text-success-hover' />
            Ir a mi perfil
          </Button>
        </div>

        <div
          className={"mt-0 h-full flex flex-col bg-gray-200 "}
          style={{ minHeight: "75vh", maxHeight: "85vh" }}
        >
          <ChatScreen
            infoAppointment={infoAppointment}
            infoForChat={infoForChat}
            isFinish={isFinish}
            isAfter={isAfter}
            isBefore={isBefore}
          />
        </div>

        {/* {
          (get(infoForChat, 'uid') && isInAppointment)
            ?
            (!finishvalidate)
              ?
              <React.Fragment>
                <BrandLoading cssClass='w-full flex justify-center items-center mt-auto' />
                <p className='text-center font-semibold mb-auto'>Cargando información del chat</p>
              </React.Fragment>
              :
              <CometChatUserListWithMessages
                user={infoForChat}
                usable
                isPacient
                sendFirstMessage={sendFirstMessage}
                metaData={{
                  isNormalAppointment: true,
                  idAppointment: id,
                  urlForNotification: URLDOCTORNORMAL
                }}
              />
            :
            <div className='xs:w-full lg:w-2/3 mx-auto' style={{ minHeight: "80vh", maxHeight: "85vh" }}>
              <ChatSkeleton />
            </div>

        } */}
      </div>
    </Card>
  );
};

export default ChatPacienteNormal;
