import gql from "graphql-tag";

export const getInfoMedic = gql`
  query getInfoMedic($id: String!) {
    doctorById(id: $id) {
      id
      online
      description
      callNowEnabled
      pic: pictureURL {
        url
      }
      rate: rating
      name: fullName
      tags: specialties {
        name
      }
    }
  }
`;

export const getMedicSlots = gql`
  query getInfoMedic($id: String!, $from: DateTime!, $to: DateTime!) {
    doctorById(id: $id) {
      id
      slots: appointmentIntervals(from: $from, to: $to) {
        status
        from
        to
      }
    }
  }
`;
