import React, { useReducer } from "react";
import PlanContext from "./Context";

export const initialState = {
  country: "Nicaragua",
  status: { loading: false, error: null },
};

export const types = {
  COUNTRY: "COUNTRY",
  STATUS: "STATUS",
};

export const reducer = (state, action) => {
  const actions = {
    [types.COUNTRY]: { ...state, country: action.value },
    [types.STATUS]: { ...state, status: action.value },
  };
  return actions[action.type] || { ...state };
};

export default function PlanProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { country, status } = state;
  const context = {
    country,
    status,
    setCountry: (country) => {
      console.log(country);
      dispatch({ type: types.COUNTRY, value: country });
    },
    setStatus: (status) => dispatch({ type: types.STATUS, value: status }),
  };
  return <PlanContext.Provider value={context}>{children}</PlanContext.Provider>;
}
