import React, { useContext, useEffect, useState } from "react";
import cls from "classnames/bind";
// antd
import Layout from "antd/lib/layout";
// components
import Brand from "layouts/profile/Brand";
import BtnToggleSider from "layouts/profile/BtnToggleShowSider";
import WizardMD from "components/WizardMD";
// context
import Context from "./Context";
// stiles
import style from "./style.module.scss";
import { notification } from "antd";
import { HandleError } from "utils.js/HandleError";
import { addCalendarGql } from "./gql";
import { useMutation } from "@apollo/react-hooks";
import { GlobalContext } from "views/App";
import GoogleCalendarLoading from "./GoogleCalendarLoading";

const cx = cls.bind(style);

export default function CommonLayout({ person, sider, content }) {
  const [overlap, setOverlap] = useState(false);
  const { state } = useContext(GlobalContext);
  const couponURL = localStorage.getItem("coupon")?.includes("mastercard") || null;

  const props = {
    Layout: {
      hasSider: true,
      className: cx("profile", { overlap }),
    },
    innerLayout: {
      onClick: () => overlap && setOverlap(!overlap),
      className: "overflow-hidden",
    },
    Sider: {
      width: 320,
      trigger: null,
      className: style.sidebar,
    },
  };
  const [
    addCalendar,
    { data: addCalendarData, loading: addCalendarLoading, error: addCalendarError },
  ] = useMutation(addCalendarGql, {
    variables: { id: state?.user?.id },
  });

  useEffect(() => {
    if (addCalendarData) {
      window.location = "/medic";
    }
  }, [addCalendarData]);

  useEffect(() => {
    if (addCalendarError) {
      notification.error({
        message: "Error",
        description: HandleError(addCalendarError),
      });
    }
  }, [addCalendarError]);

  useEffect(() => {
    console.log(window.location.search, state?.user?.id);
    if (window.location.search && state?.user?.id) {
      const code = new URLSearchParams(window.location.search).get("code");
      if (code) {
        addCalendar({ variables: { doctor: state.user.id, code } });
      }
    }
  }, [state?.user?.id]);

  useEffect(() => {
    if (couponURL) {
      let alert = setTimeout(() => {
        notification.info({
          message: "Cupon Activado",
          description: "Recuerda que tienes tu cupon mastercard con descuentos en planes",
        });
      }, 20000);

      return () => clearTimeout(alert);
    }
  }, [couponURL]);

  if (addCalendarLoading) return <GoogleCalendarLoading />;

  return (
    <Context.Provider value={{ overlap, setOverlap }}>
      <Layout {...props.Layout}>
        <Layout {...props.innerLayout}>
          <Layout.Header className={style.header}>
            <Brand />
            <WizardMD classNameButton='block xl:hidden ' />
            {person}
          </Layout.Header>
          <Layout.Content>{content}</Layout.Content>
        </Layout>
        <Layout.Sider {...props.Sider}>
          <BtnToggleSider />
          {sider}
        </Layout.Sider>
      </Layout>
    </Context.Provider>
  );
}
