import React, { useEffect } from "react";
import { Button, notification, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useLazyQuery } from "@apollo/react-hooks";
import { getCompanyByIdCSV, getPendingCodeCompanyByIdCSV } from "./gqlTags";
import { HandleError } from "utils.js/HandleError";

function DownloadCsv({ type, id }) {
  const query = type === "patient" ? getCompanyByIdCSV : getPendingCodeCompanyByIdCSV;
  const [fetchCsv, { loading, error, data }] = useLazyQuery(query, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (error && !loading) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    } else {
      if (data && !loading) {
        try {
          const downloadLink =
            data[type === "patient" ? "getCompanyByIdCSV" : "getPendingCodeCompanyByIdCSV"]
              .download;
          const a = document.createElement("a");
          a.href = downloadLink;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } catch (e) {
          notification.error({
            message: "Error",
            description: "Error al descargar archivo",
          });
        }
      }
    }
  }, [data, loading, error, type]);

  const clickDownload = () => {
    fetchCsv(id);
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <Button
      type='default'
      shape='round'
      size='small'
      className='border-0 shadow-none'
      onClick={() => clickDownload()}
    >
      <FontAwesomeIcon icon={faDownload} color={type === "patient" ? "#8bc53f" : "#ff0000"} />
    </Button>
  );
}

export default DownloadCsv;
