import React, { useState, useEffect, useContext } from "react";
import { XMPP } from "../../../xmpp";
import { Row, Col } from "antd";
import EstadosCitaDoctorMD from "../components/estadosCitaDoctorMD";
import LlamadaDiv from "../components/video/indexLlamada";

import ButtonLLamarDoctor from "../components/buttonLlamarDoctor";

import { useLazyQuery } from "@apollo/react-hooks";
import { sendNotificationCallIncomingGql } from "./gqlTags";

import moment from "moment";
import { CallChatContext } from "chatCall/store";
import { URLPATIENTCITAMD } from "chatCall/utils";
require("moment/min/locales.min");
moment.locale("es");

const LlamadaListenerConfig = (props) => {
  const { infoAppointment, usableAppointment, isRenderChat, isFinish, infoForChat } = props;
  const [callInProgress, setCallInProgress] = useState(false);
  const [sessionID, setSessionID] = useState(null);
  const [randomForClose, setRandomForClose] = useState(null);
  const [incomingLlamada, setIncomingLlamada] = useState(false);
  const [isWaitForLoginPatient, setIsWaitForLoginPatient] = useState(false);
  const [isBusyOtherCall, setIsBusyOtherCall] = useState(false);
  const [initCallListerner, setInitCallListerner] = useState(false);

  const { presences, setCallStatus } = useContext(CallChatContext);

  const [sendNotificationPushForCall] = useLazyQuery(sendNotificationCallIncomingGql, {
    fetchPolicy: "no-cache",
  });

  const getRandomInt = () => {
    const miNewDate = new Date();
    const newRandom = Math.floor(Math.random() * 7000);
    return miNewDate.toLocaleTimeString() + "_" + newRandom;
  };

  useEffect(() => {
    if (!initCallListerner) {
      setInitCallListerner(true);
      XMPP.addCallListener(
        props.listenerID,
        new XMPP.CallListener({
          onIncomingCallReceived(call) {},
          onOutgoingCallAccepted(call) {
            setIsBusyOtherCall(false);
            setCallInProgress(true);
          },
          onOutgoingCallRejected(call) {
            setRandomForClose(getRandomInt());
            setIncomingLlamada(false);
            setCallInProgress(false);
            setIsBusyOtherCall(false);
          },
          onIncomingCallCancelled(call) {
            setRandomForClose(getRandomInt());
            setIncomingLlamada(false);
            setCallInProgress(false);
            setIsBusyOtherCall(false);
          },
          onBusyCall() {
            setRandomForClose(getRandomInt());
            setIncomingLlamada(false);
            setCallInProgress(false);
            setIsBusyOtherCall(true);
          },
        })
      );
    }
  }, [initCallListerner]);

  useEffect(() => {
    return () => {
      XMPP.removeCallListener(props.listenerID);
      terminarSessionLlamada();
    };
  }, []);

  useEffect(() => {
    if (randomForClose) {
      terminarSessionLlamada();
    }
  }, [randomForClose]);

  const terminarSessionLlamada = () => {
    setIsWaitForLoginPatient(false);
    setIsBusyOtherCall(false);
    setSessionID(null);
    setIncomingLlamada(false);
    setCallInProgress(false);
  };

  useEffect(() => {
    if (isFinish) {
      terminarSessionLlamada();
    }
  }, [isFinish, sessionID]);

  function getMetaData() {
    const { doctor, id, vonageSessionId } = infoAppointment;
    const { id: doctorId, fullName, openFireUsername, pictureURL } = doctor;

    const metaData = {
      id,
      url: URLPATIENTCITAMD,
      isNormalAppointment: true,
      _id: doctorId,
      name: fullName,
      username: openFireUsername,
      chatWith: infoForChat.username,
      avatar: pictureURL.url,
      vonageSessionId,
    };
    return metaData;
  }

  function onCallTimeOut() {
    console.log("DISONNECTED FROM HERE");
    setIncomingLlamada(false);
  }

  const llamar = async () => {
    setIsBusyOtherCall(false);
    setSessionID(null);
    setIncomingLlamada(true);
    setCallInProgress(false);

    const metaData = getMetaData();

    var isOnline = false;
    if (presences) {
      for (let key of Object.keys(presences)) {
        if (key.includes(metaData.chatWith)) {
          if (presences[key] === "online") {
            isOnline = true;
            break;
          }
        }
      }
    }
    if (!isOnline) {
      sendNotificationPushForCall({ variables: { id: metaData.id } });
    }
    setIsWaitForLoginPatient(true);
    XMPP.initiateCall(metaData, onCallTimeOut);
    setCallStatus(new String("CALL_INITIATED"));
  };

  const disconnectCall = () => {
    setIncomingLlamada(false);
    setCallInProgress(false);
    setIsBusyOtherCall(false);
    const metaData = getMetaData();
    XMPP.disconnectCall(metaData);
    setCallStatus(new String("CALL_DISCONNECT"));
  };
  return (
    <Row className='h-full'>
      {isRenderChat && callInProgress && !isBusyOtherCall ? (
        <Col xs={24}>
          <LlamadaDiv
            terminarSessionLlamada={terminarSessionLlamada}
            sessionID={sessionID}
            infoAppointment={infoAppointment}
            infoForChat={infoForChat}
            disconnectCall={disconnectCall}
          />
        </Col>
      ) : (
        <Col xs={24} style={{ overflow: "none" }}>
          <EstadosCitaDoctorMD
            usableAppointment={usableAppointment}
            isRenderChat={isRenderChat}
            isFinish={isFinish}
            infoAppointment={infoAppointment}
            incomingLlamada={incomingLlamada}
            isWaitForLoginPatient={isWaitForLoginPatient}
            terminarSessionLlamada={terminarSessionLlamada}
            callInProgress={callInProgress}
            buttonLlamar={
              <ButtonLLamarDoctor
                terminarSessionLlamada={disconnectCall}
                callOngoing={incomingLlamada}
                disabled={callInProgress || incomingLlamada || !isRenderChat}
                loading={callInProgress || incomingLlamada}
                onClick={llamar}
                isNormalAppointment={false}
                idAppointment={infoAppointment.id}
                isBusyOtherCall={isBusyOtherCall}
                setIsBusyOtherCall={setIsBusyOtherCall}
              />
            }
          />
        </Col>
      )}
    </Row>
  );
};

export default React.memo(LlamadaListenerConfig);
