import DoctorLlamada from "../views/videoCall/doctorNormal";
import PacienteLlamada from "../views/videoCall/pacienteNormal";

import PacienteConsultaInmediata from "../views/videoCall/pacienteMD";
import DoctorConsultaInmediata from "../views/videoCall/doctorMD";

const routes = [
  {
    path: "/paciente/llamada/:id",
    name: "Video Llamada Paciente",
    icon: "",
    component: PacienteLlamada,
    layout: "/cita",
  },
  {
    path: "/doctor/llamada/:id",
    name: "Video Llamada Doctor",
    icon: "",
    component: DoctorLlamada,
    layout: "/cita",
  },
  {
    path: "/paciente/consultainmediata/:id",
    name: "Video Llamada Paciente",
    icon: "",
    component: PacienteConsultaInmediata,
    layout: "/cita",
  },
  {
    path: "/doctor/consultainmediata/:id",
    name: "Video Llamada Doctor",
    icon: "",
    component: DoctorConsultaInmediata,
    layout: "/cita",
  },
];

export default routes;
