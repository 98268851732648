import React, { createContext, useReducer } from "react";
import { Card } from "antd";
import TableUsers from "./tableUsers";

import reducer from "./store/reducer";
import initialState from "./store/initialState";

export const ContextTableUsers = createContext();

const UsersAdmin = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ContextTableUsers.Provider value={{ state: state, dispatch: dispatch }}>
      <div className='w-full mx-auto md:w-3/5 min-h-25rem p-6'>
        <p className='mt-3 text-2xl font-bold mb-0'>Usuarios</p>
        <Card className='mt-5' bodyStyle={{ padding: "0.5rem" }} title={null}>
          <TableUsers />
        </Card>
      </div>
    </ContextTableUsers.Provider>
  );
};

export default UsersAdmin;
