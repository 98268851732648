import React from "react";
import { Card } from "antd";
import TablePatient from "./tablePatient";

const Patients = () => {
  return (
    <div className='w-full mx-auto md:w-3/5 min-h-25rem p-6 '>
      <p className='mt-3 text-2xl font-bold mb-0'>Lista de Pacientes</p>
      <Card className='mt-5' bodyStyle={{ padding: "0.5rem" }} title={null}>
        <TablePatient />
      </Card>
    </div>
  );
};
export default Patients;
