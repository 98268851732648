import React from "react";
import { Link } from "react-router-dom";

export default function SectionLinks() {
  return (
    <div className='grid gap-4 grid-cols-2 md:grid-cols-4 items-center md:justify-start justify-center'>
      <div className='mx-auto w-full border-r border-white flex justify-center items-center'>
        <Link to='/md/familiar' className='text-white '>
          Para ti y tu familia
        </Link>
      </div>
      <div className='mx-auto w-full border-r border-white flex justify-center items-center'>
        <Link to='/md/empresa' className='text-white '>
          Empresas
        </Link>
      </div>
      <div className='mx-auto w-full flex justify-center items-center border-r border-white md:border-none'>
        <Link to='/md/tecnologia' className='text-white '>
          Tecnología
        </Link>
      </div>
      {
        // <div className='mx-auto w-full flex justify-center items-center border-r border-white md:border-none'>
        //   <Link
        //   to='/md/encuentra/especialistas'
        //   className='text-white'
        // >
        //    Red de Especialistas
        // </Link>
        // </div>
      }
    </div>
  );
}
