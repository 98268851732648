import React, { useContext } from "react";
import Tabs from "antd/lib/tabs";
import Spin from "antd/lib/spin";
import BtnTrigger from "layouts/profile/BtnToggleShowSider";

import TabsConfig from "./tabsConfig";

import { profileMedicContext } from "../../index";
import { GlobalContext } from "../../../App";
import * as actionName from "../storeProfileMedic/actionStoreMedic";
// import WizardInterconsulta from "components/WizardInterconsulta";

import includes from "lodash/includes";
import get from "lodash/get";

const TabsIndex = () => {
  const { state: globalState } = useContext(GlobalContext);
  const { user } = globalState;
  if (!user) {
    return (
      <div className='h-screen flex'>
        <Spin className='mx-auto my-auto' />
      </div>
    );
  } else {
    return <ContendView user={user} />;
  }
};

const ContendView = ({ user }) => {
  const { state, dispatch } = useContext(profileMedicContext);

  const setTabs = (key) => {
    dispatch({
      type: actionName.SET_TABS,
      payload: key,
    });
  };

  return (
    <Tabs
      id='tabDoctor'
      activeKey={state.tabsActive}
      onTabClick={setTabs}
      size='large'
      tabBarExtraContent={[
        <BtnTrigger key='BtnWizardMD' />,
        // <WizardInterconsulta key='WizardMD' classNameButton='hidden xl:inline-block' />,
      ]}
    >
      {TabsConfig.map((value) => {
        const roleVerify = includes(value.role, get(user, "role.name"));
        return roleVerify ? (
          <Tabs.TabPane tab={value.tab} key={value.key}>
            {value.children}
          </Tabs.TabPane>
        ) : null;
      })}
    </Tabs>
  );
};

export default TabsIndex;
