import React, { useContext } from "react";
import { Popover, Tag, Button } from "antd";
import { getValueAprobalStatus } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import * as actionNames from "./store/actions";
import { ContextTablaDoctores } from "./index";
import RowAvatar from "components/RowAvatar";
import Price from "./Price";

const columns = [
  {
    title: "Nombre",
    dataIndex: "fullName",
    className: "text-center p-1",
    render: (row, fullRow) => (
      <RowAvatar
        text={row}
        row={fullRow}
        description={
          <p className='text-left pb-1 mb-1'>
            # MINSA: <strong>{fullRow.minsaNumber}</strong>
          </p>
        }
      />
    ),
  },
  // {
  //   title: '# MINSA',
  //   dataIndex: 'minsaNumber',
  //   className: 'text-center'
  // },
  {
    title: "Especialidad",
    dataIndex: "specialties",
    className: "text-center w-1/6",
    render: (row, fullRow) => renderSpecialties(row, fullRow),
  },
  {
    title: "Detalles",
    dataIndex: "custom_verInfo",
    className: "text-center w-5por",
    render: (row, fullRow) => <RenderInfo row={row} fullRow={fullRow} />,
  },
  {
    title: "Precio EasyMD",
    dataIndex: "appointmentPriceEasyMD",
    className: "text-center w-5por",
    render: (row, fullRow) => <RenderPrice row={row} fullRow={fullRow} />,
  },
  {
    title: "Estado",
    dataIndex: "approvalStatus",
    className: "text-center w-5por",
    render: (row) => <RenderEstatus row={row} />,
  },
  {
    title: "Acciones",
    dataIndex: "custom_acciones",
    className: "text-center w-5por",
    render: (row, fullRow) => <RenderActionsAprobarRechazar fullRow={fullRow} />,
  },
];

const RenderInfo = ({ fullRow }) => {
  const { dispatch } = useContext(ContextTablaDoctores);
  const clickInfo = () => {
    dispatch({
      type: actionNames.SET_INFO_DOCTOR,
      payload: fullRow,
    });
    dispatch({
      type: actionNames.SET_MODAL_INFO,
      payload: true,
    });
  };
  return (
    <Button
      type='default'
      shape='round'
      size='small'
      className='border-0 shadow-none'
      onClick={() => clickInfo()}
    >
      <FontAwesomeIcon icon={faEye} />
    </Button>
  );
};

const RenderEstatus = ({ row }) => {
  if (row) {
    return getValueAprobalStatus(row, "table") || "N/A";
  }
  return "N/A";
};

const RenderPrice = ({ row, fullRow }) => {
  if (row >= 0) {
    return Price(fullRow);
  }
  return "N/A";
};

const RenderActionsAprobarRechazar = ({ fullRow }) => {
  const { dispatch } = useContext(ContextTablaDoctores);
  const { approvalStatus } = fullRow;

  const openDeclinar = () => {
    dispatch({
      type: actionNames.SET_INFO_DOCTOR,
      payload: fullRow,
    });
    dispatch({
      type: actionNames.SET_MODAL_RECHAZAR,
      payload: true,
    });
  };
  const openAprobar = () => {
    dispatch({
      type: actionNames.SET_INFO_DOCTOR,
      payload: fullRow,
    });
    dispatch({
      type: actionNames.SET_MODAL_ACEPTAR,
      payload: true,
    });
  };

  const DeclinarButton = () => {
    return (
      <Button type='primary' danger shape='round' size='small' onClick={() => openDeclinar()}>
        {" "}
        Declinar
      </Button>
    );
  };

  const AprobarButton = () => {
    return (
      <Button
        type='primary'
        shape='round'
        size='small'
        className='button-success'
        onClick={() => openAprobar()}
      >
        {" "}
        Aprobar
      </Button>
    );
  };

  switch (approvalStatus) {
    case "DOCUMENTS_REQUIRED":
      return null;
    case "UNDER_REVIEW":
      return (
        <div className='flex xs:flex-col md:flex-row content-center justify-center gap-3'>
          <DeclinarButton />
          <AprobarButton />
        </div>
      );
    case "APPROVED":
      return (
        <div className='flex xs:flex-col md:flex-row content-center justify-center gap-3'>
          <DeclinarButton />
        </div>
      );
    case "DENIED":
      return null;
    default:
      return "ERROR";
  }
};

const renderSpecialties = (row, fullRow) => {
  if (row) {
    if (row.length > 1) {
      return (
        <Popover
          content={
            <div className='w-full flex flex-col justify-center items-center'>
              <p className='font-semibold text-center'>{fullRow.fullName}</p>
              <ul>
                {row.map((value, index) => {
                  return <li key={index}>{value.name}</li>;
                })}
              </ul>
            </div>
          }
        >
          <div className='flex flex-row justify-center items-center'>
            <span>{row[0].name},</span>
            <Tag
              color='blue'
              className='ml-1 flex flex-row justify-center content-center rounded-lg px-1'
            >
              <span className='text-2xl self-center'>+</span>
              {row.length - 1}
            </Tag>
          </div>
        </Popover>
      );
    } else if (row.length === 1) {
      return <span>{row[0].name}</span>;
    }
  }
  return "N/A";
};

export default columns;
