import React, { useContext } from "react";
// gql
import { useQuery } from "@apollo/react-hooks";
import gqlAffiliation from "../gql";
// antd
import Card from "antd/lib/card";
// component
import CardPlan from "./CardPlan";
// context
import { GlobalContext } from "views/App";

const IndexPlans = () => {
  const { state } = useContext(GlobalContext);
  const idUser = state.user.id;
  const companyPlan = state.user.belongsToCompany?.companyPlan;
  const beneficiary = Boolean(state.user.beneficiaryOf);

  const { data, loading, error } = useQuery(gqlAffiliation.meUserPlans, {
    fetchPolicy: "no-cache",
  });
  if (loading) {
    return <Card loading />;
  }
  if (data && !error) {
    if (!data.me.plans.length) {
      return null;
    } else {
      if (companyPlan) {
        const activePlan = data.me.plans.at(-1);
        return (
          <CardPlan
            className='my-2'
            status={activePlan.status}
            expireAt={activePlan.expireAt}
            key={activePlan}
            valuesPlans={activePlan.plan}
            idUser={idUser}
            companyPlan={companyPlan}
          />
        );
      }
      return data.me.plans.map((value, index) => (
        <CardPlan
          className='my-2'
          status={value.status}
          expireAt={value.expireAt}
          key={index}
          valuesPlans={value.plan}
          idUser={idUser}
          beneficiary={beneficiary}
        />
      ));
    }
  }
};

export default IndexPlans;
