import React from "react";

import { Modal, Button } from "antd";

const ModalTermsAndConditions = (props) => {
  const Check = () => {
    if (props.setFields()) {
      props.setFields();
    }
    props.setVisbleModaTermsAndConditions(false);
  };

  const customFooter = (
    <div className='flex justify-between h-full'>
      <div className='my-auto'>
        <Button
          shape='round'
          key='cancel'
          onClick={() => props.setVisbleModaTermsAndConditions(false)}
        >
          Cerrar
        </Button>
      </div>
      <div className='my-auto'>
        <Button htmlType='submit' shape='round' key='send' type='primary' onClick={() => Check()}>
          Aceptar
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        title='TÉRMINOS Y CONDICIONES DEL SERVICIO DE TELEMEDICINA'
        closable={props.visibleFooter === false}
        visible={props.visbleModaTermsAndConditions}
        destroyOnClose
        onCancel={() => props.setVisbleModaTermsAndConditions(false)}
        width='55rem'
        footer={props.visibleFooter === false ? null : customFooter}
      >
        <div style={{ height: "65vh" }} className='mx-auto overflow-auto pl-2 pr-2'>
          {props.children}
        </div>
      </Modal>
    </>
  );
};

ModalTermsAndConditions.defaultProps = {
  visibleFooter: true,
};

export default ModalTermsAndConditions;
