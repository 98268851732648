import React from "react";
import { Input, Form } from "antd";

const BodyModal_Rechazar = ({ loading }) => {
  return (
    <div className='w-full flex flex-col'>
      <p className='font-bold mb-3'>Razón</p>
      <p className='mb-3'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
        voluptate velit esse cillum dolore eu fugiat nulla pariatur
      </p>
      <Form.Item
        name='razon'
        validateTrigger='onBlur'
        rules={[
          { required: true, message: "Debe dar una razón para declinar al doctor" },
          { string: true, min: 5, message: "El mínimo es 5 letras" },
        ]}
      >
        <Input.TextArea rows={4} name='razon' disabled={loading} />
      </Form.Item>
    </div>
  );
};

export default BodyModal_Rechazar;
