import React, { useContext } from "react";
import { differenceWith, toPairs, fromPairs, some, isEqual } from "lodash";
import { SET_INITIAL_STATE, SET_USER } from "../store/actions";
import { Modal } from "antd";

import { ContextTableUsers } from "../index";
import FormCrearUsuario from "../../../../components/FormCrearUsuario";

const ModalNew = ({ visible }) => {
  const { state, dispatch } = useContext(ContextTableUsers);
  const { refetch } = state;

  const afterClose = () => {
    dispatch({ type: SET_INITIAL_STATE });
  };

  const refetchUsers = () => {
    dispatch({ type: SET_INITIAL_STATE });
    refetch();
  };

  return (
    <Modal
      title='Usuario'
      style={{
        minWidth: "20rem",
      }}
      visible={visible}
      destroyOnClose
      width='50vw'
      onCancel={afterClose}
      footer={null}
      afterClose={afterClose}
      maskClosable={false}
    >
      {visible ? <BodyModal closeAndRefetch={refetchUsers} /> : null}
    </Modal>
  );
};

const BodyModal = ({ closeAndRefetch }) => {
  const { state, dispatch } = useContext(ContextTableUsers);
  const { roles, user } = state;
  const { success, loading, error, edit, ...userInfo } = user;

  const onFinish = async (values) => {
    if (values.password === undefined) delete values.password;
    const { id: selectedId, pictureURL: __, ...cleanUserInfo } = userInfo;
    cleanUserInfo["role"] =
      cleanUserInfo.role === Object(cleanUserInfo.role)
        ? cleanUserInfo.role.id
        : cleanUserInfo.role;
    const isDiff = some(values, (val, key) => cleanUserInfo[key] !== val);
    const diffValues = fromPairs(differenceWith(toPairs(values), toPairs(cleanUserInfo), isEqual));
    if (isDiff) {
      dispatch({
        type: SET_USER,
        payload: {
          ...(!edit && { new: true }),
          ...(edit && { submitEdit: true }),
          variables: {
            ...(edit
              ? { id: selectedId, ...diffValues }
              : {
                  ...values,
                  ...(values.password && { confirmPassword: values.password }),
                  ...(values.phoneNumber && { phoneNumber: values.phoneNumber }),
                }),
          },
          loading: true,
          success: false,
        },
      });
    }
  };

  const formProps = {
    edit,
    success,
    loading,
    data: user,
    roles,
    error,
    onFinish,
    successPost: closeAndRefetch,
    title: `${edit ? "Editar" : "Creación de nuevo"} Usuario`,
  };

  return <FormCrearUsuario {...formProps} />;
};

export default ModalNew;
