import gql from "graphql-tag";

const getInfoRequestMobileGQL = () => {
  const schema = `query getInfoRequestMobile($url: String!) {
        getInfoRequestMobile(url: $url) {
                token
                type
                info
                id
            }
        }
        `;
  return gql`
    ${schema}
  `;
};

const meGQL = () => {
  const schema = `query me {
          me {
            email 
            role { name } 
            verified 
            fullName 
            pictureURL { url } 
            sex 
            country 
            id 
            phoneNumber 
            belongsToCompany { id }  
            plans{ plan{ id name } } 
            appointmentMD { id reasonForConsultation }
          }
        }
      `;
  return gql`
    ${schema}
  `;
};

export { getInfoRequestMobileGQL, meGQL };
