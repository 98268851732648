import React from "react";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

// views
import Panel from "views/CompanyAdmin/Panel";

export default [
  {
    key: "panel",
    icon: <Fa icon={faHome} />,
    component: <Panel />,
    children: <span className='ml-3'>Panel de admin</span>,
    path: "/panel",
    name: "Panel",
    layout: "/company",
    useInSidebar: true,
  },
  /*{
    key: 'panel/grafico',
    icon: '',
    component: <CompanyCharts />,
    children: <span className='ml-3'>Dashboard</span>,
    path: '/panel/grafico',
    name: 'Empresas',
    layout: '/company',
    useInSidebar: false
  }*/
];
