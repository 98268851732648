import React, { useContext } from "react";

// componets
import EmptyCompany from "./Company/EmptyCompany";
import CardCompany from "views/Patient/Tabs/Affiliation/Company/Card";
import CardPlans from "./plans";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import * as actionName from "../../../../store/actions";
// context
import { GlobalContext } from "views/App";
// gql
import gqlAffiliation from "./gql";

const RenderMembership = (_) => {
  const { state, dispatch } = useContext(GlobalContext);
  const variables = { id: state.user.id };
  const { belongsToCompany, plans } = state.user;

  const onSuccess = () => {
    dispatch({
      type: actionName.SET_USER,
      payload: {
        ...state.user,
        belongsToCompany: null,
      },
    });
  };

  const props = {
    Btn: {
      type: "text",
      icon: <Fa icon={faTrashAlt} />,
      danger: true,
    },
    HOCC: {
      gql: gqlAffiliation.remove,
      variables: {},
      onSuccess,
      refetchQueries: [],
      title: "Está seguro de eliminar tu afiliación!",
      content: "lorem ",
      cancelText: "Cancelar",
      okText: "Eliminar",
      mesageError: "Error",
      mesageSuccess: "Has eliminado tu afiliación con éxito!",
    },
    propsCardCompany: {
      variables,
      belongsToCompany,
    },
  };

  if (!belongsToCompany && !plans.length) {
    return <EmptyCompany />;
  }

  return (
    <>
      {belongsToCompany && (
        <CardCompany Hocc={{ ...props.HOCC }} Btn={{ ...props.Btn }} {...props.propsCardCompany} />
      )}
      {!!plans.length && <CardPlans />}
    </>
  );
};

export default RenderMembership;
