import React from "react";
import moment from "moment";
// antd
import Card from "antd/lib/card";
import Divider from "antd/lib/divider";
// componet
import HOCConfirmMutation from "components/HOCConfirmMutation";
import Button from "antd/lib/button";
import Tag from "antd/lib/tag";
import gqlAffiliation from "../gql";
import FeaturePlan from "./FeaturePlan";
// import * as actionName from '../../../../../store/actions'

const CardPlan = (_) => {
  const {
    valuesPlans,
    expireAt,
    status,
    className: classNameProps,
    idUser,
    companyPlan,
    beneficiary,
  } = _;
  var currentTime = moment();
  var expirePlan = moment(expireAt);

  const props = {
    extraCard: {
      gql: gqlAffiliation.cancelPlan,
      variables: {
        userId: idUser,
        planId: valuesPlans.id,
      },
      // onSuccess,
      refetchQueries: ["meUserPlans"],
      title: "Está seguro de cancelar tu afiliación al plan!",
      content: valuesPlans.name,
      cancelText: "Cancelar",
      okText: "Eliminar",
      mesageError: "Error",
      mesageSuccess: "Has cancelado tu afiliación con éxito!",
    },
    btn: {
      title: "Cancelar",
      danger: true,
      children: "Cancelar",
    },
    tag: {
      className: "rounded",
      color: "#DB5151",
      style: { backGroundColor: `${beneficiary && status === "ACTIVE" && "#8BC53F"}` },
    },
  };

  const statusPlans = () => {
    if (status === "ACTIVE" && !companyPlan && !beneficiary) {
      return (
        <HOCConfirmMutation {...props.extraCard}>
          <Button {...props.btn} />
        </HOCConfirmMutation>
      );
    } else {
      return (
        <Tag {...props.tag}>
          {companyPlan
            ? "Plan Administrado por Compañia"
            : beneficiary && status === "ACTIVE"
            ? "Activo"
            : "Suscripción al plan cancelada"}
        </Tag>
      );
    }
  };

  return (
    <Card className={classNameProps} title={valuesPlans.name} extra={statusPlans()}>
      <p>{valuesPlans.description}</p>
      <Divider />
      <h2>
        <strong>Beneficios de plan</strong>{" "}
      </h2>
      <FeaturePlan features={valuesPlans.features} />
      <div className='flex justify-between mt-2'>
        <span>
          <strong>{`Quedan ${expirePlan.diff(currentTime, "days")} días al plan`}</strong>
        </span>
        <span>
          Fecha de expiración:<strong>{` ${expirePlan.format("DD/MM/YYYY")}`}</strong>
        </span>
      </div>
    </Card>
  );
};

export default CardPlan;
