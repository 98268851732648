import React from "react";
import Row from "antd/lib/row";
import Button from "antd/lib/button";
import { faLaptopMedical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PlansBody from "../../../components/PlanesEasyMD";

const PlanesEasyMD = () => {
  // EAS-1361
  // redirect to whatsApp is fixed
  const phoneNumber = "50578726684";
  const message = "";

  const handleWhatsAppRedirect = () => {
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };
  return (
    <div className='w-full min-h-25rem' style={{ backgroundColor: "#e3f4fb" }}>
      <Row className='w11/12 flex flex-col justify-center content-center mb-10'>
        <div className='mt-10'>
          <h3 className='text-primary text-lg md:text-xl lg:text-4xl stroke-2 text-center'>
            Planes
            <span className='text-success mx-1'>EasyMD</span>
            <br />
            Los mejores beneficios de salud a solo un click.
          </h3>
          <div className='mx-auto w-11/12 lg:w-5/6 xl:w-3/4'>
            <p className='text-center text-base md:text-lg xl:text-xl mx-3 mb-0'>
              ¡Con planes EasyMD vos y tu familia tendrán acceso a un Doctor las 24 horas del día
              los 7 días de la semana! Acompañado de varios beneficios inigualables en la región.
            </p>
            <p className='text-center text-base md:text-lg xl:text-xl mx-3'>
              Ahorra en transporte y tiempo. Con EasyMD tendrás tus citas en menos de 5 minutos.
            </p>
          </div>
        </div>
      </Row>
      <PlansBody />
      <div className='flex justify-center w-full'>
        {/* <a rel='stylesheet' href='mailto: admin@easymd.com'> */}
        <Button
          type='default'
          shape='round'
          className='button-success mb-12 text-lg'
          size='large'
          icon={<FontAwesomeIcon className='mx-2 text-center' size='lg' icon={faLaptopMedical} />}
          onClick={handleWhatsAppRedirect}
        >
          Contáctanos!
        </Button>
        {/* </a> */}
      </div>
    </div>
  );
};

export default PlanesEasyMD;
