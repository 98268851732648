import React, { useEffect } from "react";
// ant
import { Form, InputNumber, Modal, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { HandleError } from "utils.js/HandleError";
// tags
import { updateMedicInfo } from "../gqlTags";

const FormInfoConsulta = (props) => {
  const [form] = Form.useForm();
  const { confirm } = Modal;

  const [updateInfoDoctor, { data, error }] = useMutation(updateMedicInfo);

  const onFinish = (valueForm) => {
    confirm({
      okText: "Aceptar",
      cancelText: "Cerrar",
      title: "Hola!",
      icon: <ExclamationCircleOutlined />,
      content:
        "El precio anteriormente elegido será visible para todos los usuarios que agenden contigo. Puedes modificarlo en cualquier momento y sé actualizará automáticamente.",
      onOk: async () => {
        await updateInfoDoctor({
          variables: {
            appointmentPrice: valueForm.appointmentPrice,
          },
        });
      },
      onCancel: () => {
        if (data) {
          form.setFields([
            {
              name: "appointmentPrice",
              value: data.updateDoctor.appointmentPrice,
              errors: null,
            },
          ]);
        } else {
          form.setFields([
            {
              name: "appointmentPrice",
              value: props.initalValues.appointmentPrice.value,
              errors: null,
            },
          ]);
        }
      },
    });
  };

  useEffect(() => {
    if (props.initalValues) {
      form.setFields([
        {
          name: "appointmentPrice",
          value: props.initalValues.appointmentPrice.value,
          errors: null,
        },
      ]);
    }

    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
      if (data) {
        form.setFields([
          {
            name: "appointmentPrice",
            value: data.updateDoctor.appointmentPrice,
            errors: null,
          },
        ]);
      } else {
        form.setFields([
          {
            name: "appointmentPrice",
            value: props.initalValues.appointmentPrice.value,
            errors: null,
          },
        ]);
      }
    }
  }, [props.initalValues, error, data, form]);

  // if (data) console.log(data)

  return (
    <>
      <h4 className='text-xl'>Precio de la consulta</h4>
      <Form
        form={form}
        layout='horizontal'
        name='basic'
        onFinish={(valueForm) => onFinish(valueForm)}
      >
        <div className='flex items-center w-full'>
          <span className='mr-2'>
            <strong>USD: </strong>
          </span>
          <Form.Item
            className='mb-1'
            label={null}
            name='appointmentPrice'
            rules={[
              {
                required: true,
                message: "Por favor ingrese un precio para la consulta!",
              },
            ]}
          >
            <InputNumber
              min={5}
              max={200}
              onBlur={() => form.submit()}
              className='rounded w-full'
              placeholder='Precio por consulta'
            />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default FormInfoConsulta;
