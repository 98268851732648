import React, { useEffect } from "react";
import VerifyLoginWrapper from "../../../components/VerifyLoginWrapper";
import VerifyIdAppointment from "./verifyIdAppointment";

// Usar VerifyLoginWrapper para validar que el usuario esta login
const Index = (props) => {
  useEffect(() => {
    if (!props.match) {
      props.history.push("/md/home");
    } else {
      const { id } = props.match.params;
      if (!id) {
        props.history.push("/md/home");
      } else if (id.toString().length < 8) {
        props.history.push("/md/home");
      }
    }
  }, [props.history, props.match]);

  if (!props.match.params) {
    return null;
  }

  return (
    <VerifyLoginWrapper>
      <VerifyIdAppointment id={props.match.params ? props.match.params.id : null} />
    </VerifyLoginWrapper>
  );
};

export default Index;
