import React from "react";
import { Spin } from "antd";

const LoadingSpin = ({ loading }) => {
  if (!loading) {
    return null;
  }
  return (
    <div className='flex flex-col h-auto'>
      <Spin className='mx-auto my-auto'></Spin>
    </div>
  );
};

export default LoadingSpin;
