import React, { useEffect, useRef } from "react";
// antd
import Form from "antd/lib/form";
import Space from "antd/lib/space";
import Spin from "antd/lib/spin";
// inputs definitions
import input from "./inputs/info";

const props = {
  Space: {
    size: 0,
    align: "center",
    style: {
      display: "grid",
    },
  },
};

export default function FormValidate(_props) {
  const emailRef = useRef();

  const { email_states, emailLoading, ...rest } = _props;

  useEffect(() => {
    const emailInput = emailRef.current;
    const emailValue = emailInput.getFieldValue("email");
    if (emailValue && email_states.error) {
      emailInput.setFields([
        {
          name: "email",
          errors: ["Error no se pudo enviar el correo"],
        },
      ]);
    }
  });

  return (
    <Form ref={emailRef} {...rest}>
      <Space {...props.Space}>{emailLoading ? <Spin /> : <Form.Item {...input.email} />}</Space>
    </Form>
  );
}
