import React, { useContext, useEffect } from "react";
import Button from "antd/lib/button";
import notification from "antd/lib/notification";
import * as actionName from "../Store/actionStore";
import { faCalendarWeek } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "react-router-dom/Link";
import { useMutation } from "@apollo/react-hooks";
import { HandleError } from "utils.js/HandleError";
// store
import { wizardContext } from "../ModalWizard/ContendModal";
import * as actionNameGlobal from "../../../store/actions";

// graphQL
import { createAppointmentMD } from "../gqlWizard";

const FooterModal = (props) => {
  const { state, dispatch } = useContext(wizardContext);
  const { onClose, form, user, globalDispatch } = props;
  const [mutationAppointmentMD, { data, error, loading }] = useMutation(createAppointmentMD);

  const setSteps = (key) => {
    if (key === "next") {
      if (state.stepsActive === 1) {
        form.submit();
      } else {
        dispatch({
          type: actionName.SET_STEPS,
          payload: state.stepsActive + 1,
        });
      }
    } else {
      dispatch({
        type: actionName.SET_STEPS,
        payload: state.stepsActive - 1,
      });
    }
  };

  const finishSteps = async () => {
    const infoDataNewAppointmentMD = {
      appointmentSpecialty: state.specialtyActive,
      newSymptoms: state.detailsAppointments.symptoms,
      reasonForConsultation: state.detailsAppointments.reasonAppointments,
      appointmentType: state.appointmentsMethod,
      patient: user.id,
      newMedicalConditions: state.detailsAppointments.MedicalConditions,
      newDrugAllergies: state.detailsAppointments.DrugAllergies,
    };
    try {
      await mutationAppointmentMD({ variables: infoDataNewAppointmentMD });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (data) {
      const { id, reasonForConsultation } = data.createAppointmentMD;
      dispatch({
        type: actionName.SET_FINISHWIZARD,
        payload: true,
      });
      globalDispatch({
        type: actionNameGlobal.SET_USER,
        payload: {
          ...user,
          appointmentMD: {
            id,
            reasonForConsultation,
          },
        },
      });
    }
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
      dispatch({
        type: actionName.SET_STEPS,
        payload: null,
      });
    }
  }, [data, error, globalDispatch, dispatch, user]);

  const ButtonWizard = () => {
    return (
      <div className='mt-6 pb-6 flex justify-between content-end'>
        {state.stepsActive === 0 ? (
          <Button
            disabled={loading || state.loadingGlobal}
            onClick={() => onClose()}
            type='primary'
            shape='round'
            danger
            size='large'
          >
            Cancelar
          </Button>
        ) : (
          <Button
            disabled={loading || state.loadingGlobal}
            onClick={() => setSteps("previous")}
            type='primary'
            shape='round'
            size='large'
          >
            Atras
          </Button>
        )}
        {state.stepsActive !== 2 ? (
          <Button
            disabled={loading || state.loadingGlobal}
            onClick={() => setSteps("next")}
            type='primary'
            shape='round'
            size='large'
          >
            Siguiente
          </Button>
        ) : (
          <Button
            onClick={() => finishSteps()}
            type='default'
            shape='round'
            size='large'
            icon={<FontAwesomeIcon className='mx-2' size='lg' icon={faCalendarWeek} />}
            disabled={loading}
            loading={loading || state.loadingGlobal}
            className='button-success'
          >
            Iniciar Cita
          </Button>
        )}
      </div>
    );
  };

  if (!user) {
    return (
      <div className='mt-8 pb-6 flex justify-between content-end'>
        <Button type='primary' shape='round' size='large'>
          <Link onClick={() => onClose()} to='/md/auth/login'>
            Iniciar sesión
          </Link>
        </Button>
        <Button type='default' shape='round' className='button-success' size='large'>
          <Link onClick={() => onClose()} to='/md/auth/signup'>
            Crear Cuenta
          </Link>
        </Button>
      </div>
    );
  } else {
    if (!user.plans.length) {
      if (user.belongsToCompany || user.beneficiaryOf) {
        return <ButtonWizard />;
      } else {
        return (
          <div className='mt-6 pb-6 flex justify-end'>
            <Button type='default' shape='round' className='button-success' size='large'>
              <Link onClick={() => onClose()} to='/md/planesEasyMD'>
                Ver Planes
              </Link>
            </Button>
          </div>
        );
      }
    } else {
      if (state.stepsActive === null) {
        return null;
      } else {
        return <ButtonWizard />;
      }
    }
  }
};

export default FooterModal;
