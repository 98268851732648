import style from "components/MethodPay/style.module.scss";

export default {
  Modal: {
    width: 690,
    title: "Nuevo método de pago",
    okText: "AÑADIR TARJETA",
    centered: true,
    className: `Dialog ${style.Dialog}`,
    bodyStyle: {
      padding: 20,
      overflow: "hidden",
    },
    cancelButtonProps: {
      style: {
        display: "none",
      },
    },
    destroyOnClose: true,
  },
};
