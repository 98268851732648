import React from "react";
// antd
import Input from "antd/lib/input";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export default {
  name: {
    name: "name",
    label: "Nombre",
    rules: [
      {
        required: true,
        message: "Requerido!",
      },
      {
        min: 4,
        message: "Nombre muy corto",
      },
    ],
    children: <Input placeholder='Nombre' />,
  },
  description: {
    name: "description",
    label: "Descripción",
    children: <Input.TextArea placeholder='Descripción' />,
  },
  actions: {
    remove: {
      type: "link",
      icon: <Fa icon={faTrashAlt} />,
      danger: true,
    },
  },
};
