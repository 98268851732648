import React, { useEffect, useRef, useState } from "react";
import { Form, Button, notification, Divider, Input } from "antd";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// componets
import InputForm from "./InputForm";
import getInputList from "./InputList";

const FormSignUp = ({
  title,
  data,
  loading,
  error,
  onFinish,
  groupList,
  success,
  successPost,
  edit,
}) => {
  const [form] = Form.useForm();
  const [group, setGroup] = useState(groupList || []);
  const [newGroup, setNewGroup] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    if (success) {
      notification.success({
        message: "Exito!",
      });
      successPost();
    }
  }, [data, success, error, loading, successPost]);

  // useEffect(() => {
  // if (data.edit) {
  // form.setFields([]);
  // }
  // }, [data, form]);

  const validateOnFinish = (values) => {
    form.validateFields().then((values) => {
      onFinish(values);
    });
  };

  const disabledState = !!loading;
  const InputList = getInputList(edit);

  const onNewGroupChange = (event) => {
    setNewGroup(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    setGroup([...group, newGroup]);
    setNewGroup("");
    setTimeout(() => {
      inputRef.current.focus();
    }, 0);
  };

  return (
    <div className='w-full lg:w-full'>
      <h3 className='mt-3 mb-8 text-2xl'>
        <strong>{title}</strong>
      </h3>
      <Form
        form={form}
        layout='horizontal'
        validateTrigger='onBlur'
        name='basic'
        initialValues={{ remember: true }}
        onFinish={(valueForm) => validateOnFinish(valueForm)}
      >
        <div className='grid grid-cols-12 gap-2'>
          <div className='col-span-12 md:col-span-12 lg:col-span-12'>
            <InputForm
              item={{ ...InputList.name.item }}
              input={{
                ...InputList.name.input,
                disabled: disabledState,
              }}
            />
          </div>
          <div className='col-span-12 md:col-span-6'>
            <InputForm
              typeInput='select'
              seeTooltip={false}
              item={{ ...InputList.usedFor.item }}
              select={{
                ...InputList.usedFor.select,
                dropdownRender: (menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <div className='flex w-full' style={{ padding: "0 8px 4px" }}>
                      <Input
                        className='flex-grow'
                        placeholder='Ingresar Grupo'
                        ref={inputRef}
                        value={newGroup}
                        onChange={onNewGroupChange}
                      />
                      <Button
                        type='text'
                        icon={<FontAwesomeIcon className='text-center' icon={faPlus} />}
                        onClick={addItem}
                      >
                        Agregar
                      </Button>
                    </div>
                  </>
                ),
                options: group?.map((group) => ({ label: group, value: group })),
              }}
            />
          </div>
          <div className='col-span-12 md:col-span-6'>
            <InputForm
              typeInput='select'
              seeTooltip={false}
              item={{ ...InputList.permissions.item }}
              select={{
                ...InputList.permissions.select,
                mode: "multiple",
                options: ["create-all", "read-all", "update-all", "delete-all"].map((perm) => ({
                  label: perm,
                  value: perm,
                })),
              }}
            />
          </div>
          <div className='col-span-12 flex flex-col md:flex-row'>
            <div className='flex flex-col'>
              <div className='flex xs:flex-col md:flex-row justify-between col-span-6'>
                <Form.Item className=''>
                  <Button
                    disabled={loading}
                    loading={loading}
                    htmlType='submit'
                    type='primary'
                    shape='round'
                    size='large'
                    className='xs:mx-auto md:mx-4'
                  >
                    Crear Rol
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

FormSignUp.defaultProps = {
  buttonLogin: false,
  redirect: true,
  clickLoginButton: () => {},
};

export default React.memo(FormSignUp);
