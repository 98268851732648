import React, { useEffect, useState } from "react";
// ant
import Select from "antd/lib/select";
// helper
import isUndefined from "lodash/isUndefined";

const SelectFilterPlans = (_) => {
  const { Option } = Select;
  const [valueSelect, setValueSelect] = useState({ value: null });

  useEffect(() => {
    if (_.value == null) {
      setValueSelect({ value: null });
    } else if (_.value) {
      setValueSelect({ value: true });
    } else {
      setValueSelect({ value: false });
    }
  }, [_.value]);

  const props = {
    onChange: (e) => {
      _.setValue(isUndefined(e) ? null : e.value);
    },
    allowClear: true,
    className: "mx-3",
    placeholder: "Plan del usuario",
    optionFilterProp: "children",
    labelInValue: true,
    value: valueSelect,
  };

  return (
    <Select {...props}>
      <Option value>Plan activo</Option>
      <Option value={false}>Plan inactivo</Option>
    </Select>
  );
};

export default SelectFilterPlans;
