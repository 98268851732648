import gql from "graphql-tag";

export const takeAppointmentMDGql = gql`
  mutation takeAppointmentMD($id: String!) {
    takeAppointmentMD(id: $id) {
      id
    }
  }
`;

export const appointmentMDStatusGql = gql`
  query appointmentmdById($id: String!) {
    appointmentmdById(id: $id) {
      id
      status
    }
  }
`;

// la lista del card de la izquierda, citas para aun sin asignar / pending
export const appointmentMDListPendingGql = gql`
  query appointmentmds(
    $perPage: Float
    $page: Float
    $sort: [appointmentmdSort!] = []
    $filters: [appointmentmdFilter!] = []
  ) {
    appointmentmds(perPage: $perPage, page: $page, sort: $sort, filters: $filters) {
      total
      pages
      docs {
        id
        appointmentDateAndTime
        patient {
          fullName
          pictureURL {
            url
          }
        }
        status
        appointmentType
        reasonForConsultation
        appointmentSpecialty
      }
    }
  }
`;

export const appointmentMDIntervalPending = gql`
  query appointmentmds(
    $perPage: Float
    $page: Float
    $sort: [appointmentmdSort!] = []
    $filters: [appointmentmdFilter!] = []
  ) {
    appointmentmds(perPage: $perPage, page: $page, sort: $sort, filters: $filters) {
      docs {
        id
      }
    }
  }
`;

// la lista del card de la derecha, citas asignadas del doctor / ongoing
export const appointmentMDListOngoingGql = gql`
  query appointmentmds(
    $perPage: Float
    $page: Float
    $sort: [appointmentmdSort!] = []
    $filters: [appointmentmdFilter!] = []
  ) {
    appointmentmds(perPage: $perPage, page: $page, sort: $sort, filters: $filters) {
      docs {
        id
        appointmentDateAndTime
        patient {
          fullName
          pictureURL {
            url
          }
          newMedicalConditions
        }
        newSymptoms
        status
        appointmentType
        reasonForConsultation
        appointmentSpecialty
      }
    }
  }
`;

// Detalle para la modal
export const appointmentByMDDetailGql = gql`
  query appointmentmdById($id: String!) {
    appointmentmdById(id: $id) {
      id
      appointmentDateAndTime
      patient {
        fullName
        pictureURL {
          url
        }
        newMedicalConditions
      }
      newSymptoms
      status
      appointmentType
      reasonForConsultation
      appointmentSpecialty
    }
  }
`;
