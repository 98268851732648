import React, { useState, useEffect } from "react";
import { Button, Modal, notification } from "antd";
import { Link, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";

import { completeAppointmentGql } from "./gqlTags";

// COMPLETE
// CANCELED
// SCHEDULED
// ONGOING

const ButtonFinish = ({ isFinish, infoAppointment, refetch }) => {
  const className = ""; //(isFinish) ? ' bg-primary-dark ' : '  bg-success-hover '
  const buttonClassName = isFinish ? " button-success " : "  bg-primary-hover ";
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [blockButton, setBlockButton] = useState(false);
  const history = useHistory();

  const [
    fetchCreateAppointment,
    { loading: loadingMutation, error: errorMutation, data: dataMutation },
  ] = useMutation(completeAppointmentGql, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (errorMutation && !loadingMutation) {
      setIsOpenModal(false);
      notification.error({
        message: "Error",
        description: errorMutation.graphQLErrors[0].message,
      });
    } else {
      if (!loadingMutation && dataMutation) {
        setIsOpenModal(false);
        setBlockButton(true);
        notification.success({
          message: "Cita completada",
        });
        refetch();
        setTimeout(() => {
          history.push({
            pathname: "/medic",
          });
        }, 300);
      }
    }
  }, [loadingMutation, errorMutation, dataMutation]);

  const onClose = () => {
    setIsOpenModal(false);
  };

  const onOk = async () => {
    try {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      const variables = {
        id: infoAppointment ? infoAppointment.id : null,
        patient: infoAppointment ? infoAppointment.patient.id : null,
        status: "COMPLETE",
      };
      await fetchCreateAppointment({
        variables: variables,
      });
    } catch (e) {
      setIsOpenModal(false);
    }
  };

  return (
    <div className={" w-full h-16 flex flex-col justify-center content-center px-12  " + className}>
      {isFinish ? (
        <Button
          type='default'
          shape='round'
          size='large'
          className={buttonClassName + " shadow-md"}
        >
          <Link to='/medic' className='break-words text-white'>
            {" "}
            Ir a mi perfil{" "}
          </Link>
        </Button>
      ) : (
        <Button
          disabled={blockButton}
          shape='round'
          type='primary'
          size='large'
          className='button-primary shadow-md'
          onClick={() => setIsOpenModal(true)}
        >
          Completar cita
        </Button>
      )}
      <Modal
        title='Completar la cita'
        visible={isOpenModal}
        destroyOnClose
        closable={!loadingMutation}
        maskClosable={false}
        onOk={onOk}
        onCancel={onClose}
        footer={[
          <Button key='back' loading={loadingMutation} onClick={onClose}>
            Regresar
          </Button>,
          <Button key='submit' type='primary' loading={loadingMutation} onClick={onOk}>
            Completar la cita
          </Button>,
        ]}
      >
        <p className='font-semibold text-xl text-primary'>
          Antes de completar la cita recuerda llenar:
        </p>
        <p className='text-sm'>
          <ul>
            <li>1. El diagnóstico y notas de recomendación del paciente</li>
            <li>2. Llenar el cuadro de medicamento recomendado o "Prescripción Médica"</li>
          </ul>
        </p>
        <p className='text-sm'>
          Toda esta información le llegará al perfil del paciente como "Receta Electrónica" de
          manera 100% segura (HIPPA Compliant)
        </p>
      </Modal>
    </div>
  );
};

export default ButtonFinish;
