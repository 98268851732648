import * as actionName from "./actions";
import initialState from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionName.SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case actionName.SET_FILTER_INITIAL:
      return {
        ...state,
        filter: initialState.filter,
      };

    case actionName.SET_EMPTY_DOCTORS:
      return {
        ...state,
        listDoctors: [],
      };

    case actionName.SET_DATA_QUERY:
      return {
        ...state,
        listDoctors: [...state.listDoctors, ...action.payload.docs],
        totalData: action.payload.total,
      };

    case actionName.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case actionName.SET_PERPAGE:
      return {
        ...state,
        perPage: action.payload,
      };

    case actionName.SET_PUNCTUATION:
      return {
        ...state,
        typeFilters: {
          ...state.typeFilters,
          byStarts: { byNumber: { field: "RATING", greaterOrEqualThan: action.payload } },
        },
      };

    case actionName.SET_NAME_DOCTOR:
      return {
        ...state,
        typeFilters: {
          ...state.typeFilters,
          byName:
            action.payload.length > 0
              ? { byText: { field: "FIRSTNAME", value: action.payload, or: "LASTNAME" } }
              : null,
        },
      };

    case actionName.SET_SPECIALTIES:
      return {
        ...state,
        typeFilters: {
          ...state.typeFilters,
          bySpeciality:
            action.payload === undefined || action.payload === null
              ? null
              : action.payload.length > 0
              ? { byId: { field: "SPECIALTIES", value: action.payload } }
              : null,
        },
      };

    case actionName.SET_LANGUAJE: {
      const newArrayLanguje = [];
      if (action.payload.length > 0) {
        action.payload.map((value) => newArrayLanguje.push(value.value));
        return {
          ...state,
          typeFilters: {
            ...state.typeFilters,
            byLanguaje:
              action.payload.length > 0
                ? { byId: { field: "LANGUAGES", value: newArrayLanguje } }
                : null,
          },
        };
      } else {
        return {
          ...state,
          typeFilters: {
            ...state.typeFilters,
            byLanguaje: null,
          },
        };
      }
    }

    case actionName.SET_FILTER_FINAL:
      return {
        ...state,
        totalData: initialState.totalData,
        page: initialState.page,
        perPage: initialState.perPage,
        listDoctors: [],
        filter: action.payload,
      };

    case actionName.SET_SCROLL_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case actionName.SET_TYPE_FILTERS_BY_HOME:
      return {
        ...state,
        typeFilters: {
          ...initialState.typeFilters,
          ...action.payload.filters,
        },
        defaultValuesSelect: {
          ...state.defaultValuesSelect,
          ...action.payload.defaultValuesSelect,
        } || { ...state.defaultValuesSelect },
      };

    /*
    case actionName.SET_FILTER_BYNAME:
      return {
        ...state,
        typeFilters: {
          ...state.typeFilters,
          byName: action.payload
        }
      } */

    /* case actionName.SET_FITER_ESPECIALIDAD:

        return {
            ...state,
            filter: [

            ]
        }    */

    default:
      return { ...state };
  }
};

export default reducer;
