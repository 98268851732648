import React, { useState } from "react";
import Lottie from "react-lottie";
// antd
import Button from "antd/lib/button";
//
import family from "assets/lottie/family.json";
import ModalPlans from "../../../components/PlanesEasyMD/ModalPlans";

export default function Banner({ loading = false }) {
  const [modalVisible, setModalVisible] = useState(false);
  const props = {
    lottie: {
      options: {
        loop: true,
        autoplay: true,
        animationData: family,
      },
    },
    msg: {
      children: "Proteje tu salud y la de los tuyos, Planes familiares EasyMD",
      className: "text-center",
    },
    btn: {
      onClick: () => setModalVisible((_) => !modalVisible),
      type: "primary",
      children: "Adquirir plan familiar",
      className: "mx-auto block mt-2",
    },
    modal: {
      modalVisible,
      setModalVisible,
    },
  };

  return (
    <>
      <Lottie {...props.lottie} />
      <p {...props.msg} />
      {!loading && <Button {...props.btn} />}
      <ModalPlans {...props.modal} />
    </>
  );
}
