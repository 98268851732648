import React from "react";
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
// components
import DownloadApps from "components/DownloadApps";
// css
import style from "../../../microSalud/Company/stylesCompany.module.scss";

export default function SectionCompanyFour() {
  return (
    <div className={style.profit}>
      <div className='container mx-auto'>
        <div className='w-full md:w-1/2 xl:w-5/12'>
          <h3 className='text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl leading-tight'>
            <span>¿Cómo Activo mi Plan EasyMD? </span>
          </h3>

          <div className='grid gap-4 my-6'>
            <div className='flex flex-row'>
              <FontAwesomeIcon className='mr-3 mt-1 text-primary text-2xl' icon={faCheckCircle} />
              <span className='xl:text-lg 2xl:text-xl'>
                Envía SALUD al 400 para activar tu Plan
              </span>
            </div>

            <div className='flex flex-row '>
              <FontAwesomeIcon className='mr-3 mt-1 text-primary text-2xl' icon={faCheckCircle} />
              <span className='xl:text-lg 2xl:text-xl'>
                Recibirás un mensaje de ALTA dándote la bienvenida a EasyMD. Ahora podés proceder a
                crear tu cuenta atreves de:&nbsp;
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.easymd.co/md/auth/signup'
                >
                  nuestra web
                </a>
                &nbsp;o descargando la app: &nbsp;
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://play.google.com/store/apps/details?id=com.easymdv2.android'
                >
                  Google Play
                </a>
              </span>
            </div>

            <div className='flex flex-row '>
              <FontAwesomeIcon className='mr-3 mt-1 text-primary text-2xl' icon={faCheckCircle} />
              <span className='xl:text-lg 2xl:text-xl'>
                Una vez creada tu cuenta, Accede a tu cuenta y haz “clic” en el botón de consulta
                inmediata para recibir tu atención medica o psicólogo a través de Chat.
              </span>
            </div>

            <div className='flex flex-row '>
              <FontAwesomeIcon className='mr-3 mt-1 text-primary text-2xl' icon={faCheckCircle} />
              <span className='xl:text-lg 2xl:text-xl'>
                Una vez terminada tu consulta recibirás una receta medica con la que podas comprar
                medicamentos y exámenes de laboratorios con descuentos desde la comodidad de tu casa
                o trabajo.
              </span>
            </div>

            <div className='flex flex-row '>
              <FontAwesomeIcon className='mr-3 mt-1 text-primary text-2xl' icon={faCheckCircle} />
              <span className='xl:text-lg 2xl:text-xl'>
                La duración de el plan easyMD es de 30 días, si deseas darte de baja solo envia BAJA
                al 400
              </span>
            </div>
          </div>
        </div>

        <div className='md:w-5/12 lg:w-1/4'>
          <DownloadApps />
        </div>
      </div>
    </div>
  );
}
