import React, { useEffect, useContext } from "react";
import { Row, Col } from "antd";
import FormLogin from "../../../../components/FormLogin";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../App";
import { SET_USER } from "../../../../store/actions";

// utils
import { logoutAndClearAll } from "layouts/utils";

const LoginTokenExpired = ({ idUser, setLoginSuccess }) => {
  const history = useHistory();
  const { dispatch } = useContext(GlobalContext);

  const loginGql = () => {
    return gql`
      query login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
          token
          refreshToken
        }
      }
    `;
  };

  const meGql = () => {
    const schema = `query {
        me {
          email role { name } verified fullName pictureURL { url } sex country id nationality phoneNumber belongsToCompany { id } appointmentMD { id reasonForConsultation }
        }
        }
    `;
    return gql`
      ${schema}
    `;
  };

  const [fetchLogin, { loading: loadingLogin, error: errorLogin, data: dataLogin }] = useLazyQuery(
    loginGql(),
    { fetchPolicy: "no-cache" }
  );

  const [getUserInfo, { loading: loadingMe, data: dataMe, error: errorMe }] = useLazyQuery(
    meGql(),
    { fetchPolicy: "no-cache", notifyOnNetworkStatusChange: true }
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (!loadingLogin) {
      if (dataLogin) {
        localStorage.setItem("token", dataLogin.login.token);
        localStorage.setItem("refreshToken", dataLogin.login.refreshToken);
        getUserInfo();
      }
    }
  }, [loadingLogin, dataLogin, errorLogin, getUserInfo]);

  useEffect(() => {
    if (!loadingMe) {
      if (errorMe) {
        dispatch({
          type: SET_USER,
          payload: null,
        });
        logoutAndClearAll();
        history.push("/");
      }
      if (dataMe) {
        dispatch({
          type: SET_USER,
          payload: dataMe.me,
        });
        if (dataMe.me.id === idUser) {
          setLoginSuccess();
        } else {
          history.push("/");
        }
      }
    }
  }, [loadingMe, dataMe, errorMe, dispatch, history, idUser, setLoginSuccess]);

  const onFinishLogin = (values) => {
    fetchLogin({ variables: values });
  };

  return (
    <Row className='xs:w-2/3 md:w-1/3 mx-auto mt-5'>
      <Col>
        <FormLogin
          title='Su sesión ha caducado.'
          alertError
          loading={loadingLogin || loadingMe}
          data={dataLogin}
          error={errorLogin}
          onFinishForm={onFinishLogin}
          description='Por favor vuelva a iniciar sesión para continuar'
        />
      </Col>
    </Row>
  );
};

export default LoginTokenExpired;
