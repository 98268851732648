import React, { useEffect } from "react";
import BrandLoading from "../../../components/CitasUtils/BrandLoading/brand";
import moment from "moment";
import BrowserValidator from "./browserValidator";
import { notification } from "antd";
require("moment/min/locales.min");
moment.locale("es");

const DoctorEstadoCita = (props) => {
  const MessageAppointment = (props) => {
    if (props.isFinish) {
      return (
        <div className='flex flex-col'>
          <p className='break-words font-bold text-sm mb-1 text-primary-hoverDark text-center'>
            La cita ha concluido{" "}
          </p>
          <p className='break-words font-bold text-sm mb-1 text-primary-hoverDark text-center'>
            {props.infoAppointment
              ? moment(props.infoAppointment.appointmentDateAndTime).format("LLLL a")
              : null}
          </p>
          <div className='mt-3 px-5 flex flex-col'>{props.buttonLlamar}</div>
        </div>
      );
    }
    if (props.isBefore) {
      return (
        <div className='flex flex-col'>
          <BrowserValidator />
          <p className='break-words font-bold text-sm mb-1 text-primary-hoverDark text-center'>
            Cita programada para:{" "}
          </p>
          <p className='break-words font-bold text-sm mb-1 text-primary-hoverDark text-center'>
            {props.infoAppointment
              ? moment(props.infoAppointment.appointmentDateAndTime).format("LLLL a")
              : null}
          </p>
          <div className='mt-3 px-5 flex flex-col'>{props.buttonLlamar}</div>
        </div>
      );
    }
    if (props.isInAppointment) {
      return (
        <div className='flex flex-col'>
          <BrowserValidator />
          <div className='mt-3 px-5 flex flex-col'>{props.buttonLlamar}</div>
        </div>
      );
    }
  };

  const openBusyCallNotification = () => {
    notification.info({
      message: "Paciente ocupada",
      description: "Llame después de un tiempo, el paciente está ocupado con otra llamada",
      placement: "topLeft",
    });
  };

  useEffect(() => {
    if (props.isBusyOtherCall) {
      openBusyCallNotification();
      props.setIsBusyOtherCall(false);
    }
  }, [props.isBusyOtherCall]);

  return (
    <div className='flex flex-col h-full w-full'>
      <div className='mx-auto my-auto h-auto w-full justify-center'>
        <div className='bg-transparent mb-3 flex justify-center'>
          <BrandLoading />
        </div>
        <MessageAppointment {...props} />
      </div>
    </div>
  );
};

export default DoctorEstadoCita;
