import * as actionName from "./actions";
import initialState from "./initialState";

import get from "lodash/get";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionName.SET_INITIAL_STATE:
      return {
        ...initialState,
        refetch: state.refetch,
      };

    case actionName.SET_QUERYBYID:
      return {
        ...state,
        data: get(action.payload.data, "companyById"),
        loading: action.payload.loading,
        error: action.payload.error,
      };

    case actionName.SET_INITIAL_INFO:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
