import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin, Empty, Table } from "antd";
import { WordCloud } from "@ant-design/charts";

import SelectorRange, { getDefaultDates } from "components/SelectorRange";

import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import uniqueId from "lodash/uniqueId";

import { getSymptomsWordCloudGql } from "../gqlTags";

const WordCloudSymptoms = ({ id }) => {
  const [datesSelect, setDatesSelect] = useState(getDefaultDates());
  const [dataChart, setDataChart] = useState([]);

  const { data, loading, error } = useQuery(getSymptomsWordCloudGql, {
    variables: {
      from: datesSelect.from,
      to: datesSelect.to,
      company: id,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!loading) {
      if (data && !error) {
        const { getSymptomsWordCloud } = data;
        setDataChart(orderBy(getSymptomsWordCloud, "value", "desc"));
      } else {
        setDataChart([]);
      }
    }
  }, [loading, error, data]);

  const changeValueMonth = (value) => {
    setDatesSelect(value);
  };

  var config = {
    data: dataChart,
    isGroup: false,
    wordField: "category",
    weightField: "value",
    colorField: "value",
    wordStyle: {
      fontFamily: "Roboto",
      fontSize: [14, 28],
    },
    color: "#2497CC",
    interactions: [{ type: "element-active" }],
    state: { active: { style: { lineWidth: 1 } } },
  };

  return (
    <div className='flex flex-col h-full'>
      <div className='flex xs:flex-col md:justify-between md:flex-row gap-4'>
        <p className='mb-0 text-lg font-semibold'>Síntomas más comunes</p>
        <SelectorRange onChange={changeValueMonth} loading={loading} disabled={loading} />
      </div>

      {error && !loading ? (
        <Alert
          message='Server Error'
          description='Error al obtener la información de las citas'
          type='error'
          className='w-full mx-auto my-auto'
          showIcon
        />
      ) : (
        <div className='flex flex-col h-full'>
          <div className='mt-4 w-full'>
            {isEmpty(dataChart) && !loading ? (
              <Empty className='my-5' description='No se encontraron consultas' />
            ) : (
              <Spin spinning={loading}>
                <div className='flex xs:flex-col md:flex-row gap-4'>
                  <div
                    className='xs:w-full md:w-1/3 overflow-scroll'
                    style={{ maxHeight: "420px" }}
                  >
                    <Table
                      columns={[
                        {
                          title: "Síntoma",
                          dataIndex: "word",
                          key: "word",
                        },
                        {
                          title: "Cantidad",
                          dataIndex: "value",
                          key: "value",
                        },
                      ]}
                      pagination={false}
                      dataSource={dataChart}
                      rowKey={() => uniqueId()}
                    />
                  </div>
                  <div className='xs:w-full md:w-2/3'>
                    <WordCloud {...config} />
                  </div>
                </div>
              </Spin>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WordCloudSymptoms;
