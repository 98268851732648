import React, { useContext, useEffect } from "react";
import { Modal, Divider, Form, Button, Input, notification } from "antd";
import { ContextTablaPlanilla } from "./index";
import { SET_INITIAL_STATE, SET_MODAL_PAGAR, SET_RANDOM_VALUE_FOR_QUERY } from "./store/actions";
import { payDoctorGql } from "./gqlTags";
import { useMutation } from "@apollo/react-hooks";

const currencyFormat = (num) => {
  if (!num) {
    return "0.00";
  }
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const createRandom = () => {
  try {
    const timeStampLocal = Math.round(new Date().getTime() / 1000).toString();
    const randomOne = Math.floor(Math.random() * 3000) + 1;
    return randomOne.toString().concat("_" + timeStampLocal);
  } catch (e) {
    return (Math.floor(Math.random() * 20000) + 1).toString();
  }
};

const DescriptionSucces = ({ balance, oldBalance }) => {
  return (
    <div className='flex flex-col mt-5'>
      <div className='w-full flex-col'>
        <p className='font-semibold mb-3 text-danger'>
          Antiguo Balance: <span className='text-black'>${currencyFormat(oldBalance)}</span>
        </p>
        <p className='font-semibold mb-3 text-success'>
          Nuevo Balance: <span className='text-black'>${currencyFormat(balance)}</span>
        </p>
      </div>
    </div>
  );
};

const ModalPagarPlanilla = () => {
  const [form] = Form.useForm();
  const { state, dispatch } = useContext(ContextTablaPlanilla);
  const { infoDoctorSelect, modalOpen } = state;

  const [fetchPayDoctor, { loading, error, data }] = useMutation(payDoctorGql, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!loading) {
      if (error) {
        notification.error({
          message: "Error",
          description: Array.isArray(error.graphQLErrors)
            ? error.graphQLErrors[0].message
            : "Error temporal del servidor, no se puede realizar la transacción",
        });
      } else if (data) {
        const { payDoctor } = data;
        const { balance } = payDoctor;
        notification.success({
          message: infoDoctorSelect ? infoDoctorSelect.fullName : "Dr. N/A",
          duration: 10,
          description: (
            <DescriptionSucces
              balance={balance}
              oldBalance={infoDoctorSelect ? infoDoctorSelect.balance : 0}
            />
          ),
        });
        dispatch({ type: SET_MODAL_PAGAR, payload: false });
        dispatch({ type: SET_INITIAL_STATE });
        // este string random causa un refetch de la query de la tabla planilla
        dispatch({ type: SET_RANDOM_VALUE_FOR_QUERY, payload: createRandom() });
      }
    }
  }, [error, loading, data, dispatch, infoDoctorSelect]);

  const getVisible = () => {
    return modalOpen;
  };

  const onCancelModal = () => {
    dispatch({ type: SET_INITIAL_STATE });
  };

  const onFinish = async (e) => {
    try {
      await fetchPayDoctor({
        variables: {
          id: infoDoctorSelect ? infoDoctorSelect.id : "",
          amount: parseFloat(e.pagar),
        },
      });
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Server Error",
      });
    }
  };

  const afterClose = () => {
    dispatch({ type: SET_INITIAL_STATE });
    form.resetFields();
  };

  return (
    <Modal
      title='Pagar planilla'
      visible={getVisible()}
      destroyOnClose
      onCancel={onCancelModal}
      footer={null}
      afterClose={afterClose}
      closable={!loading}
      maskClosable={!loading}
    >
      {infoDoctorSelect && (
        <Form name='formModalPlanilla' form={form} onFinish={onFinish}>
          <div className='w-full flex-col'>
            <p className='font-bold mb-3'>Dr. {infoDoctorSelect.fullName}</p>
            <p className='mb-5'>
              Balance:{" "}
              <span className='font-bold'>${currencyFormat(infoDoctorSelect.balance)}</span>
            </p>
          </div>
          <Form.Item
            name='pagar'
            validateTrigger='onBlur'
            label='Monto a pagar'
            rules={[
              () => ({
                validator(rule, value) {
                  const balanceValue = parseFloat(infoDoctorSelect.balance);
                  if (!value) {
                    return Promise.reject("El monto a pagar es requerido");
                  } else if (parseFloat(value) > balanceValue) {
                    return Promise.reject("El máximo es $" + currencyFormat(balanceValue));
                  } else if (parseFloat(value) < 1) {
                    return Promise.reject("El mínimo es $1");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              disabled={loading || data}
              type='number'
              className='w-full'
              placeholder='Ingrese el monto ($)'
            />
          </Form.Item>
          <Divider />
          <>
            <div className='flex flex-row justify-between'>
              <Button
                key='back'
                onClick={onCancelModal}
                shape='round'
                className={state.modalInfo ? "self-end ml-auto" : ""}
                size='large'
                disabled={loading}
              >
                Cerrar
              </Button>
              <Button
                key='submit'
                type='primary'
                loading={loading}
                shape='round'
                htmlType='submit'
                size='large'
                className='button-primary'
                disabled={loading || data}
              >
                Pagar
              </Button>
            </div>
          </>
        </Form>
      )}
    </Modal>
  );
};

export default ModalPagarPlanilla;
