import React, { useContext } from "react";
import { Card, Button, Tooltip } from "antd";
import { faTrash, faEye, faImage, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { profileMedicContext } from "../../../index";

const CardDocument = (props) => {
  const { state } = useContext(profileMedicContext);
  const { title, onClickEye, sumitTrash } = props;

  const iconCard =
    props.media.mimetype === "image/png" || props.media.mimetype === "image/jpeg" ? (
      <FontAwesomeIcon className='text-primary mx-2' size='lg' icon={faImage} />
    ) : (
      <FontAwesomeIcon className='text-danger mx-2' size='lg' icon={faFilePdf} />
    );

  return (
    <Card
      bodyStyle={{ display: "none" }}
      className='w-full my-2 shadow-md hover:shadow-2xl'
      title={
        <div className='flex items-center'>
          {iconCard}
          <span>{title}</span>
        </div>
      }
      extra={
        <div className='flex'>
          <Tooltip title='Ver Documento'>
            <Button onClick={() => onClickEye()} type='link' shape='round' size='middle'>
              <FontAwesomeIcon className='text-black hover:text-gray-700' size='lg' icon={faEye} />
            </Button>
          </Tooltip>
          {state.dataMedic.approvalStatus === "DOCUMENTS_REQUIRED" && (
            <Tooltip title='Eliminar Documento'>
              <Button
                onClick={() => sumitTrash(props.id, title)}
                type='link'
                shape='round'
                size='middle'
              >
                <FontAwesomeIcon
                  className='text-danger hover:text-danger-lighter'
                  size='lg'
                  icon={faTrash}
                />
              </Button>
            </Tooltip>
          )}
        </div>
      }
    />
  );
};

export default CardDocument;
