import React, { useEffect, useState } from "react";
import { Modal, Button, Form, notification } from "antd";
import { useLazyQuery } from "@apollo/react-hooks";
import FormModalEdit from "./FormModalEdit";
import { HandleError } from "utils.js/HandleError";

const ModalEdit = (props) => {
  const [loadingEdit, setloadingEdit] = useState(false);
  const [form] = Form.useForm();

  const sumitFormEdit = async (values) => {
    try {
      setloadingEdit(true);
      await props.EditItemSubmit({
        idItem: props.idEditItem,
        ...values,
      });
      notification.success({
        message: "El ítem se ha editado exitosamente!",
      });
      setloadingEdit(false);
      props.setvisbleModal(false);
    } catch (e) {
      console.log(e);
    }
  };

  const customFooter = (
    <div className='flex justify-between'>
      <Button
        // loading={loadingEdit}
        disabled={loadingEdit}
        onClick={() => props.setvisbleModal(false)}
        type='primary'
        shape='round'
        danger
        size='large'
      >
        Cancel
      </Button>
      <Button
        loading={loadingEdit}
        disabled={loadingEdit}
        onClick={() => form.submit()}
        type='primary'
        shape='round'
        size='large'
      >
        Aceptar
      </Button>
    </div>
  );

  const [sebDrugById, { data, loading, error }] = useLazyQuery(props.gqlFindItem, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (props.idEditItem) {
      try {
        sebDrugById({ variables: { id: props.idEditItem } });
      } catch (e) {
        console.log(e);
      }
    }
  }, [props.idEditItem]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(props.error),
      });
    }
  }, [error, props]);

  useEffect(() => {
    if (data) {
      form.setFields([
        {
          name: "nameItem",
          value: data[`${props.pathId}`].name,
          errors: null,
        },
        {
          name: "descriptionItem",
          value: data[`${props.pathId}`].description ? data[`${props.pathId}`].description : "",
          errors: null,
        },
      ]);
    }
  }, [data, form, props]);

  return (
    <Modal
      afterClose={() => setloadingEdit(false)}
      destroyOnClose
      title={null}
      closable={false}
      visible={props.visbleModal}
      onOk={() => console.log("onOK Modal")}
      onCancel={() => props.setvisbleModal(false)}
      footer={customFooter}
    >
      <h4 className='text-2xl'>
        <strong>Editar ítem</strong>
      </h4>
      <FormModalEdit loading={loading} form={form} onFinishForm={sumitFormEdit} />
    </Modal>
  );
};

ModalEdit.defaultProps = {
  visbleModal: false,
  gqlFindItem: null,
  idEditItem: null,
  setvisbleModal: () => console.log("onCancel"),
};

export default ModalEdit;
