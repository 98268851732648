import React, { useState, useEffect, useCallback } from "react";
import BrandLoading from "../../../components/CitasUtils/BrandLoading";
import { Layout } from "antd";

import ContainerSala from "./containerSala";

import { getIdAppointment } from "./utils";

const { Content } = Layout;

const PacienteMD = (props) => {
  const [infoSession, setInfoSession] = useState(null);

  const setValuesInfoSession = useCallback(() => {
    if (props.match) {
      setInfoSession(null);
      setInfoSession(getIdAppointment(props));
    }
  }, [props]);

  useEffect(() => {
    setValuesInfoSession();
  }, [setValuesInfoSession]);

  return (
    <Layout className='w-full'>
      <Content className='w-full h-auto'>
        {!infoSession ? <BrandLoading /> : <ContainerSala info={infoSession} />}
      </Content>
    </Layout>
  );
};

export default PacienteMD;
