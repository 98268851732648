import React from "react";
import { Row, Col, Divider, Select } from "antd";
import moment from "moment";

export default function InputPlanChange({ value: initialValue, plans, form, companyPlan }) {
  const { Option } = Select;
  const plan = initialValue;

  const changeHandler = (value) => {
    form &&
      form.setFieldsValue({
        plan: value,
      });
  };

  return (
    <Row gutter={[16, 16]} className='md:pr-1'>
      <Col xs={24}>
        <Divider className='m-0 text-primary font-semibold ' orientation='left' plain>
          Plan
        </Divider>
      </Col>
      <Col xs={24} md={12}>
        <Select
          name='plan'
          disabled={!plans || companyPlan}
          defaultValue={plan?.status === "ACTIVE" ? plan?.plan?.id : "+ Agregar Plan"}
          mode='single'
          bordered
          className='rounded'
          style={{ width: "100%" }}
          onChange={changeHandler}
          placeholder='+ Agregar Plan'
        >
          {plans?.plans.docs.map((plan, i) => (
            <Option value={plan.id} key={i}>
              {plan.name}
              {plan.periods.length > 0 && " - $" + plan.periods[0].price}
            </Option>
          ))}
        </Select>
      </Col>
      <Col xs={24} md={12}>
        <div className='flex items-center'>
          {plan?.status === "ACTIVE" && (
            <p className='mb-0 mt-2 capitalize'>
              Expiracion: {moment(plan.expireAt).format("MMMM D")}
            </p>
          )}
        </div>
      </Col>
    </Row>
  );
}
