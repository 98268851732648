import React, { Fragment, cloneElement, useState } from "react";
import { Helmet } from "react-helmet";
// components
import Dialog from "./Dialog";

export default function Canvas({ children, info }) {
  const [visible, setVisible] = useState(false);

  const props = {
    element: {
      onClick: () => setVisible(true),
      style: { cursor: "pointer" },
    },
    Dialog: {
      info,
      visible,
      onCancel: () => setVisible(false),
    },
  };

  return (
    <Fragment>
      {cloneElement(children, props.element)}
      <Dialog {...props.Dialog} />
      {visible && (
        <Helmet>
          <body className='openDialog' />
        </Helmet>
      )}
    </Fragment>
  );
}
