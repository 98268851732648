import React from "react";
import Medicamentos from "views/Admin/Medicamentos";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCreditCard,
  faHeadSideCough,
  faHome,
  faLungsVirus,
  faPills,
  faTable,
  faUser,
  faUserMd,
  faUserNurse,
  faUserTie,
  faUserTag,
  faGift,
  faMoneyBill,
  faDownload,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons";
import Home from "../views/Admin/Home";
import Pacientes from "../views/Admin/Patients";
import Doctores from "../views/Admin/Doctores";
import DoctoresMD from "../views/Admin/DoctorMD";
import Planilla from "../views/Admin/Planilla";
import Sintomas from "../views/Admin/sintomas";
import CondicionesMedicas from "../views/Admin/CondicionesMedicas";
import Plans from "../views/Admin/Plans";
import Company from "../views/Admin/Company";
import CompanyCodes from "../views/Admin/CompanyCodes";
import CompanyCharts from "../views/Admin/CompanyCharts";
import Affiliates from "../views/Admin/Affiliates";
import Coupons from "../views/Admin/Coupons";
import Transactions from "../views/Admin/Transactions";
import Reports from "../views/Admin/Reports";
import Users from "../views/Admin/User";
import Marketing from "../views/Admin/Marketing";

export default [
  {
    key: "panel",
    icon: <Fa icon={faHome} />,
    component: <Home />,
    children: <span className='ml-3'>Panel de admin</span>,
    path: "/panel",
    name: "Panel de admin",
    layout: "/admin",
    useInSidebar: true,
  },
  {
    key: "paciente",
    icon: <Fa icon={faUser} />,
    component: <Pacientes />,
    children: <span className='ml-3'>Pacientes</span>,
    path: "/paciente",
    name: "Pacientes",
    layout: "/admin",
    useInSidebar: true,
  },
  {
    key: "Afiliados",
    icon: <Fa icon={faUserTag} />,
    component: <Affiliates />,
    children: <span className='ml-3'>Afiliados</span>,
    path: "/afiliados",
    name: "Afiliados",
    layout: "/admin",
    useInSidebar: true,
  },
  {
    key: "transacciones",
    icon: <Fa icon={faMoneyBill} />,
    component: <Transactions />,
    children: <span className='ml-3'>Transacciones</span>,
    path: "/transacciones",
    name: "Transacciones",
    layout: "/admin",
    useInSidebar: true,
  },
  {
    key: "medico",
    icon: <Fa icon={faUserMd} />,
    component: <Doctores />,
    children: <span className='ml-3'>Doctores</span>,
    path: "/medico",
    name: "Doctores",
    layout: "/admin",
    useInSidebar: true,
  },
  {
    key: "medicomd",
    icon: <Fa icon={faUserNurse} />,
    component: <DoctoresMD />,
    children: <span className='ml-3'>Doctores MD</span>,
    path: "/medicomd",
    name: "DoctoresMD",
    layout: "/admin",
    useInSidebar: true,
  },
  {
    key: "planilla",
    icon: <Fa icon={faCreditCard} />,
    component: <Planilla />,
    children: <span className='ml-3'>Planilla</span>,
    path: "/planilla",
    name: "Planilla",
    layout: "/admin",
    useInSidebar: true,
  },
  {
    key: "medicamentos",
    icon: <Fa icon={faPills} />,
    component: <Medicamentos />,
    children: <span className='ml-3'>Medicamentos</span>,
    path: "/medicamentos",
    name: "Medicamentos",
    layout: "/admin",
    useInSidebar: true,
  },
  {
    key: "sintomas",
    icon: <Fa icon={faHeadSideCough} />,
    component: <Sintomas />,
    children: <span className='ml-3'>Síntomas</span>,
    path: "/sintomas",
    name: "Sintomas",
    layout: "/admin",
    useInSidebar: true,
  },
  {
    key: "condiciones",
    icon: <Fa icon={faLungsVirus} />,
    component: <CondicionesMedicas />,
    children: <span className='ml-3'>Condiciones</span>,
    path: "/condiciones",
    name: "Condiciones",
    layout: "/admin",
    useInSidebar: true,
  },
  {
    key: "planes",
    icon: <Fa icon={faTable} />,
    component: <Plans />,
    children: <span className='ml-3'>Planes</span>,
    path: "/planes",
    name: "Planes",
    layout: "/admin",
    useInSidebar: true,
  },
  {
    key: "empresa",
    icon: <Fa icon={faBuilding} />,
    component: <Company />,
    children: <span className='ml-3'>Empresas</span>,
    path: "/empresa",
    name: "Empresas",
    layout: "/admin",
    useInSidebar: true,
  },
  {
    key: "empresa/:id",
    icon: "",
    component: <CompanyCodes />,
    children: null,
    path: "/empresa/:id",
    name: "Empresas",
    layout: "/admin",
    useInSidebar: false,
  },
  {
    key: "empresa/grafico/:id",
    icon: "",
    component: <CompanyCharts />,
    children: null,
    path: "/empresa/grafico/:id",
    name: "Empresas",
    layout: "/admin",
    useInSidebar: false,
  },
  {
    key: "cupones",
    icon: <Fa icon={faGift} />,
    component: <Coupons />,
    children: <span className='ml-3'>Cupones</span>,
    path: "/cupones",
    name: "Cupones",
    layout: "/admin",
    useInSidebar: true,
  },
  {
    key: "reportes",
    icon: <Fa icon={faDownload} />,
    component: <Reports />,
    children: <span className='ml-3'>Reportes</span>,
    path: "/reportes",
    name: "Reportes",
    layout: "/admin",
    useInSidebar: true,
  },
  {
    key: "mercadeo",
    icon: <Fa icon={faShareSquare} />,
    component: <Marketing />,
    children: <span className='ml-3'>Mercadeo</span>,
    path: "/mercadeo",
    name: "Mercadeo",
    layout: "/admin",
    useInSidebar: true,
  },
  {
    key: "usuarios",
    icon: <Fa icon={faUserTie} />,
    component: <Users />,
    children: <span className='ml-3'>Usuarios</span>,
    path: "/usuarios",
    name: "Usuarios",
    layout: "/admin",
    useInSidebar: true,
  },
];
