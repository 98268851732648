import React from "react";

import Form from "antd/lib/form";
import Space from "antd/lib/space";
import Input from "antd/lib/input";
import { Row, Col } from "antd";

import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import InputNumber from "components/InputNumber";
import InputPlanChange from "components/InputPlanChange";
import InputCompanyChange from "components/InputCompanyChange";

import size from "lodash/size";

export default function FormInfo(_props) {
  const { initialValues, form, planslist, companylist } = _props;
  const props = {
    Space: {
      size: 0,
      align: "center",
      style: {
        display: "grid",
      },
    },
    InitialValues: {
      email: initialValues.email,
      phoneNumber: initialValues.phoneNumber,
      plan: initialValues.plan,
      belongsToCompany: initialValues.company?.fullName,
    },
    email: {
      label: "Correo Electronico:",
      name: "email",
      rules: [
        {
          type: "email",
          message: "Por favor ingrese un correo electronico valido!",
        },
      ],
      children: <Input placeholder='Correo electronico' prefix={<Fa icon={faEnvelope} />} />,
    },
    phone: {
      label: "Numero Telefonico:",
      name: "phoneNumber",
      children: (
        <InputNumber
          placeholder='Número de teléfono'
          form={form}
          name='phoneNumber'
          length={8}
          icon={<Fa icon={faPhone} />}
        />
      ),
    },
    plan: {
      name: "plan",
      children: (
        <InputPlanChange
          plans={planslist}
          companyPlan={initialValues.company && true}
          form={form}
        />
      ),
    },
    company: {
      name: "belongsToCompany",
      children: <InputCompanyChange companies={companylist} form={form} />,
    },
  };

  return (
    <Form {..._props} initialValues={{ ...props.InitialValues }}>
      {!!size(initialValues) && (
        <>
          <Row gutter={[16, 16]} className='md:pr-1 mb-5'>
            <Col xs={24} md={12} style={{ maxHeight: "60px" }}>
              <Form.Item {...props.email} />
            </Col>
            <Col xs={24} md={12} style={{ maxHeight: "60px" }}>
              <Form.Item {...props.phone} />
            </Col>
          </Row>
          <Space {...props.Space}>
            <Form.Item {...props.plan} />
          </Space>
          <Space {...props.Space}>
            <Form.Item {...props.company} />
          </Space>
        </>
      )}
    </Form>
  );
}
