import React, { useReducer, createContext, useContext } from "react";

import initialState from "./store/initialState";
import reducer from "./store/reducer";

import Container from "./container";

import { GlobalContext } from "views/App";
export const EncuentraContext = createContext();

const Encuentra = () => {
  const [stateLocal, dispatch] = useReducer(reducer, initialState);
  const { state } = useContext(GlobalContext);

  return (
    <EncuentraContext.Provider value={{ user: state.user, state: stateLocal, dispatch: dispatch }}>
      <Container />
    </EncuentraContext.Provider>
  );
};

export default Encuentra;
