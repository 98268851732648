import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
// antd
import Form from "antd/lib/form";
import Modal from "antd/lib/modal";
import Space from "antd/lib/space";
import notify from "antd/lib/notification";
// components
import FormValidate from "../Profile/FormValidate";
import FormInfo from "../Profile/FormInfo";
import UploadPicture from "../AddDialog/UploadPicture";
import FormCondition from "../Profile/FormCondition";
import Age from "./inputs/Age";
// helpers
import get from "lodash/get";
// gql
import { createBeneficiary, SEND_BENEFICIARY_EMAIL } from "views/Patient/gql";
// props Statics
import propsStatic from "./propsStatic";

function Dialog({ gql, onSuccess, onError, ...attr }) {
  const [form] = Form.useForm();
  const [applyMute, { loading, ...states }] = useMutation(gql, {
    refetchQueries: ["getMeBeneficiaries"],
    awaitRefetchQueries: true,
  });

  const [sendEmail, { loading: emailLoading, ...email_states }] = useMutation(
    SEND_BENEFICIARY_EMAIL,
    {
      refetchQueries: ["getMeBeneficiaries"],
      awaitRefetchQueries: true,
    }
  );

  const [underAge, setUnderAge] = useState(false);
  const updateAge = () => {
    setUnderAge(!underAge);
    form.resetFields();
  };

  useEffect(() => {
    if (!attr.visible) {
      form.resetFields();
      setUnderAge(false);
    }
  }, [underAge, attr, form]);

  const afterClose = () => {
    if (attr.afterClose) attr.afterClose({ ...states, loading });
  };

  const onFinish = (variables) => {
    if (!underAge) {
      let email = variables.email;
      sendEmail({ variables: { email: email } })
        .then(() => {
          if (onSuccess) onSuccess();
          notify.success({ message: "Notificacion enviada" });
          attr.onCancel();
        })
        .catch((error) => {
          if (onError) onError();

          if (error.graphQLErrors) {
            const description = get(error.graphQLErrors, [0, "message"], error.graphQLErrors);
            notify.error({
              message: "Error",
              description,
            });
          }
        });
    } else {
      applyMute({ variables })
        .then(() => {
          if (onSuccess) onSuccess();
          notify.success({ message: "Beneficiario agregado" });
          attr.onCancel();
        })
        .catch((error) => {
          if (onError) onError();

          if (error.graphQLErrors) {
            const description = get(error.graphQLErrors, [0, "message"], error.graphQLErrors);
            notify.error({
              message: "Error",
              description,
            });
          }
        });
    }
  };

  const props = {
    Modal: {
      ...propsStatic.Modal,
      onOk: form.submit,
      cancelButtonProps: {
        ...propsStatic.Modal.cancelButtonProps,
        disabled: loading,
      },
      confirmLoading: loading,
      ...attr,
      afterClose,
    },
    FormValidate: {
      form,
      onFinish,
      name: "person",
      initialValues: {},
      emailLoading,
      email_states,
    },
    FormInfo: {
      form,
      onFinish,
      name: "person",
      initialValues: {},
    },
    Age: {
      underAge,
      updateAge,
    },
  };

  return (
    <Modal {...props.Modal}>
      <Space {...propsStatic.Space}>
        <Age {...props.Age} />
      </Space>
      {!underAge ? (
        <Space {...propsStatic.Space}>
          <FormValidate {...props.FormValidate} />
        </Space>
      ) : (
        <>
          <Space {...propsStatic.Space}>
            <FormInfo {...props.FormInfo} />
            <Form {...props.FormInfo}>
              <Form.Item name='picture'>
                <UploadPicture />
              </Form.Item>
            </Form>
          </Space>
          <FormCondition {...props.FormInfo} />
        </>
      )}
    </Modal>
  );
}

Dialog.defaultProps = {
  gql: createBeneficiary,
  onError: null,
  onSuccess: null,
};

export default Dialog;
