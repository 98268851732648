import React, { useState, useEffect, useContext } from "react";
import cls from "classnames/bind";
// antd
import Layout from "antd/lib/layout";
// components
import Menu from "./Menu";
import Brand from "layouts/profile/Brand";
import TopBar from "./TopBar";

// helpers
import lowerCase from "lodash/lowerCase";
import split from "lodash/split";
import get from "lodash/get";
import find from "lodash/find";
// menu
import adminRoutes from "routes/adminRoutes";
import companyRoutes from "routes/companyRoutes";
// styles
import style from "./style.module.scss";

// Login Verify
import VerifyLoginWrapper from "../../components/VerifyLoginWrapper";

// Router
import { Redirect, Route, Switch } from "react-router-dom";
import View404Router from "../../components/View404Router";

import { GlobalContext } from "views/App";

const cx = cls.bind(style);

export default function Dashboard(props) {
  const { role: roleForDashboard } = props;
  const { state } = useContext(GlobalContext);
  const [routesForUse] = useState(roleForDashboard === "root" ? adminRoutes : companyRoutes);
  const [view, setView] = useState(
    roleForDashboard === "root" ? adminRoutes[0].key : companyRoutes[0].key
  );
  const [collapsed, setCollapsed] = useState(false);
  const { user } = state;
  const userGroup = get(user, ["role", "usedFor"]);
  const rolesForLoginWrapper =
    roleForDashboard === "root" ? ["adminRole", "operationsRole"] : roleForDashboard;

  useEffect(() => {
    const pathname = get(props, "location.pathname");
    const viewUrl = lowerCase(get(split(pathname, "/", 3), "[2]"));
    const existView = find(routesForUse, function (o) {
      return lowerCase(o.key) === viewUrl;
    });

    if (existView) {
      setView(existView.key || null);
    } else {
      const k = routesForUse[0].key;
      setView(k);
    }
  }, [props, routesForUse]);

  const _props = {
    Layout: {
      hasSider: true,
      className: cx("dashboard"),
    },
    Sider: {
      collapsed,
      className: style.sidebar,
      onCollapse: setCollapsed,
      collapsible: true,
    },
    Menu: {
      selected: view,
      onSelect: setView,
    },
    innerLayout: {
      className: "overflow-hidden",
    },
    Brand: {
      className: collapsed ? "brand sm" : "brand",
    },
  };

  return (
    <VerifyLoginWrapper forceSignUp={false} role={rolesForLoginWrapper}>
      <Layout {..._props.Layout}>
        <Layout.Sider {..._props.Sider}>
          <Brand {..._props.Brand} />
          <Menu {..._props.Menu} />
        </Layout.Sider>
        <Layout {..._props.innerLayout}>
          <Layout.Header>
            <TopBar />
          </Layout.Header>
          <Layout.Content className={style.mainContent}>
            <Switch>
              {routesForUse.map((value, key) => {
                return (
                  <Route
                    path={value.layout + value.path}
                    component={() => value.component}
                    key={key}
                    exact
                  />
                );
              })}
              {userGroup === "adminRole" || userGroup === "operationsRole" ? (
                <Route path='/admin'>
                  <Redirect to='/admin/panel' />
                </Route>
              ) : (
                <Route path='/company'>
                  <Redirect to='/company/panel' />
                </Route>
              )}
              <Route component={() => <View404Router />} />
            </Switch>
          </Layout.Content>
        </Layout>
      </Layout>
    </VerifyLoginWrapper>
  );
}
