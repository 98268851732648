export default {
  required: {
    required: true,
    message: "Campo requerido",
  },
  email: {
    type: "email",
    message: "E-mail no valido!",
  },
  float: {
    type: "number",
    message: "El valor debe ser un número",
  },
  number: () => ({
    validator(rule, value) {
      if (parseFloat(value) < 0 || value === "") {
        return Promise.reject("El número de empleados es requerido");
      }
      return Promise.resolve();
    },
  }),
  multiplesEmails: () => ({
    validator(rule, value) {
      if (value && value !== "") {
        const regexEmail =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const arrayEmails = value.split(",");
        const errorEmails = [];
        arrayEmails.forEach((_value) => {
          const regValid = regexEmail.test(_value.trim());
          if (!regValid) {
            errorEmails.push(_value);
          }
        });
        if (errorEmails.length > 0) {
          let stringError = " Correo electrónico incorrecto: ";
          errorEmails.forEach((_value, _index) => {
            stringError += " \r " + _value;
            if (_index !== errorEmails.length - 1) {
              stringError += ",";
            }
          });
          return Promise.reject(stringError);
        }
      }
      return Promise.resolve();
    },
  }),
};
