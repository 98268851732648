import React, { useEffect, useContext } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { GlobalContext } from "views/App";

import HomeLayout from "./site";

import ProfileLayout from "../layouts/profile";
import VideoCallLayout from "../layouts/videoCall";
import CallChatLayout from "../chatCall/ChatCallLayout";
import Admin from "layouts/dashboard";
import { get } from "lodash";

const RouteConfig = ({ history, location }) => {
  const { state } = useContext(GlobalContext);
  const { user } = state;
  const role = get(user, ["role", "name"]);
  const userGroup = get(user, ["role", "usedFor"]);
  const uri =
    role === "root" || userGroup === "operationsRole"
      ? "/admin"
      : role === "doctor" || role === "doctorMD"
      ? "/medic"
      : "/patient";
  const url = location.pathname;

  useEffect(() => {
    history.listen(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    });
  }, [history]);

  useEffect(() => {
    if (user && url.includes("auth", "login", "signup")) {
      history.push(uri);
    } else if (url === "/" || url === "/md") {
      history.push("/md/home");
    }
  }, [user, uri, url, location, history]);

  return (
    <Switch>
      <Route path='/admin' render={(props) => <Admin role='root' {...props} />} />
      <Route path='/company' render={(props) => <Admin role='company' {...props} />} />
      <CallChatLayout>
        <Route path='/md' render={(props) => <HomeLayout {...props} />} />
        <Route path='/patient' render={(props) => <ProfileLayout {...props} />} />
        <Route path='/medic' render={(props) => <ProfileLayout {...props} />} />
        <Route path='/cita' render={(props) => <VideoCallLayout {...props} />} />
      </CallChatLayout>
    </Switch>
  );
};

export default withRouter(RouteConfig);
