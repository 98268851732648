import React, { useState } from "react";
// components
import Btn from "./Btn";
import Dialog from "./Dialog";
// contexts
import Context from "./Context";

export default function Detail(id, row) {
  const [visible, setVisible] = useState(false);

  const valueProvider = { id, row, visible, setVisible };

  return (
    <Context.Provider value={valueProvider}>
      <Btn />
      <Dialog />
    </Context.Provider>
  );
}
