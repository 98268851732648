import React from "react";
// antd
import Form from "antd/lib/form";
// inputs
import input from "./inputs";

export default function FormPlan(props) {
  return (
    <Form {...props}>
      <Form.Item {...input.name} />
      <Form.Item {...input.description} />
    </Form>
  );
}
