import React from "react";
import Lottie from "react-lottie";
import animation from "assets/lottie/easyMD_loading.json";

const props = {
  width: "25vmax",
  options: {
    loop: true,
    autoplay: true,
    animationData: animation,
  },
};

function GoogleCalendarLoading() {
  return (
    <div className='w-screen h-screen flex justify-center items-center'>
      <div
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie {...props} />
        <h1 style={{ textAlign: "center" }}>
          Integrando el calendario de Google, por favor espere...
        </h1>
      </div>
    </div>
  );
}

export default GoogleCalendarLoading;
