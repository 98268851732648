import React, { useContext, useEffect } from "react";
import { Button, Spin, Modal } from "antd";
import { CheckOutContext } from "../index";

import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";

import {
  faCalendarCheck,
  faUserMd,
  faCreditCard,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from "moment";
import { GlobalContext } from "views/App";
require("moment/min/locales.min");
moment.locale("es");

const ModalConfirmAppointment = (props) => {
  const meGql = gql`
    query me {
      me {
        id
        paymentMethods {
          id
          card
          cardType
        }
      }
    }
  `;

  const { state } = useContext(CheckOutContext);
  const { state: globalState } = useContext(GlobalContext);
  const { user } = globalState;
  console.log("🚀 ~ file: modalConfirmAppointment.js:38 ~ ModalConfirmAppointment ~ user", user);

  const [fetchMe, { loading, data }] = useLazyQuery(meGql, { fetchPolicy: "no-cache" });
  const { appointmentById, metodoDePago } = state;
  const doctor = appointmentById ? appointmentById.doctor : null;

  if (!appointmentById) {
    return null;
  }

  return (
    <Modal
      title='Confirmación de la cita'
      visible={props.visible}
      destroyOnClose
      onOk={props.onOk}
      onCancel={props.onClose}
      footer={[
        <Button key='back' loading={loading} onClick={props.onClose}>
          Cerrar
        </Button>,
        <Button key='submit' type='primary' loading={loading} onClick={props.onOk}>
          Confirmar Cita
        </Button>,
      ]}
    >
      <p className='font-semibold text-primary'>
        <FontAwesomeIcon icon={faCalendarCheck} className='mr-2 self-center' />
        Fecha:
        <span className='text-black font-normal ml-2'>
          {moment(appointmentById.appointmentDateAndTime).format("LLLL a")}
        </span>
      </p>
      <p className='font-semibold text-primary'>
        <FontAwesomeIcon icon={faUserMd} className='mr-2 self-center' />
        Doctor:
        <span className='text-black font-normal ml-2'>{doctor.fullName}</span>
      </p>
      <p className='font-semibold text-primary'>
        <FontAwesomeIcon icon={faMoneyBill} className='mr-2 self-center' />
        Precio de consulta:
        <span className='text-black font-normal ml-2'>
          US${" "}
          {doctor.appointmentPriceEasyMD && user?.plans?.length >= 1
            ? doctor.appointmentPriceEasyMD
            : doctor.appointmentPrice}
        </span>
      </p>
      <p className='font-semibold text-primary'>
        <FontAwesomeIcon icon={faCreditCard} className='mr-2 self-center' />
        Método de pago:
        <span className='text-black font-normal ml-2'>
          {metodoDePago.tabActiva.toString() === "1" ? (
            <MiMetodoDePago
              fetchMe={fetchMe}
              loading={loading}
              data={data}
              id={metodoDePago.infoPropio}
            />
          ) : (
            metodoDePago.cardNumber
          )}
        </span>
      </p>
    </Modal>
  );
};

const MiMetodoDePago = (props) => {
  useEffect(() => {
    props.fetchMe();
  }, []);

  const getCardNumber = () => {
    const { me } = props.data;
    const { paymentMethods } = me;
    let myCardNumber = "Ninguno";
    paymentMethods.forEach((value, index) => {
      if (value.id === props.id) {
        myCardNumber = value.card;
      }
    });
    return myCardNumber;
  };

  if (props.loading || !props.data) {
    return (
      <Spin spinning>
        <p className='h-12' />
      </Spin>
    );
  }

  return getCardNumber();
};

export default React.memo(ModalConfirmAppointment);
