import React, { useContext } from "react";
import { Button } from "antd";
import { ContextTablaDoctores } from "../index";

const FooterModal = ({ onCancel, loading }) => {
  const { state } = useContext(ContextTablaDoctores);

  const renderButtonOk = () => {
    if (state.modalInfo) {
      return null;
    }
    if (state.modalAceptar) {
      return (
        <Button
          key='submit'
          type='primary'
          loading={loading}
          shape='round'
          htmlType='submit'
          size='large'
          disabled={loading}
          className='button-primary'
        >
          Aprobar
        </Button>
      );
    }
    if (state.modalRechazar) {
      return (
        <Button
          key='submit'
          type='primary'
          loading={loading}
          htmlType='submit'
          shape='round'
          size='large'
          className='button-primary'
        >
          Enviar correo
        </Button>
      );
    }
  };
  return (
    <>
      <div className='flex flex-row justify-between'>
        <Button
          key='back'
          onClick={onCancel}
          shape='round'
          loading={loading}
          disabled={loading}
          className={state.modalInfo ? "self-end ml-auto" : ""}
          size='large'
        >
          Cerrar
        </Button>
        {renderButtonOk()}
      </div>
    </>
  );
};

export default FooterModal;
