import React from "react";
import Lottie from "react-lottie";
import appoinmentSuccess from "../../../../../assets/lottie/for-profile.json";

const EmptyCompany = () => {
  const defaultOptionsAnimation = {
    loop: true,
    autoplay: true,
    animationData: appoinmentSuccess,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className='w-full'>
      <Lottie
        style={{ filter: "grayscale(1) brightness(1.4) opacity(0.5)" }}
        isClickToPauseDisabled
        width='35%'
        height='100%'
        options={defaultOptionsAnimation}
      />
      <p className='text-center text-lg'>Sin afiliaciones activas</p>
    </div>
  );
};

export default EmptyCompany;
