import * as actionName from "./actionStore";

import initialState from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionName.GET_VALUES:
      return {
        ...state,
      };

    case actionName.SET_SELECTEDUSER:
      return {
        ...state,
        selectedUser: action.payload,
      };

    case actionName.SET_VISIBLEMODAL:
      return {
        ...state,
        activeModal: action.payload,
      };

    case actionName.SET_TEXT:
      return {
        ...state,
        filters: {
          ...state.filters,
          text: action.payload,
        },
      };

    // case actionName.SET_PLANS: return {
    //   ...state,
    //   filters: {
    //     ...state.filters,
    //     planId: action.payload
    //   }
    // }

    case actionName.SET_PAGINATION:
      return {
        ...state,
        filters: {
          ...state.filters,
          page: action.payload,
        },
      };

    case actionName.SET_DATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          from: action.payload.from,
          to: action.payload.to,
        },
      };

    default:
      return { ...state };
  }
};

export default reducer;
