import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStethoscope,
  faChevronDown,
  faCalendar,
  faGlobeAmericas,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import "./StyleDoctorSearchr.scss";

import { Tag, Card, Select, DatePicker, Button, notification } from "antd";

const specialties = gql`
  query specialties($perPage: Float, $page: Float, $filters: [specialtieFilter!]) {
    specialties(perPage: $perPage, page: $page, filters: $filters) {
      total
      page
      docs {
        id
        name
      }
    }
  }
`;

const specialtieById = gql`
  query specialtieById($id: String!) {
    specialtieById(id: $id) {
      id
      name
      description
    }
  }
`;

const languages = gql`
  query languages($perPage: Float, $page: Float, $filters: [languageFilter!]) {
    languages(perPage: $perPage, page: $page, filters: $filters) {
      total
      page
      docs {
        id
        name
      }
    }
  }
`;

const DoctorSearchBar = (props) => {
  const [variables, setVariables] = useState({
    perPage: 25,
    page: 1,
    filters: [],
  });

  const [variableLanguaje, setVariableLanguaje] = useState({
    perPage: 25,
    page: 1,
    filters: [],
  });

  const [afterValueSpecialtie, setAfterValueSpecialtie] = useState(null);

  const [arraySpecialtie, setArraySpecialtie] = useState([]);

  const [valueSelectLanguaje, setValueSelectLanguaje] = useState(
    props.stateStore.defaultValuesSelect.byLanguaje
  );
  const { Option } = Select;

  const {
    loading: loadingspecialties,
    error,
    data,
  } = useQuery(specialties, {
    variables: { ...variables },
    fetchPolicy: "no-cache",
  });

  const [searchIdSpecialties, req] = useLazyQuery(specialtieById, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (props.stateStore.defaultValuesSelect.byLanguaje) {
      setValueSelectLanguaje(props.stateStore.defaultValuesSelect.byLanguaje);
    }
  }, [props.stateStore.defaultValuesSelect]);

  useEffect(() => {
    if (data) {
      const newArray = data.specialties.docs.map((value) => ({
        name: value.name,
        id: value.id,
      }));
      const arrayIds = data.specialties.docs.map((value) => value.id);

      if (props.stateStore.typeFilters.bySpeciality) {
        if (!arrayIds.includes(props.stateStore.typeFilters.bySpeciality.byId.value)) {
          if (!req.called) {
            searchIdSpecialties({
              variables: { id: props.stateStore.typeFilters.bySpeciality.byId.value },
            });
          }
          if (req.data) {
            newArray.push({
              name: req.data.specialtieById.name,
              id: req.data.specialtieById.id,
            });
          }
        }
      }

      setArraySpecialtie(newArray);
    }
  }, [data, req, props]);

  const tagRenderSelect = (props) => {
    const { label, closable, onClose } = props;
    return (
      <Tag className='rounded' color='#29AAE1' closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  const reqlanguages = useQuery(languages, {
    variables: { ...variableLanguaje },
    fetchPolicy: "no-cache",
  });

  const searchLanguaje = (valueSelect) => {
    if (valueSelect) {
      setVariableLanguaje({
        ...variableLanguaje,
        filters: [
          {
            byText: {
              field: "NAME",
              value: valueSelect,
            },
          },
        ],
      });
    } else {
      setVariableLanguaje({
        ...variableLanguaje,
        filters: [],
      });
    }
  };

  const searchSpecialties = (valueSelect) => {
    if (valueSelect) {
      setVariables({
        ...variables,
        filters: [
          {
            byText: {
              field: "NAME",
              value: valueSelect,
            },
          },
        ],
      });
    } else {
      setVariables({
        ...variables,
        filters: [],
      });
    }
  };

  const getLanguages = () => {
    if (reqlanguages.data) {
      return reqlanguages.data.languages.docs.map((value) => (
        <Option key={`${value.id}`} value={value.id}>
          {value.name}
        </Option>
      ));
    } else {
      return null;
    }
  };

  const getSpecialties = () => {
    if (data) {
      return (
        <>
          {arraySpecialtie.map((value, key) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
          {afterValueSpecialtie !== null &&
          data &&
          afterValueSpecialtie.key !== afterValueSpecialtie.opcion.value ? (
            <Option key={afterValueSpecialtie.opcion.key} value={afterValueSpecialtie.opcion.value}>
              {afterValueSpecialtie.opcion.children}
            </Option>
          ) : null}
        </>
      );
    }

    if (error) {
      notification.error({
        message: "Error",
        description: error.graphQLErrors[0].message,
      });
      return null;
    }
  };

  return (
    <Card style={{ boxShadow: "none" }} className='w-full z-10' bordered={false}>
      <div
        // className=' grid grid-flow-col grid-rows-2 xs:grid-rows-4 sm:grid-rows-2 md:grid-rows-2 lg:grid-rows-2 xl:grid-rows-1'
        className='grid grid-cols-12 '
      >
        <div
          className='
        overflow-auto
        p-1
        m-1
        flex
        items-center

        sm:border-r-2
        sm:border-primary
        sm:border-b-0
        sm:pr-8
        sm:pb-1

        xs:col-span-12
        sm:col-span-6
        xl:col-span-3

        xs:mb-4
        xs:border-primary
        xs:border-b-2
        xs:border-l-0
        xs:pb-8
        xs:pr-1
        '
        >
          <div className='inline-block pl-2 pr-2 flex-1'>
            <FontAwesomeIcon
              style={{ width: "51px", height: "51px" }}
              size='lg'
              className='serviceCard--icon'
              icon={faStethoscope}
            />
          </div>
          <div className='inline-block w-full'>
            <label className='ml-2' style={{ color: "#C4C4C4" }} htmlFor='SelectsPeciality'>
              Elige la especialidad
            </label>
            <Select
              loading={loadingspecialties || req.loading}
              disabled={(loadingspecialties || req.loading) && !req.data && !data}
              id='SelectsPeciality'
              className='w-full selectDoctorSearch'
              showSearch
              allowClear
              value={
                req.loading || loadingspecialties
                  ? []
                  : props.stateStore.typeFilters.bySpeciality
                  ? props.stateStore.typeFilters.bySpeciality.byId.value
                  : []
              }
              placeholder=''
              optionFilterProp='children'
              // notFoundContent={(variables.filters.length !== 0) ? true : null}
              onChange={(e, opcion) => {
                if (e !== undefined) {
                  setAfterValueSpecialtie({
                    opcion: opcion,
                    key: e,
                  });
                  props.dispatchStore({
                    type: props.actionName.SET_SPECIALTIES,
                    payload: e,
                  });
                } else {
                  setAfterValueSpecialtie(null);
                  props.dispatchStore({
                    type: props.actionName.SET_SPECIALTIES,
                    payload: null,
                  });
                }
              }}
              onSearch={(e) => searchSpecialties(e)}
            >
              {
                //  (variables.filters.length !== 0) &&
                getSpecialties()
              }
            </Select>
          </div>
        </div>

        <div
          className='
        p-1
        m-1
        flex
        items-center

        sm:border-r-2
        sm:border-primary
        sm:border-b-0
        sm:pr-8
        sm:pb-1

        xs:col-span-12
        sm:col-span-6
        xl:col-span-3

        xs:mb-4
        xs:border-primary
        xs:border-b-2
        xs:border-l-0
        xs:pb-8
        xs:pr-1
        '
        >
          <div className='inline-block pl-2 pr-2 flex-1'>
            <FontAwesomeIcon
              style={{ width: "51px", height: "51px" }}
              size='lg'
              className='serviceCard--icon'
              icon={faCalendar}
            />
          </div>
          <div className='inline-block w-full'>
            <label className='ml-2' style={{ color: "#C4C4C4" }} htmlFor='SelectsPeciality'>
              Elige una fecha
            </label>
            <DatePicker
              format='DD/MM/YYYY'
              className='w-full border-none'
              defaultValue={moment(new Date(), "DD/MM/YYYY")}
              suffixIcon={<FontAwesomeIcon size='xs' icon={faChevronDown} />}
              // onChange={(e) => console.log(e)}
            />
          </div>
        </div>

        <div
          className='
        p-1
        m-1
        flex
        items-center

        sm:border-r-2
        sm:border-primary
        sm:border-b-0
        sm:pr-8
        sm:pb-1

        xs:col-span-12
        sm:col-span-6
        xl:col-span-3

        xs:mb-4
        xs:border-primary
        xs:border-b-2
        xs:border-l-0
        xs:pb-8
        xs:pr-1
        '
        >
          <div className='inline-block pl-2 pr-2 flex-1'>
            <FontAwesomeIcon
              style={{ width: "51px", height: "51px" }}
              size='lg'
              className='serviceCard--icon'
              icon={faGlobeAmericas}
            />
          </div>
          <div className='inline-block w-full'>
            <label className='ml-2' style={{ color: "#C4C4C4" }} htmlFor='SelectsFor'>
              Idiomas
            </label>
            <Select
              className='w-full selectDoctorSearch'
              showSearch
              disabled={reqlanguages.loading && !reqlanguages.data}
              loading={reqlanguages.loading}
              tagRender={tagRenderSelect}
              labelInValue
              mode='multiple'
              placeholder=''
              optionFilterProp='children'
              value={valueSelectLanguaje || []}
              onBlur={() => {
                setVariableLanguaje({
                  perPage: 25,
                  page: 1,
                  filters: [],
                });
              }}
              onSearch={(e) => searchLanguaje(e)}
              onChange={(e) => {
                props.dispatchStore({
                  type: props.actionName.SET_LANGUAJE,
                  payload: e,
                });
                setValueSelectLanguaje(e);
              }}
            >
              {getLanguages()}
            </Select>
          </div>
        </div>

        <div
          className='
        p-1
        m-1
        flex
        items-center
        xs:col-span-12
        sm:col-span-6
        xl:col-span-3'
        >
          <Button
            className='xs:w-full '
            type='primary'
            shape='round'
            size='large'
            onClick={() => (props.botonFiltrar ? props.botonFiltrar() : null)}
          >
            BUSCAR MEDICO
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default DoctorSearchBar;
