import React from "react";
import { Alert } from "antd";
import { useQuery } from "@apollo/react-hooks";
import Body from "./body";
import BodyDoctorMD from "./bodyDoctorMD";
import { infoDoctorByIdGql, infoDoctorMDByIdGql } from "./gqlTags";
import Spin from "antd/es/spin";

const BodyDetailsDoctor = ({ id, isNormalDoctor }) => {
  const { loading, error, data } = useQuery(
    isNormalDoctor ? infoDoctorByIdGql : infoDoctorMDByIdGql,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: {
        id: id,
      },
    }
  );
  if (loading) {
    return (
      <div className='w-full flex justify-center content-center self-center'>
        <Spin />
      </div>
    );
  }
  if (error) {
    return (
      <div className='w-full'>
        <Alert
          message='Error'
          description='Error al obtener la información del doctor'
          type='error'
          className='w-full'
          showIcon
        />
      </div>
    );
  }
  return isNormalDoctor ? <Body data={data} /> : <BodyDoctorMD data={data} />;
};

BodyDetailsDoctor.defaultProps = {
  isNormalDoctor: true,
};

export default BodyDetailsDoctor;
