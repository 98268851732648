import React from "react";
import { useQuery } from "@apollo/react-hooks";
// gql
import { getCanAddBeneficiary } from "views/Patient/gql";
// components
import Banner from "./Banner";
import ListBeneficiaries from "./List";
//utils
import get from "lodash/get";

export default function Beneficiaries() {
  const { data, loading, error } = useQuery(getCanAddBeneficiary, {
    fetchPolicy: "no-cache",
  });

  const capacity = get(data, ["canAddBeneficiary"], 0);

  return !loading && capacity > 0 && !error ? (
    <ListBeneficiaries capacity={capacity} />
  ) : (
    <Banner loading={loading || !data} />
  );
}
