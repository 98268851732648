import React, { useState, useContext, useEffect } from "react";
import LlamadaDiv from "../components/video/indexLlamada";
import { GlobalContext } from "../../App";
import { XMPP } from "../../../xmpp";
import { SET_CALLINPROGRESS, SET_CHATCALL_SESSIONID } from "../../../store/actions";
import BrowserValidator from "../components/browserValidator";
import { getRandomInt } from "../components/utils";
import EstadosCita from "../components/estadosCitaPacienteNormal";

const LlamadaListenerConfig = ({
  infoForChat,
  infoAppointment,
  isBefore,
  isInAppointment,
  isAfter,
  isFinish,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { chatCall: chatCallGlobalState } = state;
  const [callInProgress, setCallInProgress] = useState(false);
  const [randomForClose, setRandomForClose] = useState(null);

  useEffect(() => {
    verificarLlamada();
  }, [chatCallGlobalState]);

  useEffect(() => {
    return () => {
      terminarSessionLlamada();
      dispatch({
        type: SET_CHATCALL_SESSIONID,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    if (randomForClose) {
      terminarSessionLlamada();
    }
  }, [randomForClose]);

  const terminarSessionLlamada = () => {
    try {
      const activeCall = XMPP.getActiveCall();
      if (activeCall) {
        XMPP.endCall(activeCall?.sessionID).then(
          () => {
            setCallInProgress(false);
            dispatch({
              type: SET_CHATCALL_SESSIONID,
              payload: null,
            });
          },
          () => {
            setCallInProgress(false);
            dispatch({
              type: SET_CHATCALL_SESSIONID,
              payload: null,
            });
          }
        );
      }
    } catch (e) {
      console.log(e);
      setCallInProgress(false);
      dispatch({
        type: SET_CHATCALL_SESSIONID,
        payload: null,
      });
    }
  };

  const verificarLlamada = () => {
    if (chatCallGlobalState) {
      setCallInProgress(true);
    } else {
      setCallInProgress(false);
    }
  };

  const listenerLlamadaCall = () => {
    XMPP.addCallListener(
      "specialist_call",
      new XMPP.CallListener({
        onOutgoingCallRejected(call) {
          setCallInProgress(false);
          setRandomForClose(getRandomInt());
          dispatch({
            type: SET_CHATCALL_SESSIONID,
            payload: null,
          });
        },
        onIncomingCallCancelled(call) {
          setCallInProgress(false);
          setRandomForClose(getRandomInt());
          dispatch({
            type: SET_CHATCALL_SESSIONID,
            payload: null,
          });
        },
      })
    );
  };

  const disconnectCall = () => {
    setCallInProgress(false);
    dispatch({
      type: SET_CALLINPROGRESS,
      payload: { isCallInProgress: false },
    });
  };
  return (
    <div className='h-full w-full'>
      <div className='flex flex-col justify-center content-center w-full h-full'>
        {callInProgress ? (
          <LlamadaDiv
            sessionID={chatCallGlobalState ? chatCallGlobalState.sessionID : null}
            terminarSessionLlamada={terminarSessionLlamada}
            listenerEmpezarLlamada={listenerLlamadaCall}
            infoAppointment={infoAppointment}
            infoForChat={infoForChat}
            disconnectCall={disconnectCall}
          />
        ) : (
          <EstadosCita
            infoAppointment={infoAppointment}
            isInAppointment={isInAppointment}
            isBefore={isBefore}
            isAfter={isFinish || isAfter}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(LlamadaListenerConfig);
