import React from "react";
// antd
import Card from "antd/lib/card";
import WizardMD from "components/WizardMD";
import Typography from "antd/lib/typography";
// components
// import { Btn } from 'views/Patient/WithOutAppointment'
// style
import style from "../../style.module.scss";

const { Title } = Typography;

export default function BannerConsulta() {
  const props = {
    Card: {
      className: style.BannerConsulta,
    },
    Btn: {
      type: "primary",
      href: "https://api.whatsapp.com/send?phone=50587131519&text=Hola%20soy%20afiliado%20EasyMD%2c%20me%20gustar%c3%ada%20comprar%20medicamentos",
      target: "_blank",
      children: "Consulta",
    },
    Title: {
      level: 3,
      children: "¿Necesitas una consulta?",
      className: "text-white",
    },
  };

  return (
    <Card
      {...props.Card}
      actions={[
        <WizardMD key='WizardMD' classNameButton='xl:inline-block bg-white text-green-500' />,
      ]}
    >
      <Title {...props.Title} />
    </Card>
  );
}
