import React, { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
// antd
import Card from "antd/lib/card";
import Spin from "antd/lib/spin";
import Notify from "antd/lib/notification";
import Statistic from "antd/lib/statistic";
// icons
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
// helpers
import get from "lodash/get";
import { HandleError } from "utils.js/HandleError";
// style
import style from "./style.module.scss";

export default function StatisticMultipleCards(_) {
  const { icon, gql, path, redirectTo, onClick, ...attr } = _;
  const history = useHistory();
  const { data, loading, error } = useQuery(gql, {
    fetchPolicy: "no-cache",
  });

  const redirect = (id) => {
    if (redirectTo) {
      history.push({ pathname: redirectTo, state: { plan: id } });
    }
  };

  const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
  };

  useEffect(() => {
    if (error) Notify.error({ message: HandleError(error) });
  }, [error]);

  const values = get(data, path, []);

  return loading ? (
    <Spin className='self-center' />
  ) : (
    values.map((value, i) => (
      <Card
        key={i}
        onClick={() => redirect(value.id)}
        className={style.staticsCard + " " + (redirectTo ? style.hoverRedirectTo : "")}
      >
        <Statistic
          title={value.name}
          value={isFloat(value.value) ? value.value.toFixed(2) : value.value}
          {...attr}
        />

        <FA icon={icon} />
      </Card>
    ))
  );
}

StatisticMultipleCards.defaultProps = {
  className: "",
};
