import React from "react";
// antd
import Form from "antd/lib/form";
import Space from "antd/lib/space";
// components
import InputState from "./InputState";
import input from "./input/info";
// styles
import style from "./style.module.scss";

const props = {
  Space: {
    size: 0,
    align: "center",
    style: {
      width: "100%",
      display: "grid",
      gridAutoFlow: "row",
      gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr) )",
    },
  },
  State: {
    noStyle: true,
    children: ({ getFieldValue }) => {
      const c = getFieldValue("country");
      return (
        <Form.Item {...input.State}>
          <InputState country={c} disabled={!c} />
        </Form.Item>
      );
    },
    shouldUpdate: (pVal, cVal) => pVal.country !== cVal.country,
  },
};

export default function FormPay(_) {
  const cl = _.className ? `${_.className} ${style.FormPay}` : style.FormPay;

  return (
    <Form {..._} className={cl}>
      <Space {...props.Space}>
        <Form.Item {...input.Address} />
        <Form.Item {...input.Address2} />
      </Space>
      <Space {...props.Space}>
        <Form.Item {...input.Country} />
        <Form.Item {...props.State} />
      </Space>
      <Space {...props.Space}>
        <Form.Item {...input.City} />
        <Form.Item {...input.Zip} />
      </Space>
    </Form>
  );
}
