import React from "react";
import Modal from "antd/lib/modal";
import Table from "./Table";
export default function ModalPlans({ modalVisible, setModalVisible }) {
  return (
    <Modal
      destroyOnClose
      closable
      style={{
        minWidth: "20rem",
      }}
      className='xs:w-4/5 md:w-4/5 lg:w-4/6'
      title={null}
      footer={null}
      bodyStyle={{ paddingTop: "5%" }}
      open={modalVisible}
      visible={modalVisible}
      onCancel={() => setModalVisible((_) => false)}
    >
      <Table modal={true} />
    </Modal>
  );
}
