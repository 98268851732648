import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import classes from "classnames";
// antd
import Layout from "antd/lib/layout";
// components
import Medic from "components/Medic";
import Calendar from "./Calendar";
// hooks
import useMedia from "hooks/useMedia";
// helpers
import get from "lodash/get";
// import isEmpty from 'lodash/isEmpty'

const breakpoint = [1600, 1200, 768];
const minWidth = [450, 380, 300];

export default function Diary(_) {
  const queries = breakpoint.map((w) => `(min-width: ${w}px)`);
  const width = useMedia(queries, minWidth, "100%");
  const history = useHistory();

  useEffect(() => () => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  });

  const id = get(_, "location.state.idDoctor", false);

  if (!id) {
    history.push({ pathname: "/md/home" });
    return null;
  }

  const props = {
    Medic: { id },
    Layout: {
      hasSider: true,
      style: { marginTop: 1 },
      className: classes("bg-transparent", "overflow-hidden", { "flex-col": width === "100%" }),
    },
    Sider: {
      width,
      className: "bg-transparent",
    },
  };

  return (
    <Layout {...props.Layout}>
      <Layout.Sider {...props.Sider}>
        <Medic {...props.Medic} />
      </Layout.Sider>
      <Layout.Content tagName='section'>
        <Calendar id={id} />
      </Layout.Content>
    </Layout>
  );
}
