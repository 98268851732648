import gql from "graphql-tag";

export const SelectplansGql = gql`
  query SelectplansAfiliados($perPage: Float, $page: Float, $filters: [planFilter!]) {
    plans(perPage: $perPage, page: $page, filters: $filters) {
      total
      page
      docs {
        id
        comprable
        name
      }
    }
  }
`;
export const getAgendaJobActive = gql`
  query ($userPlanId: String!) {
    getAgendaJobActive(userPlanId: $userPlanId) {
      lastDatePayment
      nextDatePayment
      failReason
      failDate
      amount
    }
  }
`;

export const patientsTransactions = gql`
  query patientsTransactions($userPlanId: String!) {
    patientsTransactions(userPlanId: $userPlanId) {
      amount
      currency
      confirmationNumber
      createdAt
      description
      status
      type
    }
  }
`;
