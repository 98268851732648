import React, { useContext } from "react";
import { Avatar, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faGift } from "@fortawesome/free-solid-svg-icons";

import * as actionNames from "./store/actions";
import { ContextTablaCoupons } from "./index";

import SwitchStatus from "./switchStatus";

const columns = [
  {
    title: "Nombre",
    dataIndex: "coupon",
    className: "p-1",
    render: (text, row) => (
      <div className='flex flex-row justify-left items-center'>
        <Avatar icon={<FontAwesomeIcon icon={faGift} />} />
        <div className='flex flex-col pl-3'>
          <h1 className='mb-0 pt-3'>{row.coupon}</h1>
          <p className='lowercase'>
            {row.type}:
            <span className='pl-1'>
              <strong>{row.amount}</strong>
            </span>
          </p>
        </div>
      </div>
    ),
  },
  {
    title: "Estado",
    dataIndex: "status",
    className: "text-center w-5por",
    render: (row, fullRow) => <RenderActive row={row} fullRow={fullRow} />,
  },
  {
    title: "Detalles",
    dataIndex: "custom_verInfo",
    className: "text-center w-5por",
    render: (row, fullRow) => <RenderInfo row={row} fullRow={fullRow} />,
  },
  {
    title: "Acciones",
    dataIndex: "custom_acciones",
    className: "text-center w-1/6",
    render: (row, fullRow) => <RenderActionsAprobarRechazar fullRow={fullRow} />,
  },
];

const RenderActive = ({ row, fullRow }) => {
  const { id } = fullRow;
  return <SwitchStatus initialState={row} id={id} />;
};

const RenderInfo = ({ fullRow }) => {
  const { dispatch } = useContext(ContextTablaCoupons);
  const clickInfo = () => {
    dispatch({
      type: actionNames.SET_INFO_COUPON,
      payload: fullRow.id,
    });
    dispatch({
      type: actionNames.SET_MODAL_INFO_OPEN,
      payload: true,
    });
  };
  return (
    <Button
      type='default'
      shape='round'
      size='small'
      className='border-0 shadow-none'
      onClick={() => clickInfo()}
    >
      <FontAwesomeIcon icon={faEye} />
    </Button>
  );
};

const RenderActionsAprobarRechazar = ({ fullRow }) => {
  const { dispatch } = useContext(ContextTablaCoupons);
  const { id } = fullRow;

  const openDeclinar = () => {
    dispatch({
      type: actionNames.SET_INFO_COUPON,
      payload: id,
    });
    dispatch({
      type: actionNames.SET_MODAL_DENY_OPEN,
      payload: true,
    });
  };

  const openEdit = () => {
    dispatch({
      type: actionNames.SET_INFO_COUPON,
      payload: id,
    });
    dispatch({
      type: actionNames.SET_MODAL_EDIT_OPEN,
      payload: true,
    });
  };

  return (
    <div className='flex justify-center'>
      <Button
        className='mx-2'
        type='primary'
        danger
        shape='round'
        size='small'
        onClick={() => openDeclinar()}
      >
        {" "}
        Eliminar
      </Button>
      <Button
        className='button-success mx-2'
        type='default'
        shape='round'
        size='small'
        onClick={() => openEdit()}
      >
        {" "}
        Editar
      </Button>
    </div>
  );
};

export default columns;
