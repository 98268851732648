import React, { useEffect, useState } from "react";
import capitalize from "lodash/capitalize";
import moment from "moment";

export const useTime = (refreshCycle = 120000, timeForCalc) => {
  // Returns the current time
  // and queues re-renders every `refreshCycle` milliseconds (default: 100ms)

  const [now, setNow] = useState(getTime(timeForCalc));

  useEffect(() => {
    // Regularly set time in state
    // (this will cause your component to re-render frequently)
    const intervalId = setInterval(() => setNow(getTime(timeForCalc)), refreshCycle);

    // Cleanup interval
    return () => clearInterval(intervalId);

    // Specify dependencies for useEffect
  }, [refreshCycle, setNow, timeForCalc]);

  return now;
};

export const getTime = (time) => {
  return capitalize(moment(time).fromNow());
};

const TimeLabel = ({ time }) => {
  const now = useTime(1000, time);
  return <span className='text-primary font-semibold text-sm mb-auto'>{now}</span>;
};

export default TimeLabel;
