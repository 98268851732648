import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import FullCalendar from "@fullcalendar/react";
// antd
import Spin from "antd/lib/spin";
// gql
import { getMedicSlots } from "./gql";
// props statics
import propsCalendar from "./staticsProps";
// helpers
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
// styles
import style from "./style.module.scss";

export default function Calendar({ id }) {
  const [range, setRange] = useState({});
  const [fetchSlots, { data, loading }] = useLazyQuery(getMedicSlots, {
    variables: { id, ...range },
    fetchPolicy: "no-cache",
  });

  const history = useHistory();

  function onSelect(event) {
    history.push({
      pathname: "/md/appointment/verify",
      state: {
        date: event.start,
        idDoctor: id,
      },
    });
  }

  useEffect(() => {
    if (!isEmpty(range) && !loading) fetchSlots();
  }, [range]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  });

  const onChange = (vars) => {
    if (!isEqual(vars, range)) setRange(vars);
  };

  const slots = loading ? [] : get(data, "doctorById.slots", []);

  return (
    <section className={style.Calendar}>
      <Spin spinning={loading}>
        <FullCalendar {...propsCalendar(onSelect, onChange, slots)} />
      </Spin>
    </section>
  );
}
