import gql from "graphql-tag";

export const completeAppointmentGql = gql`
  mutation updateAppointmentMD($id: String!, $status: updateAppointmentStatus!, $patient: String!) {
    updateAppointmentMD(id: $id, status: $status, patient: $patient) {
      id
    }
  }
`;

export const appointmentMDGql = gql`
  query appointmentmdById($id: String!) {
    appointmentmdById(id: $id) {
      id
      status
      reasonForConsultation
      newSymptoms
      appointmentType
      vonageSessionId
      patient {
        id
        newMedicalConditions
        newDrugAllergies
        fullName
        pictureURL {
          url
        }
        openFireUsername
        dateOfBirth
        weight
        height
        sex
        typeOfBlood
        plans {
          plan {
            name
          }
        }
        belongsToCompany {
          fullName
        }
      }
      doctor {
        id
        fullName
        pictureURL {
          url
        }
        openFireUsername
      }
      log {
        firstMessagePatient
        firstMessageDoctor
      }
    }
  }
`;

export const appointmentStatusGql = gql`
  query appointmentmdById($id: String!) {
    appointmentmdById(id: $id) {
      status
    }
  }
`;

export const sendNotificationCallIncomingGql = gql`
  query sendNotificationCallIncoming($id: String!) {
    sendNotificationCallIncoming(id: $id)
  }
`;

export const sendNotificationNewMessageGql = gql`
  query sendNotificationNewMessage($id: String!, $message: String) {
    sendNotificationNewMessage(id: $id, message: $message)
  }
`;
