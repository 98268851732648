import React from "react";
import { Link } from "react-router-dom";
import logoEasy from "assets/img/EasyMDLogo.svg";

export default function Brand() {
  return (
    <Link to='/md/home' className='brand'>
      <img
        style={{
          width: "237.855px",
          height: "51.316px",
        }}
        src={logoEasy}
        alt='LogoEasy'
      />
    </Link>
  );
}
