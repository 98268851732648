import React from "react";
import Divider from "antd/lib/divider";

import DetailAppointment from "../detailAppointment";

import { getDataForCard } from "../utils";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { URLDOCTORCITAMD } from "../../../../../../chatCall/utils";

const MyAppointments = (props) => {
  return (
    <>
      {props.data.map((value, index) => {
        const dataForDetail = getDataForCard(value);
        return (
          <div className='w-full mb-3' key={index}>
            <DetailAppointment
              {...dataForDetail}
              footer={<FooterDetail loading={props.loading} id={value.id} />}
            />
          </div>
        );
      })}
    </>
  );
};

const FooterDetail = ({ loading, id }) => {
  return (
    <div className='w-full flex flex-col'>
      <Divider className='mb-2' />
      <Button
        type='default'
        shape='round'
        disabled={loading}
        className='mx-auto my-auto button-success'
      >
        <a rel='noopener noreferrer' target='_blank' href={URLDOCTORCITAMD + id}>
          <FontAwesomeIcon icon={faPhoneAlt} className='mr-2' />
          Atender cita
        </a>
      </Button>
    </div>
  );
};

export default MyAppointments;
