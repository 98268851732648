import React, { useEffect } from "react";
import moment from "moment";
import { HandleError } from "utils.js/HandleError";
// ant
import { Modal, Button, TimePicker, Form, notification } from "antd";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@apollo/react-hooks";

import { updateMedicWorkingDays } from "../../gqlTags";

const ModalAgregarIntervalo = (props) => {
  const [form] = Form.useForm();
  let { arrayInterval } = props;
  const { selectedDay } = props;

  const [agregateWorkingDays, { error, data, loading }] = useMutation(updateMedicWorkingDays, {
    refetchQueries: ["doctorById"],
  });

  arrayInterval = arrayInterval.map((value) => ({ from: value.from, to: value.to }));

  const submitAgregarIntervalo = async (valueForm) => {
    try {
      const fromDay = moment(
        `${moment().day(selectedDay).format("MM/DD/YYYY")} ${moment(
          valueForm.TimeInterval[0]._d
        ).format("hh:mm a")}`,
        "MM/DD/YYYY hh:mm a"
      ).toISOString();
      const toDay = moment(
        `${moment().day(selectedDay).format("MM/DD/YYYY")} ${moment(
          valueForm.TimeInterval[1]._d
        ).format("hh:mm a")}`,
        "MM/DD/YYYY hh:mm a"
      ).toISOString();
      arrayInterval.push({
        from: fromDay,
        to: toDay,
      });
      await agregateWorkingDays({
        variables: {
          workingDays: arrayInterval,
        },
      });
      props.setModalVisible(false);
      form.resetFields();
    } catch (e) {
      form.resetFields();
      console.log(e);
    }
  };

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
    if (data) {
      notification.success({
        message: "Intervalo agregado",
      });
    }
  }, [error, data]);

  const footerCustom = (
    <div className='flex justify-between '>
      <Button
        loading={loading}
        disabled={loading}
        onClick={() => {
          form.resetFields();
          props.setModalVisible(false);
        }}
        type='primary'
        shape='round'
        danger
        size='large'
      >
        Cerrar
      </Button>
      <Button
        loading={loading}
        disabled={loading}
        type='primary'
        shape='round'
        htmlType='submit'
        size='large'
        onClick={() => form.submit()}
      >
        Agregar Intervalo
      </Button>
    </div>
  );

  return (
    <Modal
      style={{
        width: "45rem",
        minWidth: "15rem",
      }}
      className='ModalDoctor'
      visible={props.visbleModal}
      onCancel={() => props.setModalVisible(false)}
      footer={footerCustom}
      closeIcon={<FontAwesomeIcon className='text-red-600 hover:text-primary' icon={faTimes} />}
    >
      <div className='mx-3 mt-3 pt-3 px-3'>
        <h3 className='text-2xl'>
          {" "}
          <strong>Agregar intervalo</strong>
        </h3>
      </div>
      <div className='mx-3 px-3'>
        <Form
          form={form}
          layout='horizontal'
          name='basic'
          onFinish={(valueForm) => submitAgregarIntervalo(valueForm)}
        >
          <Form.Item
            className='mb-1'
            label={null}
            name='TimeInterval'
            rules={[{ required: true, message: "Por favor ingrese un intervalo de fecha!" }]}
          >
            <TimePicker.RangePicker
              // disabledHours={() => [, 2, 3]}
              placeholder={["Desde", "Hasta"]}
              allowClear={false}
              format='hh:mm a'
              minuteStep={30}
              className='w-full my-3'
              bordered={false}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalAgregarIntervalo;
