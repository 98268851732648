import React from "react";
// antd
import Button from "antd/lib/button";
// components
import Canvas from "./Canvas";
// icons
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";

export default function AddFeature() {
  const props = {
    type: "primary",
    icon: <Fa icon={faPlus} className='mr-2' />,
    ghost: true,
    children: "Agregar Caracteristica",
  };

  return (
    <Canvas>
      <Button {...props} />
    </Canvas>
  );
}
