import React, { createContext, useReducer, useEffect, useContext, useCallback } from "react";
import { withRouter } from "react-router";
import { useLazyQuery } from "@apollo/react-hooks";
import { companyByIdGql } from "./gqlTags";

// utils
import get from "lodash/get";

// context
import { SET_INITIAL_INFO, SET_QUERYBYID } from "./store/actions";
import reducer from "./store/reducer";
import initialState from "./store/initialState";

// components
import LoadingFirts from "./loadingFirts";
import ContentCompany from "./contentCompany";
import View404Router from "../../../components/View404Router";

// global Context
import { GlobalContext } from "views/App";

export const ContextCompanyCodes = createContext();

const CompanyCodes = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { state: globalState } = useContext(GlobalContext);

  const getIdUrl = useCallback(() => {
    const role = get(globalState, ["user", "role", "name"], "");
    const id =
      role === "root"
        ? get(props, ["match", "params", "id"], "_")
        : get(globalState, ["user", "id"], "");

    return {
      id,
      role,
    };
  }, [globalState, props]);

  const [fetchCompanyById, { data, loading, error }] = useLazyQuery(companyByIdGql, {
    variables: {
      id: getIdUrl(),
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const reFetchCompany = useCallback(() => {
    fetchCompanyById({
      variables: {
        id: state.id || getIdUrl().id,
      },
    });
  }, [fetchCompanyById, getIdUrl, state.id]);

  useEffect(() => {
    const info = getIdUrl();
    dispatch({
      type: SET_INITIAL_INFO,
      payload: {
        ...info,
      },
    });
    reFetchCompany();
  }, [getIdUrl, reFetchCompany]);

  useEffect(() => {
    dispatch({
      type: SET_QUERYBYID,
      payload: {
        data,
        loading,
        error,
      },
    });
  }, [data, loading, error]);

  const validateRender = () => {
    if (!state.data && !state.error) {
      return <LoadingFirts />;
    }
    if (!state.loading && state.error) {
      return <View404Router />;
    }
    return <ContentCompany />;
  };

  return (
    <ContextCompanyCodes.Provider value={{ state: state, dispatch: dispatch }}>
      {validateRender()}
    </ContextCompanyCodes.Provider>
  );
};

export default withRouter(CompanyCodes);
