import React from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/loadingCircleBlue.json";

const LoadingLottie = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className='w-full'>
      <Lottie
        isClickToPauseDisabled
        options={defaultOptions}
        height={props.height}
        width={props.width}
      />
    </div>
  );
};

LoadingLottie.defaultProps = {
  height: "20%",
  width: "20%",
};

export default LoadingLottie;
