export const getIdAppointment = (props) => {
  let info = {
    idAppointment: null,
    sessionID: null,
  };
  const { location, match } = props;
  if (location) {
    const { state } = location;
    if (state) {
      const { idAppointment, sessionID } = state;
      info = {
        idAppointment: idAppointment,
        sessionID: sessionID,
      };
    } else {
      if (match) {
        const { params } = match;
        if (params) {
          info = {
            idAppointment: params.id,
            sessionID: info ? (info.sessionID ? info.sessionID : null) : null,
          };
        }
      }
    }
  } else {
    if (match) {
      const { params } = match;
      if (params) {
        info = {
          idAppointment: params.id,
          sessionID: info ? (info.sessionID ? info.sessionID : null) : null,
        };
      }
    }
  }
  return info;
};

export const verifyIdUserVsDoctor = (idUser, idDoctor) => {
  return idUser === idDoctor;
};

export const verifyInAppointment = (status, usable) => {
  return status !== "COMPLETE" && usable === "IN_APPOINTMENT";
};

export const verifyFinish = (status, usable) => {
  return status === "COMPLETE" || usable === "AFTER_APPOINTMENT";
};

export const verifyBefore = (status, usable) => {
  return (
    status !== "COMPLETE" &&
    (usable === "BEFORE_APPOINTMENT" || usable === "PENDING" || usable === "NOT_SCHEDULED")
  );
};

export const verifyIsAfter = (status, usable) => {
  return status !== "COMPLETE" && usable === "AFTER_APPOINTMENT";
};

/// usable ////
// NOT_SCHEDULED
// BEFORE_APPOINTMENT
// IN_APPOINTMENT
// AFTER_APPOINTMENT

/// status ////
//PENDING
//SCHEDULED
//ONGOING
//COMPLETE
//CANCELED
//AVAILABLE
