export const SET_FILTER = "SETFILTER";
export const SET_FILTER_INITIAL = "SETFILTERINITIAL";

export const SET_EMPTY_DOCTORS = "SETEMPTYDOCTORS";

export const SET_PAGE = "SETPAGE";

export const SET_SCROLL_PAGE = "SET_SCROLL_PAGE";

export const SET_PERPAGE = "SETPERPAGE";

export const SET_DATA_QUERY = "SETDATAQUERY";

export const SET_PUNCTUATION = "SETPUNCTUATION";
export const SET_NAME_DOCTOR = "SETNAMEDOCTOR";
export const SET_SPECIALTIES = "SETSPECIALTIES";
export const SET_LANGUAJE = "SETLANGUAJE";
export const SET_FILTER_FINAL = "SETFILTERFINAL";

export const SET_TYPE_FILTERS_BY_HOME = "SETTYPEFILTERSBYHOME";

export const SET_FITER_ESPECIALIDAD = "SETFITERESPECIALIDAD";
