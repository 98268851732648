import React, { useContext } from "react";
import { ContextTablaPlanilla } from "./index";
import * as actionNames from "./store/actions";
import { Button } from "antd";

const currencyFormat = (num) => {
  if (!num) {
    return "$0.00";
  }
  return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const columns = [
  {
    title: "Nombre",
    dataIndex: "fullName",
    className: "text-center",
  },
  {
    title: "# MINSA",
    dataIndex: "minsaNumber",
    className: "text-center",
  },
  {
    title: "Correo",
    dataIndex: "email",
    className: "text-center",
  },
  {
    title: "Balance",
    dataIndex: "balance",
    className: "text-center",
    render: (e) => currencyFormat(e),
  },
  {
    title: "¿Desembolsar?",
    dataIndex: "_customDesembolsar",
    className: "text-center",
    render: (row, fullRow) => <RenderActionsAprobarRechazar fullRow={fullRow} />,
  },
  /*{
        title: 'Último desembolso ',
        dataIndex: 'fecha',
        className:'text-center',
    }*/
];

const RenderActionsAprobarRechazar = ({ fullRow }) => {
  const { dispatch } = useContext(ContextTablaPlanilla);
  const { balance } = fullRow;
  if (!balance) {
    return null;
  }
  const openModal = () => {
    dispatch({
      type: actionNames.SET_INFO_DOCTOR,
      payload: fullRow,
    });
    dispatch({
      type: actionNames.SET_MODAL_PAGAR,
      payload: true,
    });
  };
  if (parseFloat(balance) > 0) {
    return (
      <Button
        type='primary'
        shape='round'
        size='small'
        className='button-success'
        onClick={() => openModal()}
      >
        {" "}
        Pagar{" "}
      </Button>
    );
  }
  return null;
};

export default columns;
