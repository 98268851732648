import React, { useContext, useState } from "react";
// componet
import ModalCambiarFirma from "./ModalCambiarFirma";

// ant
import Button from "antd/lib/button";
import Spin from "antd/lib/spin";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { profileMedicContext } from "../../../index";

const FirmaDoctor = () => {
  const { state } = useContext(profileMedicContext);
  const [visbleModal, setVisbleModal] = useState(false);
  const [loadingSignature, setLoadingSignature] = useState(false);

  return (
    <div className='w-full h-full flex items-center'>
      {visbleModal && (
        <ModalCambiarFirma
          setLoadingSignature={setLoadingSignature}
          idDoctor={state.dataMedic.id}
          dataMedic={state.dataMedic}
          error={state.error}
          visbleModal={visbleModal}
          setModalVisible={setVisbleModal}
        />
      )}

      <div
        style={{
          backgroundImage: `url(${state.dataMedic ? state.dataMedic.signature : ""})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className='absolute top-0 right-0 bottom-0 left-0  border border-gray-400  w-full h-full flex items-center justify-center'
      >
        {(state.loading || loadingSignature) && <Spin />}
      </div>
      <div className='absolute right-0 bottom-0 mr-3 mb-3'>
        <Button
          // disabled={state.loading}
          type='default'
          shape='circle'
          className='button-success'
          size='large'
          onClick={() => {
            setVisbleModal(true);
          }}
        >
          <FontAwesomeIcon className='text-center' size='lg' icon={faPen} />
        </Button>
      </div>
    </div>
  );
};

export default FirmaDoctor;
